// extracted by mini-css-extract-plugin
var _1 = "PdfPoster_container__NM3l9";
var _2 = "PdfPoster_footer__DkNT9";
var _3 = "PdfPoster_header__9vF6Z";
var _4 = "PdfPoster_hide__yHfJQ";
var _5 = "PdfPoster_logo__Fw1th";
var _6 = "PdfPoster_name__0Haxd";
var _7 = "PdfPoster_qrCodeContainer__sMxiv";
var _8 = "PdfPoster_scanForBooking__Cpc6T";
export { _1 as "container", _2 as "footer", _3 as "header", _4 as "hide", _5 as "logo", _6 as "name", _7 as "qrCodeContainer", _8 as "scanForBooking" }
