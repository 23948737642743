import General from './general';

import {
    SETTINGS_INVITE_CLIENTS_PAGE,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: General,
        exact: false,
        path: SETTINGS_INVITE_CLIENTS_PAGE,
    },
];

export default routes;
