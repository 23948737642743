import moment from 'moment';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loaded: false,
    date: moment.now(),
    bookingsFetchRange: {
        from: null,
        to: null,
    },
};

export const app = (state = initialState, { type, payload }) => {
    switch (type) {
    case actionTypes.LOAD_APP_SUCCEEDED:
        return {
            ...state,
            loaded: true,
        };

    case actionTypes.SIGN_IN_SUCCEEDED:
        return {
            ...state,
            date: moment.now(),
        };
    case actionTypes.DATE_CHANGED:
        return {
            ...state,
            date: payload,
        };

    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return {
            ...state,
            date: moment.now(),
            bookingsFetchRange: {
                from: null,
                to: null,
            },
        };

    case actionTypes.SET_BOOKINGS_FETCH_RANGE:
        const { from, to } = payload;

        return {
            ...state,
            bookingsFetchRange: { from, to },
        };

    default:
        return state;
    }
};
