import React from 'react';

// Hooks
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import * as actions from 'store/actions/bookings';

// Components
import { Timeline } from 'components';

const Day = ({
    productType = 'basic', date, onBookingClick, type,
}) => {
    const isShopLoaded = useSelector((state) => state.shop.loaded);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isShopLoaded) {
            return;
        }

        dispatch(
            actions.getBookings(
                date.clone().startOf('day').unix(),
                date.clone().endOf('day').unix(),
                productType === 'object' ? 'basic' : productType,
            ),
        );
    }, [isShopLoaded, date]);

    return (
        <Timeline
            className="mt-4 px-0"
            day={date}
            onBookingClick={onBookingClick}
            dataType={`${productType}s`}
            type={type}
        />
    );
};

export default Day;
