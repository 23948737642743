import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components/Common/Loader';
import { EmptyNotes } from 'components/clients/ClientPage/ClientTables/ClientNotes/EmptyNotes';
import { ClientNoteItem } from 'components/clients/ClientPage/ClientTables/ClientNotes/ClientNoteItem';
import { ClientNotesFooter } from 'components/clients/ClientPage/ClientTables/ClientNotes/ClientNotesFooter';

import { ClientNotesCreate } from 'components/clients/ClientPage/ClientTables/ClientNotes/ClientNotesCreate';
import { ClientNotesFilter } from 'components/clients/ClientPage/ClientTables/ClientNotes/ClientNotesFilter';
import * as CLIENTS_ACTIONS from 'store/actions/clients/tables/notes';
import * as CLIENTS_SELECTORS from 'store/selectors/clients';
import { useMobile } from 'hooks/useMobile';
import ClientNotesMobileFooter from 'components/clients/ClientPage/ClientTables/ClientNotes/ClientNotesMobileFooter/ClientNotesMobileFooter';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useTranslation } from 'react-i18next';
import { Content } from 'components/Common/Content';
import * as styles from './page.module.scss';

const ITEMS_PER_PAGE = 10;

function ClientNotesTable() {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { clientId } = useParams();
    const isMobile = useMobile();

    const dispatch = useDispatch();

    const {
        page,
        items: notes,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        categories,
        selectedCategoriesIds,
        savingNote,
        savingCategory,
    } = useSelector(CLIENTS_SELECTORS.clientNotesSelector);

    const noDataLoaded = loading && !categories.length;

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientNotesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientNotesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientNotesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientNotesPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientNotesPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientNotesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_ACTIONS.setClientNotesPage({ page: nextPage }));
    }, [dispatch]);

    const handleFilterCategory = useCallback((categoryId) => {
        dispatch(CLIENTS_ACTIONS.setClientNotesFilter({ categoryId }));
    }, []);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.clearClientNotesFilter());
        dispatch(CLIENTS_ACTIONS.getClientNotesCategories());
    }, []);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClientNotes({ clientId, categories: selectedCategoriesIds }));
    }, [dispatch, page, selectedCategoriesIds]);

    return (
        <Content loading={noDataLoaded}>
            <div
                className={styles.container}
            >
                {!isMobile && (
                    <div className={styles.createAndFilter}>
                        <ClientNotesCreate
                            categories={categories}
                            saving={savingNote || savingCategory}
                        />
                        <div>
                            <p className="font-weight-600 font-size-16">{t('notes.filter.label')}</p>
                            <ClientNotesFilter
                                categories={categories}
                                onSelect={handleFilterCategory}
                                selectedCategoriesIds={selectedCategoriesIds}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.items}>
                    {loading
                        ? <Loader />
                        : (
                            itemsCount
                                ? (
                                    <>
                                        {notes.map((note) => (
                                            !!note && (
                                                <ClientNoteItem
                                                    key={note.id}
                                                    note={note}
                                                    categories={categories}
                                                    saving={savingNote || savingCategory}
                                                />
                                            )
                                        ))}
                                        <ClientNotesFooter
                                            page={page}
                                            showAll={showAll}
                                            itemsCount={itemsCount}
                                            pagesCount={pagesCount}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            onShowAll={handleShowAll}
                                            onPrevPage={handlePrevPage}
                                            onNextPage={handleNextPage}
                                            onLastPage={handleLastPage}
                                            onFirstPage={handleFirstPage}
                                            onShowPages={handleShowPages}
                                            onChangePage={handleChangePage}
                                        />
                                    </>
                                )
                                : <EmptyNotes />
                        )}
                </div>
            </div>
            <ClientNotesMobileFooter
                categories={categories}
                onSelect={handleFilterCategory}
                selectedCategoriesIds={selectedCategoriesIds}
                saving={savingNote || savingCategory}
            />
        </Content>
    );
}

export default ClientNotesTable;
