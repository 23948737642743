import React from 'react';

const Phone = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <path d="M17,15l-3,3l-8-8l3-3L4,2 L1,5c0,9.941,8.059,18,18,18l3-3L17,15z" fill="none" stroke={fill} />
            <path d="M14,1 c4.971,0,9,4.029,9,9" fill="none" />
            <path d="M14,5 c2.761,0,5,2.239,5,5" fill="none" />
        </g>
    </svg>
);

export default Phone;
