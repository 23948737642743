import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/Common/IconButton/IconButton';
import SearchInput from 'components/SearchInput';
import { Plus } from 'react-feather';
import { useDialog } from '../../../../hooks/useDialog';

import Footer from '../../../Layout/Footer/Footer';
import { InvoicesMobileFiltersDialog } from '../InvoicesMobileFiltersDialog';

import { FilterIcon } from '../../../Icon/Icon';

import * as styles from './InvoicesPageMobileFooter.module.scss';

const InvoicesPageMobileFooter = (props) => {
    const { searchValue, onSearch } = props;

    const invoicesMobileFiltersDialog = useDialog(false);

    return (
        <>
            <Footer className={styles.mobileButtonsContainer}>
                <SearchInput
                    searchValue={searchValue}
                    onSearch={onSearch}
                />
                <div className={styles.footerDivider} />

                <IconButton
                    color="white"
                    size={60}
                    onClick={invoicesMobileFiltersDialog.onShow}
                >
                    <FilterIcon />
                </IconButton>
                <div className={styles.footerDivider} />

                <IconButton
                    color="yellow"
                    size={60}
                    href="/financials/invoices/add"
                >
                    <Plus size={28} />
                </IconButton>
            </Footer>
            <InvoicesMobileFiltersDialog
                isVisible={invoicesMobileFiltersDialog.visible}
                onClose={invoicesMobileFiltersDialog.onClose}
            />
        </>
    );
};

InvoicesPageMobileFooter.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
};

InvoicesPageMobileFooter.defaultProps = {
    searchValue: '',
    onSearch: PropTypes.emptyFunc,
};

export default InvoicesPageMobileFooter;
