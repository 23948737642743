import React from 'react';
import { ComingSoon } from 'components';

function WidgetIntegration() {
    return (
        <ComingSoon />
    );
}

export default WidgetIntegration;
