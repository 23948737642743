/* eslint-disable import/prefer-default-export */
import * as ACCESS_PROFILES_TYPES from '../../actions/access/actionTypes';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    showAll: false,
    isLoading: false,
    itemsCount: 0,
    pagesCount: 0,
    itemsPerPage: 10,
};

export const accessProfilesListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (ACCESS_PROFILES_TYPES.GET_ACCESS_PROFILES_RESOLVED): {
        const items = payload ?? [];
        return {
            ...state,
            items,
            itemsCount: items.length,
            isLoading: false,
        };
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_CLEAR): {
        return INITIAL_STATE;
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: false,
        };
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_SHOW_ALL): {
        return {
            ...state,
            page: 0,
            showAll: true,
        };
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (ACCESS_PROFILES_TYPES.ACCESS_PROFILES_SEARCH_SET): {
        const { search } = payload;

        return {
            ...state,
            search,
        };
    }
    case (ACCESS_PROFILES_TYPES.GET_ACCESS_PROFILES_TRIGGER): {
        return {
            ...state,
            isLoading: true,
        };
    }
    case (ACCESS_PROFILES_TYPES.GET_ACCESS_PROFILES_REJECTED): {
        return {
            ...state,
            isLoading: false,
        };
    }
    default:
        return state;
    }
};
