import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';

import Switch from '../../../Switch/Switch';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './BundleTrialFormRow.module.scss';

const BundleTrialFormRow = (props) => {
    const {
        values,
        validation,
        onToggleTrial,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <Row>
            <Col
                xs={12}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <InputGroup className="d-flex flex-wrap-reverse align-items-center">
                        <Switch
                            checked={values.isTrial}
                            className={styles.trial}
                            onChange={onToggleTrial}
                        />
                        <InputGroup.Append>
                            <Form.Label className={styles.trialLabel}>
                                {t('addOrEdit.form.fields.trial.label')}
                            </Form.Label>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.isTrial?.isInvalid,
                        })}
                    >
                        {validation?.isTrial?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

BundleTrialFormRow.propTypes = {
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onToggleTrial: PropTypes.func,
};

BundleTrialFormRow.defaultProps = {
    validation: null,
    onToggleTrial: emptyFunc,
};

export default BundleTrialFormRow;
