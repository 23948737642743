import moment from 'moment';
import * as TYPES from '../../actions/access/actionTypes';

const INITIAL_STATE = {
    item: null,
    isLoading: false,
    isSavingProcess: false,
    date: {
        period: 'day',
        dateFrom: moment().startOf('day'),
        dateTo: moment().endOf('day'),
    },
};

export const accessLogsStatisticsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case TYPES.GET_ACCESS_STATISTICS_RESOLVED: {
        const { gate } = payload;
        return {
            ...state,
            item: gate,
            isLoading: false,
        };
    }
    case TYPES.GET_ACCESS_STATISTICS_TRIGGER: {
        const { silent } = payload ?? {};
        return {
            ...state,
            isLoading: !silent ?? true,
        };
    }
    case TYPES.GET_ACCESS_STATISTICS_REJECTED: {
        return {
            ...state,
            isLoading: false,
        };
    }
    case (TYPES.ACCESS_LOGS_STATISTICS_SELECTED_DATE_SET): {
        const { dateFrom, dateTo, period } = payload;

        return {
            ...state,
            date: {
                period,
                dateFrom,
                dateTo,
            },
        };
    }
    default:
        return state;
    }
};
