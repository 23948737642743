import React from 'react';

import * as styles from './Header.module.scss';
import Typography from '../Typography/Typography';

const Header = (props) => (
    <Typography {...props} className={styles.header} data-testid="header" />
);

Header.propTypes = Typography.propTypes;

Header.defaultProps = Typography.defaultProps;

export default Header;
