import {
    call, put, select, takeEvery,
} from 'redux-saga/effects';
import { get, isArray } from 'lodash';
import moment from 'moment';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { formatPrice } from 'helpers/formatters/formatPrice';
import axios from '../../../services/axios';
import SubscriptionDataTransformations from '../../../services/subscriptionDataTransformations';

import * as actionTypes from '../../actions/actionTypes';
import * as SUBSCRIPTIONS_ACTIONS from '../../actions/subscriptions';
import * as SUBSCRIPTIONS_SELECTORS from '../../selectors/subscriptions';

import {
    showToast,
    setSoldIsDataLoaded,
    setSoldItemsCount,
    setSoldPagesCount,
    setSoldSubscriptionsList,
    setActiveCount,
    setTotalMonthly,
    setTotalAnnual,
    updateSubscriptionPaymentInfo,
    updateSubscriptionUserInfo,
    setSoldSubscriptionBookings,
    setCancelSoldSubscriptionEndDate,
    toggleCancelSoldSubscriptionModal,
    toggleCancelSoldSubscriptionWithBookingsModal,
    resetCancelSoldSubscription,
    getSoldSubscriptionsList as getSoldSubscriptionsListAction,
    setSellSubscriptionData,
    resetSellSubscription,
    setSubscriptionUsagesList,
    resetEditSubscriptionUsages,
    showBookingsToCancelingModal,
    resetChangeSoldSubscription,
} from '../../actions';
import * as TOAST_ACTIONS from '../../actions/toast';

import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_SUBSCRIPTION_PAUSE_ID_ROUTE,
    API_ADMIN_SUBSCRIPTION_RESUME_ID_ROUTE,
    API_ADMIN_SUBSCRIPTION_PAY_PRO_PAYMENT_LINK_RESEND_ID_ROUTE,
    API_ADMIN_SUBSCRIPTION_CHANGE_ID_ROUTE,
    API_ADMIN_SUBSCRIPTION_DELETE_ID_ROUTE,
    API_ADMIN_SUBSCRIPTION_ID_BOOKINGS_ROUTE,
    API_ADMIN_REQEST_EXPORT_SOLD_SUBSCRIBTIONS,
    API_ADMIN_SUBSCRIPTION_CHECK_ID_ROUTE,
} from '../../../const/API_URL';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

export function* getSoldSubscriptionsList(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const {
        currentPage, searchValue, idFilter, statusFilter,
    } = yield select(
        SUBSCRIPTIONS_SELECTORS.soldSubscriptionListsQuerySelector,
    );

    yield put(setSoldIsDataLoaded(false));

    try {
        const request = () => axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/subscription/sold-list',
            headers: defaultHeaders,
            params: {
                page: currentPage,
                search: searchValue,
                idFilter: isArray(idFilter) ? idFilter : [idFilter],
                statusFilter: isArray(statusFilter) ? statusFilter : [statusFilter],
            },
        });

        const response = yield call(request);
        const {
            data: {
                result: {
                    itemsCount,
                    pagesCount,
                    subscriptions,
                    activeCount,
                    totalMonthly,
                    totalAnnual,
                },
            },
        } = response;
        const parsedData = SubscriptionDataTransformations.transformSoldSubscriptionsResponse(
            subscriptions,
        );

        yield put(setSoldPagesCount(pagesCount));
        yield put(setSoldItemsCount(itemsCount));
        yield put(setSoldSubscriptionsList(parsedData));
        yield put(setActiveCount(activeCount));
        yield put(
            setTotalAnnual(formatPrice.toEuroWithComma({ amount: totalAnnual })),
        );
        yield put(
            setTotalMonthly(formatPrice.toEuroWithComma({ amount: totalMonthly })),
        );
    } catch (err) {
        yield put(
            showToast({
                message: 'subscriptionSoldListError',
                appearance: 'error',
            }),
        );
    } finally {
        yield put(setSoldIsDataLoaded(true));
    }
}

export function* getSellSubscriptionData(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
    /*
            The reason why we have a separate request instead of triggering getSubscriptionsList saga
            is we need to get all subscriptions ignoring the pages count and search filter
         */
        const subscriptionsRequest = () => axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/subscription/list',
            headers: defaultHeaders,
            params: {
                page: 0,
            },
        });

        const clientsRequest = () => axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/company/clients',
            headers: defaultHeaders,
        });

        const subscriptionsResponse = yield call(subscriptionsRequest);
        const clientsResponse = yield call(clientsRequest);
        const subscriptions = get(
            subscriptionsResponse,
            'data.result.subscriptions',
            [],
        );
        const clients = get(clientsResponse, 'data.result', []);
        /*
            In this case there is no need to parse subscriptions for them to be form like
        */
        yield put(setSellSubscriptionData(clients, subscriptions));
    } catch (err) {
        yield put(
            showToast({
                message: get(err, 'response.data.result.message', ''),
                appearance: 'error',
                isCustom: true,
            }),
        );
        yield put(
            showToast({
                message: 'subscriptionSellModalReopen',
                appearance: 'warning',
            }),
        );
        resetSellSubscription();
    }
}

export function* sellSubscription(action) {
    const { payload: dataPayload } = action;
    const {
        subscription, onFinal, onDone, payload,
    } = dataPayload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const request = () => axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/subscription/sell/${subscription}`,
            headers: defaultHeaders,
            data: payload,
        });

        yield call(request);

        yield put(
            showToast({
                message: 'subscriptionSellSuccess',
                appearance: 'success',
            }),
        );
        yield put(resetSellSubscription());
        onDone?.();
        yield put(getSoldSubscriptionsListAction());
    } catch (err) {
        const errMessage = get(err, 'response.data.result.message', '');

        if (errMessage) {
            yield put(
                showToast({
                    message: get(err, 'response.data.result.message', ''),
                    appearance: 'error',
                    isCustom: true,
                }),
            );
        } else {
            const errors = get(err, 'response.data.result.errors', {});
            const toasts = Object.entries(errors).map(([key, value]) => ({
                message: `${key}: ${value}`,
                appearance: 'error',
                isCustom: true,
            }));

            yield put(showToast(toasts));
        }
    } finally {
        onFinal?.();
    }
}

export function* changeSoldSubscription(action) {
    const { payload } = action;
    const {
        soldSubscriptionId, newSubscriptionId, onSuccess, isConfirmed,
    } = payload;
    const data = new FormData();
    data.append('subscription', newSubscriptionId);

    const defaultHeaders = yield getDefaultHeaders();

    // TODO: temp workaround, remove on backend update
    // -1 is temporary hack for the correct date calculation on the backend
    const startDate = moment().startOf('month').add(1, 'M').unix() - 1;

    if (!isConfirmed) {
        try {
            const subscriptionBookingsRequest = () => axios.request({
                _action: action,
                method: 'GET',
                url: `/api/admin/subscription/${soldSubscriptionId}/bookings`,
                params: {
                    startDate,
                },
                headers: defaultHeaders,
            });

            const { data } = yield call(subscriptionBookingsRequest);

            if (data.result?.length) {
                yield put(
                    showBookingsToCancelingModal({
                        bookings: data.result,
                        newSubscriptionId,
                    }),
                );

                if (onSuccess) {
                    yield call(onSuccess);
                }

                return;
            }
        } catch (err) {
            yield put(
                showToast({
                    message: get(err, 'response.data.result.message', ''),
                    appearance: 'error',
                    isCustom: true,
                }),
            );

            return;
        }
    }

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SUBSCRIPTION_CHANGE_ID_ROUTE({
                oldSubscriptionId: soldSubscriptionId,
            }),
            headers: defaultHeaders,
            data,
        });

        const { data: resData } = res;
        const { code } = resData;

        if (code === HTTP_STATUS_CODE.OK) {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.change.success',
                    appearance: 'success',
                }),
            );

            yield put(getSoldSubscriptionsListAction());
            yield put(resetChangeSoldSubscription());

            if (onSuccess) {
                yield call(onSuccess);
            }
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                showToast({
                    message: get(
                        error,
                        'response.data.result.message',
                        'soldSubscriptions.change.error',
                    ),
                    appearance: 'error',
                }),
            );
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export function* toggleSubscriptionPaymentStatus(action) {
    const { subscriptionId, paymentId } = action;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const request = () => axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/subscription/payment/toggle-status/${paymentId}`,
            headers: defaultHeaders,
        });

        const {
            data: {
                result: {
                    paymentStatus,
                    subscription_status: subscriptionStatus,
                    unpaid_counter: unpaidCounter,
                    is_manually_set: isManuallySet,
                },
            },
        } = yield call(request);

        yield put(
            updateSubscriptionPaymentInfo({
                subscriptionId,
                paymentId,
                paymentStatus,
                subscriptionStatus,
                unpaidCounter,
                isManuallySet,
            }),
        );
    } catch (err) {
        yield put(
            showToast({
                message: get(err, 'response.data.result.message', ''),
                appearance: 'error',
                isCustom: true,
            }),
        );
    }
}

export function* toggleUserLock(action) {
    const { clientId } = action;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const request = () => axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/client/toggle-block/${clientId}`,
            headers: defaultHeaders,
        });

        const {
            data: {
                result: { blockValue },
            },
        } = yield call(request);

        yield put(
            updateSubscriptionUserInfo({
                clientId,
                clientIsBlocked: blockValue,
            }),
        );
    } catch (err) {
        yield put(
            showToast({
                message: get(err, 'response.data.result.message', ''),
                appearance: 'error',
                isCustom: true,
            }),
        );
    }
}

export function* cancelSoldSubscription(action) {
    const { endDate, cancelBookings, notNotifyClient } = action;
    const defaultHeaders = yield getDefaultHeaders();
    const { subscriptionId, paymentType } = yield select(
        SUBSCRIPTIONS_SELECTORS.cancelSoldSubscriptionSelector,
    );

    /*
        cancelBookings = false by default comes from the first
        CancelSubscriptionModal where you select the subscription end date.
        At that point we know nothing about the active booking for the particular subscription.
        So we need to request the bookings for this particular subscription and then:
        1. If the list of the future bookings (after endDate) is empty - proceed with the subscription cancellation
        2. If the list is not empty - show the next modal
    */

    if (!cancelBookings) {
        try {
            const subscriptionBookingsRequest = () => axios.request({
                _action: action,
                method: 'GET',
                url: `/api/admin/subscription/${subscriptionId}/bookings`,
                params: {
                    // TODO: temp workaround, remove on backend update
                    // -1 is temporary hack for the correct date calculation on the backend
                    startDate: endDate - 1,
                },
                headers: defaultHeaders,
            });

            const {
                data: { result: subscriptionBookings },
            } = yield call(subscriptionBookingsRequest);

            yield put(setSoldSubscriptionBookings(subscriptionBookings));
            yield put(setCancelSoldSubscriptionEndDate(endDate));

            if (subscriptionBookings.length) {
                yield put(
                    toggleCancelSoldSubscriptionModal({
                        showCancelModal: false,
                        subscriptionId: null,
                        subscriptionStartDate: null,
                        nextSubscriptionDate: null,
                        nextSubscriptionPrice: null,
                        payments: [],
                        nextDebitDate: null,
                        subscriptionStatus: null,
                        paymentType: null,
                    }),
                );
                yield put(
                    toggleCancelSoldSubscriptionWithBookingsModal({
                        showCancelWithBookingsModal: true,
                        subscriptionId,
                        paymentType,
                    }),
                );

                // interrupt further saga run
                return;
            }
        } catch (err) {
            yield put(
                showToast({
                    message: get(err, 'response.data.result.message', ''),
                    appearance: 'error',
                    isCustom: true,
                }),
            );

            return;
        }
    }

    try {
        const payload = SubscriptionDataTransformations.createCancelSubscriptionPayload({
            endDate,
            cancelBookings,
            notNotifyClient,
        });

        const cancelSubscriptionRequest = () => axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/subscription/cancel/${subscriptionId}`,
            headers: defaultHeaders,
            data: payload,
        });

        const {
            data: { code },
        } = yield call(cancelSubscriptionRequest);

        if (code === 200) {
            yield put(
                showToast({
                    message: 'subscriptionCancelSuccess',
                    appearance: 'success',
                }),
            );

            yield put(resetCancelSoldSubscription());
            yield put(getSoldSubscriptionsListAction());
        }
    } catch (err) {
        yield put(
            showToast({
                message: get(err, 'response.data.result.message', ''),
                appearance: 'error',
                isCustom: true,
            }),
        );
    }
}

export function* getSubscriptionUsagesList(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { paymentStartDate, subscriptionId } = yield select(
        SUBSCRIPTIONS_SELECTORS.editSubscriptionUsagesSelector,
    );

    try {
        const request = () => axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/subscription/usages-list/${subscriptionId}`,
            headers: defaultHeaders,
            params: {
                startDate: paymentStartDate,
            },
        });

        const {
            data: { result },
        } = yield call(request);
        const usages = SubscriptionDataTransformations.parseSoldSubscriptionUsages(
            result[0],
        );
        const formInitialState = SubscriptionDataTransformations.createSoldSubscriptionUsagesIntoFormData(
            usages,
        );

        yield put(setSubscriptionUsagesList(usages, formInitialState));
    } catch (err) {
        yield put(resetEditSubscriptionUsages());
        yield put(
            showToast({
                message: get(err, 'response.data.result.message', ''),
                appearance: 'error',
                isCustom: true,
            }),
        );
    }
}

export function* getSubscriptionPeriodsUsages(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { subscriptionId } = yield select(
        SUBSCRIPTIONS_SELECTORS.editSubscriptionUsagesSelector,
    );

    const { startDate } = action;

    const request = () => axios.request({
        _action: action,
        method: 'GET',
        url: `/api/admin/subscription/periods/${subscriptionId}`,
        headers: defaultHeaders,
        params: {
            startDate,
        },
    });

    try {
        const {
            data: { result },
        } = yield call(request);

        const usages = SubscriptionDataTransformations.parseSoldSubscriptionUsages(result);

        yield put(setSubscriptionUsagesList(usages, []));
    } catch (err) {
        yield put(resetEditSubscriptionUsages());
        yield put(
            showToast({
                message: get(err, 'response.data.result.message', ''),
                appearance: 'error',
                isCustom: true,
            }),
        );
    }
}

function* resendSoldSubscriptionPayment(action) {
    const { payload } = action;
    const { soldSubscriptionId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SUBSCRIPTION_PAY_PRO_PAYMENT_LINK_RESEND_ID_ROUTE({
                soldSubscriptionId,
            }),
            headers: defaultHeaders,
        });

        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.resendPaymentLink.success',
                    appearance: 'success',
                }),
            );
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.resendPaymentLink.error',
                    appearance: 'error',
                }),
            );
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* pauseSoldSubscriptionItem(action) {
    const { payload } = action;
    const {
        soldSubscriptionId, data, onSuccess, onError,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SUBSCRIPTION_PAUSE_ID_ROUTE({ soldSubscriptionId }),
            headers: defaultHeaders,
            data,
        });

        const { data: resData } = res;
        const { code } = resData;

        if (code === HTTP_STATUS_CODE.OK) {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.pause.success',
                    appearance: 'success',
                }),
            );
            if (onSuccess) {
                yield call(onSuccess, { soldSubscriptionId });
            }
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.pause.error',
                    appearance: 'error',
                }),
            );
            if (onError) {
                yield call(onError, { soldSubscriptionId });
            }
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* resumeSoldSubscriptionItem(action) {
    const { payload } = action;
    const {
        soldSubscriptionId, data, onSuccess, onError,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SUBSCRIPTION_RESUME_ID_ROUTE({ soldSubscriptionId }),
            headers: defaultHeaders,
            data,
        });

        const { data: resData } = res;
        const { code } = resData;

        if (code === HTTP_STATUS_CODE.OK) {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.resume.success',
                    appearance: 'success',
                }),
            );
            if (onSuccess) {
                yield call(onSuccess);
            }
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.resume.error',
                    appearance: 'error',
                }),
            );
            if (onError) {
                yield call(onError);
            }
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* getSoldSubscriptionsItemBookings(action) {
    const { payload } = action;
    const {
        startDate, soldSubscriptionId, onSuccess, onFail,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_SUBSCRIPTION_ID_BOOKINGS_ROUTE({ soldSubscriptionId }),
            params: { startDate },
            headers: defaultHeaders,
        });

        const { data } = res;
        const { code } = data;

        if (code === HTTP_STATUS_CODE.OK) {
            const { result } = data;
            const bookings = camelize(result).map((booking) => ({
                id: booking.id,
                date: booking.datetime,
                isRecurring: booking.isRecurring,
                objectName: booking.solariumName,
            }));

            if (onSuccess) {
                yield call(onSuccess, {
                    soldSubscriptionId,
                    bookings,
                });
            }
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            if (onFail) {
                yield call(onFail, { soldSubscriptionId });
            }
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* deleteSoldSubscriptionsItem(action) {
    const { soldSubscriptionId, onSuccess, onFail } = action.payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.DELETE,
            url: API_ADMIN_SUBSCRIPTION_DELETE_ID_ROUTE({ soldSubscriptionId }),
            headers: defaultHeaders,
        });

        const { code } = res.data;

        if (code === HTTP_STATUS_CODE.OK) {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.delete.success',
                    appearance: 'success',
                }),
            );
            yield put(SUBSCRIPTIONS_ACTIONS.getSoldSubscriptionsList());

            if (onSuccess) {
                yield call(onSuccess, { soldSubscriptionId });
            }
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                showToast({
                    message: get(
                        error,
                        'response.data.result.message',
                        'soldSubscriptions.delete.error',
                    ),
                    appearance: 'error',
                }),
            );

            if (onFail) {
                yield call(onFail, { soldSubscriptionId });
            }
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* reqestExportSoldSubscribtions(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            method: HTTP_METHOD.GET,
            url: API_ADMIN_REQEST_EXPORT_SOLD_SUBSCRIBTIONS,
            headers: defaultHeaders,
        });
        const { code } = res.data;

        if (code === HTTP_STATUS_CODE.OK) {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'soldSubscriptions.export.success',
                    appearance: 'success',
                }),
            );
        }
    } catch (error) {
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
            });
        }
    }
}

function* checkSubscription(action) {
    const { payload } = action;
    const {
        subscriptionId, formData, onSuccess, onFail, onFinal,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SUBSCRIPTION_CHECK_ID_ROUTE({ subscriptionId }),
            headers: defaultHeaders,
            data: formData,
        });

        const { data } = res;
        const { code, result } = data;

        if (code === HTTP_STATUS_CODE.OK) {
            if (onSuccess) {
                yield call(onSuccess, { result });
            }
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            if (onFail) {
                yield call(onFail, { subscriptionId });
            }
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        onFinal?.();
    }
}

export const soldSubscriptionsSaga = [
    takeEvery(actionTypes.GET_SOLD_SUBSCRIPTIONS_LIST, getSoldSubscriptionsList),
    takeEvery(actionTypes.SELL_SUBSCRIPTION, sellSubscription),
    takeEvery(actionTypes.CHANGE_SOLD_SUBSCRIPTION, changeSoldSubscription),
    takeEvery(actionTypes.CANCEL_SOLD_SUBSCRIPTION, cancelSoldSubscription),
    takeEvery(
        actionTypes.GET_SUBSCRIPTION_USAGES_LIST,
        getSubscriptionUsagesList,
    ),
    takeEvery(
        actionTypes.GET_SUBSCRIPTION_PERIODS_USAGES,
        getSubscriptionPeriodsUsages,
    ),
    takeEvery(actionTypes.TOGGLE_USER_LOCK, toggleUserLock),
    takeEvery(
        actionTypes.SOLD_SUBSCRIPTIONS_ITEM_RESEND_PAYMENT,
        resendSoldSubscriptionPayment,
    ),
    takeEvery(
        actionTypes.SOLD_SUBSCRIPTIONS_ITEM_PAUSE,
        pauseSoldSubscriptionItem,
    ),
    takeEvery(
        actionTypes.SOLD_SUBSCRIPTIONS_ITEM_RESUME,
        resumeSoldSubscriptionItem,
    ),
    takeEvery(
        actionTypes.SOLD_SUBSCRIPTIONS_ITEM_REMOVE,
        deleteSoldSubscriptionsItem,
    ),
    takeEvery(
        actionTypes.SOLD_SUBSCRIPTIONS_ITEM_BOOKINGS_GET,
        getSoldSubscriptionsItemBookings,
    ),
    takeEvery(
        actionTypes.SOLD_SUBSCRIBTIONS_REQEST_EXPORT,
        reqestExportSoldSubscribtions,
    ),
    takeEvery(SUBSCRIPTIONS_ACTIONS.checkSubscription, checkSubscription),
];
