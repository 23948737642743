import React from 'react';
import { createRoot } from 'react-dom/client';

import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
// sentry import must be before App import
import { ErrorBoundary } from '@sentry/react';
import { Button } from 'components/Common/Button';
import sentry from './services/sentry';

import App from './App';

import './styles/app.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import './services/i18n';

import { store, persistor } from './store/createStore';

sentry.init();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ErrorBoundary
        fallback={(
            ({ error, componentStack }) => (
                <div style={{
                    display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', pointerEvents: 'all',
                }}
                >
                    <h1>You have encountered an error</h1>
                    <br />
                    <span>{error.toString()}</span>
                    <br />
                    <span>{componentStack}</span>
                    <br />
                    <Button
                        onClick={() => window.location.reload()}
                    >
                        Click here to reset
                    </Button>
                </div>
            )
        )}
        showDialog
    >
        <ToastProvider autoDismiss autoDismissTimeout={6000}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </ToastProvider>
    </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
