import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from './scenes';

function Services() {
    return (
        <Switch>
            {
                routes.map(({ component, exact, path }) => (
                    <Route
                        key={path}
                        component={component}
                        exact={exact}
                        path={path}
                    />
                ))
            }

            <Redirect to={routes[0].path} />
        </Switch>
    );
}

export default Services;
