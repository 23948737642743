import { FIRST_LEVEL_NAV_HEIGHT as DESKTOP_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { FIRST_LEVEL_NAV_HEIGHT as MOBILE_HEIGHT } from 'const/navigation/MOBILE_NAV';
import { useMobile } from 'hooks/useMobile';
import React from 'react';
import { Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

function CompanyBadge({ size = 48, name, logo }) {
    const isMobile = useMobile();

    return (
        <Col
            style={{ height: isMobile ? MOBILE_HEIGHT : DESKTOP_HEIGHT }}
        >
            <div className="d-flex flex-row align-items-center h-100">
                {logo ? (
                    <img
                        className="d-block rounded-circle"
                        src={logo}
                        alt=""
                        style={{
                            width: size,
                            minWidth: size,
                            height: size,
                        }}
                    />
                ) : (
                    <Skeleton
                        width={size}
                        height={size}
                        circle
                    />
                )}

                {name ? (
                    <p
                        className="ml-3 mb-0 font-weight-bold d-none d-lg-block w-100 text-truncate"
                        style={{
                            lineHeight: '21px',
                            fontSize: 17,
                        }}
                    >
                        {name}
                    </p>
                ) : (
                    <div className="ml-3 mb-0 d-none d-lg-block">
                        <Skeleton
                            width={150}
                            height={17}
                        />
                    </div>
                )}
            </div>
        </Col>
    );
}

export default CompanyBadge;
