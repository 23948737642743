import React, {
    useState, useMemo, useRef, useLayoutEffect,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { formatPrice } from 'helpers/formatters/formatPrice';
import { getFullName } from '../../helpers/services/getFullName';

import { BookingPaymentType, Feedback } from '..';
import { Tooltip } from '../Common/Tooltip';

import {
    Clock, FromMobile, Time, FromWidget, FromAdmin,
} from '../Icon/Icon';

import * as styles from './Booking.module.scss';

const Booking = (props) => {
    const {
        booking,
        style,
        onClick,
        quartersBlocks,
        extraBlocks,
        extraTimeInBlocks,
    } = props;

    const ref = useRef();

    const [isCompact, setCompact] = useState(false);

    const bookingDynamicClasses = {
        [styles.compact]: isCompact,
        [styles.paidAfterwards]: !booking?.payment.isInProgress && !booking?.payment.paid,
        [styles.paid]: !booking?.payment.isInProgress && booking?.payment.paid,
        [styles.inProgress]: booking?.payment.isInProgress,
        [styles.clickable]: !!onClick,
        [styles.notPresent]: booking?.notPresent,
    };

    const serviceName = `${booking?.service?.name}: ${booking?.time?.length}`;

    const containerHeight = (quartersBlocks - (extraBlocks - extraTimeInBlocks)) * 60 || 0;

    const MadeFromIcon = useMemo(() => {
        if (booking?.madeFromWidget) {
            return FromWidget;
        }

        if (booking?.madeFromApp) {
            return FromMobile;
        }

        return FromAdmin;
    }, [booking?.madeFromApp, booking?.madeFromWidget]);

    useLayoutEffect(() => {
        setCompact(ref.current.clientHeight < 120);
    }, [ref]);

    return (
        <div
            style={style}
            onClick={onClick}
        >
            <div
                className={classNames(
                    'd-flex flex-column',
                    styles.container,
                    bookingDynamicClasses,
                )}
                style={{ height: containerHeight }}
                data-testid={`data-test-booking-${booking?.id}`}
            >
                <div
                    ref={ref}
                    className={classNames(
                        'd-flex flex-column h-100 w-100 overflow-hidden',
                        styles.content,
                    )}
                >
                    <Tooltip
                        tooltip={serviceName}
                        placement="top-start"
                        className={styles.tooltip}
                    >
                        <div>
                            {booking ? (
                                <div className="d-flex justify-content-between align-items-center position-relative">
                                    <div
                                        className="d-flex align-items-center"
                                        style={{
                                            width: `calc(100% - ${booking.payment.type ? '0.5rem - 16px' : '0px'} - ${booking.recurrence.enabled ? '0.5rem - 14px' : '0px'})`,
                                        }}
                                    >
                                        <div className={styles.madeFromIconWrapper}>
                                            <MadeFromIcon />
                                        </div>

                                        <p
                                            className={classNames(
                                                'font-weight-bold font-size-14 m-0',
                                                styles.serviceName,
                                            )}
                                            data-testid="data-test-service-name"
                                        >
                                            {serviceName}
                                        </p>
                                    </div>

                                    <div className="position-relative d-flex align-items-center">
                                        {booking.payment.type && (
                                            <BookingPaymentType
                                                type={booking.payment.type}
                                                className="text-muted ml-2"
                                                width={16}
                                            />
                                        )}

                                        {booking.recurrence.enabled && (
                                            <Time
                                                className="text-muted ml-2"
                                                width={14}
                                                height={14}
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <Skeleton width="100%" height={18} />
                            )}

                            <div className="d-flex">
                                <span
                                    className={classNames(
                                        'font-weight-bold font-size-14 m-0 text-dark-gray mt-1',
                                        styles.clientName,
                                    )}
                                    data-testid="data-test-client-name"
                                >
                                    {booking && getFullName({ client: booking?.client })}
                                </span>
                            </div>
                        </div>
                    </Tooltip>

                    {!isCompact && (
                        <>
                            <div className="d-flex flex-wrap mt-1 justify-content-between">
                                {booking ? (
                                    <>
                                        <p className="text-muted font-size-14 m-0 mr-2">{`${moment(booking.time.from).format('HH:mm')} - ${moment(booking.time.to).format('HH:mm')}`}</p>

                                        <p className="text-muted font-size-14 m-0 mr-2" data-testid="data-test-length">
                                            <Clock
                                                width={14}
                                                height={14}
                                                strokeWidth={2.5}
                                                className="position-relative mr-1"
                                                style={{ top: -1 }}
                                            />
                                            {`${moment(booking.time.to).diff(moment(booking.time.from), 'minute')} min`}
                                        </p>

                                        <p className="text-muted font-size-14 m-0" data-testid="data-test-price">
                                            {formatPrice.toEuroWithComma({ amount: booking.payment.finalCost })}
                                        </p>
                                    </>
                                ) : _.times(3, (i) => (
                                    <Skeleton key={`detail-${i}`} width={50} height={16} />
                                ))}
                            </div>

                            <div className="d-flex flex-wrap mt-1 justify-content-between">
                                {booking ? (
                                    <>
                                        <span className="d-inline-block text-dark font-size-14 font-weight-600 mr-2" data-testid="data-test-contact-number">
                                            {booking.client.contactNumber}
                                        </span>
                                        <span className="text-muted font-size-14" data-testid="data-test-product-name">
                                            {booking?.product?.name}
                                        </span>
                                    </>
                                ) : _.times(2, (i) => (
                                    <Skeleton key={`detail-${i}`} width={75} height={16} />
                                ))}
                            </div>

                            {booking?.feedback && (
                                <Feedback
                                    feedback={booking.feedback}
                                    size={quartersBlocks <= 3 ? 24 : 48}
                                    classNames="d-flex flex-grow-1 mt-2"
                                />
                            )}
                        </>
                    )}
                </div>

                {(!isNaN(extraBlocks) && extraBlocks !== 0) && (
                    <div
                        className={classNames(styles.extraTimeContainer, bookingDynamicClasses)}
                        style={{ height: extraTimeInBlocks * 60 || 0 }}
                    />
                )}
            </div>
        </div>
    );
};

export default Booking;
