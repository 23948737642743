import {
    put, select, call, takeEvery,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import camelize from 'camelize';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'const/time/DEFAULT_TIME_FORMAT';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import sentry from '../../services/sentry';

// Actions
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';

// Utils
import axios from '../../services/axios';
import { convertWorkBreaks } from '../../helpers';

export function* getObjects(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/product/basic`,
            headers: defaultHeaders,
        });

        const objects = Object.values(resp.data.result).map((
            {
                id,
                name,
                disabled_days: disabledDays,
                work_breaks: workBreaks,
                time_blocks: timeBlocks,
                has_own_schedule: hasOwnSchedule,
                special_days: specialDays,
                working_days: workingDays,
                services,
                photo_url: image,
                future_booking: futureBooking,
            },
        ) => ({
            id,
            name,
            image,
            disabledDays: disabledDays.map((day) => (day + 1) % 7),
            workBreaks,
            timeBlocks,
            hasOwnSchedule,
            workingDays,
            specialDays: specialDays.reduce((acc, day) => {
                const currentDay = moment(day.date, 'DD/MM');
                const openingTime = moment.utc(day.opening_time);
                const closingTime = moment.utc(day.closing_time);
                return {
                    ...acc,
                    [currentDay.format(DEFAULT_DATE_FORMAT)]: {
                        isWorkingDay: day.is_working_day,
                        from: openingTime.format('HH:mm'),
                        to: closingTime.format('HH:mm'),
                    },
                };
            }, {}),
            services: camelize(services).map((service) => ({
                id: service.id,
                name: service.name,
                description: service.description,
                length: service.minutes,
                price: service.price,
                category: service.category,
                bookableType: service.bookableType,
                minutes: service.minutes,
                bookingFrequency: service.bookingFrequency,
                subOptions: service.subOptions ?? [],
                restrictUnpaidBookings: service.restrictUnpaidBookings,
            })),
            futureBooking,
        }));

        yield put(actions.getObjectsSucceeded(objects));
    } catch (err) {
        yield put(actions.getObjectsFailed());
    }
}

export function* addObject(action) {
    const { object } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const hasOwnSchedule = object?.hasOwnSchedule && Object.values(object.schedule).some(({ from, to }) => from && to);
        const hasWorkBreaks = object?.hasWorkBreaks && Object.values(object.workBreaks).some((array) => !!array.length);

        const data = new FormData();
        data.append('image', object.image);
        data.append('name', object.name);
        data.append('description', object.description);
        data.append('maintenanceMode', Number(object.isMaintenanceMode));
        object.services.map(({ id }, i) => data.append(`services[${i}]`, id));
        data.append('hasOwnSchedule', (hasOwnSchedule ? 1 : 0).toString());
        if (hasOwnSchedule) {
            Object.entries(object.schedule).map(([day, schedule]) => {
                if (!schedule || !schedule.from || !schedule.to) {
                    return;
                }

                data.append(`schedule[${day.toLowerCase()}][from]`, schedule.from.unix());
                data.append(`schedule[${day.toLowerCase()}][to]`, schedule.to.unix());
            });
        }
        data.append('hasWorkBreaks', (hasWorkBreaks ? 1 : 0).toString());
        if (hasWorkBreaks) {
            Object.entries(object.workBreaks).map(([day, workBreaks]) => {
                if (!workBreaks) {
                    return;
                }

                workBreaks.map(({ from, to }, i) => {
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][from]`, from.unix());
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][to]`, to.unix());
                });
            });
        }
        if (object.futureBooking) {
            data.append('futureBookingsInDays', object.futureBooking);
        }

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/add/object`,
            data,
            headers: defaultHeaders,
        });

        const result = camelize(resp.data.result);

        object.id = result.id;
        object.disabledDays = [];
        object.timeBlocks = [];

        const newObj = { ...object };
        // convert {Moday: [obj, obj], Tuesday: [obj]} => [obj, obj]
        if (Object.keys(newObj.workBreaks).length > 0) {
            newObj.workBreaks = convertWorkBreaks(newObj.workBreaks);
        }

        newObj.workingDays = newObj?.hasOwnSchedule && Object.entries(newObj.schedule).reduce((breaks, [day, item]) => {
            if (item?.from && item?.to) {
                return {
                    ...breaks,
                    [day.toLowerCase()]: {
                        from: item.from.format('HH:mm'),
                        to: item.to.format('HH:mm'),
                    },
                };
            }
            return breaks;
        }, {});

        yield put(actions.addObjectSucceeded(newObj));

        yield put(actions.getObjects());

        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;

            const customErrorWorkBreak = Object.keys(errors).find((error) => error.toLowerCase().includes('workbreaks'));
            const customErrorSchedule = Object.keys(errors).find((error) => error.toLowerCase().includes('schedule'));

            yield put(actions.addObjectFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    image: errors.image,
                    name: errors.name,
                    description: errors.description,
                    isMaintenanceMode: errors.maintenanceMode,
                    services: errors.services,
                    schedule: errors.hasOwnSchedule || errors.schedule || errors[customErrorSchedule],
                    workBreaks: errors.hasWorkBreaks || errors.workBreaks || errors[customErrorWorkBreak],
                },
            });
            return;
        } if (err.response?.data) {
            const { message } = err.response.data.result;

            yield put(actions.addObjectFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }
        sentry.error(err);

        yield put(actions.addObjectFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* editObject(action) {
    const { object } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const hasOwnSchedule = object?.hasOwnSchedule && Object.values(object.schedule).some(({ from, to }) => from && to);
        const hasWorkBreaks = object?.hasWorkBreaks && Object.values(object.workBreaks).some((array) => !!array.length);

        const data = new FormData();
        data.append('productId', object.id);
        data.append('image', object.image);
        data.append('name', object.name);
        data.append('description', object.description);
        data.append('maintenanceMode', Number(object.isMaintenanceMode));
        data.append('timeslotsRecalc', object.isTimeslotRecalc ? 1 : 0);
        object.services.forEach(({ id }, i) => data.append(`services[${i}]`, id));
        data.append('hasOwnSchedule', (hasOwnSchedule ? 1 : 0).toString());
        if (hasOwnSchedule) {
            Object.entries(object.schedule).map(([day, schedule]) => {
                if (!schedule || !schedule.from || !schedule.to) {
                    return;
                }

                data.append(`schedule[${day.toLowerCase()}][from]`, schedule.from.unix());
                data.append(`schedule[${day.toLowerCase()}][to]`, schedule.to.unix());
            });
        }
        data.append('hasWorkBreaks', (hasWorkBreaks ? 1 : 0).toString());
        if (hasWorkBreaks) {
            Object.entries(object.workBreaks).map(([day, workBreaks]) => {
                if (!workBreaks) {
                    return;
                }

                workBreaks.map(({ from, to }, i) => {
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][from]`, from.unix());
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][to]`, to.unix());
                });
            });
        }
        if (object.futureBooking) {
            data.append('futureBookingsInDays', object.futureBooking);
        }

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/edit/object`,
            data,
            headers: defaultHeaders,
        });

        const newObj = { ...object };
        // convert {Moday: [obj, obj], Tuesday: [obj]} => [obj, obj]
        if (Object.keys(newObj.workBreaks).length > 0) {
            newObj.workBreaks = convertWorkBreaks(newObj.workBreaks);
        }

        newObj.workingDays = newObj?.hasOwnSchedule && Object.entries(newObj.schedule).reduce((breaks, [day, item]) => {
            if (item?.from && item?.to) {
                return {
                    ...breaks,
                    [day.toLowerCase()]: {
                        from: item.from.format('HH:mm'),
                        to: item.to.format('HH:mm'),
                    },
                };
            }
            return breaks;
        }, {});

        delete newObj.schedule;

        yield put(actions.editObjectSucceeded(newObj));

        yield put(actions.getObjects());

        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;

            const customErrorWorkBreak = Object.keys(errors).find((error) => error.toLowerCase().includes('workbreaks'));
            const customErrorSchedule = Object.keys(errors).find((error) => error.toLowerCase().includes('schedule'));

            yield put(actions.editObjectFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    image: errors.image,
                    name: errors.name,
                    description: errors.description,
                    isMaintenanceMode: errors.maintenanceMode,
                    services: errors.services,
                    schedule: errors.hasOwnSchedule || errors.schedule || errors[customErrorSchedule],
                    workBreaks: errors.hasWorkBreaks || errors.workBreaks || errors[customErrorWorkBreak],
                },
            });
            return;
        }
        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.editObjectFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.editObjectFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* deleteObject(action) {
    const id = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/delete/${id}`,
            headers: defaultHeaders,
        });

        yield put(actions.deleteObjectSucceeded(id));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.deleteObjectFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.deleteObjectFailed());
        yield call(rejectPromiseAction, action);
    }
}

export const objectsSaga = [
    takeEvery(actionTypes.GET_OBJECTS, getObjects),
    takeEvery(actions.addObject, addObject),
    takeEvery(actions.editObject, editObject),
    takeEvery(actions.deleteObject, deleteObject),
];
