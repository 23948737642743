import React from 'react';

const TableIcon = ({ width = '24', height = '24' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99951 8C5.5518 8 5.99951 7.55228 5.99951 7C5.99951 6.44772 5.5518 6 4.99951 6C4.44723 6 3.99951 6.44772 3.99951 7C3.99951 7.55228 4.44723 8 4.99951 8Z" fill="currentColor" />
        <path d="M8.99951 6C8.44723 6 7.99951 6.44772 7.99951 7C7.99951 7.55228 8.44723 8 8.99951 8H18.9995C19.5518 8 19.9995 7.55228 19.9995 7C19.9995 6.44772 19.5518 6 18.9995 6H8.99951Z" fill="currentColor" />
        <path d="M4.99951 13C5.5518 13 5.99951 12.5523 5.99951 12C5.99951 11.4477 5.5518 11 4.99951 11C4.44723 11 3.99951 11.4477 3.99951 12C3.99951 12.5523 4.44723 13 4.99951 13Z" fill="currentColor" />
        <path d="M8.99951 11C8.44723 11 7.99951 11.4477 7.99951 12C7.99951 12.5523 8.44723 13 8.99951 13H18.9995C19.5518 13 19.9995 12.5523 19.9995 12C19.9995 11.4477 19.5518 11 18.9995 11H8.99951Z" fill="currentColor" />
        <path d="M4.99951 18C5.5518 18 5.99951 17.5523 5.99951 17C5.99951 16.4477 5.5518 16 4.99951 16C4.44723 16 3.99951 16.4477 3.99951 17C3.99951 17.5523 4.44723 18 4.99951 18Z" fill="currentColor" />
        <path d="M8.99951 16C8.44723 16 7.99951 16.4477 7.99951 17C7.99951 17.5523 8.44723 18 8.99951 18H18.9995C19.5518 18 19.9995 17.5523 19.9995 17C19.9995 16.4477 19.5518 16 18.9995 16H8.99951Z" fill="currentColor" />
    </svg>

);

export default TableIcon;
