import React from 'react';
import { WindowedMenuList } from 'react-windowed-select';
import { useDispatch } from 'react-redux';
import { getGatesForSelect } from 'store/actions';

import { styles as selectStyles, theme } from 'styles/select';

import AsyncSelect from 'react-select/async';
import * as styles from './GatesSelect.module.scss';

function GatesSelect({
    placeholder, onSelect, defaultSelected,
}) {
    const dispatch = useDispatch();

    const loadOptions = async (search) => {
        const response = await new Promise((resolve, reject) => {
            dispatch(getGatesForSelect({ search }))
                .then((gates) => resolve(gates))
                .catch(reject);
        });

        return response.items.map((item) => ({ value: item.id, label: item.name }));
    };

    return (
        <AsyncSelect
            key={defaultSelected?.value}
            className={styles.container}
            defaultValue={defaultSelected}
            loadOptions={loadOptions}
            onChange={onSelect}
            additional={{
                page: 1,
            }}
            components={{ MenuList: WindowedMenuList }}
            id="gateSelect"
            isClearable
            isSearchable
            theme={theme}
            styles={selectStyles}
            cacheOptions
            defaultOptions
            placeholder={placeholder}
        />
    );
}

export default GatesSelect;
