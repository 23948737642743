/* eslint-disable import/prefer-default-export */
import * as ACCESS_GATES_TYPES from '../../actions/access/actionTypes';
import { ACCESS_GATES_SORT_BY_TYPE } from '../../../const/access/ACCESS_GATES_SORT_BY_TYPE';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    sortOptions: {
        sortBy: ACCESS_GATES_SORT_BY_TYPE.START_DATE,
        orderBy: 1,
    },
    showAll: false,
    isLoading: false,
    itemsCount: 0,
    pagesCount: 0,
    itemsPerPage: 10,
};

export const accessGatesListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (ACCESS_GATES_TYPES.GET_ACCESS_GATES_RESOLVED): {
        const { items, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items,
            page,
            itemsCount,
            pagesCount,
            isLoading: false,
        };
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_CLEAR): {
        return INITIAL_STATE;
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: false,
        };
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_SHOW_ALL): {
        return {
            ...state,
            page: 0,
            showAll: true,
        };
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (ACCESS_GATES_TYPES.ACCESS_GATES_SEARCH_SET): {
        const { search } = payload;

        return {
            ...state,
            search,
        };
    }
    case (ACCESS_GATES_TYPES.GET_ACCESS_GATES_TRIGGER): {
        return {
            ...state,
            isLoading: true,
        };
    }
    case (ACCESS_GATES_TYPES.GET_ACCESS_GATES_REJECTED): {
        return {
            ...state,
            isLoading: false,
        };
    }
    case ACCESS_GATES_TYPES.ACCESS_GATES_SORT_OPTIONS_SET: {
        const { sortOptions: { sortBy, orderBy } } = payload;

        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    default:
        return state;
    }
};
