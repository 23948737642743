import { createStore, applyMiddleware, compose } from 'redux';
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import createSagaMiddleware from 'redux-saga';

// Reducers
import { persistStore } from 'redux-persist';
import { reducer } from './reducers';

// Sagas
import { saga } from './sagas';

// Persist

// Actions
import * as actions from './actions/app';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(promiseMiddleware, sagaMiddleware)),
);
export const persistor = persistStore(store, null, () => {
    store.dispatch(actions.loadApp());
});

sagaMiddleware.run(saga);
