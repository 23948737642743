import React from 'react';

// Hooks
import {
    useState, useEffect, useMemo, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    useHistory, useParams, Redirect,
} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// Actions
import * as actions from 'store/actions';

// Components
import {
    Form, Row, Col, InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import {
    Calendar,
    Clock,
    ChevronLeft,
    ChevronUp,
    ChevronDown,
} from 'react-feather';
import { ConfirmationModal, Footer } from 'components';
import * as yup from 'yup';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { permissionsEventSelector } from 'store/selectors/permissions';
import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import Container from 'components/Layout/Container/Container';
import { useMobile } from 'hooks/useMobile';
import { BackButton } from 'components/Common/BackButton';
import FileUpload from '../../../../../components/FileUpload/FileUpload';
import DateInput from '../../../../../components/DateInput/DateInput';
import Map from '../../../../../components/Map/Map';
import HourInput from '../../../../../components/HourInput/HourInput';
import PhoneInput from '../../../../../components/PhoneInput/PhoneInput';

// Styles
import { styles, theme, dangerTheme } from '../../../../../styles/select';

import * as SERVICES_ACTIONS from '../../../../../store/actions/services';

import * as SERVICES_SELECTORS from '../../../../../store/selectors/services';

const Edit = () => {
    const { t } = useTranslation();
    const isMobile = useMobile();
    /* istanbul ignore next */
    const schema = useRef(yup.object({
        name: yup
            .string()
            .required(
                t('validationErrors.cannotBeEmpty', {
                    name: t('addEventRoute.eventName'),
                }),
            ),
        description: yup
            .string()
            .required(
                t('validationErrors.cannotBeEmpty', {
                    name: t('addEventRoute.description'),
                }),
            ),
        image: yup.mixed().required(t('addEventRoute.pleaseSelectEventImage')),
        videoURL: yup
            .string()
            .matches(
                /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
                t('validationErrors.invalidURLAddress'),
            ),
        isMaintenanceMode: yup.boolean().required(),
        canSendEmail: yup.boolean().required(),
        time: yup.object({
            from: yup
                .mixed()
                .required(
                    t('validationErrors.cannotBeEmpty', {
                        name: t('addEventRoute.time'),
                    }),
                ),
        }),
        service: yup
            .object({
                id: yup.number().required(),
                name: yup.string().required(),
            })
            .typeError(
                t('validationErrors.cannotBeEmpty', {
                    name: t('addEventRoute.service'),
                }),
            ),
        totalPlaces: yup
            .number()
            .positive(
                t('validationErrors.mustBePositiveNumber', {
                    name: t('addEventRoute.maxRegistrations'),
                }),
            )
            .required(
                t('validationErrors.cannotBeEmpty', {
                    name: t('addEventRoute.maxRegistrations'),
                }),
            ),
        showRegistrationsNumber: yup.boolean().required(),
        contactNumber: yup
            .string()
            .matches(
                /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/,
                t('validationErrors.invalidPhoneNumber'),
            )
            .required(
                t('validationErrors.cannotBeEmpty', {
                    name: t('addEventRoute.contactNumber'),
                }),
            ),
        address: yup
            .object({
                city: yup
                    .string()
                    .required(
                        t('validationErrors.cannotBeEmpty', {
                            name: t('addEventRoute.city'),
                        }),
                    ),
                street: yup
                    .string()
                    .required(
                        t('validationErrors.cannotBeEmpty', {
                            name: t('addEventRoute.streetAddress'),
                        }),
                    ),
                zip: yup
                    .string()
                    .required(
                        t('validationErrors.cannotBeEmpty', {
                            name: t('addEventRoute.zipCode'),
                        }),
                    ),
                country: yup
                    .string()
                    .required(
                        t('validationErrors.cannotBeEmpty', {
                            name: t('addEventRoute.country'),
                        }),
                    ),
            })
            .required(),
    })).current;

    const { id: eventId } = useParams();

    const { loaded: isShopLoaded, id: shopID } = useSelector(
        (state) => state.shop,
    );
    const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const { contactNumber } = useSelector((state) => state.shop);
    const { loaded: areEventsLoaded, events } = useSelector(
        (state) => state.events,
    );
    const { banEdit, banDelete } = useSelector(permissionsEventSelector);

    const event = events?.find(
        ({ resourceId }) => resourceId.toString() === eventId,
    );

    const dispatch = useDispatch();

    const openingHours = useSelector((state) => Object.entries(state.shop.workingDays || {}).reduce(
        (openingHours, [day, hours]) => ({
            ...openingHours,
            [_.upperFirst(day)]: hours,
        }),
        {},
    ));

    const [center, setCenter] = useState(null);
    const [isGettingAddress, setGettingAddress] = useState(false);
    const [isGettingCoordinates, setGettingCoordinates] = useState(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isDeletingEvent, setIsDeletingEvent] = useState(false);

    const { addToast } = useToasts();
    const history = useHistory();

    const initialValues = useMemo(
        () => ({
            name: event?.name ?? '',
            description: event?.description ?? '',
            image: event?.image ?? '',
            videoURL: event?.videoURL ?? '',
            isMaintenanceMode: event?.maintenanceMode ?? false,
            canSendEmail: event?.canSendEmail ?? false,
            time: {
                from: moment(event?.time?.from) ?? null,
            },
            service: event?.service ?? null,
            totalPlaces: event?.totalPlaces ?? 0,
            showRegistrationsNumber: event?.showSessionsLeft ?? false,
            contactNumber: contactNumber ?? '',
            address: {
                street: event?.address.street ?? '',
                zip: event?.address.zip ?? '',
                city: event?.address.city ?? '',
                country: event?.address.country ?? '',
                latitude: event?.address.latitude ?? 0,
                longitude: event?.address.longitude ?? 0,
            },
        }),
        [event],
    );

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        isSubmitting,
        touched,
        errors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                await dispatch(
                    actions.editEvent({
                        event: {
                            ...values,
                            id: eventId,
                        },
                    }),
                );
                addToast(t('editEventRoute.eventEditedSuccessfully'), {
                    appearance: 'success',
                });
                history.push('/agenda/events');
            } catch ({ message, errors }) {
                if (message) {
                    addToast(message, {
                        appearance: 'error',
                    });
                } else {
                    setErrors(errors || {});
                }
            }
            setSubmitting(false);
        },
        initialValues,
        enableReinitialize: true,
    });

    useEffect(() => {
        if (!shopID || !areEventsLoaded) {
            return;
        }

        dispatch(actions.getProductById({ productId: eventId, type: 'event' }));
    }, [shopID, areEventsLoaded]);

    useEffect(() => {
        if (!shopID || !isShopLoaded) {
            return;
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [shopID, isShopLoaded]);

    useEffect(() => {
        if (!event.address.latitude || !event.address.longitude) {
            return;
        }

        setCenter({
            latitude: event.address.latitude,
            longitude: event.address.longitude,
        });
    }, [event.address.latitude, event.address.longitude]);

    const onDeleteHandler = async () => {
        if (banDelete || isDeletingEvent) {
            return;
        }

        setIsDeletingEvent(true);
        try {
            await dispatch(actions.deleteEvent({ id: eventId, history }));
            addToast(t('eventsRoute.eventDeletedSuccessfully'), {
                appearance: 'success',
            });
            setDeleteModalVisible(false);
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        } finally {
            setIsDeletingEvent(false);
        }
    };

    if (banEdit) {
        return <Redirect to="/agenda/events" />;
    }

    return (
        <>
            <TextSubHeader
                text={t('editEventRoute.editEvent')}
                before={(
                    <BackButton
                        href="/agenda/events"
                    />
                )}
                rightActions={!banDelete && !isMobile && (
                    <Button
                        onClick={() => {
                            if (banDelete) return;
                            setDeleteModalVisible(true);
                        }}
                        color="red"
                    >
                        {t('agendaRoute.deleteResource')}
                    </Button>
                )}
            />
            <Container>
                <Form noValidate onSubmit={handleSubmit} className="w-100">
                    <Row>
                        <Col xs={12} lg={6}>
                            <FileUpload
                                name="image"
                                label={
                                    (touched.image && errors.image)
                                || t('addEventRoute.uploadEventImage')
                                }
                                isInvalid={!!touched.image && !!errors.image}
                                defaultValue={values.image}
                                onChange={(file) => setFieldValue('image', file)}
                                loading={!event}
                            />
                        </Col>

                        <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                            <Form.Group>
                                <Form.Label>{t('addEventRoute.eventName')}</Form.Label>
                                {!!event && (
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!touched.name && !!errors.name}
                                    />
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t('addEventRoute.videoURL')}</Form.Label>
                                {!!event && (
                                    <Form.Control
                                        name="videoURL"
                                        type="text"
                                        value={values.videoURL}
                                        onChange={handleChange}
                                        isInvalid={!!touched.videoURL && !!errors.videoURL}
                                    />
                                )}
                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {errors.videoURL}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t('addEventRoute.description')}</Form.Label>
                                {!!event && (
                                    <Form.Control
                                        name="description"
                                        as="textarea"
                                        rows={3}
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={!!touched.description && !!errors.description}
                                    />
                                )}
                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={86} />
                                    </div>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {errors.description}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                {!!event && (
                                    <Form.Check
                                        id="isMaintenanceMode"
                                        name="isMaintenanceMode"
                                        type="checkbox"
                                        label={t('addGroupRoute.maintenanceMode')}
                                        checked={values.isMaintenanceMode}
                                        onChange={handleChange}
                                    />
                                )}
                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback
                                    className={
                                        !!touched.isMaintenanceMode
                                    && !!errors.isMaintenanceMode
                                    && 'd-block'
                                    }
                                    type="invalid"
                                >
                                    {errors.isMaintenanceMode}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                {!!event && (
                                    <Form.Check
                                        id="canSendEmail"
                                        name="canSendEmail"
                                        type="checkbox"
                                        label={t('addGroupRoute.canSendEmail')}
                                        checked={values.canSendEmail}
                                        onChange={handleChange}
                                    />
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback
                                    className={
                                        !!touched.canSendEmail && !!errors.canSendEmail && 'd-block'
                                    }
                                    type="invalid"
                                >
                                    {errors.canSendEmail}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h3 className="mb-4 mt-4 font-weight-normal">
                        {t('addEventRoute.eventSettings')}
                    </h3>
                    <Row>
                        <Col xs={12} lg={4}>
                            <Form.Group className="mb-3">
                                <label className="form-label">{t('addEventRoute.date')}</label>
                                {!!event && (
                                    <InputGroup>
                                        <DateInput
                                            name="time.from.date"
                                            value={values.time.from}
                                            placeholder={t('blockModal.selectDate')}
                                            onChange={(time) => setFieldValue('time.from', time)}
                                            isInvalid={!!touched.time?.from && !!errors.time?.from}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text
                                                className={classNames({
                                                    'border-danger text-danger':
                                                !!touched.time?.from && !!errors.time?.from,
                                                    'text-muted':
                                                !touched.time?.from || !errors.time?.from,
                                                })}
                                            >
                                                <Calendar size={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback
                                    className={
                                        !!touched.time?.from && !!errors.time?.from && 'd-block'
                                    }
                                    type="invalid"
                                >
                                    {errors.time?.from}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Form.Group>
                                <label className="form-label">{t('addEventRoute.time')}</label>
                                {!!event && (
                                    <InputGroup className="mb-3">
                                        <HourInput
                                            allowAllHours
                                            cycleTimeThroughSameDate
                                            name="time.from.time"
                                            value={values.time.from}
                                            placeholder={t('blockModal.selectTime')}
                                            onChange={(time) => setFieldValue('time.from', time)}
                                            min={
                                                openingHours[
                                                    values.time.from?.format('dddd')
                                            ?? _.upperFirst(moment().format('dddd'))
                                                ]?.from
                                            }
                                            max={
                                                openingHours[
                                                    values.time.from?.format('dddd')
                                            ?? _.upperFirst(moment().format('dddd'))
                                                ]?.to
                                            }
                                            isInvalid={!!touched.time?.from && !!errors.time?.from}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text
                                                className={classNames({
                                                    'border-danger text-danger':
                                                touched.time?.from && errors.time?.from,
                                                    'text-muted':
                                                !touched.time?.from || !errors.time?.from,
                                                })}
                                            >
                                                <Clock size={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Form.Group>
                                <Form.Label>{t('addEventRoute.service')}</Form.Label>

                                {!!event && (
                                    <Select
                                        name="service"
                                        styles={styles}
                                        theme={
                                            touched.service && !!errors.service ? dangerTheme : theme
                                        }
                                        options={companyServices.map(({ id, name }) => ({
                                            value: id,
                                            label: name,
                                        }))}
                                        value={
                                            values.service
                                                ? { value: values.service.id, label: values.service.name }
                                                : null
                                        }
                                        onChange={({ value: id, label: name }) => setFieldValue('service', { id, name })}
                                        placeholder={t('placeholders.select')}
                                    />
                                )}
                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback
                                    className={!!touched.service && !!errors.service && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.service}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={4}>
                            <Form.Group>
                                <label className="form-label">
                                    {t('addEventRoute.maxRegistrations')}
                                </label>
                                {!!event && (
                                    <InputGroup>
                                        <Form.Control
                                            name="totalPlaces"
                                            type="number"
                                            value={values.totalPlaces}
                                            onChange={handleChange}
                                            isInvalid={!!touched.totalPlaces && !!errors.totalPlaces}
                                        />
                                        <InputGroup.Append className="border-left">
                                            <div
                                                className={classNames(
                                                    {
                                                        'border-light':
                                                    !touched.totalPlaces || !errors.totalPlaces,
                                                        'border-danger':
                                                    touched.totalPlaces && !!errors.totalPlaces,
                                                    },
                                                    'd-flex flex-column border rounded-right',
                                                )}
                                            >
                                                <button
                                                    type="button"
                                                    name="totalPlaces.increment"
                                                    className={classNames(
                                                        {
                                                            'text-darker-light':
                                                        !touched.totalPlaces || !errors.totalPlaces,
                                                            'text-danger':
                                                        touched.totalPlaces && !!errors.totalPlaces,
                                                        },
                                                        'd-flex justify-content-center bg-transparent border-0 p-0',
                                                    )}
                                                    style={{ width: 23, height: 19 }}
                                                    onClick={() => setFieldValue('totalPlaces', values.totalPlaces + 1)}
                                                >
                                                    <ChevronUp size={16} />
                                                </button>

                                                <button
                                                    type="button"
                                                    name="totalPlaces.decrement"
                                                    className={classNames(
                                                        {
                                                            'text-darker-light':
                                                        !touched.totalPlaces || !errors.totalPlaces,
                                                            'text-danger':
                                                        touched.totalPlaces && !!errors.totalPlaces,
                                                        },
                                                        'd-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0',
                                                    )}
                                                    style={{ width: 23, height: 19 }}
                                                    onClick={() => setFieldValue(
                                                        'totalPlaces',
                                                        Math.max(values.totalPlaces - 1, 0),
                                                    )}
                                                >
                                                    <ChevronDown size={16} />
                                                </button>
                                            </div>
                                        </InputGroup.Append>
                                    </InputGroup>
                                )}
                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}
                                <Form.Control.Feedback
                                    className={
                                        !!touched.totalPlaces && !!errors.totalPlaces && 'd-block'
                                    }
                                    type="invalid"
                                >
                                    {errors.totalPlaces}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col
                            xs={12}
                            lg={4}
                            className="pt-4"
                            style={{ marginTop: '0.8rem' }}
                        >
                            <Form.Group controlId="showRegistrationsNumber" className="m-0">
                                {!!event && (
                                    <Form.Check
                                        name="showRegistrationsNumber"
                                        type="checkbox"
                                        label={t('addEventRoute.showNumberOfRegistrations')}
                                        checked={values.showRegistrationsNumber}
                                        onChange={handleChange}
                                    />
                                )}
                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <h3 className="my-4 font-weight-normal">
                        {t('addEventRoute.address')}
                    </h3>
                    <Row>
                        <Col xs={12} lg={8} className="d-flex flex-column">
                            {center ? (
                                <Map
                                    name="map"
                                    className="h-100"
                                    center={center}
                                    markerPosition={{
                                        latitude: values.address.latitude,
                                        longitude: values.address.longitude,
                                    }}
                                    onMarkerPositionChange={({ latitude, longitude }) => {
                                        setCenter({ latitude, longitude });
                                        setFieldValue('address', {
                                            ...values.address,
                                            latitude,
                                            longitude,
                                        });
                                    }}
                                />
                            ) : (
                                <Skeleton width="100%" height={414} />
                            )}

                            <div className="mt-4 px-3">
                                <Button
                                    type="button"
                                    name="fillAddress"
                                    color="outline"
                                    size="small"
                                    onClick={() => {
                                        setGettingAddress(true);
                                        dispatch(
                                            actions.getAddressByCoordinates({
                                                latitude: values.address.latitude,
                                                longitude: values.address.longitude,
                                            }),
                                        )
                                            .then(({
                                                street, zip, city, country,
                                            }) => setFieldValue('address', {
                                                ...values.address,
                                                street,
                                                zip,
                                                city,
                                                country,
                                            }))
                                            .finally(() => setGettingAddress(false));
                                    }}
                                    loading={isGettingAddress}
                                >
                                    { t('addEventRoute.fillAddressByMarkerPosition')}
                                </Button>
                            </div>
                        </Col>

                        <Col xs={12} lg={4} className="mt-4 mt-lg-0">
                            <Form.Group>
                                <label className="form-label">
                                    {t('addEventRoute.contactNumber')}
                                </label>
                                {!!event && (
                                    <PhoneInput
                                        name="contactNumber"
                                        value={values.contactNumber}
                                        onChange={(contactNumber) => setFieldValue('contactNumber', contactNumber)}
                                        isInvalid={!!touched.contactNumber && !!errors.contactNumber}
                                    />
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}
                                <Form.Control.Feedback
                                    className={
                                        !!touched.contactNumber && !!errors.contactNumber && 'd-block'
                                    }
                                    type="invalid"
                                >
                                    {errors.contactNumber}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t('addEventRoute.city')}</Form.Label>
                                {!!event && (
                                    <Form.Control
                                        name="address.city"
                                        type="text"
                                        placeholder={t('addEventRoute.enterCity')}
                                        value={values.address.city}
                                        onChange={handleChange}
                                        isInvalid={!!touched.address?.city && !!errors.address?.city}
                                    />
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback type="invalid">
                                    {errors.address?.city}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t('addEventRoute.streetAddress')}</Form.Label>
                                {!!event && (
                                    <Form.Control
                                        name="address.street"
                                        type="text"
                                        placeholder={t('addEventRoute.enterStreetAddress')}
                                        value={values.address.street}
                                        onChange={handleChange}
                                        isInvalid={
                                            touched.address
                                        && touched.address.street
                                        && !!errors.address
                                        && !!errors.address.street
                                        }
                                    />
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback type="invalid">
                                    {errors.address?.street}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t('addEventRoute.zipCode')}</Form.Label>

                                {!!event && (
                                    <Form.Control
                                        name="address.zip"
                                        type="text"
                                        placeholder={t('addEventRoute.enterZipCode')}
                                        value={values.address.zip}
                                        onChange={handleChange}
                                        isInvalid={
                                            touched.address
                                        && touched.address.zip
                                        && !!errors.address
                                        && !!errors.address.zip
                                        }
                                    />
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback type="invalid">
                                    {errors.address?.zip}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t('addEventRoute.country')}</Form.Label>

                                {!!event && (
                                    <Form.Control
                                        name="address.country"
                                        type="text"
                                        placeholder={t('addEventRoute.enterCountry')}
                                        value={values.address.country}
                                        onChange={handleChange}
                                        isInvalid={
                                            touched.address
                                        && touched.address.country
                                        && !!errors.address
                                        && !!errors.address.country
                                        }
                                    />
                                )}

                                {!event && (
                                    <div
                                        className="position-relative"
                                        style={{ top: -3, marginBottom: -4 }}
                                    >
                                        <Skeleton width="100%" height={38} />
                                    </div>
                                )}

                                <Form.Control.Feedback type="invalid">
                                    {errors.address?.country}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                type="button"
                                name="placeMarker"
                                color="yellow"
                                onClick={() => {
                                    setGettingCoordinates(true);
                                    dispatch(
                                        actions.getCoordinatesByAddress({
                                            street: values.address.street,
                                            zip: values.address.zip,
                                            city: values.address.city,
                                            country: values.address.country,
                                        }),
                                    )
                                        .then(({ latitude, longitude }) => {
                                            setFieldValue('address', {
                                                ...values.address,
                                                latitude,
                                                longitude,
                                            });
                                            setCenter({
                                                latitude,
                                                longitude,
                                            });
                                        })
                                        .finally(() => setGettingCoordinates(false));
                                }}
                                loading={isGettingCoordinates}
                            >
                                { t('addEventRoute.placeMarkerOnMap')}
                            </Button>
                        </Col>
                    </Row>

                    <div
                        className="d-flex align-items-center border-top pt-3 mt-3"
                    >
                        <Button
                            type="button"
                            color="outline"
                            href="/agenda/events"
                        >
                            {t('addEventRoute.cancel')}
                        </Button>

                        <div className="d-flex justify-content-end flex-grow-1">
                            <Button color="yellow" type="submit" loading={isSubmitting}>
                                {t('editEventRoute.save')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>

            <Footer className="d-flex d-lg-none justify-content-between">
                <Button
                    color="outline"
                    href="/agenda/events"
                    size="extra-large"
                    className="rounded-0 px-3 px-sm-5"
                >
                    <ChevronLeft
                        className="text-darker-light mr-1 position-relative"
                        size={20}
                    />
                    {t('addObjectRoute.back')}
                </Button>

                {!banDelete && (
                    <Button
                        onClick={() => {
                            if (banDelete) return;
                            setDeleteModalVisible(true);
                        }}
                        color="red"
                        size="extra-large"
                        className="rounded-0 px-3 px-sm-5"
                    >
                        {t('agendaRoute.deleteResource')}
                    </Button>
                )}
            </Footer>

            <ConfirmationModal
                isShow={deleteModalVisible}
                hide={() => setDeleteModalVisible(false)}
                loading={isDeletingEvent}
                confirmAction={onDeleteHandler}
                titleText={`${t('eventsRoute.delete')} ${t('eventsRoute.event')}`}
                bodyText={t('eventsRoute.areYouSureYouWantToDeleteEvent')}
                deleteText={t('eventsRoute.delete')}
            />
        </>
    );
};

export default Edit;
