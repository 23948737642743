import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge } from 'components/Common/Badge';
import { Row } from 'components/Common/Row';
import SearchInput2 from 'components/Common/SearchInput2';
import { emptyFunc } from 'helpers/function/emptyFunc';
import { PeriodPicker } from 'components/Common/PeriodPicker';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './LogsListFilter.module.scss';

const T_PREFIX = 'logs.list.table.body.rows';

const LogsListFilter = (props) => {
    const {
        searchValue,
        onSearch,
        onChangeDate,
        filter,
        onChangeFilter,
        date,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);

    return (
        <div className={styles.container}>
            <SearchInput2
                onSearch={onSearch}
                searchValue={searchValue}
                stretched={false}
            />
            <Row gap={12} wrap>
                <PeriodPicker
                    initDateFrom={date.dateFrom}
                    initDateTo={date.dateTo}
                    initPeriod={date.period}
                    onChange={onChangeDate}
                />
                <Badge color={filter === 'allowed_entry' ? 'yellow' : 'gray'} size="small" onClick={() => onChangeFilter('allowed_entry')}>
                    {t(`${T_PREFIX}.scanResult.allowedEntry`)}
                </Badge>
                <Badge color={filter === 'denied_entry' ? 'yellow' : 'gray'} size="small" onClick={() => onChangeFilter('denied_entry')}>
                    {t(`${T_PREFIX}.scanResult.deniedEntry`)}
                </Badge>
                <Badge color={filter === 'exit' ? 'yellow' : 'gray'} size="small" onClick={() => onChangeFilter('exit')}>
                    {t(`${T_PREFIX}.scanResult.exit`)}
                </Badge>
                <Badge color={filter === 'error' ? 'yellow' : 'gray'} size="small" onClick={() => onChangeFilter('error')}>
                    {t(`${T_PREFIX}.scanResult.error`)}
                </Badge>
            </Row>
        </div>
    );
};

LogsListFilter.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
};

LogsListFilter.defaultProps = {
    searchValue: '',
    onSearch: emptyFunc,
};

export default LogsListFilter;
