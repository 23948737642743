import React from 'react';

const RefundIcon = ({ width = '25', height = '24' }) => (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.25 6.2C13.25 5.53726 13.7873 5 14.45 5H15.45C16.8291 5 18.1518 5.54786 19.127 6.52304C20.1021 7.49823 20.65 8.82087 20.65 10.2C20.65 11.5791 20.1021 12.9018 19.127 13.877C18.1518 14.8521 16.8291 15.4 15.45 15.4H7.34706L9.29853 17.3515C9.76716 17.8201 9.76716 18.5799 9.29853 19.0485C8.8299 19.5172 8.0701 19.5172 7.60147 19.0485L3.60147 15.0485C3.13284 14.5799 3.13284 13.8201 3.60147 13.3515L7.60147 9.35147C8.0701 8.88284 8.8299 8.88284 9.29853 9.35147C9.76716 9.8201 9.76716 10.5799 9.29853 11.0485L7.34706 13H15.45C16.1926 13 16.9048 12.705 17.4299 12.1799C17.955 11.6548 18.25 10.9426 18.25 10.2C18.25 9.45739 17.955 8.7452 17.4299 8.2201C16.9048 7.695 16.1926 7.4 15.45 7.4H14.45C13.7873 7.4 13.25 6.86274 13.25 6.2Z" fill="#26292C" />
    </svg>

);

export default RefundIcon;
