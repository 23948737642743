import React from 'react';

// Components
import { Collapse, FormCheck } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import Skeleton from 'react-loading-skeleton';

// Styles

// Utils
import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'components/Common/Button';
import * as styles from './Sidebar.module.scss';
import Calendar from '../../Calendar/Calendar';

const SidebarBody = ({
    title,
    viewType,
    date,
    onSelectDate,
    month,
    year,
    onChangeTimespan,
    setShowFilters,
    showFilters,
    products,
    filters,
    onFilterClick,
}) => (
    <>
        <Calendar
            selectTimespan={viewType}
            selectedDate={date}
            onSelectDate={onSelectDate}
            month={month}
            year={year}
            onChangeTimespan={onChangeTimespan}
        />

        {(filters && products) && (
            <>
                <Button
                    data-testid="toggleFiltersButton"
                    color="transparent"
                    stretched
                    className="mt-4"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    <span className="text-left flex-grow-1">{title}</span>
                    {showFilters ? (
                        <ChevronUp className="text-muted" size={28} />
                    ) : (
                        <ChevronDown className="text-muted" size={28} />
                    )}
                </Button>

                <Collapse
                    className={classNames(styles.objectsCollapse, 'mx-4 my-2 px-2')}
                    in={showFilters}
                >
                    <div>
                        {products?.map(({ id, name }) => (
                            <FormCheck
                                key={`employee-${id}`}
                                className="py-1"
                                type="checkbox"
                                label={name}
                                checked={filters[id]}
                                onChange={({ target: { checked } }) => onFilterClick(id, checked)}
                            />
                        ))}
                        {!products
                            && _.times(5, (i) => (
                                <div key={`skeleton-${i}`} className="d-block py-1">
                                    <Skeleton
                                        width={130 + i * 10 * (i % 2 === 0 ? -1 : 1)}
                                        height={18}
                                    />
                                </div>
                            ))}
                    </div>
                </Collapse>
            </>
        )}
    </>
);

export default SidebarBody;
