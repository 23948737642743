import React from 'react';
import { SubNavigation, SubNavigationHorisontal } from 'components';
import { Row } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMobile } from 'hooks/useMobile';
import * as styles from './SettingsCompanyNavigation.module.scss';

const SettingsCompanyNavigation = ({
    isDataLoaded,
    subRoutes,
    withQuery,
}) => {
    const isMobile = useMobile(1200);

    if (isMobile) {
        return (
            <Row noGutters className={classNames('align-items-center justify-content-between flex-nowrap overflow-auto w-100', styles.hideScroll)}>
                <SubNavigation
                    isDataLoaded={isDataLoaded}
                    subRoutes={subRoutes}
                    withQuery={withQuery}
                />
            </Row>
        );
    }

    return (
        <div className={styles.desktopContainer}>
            <SubNavigationHorisontal
                isDataLoaded={isDataLoaded}
                subRoutes={subRoutes}
                withQuery={withQuery}
            />
        </div>
    );
};

SettingsCompanyNavigation.propTypes = {
    isDataLoaded: PropTypes.bool,
    subRoutes: PropTypes.arrayOf(PropTypes.shape({
        directsTo: PropTypes.string,
        translation: PropTypes.string,
    })),
    withQuery: PropTypes.bool,
};

SettingsCompanyNavigation.defaultProps = {
    isDataLoaded: false,
    subRoutes: [],
    withQuery: false,
};

export default SettingsCompanyNavigation;
