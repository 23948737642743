import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import moment from 'moment';

import { formatPrice } from 'helpers/formatters/formatPrice';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import Container from 'components/Layout/Container/Container';
import { toCapitalize } from '../../../../helpers/string/toCapitalize';

import { Tooltip } from '../../../Common/Tooltip';

import { BalanceIcon, InfoIcon, PendingBalanceIcon } from '../../../Icon/Icon';

import * as styles from './ClearingsPageHeader.module.scss';

import { companySelector } from '../../../../store/selectors/company';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.header';

const ClearingsPageHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLEARINGS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const {
        balance,
        nextClearingDate,
    } = useSelector(companySelector);

    const bookBalance = balance?.balance ? balance.balance.toFixed(2) : 0;
    const nextClearing = balance?.nextClearing?.amount ? balance.nextClearing.amount.toFixed(2) : 0;
    const pending = [bookBalance, nextClearing].includes(0)
        ? 0
        : (bookBalance - nextClearing).toFixed(2);

    const clearingDate = useMemo(() => {
        if (!nextClearingDate) {
            return null;
        }

        const date = moment(nextClearingDate).locale('en');
        const weekDay = tc(`date.weekDay.${date.format('dddd').toLocaleLowerCase()}`);
        const month = tc(`date.month.${date.format('MMMM').toLocaleLowerCase()}`);
        const day = date.format('DD');

        return `${toCapitalize(weekDay)} ${day} ${toCapitalize(month)}`;
    }, [nextClearingDate, tc]);

    return (
        <>
            <TextSubHeader text={t(`${T_PREFIX}.title`)} />
            <Container>
                <div className={styles.companyInfoPanel}>
                    <Row className={styles.cellCompanyInfoPanel}>
                        <BalanceIcon className={styles.balanceIcon} />

                        <div className={styles.cellCompanyInfoPanelItemsWrapper}>
                            <div>
                                <p>
                                    {t(`${T_PREFIX}.companyInfo.bookBalance.label`)}
                                    <Tooltip
                                        tooltip={t(`${T_PREFIX}.companyInfo.bookBalance.tooltip`)}
                                        placement="bottom"
                                    >
                                        <div className={styles.infoIcon}>
                                            <InfoIcon />
                                        </div>
                                    </Tooltip>
                                </p>
                                <p className={styles.strongText}>
                                    {formatPrice.toEuroWithComma({ amount: bookBalance }) }
                                </p>
                            </div>
                            <div>
                                <p>
                                    {t(`${T_PREFIX}.companyInfo.pending.label`)}
                                    <Tooltip
                                        tooltip={t(`${T_PREFIX}.companyInfo.pending.tooltip`)}
                                        placement="bottom"
                                    >
                                        <div className={styles.infoIcon}>
                                            <InfoIcon />
                                        </div>
                                    </Tooltip>
                                </p>
                                <p className={styles.strongText}>
                                    {formatPrice.toEuroWithComma({ amount: pending }) }
                                </p>
                            </div>
                        </div>
                    </Row>

                    <div className={styles.divider} />

                    <Row className={styles.cellCompanyInfoPanel}>
                        <PendingBalanceIcon className={classNames(styles.balanceIcon, styles.grayColor)} />

                        <div>
                            <p>
                                {t(`${T_PREFIX}.companyInfo.nextClearing.label`)}
                                <Tooltip
                                    tooltip={t(`${T_PREFIX}.companyInfo.nextClearing.tooltip`)}
                                    placement="bottom"
                                >
                                    <div className={styles.infoIcon}>
                                        <InfoIcon />
                                    </div>
                                </Tooltip>
                            </p>

                            <p>
                                <span className={styles.strongText}>
                                    {formatPrice.toEuroWithComma({ amount: nextClearing }) }
                                </span>

                                {clearingDate && (
                                    <>
                                        <span className={styles.point}>
                                            ·
                                        </span>

                                        <span className={styles.date}>
                                            {clearingDate}
                                        </span>
                                    </>
                                )}
                            </p>
                        </div>
                    </Row>
                </div>
            </Container>
        </>
    );
};

ClearingsPageHeader.propTypes = {
    onClearing: PropTypes.func,
};

ClearingsPageHeader.defaultProps = {
    onClearing: PropTypes.emptyFunc,
};

export default ClearingsPageHeader;
