import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';

import * as SERVICES_TYPES from '../../actions/services/types';

import * as SERVICES_ACTIONS from '../../actions/services';
import * as TOAST_ACTIONS from '../../actions/toast';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { API_ADMIN_SERVICE_GET_ID_ROUTE } from '../../../const/API_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';

function* getServiceItem(action) {
    const { payload } = action;
    const { serviceId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(SERVICES_ACTIONS.setServiceItemLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_SERVICE_GET_ID_ROUTE({ serviceId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const item = camelize(result);
            const subOptions = (item.subOptions || []).map((subOption) => ({
                id: subOption.id,
                name: subOption.name,
                price: subOption.price,
                time: subOption.minutes,
                bookingFrequency: subOption.bookingFrequency,
                addTime: subOption.additionalTime,
                seatsAmount: subOption.seatsAmount,
                restrictUnpaidBookings: item.restrictUnpaidBookings,
            }));
            const service = {
                id: item.id,
                name: item.name,
                time: item.minutes,
                price: item.price,
                categoryName: item.categoryName,
                bookableType: item.bookableType,
                bookingFrequency: item.bookingFrequency,
                subOptions,
                addTime: item.additionalTime,
                seatsAmount: item.seatsAmount,
                vatValue: item.vatValue,
                randomlySelectEmployee: item.randomlySelectEmployee,
                restrictUnpaidBookings: item.restrictUnpaidBookings,
                hidePrice: item.hidePrice,
            };
            yield put(SERVICES_ACTIONS.setServiceItem({ service }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'serviceItem.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(SERVICES_ACTIONS.setServiceItemLoading({ loading: false }));
    }
}

export const serviceItemSaga = [
    takeLatest(SERVICES_TYPES.SERVICE_ITEM_GET, getServiceItem),
];
