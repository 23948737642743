import React, { useCallback, useState } from 'react';
import { Col, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { roleSelector } from 'store/selectors/auth';
import { permissionsPresetSelector } from 'store/selectors/permissions';

import AnimatedBurger from 'components/AnimatedBurger/AnimatedBurger';
import CompanyBadge from 'components/CompanyBadge/CompanyBadge';
import MainNavigation, { ProfileDropdown } from 'components/Navigation/MainNavigation/MainNavigation';
import { Tooltip } from 'components/Common/Tooltip';

import { HelpQuestion } from 'components/Icon/Icon';

import { productAllDataSelector } from 'store/selectors/products';
import { useMobile } from 'hooks/useMobile';
import * as styles from './Header.module.scss';

const HELP_URL = 'http://help.appybee.nl';

function Header() {
    const { role } = useSelector(roleSelector);
    const preset = useSelector(permissionsPresetSelector);
    const { sections } = useSelector(({ company }) => company);
    const { logo, name } = useSelector((state) => state.company);
    const products = useSelector(productAllDataSelector());
    const isMobile = useMobile();

    const { t } = useTranslation();

    const [isExpanded, setExpanded] = useState(false);

    const removeFocus = useCallback((event) => { event.currentTarget.blur(); }, []);

    return (
        <header className={styles.noOutline}>
            <Navbar
                collapseOnSelect
                className="bg-white border-bottom py-0 px-0 px-md-2"
                expand="lg"
                expanded={isExpanded}
            >
                <Col
                    xs={{ span: 'auto' }}
                    md={2}
                    lg={2}
                    xl={3}
                    className="d-flex flex-nowrap"
                >
                    <CompanyBadge size={isMobile ? 42 : 48} name={name} logo={logo} />
                    {isMobile && <ProfileDropdown role={role} isMobile={isMobile} onlyAvatar={isMobile} />}
                </Col>
                <MainNavigation
                    role={role}
                    sections={sections}
                    preset={preset}
                    productsList={products}
                    onClose={() => setExpanded(false)}
                    isMobile={isMobile}
                />
                <Col
                    xs={{ span: 'auto' }}
                    className="d-flex flex-row justify-content-end align-items-center align-self-stretch ml-1"
                >

                    {!isMobile && (
                        <>
                            <ProfileDropdown role={role} isMobile={isMobile} onlyAvatar={isMobile} />
                            <Tooltip
                                tooltip={t('help')}
                                placement="bottom"
                            >
                                <a
                                    href={HELP_URL}
                                    onClick={removeFocus}
                                    target="__about"
                                    className={styles.helpQuestionIcon}
                                >
                                    <HelpQuestion />
                                </a>
                            </Tooltip>

                        </>

                    )}
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={() => setExpanded(!isExpanded)}
                    >
                        <AnimatedBurger active={isExpanded} size={16} />
                    </Navbar.Toggle>
                </Col>
            </Navbar>
        </header>
    );
}

export default Header;
