import React from 'react';

import * as styles from './Label.module.scss';
import Typography from '../Typography/Typography';

const Label = (props) => (
    <Typography {...props} className={styles.label} data-testid="label" />
);

Label.propTypes = Typography.propTypes;

Label.defaultProps = Typography.defaultProps;

export default Label;
