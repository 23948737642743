import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { emptyFunc } from 'helpers/function/emptyFunc';

import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { CheckBox } from '../../Common/CheckBox';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';
import { SORT_BY } from '../../../const/clients/SORT_BY';
import { SORT_ORDER } from '../../../const/clients/SORT_ORDER';

const T_PREFIX = 'list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const ClientsListTableHeader = (props) => {
    const {
        isSelected,
        onSelectAll,
        onDeselectAll,
        onSort,
        sortBy,
        sortType,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const handleSelect = useCallback(() => {
        if (isSelected) {
            onDeselectAll();
        } else {
            onSelectAll();
        }
    }, [isSelected, onSelectAll, onDeselectAll]);

    return (
        <TableHeader>
            <TableHeaderCell>
                <ListTableHeaderCell name={(
                    <CheckBox
                        checked={isSelected}
                        onChange={handleSelect}
                    />
                )}
                />
            </TableHeaderCell>
            <TableHeaderCell />
            <TableHeaderCell>
                <ListTableHeaderCell name={t(`${T_FIELDS}.name.label`)} onClick={onSort} active={sortBy === SORT_BY.FULL_NAME} ascending={sortType === SORT_ORDER.DESC} />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell name={t(`${T_FIELDS}.phone.label`)} />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell name={t(`${T_FIELDS}.email.label`)} />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell name={t(`${T_FIELDS}.registered.label`)} />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell name={t(`${T_FIELDS}.address.label`)} />
            </TableHeaderCell>
        </TableHeader>
    );
};

ClientsListTableHeader.propTypes = {
    isSelected: PropTypes.bool,
    onSelectAll: PropTypes.func,
    onDeselectAll: PropTypes.func,
};

ClientsListTableHeader.defaultProps = {
    isSelected: false,
    onSelectAll: emptyFunc,
    onDeselectAll: emptyFunc,
};

export default ClientsListTableHeader;
