import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Sort } from 'components/Icon/Icon';

import * as styles from './ListTableHeaderCell.module.scss';

const Component = (props) => (props?.onClick ? (
    <button
        className={classNames(styles.wrapper, props?.align && styles[props?.align], props?.wrap && styles.wrap)}
        disabled={!props?.onClick}
        onClick={props?.onClick}
    >
        {props?.children}
    </button>
) : (
    <div
        className={classNames(styles.wrapper, props?.align && styles[props?.align], props?.wrap && styles.wrap)}
    >
        {props?.children}
    </div>
));

const ListTableHeaderCell = (props) => {
    const {
        name,
        active,
        ascending,
        onClick,
        align,
        wrap,
    } = props;

    return (
        <Component onClick={onClick} align={align} wrap={wrap}>
            <span className={classNames(styles.name, align && styles[align], wrap && styles.wrap)}>
                {name}
            </span>

            {!!onClick && (
                <Sort
                    className={styles.iconSort}
                    upClassName={classNames(active && !ascending && styles.active)}
                    downClassName={classNames(active && ascending && styles.active)}
                />
            )}
        </Component>
    );
};

ListTableHeaderCell.defaultProps = {
    name: '',
    active: false,
    ascending: true,
    onClick: null,
    align: 'left',
    wrap: true,
};

ListTableHeaderCell.propTypes = {
    name: PropTypes.string,
    active: PropTypes.bool,
    ascending: PropTypes.bool,
    onClick: PropTypes.func,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    wrap: PropTypes.bool,
};

export default ListTableHeaderCell;
