import { CHAR_SYMBOL } from '../../const/string/CHAR_SYMBOL';

const DECIMAL_PAD_END_LENGTH = 2;

export const formatPrice = ({
    amount,
    currency,
    decimalSeparator,
}) => {
    if (!amount) {
        return `${currency}0${decimalSeparator}${'0'.repeat(DECIMAL_PAD_END_LENGTH)}`;
    }
    const amountString = String(amount)
        .replace(
            CHAR_SYMBOL.PRICE.SEPARATOR.COMMA,
            CHAR_SYMBOL.PRICE.SEPARATOR.DOT,
        );
    const [whole, decimal = ''] = amountString.split(CHAR_SYMBOL.PRICE.SEPARATOR.DOT);
    const amountFirstDigits = whole.length % 3 || 3;
    const formattedWhole = amountFirstDigits === 1 && whole[0] === '-'
        ? whole.slice(0, 4) + whole.slice(4).replace(/\d{3}/g, '.$&')
        : whole.slice(0, amountFirstDigits) + whole.slice(amountFirstDigits).replace(/\d{3}/g, '.$&');
    const formattedDecimal = decimal.slice(0, 2);

    return `${currency}${formattedWhole}${decimalSeparator}${formattedDecimal.padEnd(DECIMAL_PAD_END_LENGTH, '0')}`;
};

formatPrice.toEuroWithComma = ({ amount }) => formatPrice({
    amount,
    currency: CHAR_SYMBOL.CURRENCY.EURO,
    decimalSeparator: CHAR_SYMBOL.PRICE.SEPARATOR.COMMA,
});

formatPrice.parseEuroWithComma = ({ price }) => {
    // Remove non-numeric characters except for dots and commas
    const cleanedString = price.replace(/[^\d,]/g, '');

    // Replace commas with dots for consistent decimal representation
    const dotString = cleanedString.replace(/,/g, '.');

    // Parse the string to a floating-point number
    const numberValue = parseFloat(dotString);

    return isNaN(numberValue) ? 0 : numberValue;
};
