export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const RESET_PASSWORD = '/reset-password';
export const RESET_PASSWORD_SUCCESS = '/reset-password-success';
export const SET_NEW_PASSWORD = '/set-new-password';

export const AGENDA = '/agenda';

// SERVICES
export const SERVICES_ROOT_PAGE = '/services';

// SERVICES -> SUBSCRIPTIONS
export const SUBSCRIPTIONS_PAGE = `${SERVICES_ROOT_PAGE}/subscriptions`;
export const SUBSCRIPTIONS_ADD_PAGE = `${SUBSCRIPTIONS_PAGE}/add`;

// SERVICES -> BUNDLES
export const BUNDLES_PAGE = `${SERVICES_ROOT_PAGE}/bundles`;
export const BUNDLES_LIST_PAGE = `${BUNDLES_PAGE}/list`;
export const BUNDLES_SOLD_PAGE = `${BUNDLES_PAGE}/sold`;
export const BUNDLES_ADD_PAGE = `${BUNDLES_PAGE}/add`;
export const BUNDLES_EDIT_ID_PAGE = ({ bundleId }) => `${BUNDLES_PAGE}/edit/${bundleId}`;

// SERVICES -> SERVICES
export const SERVICES_PAGE = `${SERVICES_ROOT_PAGE}/services`;
export const SERVICES_LIST_PAGE = `${SERVICES_PAGE}/list`;

// CLIENTS
export const CLIENTS_ROOT_PAGE = '/clients';
export const CLIENTS_LIST_PAGE = `${CLIENTS_ROOT_PAGE}/list`;
export const CLIENTS_LIST_ID_PAGE = ({ clientId }) => `${CLIENTS_LIST_PAGE}/${clientId}`;
export const CLIENTS_ADD_PAGE = `${CLIENTS_ROOT_PAGE}/add`;
const CLIENTS_EDIT_PAGE = `${CLIENTS_ROOT_PAGE}/edit`;
export const CLIENTS_EDIT_ID_PAGE = ({ clientId }) => `${CLIENTS_EDIT_PAGE}/${clientId}`;
export const CLIENTS_LIST_ID_SUBSCRIPTIONS_PAGE = ({ clientId }) => `${CLIENTS_LIST_ID_PAGE({ clientId })}/subscriptions`;

// MARKETING
export const MARKETING_ROOT_PAGE = '/marketing';

// MARKETING -> MESSAGES
export const MESSAGES_ROOT_PAGE = `${MARKETING_ROOT_PAGE}/messages`;
export const MESSAGES_LIST_PAGE = `${MESSAGES_ROOT_PAGE}/list`;
export const MESSAGES_ADD_PAGE = `${MESSAGES_ROOT_PAGE}/add`;
export const MESSAGES_EDIT_PAGE = `${MESSAGES_ROOT_PAGE}/edit`;
export const MESSAGES_EDIT_ID_PAGE = ({ messageId }) => `${MESSAGES_EDIT_PAGE}/${messageId}`;

// MARKETING -> PROMOTIONS
export const PROMOTIONS_ROOT_PAGE = `${MARKETING_ROOT_PAGE}/promotions`;

// SETTINGS
const SETTINGS_ROOT_PAGE = '/settings';

export const SETTINGS_COMPANY_PAGE = `${SETTINGS_ROOT_PAGE}/company`;

export const SETTINGS_COMPANY_GENERAL_PAGE = `${SETTINGS_COMPANY_PAGE}/general`;

export const SETTINGS_COMPANY_LOCATIONS_PAGE = `${SETTINGS_COMPANY_PAGE}/locations`;
export const SETTINGS_COMPANY_LOCATIONS_ADD_PAGE = `${SETTINGS_COMPANY_LOCATIONS_PAGE}/add`;
export const SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE = ({ id }) => `${SETTINGS_COMPANY_LOCATIONS_PAGE}/${id}`;

export const SETTINGS_COMPANY_BIRTHDAY_AND_LOYALTY_PAGE = `${SETTINGS_COMPANY_PAGE}/birthday-and-loyalty`;

export const SETTINGS_COMPANY_DISPLAY_AND_NOTIFICATIONS_PAGE = `${SETTINGS_COMPANY_PAGE}/display-and-notifications`;

export const SETTINGS_COMPANY_DISCOUNT_AND_LOYALTY_PAGE = `${SETTINGS_COMPANY_PAGE}/discount-and-loyalty`;

// ACCESS
export const ACCESS_ROOT_PAGE = '/access';
export const ACCESS_GATES_PAGE = `${ACCESS_ROOT_PAGE}/gates`;
export const ACCESS_GATE_EDIT_ITEM_PAGE = ({ id }) => `${ACCESS_GATES_PAGE}/edit/${id}`;
export const ACCESS_LOGS_PAGE = `${ACCESS_ROOT_PAGE}/logs`;
export const ACCESS_LOGS_SELECTED_GATE_PAGE = ({ id }) => `${ACCESS_LOGS_PAGE}?gate=${id}`;
export const ACCESS_PROFILES_PAGE = `${ACCESS_ROOT_PAGE}/profiles`;
export const ACCESS_PROFILES_ADD_PAGE = `${ACCESS_PROFILES_PAGE}/add`;
export const ACCESS_PROFILES_EDIT_ITEM_PAGE = ({ id }) => `${ACCESS_PROFILES_PAGE}/edit/${id}`;
