import React from 'react';
import PropTypes from 'prop-types';

const Trash2 = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.5 13.4444C3.5 14.3 4.175 15 5 15H11C11.825 15 12.5 14.3 12.5 13.4444V4.61111C12.5 4.33497 12.2761 4.11111 12 4.11111H4C3.72386 4.11111 3.5 4.33497 3.5 4.61111V13.4444ZM5 5.91667C5 5.7786 5.11193 5.66667 5.25 5.66667H10.75C10.8881 5.66667 11 5.7786 11 5.91667V13.1944C11 13.3325 10.8881 13.4444 10.75 13.4444H5.25C5.11193 13.4444 5 13.3325 5 13.1944V5.91667ZM10.7312 1.77778C10.6633 1.77778 10.5984 1.75018 10.5513 1.70131L9.94874 1.07647C9.90162 1.0276 9.83665 1 9.76877 1H6.23123C6.16335 1 6.09838 1.0276 6.05126 1.07647L5.44873 1.70131C5.40162 1.75018 5.33665 1.77778 5.26877 1.77778H3.25C2.97386 1.77778 2.75 2.00164 2.75 2.27778V2.83333C2.75 3.10948 2.97386 3.33333 3.25 3.33333H12.75C13.0261 3.33333 13.25 3.10948 13.25 2.83333V2.27778C13.25 2.00163 13.0261 1.77778 12.75 1.77778H10.7312Z"
                fill="currentColor"
            />
        </svg>
    );
};

Trash2.propTypes = {
    className: PropTypes.string,
};

Trash2.defaultProps = {
    className: '',
};

export default Trash2;
