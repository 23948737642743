import { createSelector } from 'reselect';

export const messagesSelector = (state) => state.messages;

export const messagesListSelector = createSelector(
    messagesSelector,
    (messages) => messages.messages,
);

export const companyMessagesSelector = createSelector(
    messagesSelector,
    (messages) => messages.companyMessages,
);

export const messageItemSelector = createSelector(
    messagesSelector,
    (messages) => messages.message,
);

export const endMessagesSelector = createSelector(
    companyMessagesSelector,
    (companyMessages) => companyMessages.items.flatMap(
        (companyMessage) => (
            companyMessage?.subOptions?.length
                ? companyMessage?.subOptions
                : companyMessage
        ),
    ),
);
