import React, { useMemo, memo } from 'react';

// Hooks
import {
    useState, useEffect, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import * as actions from 'store/actions';

// Components
import Skeleton from 'react-loading-skeleton';
import { TailSpin } from 'react-loader-spinner';
import _ from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { DEFAULT_DATE_FORMAT } from 'const/time/DEFAULT_TIME_FORMAT';
import Booking from '../Booking/Booking';
import Block from '../Block/Block';
import BookingsList from '../BookingsList/BookingsList';

// Styles
import * as styles from './Timeline.module.scss';
import TimelineHeader from './TimelineHeader';

const translateDay = (day) => {
    const days = {
        monday: 'monday',
        tuesday: 'tuesday',
        wednesday: 'wednesday',
        thursday: 'thursday',
        friday: 'friday',
        saturday: 'saturday',
        sunday: 'sunday',
        maandag: 'monday',
        dinsdag: 'tuesday',
        woensdag: 'wednesday',
        donderdag: 'thursday',
        vrijdag: 'friday',
        zaterdag: 'saturday',
        zondag: 'sunday',
        montag: 'monday',
        dienstag: 'tuesday',
        mittwoch: 'wednesday',
        donnerstag: 'thursday',
        freitag: 'friday',
        samstag: 'saturday',
        sonntag: 'sunday',
        pirmadienis: 'monday',
        antradienis: 'tuesday',
        trečiadienis: 'wednesday',
        ketvirtadienis: 'thursday',
        penktadienis: 'friday',
        šeštadienis: 'saturday',
        sekmadienis: 'sunday',
    };

    return days[day];
};

const BookingDayLoader = memo(({ i }) => (
    <Booking
        key={`booking-${i}`}
        booking={null}
        style={{
            gridColumn: 3 + i,
            gridRowStart: (2) + Math.floor(Math.random() * 5),
            gridRowEnd: (3) + Math.floor(Math.random() * 5),
        }}
    />
));

const BookingsLoader = ({
    startCol, startRow, gridHeight, gridWidth,
}) => (
    <div
        className="d-flex justify-content-center pt-5"
        style={{
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            zIndex: 100,
            gridArea: `${startCol}/${startRow}/${gridHeight}/${gridWidth}`,
        }}
    >
        <TailSpin
            color="black"
            width={75}
            height={75}
        />
    </div>
);

const PlainCell = ({
    i, j, k, isNotBookable, onClick,
}) => (
    <div
        className={classNames({
            [styles.notBookable]: isNotBookable,
        }, styles.cell)}
        style={{ gridColumn: j + 3, gridRow: i * 4 + k + 2 }}
        onClick={isNotBookable ? null : onClick}
    />
);

const Timeline = ({
    globalDate, day, week, month, year, view = 'day', className, onBookingClick, onClick, dataType = 'objects', type,
}) => {
    const isDayView = view === 'day';
    const isWeekView = view === 'week';
    const isMonthView = view === 'month';

    const products = useSelector((state) => state[dataType]?.[dataType === 'groups' ? 'sessions' : dataType]
        ?.filter((product) => {
            if (dataType === 'groups') {
                return !!state[dataType].filters[product.product?.id] || !product.product?.id;
            }

            return !!state[dataType].filters[product.id];
        })
        .sort((a, b) => {
            if (dataType !== 'employees') {
                return 0;
            }

            return (!!a.services.length === !!b.services.length)
                ? 0 : a.services.length
                    ? -1 : 1;
        }));

    const filters = useSelector((state) => state[dataType].filters);

    const bookings = useSelector((state) => state[dataType].bookings
        ?.filter((booking) => {
            if (dataType === 'groups') return false;
            return !booking || !filters || filters[booking.product.id];
        }));

    const blocks = useSelector((state) => state[dataType]?.[dataType]?.map((product) => product?.timeBlocks?.map((block) => ({
        ...block,
        productId: product.id,
    }))))?.flat();

    const bookingsLoading = useSelector((state) => (dataType === 'groups'
        ? state[dataType].sessionsLoading
        : state[dataType].bookingsLoading));

    // shop open/close time
    const { from: openHour, to: closeHour } = useSelector((state) => (state.shop.workingDays ? Object.entries(state.shop.workingDays)
        .reduce(({ from, to }, [key, openingHours]) => {
            if (isDayView) {
                if (translateDay(day.format('dddd').toLowerCase()) === key) {
                    return openingHours;
                }
            } else {
                return {
                    from: moment(openingHours.from, 'H:mm').isBefore(moment(from, 'H:mm')) || !from ? openingHours.from : from,
                    to: moment(openingHours.to, 'H:mm').isAfter(moment(to, 'H:mm')) || !to ? openingHours.to : to,
                };
            }

            return { from, to };
        }, { from: null, to: null }) : {}));

    const workingHours = useSelector((state) => state.shop?.workingDays);

    const dispatch = useDispatch();

    const [lineWidth, setLineWidth] = useState(0);
    const [index, setIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [showScroll, setShowScroll] = useState(false);

    const getMaxObjectsVisible = () => (window.innerWidth < 1200 ? (window.innerWidth < 992 ? (window.innerWidth < 768 ? (window.innerWidth < 576 ? 1 : 2) : 3) : Infinity) : Infinity);
    const [objectsVisible, setObjectsVisible] = useState(getMaxObjectsVisible());

    const [currentTime, setCurrentTime] = useState(moment().tz('Europe/Amsterdam'));

    const containerRef = useRef(null);

    useEffect(() => {
        if (!products?.length) return;

        if (index > products.length - objectsVisible) {
            setIndex(Math.max(index - 1, 0));
        }
    }, [products?.length, objectsVisible, index]);

    useEffect(/* istanbul ignore next */ () => {
        if (!containerRef.current) {
            return;
        }

        const handleResize = () => {
            setLineWidth(containerRef.current.clientWidth - 45);
            setObjectsVisible(getMaxObjectsVisible());
            setWindowWidth(window.innerWidth);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(/* istanbul ignore next */() => {
        const interval = setInterval(() => setCurrentTime(moment().tz('Europe/Amsterdam')), 5000);
        return () => clearInterval(interval);
    }, []);

    // timeline open/close time
    const { open, close } = useMemo(() => {
        const defaultOpenHours = moment(openHour || '8:00', 'HH:mm');
        const defaultCloseHours = moment(closeHour || '20:00', 'HH:mm');

        let productEarliestHours = defaultOpenHours.clone();
        let productLatestHours = defaultCloseHours.clone();

        let earliestBookingHours = defaultOpenHours.clone();
        let latestBookingHours = defaultCloseHours.clone();

        const productsOwnHours = products?.map((product) => {
            if (!product?.hasOwnSchedule) {
                return { from: 0, to: 0 };
            }

            let earliest = Math.min(...Object.values(product.workingDays).map(({ from }) => moment(from, 'HH:mm').unix()));
            let latest = Math.max(...Object.values(product.workingDays).map(({ to }) => moment(to, 'HH:mm').unix()));

            if (product.workingDays?.[day?.format('dddd').toLowerCase()]) {
                earliest = moment(product.workingDays[day?.format('dddd').toLowerCase()].from, 'HH:mm').unix();
                latest = moment(product.workingDays[day?.format('dddd').toLowerCase()].to, 'HH:mm').unix();
            }

            return {
                from: earliest,
                to: latest,
            };
        }).filter(({ from, to }) => from && to);

        if (productsOwnHours.length) {
            productEarliestHours = moment(Math.min(...productsOwnHours.map(({ from }) => from)) * 1000);
            productLatestHours = moment(Math.max(...productsOwnHours.map(({ to }) => to)) * 1000);
        }

        if (bookings?.length) {
            earliestBookingHours = moment(Math.min(...bookings.map(({ time: { from } }) => from)));
            latestBookingHours = moment(Math.max(...bookings.map(({ time: { to } }) => to)));
        }

        [productEarliestHours, productLatestHours, earliestBookingHours, latestBookingHours].forEach((hour) => {
            hour.year(defaultOpenHours.year()).month(defaultOpenHours.month()).date(defaultOpenHours.date());
        });

        return {
            open: moment(Math.min(productEarliestHours.unix(), earliestBookingHours.unix(), defaultOpenHours.unix()) * 1000),
            close: moment(Math.max(productLatestHours.unix(), latestBookingHours.unix(), defaultCloseHours.unix()) * 1000),
        };
    }, [products, bookings, day, openHour, closeHour]);

    const { gridTemplateRows, gridTemplateColumns } = useMemo(() => {
        let gridTemplateRows;
        let gridTemplateColumns;
        switch (view) {
        case 'day':
            gridTemplateRows = `auto ${_.times((close.hour() - open.hour()) * 4, _.constant('60px')).join(' ')}`;
            gridTemplateColumns = objectsVisible !== Infinity
                ? `25px 25px ${_.times(objectsVisible, _.constant(`calc(${1 / Math.min(products ? products.length : 4, objectsVisible) * 100}% - 50px / ${Math.min(products ? products.length : 4, objectsVisible)})`)).join(' ')}`
                : `25px 25px ${_.times(products.length, _.constant('200px')).join(' ')}`;
            break;
        case 'week':
            gridTemplateRows = `${_.times((close.hour() - open.hour() + 1), _.constant('minmax(98px, max-content)')).join(' ')}`;
            gridTemplateColumns = `25px 25px ${_.times(7, _.constant('calc((100% - 50px) / 7)')).join(' ')}`;
            break;
        default:
            gridTemplateRows = 'auto';
            gridTemplateColumns = `${_.times(7, _.constant('calc(100% / 7)')).join(' ')}`;
            break;
        }

        return { gridTemplateRows, gridTemplateColumns };
    }, [view, objectsVisible, products, close, open]);

    const emptyDayCell = (i) => {
        const setBookingDetails = (j, k) => {
            const selectedTime = day.clone().hour(open.hour() + i).minutes(k * 15);
            const productDetails = {
                ...products[j + index],
                type: dataType.substring(0, dataType.length - 1),
            };
            dispatch(actions.setAddBookingDetails(selectedTime, productDetails));
        };

        const isInWorkBreak = (j, k) => {
            const currentTime = moment(`${open.hour() + i}:${k * 15 + 1}`, 'H:m');
            return !!products[j + index]?.workBreaks?.find(({ from, to, weekday }) => weekday === translateDay(day.format('dddd').toLowerCase()) && currentTime.isBetween(moment(from, 'HH:mm'), moment(to, 'HH:mm')));
        };

        const hasOwnSchedule = (j) => !!products[j + index]?.hasOwnSchedule;

        const getOwnScheduleToday = (j) => Object.entries(products[j + index].workingDays).find(
            ([weekday]) => weekday === translateDay(day.format('dddd').toLowerCase()),
        );

        const getSpecialDayToday = (j) => products[j + index].specialDays?.[day.format(DEFAULT_DATE_FORMAT)];

        const isOutsideOwnSchedule = (j, k, from, to) => {
            const currentTime = moment(`${open.hour() + i}:${k * 15 + 1}`, 'HH:mm');
            return !currentTime.isBetween(moment(from, 'HH:mm'), moment(to, 'HH:mm'));
        };

        const isDuringShopHours = (j, k) => {
            const specialDay = getSpecialDayToday(j);
            const currentDayOpenHour = specialDay?.isWorkingDay ? moment(specialDay.from, 'HH:mm') : moment(openHour, 'HH:mm');
            const currentDayCloseHour = specialDay?.isWorkingDay ? moment(specialDay.to, 'HH:mm') : moment(closeHour, 'HH:mm');
            const currentTime = moment(`${open.hour() + i}:${k * 15 + 1}`, 'HH:mm');
            return currentTime.isBetween(currentDayOpenHour, currentDayCloseHour);
        };

        const isCellBookable = (j, k) => {
            if (!products[j + index]?.services.length) return false;
            if (isInWorkBreak(j, k)) return false;
            const specialDay = getSpecialDayToday(j);
            if (specialDay) {
                const { isWorkingDay, from, to } = specialDay;
                if (!isWorkingDay) return false;
                if (isOutsideOwnSchedule(j, k, from, to)) return false;
            }
            if (hasOwnSchedule(j)) {
                const schedule = getOwnScheduleToday(j);
                if (schedule) {
                    const [, { from, to }] = schedule;
                    return !isOutsideOwnSchedule(j, k, from, to);
                }
                return false;
            }
            return isDuringShopHours(j, k);
        };

        return _.times(Math.min(objectsVisible, products ? products.length : 4), (j) => _.times(4, (k) => {
            if (!day || !products || !products[j + index]) return;
            const isNotBookable = !isCellBookable(j, k);
            return (
                <PlainCell
                    key={`hour-${i}-${j}-${k}`}
                    i={i}
                    j={j}
                    k={k}
                    isNotBookable={isNotBookable}
                    onClick={() => setBookingDetails(j, k)}
                />
            );
        }));
    };

    const isWeekCellNotBookable = (i, j) => {
        const currentHour = moment().hour(open.hour() + i).minute(0);
        const checkingWeek = moment().isoWeek(week).year(year);

        const checkingDay = checkingWeek.clone().isoWeekday(j + 1).format(DEFAULT_DATE_FORMAT);
        const specialDay = products?.[index]?.specialDays?.[checkingDay];
        if (specialDay) {
            if (!specialDay.isWorkingDay) {
                return true;
            }
            const specialDayTo = moment(specialDay?.to, 'HH:mm');
            const specialDayFrom = moment(specialDay?.from, 'HH:mm');
            if (!currentHour.isBetween(specialDayFrom, specialDayTo)) {
                return true;
            }
        }

        const currentWeekday = moment().isoWeekday(j + 1).format('dddd').toLowerCase();
        const workingHourFrom = workingHours?.[currentWeekday]?.from;
        const workingHourTo = workingHours?.[currentWeekday]?.to;

        const currentDayOpenHour = specialDay?.isWorkingDay ? specialDay.from : workingHourFrom;
        const currentDayCloseHour = specialDay?.isWorkingDay ? specialDay.to : workingHourTo;

        const noTimeData = !currentDayOpenHour && !currentDayCloseHour;
        const isCurrentHourNotBetween = !currentHour.isBetween(moment(currentDayOpenHour, 'HH:mm'), moment(currentDayCloseHour, 'HH:mm'));

        return isCurrentHourNotBetween || noTimeData;
    };

    useEffect(() => {
        if (!containerRef.current || view !== 'day') {
            return;
        }

        const $wrapper = containerRef.current;
        const $wrapperOffsetRight = $wrapper.offsetLeft + $wrapper.offsetWidth;
        const $lastHeader = $wrapper.children[products.length + 1];
        const $lastHeaderOffsetRight = ($lastHeader?.offsetLeft || 0) + ($lastHeader?.offsetWidth || 0);

        setShowScroll(
            window.innerWidth >= 992
            && ($lastHeaderOffsetRight - $wrapperOffsetRight > 1),
        );
    }, [objectsVisible, products.length, window.innerWidth]);

    const currentTimeInGrid = currentTime.clone().add(currentTime.utcOffset(), 'minute');

    return (
        <div
            ref={containerRef}
            className={[styles.container, styles[view], className, 'timeline'].join(' ')}
            style={{
                gridTemplateRows,
                gridTemplateColumns,
                overflowX: showScroll ? 'auto' : undefined,
            }}
        >
            {view !== 'month' && (
                <>
                    <div className={styles.hourColumn} />
                    <div className={classNames(
                        styles.spacer,
                        isWeekView && moment().isSame(globalDate?.clone().isoWeekday(1), 'day') && styles.selectedNext,
                    )}
                    />
                </>
            )}
            {isWeekView && bookingsLoading && (
                <BookingsLoader
                    startCol={2}
                    startRow={3}
                    gridHeight={Math.round(close.diff(open, 'hours', true) + 3)}
                    gridWidth={10}
                />
            )}

            {isMonthView && bookingsLoading && (
                <BookingsLoader
                    startCol={2}
                    startRow={1}
                    gridHeight={8}
                    gridWidth={8}
                />
            )}

            <TimelineHeader
                globalDate={globalDate}
                year={year}
                view={view}
                dataType={dataType}
                index={index}
                setIndex={setIndex}
                products={products}
                showScroll={showScroll}
                windowWidth={windowWidth}
                objectsVisible={objectsVisible}
            />

            {(isDayView || isWeekView) && _.times((close.hour() + 1 - open.hour()), (hour) => (
                <React.Fragment key={`hour-${hour}`}>
                    {_.times(isDayView ? 4 : 1, (min15) => {
                        const startTime = open.clone().add(hour, 'hour').add(min15 * 15, 'minutes');
                        const endTime = open.clone().add(hour + Number(isWeekView), 'hour').add(min15 * 15 + 15, 'minutes');
                        const inOpenedCondition = !!open && !!close;

                        return (
                            <React.Fragment key={min15}>
                                <div className={styles.hourColumn}>
                                    {inOpenedCondition
                                        ? (
                                            <span className={styles.hour}>
                                                {hour + open.hour()}
                                                {min15 * 15 ? `:${min15 * 15}` : ':00'}
                                            </span>
                                        )
                                        : (
                                            <span className={styles.hour}>
                                                <Skeleton style={{ top: -5 }} width={25} height={14} />
                                            </span>
                                        )}

                                    {inOpenedCondition && ((isDayView && currentTimeInGrid.isSame(day, 'day')) || (isWeekView && currentTimeInGrid.isSame(moment().isoWeek(week).year(year), 'week')))
                            && currentTimeInGrid.isBetween(startTime, endTime)
                            && (
                                <div className={styles.currentTime} style={{ top: `${(moment.duration(currentTimeInGrid.diff(open)).asMinutes() % 60) / 60 * 100}%` }}>
                                    {currentTime.format('HH:mm')}
                                    <div className={styles.line} style={{ width: lineWidth }} />
                                </div>
                            )}
                                </div>
                                <div key={min15} className={styles.spacer} />
                            </React.Fragment>
                        );
                    })}

                    {isDayView && emptyDayCell(hour)}

                    {isWeekView && _.times(7, (day) => (
                        <div
                            key={`hour-${hour}-${day}`}
                            className={classNames({
                                [styles.notBookable]: isWeekCellNotBookable(hour, day),
                            }, styles.cell)}
                            style={{ gridColumn: day + 3, gridRow: hour + 2 }}
                            onClick={() => !isWeekCellNotBookable(hour, day) && dispatch(actions.setAddBookingDetails(globalDate.isoWeekday(day + 1).hour(open.hour() + hour).minutes(0).seconds(0), null))}
                        />
                    ))}
                </React.Fragment>
            ))}

            {isMonthView && _.times(moment().month(month).year(year).endOf('month')
                .endOf('isoWeek')
                .diff(moment().month(month).year(year).startOf('month')
                    .startOf('isoWeek'), 'days') + 1, (i) => {
                const date = moment().month(month).year(year).startOf('month')
                    .startOf('isoWeek')
                    .add(i, 'day');
                return (
                    <div
                        key={`day-${i}`}
                        className={styles.day}
                        style={{
                            gridColumn: windowWidth <= 991 ? 1 : i % 7 + 1,
                            gridRow: windowWidth <= 991 ? i + 1 : Math.floor(i / 7) + 2,
                            paddingBottom: 'calc(100% - 35px)',
                        }}
                    >
                        <span
                            className={classNames(styles.date, moment().isSame(date, 'day') && styles.selected)}
                        >
                            { date.format('D') }
                        </span>
                    </div>
                );
            })}

            {isDayView && blocks && blocks.map((block) => {
                if (!block) return;
                const blockId = block.id;
                const from = moment(block.from * 1000);
                const to = moment(block.to * 1000);
                const productBlock = products && filters && products.filter(({ id }) => !!filters[id]).find(({ id }) => id === block.productId);
                const productIndex = products && filters && products.filter(({ id }) => !!filters[id]).indexOf(products.find(({ id }) => id === block.productId));
                const isIndexOutGrid = blocks.indexOf(block) === -1 || !filters || (block && !filters[block.productId]) || productIndex < index || productIndex >= index + Math.min(products && products.length, objectsVisible);

                // values for block in one-day range
                let gridRowStart = Math.round((from.hour() - open.hour() + 1) * 4 + from.minutes() / 15 - 2);
                if (from.hour() < open.hour()) {
                    gridRowStart = Math.round(4 + from.minutes() / 15 - 2);
                }
                let gridRowEnd = Math.round((to.hour() - open.hour()) * 4 + to.minutes() / 15 + 2);
                let shouldRender = isIndexOutGrid || !from.isSame(day, 'day');

                const diff = from.diff(to, 'days');
                // for multiply days
                if (diff < 0) {
                    if (from.isSame(day, 'day')) {
                        gridRowEnd = Math.round((close.hour()) * 4 + to.minutes() / 15 + 2);
                        shouldRender = isIndexOutGrid;
                    } else if (day.isBetween(from, to) && !day.isSame(from, 'day') && !day.isSame(to, 'day')) {
                        gridRowStart = 2;
                        gridRowEnd = Math.round((close.hour()) * 4 + to.minutes() / 15 + 2);
                        shouldRender = isIndexOutGrid;
                    } else if (to.isSame(day, 'day')) {
                        gridRowStart = 2;
                        shouldRender = isIndexOutGrid || (to.hour() < open.hour());
                    }
                }

                if (shouldRender) return null;

                return (
                    <Block
                        key={`block-${block.id}`}
                        type={type}
                        style={{
                            gridColumn: productIndex + 3 - index,
                            gridRowStart,
                            gridRowEnd,
                        }}
                        blockId={blockId}
                        productBlock={productBlock}
                        from={from}
                        to={to}
                        comment={block.comment}
                        multiply={!!diff}
                    />
                );
            })}

            {isDayView && bookings && !bookingsLoading && bookings.map((booking) => {
                const from = moment(booking.time.from);
                const to = moment(booking.time.to);
                const extraTimeInBlocks = (booking.time.extraMinutes ?? 0) / 15;
                const extraBlocks = Math.ceil(extraTimeInBlocks);

                const productIndex = (
                    products
                        && filters
                        && products
                            .filter(({ id }) => Boolean(filters[id]))
                            .indexOf(products.find(({ id }) => (
                                booking.product
                                && id === booking.product.id
                            )))
                );

                if (
                    bookings.indexOf(booking) === -1
                        || !filters
                        || (booking.product && !filters[booking.product.id])
                        || productIndex < index
                        || productIndex >= index + Math.min(products && products.length, objectsVisible)
                        || !moment(booking.time.from).isSame(day, 'day')
                ) {
                    return null;
                }

                let countHour = from.hour() - open.hour();
                if (from.hour() < open.hour()) {
                    countHour = open.hour();
                }

                const timeStart = (countHour + 1) * 4 + from.minutes() / 15 - 2;
                const timeEnd = ((to.hour() - open.hour()) * 4 + to.minutes() / 15 + 2);

                const gridRowStart = Math.round(timeStart);
                const gridRowEnd = Math.round(timeEnd) + extraBlocks;

                const quartersTimeEnd = timeEnd + extraBlocks;
                const quartersBlocks = quartersTimeEnd - gridRowStart;

                return (
                    <Booking
                        key={`booking-${booking.id}`}
                        booking={booking}
                        style={{
                            gridColumn: productIndex + 3 - index,
                            gridRowStart,
                            gridRowEnd,
                        }}
                        block
                        quartersBlocks={quartersBlocks}
                        extraBlocks={extraBlocks}
                        extraTimeInBlocks={extraTimeInBlocks}
                        onClick={() => onBookingClick({ id: booking.id, timestamp: booking.time.from })}
                        dataType={dataType}
                    />
                );
            })}

            {isDayView && bookingsLoading && _.times(objectsVisible, (i) => <BookingDayLoader key={i} i={i} />)}

            {isWeekView && bookings && Object.entries(bookings
                .reduce((bookings, booking) => {
                    const start = moment(booking.time.from).startOf('hour');
                    const formatted = start.format('DD/MM/YYYY HH:mm');

                    return {
                        ...bookings,
                        [formatted]: [
                            ...bookings[formatted] || [],
                            booking,
                        ],
                    };
                }, {}))
                .map(([formatted, bookings]) => {
                    const date = moment(formatted, 'DD/MM/YYYY HH:mm');

                    return (
                        <BookingsList
                            key={`bookings-${formatted}`}
                            data-testid={`bookings-${formatted}`}
                            bookings={bookings}
                            style={{
                                gridColumn: date.isoWeekday() + 2,
                                gridRowStart: (date.hour() - open.hour() + 2),
                            }}
                            onClick={() => onClick(date)}
                        />
                    );
                })}

            {isMonthView && !!(dataType === 'groups' ? products : bookings) && Object.entries((dataType === 'groups' ? products : bookings)
                .reduce((bookings, booking) => {
                    const start = moment(booking.time.from).startOf('day');
                    const formatted = start.format('DD/MM/YYYY');

                    if (!start.isSame(moment().month(month).year(year), 'month')) {
                        return bookings;
                    }

                    return {
                        ...bookings,
                        [formatted]: [
                            ...bookings[formatted] || [],
                            booking,
                        ],
                    };
                }, {}))
                .map(([formatted, bookings]) => {
                    const date = moment(formatted, 'DD/MM/YYYY');
                    const month = date.clone().month();
                    let currentWeek = date.clone().isoWeek();
                    if (month === 0 && (currentWeek >= 1 && currentWeek <= 5)) {
                        currentWeek += date.clone().startOf('year').isoWeek();
                    }
                    if (month === 11 && (currentWeek <= 1)) {
                        currentWeek += date.clone().isoWeeksInYear();
                    }
                    return (
                        <div
                            key={`bookings-${formatted}`}
                            data-testid={`bookings-${formatted}`}
                            className={classNames(styles.day, styles.hoverable, 'd-flex flex-column')}
                            style={{
                                gridColumn: windowWidth <= 991 ? 1 : date.isoWeekday(),
                                gridRowStart: windowWidth <= 991 ? date.diff(date.clone().startOf('month').startOf('isoWeek'), 'days') + 1 : currentWeek - date.clone().startOf('month').isoWeek() + 2,
                            }}
                            onClick={() => onClick(date)}
                        >
                            <span
                                className={classNames(styles.date, moment().isSame(date, 'day') && styles.selected)}
                            >
                                {date.format('D')}
                            </span>

                            <BookingsList
                                key={`bookings-${formatted}`}
                                className="flex-grow-1"
                                bookings={bookings.sort(({ time: { from: a } }, { time: { from: b } }) => a - b)}
                                onClick={() => onClick(date)}
                                productType={dataType}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default Timeline;
