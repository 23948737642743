import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import { Dropdown } from 'react-bootstrap';
import { BundleSelectFormRow } from './BundleSelectFormRow';

import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { ClientFormRow } from './ClientFormRow';
import { BundleSelectPaymentRow } from './BundleSelectPaymentRow';
import { NoteFormRow } from './NoteFormRow';

const SellBundleForm = (props) => {
    const {
        values,
        bundles,
        validation,
        onGetClients,
        onChangeNote,
        onChangeClient,
        onCreateClient,
        onChangeBundle,
        onChangeClientPhone,
        onChangeClientEmail,
        onSelectPayment,
        isClientDisabled,
    } = props;

    const bundlesHash = useMemo(() => (
        bundles.reduce((res, b) => ({
            ...res,
            [b.id]: { ...b },
        }), {})
    ), [bundles]);

    const selectedBundle = bundlesHash[values.bundle];

    const isNotZeroPrice = selectedBundle?.price !== 0;

    const isBundleSelected = Boolean(values.bundle);
    return (
        <Container
            fluid
            className="m-0 p-0"
        >
            <BundleSelectFormRow
                values={values}
                bundles={bundles}
                selectedBundle={selectedBundle}
                validation={validation}
                onChangeBundle={onChangeBundle}
            />
            <Dropdown.Divider />
            <ClientFormRow
                values={values}
                validation={validation}
                onGetClients={onGetClients}
                onChangeClient={onChangeClient}
                onCreateClient={onCreateClient}
                onChangeClientPhone={onChangeClientPhone}
                onChangeClientEmail={onChangeClientEmail}
                disabled={isClientDisabled}
            />
            {isBundleSelected && (
                <>
                    <Dropdown.Divider />
                    <BundleSelectPaymentRow
                        values={values}
                        selectedBundle={selectedBundle}
                        validation={validation}
                        onSelectPayment={onSelectPayment}
                    />
                    {isNotZeroPrice && (
                        <NoteFormRow
                            values={values}
                            validation={validation}
                            onChangeNote={onChangeNote}
                        />
                    )}
                </>
            )}
        </Container>
    );
};

SellBundleForm.propTypes = {
    values: PropTypes.object.isRequired,
    bundles: PropTypes.array,
    isClientDisabled: PropTypes.bool,
    validation: PropTypes.object,
    onGetClients: PropTypes.func,
    onChangeNote: PropTypes.func,
    onChangeClient: PropTypes.func,
    onCreateClient: PropTypes.func,
    onChangeBundle: PropTypes.func,
    onChangeClientPhone: PropTypes.func,
    onChangeClientEmail: PropTypes.func,
};

SellBundleForm.defaultProps = {
    bundles: [],
    validation: null,
    isClientDisabled: false,
    onGetClients: emptyFunc,
    onChangeNote: emptyFunc,
    onChangeClient: emptyFunc,
    onCreateClient: emptyFunc,
    onChangeBundle: emptyFunc,
    onChangeClientPhone: emptyFunc,
    onChangeClientEmail: emptyFunc,
};

export default SellBundleForm;
