import SubscriptionsList from './subscriptionsList';
import SoldSubscriptions from './soldSubscriptions';
import AddNewSubscription from './addNewSubscription';
import EditSubscription from './editSubscription';

const routes = [
    {
        component: SubscriptionsList,
        exact: true,
        path: '/services/subscriptions/list',
    },
    {
        component: SoldSubscriptions,
        exact: true,
        path: '/services/subscriptions/sold',
    },
    {
        component: AddNewSubscription,
        exact: true,
        path: '/services/subscriptions/add',
    },
    {
        component: EditSubscription,
        exact: true,
        path: '/services/subscriptions/edit/:id',
    },

];

export default routes;
