import React from 'react';

const Discount = (
    {
        fill = 'currentColor',
        secondaryFill = fill,
        strokeWidth = 2,
        width = '100%',
        // height = '100%',
        className,
        // style,
    },
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="none"
        width={width}
        viewBox="0 0 24 24"
        stroke={secondaryFill}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
            d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
        />
    </svg>
);

export default Discount;
