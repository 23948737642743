import React from 'react';

const Calendar = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <line fill="none" x1="17" x2="17" y1="4" y2="1" />
            <line fill="none" x1="7" x2="7" y1="4" y2="1" />
            <line fill="none" strokeLinecap="butt" x1="1" x2="23" y1="8" y2="8" />
            <rect height="18" width="22" fill="none" stroke={fill} x="1" y="4" />
        </g>
    </svg>
);

export default Calendar;
