import React, { useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
    getSubscriptionPeriodsUsages,
    resetEditSubscriptionUsages,
} from 'store/actions';
import {
    editSubscriptionUsagesSelector,
} from 'store/selectors/subscriptions';
import { Button } from 'components/Common/Button';
import SubscriptionsPerPeriods from './SubscriptionsPerPeriods';

function EditSoldSubscriptionModal({ startDate }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        formInitialState,
        usages: subscriptions,
    } = useSelector(editSubscriptionUsagesSelector);

    useEffect(() => {
        dispatch(getSubscriptionPeriodsUsages(startDate));
    }, [dispatch]);

    const formik = useFormik({
        initialValues: formInitialState,
        enableReinitialize: true,
        onSubmit: () => {}, // will be implemented later
    });

    const submitDisabled = Object.keys(formik.errors).length;
    const editEnabled = false;

    const onCloseEditSubscriptionModal = () => dispatch(resetEditSubscriptionUsages());

    const {
        nextMonthly,
        nextWeekly,
        prevMonthly,
        prevWeekly,
        usages,
    } = subscriptions;

    const weeklyUsage = usages && usages.weekly;
    const monthlyUsage = usages && usages.monthly;

    return (
        <Modal
            centered
            size="xl"
            onHide={onCloseEditSubscriptionModal}
            show
        >
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header
                    closeButton
                    onHide={onCloseEditSubscriptionModal}
                >
                    <Modal.Title>
                        {editEnabled ? t('subscriptions.pageTitle.editSubscription')
                            : t('subscriptions.pageTitle.showSubscription')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    {
                        !weeklyUsage && !monthlyUsage && (
                            <div style={{
                                display: 'flex',
                                flex: '1 1 auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                <div
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                    }}
                                >
                                    <TailSpin
                                        color="black"
                                        width={100}
                                        height={100}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {monthlyUsage
                        && (
                            <SubscriptionsPerPeriods
                                key="monthly"
                                usages={monthlyUsage}
                                nextDate={nextMonthly}
                                prevDate={prevMonthly}
                                formik={formik}
                            />
                        )}
                    {weeklyUsage
                        && (
                            <SubscriptionsPerPeriods
                                key="weekly"
                                usages={weeklyUsage}
                                nextDate={nextWeekly}
                                prevDate={prevWeekly}
                                formik={formik}
                            />
                        )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        variant="outline"
                        onClick={onCloseEditSubscriptionModal}
                    >
                        {t('placeholders.cancel')}
                    </Button>
                    {
                        editEnabled
                        && (
                            <Button
                                type="submit"
                                color="yellow"
                                disabled={submitDisabled}
                            >
                                {t('placeholders.save')}
                            </Button>
                        )
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default EditSoldSubscriptionModal;
