import { createSelector } from 'reselect';

// WARNING
// selector to get products used in Agenda to fetch all products ONLY!!!
// the rest of code uses old approach

const formProductData = (data, type, withFilters) => {
    // TODO change event structure
    if (type === 'events') {
        return {
            bookings: null,
            filters: null,
            products: data[type],
            blocks: null,
            isLoaded: data.loaded,
        };
    }

    const products = data?.[type]
        ?.filter((product) => {
            if (!withFilters) return true;
            if (type === 'groups') {
                return !!data?.filters[product.product?.id] || !product.product?.id;
            }
            return !!data?.filters[product.id];
        });

    return {
        bookings: data?.bookings?.filter(
            (booking) => data?.filters?.[booking.product?.id],
        ),
        filters: data?.filters,
        sessions: data?.sessions,
        products,
        blocks: data?.[type]
            ?.map((product) => product?.timeBlocks?.map((block) => ({
                ...block,
                productId: product.id,
            })))
            .flat(),
        isLoaded: data.loaded,
    };
};

const modelSelector = (type) => (state) => state[type];

export const filterSelector = (type) => createSelector(modelSelector(type), (data) => data?.filters);

export const productListToFilterSelector = (type) => createSelector(modelSelector(type), (data) => (type === 'groups' ? data.employees : data?.[type]));

export const productDataSelector = (type) => createSelector(modelSelector(type), (data) => formProductData(data, type));

export const productAllDataSelector = (withFilters = false) => createSelector(
    (state) => state,
    (state) => {
        const types = ['objects', 'employees', 'groups', 'events'];

        return types.reduce((result, type) => ({
            ...result,
            [type]: formProductData(state[type], type, withFilters),
        }), {});
    },
);

export const productsLoadersSelector = () => createSelector(
    (state) => ({
        objects: state.objects,
        employees: state.employees,
        groups: state.groups,
        events: state.events,
    }),
    ({
        objects, employees, groups, events,
    }) => ({
        objects: objects.loaded,
        employees: employees.loaded,
        groups: groups.loaded,
        events: events.loaded,
        allProducts:
                objects.loaded && employees.loaded && groups.loaded && events.loaded,
    }),
);

// Products from custom invoices

export const productsSelector = (state) => state.products.products;

export const productsListSavingSelector = createSelector(
    productsSelector,
    (productsState) => productsState.saving,
);

export const productsListLoadingSelector = createSelector(
    productsSelector,
    (productsState) => productsState.loading,
);

export const productsListSelector = createSelector(
    productsSelector,
    (productsState) => productsState.products,
);
