import React from 'react';

import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/Common/Button';
import { Header } from 'components/Common/Typography/Header';
import { Label } from 'components/Common/Typography/Label';

const ConfirmationModal = (
    {
        titleText,
        bodyText,
        deleteText,
        dismissText,
        deleteColor = 'red',
        isShow,
        hide,
        loading,
        confirmAction,
        customFooter,
        restoreFocus = false,
        ...rest
    },
) => {
    const { t } = useTranslation();

    return (
        <Modal
            size="lg"
            show={isShow}
            onHide={hide}
            centered
            restoreFocus={restoreFocus}
            data-testid="data-test-confirmation-modal"
            {...rest}
        >
            <Modal.Header closeButton data-testid="data-test-confirm-dialog-header">
                <Header data-testid="data-test-title">
                    {titleText}
                </Header>
            </Modal.Header>

            <Modal.Body data-testid="data-test-confirm-dialog-text">
                { typeof bodyText === 'string' ? <Label>{bodyText}</Label> : bodyText}
            </Modal.Body>

            {customFooter || (
                <Modal.Footer data-testid="data-test-confirm-dialog-footer">
                    <div className="flex-grow-1">
                        <Button
                            color="outline"
                            onClick={hide}
                            data-testid="data-test-cancel-button"
                        >
                            {dismissText ?? t('bookingModal.cancel')}
                        </Button>
                    </div>

                    <div className="mx-n2 d-flex flex-row-reverse">
                        <Button
                            color={deleteColor}
                            className="mx-2"
                            loading={loading}
                            onClick={confirmAction}
                            data-testid="data-test-delete-button"
                        >
                            {deleteText}
                        </Button>
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default ConfirmationModal;
