import React, {
    useState, useRef, useEffect, useCallback,
} from 'react';
import Sortable from 'sortablejs';
import classNames from 'classnames';

import FileUpload from 'components/FileUpload/FileUpload';

import { generateRandomString } from 'helpers/string/generateRandomString';
import { useMobile } from 'hooks/useMobile';
import * as styles from './ImagesGallery.module.scss';
import FilesSelect from './FilesSelect';

const SORT_EXCLUDE = 'sort-exclude';
const MAX_IMAGES_COINT = 10;
const MAX_ALLOWED_FILE_SIZE = 5;

const getNewId = () => `new-${generateRandomString()}`;

const ImagesGallery = ({
    allowedFileSize = MAX_ALLOWED_FILE_SIZE,
    allowedFormats = ['jpg', 'jpeg', 'png'],
    maxImagesCount = MAX_IMAGES_COINT,
    onChange,
    square,
    images = [],
    withoutEdit = false,
}) => {
    const isMobile = useMobile();
    const gridRef = useRef(null);
    const sortableJsRef = useRef(null);
    const [galery, setGalery] = useState([]); // {id: ..., image: ...}

    useEffect(() => {
        if (images) {
            setGalery(images);
        }
    }, [images]);

    const showPlaceholder = !galery.length;

    const canAddMore = galery.length < maxImagesCount;

    const onListChange = () => {
        const list = [...gridRef.current.children].map((item) => item.dataset.id);
        setGalery((prev) => list
            .map((id) => prev.find((galeryItem) => galeryItem.id === id))
            .filter((item) => item));
    };

    useEffect(() => {
        sortableJsRef.current = new Sortable(gridRef.current, {
            animation: 150,
            onEnd: onListChange,
            filter: `.${SORT_EXCLUDE}`,
            onMove(e) {
                if (e.related.classList.contains(SORT_EXCLUDE)) {
                    return false;
                }
            },
            preventOnFilter: false, // ios fix
        });
    }, []);

    const handleChange = useCallback(async (images) => {
        onChange?.(images);
    }, [onChange]);

    const handleSelect = useCallback((id) => (image) => {
        let newGalery = [];
        if (!image) {
            newGalery = galery.filter((item) => item.id !== id);
        } else {
            const exists = galery.find((item) => item.id === id);
            if (exists) {
                newGalery = galery.map((item) => (item.id === id ? ({
                    ...item,
                    image,
                }) : item));
            } else if (canAddMore) {
                newGalery = [...galery, ({ id, image })];
            } else {
                newGalery = galery;
            }
        }
        setGalery(newGalery);
        handleChange(newGalery);
    }, [canAddMore, handleChange, galery]);

    const handleSelectMultiple = useCallback((images) => {
        if (!canAddMore) {
            return;
        }

        const galeryImages = images.map((image) => ({ id: getNewId(), image }));

        const newGalery = [...galery, ...galeryImages];
        setGalery(newGalery);
        handleChange(newGalery);
    }, [canAddMore, handleChange, galery]);

    return (
        <div
            ref={gridRef}
            className={classNames(
                styles.container,
                square && styles.square,
                'border-dashed border-darker-light rounded',
            )}
        >
            {galery.map(({ id, image }) => (
                <div
                    key={id}
                    data-id={String(id)}
                    className={classNames(
                        styles.image,
                        isMobile && styles.imageCompact,
                        withoutEdit && !id.startsWith('new-') && SORT_EXCLUDE,
                    )}
                >
                    <FileUpload
                        compact
                        onChange={handleSelect(String(id))}
                        defaultValue={image}
                        allowedFileSize={allowedFileSize}
                        allowedFormats={allowedFormats}
                        withoutEdit={withoutEdit && !id.startsWith('new-')}
                    />
                </div>
            ))}
            {canAddMore && (
                <div
                    data-id={String(galery.length)}
                    className={classNames(
                        !showPlaceholder && styles.image,
                        !showPlaceholder && isMobile && styles.imageCompact,
                        showPlaceholder && styles.imagePlaceholder,
                        SORT_EXCLUDE,
                    )}
                >
                    <FilesSelect
                        compact={!showPlaceholder}
                        onChange={handleSelectMultiple}
                        allowedFileSize={allowedFileSize}
                        allowedFormats={allowedFormats}
                        allowedFileCount={maxImagesCount - galery.length}
                    />
                </div>
            )}
        </div>
    );
};

export default ImagesGallery;
