import React, {
    useEffect, useMemo, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
    Row, Col, Form, Container,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import classNames from 'classnames';

import WindowedSelect, { components } from 'react-windowed-select';

import TextButton from 'components/Common/TextButton/TextButton';
import { Button } from 'components/Common/Button';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import MESSAGE_STATUS from 'const/marketing/MESSAGE_STATUS';
import TextSubHeader from 'components/Layout/TextSubHeader/TextSubHeader';
import { BackButton } from 'components/Common/BackButton';
import { useDialog } from 'hooks/useDialog';
import { generateRandomString } from 'helpers/string/generateRandomString';

import FileUpload from 'components/FileUpload/FileUpload';
import { DateInput } from 'components/Common/DateInput';
import { HourInput } from 'components/Common/HourInput';
import MessageDeleteDialog from 'components/Marketing/Messages/MessageDeleteDialog/MessageDeleteDialog';

import {
    Calendar, Clock, Trash3Icon,
} from 'components/Icon/Icon';

import { dangerTheme, theme } from 'styles/select';
import { selectWithGrayItems } from 'styles/selectStyles/selectWithGrayItems';

import * as MESSAGES_ACTIONS from 'store/actions/messages';
import { companySelector } from 'store/selectors/company';
import { getCompanyClients } from 'store/actions';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { MESSAGES_LIST_PAGE } from 'const/CLIENT_URL';
import MESSAGE_TYPE from 'const/marketing/MESSAGE_TYPE';

import * as styles from './page.module.scss';

const initialValues = {
    title: '',
    image: null,
    photoUrl: '',
    videoUrl: '',
    description: '',
    immediately: false,
    indefinite: false,
    beginDate: null,
    beginTime: null,
    endDate: null,
    messageToAllClients: true,
    clients: [],
    sendAlsoToEmail: true,
};

const T_PREFIX = 'item';
const MAX_TITLE_LENGTH = 128;
const MAX_VIDEO_URL_LENGTH = 250;
const MAX_DESCRIPTION_LENGTH = 10000;

const AddOrEditMessage = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.MESSAGES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const {
        item: message,
        isSavingProcess,
    } = useSelector((state) => state.messages.message);
    const {
        marketingTimezone,
    } = useSelector(companySelector);
    const [companyClients, setCompanyClients] = useState([]);
    const messageDeleteDialog = useDialog(false);
    const isEditMode = Boolean(params.id);
    const isDuplicateMode = Boolean(history.location.state?.messageId);

    const [value, setValue] = useState(null);

    const selectClientsOptions = useMemo(() => [
        { value: null, label: '' },
        ...companyClients.map((client, index) => ({
            value: client.id,
            label: `${client.firstName} ${client.lastName}`,
            email: client.email,
            phone: client.contactNumber,
            index,
        })),
    ], [companyClients]);

    const getUtcDateInMarketingTzFormat = ({ date }) => moment.utc(date.tz(marketingTimezone).format('YYYY-MM-DDTHH:mm:ss'));

    const validationSchema = useMemo(() => yup.object({
        image: yup
            .mixed()
            .required(t(`${T_PREFIX}.fileInputField.errors.required`)),
        title: yup
            .string()
            .max(MAX_TITLE_LENGTH, t(`${T_PREFIX}.title.errors.maxLength`, { length: MAX_TITLE_LENGTH }))
            .required(t(`${T_PREFIX}.title.errors.required`)),
        videoUrl: yup
            .string()
            .matches(/^((http|https):\/\/.+)|(www\..+)/, t(`${T_PREFIX}.videoUrl.errors.invalid`))
            .max(MAX_VIDEO_URL_LENGTH, t(`${T_PREFIX}.videoUrl.errors.maxLength`, { length: MAX_VIDEO_URL_LENGTH })),
        description: yup
            .string()
            .max(MAX_DESCRIPTION_LENGTH, t(`${T_PREFIX}.description.errors.maxLength`, { length: MAX_DESCRIPTION_LENGTH.toLocaleString('en-US') }))
            .required(t(`${T_PREFIX}.description.errors.required`)),
        immediately: yup
            .boolean()
            .required(),
        indefinite: yup
            .boolean()
            .required(),
        beginDate: yup
            .mixed()
            .when('immediately', {
                is: false,
                then: (beginTime) => beginTime
                    .required(t(`${T_PREFIX}.date.dateFrom.errors.required`)),
            }),
        beginTime: yup
            .mixed()
            .when('immediately', {
                is: false,
                then: (beginTime) => beginTime
                    .required(t(`${T_PREFIX}.date.timeFrom.errors.required`)),
            }),
        endDate: yup
            .mixed()
            .when('indefinite', {
                is: false,
                then: (endDate) => endDate
                    .required(t(`${T_PREFIX}.date.dateTo.errors.required`)),
            })
            .when('immediately', (immediately, schema) => schema.when('indefinite', (indefinite, schema) => schema.test({
                name: generateRandomString(),
                test: (endDate) => (
                    !immediately
                            || indefinite
                            || getUtcDateInMarketingTzFormat({ date: moment() }).isSameOrBefore(endDate, 'date')
                ),
                message: t(`${T_PREFIX}.date.dateTo.errors.currentOrLater`),
            }))),
        sendAlsoToEmail: yup
            .boolean()
            .required(),
        clients: yup
            .array()
            .min(1, t(`${T_PREFIX}.sendTo.clients.errors.atLeast`)),
    }), [t]);

    const onSubmit = useCallback((message) => {
        const formData = new FormData();
        formData.append('name', message.title);
        formData.append('image', message.image);
        formData.append('videoUrl', message.videoUrl);
        formData.append('description', message.description);
        formData.append('indefinite', message.indefinite);
        formData.append('beginDate', message.immediately
            ? getUtcDateInMarketingTzFormat({ date: moment() }).unix()
            : message.beginDate.unix());
        if (message.endDate && !message.indefinite) {
            formData.append('endDate', message.endDate.unix());
        }
        const isMessageToAllClients = message.clients.length === companyClients.length;
        formData.append('messageToAllClients', isMessageToAllClients);
        if (!isMessageToAllClients) {
            message.clients?.forEach((c, i) => formData.append(`clients[${i}]`, c.id));
        }
        formData.append('messageType', message.sendAlsoToEmail ? MESSAGE_TYPE.EMAIL_AND_PUSH : MESSAGE_TYPE.PUSH);

        if (isEditMode) {
            dispatch(MESSAGES_ACTIONS.editMessagesItem({
                messageId: params.id,
                message: formData,
                history,
            }));
        } else {
            dispatch(MESSAGES_ACTIONS.addMessagesItem({
                message: formData,
                history,
            }));
        }
    }, [dispatch, history, isEditMode, params.id, companyClients]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const isClientsSelectInvalid = !!touched.clients && !!errors.clients;

    const onSaveAndSendHandler = useCallback(() => {
        handleSubmit();
    });

    const handleChangeImage = useCallback((file) => {
        setFieldTouched('image', true);
        setFieldValue('image', file);
    }, []);

    const handleChangeBeginDate = useCallback((date) => {
        if (values.beginTime) {
            date.set('hours', values.beginTime.hours()).set('minutes', values.beginTime.minutes());
        }

        if (values.endDate && date.diff(values.endDate, 'days') > 0) {
            setFieldValue('endDate', date.endOf('minute'));
        }

        setFieldTouched('beginDate', true);
        setFieldValue('beginDate', date);
        setTimeout(setFieldValue, 0, 'beginTime', date);
    }, [values.beginTime, values.endDate]);

    const handleChangeBeginTime = useCallback((date) => {
        setFieldTouched('beginTime', true);
        setFieldValue('beginTime', date);

        if (values.beginDate) {
            setFieldValue('beginDate', date);
        }
    }, [values.beginDate]);

    const handleChangeEndDate = useCallback((date) => {
        if (values.beginDate && date.diff(values.beginDate, 'days') < 0) {
            if (values.beginTime) {
                date.set('hours', values.beginTime.hours()).set('minutes', values.beginTime.minutes());
            }

            setFieldValue('beginDate', date);
        }

        setFieldTouched('endDate', true);
        setFieldValue('endDate', date.endOf('minute'));
    }, [values.beginDate]);

    const handleChangeVideoUrl = useCallback((e) => {
        const videoUrl = e.target.value;

        setFieldTouched('videoUrl', true);
        setFieldValue('videoUrl', videoUrl);
    }, [values.videoUrl]);

    const handleMessageDelete = useCallback(() => {
        messageDeleteDialog.onClose();
        history.push(MESSAGES_LIST_PAGE);
    });

    const isAllClientsSelected = values.clients.length === companyClients.length;

    const handleSetValues = useCallback((clients) => {
        const valies = clients.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (clients.length > 100) {
            valies.push({ value: -1, label: tc('weekRoute.more', { count: clients.length - 100 }) });
        }
        setValue(valies);
    }, []);

    const handleChangeAllClientsSelect = useCallback(({ clear }) => {
        let clients = [];
        if (isAllClientsSelected || clear) {
            setFieldValue('clients', clients);
        } else {
            clients = companyClients.map((c) => ({
                id: c.id,
                name: `${c.firstName} ${c.lastName}`,
            }));
            setFieldValue('clients', clients);
        }
        handleSetValues(clients);
    }, [isAllClientsSelected, companyClients]);

    const handleChangeClientSelect = useCallback((client, selected) => {
        if (!client.value) {
            return;
        }
        let clients = [];
        if (selected) {
            clients = [...values.clients, { id: client.value, name: client.label }];
            setFieldValue('clients', clients);
        } else {
            clients = values.clients.filter((c) => c.id !== client.value);
            setFieldValue('clients', clients);
        }

        handleSetValues(clients);
    }, [values?.clients.length]);

    const handleChangeClientsSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllClientsSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeClientSelect(triggeredAction.removedValue, false);
                return;
            }
            const clients = values.clients.slice(0, 100);
            setFieldValue('clients', clients);
            handleSetValues(clients);
        }
        if (triggeredAction.action === 'deselect-option') {
            handleChangeClientSelect(triggeredAction.option, false);
        }
        if (triggeredAction.action === 'select-option') {
            handleChangeClientSelect(triggeredAction.option, true);
        }
    }, [handleChangeClientSelect, handleChangeAllClientsSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllClientsSelected) {
            e.preventDefault();
            setFieldValue('clients', []);
        }
    }, [isAllClientsSelected]);

    useEffect(() => {
        if (isEditMode || isDuplicateMode) {
            return;
        }

        const selectedClientsIds = new Set(history.location.state?.selectedClientsIds);
        const filtredClients = selectedClientsIds?.size
            ? companyClients.filter((c) => selectedClientsIds.has(c.id))
            : companyClients;

        const selectedClients = filtredClients.map((c) => ({
            id: c.id,
            name: `${c.firstName} ${c.lastName}`,
        }));

        setFieldValue('clients', selectedClients);
        handleSetValues(selectedClients);
    }, [companyClients]);

    useEffect(() => {
        const messageId = params.id || history.location.state?.messageId;
        if (!messageId) {
            return;
        }

        dispatch(MESSAGES_ACTIONS.getMessageItem({ messageId }));
    }, [dispatch, params.id, isEditMode]);

    useEffect(() => {
        if (!message || (!isEditMode && !isDuplicateMode)) {
            return;
        }

        Object.entries(message)
            .forEach(([key, value]) => {
                if (key === 'name') {
                    if (isDuplicateMode) {
                        setFieldValue('title', t(`${T_PREFIX}.title.duplicateTitle`, { title: value }));
                    }
                    if (isEditMode) {
                        setFieldValue('title', value);
                    }
                } else if (key === 'photo_url') {
                    setFieldValue('image', value);
                } else if (key === 'video_url') {
                    setFieldValue('videoUrl', value);
                } else if (key === 'begin_date') {
                    if (isEditMode) {
                        setFieldValue('beginTime', moment.unix(value));
                        setTimeout(setFieldValue, 0, 'beginDate', moment.unix(value));
                    }
                } else if (key === 'end_date') {
                    if (isEditMode) {
                        const endDate = message.begin_date ? moment.max(moment.unix(message.begin_date), moment.unix(value)) : moment.unix(value);
                        setFieldValue('endDate', endDate);
                    }
                } else if (key === 'message_type') {
                    setFieldValue('sendAlsoToEmail', value.includes('email'));
                } else if (key === 'clients') {
                    const clients = message.message_to_all_clients
                        ? companyClients.map((c) => ({
                            id: c.id,
                            name: `${c.firstName} ${c.lastName}`,
                        }))
                        : value.map((c) => ({
                            id: c.id,
                            name: c.fullName,
                        }));
                    setFieldValue('clients', clients);
                    handleSetValues(clients);
                } else if (key === 'immediately') {
                    if (isEditMode) {
                        setFieldValue('immediately', value);
                    }
                } else if (key === 'indefinite') {
                    if (isEditMode) {
                        setFieldValue('indefinite', value);
                    }
                } else if (key in values) {
                    setFieldValue(key, value);
                }
            });
    }, [message]);

    const valuesClientsIdsSet = useMemo(() => new Set(values?.clients?.map((c) => c.id)), [values?.clients?.length]);

    useEffect(() => {
        dispatch(getCompanyClients())
            .then((clients) => setCompanyClients(clients));
    }, []);

    const notEditOrPending = (!isEditMode || message?.status === MESSAGE_STATUS.PENDING);

    return (
        <>
            <TextSubHeader
                before={(
                    <BackButton
                        href={MESSAGES_LIST_PAGE}
                    />
                )}
                text={isEditMode
                    ? t(`${T_PREFIX}.editMessage`)
                    : t(`${T_PREFIX}.addMessage`)}
                rightActions={isEditMode && (
                    <Button
                        color="outline"
                        loading={isSavingProcess}
                        onClick={messageDeleteDialog.onShow}
                        before={<Trash3Icon />}
                    >
                        {t(`${T_PREFIX}.actions.delete`)}
                    </Button>
                )}
            />
            <Container fluid className={styles.container}>
                <Form className={styles.form}>
                    <Row className={styles.contentWrapper}>
                        <Col xs={12} md={4}>
                            <FileUpload
                                name="image"
                                // eslint-disable-next-line react/no-unstable-nested-components
                                label={() => (
                                    <Trans
                                        ns={LOCALE_NAMESPACE.MESSAGES}
                                        i18nKey={`${T_PREFIX}.fileInputField.label`}
                                        components={[
                                            <span
                                                className={classNames({
                                                    'text-dark': !(touched.image && !!errors.image),
                                                    'text-danger': touched.image && !!errors.image,
                                                })}
                                            />,
                                            <span className={styles.fileInputSub} />,
                                        ]}
                                    />
                                )}
                                isInvalid={touched.image && !!errors.image}
                                defaultValue={values.image}
                                onChange={handleChangeImage}
                                className={classNames('p-4 mb-3', styles.fileInput)}
                                allowedFileSize={10}
                                allowedFormats={['.jpg', '.jpeg', '.png']}
                            />

                            <div className={styles.dateLabel}>
                                {t(`${T_PREFIX}.date.label`)}
                            </div>

                            {notEditOrPending && (
                                <Form.Group className={styles.dateCheckbox}>
                                    <Form.Check
                                        name="immediately"
                                        id="immediately"
                                        type="checkbox"
                                        label={(
                                            <Form.Label htmlFor="immediately" className="font-size-15 m-0 d-flex align-items-center">
                                                {t(`${T_PREFIX}.date.immediately.label`)}
                                                <InfoTooltip
                                                    text={t(`${T_PREFIX}.date.immediately.tooltip`)}
                                                    placement="bottom"
                                                />
                                            </Form.Label>
                                        )}
                                        checked={values.immediately}
                                        value={values.immediately}
                                        onChange={handleChange}
                                        className="font-weight-normal"
                                    />

                                </Form.Group>
                            )}

                            <Form.Group className={styles.dateCheckbox}>
                                <Form.Check
                                    name="indefinite"
                                    id="indefinite"
                                    type="checkbox"
                                    label={(
                                        <Form.Label htmlFor="indefinite" className="font-size-15 m-0 d-flex align-items-center">
                                            {t(`${T_PREFIX}.date.indefinite.label`)}
                                            <InfoTooltip
                                                text={t(`${T_PREFIX}.date.indefinite.tooltip`)}
                                                placement="bottom"
                                            />
                                        </Form.Label>
                                    )}
                                    checked={values.indefinite}
                                    value={values.indefinite}
                                    onChange={handleChange}
                                    className="font-weight-normal"
                                />

                            </Form.Group>

                            <div className={styles.datePickersContainer}>
                                {notEditOrPending && (
                                    <>
                                        <div className={styles.datePicker}>
                                            <DateInput
                                                name="beginDate"
                                                value={values.immediately ? '' : values.beginDate}
                                                placeholder={t(`${T_PREFIX}.date.dateFrom.placeholder`)}
                                                onChange={handleChangeBeginDate}
                                                isInvalid={!!touched.beginDate && !!errors.beginDate}
                                                className="text-nowrap"
                                                iconComponent={Calendar}
                                                isDisabled={values.immediately}
                                                format="DD.MM.YYYY"
                                                minDate={moment()}
                                            />
                                        </div>

                                        <div className={styles.datePicker}>
                                            <HourInput
                                                name="beginTime"
                                                value={values.immediately ? '' : values.beginTime}
                                                placeholder={t(`${T_PREFIX}.date.timeFrom.placeholder`)}
                                                onChange={handleChangeBeginTime}
                                                isInvalid={!!touched.beginTime && !!errors.beginTime}
                                                className="text-nowrap"
                                                iconComponent={Clock}
                                                allowAllHours
                                                timespan={15}
                                                max={(values.beginDate || moment()).endOf('minute')}
                                                min={(values.beginDate || moment()).startOf('minute')}
                                                isDisabled={values.immediately}
                                            />
                                        </div>
                                        <div className={styles.hyphen} />
                                    </>
                                )}

                                <div className={styles.datePicker}>
                                    <DateInput
                                        name="endDate"
                                        value={values.indefinite ? '' : values.endDate}
                                        placeholder={t(`${T_PREFIX}.date.dateTo.placeholder`)}
                                        onChange={handleChangeEndDate}
                                        isInvalid={!!touched.endDate && !!errors.endDate}
                                        className="text-nowrap"
                                        iconComponent={Calendar}
                                        isDisabled={values.indefinite}
                                        format="DD.MM.YYYY"
                                        minDate={moment()}
                                    />
                                </div>
                            </div>

                            <Form.Control.Feedback
                                className={!!touched.beginDate && !!errors.beginDate && 'd-block'}
                                type="invalid"
                            >
                                {errors.beginDate}
                            </Form.Control.Feedback>

                            <Form.Control.Feedback
                                className={!!touched.beginTime && !!errors.beginTime && 'd-block'}
                                type="invalid"
                            >
                                {errors.beginTime}
                            </Form.Control.Feedback>

                            <Form.Control.Feedback
                                className={!!touched.endDate && !!errors.endDate && 'd-block'}
                                type="invalid"
                            >
                                {errors.endDate}
                            </Form.Control.Feedback>
                        </Col>

                        <Col xs={12} md={8} className="mt-4 mt-md-0">

                            {notEditOrPending && (
                                <Form.Group className={styles.clientsSelect}>
                                    <Form.Label className="font-size-15 d-flex align-items-center">
                                        {t(`${T_PREFIX}.sendTo.label`)}

                                        <InfoTooltip
                                            text={t(`${T_PREFIX}.sendTo.tooltip`)}
                                            placement="bottom"
                                        />
                                    </Form.Label>

                                    <WindowedSelect
                                        name="clients"
                                        styles={selectWithGrayItems}
                                        theme={isClientsSelectInvalid ? dangerTheme : theme}
                                        options={selectClientsOptions}
                                        value={value}
                                        onChange={handleChangeClientsSelect}
                                        isMulti
                                        isClearable
                                        backspaceRemovesValue
                                        onKeyDown={onKeyDownHandle}
                                        hideSelectedOptions={false}
                                        closeMenuOnSelect={false}
                                        placeholder={t(`${T_PREFIX}.sendTo.clients.placeholder`)}
                                        components={{
                                            MultiValue: values.clients.length === companyClients.length
                                                ? (props) => (
                                                    props.index === 0
                                                        ? t(`${T_PREFIX}.sendTo.clients.allClients`)
                                                        : null
                                                )
                                                // eslint-disable-next-line react/no-unstable-nested-components
                                                : (props) => <components.MultiValue {...props} />,
                                            // eslint-disable-next-line react/no-unstable-nested-components
                                            Option: ({
                                                innerRef, innerProps, data,
                                            }) => {
                                                const isChecked = valuesClientsIdsSet.has(data.value);

                                                return (
                                                    <Form.Group
                                                        ref={innerRef}
                                                        {...innerProps}
                                                        className={styles.option}
                                                    >
                                                        {data.value === null
                                                            ? (
                                                                <TextButton color="yellow" onClick={handleChangeAllClientsSelect}>
                                                                    {t(`${T_PREFIX}.sendTo.clients.allClients`)}
                                                                </TextButton>
                                                            )
                                                            : (
                                                                <>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        checked={isChecked}
                                                                        onChange={() => {}}
                                                                        className={styles.checkbox}
                                                                    />
                                                                    {`${data.label}`}
                                                                    <span className={classNames('ml-1', styles.grayColor)}>
                                                                        {` · ${data.email} · ${data.phone}`}
                                                                    </span>
                                                                </>
                                                            )}
                                                    </Form.Group>
                                                );
                                            },
                                        }}
                                    />

                                    <Form.Control.Feedback
                                        className={isClientsSelectInvalid && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.clients}
                                    </Form.Control.Feedback>

                                    {notEditOrPending && (
                                        <Form.Group className={styles.sendToCheckbox}>
                                            <Form.Check
                                                name="sendAlsoToEmail"
                                                id="sendAlsoToEmail"
                                                type="checkbox"
                                                label={(
                                                    <Form.Label htmlFor="sendAlsoToEmail" className="font-size-15 m-0 d-flex align-items-center">
                                                        {t(`${T_PREFIX}.sendTo.sendAlsoToEmail.label`)}
                                                        <InfoTooltip
                                                            text={t(`${T_PREFIX}.sendTo.sendAlsoToEmail.tooltip`)}
                                                            placement="bottom"
                                                        />
                                                    </Form.Label>
                                                )}
                                                checked={values.sendAlsoToEmail}
                                                value={values.sendAlsoToEmail}
                                                onChange={handleChange}
                                                className="font-weight-normal"
                                            />
                                        </Form.Group>
                                    )}
                                </Form.Group>
                            )}
                            <Form.Group>
                                <Form.Label>
                                    {t(`${T_PREFIX}.title.label`)}
                                </Form.Label>
                                <Form.Control
                                    name="title"
                                    type="text"
                                    value={values.title}
                                    onChange={handleChange}
                                    isInvalid={touched.title && !!errors.title}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t(`${T_PREFIX}.videoUrl.label`)}
                                </Form.Label>
                                <Form.Control
                                    name="videoUrl"
                                    type="text"
                                    value={values.videoUrl}
                                    onChange={handleChangeVideoUrl}
                                    isInvalid={touched.videoUrl && !!errors.videoUrl}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.videoUrl}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t(`${T_PREFIX}.description.label`)}
                                </Form.Label>
                                <Form.Control
                                    name="description"
                                    as="textarea"
                                    rows={20}
                                    value={values.description}
                                    onChange={handleChange}
                                    isInvalid={touched.description && !!errors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.description}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className={styles.actionsButtonsWrapper}>
                        <Button
                            color="outline"
                            href={MESSAGES_LIST_PAGE}
                        >
                            {t(`${T_PREFIX}.actions.cancel`)}
                        </Button>

                        <Button
                            color="yellow"
                            loading={isSavingProcess}
                            onClick={onSaveAndSendHandler}
                        >
                            {t(`${T_PREFIX}.actions.save`)}
                        </Button>
                    </div>
                </Form>

                <MessageDeleteDialog
                    message={messageDeleteDialog.visible && message}
                    onClose={handleMessageDelete}
                />
            </Container>
        </>

    );
};

export default AddOrEditMessage;
