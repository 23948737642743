import { createSelector } from 'reselect';

export const accessSelector = (state) => state.access;

export const accessGatesListSelector = createSelector(
    accessSelector,
    (access) => access.gates,
);

export const accessGateSelector = createSelector(
    accessSelector,
    (access) => access.gate,
);

export const accessLogsListSelector = createSelector(
    accessSelector,
    (access) => access.logs,
);

export const accessLogsStatisticsSelector = createSelector(
    accessSelector,
    (access) => access.logsStatistics,
);

export const accessProfilesListSelector = createSelector(
    accessSelector,
    (access) => access.profiles,
);

export const accessProfileSelector = createSelector(
    accessSelector,
    (access) => access.profile,
);
