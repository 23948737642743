import React from 'react';
import PropTypes from 'prop-types';

const EmptyTableIcon = (props) => {
    const { width, height } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 188 188"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M70.2086 158.25L130.868 158.479C133.919 158.49 136.733 156.88 138.248 154.254L168.379 102.065C169.896 99.4395 169.885 96.1968 168.35 93.5584L137.821 41.1406C136.285 38.5031 133.459 36.8713 130.407 36.8598L69.7539 36.634C66.7014 36.624 63.8876 38.2356 62.3719 40.8621L32.2408 93.0506C30.7244 95.6757 30.7362 98.9175 32.272 101.555L62.8002 153.972C64.3361 156.606 67.1594 158.237 70.2086 158.25Z"
                fill="#F9FAFB"
            />
            <rect
                x="41.8687"
                y="65.4155"
                width="24"
                height="72"
                fill="#F9FAFB"
            />
            <path
                d="M64.311 67.4114L45.0988 67.4114L39.1782 67.4114C39.1782 65.8206 39.802 64.2949 40.9123 63.17C42.0226 62.0451 43.5286 61.4131 45.0988 61.4131L64.311 61.4131C65.2452 61.4107 66.1667 61.6322 67.0002 62.0596C67.8337 62.4871 68.5555 63.1082 69.1067 63.8724L75.945 73.4097L116.146 73.4097C117.716 73.4097 119.222 74.0417 120.332 75.1666C121.442 76.2915 122.066 77.8172 122.066 79.408L74.4352 79.408C73.9681 79.4093 73.5074 79.2985 73.0906 79.0848C72.6739 78.8711 72.313 78.5605 72.0374 78.1784L64.311 67.4114Z"
                fill="#FCC108"
            />
            <path
                d="M110.919 102.033L131.466 102.11C132.499 102.114 133.452 101.569 133.965 100.679L144.172 83.0017C144.685 82.1122 144.681 81.0139 144.162 80.1202L133.821 62.3651C133.301 61.4717 132.343 60.919 131.309 60.9151L110.765 60.8386C109.731 60.8352 108.778 61.3811 108.264 62.2707L98.0584 79.9482C97.5447 80.8374 97.5487 81.9355 98.0689 82.8287L108.41 100.584C108.93 101.476 109.886 102.028 110.919 102.033Z"
                fill="#E5EBF0"
            />
            <path
                d="M103.151 28.4929L99.3853 22.0272C99.1958 21.702 99.1944 21.3021 99.3814 20.9783L103.098 14.5409C103.285 14.2169 103.632 14.0181 104.009 14.0194L111.491 14.0476C111.867 14.0491 112.216 14.2503 112.405 14.5757L116.17 21.0409C116.36 21.3664 116.361 21.7664 116.174 22.0903L112.457 28.5277C112.27 28.8516 111.923 29.0502 111.547 29.0488L104.065 29.0205C103.689 29.0189 103.34 28.8179 103.151 28.4929Z"
                fill="#E5EBF0"
            />
            <path
                d="M77.7741 58.6503L70.5029 46.647C70.1371 46.0431 70.1213 45.3083 70.4616 44.7194L77.2211 33.0114C77.5612 32.4222 78.2055 32.0682 78.9116 32.0827L92.9424 32.3781C93.6483 32.393 94.3086 32.7742 94.6745 33.3782L101.944 45.3806C102.31 45.9848 102.325 46.7198 101.985 47.3089L95.2256 59.0169C94.8857 59.606 94.2414 59.9597 93.5356 59.9449L79.5047 59.6495C78.7995 59.6343 78.1399 59.2535 77.7741 58.6503Z"
                fill="#E5EBF0"
            />
            <path
                d="M129.403 85.7177C130.152 86.1052 130.794 86.6759 131.271 87.3781C131.696 88.001 131.973 88.7147 132.081 89.4638C132.189 90.2129 132.124 90.9771 131.893 91.6969L119.489 136.984C119.305 137.627 118.913 138.189 118.377 138.581C117.84 138.972 117.19 139.17 116.529 139.144L42.7292 139.144C41.6633 139.176 40.6277 138.782 39.8465 138.047C39.0653 137.311 38.6014 136.294 38.5552 135.215L38.5552 67.1638L44.4757 67.1638L44.4757 121.149L53.1198 90.1373C53.1101 89.9975 53.1101 89.8572 53.1198 89.7174C53.7048 88.4075 54.6372 87.2878 55.8132 86.4829C56.9891 85.678 58.3625 85.2196 59.7804 85.1587L126.979 85.1587C127.82 85.1381 128.653 85.3302 129.403 85.7177Z"
                fill="#FCC108"
            />
        </svg>
    );
};

EmptyTableIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

EmptyTableIcon.defaultProps = {
    width: 0,
    height: 0,
};

export default EmptyTableIcon;
