import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ClientSelectOption.module.scss';

const ClientSelectOption = (props) => {
    const {
        name,
        lastName,
        phone,
        label,
    } = props;

    return (
        <div className="d-flex align-items-center justify-content-between">
            <span className={styles.name}>
                {label || `${name} ${lastName}`}
            </span>
            <span>
                {phone}
            </span>
        </div>
    );
};

ClientSelectOption.propTypes = {
    name: PropTypes.string,
    phone: PropTypes.string,
    label: PropTypes.string,
};

ClientSelectOption.defaultValues = {
    name: '',
    phone: '',
    label: '',
};

export default ClientSelectOption;
