import Gates from './gates';
import Logs from './logs';
import profiles from './profiles';

const routes = [
    {
        component: Logs,
        exact: false,
        path: '/access/logs',
    },
    {
        component: Gates,
        exact: false,
        path: '/access/gates',
    },
    {
        component: profiles,
        exact: false,
        path: '/access/profiles',
    },
];

export default routes;
