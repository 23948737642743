import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

// Components
import classNames from 'classnames';
import { TailSpin } from 'react-loader-spinner';
import { Button } from 'components/Common/Button';
import IconButton from 'components/Common/IconButton/IconButton';
import {
    FileUpload as Upload,
    Warning,
    Pen2Icon,
    Trash3Icon,
} from '../Icon/Icon';

// Styles
import * as styles from './FileUpload.module.scss';

const FilePreview = ({
    label,
    image,
    onAdd,
    onEdit,
    onDelete,
    variant,
    isInvalid,
    compact = false,
    loading = false,
    disabledActions = false,
    withoutEdit = false,
}) => {
    const { t } = useTranslation();

    const src = useMemo(() => (image instanceof Blob ? URL.createObjectURL(image) : image), [image]);

    const showRemove = Boolean(src) && !disabledActions;

    return (
        <>
            {!loading && (
                <>
                    {!showRemove && !disabledActions && (
                        <>
                            <span
                                className={classNames({
                                    'text-danger': variant === 'danger' || isInvalid,
                                    'text-muted': !(variant === 'danger' || isInvalid),
                                })}
                            >
                                {variant === 'danger' || isInvalid
                                    ? <Warning width={64} strokeWidth={1} />
                                    : <Upload width={64} strokeWidth={0.75} />}
                            </span>

                            <span
                                className={classNames(
                                    'mt-3 text-center',
                                    {
                                        'text-danger': variant === 'danger' || isInvalid,
                                        'text-muted': !(variant === 'danger' || isInvalid),
                                    },
                                )}
                            >
                                {image?.name || typeof label !== 'function'
                                    ? image?.name || label
                                    : label()}
                            </span>

                            <Button
                                color="yellow"
                                className="mt-4 mb-2"
                                type="button"
                                onClick={onAdd}
                            >
                                {t('fileUpload.clickToUpload')}
                            </Button>
                        </>
                    )}

                    {src && <img className={styles.image} src={src} alt="" />}

                    {showRemove && (
                        <div className={styles.actionButtonsWrapper}>
                            {!withoutEdit && (
                                <IconButton
                                    onClick={onEdit}
                                    color="black"
                                    type="button"
                                    size={compact ? 24 : 32}
                                >
                                    <Pen2Icon width={compact ? '16' : '24'} height={compact ? '16' : '24'} />
                                </IconButton>
                            )}
                            <IconButton
                                onClick={onDelete}
                                color="black"
                                type="button"
                                size={compact ? 24 : 32}
                            >
                                <Trash3Icon width={compact ? '16' : '24'} height={compact ? '16' : '24'} />
                            </IconButton>
                        </div>
                    )}
                </>
            )}
            {loading && (
                <TailSpin
                    color="black"
                    width={48}
                    height={48}
                />
            )}
        </>
    );
};

export default FilePreview;
