import React, { useState, useEffect, useRef } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// Actions
import {
    Form, Row, Col, InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import {
    Calendar, Clock, ChevronLeft, ChevronUp, ChevronDown,
} from 'react-feather';
import * as yup from 'yup';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { permissionsResourceSelector } from 'store/selectors/permissions';
import * as actions from 'store/actions';

// Components
import FileUpload from 'components/FileUpload/FileUpload';
import DateInput from 'components/DateInput/DateInput';
import Map from 'components/Map/Map';
import HourInput from 'components/HourInput/HourInput';
import Footer from 'components/Layout/Footer/Footer';
import PhoneInput from 'components/PhoneInput/PhoneInput';

// Styles
import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import Container from 'components/Layout/Container/Container';
import { BackButton } from 'components/Common/BackButton';
import { styles, theme, dangerTheme } from '../../../../../styles/select';

// Store

// Utils

import * as SERVICES_ACTIONS from '../../../../../store/actions/services';

import * as SERVICES_SELECTORS from '../../../../../store/selectors/services';

const Add = () => {
    /* istanbul ignore next */

    const { t } = useTranslation();

    const schema = useRef(yup.object({
        name: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.eventName') })),
        description: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.description') })),
        image: yup.mixed().required(t('addEventRoute.pleaseSelectEventImage')),
        videoURL: yup
            .string()
            .matches(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/, t('validationErrors.invalidURLAddress')),
        isMaintenanceMode: yup.boolean().required(),
        canSendEmail: yup.boolean().required(),
        time: yup.object({
            from: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.time') })),
        }),
        service: yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        }).typeError(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.service') })),
        totalPlaces: yup
            .number()
            .positive(t('validationErrors.mustBePositiveNumber', { name: t('addEventRoute.maxRegistrations') }))
            .required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.maxRegistrations') })),
        showRegistrationsNumber: yup.boolean().required(),
        contactNumber: yup
            .string()
            .matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/, t('validationErrors.invalidPhoneNumber'))
            .required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.contactNumber') })),
        address: yup.object({
            city: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.city') })),
            street: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.streetAddress') })),
            zip: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.zipCode') })),
            country: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEventRoute.country') })),
        }).required(),
    })).current;

    const { loaded: isShopLoaded, id: shopID } = useSelector((state) => state.shop);
    const { loading: companyServicesLoading, items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const {
        contactNumber, address: {
            street, zip, city, country, latitude, longitude,
        },
    } = useSelector((state) => state.shop);
    const { banAdd } = useSelector(permissionsResourceSelector);

    const dispatch = useDispatch();

    const openingHours = useSelector((state) => Object.entries(state.shop.workingDays || {})
        .reduce((openingHours, [day, hours]) => ({
            ...openingHours,
            [_.upperFirst(day)]: hours,
        }), {}));

    const [center, setCenter] = useState(null);
    const [isGettingAddress, setGettingAddress] = useState(false);
    const [isGettingCoordinates, setGettingCoordinates] = useState(false);

    const { addToast } = useToasts();

    const { push } = useHistory();

    const {
        handleSubmit, handleChange, setFieldValue, values, isSubmitting, touched, errors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            dispatch(actions.addEvent({
                event: values,
            }))
                .then(() => {
                    addToast(t('addEventRoute.eventAddedSuccessfully'), {
                        appearance: 'success',
                    });
                    push('/agenda/events');
                })
                .catch(({ message, errors }) => {
                    if (message) {
                        addToast(message, {
                            appearance: 'error',
                        });
                    } else {
                        setErrors(errors || {});
                    }

                    setSubmitting(false);
                });
        },
        initialValues: {
            name: '',
            description: '',
            image: null,
            videoURL: '',
            isMaintenanceMode: false,
            canSendEmail: false,
            time: {
                from: null,
            },
            service: null,
            totalPlaces: 0,
            showRegistrationsNumber: false,
            contactNumber: contactNumber || '',
            address: {
                street: street || '',
                zip: zip || '',
                city: city || '',
                country: country || '',
                latitude: latitude || 0,
                longitude: longitude || 0,
            },
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (companyServicesLoading || !shopID || !isShopLoaded) {
            return;
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [companyServicesLoading, shopID, isShopLoaded]);

    useEffect(() => {
        if (!latitude || !longitude) {
            return;
        }

        setCenter({
            latitude,
            longitude,
        });
    }, [latitude, longitude]);

    if (banAdd) {
        return <Redirect to="/agenda/events" />;
    }

    return (
        <>
            <TextSubHeader
                text={t('addEventRoute.addNewEvent')}
                before={(
                    <BackButton
                        href="/agenda/events"
                    />
                )}
            />
            <Container>
                <Form noValidate onSubmit={handleSubmit} className="w-100">
                    <Row>
                        <Col xs={12} lg={6}>
                            <FileUpload
                                name="image"
                                label={(touched.image && errors.image) || t('addEventRoute.uploadEventImage')}
                                isInvalid={touched.image && !!errors.image}
                                onChange={(file) => setFieldValue('image', file)}
                                defaultValue={values.image}
                            />
                        </Col>

                        <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.eventName')}
                                </Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.videoURL')}
                                </Form.Label>
                                <Form.Control
                                    name="videoURL"
                                    type="text"
                                    value={values.videoURL}
                                    onChange={handleChange}
                                    isInvalid={touched.videoURL && !!errors.videoURL}
                                />
                                <Form.Control.Feedback type="invalid">{errors.videoURL}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.description')}
                                </Form.Label>
                                <Form.Control
                                    name="description"
                                    as="textarea"
                                    rows={3}
                                    value={values.description}
                                    onChange={handleChange}
                                    isInvalid={touched.description && !!errors.description}
                                />
                                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    id="isMaintenanceMode"
                                    name="isMaintenanceMode"
                                    type="checkbox"
                                    label={t('addGroupRoute.maintenanceMode')}
                                    checked={values.isMaintenanceMode}
                                    onChange={handleChange}
                                />

                                <Form.Control.Feedback
                                    className={!!touched.isMaintenanceMode && !!errors.isMaintenanceMode && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.isMaintenanceMode}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Check
                                    id="canSendEmail"
                                    name="canSendEmail"
                                    type="checkbox"
                                    label={t('addGroupRoute.canSendEmail')}
                                    checked={values.canSendEmail}
                                    onChange={handleChange}
                                />

                                <Form.Control.Feedback
                                    className={!!touched.canSendEmail && !!errors.canSendEmail && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.canSendEmail}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <h3 className="mb-4 mt-4 font-weight-normal">
                        {t('addEventRoute.eventSettings')}
                    </h3>
                    <Row>
                        <Col xs={12} lg={4}>
                            <Form.Group className="mb-3">
                                <label className="form-label">{t('addEventRoute.date')}</label>
                                <InputGroup>
                                    <DateInput
                                        name="time.from.date"
                                        value={values.time.from}
                                        placeholder={t('blockModal.selectDate')}
                                        onChange={(time) => setFieldValue('time.from', time)}
                                        isInvalid={touched.time && touched.time.from && !!errors.time && !!errors.time.from}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text className={classNames({
                                            'border-danger text-danger': touched.time && touched.time.from && !!errors.time && !!errors.time.from,
                                            'text-muted': !touched.time || !touched.time.from || !errors.time || !errors.time.from,
                                        })}
                                        >
                                            <Calendar size={18} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Form.Control.Feedback
                                    className={touched.time && touched.time.from && !!errors.time && !!errors.time.from && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.time && errors.time.from}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Form.Group>
                                <label className="form-label">{t('addEventRoute.time')}</label>
                                <InputGroup className="mb-3">
                                    <HourInput
                                        allowAllHours
                                        cycleTimeThroughSameDate
                                        name="time.from.time"
                                        value={values.time.from}
                                        placeholder={t('blockModal.selectTime')}
                                        onChange={(time) => setFieldValue('time.from', time)}
                                        min={openingHours[values.time.from ? values.time.from.format('dddd') : _.upperFirst(moment().format('dddd'))]?.from}
                                        max={openingHours[values.time.from ? values.time.from.format('dddd') : _.upperFirst(moment().format('dddd'))]?.to}
                                        isInvalid={touched.time && touched.time.from && !!errors.time && !!errors.time.from}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text className={classNames({
                                            'border-danger text-danger': touched.time && touched.time.from && errors.time && errors.time.from,
                                            'text-muted': !touched.time || !touched.time.from || !errors.time || !errors.time.from,
                                        })}
                                        >
                                            <Clock size={18} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.service')}
                                </Form.Label>
                                <Select
                                    name="service"
                                    styles={styles}
                                    theme={touched.service && !!errors.service ? dangerTheme : theme}
                                    options={companyServices?.map(({ id, name }) => ({ value: id, label: name }))}
                                    value={values.service ? { value: values.service.id, label: values.service.name } : null}
                                    onChange={({ value: id, label: name }) => setFieldValue('service', { id, name })}
                                    placeholder={t('placeholders.select')}
                                />

                                <Form.Control.Feedback
                                    className={touched.service && !!errors.service && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.service}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={4}>
                            <Form.Group>
                                <label className="form-label">{t('addEventRoute.maxRegistrations')}</label>
                                <InputGroup>
                                    <Form.Control
                                        name="totalPlaces"
                                        type="number"
                                        value={values.totalPlaces}
                                        onChange={handleChange}
                                        isInvalid={touched.totalPlaces && !!errors.totalPlaces}
                                    />
                                    <InputGroup.Append className="border-left">
                                        <div className={classNames({
                                            'border-light': !touched.totalPlaces || !errors.totalPlaces,
                                            'border-danger': touched.totalPlaces && !!errors.totalPlaces,
                                        }, 'd-flex flex-column border rounded-right')}
                                        >
                                            <button
                                                type="button"
                                                name="totalPlaces.increment"
                                                className={classNames({
                                                    'text-darker-light': !touched.totalPlaces || !errors.totalPlaces,
                                                    'text-danger': touched.totalPlaces && !!errors.totalPlaces,
                                                }, 'd-flex justify-content-center bg-transparent border-0 p-0')}
                                                style={{ width: 23, height: 19 }}
                                                onClick={() => setFieldValue('totalPlaces', values.totalPlaces + 1)}
                                            >
                                                <ChevronUp size={16} />
                                            </button>

                                            <button
                                                type="button"
                                                name="totalPlaces.decrement"
                                                className={classNames({
                                                    'text-darker-light': !touched.totalPlaces || !errors.totalPlaces,
                                                    'text-danger': touched.totalPlaces && !!errors.totalPlaces,
                                                }, 'd-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0')}
                                                style={{ width: 23, height: 19 }}
                                                onClick={() => setFieldValue('totalPlaces', Math.max(values.totalPlaces - 1, 0))}
                                            >
                                                <ChevronDown size={16} />
                                            </button>
                                        </div>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Form.Control.Feedback
                                    className={touched.totalPlaces && !!errors.totalPlaces && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.totalPlaces}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12} lg={4} className="pt-4" style={{ marginTop: '0.8rem' }}>
                            <Form.Group controlId="showRegistrationsNumber" className="m-0">
                                <Form.Check
                                    name="showRegistrationsNumber"
                                    type="checkbox"
                                    label={t('addEventRoute.showNumberOfRegistrations')}
                                    checked={values.showRegistrationsNumber}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h3 className="my-4 font-weight-normal">
                        {t('addEventRoute.address')}
                    </h3>
                    <Row>
                        <Col xs={12} lg={8} className="d-flex flex-column">
                            {center ? (
                                <Map
                                    name="map"
                                    className="h-100"
                                    center={center}
                                    markerPosition={{
                                        latitude: values.address.latitude,
                                        longitude: values.address.longitude,
                                    }}
                                    onMarkerPositionChange={({ latitude, longitude }) => {
                                        setCenter({ latitude, longitude });
                                        setFieldValue('address', {
                                            ...values.address,
                                            latitude,
                                            longitude,
                                        });
                                    }}
                                />
                            ) : <div className="h-100" style={{ minHeight: 400 }} />}

                            <div className="mt-4">
                                <Button
                                    type="button"
                                    name="fillAddress"
                                    color="outline"
                                    size="small"
                                    onClick={() => {
                                        setGettingAddress(true);
                                        dispatch(actions.getAddressByCoordinates({
                                            latitude: values.address.latitude,
                                            longitude: values.address.longitude,
                                        }))
                                            .then(({
                                                street, zip, city, country,
                                            }) => setFieldValue('address', {
                                                ...values.address,
                                                street,
                                                zip,
                                                city,
                                                country,
                                            }))
                                            .finally(() => setGettingAddress(false));
                                    }}
                                    loading={isGettingAddress}
                                >
                                    { t('addEventRoute.fillAddressByMarkerPosition')}
                                </Button>
                            </div>
                        </Col>

                        <Col xs={12} lg={4} className="mt-4 mt-lg-0">
                            <Form.Group>
                                <label className="form-label">{t('addEventRoute.contactNumber')}</label>
                                <PhoneInput
                                    name="contactNumber"
                                    value={values.contactNumber}
                                    onChange={(contactNumber) => setFieldValue('contactNumber', contactNumber)}
                                    isInvalid={touched.contactNumber && !!errors.contactNumber}
                                />
                                <Form.Control.Feedback
                                    className={errors.contactNumber && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.contactNumber}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.city')}
                                </Form.Label>
                                <Form.Control
                                    name="address.city"
                                    type="text"
                                    placeholder={t('addEventRoute.enterCity')}
                                    value={values.address.city}
                                    onChange={handleChange}
                                    isInvalid={touched.address && touched.address.city && !!errors.address && !!errors.address.city}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.city}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.streetAddress')}
                                </Form.Label>
                                <Form.Control
                                    name="address.street"
                                    type="text"
                                    placeholder={t('addEventRoute.enterStreetAddress')}
                                    value={values.address.street}
                                    onChange={handleChange}
                                    isInvalid={touched.address && touched.address.street && !!errors.address && !!errors.address.street}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.street}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.zipCode')}
                                </Form.Label>
                                <Form.Control
                                    name="address.zip"
                                    type="text"
                                    placeholder={t('addEventRoute.enterZipCode')}
                                    value={values.address.zip}
                                    onChange={handleChange}
                                    isInvalid={touched.address && touched.address.zip && !!errors.address && !!errors.address.zip}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.zip}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addEventRoute.country')}
                                </Form.Label>
                                <Form.Control
                                    name="address.country"
                                    type="text"
                                    placeholder={t('addEventRoute.enterCountry')}
                                    value={values.address.country}
                                    onChange={handleChange}
                                    isInvalid={touched.address && touched.address.country && !!errors.address && !!errors.address.country}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.country}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                type="button"
                                name="placeMarker"
                                onClick={() => {
                                    setGettingCoordinates(true);
                                    dispatch(actions.getCoordinatesByAddress({
                                        street: values.address.street,
                                        zip: values.address.zip,
                                        city: values.address.city,
                                        country: values.address.country,
                                    }))
                                        .then(({ latitude, longitude }) => {
                                            setFieldValue('address', {
                                                ...values.address,
                                                latitude,
                                                longitude,
                                            });
                                            setCenter({
                                                latitude,
                                                longitude,
                                            });
                                        })
                                        .finally(() => setGettingCoordinates(false));
                                }}
                                loading={isGettingCoordinates}
                            >
                                {t('addEventRoute.placeMarkerOnMap')}
                            </Button>
                        </Col>
                    </Row>

                    <div
                        className="d-flex align-items-center border-top pt-3 mt-3"
                    >
                        <Button
                            type="button"
                            color="outline"
                            href="/agenda/events"
                        >
                            {t('addEventRoute.cancel')}
                        </Button>

                        <div className="d-flex justify-content-end flex-grow-1">
                            <Button color="yellow" loading={isSubmitting} type="submit">
                                {t('addEventRoute.addEvent')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>

            <Footer className="d-flex d-lg-none">
                <Button
                    color="outline"
                    href="/agenda/events"
                    size="extra-large"
                    className="d-flex align-items-center border-top-0 border-bottom-0 border-left-0 border-right rounded-0 text-dark px-3 px-sm-5"
                >
                    <ChevronLeft
                        className="text-darker-light mr-1 position-relative"
                        size={20}
                    />
                    {t('addObjectRoute.back')}
                </Button>
            </Footer>
        </>
    );
};

export default Add;
