import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import axios from 'services/axios';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as CLIENTS_ACTIONS from '../../../actions/clients/tables/subscriptions';
import * as CLIENTS_TYPES from '../../../actions/clients/tables/subscriptions/types';
import * as CLIENTS_SELECTORS from '../../../selectors/clients';

import * as TOAST_ACTIONS from '../../../actions/toast';

import { HTTP_METHOD } from '../../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../../const/http/HTTP_STATUS_CODE';
import { API_ADMIN_CLIENT_SUBSCRIPTIONS_ID_ROUTE } from '../../../../const/API_URL';

function* getClientSubscriptions(action) {
    const { payload } = action;
    const { clientId } = payload;

    const { page } = yield select(CLIENTS_SELECTORS.clientSubscriptionsSelector);
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(CLIENTS_ACTIONS.setClientSubscriptionsLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_SUBSCRIPTIONS_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            params: { page },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
            } = result;
            const subscriptions = items.map((item) => {
                const subscription = camelize(item);
                return {
                    id: subscription.id,
                    name: subscription.subscriptionName,
                    subscriptionStatus: subscription.subscriptionStatus,
                    amount: subscription.amount,
                    contract: {
                        startDate: subscription.contract.startDate,
                        endDate: subscription.contract.endDate,
                        nextDebitDate: subscription.contract.nextDebitDate,
                    },
                    unpaidCounter: subscription.unpaidCounter,
                    paymentType: subscription.paymentType,
                    paymentsStatusInfo: subscription.paymentStatusInfo,
                    pause: {
                        to: subscription.pausedTo,
                        from: subscription.pausedFrom,
                        paused: subscription.paused,
                        unlimited: !subscription.pausedTo,
                    },
                    updatingSubscription: subscription.updatingSubscription,
                    updatingAt: subscription.updatingAt,
                };
            });
            yield put(CLIENTS_ACTIONS.setClientSubscriptions({
                subscriptions,
                itemsCount,
                pagesCount,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.getSubscriptions.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(CLIENTS_ACTIONS.setClientSubscriptionsLoading({ loading: false }));
    }
}

export const clientSubscriptionSaga = [
    takeLatest(CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_GET, getClientSubscriptions),
];
