import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import * as styles from './GatesTableHeader.module.scss';

const T_PREFIX = 'gates.list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const GatesTableHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);

    return (
        <tr>
            <TableHeaderCell
                className={styles.wideCol}
            >
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.name`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.status`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.location`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.lastActivity`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.actions`)}
                />
            </TableHeaderCell>
        </tr>
    );
};

GatesTableHeader.propTypes = {};

GatesTableHeader.defaultProps = {};

export default GatesTableHeader;
