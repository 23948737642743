// extracted by mini-css-extract-plugin
var _1 = "MessageDetailsDialog_body__joC7G";
var _2 = "MessageDetailsDialog_contentColumn__XS8TD";
var _3 = "MessageDetailsDialog_description__lY6Qy";
var _4 = "MessageDetailsDialog_footer__pr87c";
var _5 = "MessageDetailsDialog_grayColor__I0gR7";
var _6 = "MessageDetailsDialog_greenColor__uDVo9";
var _7 = "MessageDetailsDialog_image__5cZHO";
var _8 = "MessageDetailsDialog_imageColumn__oMgO2";
var _9 = "MessageDetailsDialog_info__Z+fww";
var _a = "MessageDetailsDialog_infoItem__9g2mf";
var _b = "MessageDetailsDialog_infoItemTitle__HGLZL";
var _c = "MessageDetailsDialog_infoItemUserList__JbRKd";
var _d = "MessageDetailsDialog_infoItemUserListWithScroll__b0pWy";
var _e = "MessageDetailsDialog_infoItemUserListWrapper__wHF7I";
var _f = "MessageDetailsDialog_infoItemVideoUrl__hLwdM";
var _10 = "MessageDetailsDialog_rightBlockWrapper__m5wzm";
var _11 = "MessageDetailsDialog_title__4hh4z";
export { _1 as "body", _2 as "contentColumn", _3 as "description", _4 as "footer", _5 as "grayColor", _6 as "greenColor", _7 as "image", _8 as "imageColumn", _9 as "info", _a as "infoItem", _b as "infoItemTitle", _c as "infoItemUserList", _d as "infoItemUserListWithScroll", _e as "infoItemUserListWrapper", _f as "infoItemVideoUrl", _10 as "rightBlockWrapper", _11 as "title" }
