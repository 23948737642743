const PREFIX = '@@BUNDLES/';

const BUNDLE_ITEM = `${PREFIX}BUNDLE_ITEM`;
export const BUNDLE_ITEM_GET = `${BUNDLE_ITEM}:GET`;
export const BUNDLE_ITEM_SET = `${BUNDLE_ITEM}:SET`;
const BUNDLE_ITEM_LOADING = `${BUNDLE_ITEM}:LOADING`;
export const BUNDLE_ITEM_LOADING_SET = `${BUNDLE_ITEM_LOADING}:SET`;

const BUNDLES = `${PREFIX}BUNDLES`;
export const BUNDLES_GET = `${BUNDLES}:GET`;
export const BUNDLES_SET = `${BUNDLES}:SET`;
const BUNDLES_ITEM = `${BUNDLES}:ITEM`;
export const BUNDLES_ITEM_ADD = `${BUNDLES_ITEM}:ADD`;
export const BUNDLES_ITEM_EDIT = `${BUNDLES_ITEM}:EDIT`;
export const BUNDLES_ITEM_SELL = `${BUNDLES_ITEM}:SELL`;
export const BUNDLES_ITEM_ARCHIVE = `${BUNDLES_ITEM}:ARCHIVE`;
const BUNDLES_ITEM_STATUS = `${BUNDLES_ITEM}:STATUS`;
export const BUNDLES_ITEM_STATUS_TOGGLE = `${BUNDLES_ITEM_STATUS}:TOGGLE`;
const BUNDLES_PAGE = `${BUNDLES}:PAGE`;
export const BUNDLES_PAGE_SET = `${BUNDLES_PAGE}:SET`;
export const BUNDLES_PAGE_SET_FIRST = `${BUNDLES_PAGE_SET}:FIRST`;
export const BUNDLES_PAGE_SET_LAST = `${BUNDLES_PAGE_SET}:LAST`;
export const BUNDLES_PAGE_SET_PREV = `${BUNDLES_PAGE_SET}:PREV`;
export const BUNDLES_PAGE_SET_NEXT = `${BUNDLES_PAGE_SET}:NEXT`;
const BUNDLES_SEARCH = `${BUNDLES}:SEARCH`;
export const BUNDLES_SEARCH_SET = `${BUNDLES_SEARCH}:SET`;
const BUNDLES_LOADING = `${BUNDLES}:LOADING`;
export const BUNDLES_LOADING_SET = `${BUNDLES_LOADING}:SET`;
const BUNDLES_HIDE_ARCHIVED = `${BUNDLES}:HIDE_ARCHIVED`;
export const BUNDLES_HIDE_ARCHIVED_TOGGLE = `${BUNDLES_HIDE_ARCHIVED}:TOGGLE`;

const SOLD_BUNDLES = `${PREFIX}SOLD_BUNDLES`;
const SOLD_BUNDLES_ITEM = `${SOLD_BUNDLES}:ITEM`;
export const SOLD_BUNDLES_ITEM_EDIT = `${SOLD_BUNDLES_ITEM}:EDIT`;
export const SOLD_BUNDLES_ITEM_ARCHIVE = `${SOLD_BUNDLES_ITEM}:ARCHIVE`;
export const SOLD_BUNDLES_GET = `${SOLD_BUNDLES}:GET`;
export const SOLD_BUNDLES_SET = `${SOLD_BUNDLES}:SET`;
const SOLD_BUNDLES_PAGE = `${SOLD_BUNDLES}:PAGE`;
export const SOLD_BUNDLES_PAGE_SET = `${SOLD_BUNDLES_PAGE}:SET`;
export const SOLD_BUNDLES_PAGE_SET_FIRST = `${SOLD_BUNDLES_PAGE_SET}:FIRST`;
export const SOLD_BUNDLES_PAGE_SET_LAST = `${SOLD_BUNDLES_PAGE_SET}:LAST`;
export const SOLD_BUNDLES_PAGE_SET_PREV = `${SOLD_BUNDLES_PAGE_SET}:PREV`;
export const SOLD_BUNDLES_PAGE_SET_NEXT = `${SOLD_BUNDLES_PAGE_SET}:NEXT`;
const SOLD_BUNDLES_SEARCH = `${SOLD_BUNDLES}:SEARCH`;
export const SOLD_BUNDLES_SEARCH_SET = `${SOLD_BUNDLES_SEARCH}:SET`;
const SOLD_BUNDLES_LOADING = `${SOLD_BUNDLES}:LOADING`;
export const SOLD_BUNDLES_LOADING_SET = `${SOLD_BUNDLES_LOADING}:SET`;
const SOLD_BUNDLES_HIDE_ARCHIVED = `${SOLD_BUNDLES}:HIDE_ARCHIVED`;
export const SOLD_BUNDLES_HIDE_ARCHIVED_TOGGLE = `${SOLD_BUNDLES_HIDE_ARCHIVED}:TOGGLE`;
const SOLD_BUNDLES_PAYMENT_STATUS = `${SOLD_BUNDLES}:PAYMENT_STATUS`;
export const SOLD_BUNDLES_PAYMENT_STATUS_TOGGLE = `${SOLD_BUNDLES_PAYMENT_STATUS}:TOGGLE`;
export const SOLD_BUNDLES_PAYMENT_STATUS_SET = `${SOLD_BUNDLES_PAYMENT_STATUS}:SET`;

const COMPANY_BUNDLES = `${PREFIX}COMPANY_BUNDLES`;
export const COMPANY_BUNDLES_GET = `${COMPANY_BUNDLES}:GET`;
export const COMPANY_BUNDLES_SET = `${COMPANY_BUNDLES}:SET`;
const COMPANY_BUNDLES_LOADING = `${COMPANY_BUNDLES}:LOADING`;
export const COMPANY_BUNDLES_LOADING_SET = `${COMPANY_BUNDLES_LOADING}:SET`;

const CHECK_BUNDLE = `${PREFIX}CHECK_BUNDLE`;
export const CHECK_BUNDLE_GET = `${CHECK_BUNDLE}:GET`;
