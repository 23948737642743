import React, { useEffect, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ClientSubscriptionsTableHeader } from 'components/clients/ClientPage/ClientTables/ClientSubscriptions/ClientSubscriptionsTableHeader';
import { ClientSubscriptionsTableRow } from 'components/clients/ClientPage/ClientTables/ClientSubscriptions/ClientSubscriptionsTableRow';

import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { EmptyTable } from 'components/Common/NewTable/EmptyTable';
import * as CLIENTS_SELECTORS from '../../../../../../store/selectors/clients';
import * as CLIENTS_ACTIONS from '../../../../../../store/actions/clients/tables/subscriptions';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 6;

function ClientBundlesTable() {
    const { clientId } = useParams();

    const dispatch = useDispatch();

    const {
        page,
        items: subscriptions,
        showAll,
        loading,
        itemsCount,
        pagesCount,
    } = useSelector(CLIENTS_SELECTORS.clientSubscriptionsSelector);

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientSubscriptionsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientSubscriptionsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientSubscriptionsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientSubscriptionsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientSubscriptionsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientSubscriptionsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_ACTIONS.setClientSubscriptionsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClientSubscriptions({ clientId }));
    }, [dispatch, page]);

    return (
        <Container
            fluid
            className="m-0 p-0 my-5 d-flex flex-column flex-grow-1"
        >
            <Content loading={loading}>
                {itemsCount
                    ? (
                        <Table>
                            <TableHeader>
                                <ClientSubscriptionsTableHeader />
                            </TableHeader>
                            <TableBody>
                                {subscriptions.map((subscription) => (
                                    <ListTableBodyRow key={subscription.id}>
                                        <ClientSubscriptionsTableRow subscription={subscription} />
                                    </ListTableBodyRow>
                                ))}
                            </TableBody>
                            <ListTableFooter
                                page={page}
                                showAll={showAll}
                                itemsCount={itemsCount}
                                pagesCount={pagesCount}
                                columnsCount={COLUMNS_COUNT}
                                itemsPerPage={ITEMS_PER_PAGE}
                                onShowAll={handleShowAll}
                                onPrevPage={handlePrevPage}
                                onNextPage={handleNextPage}
                                onLastPage={handleLastPage}
                                onFirstPage={handleFirstPage}
                                onShowPages={handleShowPages}
                                onChangePage={handleChangePage}
                            />
                        </Table>
                    )
                    : <EmptyTable />}
            </Content>
        </Container>
    );
}

export default ClientBundlesTable;
