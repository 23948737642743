import React, { useCallback } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { formatPrice } from 'helpers/formatters/formatPrice';
import { Badge } from 'components/Common/Badge';
import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { useMobile } from 'hooks/useMobile';
import Container from 'components/Layout/Container/Container';
import SearchInput2 from 'components/Common/SearchInput2';
import { PeriodPicker } from '../../../Common/PeriodPicker';

import {
    BalanceIcon, BeeIcon, OneAndHalfCercleIcon,
} from '../../../Icon/Icon';

import { invoicesListSelector } from '../../../../store/selectors/financilas';
import { setExportPeriod, setInvoicesFilters } from '../../../../store/actions/financials/invoices';
import { INVOICE_STATUS } from '../../../../const/financials/invoices/INVOICE_STATUS';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './InvoicesPageHeader.module.scss';

const T_PREFIX = 'list.header';

const InvoicesPageHeader = (props) => {
    const {
        onExport,
        searchValue,
        onSearch,
    } = props;

    const isMobile = useMobile();

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const {
        totalRevenue,
        appyBeePay,
        manual,
        filters,
    } = useSelector(invoicesListSelector);
    const dispatch = useDispatch();

    const onChangeStatusHandler = useCallback((value) => {
        dispatch(setInvoicesFilters({
            filters: {
                status: filters.status === value
                    ? null
                    : value,
            },
        }));
    }, [dispatch, filters.status]);

    const onChangeDateHandler = useCallback(({ period, dateFrom, dateTo }) => {
        dispatch(setInvoicesFilters({
            filters: {
                from: dateFrom.format('X'),
                to: dateTo.format('X'),
            },
        }));

        dispatch(setExportPeriod({ period }));
    }, [dispatch]);

    return (
        <>
            <TextSubHeader
                text={t(`${T_PREFIX}.title`)}
                rightActions={
                    [
                        !isMobile && (
                            <div className={styles.filtrationButtonsContainer}>
                                <Badge
                                    onClick={() => onChangeStatusHandler(INVOICE_STATUS.PAID)}
                                    size="small"
                                    color={filters.status === INVOICE_STATUS.PAID ? 'yellow' : 'gray'}
                                >
                                    {t(`${T_PREFIX}.filtrationButtons.paid.label`)}
                                </Badge>

                                <Badge
                                    onClick={() => onChangeStatusHandler(INVOICE_STATUS.UNPAID)}
                                    size="small"
                                    color={filters.status === INVOICE_STATUS.UNPAID ? 'yellow' : 'gray'}
                                >
                                    {t(`${T_PREFIX}.filtrationButtons.unpaid.label`)}
                                </Badge>

                                <Badge
                                    onClick={() => onChangeStatusHandler(INVOICE_STATUS.DRAFT)}
                                    size="small"
                                    color={filters.status === INVOICE_STATUS.DRAFT ? 'yellow' : 'gray'}
                                >
                                    {t(`${T_PREFIX}.filtrationButtons.draft.label`)}
                                </Badge>

                            </div>
                        ),
                        !isMobile && (
                            <PeriodPicker
                                initDateFrom={moment.unix(filters.from)}
                                initDateTo={moment.unix(filters.to)}
                                onChange={onChangeDateHandler}
                            />
                        ),
                        !isMobile && (
                            <SearchInput2
                                onSearch={onSearch}
                                searchValue={searchValue}
                                stretched={false}
                            />
                        ),
                        <Button
                            color="yellow"
                            onClick={onExport}
                        >
                            {t(`${T_PREFIX}.actions.export.label`)}
                        </Button>,
                        !isMobile && (
                            <Button
                                color="yellow"
                                href="/financials/invoices/add"
                            >
                                {t(`${T_PREFIX}.actions.createInvoice.label`)}
                            </Button>
                        ),
                    ]
                }
            />

            <Container>
                <div className={styles.invoiceInfoPanel}>
                    <Row className={styles.cellInvoiceInfoPanel}>
                        <BalanceIcon className={styles.cellInvoiceInfoPanelIcon} />

                        <Row className="m-0 flex-column">
                            <div>
                                {t(`${T_PREFIX}.infoPanel.totalRevenue.label`)}
                            </div>
                            <div className={styles.totalPriceText}>
                                {(totalRevenue.total || totalRevenue.total === 0)
                                    ? formatPrice.toEuroWithComma({ amount: totalRevenue.total })
                                    : <Skeleton />}
                            </div>
                        </Row>

                        <Row className="m-0 w-100 justify-content-between">
                            <div className={styles.labelBeforeValue}>
                                {t(`${T_PREFIX}.infoPanel.net.label`)}
                            </div>
                            <div className={styles.regularPriceText}>
                                {(totalRevenue.net || totalRevenue.net === 0)
                                    ? formatPrice.toEuroWithComma({ amount: totalRevenue.net })
                                    : <Skeleton />}
                            </div>
                        </Row>

                        <Row className="m-0 w-100 justify-content-between">
                            <div className={styles.labelBeforeValue}>
                                {t(`${T_PREFIX}.infoPanel.vat.label`)}
                            </div>
                            <div className={styles.regularPriceText}>
                                {(totalRevenue.vat || totalRevenue.vat === 0)
                                    ? formatPrice.toEuroWithComma({ amount: totalRevenue.vat })
                                    : <Skeleton />}
                            </div>
                        </Row>
                    </Row>

                    <div className={styles.divider} />

                    <Row className={styles.cellInvoiceInfoPanel}>
                        <BeeIcon className={styles.cellInvoiceInfoPanelIcon} />

                        <Row className="m-0 flex-column">
                            <div>
                                {t(`${T_PREFIX}.infoPanel.appyBeePay.label`)}
                            </div>
                            <div className={styles.categoryPriceText}>
                                {(appyBeePay.total || appyBeePay.total === 0)
                                    ? formatPrice.toEuroWithComma({ amount: appyBeePay.total })
                                    : <Skeleton />}
                            </div>
                        </Row>

                        <Row className="m-0 w-100 justify-content-between">
                            <div className={styles.labelBeforeValue}>
                                {t(`${T_PREFIX}.infoPanel.net.label`)}
                            </div>
                            <div className={styles.regularPriceText}>
                                {(appyBeePay.net || appyBeePay.net === 0)
                                    ? formatPrice.toEuroWithComma({ amount: appyBeePay.net })
                                    : <Skeleton />}
                            </div>
                        </Row>

                        <Row className="m-0 w-100 justify-content-between">
                            <div className={styles.labelBeforeValue}>
                                {t(`${T_PREFIX}.infoPanel.vat.label`)}
                            </div>
                            <div className={styles.regularPriceText}>
                                {(appyBeePay.vat || appyBeePay.vat === 0)
                                    ? formatPrice.toEuroWithComma({ amount: appyBeePay.vat })
                                    : <Skeleton />}
                            </div>
                        </Row>
                    </Row>

                    <Row className={styles.cellInvoiceInfoPanel}>
                        <OneAndHalfCercleIcon className={styles.cellInvoiceInfoPanelIcon} />

                        <Row className="m-0 flex-column">
                            <div>
                                {t(`${T_PREFIX}.infoPanel.manual.label`)}
                            </div>
                            <div className={styles.categoryPriceText}>
                                {(manual.total || manual.total === 0)
                                    ? formatPrice.toEuroWithComma({ amount: manual.total })
                                    : <Skeleton />}
                            </div>
                        </Row>

                        <Row className="m-0 w-100 justify-content-between">
                            <div className={styles.labelBeforeValue}>
                                {t(`${T_PREFIX}.infoPanel.net.label`)}
                            </div>
                            <div className={styles.regularPriceText}>
                                {(manual.net || manual.net === 0)
                                    ? formatPrice.toEuroWithComma({ amount: manual.net })
                                    : <Skeleton />}
                            </div>
                        </Row>

                        <Row className="m-0 w-100 justify-content-between">
                            <div className={styles.labelBeforeValue}>
                                {t(`${T_PREFIX}.infoPanel.vat.label`)}
                            </div>
                            <div className={styles.regularPriceText}>
                                {(manual.vat || manual.vat === 0)
                                    ? formatPrice.toEuroWithComma({ amount: manual.vat })
                                    : <Skeleton />}
                            </div>
                        </Row>
                    </Row>
                </div>
            </Container>
        </>
    );
};

InvoicesPageHeader.propTypes = {
    onExport: PropTypes.func,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
};

InvoicesPageHeader.defaultProps = {
    onExport: PropTypes.emptyFunc,
    searchValue: '',
    onSearch: PropTypes.emptyFunc,
};

export default InvoicesPageHeader;
