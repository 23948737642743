import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import { LOCALE_NAMESPACE } from '../../const/translations/LOCALE_NAMESPACE';

const ToastManager = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.TOASTS);
    const toast = useSelector((state) => state.toast);

    const { addToast } = useToasts();

    useEffect(() => {
        if (!toast) {
            return;
        }

        if (Array.isArray(toast)) {
            toast.forEach(({
                appearance,
                isCustom,
                message,
                insertion,
            }) => addToast(isCustom ? message : t(message, insertion), {
                appearance,
            }));
        } else {
            const {
                appearance,
                isCustom,
                message,
                insertion,
            } = toast;

            const messageToShow = isCustom ? message : t(message, insertion);

            addToast(messageToShow, {
                appearance,
            });
        }
    }, [toast, addToast]);

    return null;
};

export default ToastManager;
