export const SOLD_SUBSCRIPTION_PROP = {
    PAUSE: {
        DAYS_BEFORE_NEXT_MONTH: 4,
    },
    RESUME: {
        DAYS_BEFORE_NEXT_MONTH: 4,
    },
    AUTO_DEBIT: {
        DAYS_PERIOD: 4,
    },
};
