import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';

import { Chip } from 'components/Common/Chip';
import * as CLIENTS_ACTIONS from 'store/actions/clients/tables/notes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useDispatch } from 'react-redux';

const ClientCategory = ({
    selected, category, onSelect, canDelete,
}) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const dispatch = useDispatch();
    const deleteCategoryConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: useCallback(() => {
            dispatch(CLIENTS_ACTIONS.deleteClientNotesCategory({ categoryId: category.id }));
        }, [dispatch]),
    });

    const categoryName = useMemo(() => {
        const key = `notes.category.default.${category.name.replaceAll(' ', '_')}`;
        const translation = t(key);
        if (translation.includes(key)) {
            return category.name;
        }
        return translation;
    }, [t, category.name]);

    return (
        <>
            <Chip
                onClick={() => onSelect(category.id)}
                onDeleteClick={canDelete ? deleteCategoryConfirmDialog.onShow : undefined}
                color={selected ? 'yellow' : 'gray'}
            >
                {categoryName}
            </Chip>
            {deleteCategoryConfirmDialog.visible && (
                <ConfirmationModal
                    isShow={deleteCategoryConfirmDialog.visible}
                    titleText={t('notes.category.delete.title', { name: category.name })}
                    bodyText={t('notes.category.delete.text')}
                    deleteText={t('notes.category.delete.actions.confirm')}
                    dismissText={t('notes.category.delete.actions.reject')}
                    confirmAction={deleteCategoryConfirmDialog.onConfirm}
                    hide={deleteCategoryConfirmDialog.onReject}
                />
            )}
        </>
    );
};

ClientCategory.propTypes = {
    category: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    canDelete: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
};

export default ClientCategory;
