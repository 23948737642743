import React from 'react';
import PropTypes from 'prop-types';

import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { useBool } from '../../../hooks/useBool';
import { SoldSubscriptionInfoTableCells } from '../SoldSubscriptionInfoTableCells';
import { SoldSubscriptionPaymentTableCells } from '../SoldSubscriptionPaymentTableCells';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './SoldSubscriptionsTableRow.module.scss';

const SoldSubscriptionsTableRow = (props) => {
    const {
        soldSubscription,
        onShow,
        onEdit,
        onPause,
        onResume,
        onCancel,
        onResend,
        onDelete,
        onToggleUserLock,
        onTogglePaymentStatus,
        onOpenClient,
    } = props;

    const showPayments = useBool(false);

    const { payments } = soldSubscription || {};

    return (
        <>
            <ListTableBodyRow>
                <SoldSubscriptionInfoTableCells
                    showPayments={showPayments.value}
                    soldSubscription={soldSubscription}
                    onShow={onShow}
                    onEdit={onEdit}
                    onPause={onPause}
                    onResume={onResume}
                    onCancel={onCancel}
                    onResend={onResend}
                    onDelete={onDelete}
                    onToggleUserLock={onToggleUserLock}
                    onToggleShowPayments={showPayments.onToggle}
                    onOpenClient={onOpenClient}
                />
            </ListTableBodyRow>
            {showPayments.value && (
                payments?.map?.((payment) => (
                    <ListTableBodyRow
                        key={payment?.paymentId}
                        className={styles.paymentRow}
                    >
                        <SoldSubscriptionPaymentTableCells
                            payment={payment}
                            soldSubscription={soldSubscription}
                            onTogglePaymentStatus={onTogglePaymentStatus}
                        />
                    </ListTableBodyRow>
                ))
            )}
        </>
    );
};

SoldSubscriptionsTableRow.propTypes = {
    soldSubscription: PropTypes.object,
    onShow: PropTypes.func,
    onPause: PropTypes.func,
    onResume: PropTypes.func,
    onCancel: PropTypes.func,
    onResend: PropTypes.func,
    onDelete: PropTypes.func,
    onToggleUserLock: PropTypes.func,
    onTogglePaymentStatus: PropTypes.func,
    onOpenClient: PropTypes.func,
};

SoldSubscriptionsTableRow.defaultProps = {
    soldSubscription: null,
    onShow: emptyFunc,
    onPause: emptyFunc,
    onResume: emptyFunc,
    onCancel: emptyFunc,
    onResend: emptyFunc,
    onDelete: emptyFunc,
    onToggleUserLock: emptyFunc,
    onTogglePaymentStatus: emptyFunc,
    onOpenClient: emptyFunc,
};

export default SoldSubscriptionsTableRow;
