import React, { useRef, useCallback, useMemo } from 'react';

const ALL_FILES_ACCEPT = '*';

const checkFiles = (
    files = [],
    sizeLimit = undefined,
    accept = [ALL_FILES_ACCEPT],
    handleError = () => {},
) => {
    const allFiles = Array.prototype.slice.call(files);
    const isAllFileAccept = accept[0] === ALL_FILES_ACCEPT;
    if (!sizeLimit && isAllFileAccept) return allFiles;

    const incorrectFiles = [];
    const correctFiles = [];

    allFiles.forEach((file) => {
        const fileType = `.${file?.type.split('/')[1]}`;
        const incorrectSize = file.size / 1_000_000 > sizeLimit;
        const incorrectType = !isAllFileAccept && !(accept.includes(fileType));
        if (incorrectSize || incorrectType) {
            incorrectFiles.push(file);
        } else {
            correctFiles.push(file);
        }
    });
    if (incorrectFiles.length > 0) {
        handleError(incorrectFiles);
    }
    return correctFiles;
};

function useFileSelect(
    {
        multiple, accept, sizeLimit, onError, onSelect, disabled,
    } = {
        multiple: false, accept: [ALL_FILES_ACCEPT], sizeLimit: undefined, disabled: false,
    },
) {
    const inputRef = useRef(null);
    const handleSelectClick = useCallback(() => {
        if (disabled) {
            return;
        }
        if (inputRef.current === null) {
            return;
        }

        inputRef.current.click();
    }, [disabled]);

    const handleError = (err) => {
        if (onError) {
            onError(err);
        }
    };

    const handleFileChange = useCallback(({ target }) => {
        if (!disabled) {
            const files = Array.prototype.slice.call(target.files);
            const correctFiles = checkFiles(files, sizeLimit, accept, handleError);
            if (correctFiles.length) {
                if (onSelect) {
                    onSelect(correctFiles);
                }
            }
        }
        inputRef.current.value = '';
    }, [onSelect, sizeLimit, disabled]);

    const FileInputElement = useMemo(
        () => (
            <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                ref={inputRef}
                multiple={multiple}
                accept={accept.join(',')}
            />
        ),
        [handleFileChange, multiple, accept],
    );

    return [FileInputElement, handleSelectClick];
}

export default useFileSelect;
