import React from 'react';

const BellOff = ({
    fill = 'currentColor', width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0017 21.6763C12.9913 21.6763 13.8423 21.0814 14.2165 20.2293C14.4385 19.7236 13.9719 19.2573 13.4196 19.2573H10.5838C10.0316 19.2573 9.56492 19.7236 9.78699 20.2293C10.1612 21.0814 11.0122 21.6763 12.0017 21.6763ZM20.1428 16.0177C19.4126 15.2331 18.0462 14.0526 18.0462 10.186C18.0462 7.24918 15.987 4.8982 13.2105 4.32142V3.53372C13.2105 2.86585 12.6692 2.32422 12.0017 2.32422C11.3342 2.32422 10.793 2.86585 10.793 3.53372V4.32142C8.0164 4.8982 5.95722 7.24918 5.95722 10.186C5.95722 14.0526 4.59085 15.2331 3.86062 16.0177C3.63383 16.2615 3.53329 16.5529 3.53518 16.8383C3.53934 17.4582 4.02579 18.0478 4.74847 18.0478H19.255C19.9777 18.0478 20.4645 17.4582 20.4683 16.8383C20.4702 16.5529 20.3696 16.2611 20.1428 16.0177Z" fill={fill} />
    </svg>
);

export default BellOff;
