import {
    call,
    put,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';

import * as MESSAGES_TYPES from '../../actions/messages/types';
import * as MESSAGES_ACTIONS from '../../actions/messages';
import * as TOAST_ACTIONS from '../../actions/toast';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import {
    API_ADMIN_PROMOTION_MESSAGE_ID_CLIENTS_ROUTE,
    API_ADMIN_PROMOTION_MESSAGE_ID_ROUTE,
    API_ADMIN_PROMOTION_MESSAGE_ROUTE,
} from '../../../const/API_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import { MESSAGES_LIST_PAGE } from '../../../const/CLIENT_URL';

function* getMessageItem(action) {
    const { payload } = action;
    const { messageId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(MESSAGES_ACTIONS.setMessageItemLoading({ isLoading: true }));

        const res = yield call(axios.request, {
            method: HTTP_METHOD.GET,
            url: API_ADMIN_PROMOTION_MESSAGE_ID_ROUTE({ messageId }),
            headers: defaultHeaders,
        });

        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(MESSAGES_ACTIONS.setMessageItem({ message: result }));
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(MESSAGES_ACTIONS.setMessageItemLoading({ isLoading: false }));
    }
}

function* addMessageItem(action) {
    const { payload } = action;
    const { message, history } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(MESSAGES_ACTIONS.setMessageItemSavingProcess({ isSavingProcess: true }));

        const res = yield call(axios.request, {
            method: HTTP_METHOD.POST,
            url: API_ADMIN_PROMOTION_MESSAGE_ROUTE,
            headers: defaultHeaders,
            data: message,
        });

        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.add.success',
                appearance: 'success',
            }));
            history.push(MESSAGES_LIST_PAGE);
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.add.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(MESSAGES_ACTIONS.setMessageItemSavingProcess({ isSavingProcess: false }));
    }
}

function* editMessageItem(action) {
    const { payload } = action;
    const {
        messageId,
        message,
        history,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(MESSAGES_ACTIONS.setMessageItemSavingProcess({ isSavingProcess: true }));

        const res = yield call(axios.request, {
            method: HTTP_METHOD.POST,
            url: API_ADMIN_PROMOTION_MESSAGE_ID_ROUTE({ messageId }),
            headers: defaultHeaders,
            data: message,
        });

        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.edit.success',
                appearance: 'success',
            }));
            history.push(MESSAGES_LIST_PAGE);
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(MESSAGES_ACTIONS.setMessageItemSavingProcess({ isSavingProcess: false }));
    }
}

function* addClientsToMessageItem(action) {
    const { payload } = action;
    const {
        messageId,
        message,
        onSuccess,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(MESSAGES_ACTIONS.setMessageItemSavingProcess({ isSavingProcess: true }));

        const res = yield call(axios.request, {
            method: HTTP_METHOD.POST,
            url: API_ADMIN_PROMOTION_MESSAGE_ID_CLIENTS_ROUTE({ messageId }),
            headers: defaultHeaders,
            data: message,
        });

        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.edit.success',
                appearance: 'success',
            }));
            onSuccess?.();
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(MESSAGES_ACTIONS.setMessageItemSavingProcess({ isSavingProcess: false }));
    }
}

function* removeMessageItem(action) {
    const { payload } = action;
    const { messageId, history } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            method: HTTP_METHOD.DELETE,
            url: API_ADMIN_PROMOTION_MESSAGE_ID_ROUTE({ messageId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(MESSAGES_ACTIONS.getMessages());
            history.push(MESSAGES_LIST_PAGE);
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.remove.success',
                appearance: 'success',
            }));
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.remove.error',
                appearance: 'error',
            }));
        }
        function* handleCustomFail() {
            const message = error.response?.data?.result?.message;
            if (message) {
                yield put(TOAST_ACTIONS.showToast({
                    message,
                    appearance: 'error',
                    isCustom: true,
                }));
            }
            return Boolean(message);
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        const handled = yield call(handleCustomFail);
        if (!handled) {
            yield call(handleFail);
        }
    }
}

export const messageItemSaga = [
    takeLatest(MESSAGES_TYPES.MESSAGE_ITEM_GET, getMessageItem),
    takeEvery(MESSAGES_TYPES.MESSAGES_ITEM_ADD, addMessageItem),
    takeEvery(MESSAGES_TYPES.MESSAGES_ITEM_EDIT, editMessageItem),
    takeEvery(MESSAGES_TYPES.MESSAGES_ITEM_ADD_CLIENTS, addClientsToMessageItem),
    takeEvery(MESSAGES_TYPES.MESSAGES_ITEM_REMOVE, removeMessageItem),
];
