import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from 'components/Common/Content';
import Container from 'components/Layout/Container/Container';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { EmptyTable } from 'components/Common/NewTable/EmptyTable';
import { ClearingsPageHeader } from '../../../../components/Financials/Clearings/ClearingsPageHeader';
import { ClearingsTableHeader } from '../../../../components/Financials/Clearings/ClearingsTableHeader';
import { ClearingsTableBodyRow } from '../../../../components/Financials/Clearings/ClearingsTableBodyRow';

import * as CLEARINGS_ACTIONS from '../../../../store/actions/financials/clearings';
import * as FINANCIALS_SELECTORS from '../../../../store/selectors/financilas';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 4;

function Clearings() {
    const dispatch = useDispatch();

    const {
        page,
        items,
        showAll,
        loading,
        itemsCount,
        pagesCount,
    } = useSelector(FINANCIALS_SELECTORS.clearingsListSelector);

    const clearingsList = useMemo(() => (
        (!showAll && items.length > ITEMS_PER_PAGE)
            ? items.slice(0, ITEMS_PER_PAGE)
            : items
    ), [showAll, items]);

    const handlePrevPage = useCallback(() => {
        dispatch(CLEARINGS_ACTIONS.setClearingsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLEARINGS_ACTIONS.setClearingsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLEARINGS_ACTIONS.setClearingsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLEARINGS_ACTIONS.setClearingsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLEARINGS_ACTIONS.setClearingsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLEARINGS_ACTIONS.setClearingsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLEARINGS_ACTIONS.setClearingsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(CLEARINGS_ACTIONS.getClearings());
    }, [dispatch, page]);

    return (
        <>
            <ClearingsPageHeader />
            <Container>
                <Content loading={loading}>
                    {
                        !!itemsCount && (
                            <Table>
                                <TableHeader>
                                    <ClearingsTableHeader />
                                </TableHeader>

                                <TableBody>
                                    {clearingsList.map((clearing) => (
                                        <ListTableBodyRow key={clearing.id}>
                                            <ClearingsTableBodyRow
                                                clearing={clearing}
                                            />
                                        </ListTableBodyRow>
                                    ))}
                                </TableBody>

                                <ListTableFooter
                                    page={page}
                                    showAll={showAll}
                                    itemsCount={itemsCount}
                                    pagesCount={pagesCount}
                                    columnsCount={COLUMNS_COUNT}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    onShowAll={handleShowAll}
                                    onPrevPage={handlePrevPage}
                                    onNextPage={handleNextPage}
                                    onLastPage={handleLastPage}
                                    onFirstPage={handleFirstPage}
                                    onShowPages={handleShowPages}
                                    onChangePage={handleChangePage}
                                />
                            </Table>
                        )
                    }
                    {
                        !itemsCount && (
                            <EmptyTable />
                        )
                    }
                </Content>
            </Container>
        </>
    );
}

export default Clearings;
