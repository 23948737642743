import React from 'react';

const Play = (props) => {
    const { width = '24', height = '24' } = props;

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.756 14.1639C19.421 13.2019 19.421 10.7979 17.756 9.83491L9.251 4.91791C7.585 3.95491 5.5 5.15791 5.5 7.08291V16.9169C5.5 18.8419 7.585 20.0449 9.251 19.0809L17.756 14.1639Z" fill="currentColor" />
        </svg>
    );
};

export default Play;
