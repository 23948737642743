import React, {
    useState, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

// Components
import classNames from 'classnames';
import useFileSelect from 'hooks/useFileSelect';

// Styles
import { PlusBoxIcon, GaleryIcon } from 'components/Icon/Icon';
import { Button } from 'components/Common/Button';
import IconButton from 'components/Common/IconButton/IconButton';
import * as styles from './ImagesGallery.module.scss';
import FileCropModal from '../FileUpload/FileCropModal';

const FilesSelect = ({
    className,
    onChange,
    allowedFormats = ['.jpg', '.jpeg', '.png'],
    allowedFileSize,
    allowedFileCount,
    disabledActions = false,
    compact = false,
    square,
}) => {
    const { t } = useTranslation();

    const { addToast } = useToasts();

    const handleFileSelectError = useCallback(() => {
        addToast(
            t('chooseOtherFile', {
                formats: allowedFormats.join(` ${t('separator')} `),
                size: allowedFileSize,
            }),
            { appearance: 'warning' },
        );
    }, [allowedFormats, allowedFileSize]);
    const [blobs, setBlobs] = useState([]);
    const [croppedBlobs, setCroppedBlobs] = useState([]);
    const [currentBlob, setCurrentBlob] = useState(null);

    const handleUpdateQueue = (queue, newCroppedBlobs = []) => {
        if (!queue.length) {
            onChange(newCroppedBlobs);
            setBlobs([]);
            setCroppedBlobs([]);
            setCurrentBlob(null);
            return;
        }
        const copyBlobs = [...queue];
        const firstBlob = copyBlobs.shift();
        setBlobs(copyBlobs);
        setCurrentBlob(firstBlob);
    };

    const handleFileSelect = useCallback((files) => {
        const allowedFiles = files.slice(0, allowedFileCount);
        setBlobs(allowedFiles);
        handleUpdateQueue(allowedFiles, []);
    }, [allowedFileCount]);

    const [FileInputElement, handleSelectClick] = useFileSelect({
        multiple: true,
        sizeLimit: allowedFileSize,
        accept: allowedFormats,
        disabled: disabledActions,
        onError: handleFileSelectError,
        onSelect: handleFileSelect,
    });

    const handleCancel = useCallback(() => {
        handleUpdateQueue(blobs, croppedBlobs);
    }, [blobs, croppedBlobs]);

    const handleSubmit = useCallback((blob) => {
        const newCroppedBlobs = [...croppedBlobs, blob];
        setCroppedBlobs(newCroppedBlobs);
        handleUpdateQueue(blobs, newCroppedBlobs);
    }, [onChange, blobs, croppedBlobs]);

    if (disabledActions) {
        return null;
    }

    return (
        <div
            className={classNames(
                'w-100 h-100 position-relative d-flex flex-column justify-content-center align-items-center',
                compact && 'border-dashed border-darker-light rounded',
                styles.container,
                {
                    [styles.compact]: compact,
                },
                className,
            )}
        >
            {compact && (
                <IconButton
                    color="transparent"
                    stretched
                    type="button"
                    onClick={handleSelectClick}
                >
                    <PlusBoxIcon />
                </IconButton>
            )}
            {!compact && (
                <div className={classNames(styles.gray, 'w-100 h-100 d-flex flex-column justify-content-center align-items-center my-2')}>
                    <GaleryIcon />
                    <span className="mt-3 mb-4 font-weight-700">
                        {t('fileUpload.upToLabel', { count: allowedFileCount })}
                    </span>
                    <Button
                        type="button"
                        color="yellow"
                        onClick={handleSelectClick}
                    >
                        {t('fileUpload.clickToUpload')}
                    </Button>
                </div>
            )}
            {currentBlob && (
                <FileCropModal
                    key={currentBlob}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    square={square}
                    image={currentBlob}
                />
            )}
            {FileInputElement}
        </div>
    );
};

export default FilesSelect;
