import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import moment from 'moment';
import classNames from 'classnames';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';

import {
    Form, InputGroup,

} from 'react-bootstrap';

import { useBool } from '../../../hooks/useBool';
import { generateRandomString } from '../../../helpers/string/generateRandomString';
import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { getError, isInvalid } from '../../../services/validationService';

import DateInput from '../../DateInput/DateInput';

import { Calendar2 } from '../../Icon/Icon';

import * as styles from './PeriodPicker.module.scss';
import { Column } from '../Column';
import { Row } from '../Row';
import TextButton from '../TextButton/TextButton';
import { Button } from '../Button';

const PERIODS_MAP = {
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
    custom: 'custom',
};

const T_PREFIX = 'periodPicker';

export const PeriodPickerPanel = (props) => {
    const {
        initDateFrom,
        initDateTo,
        initPeriod,
        onChange,
        withoutConfirm,
        periods,
    } = props;

    const { t } = useTranslation();
    const hasErrors = useBool(false);
    const [withCurrentPeriodOffset, setWithCurrentPeriodOffset] = useState(!(
        moment().startOf(initPeriod).isSame(initDateFrom, 'day')
            && moment().endOf(initPeriod).isSame(initDateTo, 'day')
    ));

    const validationSchema = useMemo(() => yup.object({
        dateFrom: yup
            .mixed()
            .required(t(`${T_PREFIX}.inputErrors.required`)),
        dateTo: yup
            .mixed()
            .required(t(`${T_PREFIX}.inputErrors.required`))
            .when('dateFrom', (dateFrom, schema) => schema.test({
                name: generateRandomString(),
                test: (dateTo) => dateTo.isSameOrAfter(dateFrom),
                message: t(`${T_PREFIX}.inputErrors.endAfterStart`),
            })),
    }), [t]);

    const {
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleSubmit: handleApply,
        resetForm,
    } = useFormik({
        initialValues: {
            period: initPeriod,
            dateFrom: initDateFrom,
            dateTo: initDateTo,
        },
        validationSchema,
        onSubmit: (values) => {
            if (hasErrors.value) {
                return;
            }

            onChange(values);
        },
    });

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const invalid = isInvalid(k, errors, touched);
            if (invalid) {
                hasErrors.onTrue();
            }
            const subValidation = {
                isInvalid: invalid,
                error: getError(k, errors),
            };
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [values, errors, touched]);

    const handleSetPeriod = useCallback(({ period }) => () => {
        setFieldValue('period', period);

        if (period === PERIODS_MAP.custom) {
            return;
        }

        if (withCurrentPeriodOffset) {
            setFieldValue('dateTo', values.dateTo.clone().endOf('day'));
            setFieldValue('dateFrom', values.dateTo.clone().endOf('day').subtract(1, period).add(1, 's'));
        } else {
            setFieldValue('dateFrom', moment().startOf(period));
            setFieldValue('dateTo', moment().endOf(period));
        }
    }, [values.period, values.dateFrom, values.dateTo, withCurrentPeriodOffset]);

    const handleStartDateChange = useCallback((dateFrom) => {
        setFieldValue('dateFrom', dateFrom.clone().startOf('day'));
        setFieldTouched('dateFrom', true);
        setFieldTouched('dateTo', true);

        if (values.period !== PERIODS_MAP.custom) {
            setFieldValue('dateTo', dateFrom.clone().startOf('day').add(1, values.period).subtract(1, 's'));
            setFieldTouched('dateTo', true);
        }

        if (!dateFrom.isSame(values.dateFrom, 'day')) {
            setWithCurrentPeriodOffset(true);
        }
    }, [setFieldValue, setFieldTouched, values]);

    const handleEndDateChange = useCallback((dateTo) => {
        setFieldValue('dateTo', dateTo.clone().endOf('day'));
        setFieldTouched('dateTo', true);

        if (values.period !== PERIODS_MAP.custom) {
            setFieldValue('dateFrom', dateTo.clone().endOf('day').subtract(1, values.period).add(1, 'day'));
            setFieldTouched('dateFrom', true);
        }

        if (!dateTo.isSame(values.dateTo, 'day')) {
            setWithCurrentPeriodOffset(true);
        }
    }, [setFieldValue, setFieldTouched, values]);

    useEffect(() => {
        if (hasErrors.value || !withoutConfirm) {
            return;
        }

        onChange(values);
    }, [values]);

    return (
        <>
            <div>
                <Column gap={8}>
                    <span>
                        {t(`${T_PREFIX}.dateInput.period`)}
                    </span>

                    <Row gap={0} stretched>
                        {periods.map((period) => (
                            <Button
                                key={period}
                                size="small"
                                group
                                color={values.period === period ? 'yellow' : 'outline'}
                                onClick={handleSetPeriod({ period })}
                            >
                                {t(`${T_PREFIX}.periodBtns.${period}`)}
                            </Button>
                        ))}
                    </Row>
                </Column>
            </div>
            <div>

                {values.period === PERIODS_MAP.day && (
                    <Column gap={8}>
                        <span>
                            {t(`${T_PREFIX}.dateInput.date`)}
                        </span>

                        <InputGroup>
                            <DateInput
                                placeholder={t(`${T_PREFIX}.dateInput.selectDate`)}
                                value={values.dateFrom}
                                format="DD.MM.YYYY"
                                onChange={handleStartDateChange}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <Calendar2 />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Column>
                )}
                {values.period !== PERIODS_MAP.day && (
                    <Row gap={16} spaceBetween stretched>
                        <Column gap={8}>
                            <span>
                                {t(`${T_PREFIX}.dateInput.dateFrom`)}
                            </span>

                            <InputGroup>
                                <DateInput
                                    placeholder={t(`${T_PREFIX}.dateInput.selectStartDate`)}
                                    value={values.dateFrom}
                                    format="DD.MM.YYYY"
                                    onChange={handleStartDateChange}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        <Calendar2 />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>

                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.dateTo?.isInvalid
                                                && values.period === PERIODS_MAP.custom,
                                })}
                            >
                                {t(`${T_PREFIX}.inputErrors.startBeforeEnd`)}
                            </Form.Control.Feedback>
                        </Column>

                        <Column gap={8}>
                            <span>
                                {t(`${T_PREFIX}.dateInput.dateTo`)}
                            </span>

                            <InputGroup>
                                <DateInput
                                    placeholder={t(`${T_PREFIX}.dateInput.selectEndDate`)}
                                    value={values.dateTo}
                                    format="DD.MM.YYYY"
                                    onChange={handleEndDateChange}
                                    align="right"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        <Calendar2 />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>

                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-block': validation?.dateTo?.isInvalid
                                                && values.period === PERIODS_MAP.custom,
                                })}
                            >
                                {validation?.dateTo?.error}
                            </Form.Control.Feedback>
                        </Column>
                    </Row>
                )}
            </div>

            {!withoutConfirm && (
                <Row stretched spaceBetween>
                    <TextButton onClick={resetForm}>
                        {t(`${T_PREFIX}.actions.clear`)}
                    </TextButton>
                    <Button
                        onClick={handleApply}
                    >
                        {t(`${T_PREFIX}.actions.apply`)}
                    </Button>
                </Row>
            )}
        </>
    );
};

export const PeriodPicker = (props) => {
    const {
        initDateFrom,
        initDateTo,
        initPeriod,
        onChange,
        stretched,
        periods,
        align,
    } = props;

    const isVisibleDropdown = useBool(false);

    const periodPickerRef = useRef(null);

    const getFormattedPeriod = useCallback(({ period, dateFrom, dateTo }) => {
        if (period === PERIODS_MAP.day) {
            return dateTo.format('DD MMM YYYY');
        }

        return `${dateFrom.format('DD MMM YYYY')} - ${dateTo.format('DD MMM YYYY')}`;
    }, []);

    const [period, setPeriod] = useState(initPeriod);
    const [formattedPeriod, setFormattedPeriod] = useState(getFormattedPeriod({
        dateFrom: initDateFrom, dateTo: initDateTo, period,
    }));

    const onChangeHandler = useCallback(({ period, dateFrom, dateTo }) => {
        setFormattedPeriod(getFormattedPeriod({ period, dateFrom, dateTo }));
        setPeriod(period);

        onChange({ period, dateFrom, dateTo });
        isVisibleDropdown.onFalse();
    }, [getFormattedPeriod, onChange]);

    useEffect(() => {
        const handleClickOutsideElement = (e) => {
            if (!document.contains(e.target) || periodPickerRef.current.contains(e.target)) {
                return;
            }

            isVisibleDropdown.onFalse();
        };

        document.addEventListener('click', handleClickOutsideElement);

        return () => {
            document.removeEventListener('click', handleClickOutsideElement);
        };
    }, []);

    return (
        <div
            ref={periodPickerRef}
            className={classNames(styles.periodPickerContainer, stretched && styles.stretched)}
        >
            <button
                onClick={isVisibleDropdown.onToggle}
                className={classNames(
                    styles.periodPickerToggleButton,
                    { [styles.periodPickerToggleButtonActive]: isVisibleDropdown.value },
                )}
            >
                {formattedPeriod}

                <Calendar2 className={styles.periodPickerToggleButtonIcon} />
            </button>
            <div className={styles.panelContainer}>
                {isVisibleDropdown.value && (
                    <div className={classNames(styles.periodPickerDropdown, styles[align])}>
                        <PeriodPickerPanel
                            initDateFrom={initDateFrom}
                            initDateTo={initDateTo}
                            initPeriod={period}
                            onChange={onChangeHandler}
                            periods={periods}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

PeriodPicker.propTypes = {
    initDateFrom: MomentPropTypes.momentObj,
    initDateTo: MomentPropTypes.momentObj,
    initPeriod: PropTypes.string,
    stretched: PropTypes.bool,
    onChange: PropTypes.func,
    periods: PropTypes.arrayOf(PropTypes.string),
};

PeriodPicker.defaultProps = {
    initDateFrom: moment(),
    initDateTo: moment(),
    initPeriod: PERIODS_MAP.month,
    stretched: false,
    onChange: emptyFunc,
    periods: Object.values(PERIODS_MAP),
};

PeriodPickerPanel.propTypes = {
    initDateFrom: MomentPropTypes.momentObj,
    initDateTo: MomentPropTypes.momentObj,
    initPeriod: PropTypes.string,
    onChange: PropTypes.func,
    withoutConfirm: PropTypes.bool,
    periods: PropTypes.arrayOf(PropTypes.string),
    align: PropTypes.oneOf(['left', 'right']),
};

PeriodPickerPanel.defaultProps = {
    initDateFrom: moment(),
    initDateTo: moment(),
    initPeriod: PERIODS_MAP.month,
    onChange: emptyFunc,
    withoutConfirm: false,
    periods: Object.values(PERIODS_MAP),
    align: 'left',
};

export default PeriodPicker;
