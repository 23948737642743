import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ChevronDown, ChevronUp } from 'react-feather';
import Select from 'react-select';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';

import { IGNORED_NUMBER_SYMBOLS } from 'const/string/IGNORED_NUMBER_SYMBOLS';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { Button } from 'components/Common/Button';
import { useIncDec } from '../../../hooks/useIncDec';

import { SERVICE_PROP } from '../../../const/services/SERVICE_PROP';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { getError, isInvalid } from '../../../services/validationService';

import { styles, theme } from '../../../styles/select';

const T_PREFIX = 'addServiceRoute';
const T_FIELDS = `${T_PREFIX}.form.fields`;
const T_TABS = `${T_PREFIX}.tabs`;

const BOOKING_FREQUENCY_OPTIONS = range(
    SERVICE_PROP.BOOKING_FREQUENCY.MIN,
    SERVICE_PROP.BOOKING_FREQUENCY.MAX + 1,
    SERVICE_PROP.BOOKING_FREQUENCY.STEP,
).map((v) => ({
    value: v,
    label: v,
}));

const SubOptionForm = (props) => {
    const {
        vatRate,
        subOption,
        formState,
        onDelete,
        onChangeName,
        onChangeTime,
        onChangePrice,
        onChangeSeatsAmount,
        onChangeAdditionalTime,
        onChangeBookingFrequency,
    } = props;

    const { t } = useTranslation();

    const { errors, touched } = formState;

    const validationState = useMemo(() => (
        Object.keys(subOption).reduce((res, k) => ({
            ...res,
            [k]: {
                isInvalid: isInvalid(`subOptions[${subOption.key}].${k}`, errors, touched),
                error: getError(`subOptions[${subOption.key}].${k}`, errors),
            },
        }), [])
    ), [errors, touched, subOption.key]);

    const priceWithoutVat = (subOption.price / (1 + vatRate / 100))
        .toFixed(SERVICE_PROP.PRICE.TO_FIXED);

    const bookingFrequencyValue = useMemo(() => ({
        value: subOption.bookingFrequency,
        label: subOption.bookingFrequency,
    }), [subOption.bookingFrequency]);

    const handleDelete = useCallback(() => {
        onDelete({ key: subOption.key });
    }, [onDelete, subOption.key]);

    const handleChangeName = useCallback((e) => {
        const { target: { value } } = e;
        onChangeName({ key: subOption.key, name: value });
    }, [onChangeName, subOption.key]);

    const handleChangeTime = useCallback((e) => {
        const { target, nativeEvent } = e;
        const { value } = target;
        const { data } = nativeEvent;
        if (IGNORED_NUMBER_SYMBOLS.includes(data)) {
            return;
        }
        const num = parseInt(value, 10);
        const time = (num || num === 0) ? num : '';
        onChangeTime({ key: subOption.key, time });
    }, [onChangeTime, subOption.key]);

    const {
        onInc: handleIncTime,
        onDec: handleDecTime,
    } = useIncDec({
        min: SERVICE_PROP.TIME.MIN,
        step: SERVICE_PROP.TIME.STEP,
        value: subOption.time,
        onChange: useCallback((time) => {
            onChangeTime({ key: subOption.key, time });
        }, [onChangeTime, subOption.key]),
    });

    const handleChangePrice = useCallback((e) => {
        const { target, nativeEvent } = e;
        const { value } = target;
        const { data } = nativeEvent;
        if (IGNORED_NUMBER_SYMBOLS.includes(data)) {
            return;
        }
        const num = parseFloat(value);
        const price = (num || num === 0) ? num : '';
        onChangePrice({ key: subOption.key, price });
    }, [onChangePrice, subOption.key]);

    const {
        onInc: handleIncPrice,
        onDec: handleDecPrice,
    } = useIncDec({
        min: SERVICE_PROP.PRICE.MIN,
        max: SERVICE_PROP.PRICE.MAX,
        step: SERVICE_PROP.PRICE.STEP,
        value: subOption.price,
        toFixed: SERVICE_PROP.PRICE.TO_FIXED,
        onChange: useCallback((price) => {
            onChangePrice({ key: subOption.key, price });
        }, [onChangePrice, subOption.key]),
    });

    const handleChangeSeatsAmount = useCallback((e) => {
        const { target, nativeEvent } = e;
        const { value } = target;
        const { data } = nativeEvent;
        if (IGNORED_NUMBER_SYMBOLS.includes(data)) {
            return;
        }
        const num = parseInt(value, 10);
        const seatsAmount = (num || num === 0) ? num : '';
        onChangeSeatsAmount({ key: subOption.key, seatsAmount });
    }, [onChangeSeatsAmount, subOption.key]);

    const {
        onInc: handleIncSeatsAmount,
        onDec: handleDecSeatsAmount,
    } = useIncDec({
        min: SERVICE_PROP.SEATS_AMOUNT.MIN,
        step: SERVICE_PROP.SEATS_AMOUNT.STEP,
        value: subOption.seatsAmount,
        onChange: useCallback((seatsAmount) => {
            onChangeSeatsAmount({ key: subOption.key, seatsAmount });
        }, [onChangeSeatsAmount, subOption.key]),
    });

    const handleChangeAdditionalTime = useCallback((e) => {
        const { target, nativeEvent } = e;
        const { value } = target;
        const { data } = nativeEvent;
        if (IGNORED_NUMBER_SYMBOLS.includes(data)) {
            return;
        }
        const num = parseInt(value, 10);
        const addTime = (num || num === 0) ? num : '';
        onChangeAdditionalTime({ key: subOption.key, addTime });
    }, [onChangeAdditionalTime, subOption.key]);

    const {
        onInc: handleIncAdditionalTime,
        onDec: handleDecAdditionalTime,
    } = useIncDec({
        min: SERVICE_PROP.ADDITIONAL_TIME.MIN,
        max: SERVICE_PROP.ADDITIONAL_TIME.MAX,
        step: SERVICE_PROP.ADDITIONAL_TIME.STEP,
        value: subOption.addTime,
        onChange: useCallback((addTime) => {
            onChangeAdditionalTime({ key: subOption.key, addTime });
        }, [onChangeAdditionalTime, subOption.key]),
    });

    const handleChangeBookingFrequency = useCallback(({ value }) => {
        onChangeBookingFrequency({ key: subOption.key, bookingFrequency: value });
    }, [onChangeBookingFrequency, subOption.key]);

    return (
        <div className="py-4 border-bottom border-gray-200">
            <Row>
                <Col xs={12} lg={4}>
                    <Form.Group>
                        <Form.Label className="font-size-15 d-flex align-items-center">
                            {t(`${T_FIELDS}.name.label`)}
                            <InfoTooltip
                                text={t(`${T_FIELDS}.name.tooltip`)}
                                placement="top"
                            />
                        </Form.Label>
                        <Form.Control
                            value={subOption.name}
                            onChange={handleChangeName}
                            isInvalid={validationState.name?.isInvalid}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.name?.isInvalid,
                            })}
                        >
                            {validationState.name?.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={4}>
                    <Form.Label className="font-size-15 d-flex align-items-center">
                        {t(`${T_FIELDS}.price.label`)}
                    </Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="number"
                            value={subOption.price}
                            onChange={handleChangePrice}
                            isInvalid={validationState.price?.isInvalid}
                        />
                        <InputGroup.Append className="border-left">
                            <div className="d-flex flex-column border border-light rounded-right">
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleIncPrice}
                                >
                                    <ChevronUp size={16} />
                                </button>
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleDecPrice}
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </div>
                        </InputGroup.Append>
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.price?.isInvalid,
                            })}
                        >
                            {validationState.price?.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col xs={12} lg={4}>
                    <Form.Group>
                        <Form.Label className="font-size-15">
                            {t(`${T_FIELDS}.priceWithoutVat.label`)}
                        </Form.Label>
                        <Form.Control
                            value={priceWithoutVat}
                            disabled
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={3}>
                    <Form.Label className="font-size-15 d-flex align-items-center">
                        {t(`${T_FIELDS}.time.label`)}
                        <InfoTooltip
                            text={t(`${T_FIELDS}.time.tooltip`)}
                            placement="top"
                        />
                    </Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="number"
                            value={subOption.time}
                            onChange={handleChangeTime}
                            isInvalid={validationState.time?.isInvalid}
                        />
                        <InputGroup.Append className="border-left">
                            <div className="d-flex flex-column border border-light rounded-right">
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleIncTime}
                                >
                                    <ChevronUp size={16} />
                                </button>
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleDecTime}
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </div>
                        </InputGroup.Append>
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.time?.isInvalid,
                            })}
                        >
                            {validationState.time?.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col xs={12} lg={3}>
                    <Form.Group>
                        <Form.Label className="font-size-15 d-flex align-items-center">
                            {t(`${T_FIELDS}.bookingFrequency.label`)}
                            <InfoTooltip
                                text={t(`${T_FIELDS}.bookingFrequency.tooltip`)}
                                placement="top"
                            />
                        </Form.Label>
                        <Select
                            styles={styles}
                            theme={theme}
                            options={BOOKING_FREQUENCY_OPTIONS}
                            value={bookingFrequencyValue}
                            onChange={handleChangeBookingFrequency}
                            placeholder={t('placeholders.select')}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.bookingFrequency?.isInvalid,
                            })}
                        >
                            {validationState.bookingFrequency?.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={3}>
                    <Form.Label className="font-size-15 d-flex align-items-center">
                        {t(`${T_FIELDS}.addTime.label`)}
                        <InfoTooltip
                            text={t(`${T_FIELDS}.addTime.tooltip`)}
                            placement="top"
                        />
                    </Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="number"
                            value={subOption.addTime}
                            onChange={handleChangeAdditionalTime}
                            isInvalid={validationState.addTime?.isInvalid}
                        />
                        <InputGroup.Append className="border-left">
                            <div className="d-flex flex-column border border-light rounded-right">
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleIncAdditionalTime}
                                >
                                    <ChevronUp size={16} />
                                </button>
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleDecAdditionalTime}
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </div>
                        </InputGroup.Append>
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.addTime?.isInvalid,
                            })}
                        >
                            {validationState.addTime?.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col xs={12} lg={3}>
                    <Form.Label className="font-size-15 d-flex align-items-center">
                        {t(`${T_FIELDS}.seatsAmount.label`)}
                        <InfoTooltip
                            text={t(`${T_FIELDS}.seatsAmount.tooltip`)}
                            placement="top"
                        />
                    </Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="number"
                            value={subOption.seatsAmount}
                            onChange={handleChangeSeatsAmount}
                            isInvalid={validationState.seatsAmount?.isInvalid}
                        />
                        <InputGroup.Append className="border-left">
                            <div className="d-flex flex-column border border-light rounded-right">
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleIncSeatsAmount}
                                >
                                    <ChevronUp size={16} />
                                </button>
                                <button
                                    type="button"
                                    className="d-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0 text-darker-light"
                                    style={{ width: 23, height: 19 }}
                                    onClick={handleDecSeatsAmount}
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </div>
                        </InputGroup.Append>
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.seatsAmount?.isInvalid,
                            })}
                        >
                            {validationState.seatsAmount?.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                <Button
                    type="button"
                    color="red"
                    size="small"
                    onClick={handleDelete}
                >
                    {t(`${T_TABS}.subOptions.actions.delete`)}
                </Button>
            </div>
        </div>
    );
};

SubOptionForm.propTypes = {
    vatRate: PropTypes.number.isRequired,
    subOption: PropTypes.object.isRequired,
    formState: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onChangeName: PropTypes.func,
    onChangeTime: PropTypes.func,
    onChangePrice: PropTypes.func,
    onChangeSeatsAmount: PropTypes.func,
    onChangeAdditionalTime: PropTypes.func,
    onChangeBookingFrequency: PropTypes.func,
};

SubOptionForm.defaultProps = {
    onDelete: emptyFunc,
    onChangeName: emptyFunc,
    onChangeTime: emptyFunc,
    onChangePrice: emptyFunc,
    onChangeSeatsAmount: emptyFunc,
    onChangeAdditionalTime: emptyFunc,
    onChangeBookingFrequency: emptyFunc,
};

export default SubOptionForm;
