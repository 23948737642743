import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { clientsListReducer } from './clientsList';
import { clientItemReducer } from './clientItem';
import { clientBookingsListReducer } from './clientTables/clientBookings';
import { clientBundlesListReducer } from './clientTables/clientBundles';
import { clientSubscriptionsListReducer } from './clientTables/clientSubscriptions';
import { clientReviewsListReducer } from './clientTables/clientReviews';
import { clientNotesListReducer } from './clientTables/clientNotes';

export const clientsReducer = combineReducers({
    clients: clientsListReducer,
    clientItem: clientItemReducer,
    clientBookings: clientBookingsListReducer,
    clientBundles: clientBundlesListReducer,
    clientSubscriptions: clientSubscriptionsListReducer,
    clientReviews: clientReviewsListReducer,
    clientNotes: persistReducer({
        key: 'clientNotes',
        storage,
        whitelist: ['persistCreateItem'],
    }, clientNotesListReducer),
});
