import {
    put, takeEvery,
} from 'redux-saga/effects';

// Actions
import camelize from 'camelize';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';

// Utils
import axios from '../../services/axios';

export function* getShopDetails(action) {
    const { id, onDone } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${id}/details`,
            headers: defaultHeaders,
        });

        const details = camelize(resp.data.result);

        yield put(actions.getShopDetailsSucceeded({
            id: details.id,
            name: details.name,
            workingDays: details.workingDays,
            contactNumber: details.contactNumber,
            address: {
                street: details.street,
                zip: details.zip,
                city: details.city,
                country: details.country,
                latitude: details.latitude,
                longitude: details.longitude,
            },
        }));

        onDone?.();
    } catch (err) {
        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.getShopDetailsFailed());
            yield put(actions.showToast({
                message,
                appearance: 'error',
            }));
            return;
        }

        yield put(actions.getShopDetailsFailed());
    }
}

export const shopSaga = [
    takeEvery(actionTypes.SELECT_SHOP_TRIGGER, getShopDetails),
    takeEvery(actionTypes.GET_SHOP_DETAILS_TRIGGER, getShopDetails),
];
