export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCEEDED = 'GET_EMPLOYEES_SUCCEEDED';
export const GET_EMPLOYEES_FAILED = 'GET_EMPLOYEES_FAILED';

export const SET_EMPLOYEE_FILTER = 'SET_EMPLOYEE_FILTER';

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCEEDED = 'ADD_EMPLOYEE_SUCCEEDED';
export const ADD_EMPLOYEE_FAILED = 'ADD_EMPLOYEE_FAILED';

export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const EDIT_EMPLOYEE_SUCCEEDED = 'EDIT_EMPLOYEE_SUCCEEDED';
export const EDIT_EMPLOYEE_FAILED = 'EDIT_EMPLOYEE_FAILED';

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCEEDED = 'DELETE_EMPLOYEE_SUCCEEDED';
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';
