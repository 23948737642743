import React from 'react';
import PropTypes from 'prop-types';

const Email = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.5 3L8.00032 8.6818L14.5 3"
                stroke="currentColor"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="1"
                y="2.31824"
                width="14"
                height="11.4545"
                rx="1.75"
                stroke="currentColor"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Email.propTypes = {
    className: PropTypes.string,
};

Email.defaultProps = {
    className: '',
};

export default Email;
