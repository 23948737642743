import React from 'react';

// Components
import {
    InputGroup, Form, FormCheck,

} from 'react-bootstrap';
import Select from 'react-select';
import { ChevronDown, ChevronUp } from 'react-feather';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Styles

// Utils
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { IconButton } from 'components/Common/IconButton';
import { Row } from 'components/Common/Row';
import { styles as selectStyles, theme } from '../../styles/select';
import * as styles from './GroupSchedule.module.scss';
import { Clock, Plus, Trash3Icon } from '../Icon/Icon';
import HourInput from '../HourInput/HourInput';

import * as SERVICES_SELECTORS from '../../store/selectors/services';

const GroupSchedule = ({
    className, value, services, onChange, errors, loading = false,
}) => {
    const { locale } = useSelector((state) => state.locales);
    const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const employees = useSelector((state) => state.employees.employees);
    const workingHours = useSelector((state) => state.shop?.workingDays);

    const { t } = useTranslation();

    const allServices = services || companyServices;

    return (
        <div className={classNames(styles.container, className, 'border-top border-right border-light')}>
            {_.times(7, (i) => (
                <div key={`weekday-${i}`} className={classNames(styles.column, 'd-flex flex-column')}>
                    <div className={classNames(styles.head, 'border-left border-light')}>
                        <span className="font-size-14">{moment().locale(locale).isoWeekday(i + 1).format('dddd')}</span>
                    </div>

                    <div className="flex-grow-1 border-bottom border-left border-light">
                        {(loading ? _.times(i % 2 === 0 ? 0 : 1) : value[moment().isoWeekday(i + 1).format('dddd')]).map((session, j) => (
                            <div key={`schedule-${i}-${j}`} className="border-top border-light px-3 pb-2">
                                <Form.Group className="mb-3">
                                    <Row spaceBetween stretched padding={8}>
                                        <label
                                            className="form-label font-size-15 m-0"
                                        >
                                            {t('groupSchedule.startTime')}
                                        </label>

                                        {!loading && (
                                            <IconButton
                                                data-testid="remove"
                                                color="transparent"
                                                onClick={() => onChange({
                                                    type: 'REMOVE',
                                                    day: moment().isoWeekday(i + 1).format('dddd'),
                                                    index: j,
                                                })}
                                            >
                                                <Trash3Icon />
                                            </IconButton>
                                        )}
                                    </Row>
                                    {!!session && (
                                        <InputGroup>
                                            <HourInput
                                                allowAllHours
                                                value={session.from}
                                                timespan={15}
                                                placeholder={t('groupSchedule.selectTime')}
                                                onChange={(from) => onChange({
                                                    type: 'UPDATE',
                                                    day: moment().isoWeekday(i + 1).format('dddd'),
                                                    index: j,
                                                    from,
                                                })}
                                                max={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.to}
                                                min={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.from}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text className="text-muted">
                                                    <Clock width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    )}
                                    {!session && (
                                        <div className="position-relative" style={{ top: -3, marginBottom: -4 }}>
                                            <Skeleton
                                                width="100%"
                                                height={38}
                                            />
                                        </div>
                                    )}

                                    <Form.Control.Feedback
                                        className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].from && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].from}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <label
                                        className="form-label font-size-15"
                                    >
                                        {t('groupSchedule.maxRegistrations')}
                                    </label>

                                    {!!session && (
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                data-testid="maxRegistrations"
                                                type="number"
                                                value={session.maxRegistrations}
                                                onChange={(e) => onChange({
                                                    type: 'UPDATE',
                                                    day: moment().isoWeekday(i + 1).format('dddd'),
                                                    index: j,
                                                    maxRegistrations: Number(e.target.value),
                                                })}
                                            />
                                            <InputGroup.Append className="border-left">
                                                <div className="d-flex flex-column border border-light rounded-right">
                                                    <button
                                                        data-testid="increment-maxRegistrations"
                                                        type="button"
                                                        className="d-flex justify-content-center bg-transparent border-0 p-0 text-darker-light"
                                                        style={{ width: 23, height: 19 }}
                                                        onClick={() => onChange({
                                                            type: 'UPDATE',
                                                            day: moment().isoWeekday(i + 1).format('dddd'),
                                                            index: j,
                                                            maxRegistrations: session.maxRegistrations + 1,
                                                        })}
                                                    >
                                                        <ChevronUp size={16} />
                                                    </button>

                                                    <button
                                                        data-testid="decrement-maxRegistrations"
                                                        type="button"
                                                        className="d-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0 text-darker-light"
                                                        style={{ width: 23, height: 19 }}
                                                        onClick={() => onChange({
                                                            type: 'UPDATE',
                                                            day: moment().isoWeekday(i + 1).format('dddd'),
                                                            index: j,
                                                            maxRegistrations: Math.max(session.maxRegistrations - 1, 0),
                                                        })}
                                                    >
                                                        <ChevronDown size={16} />
                                                    </button>
                                                </div>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    )}
                                    {!session && (
                                        <div className="position-relative" style={{ top: -3, marginBottom: -4 }}>
                                            <Skeleton
                                                width="100%"
                                                height={38}
                                            />
                                        </div>
                                    )}

                                    <Form.Control.Feedback
                                        className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].maxRegistrations && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].maxRegistrations}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="showNumberOfRegistrations">
                                    {!!session && (
                                        <FormCheck className="align-items-center">
                                            <FormCheck.Input
                                                data-testid="showNumberOfRegistrations"
                                                id={`showNumberOfRegistrations_${i}_${j}`}
                                                style={{ flex: '100%', maxWidth: 26 }}
                                                checked={session.showNumberOfRegistrations}
                                                onChange={(e) => onChange({
                                                    type: 'UPDATE',
                                                    day: moment().isoWeekday(i + 1).format('dddd'),
                                                    index: j,
                                                    showNumberOfRegistrations: e.target.checked,
                                                })}
                                            />
                                            <FormCheck.Label htmlFor={`showNumberOfRegistrations_${i}_${j}`}>
                                                {t('groupSchedule.showTheNumberOfRegistrations')}
                                            </FormCheck.Label>
                                        </FormCheck>
                                    )}
                                    {!session && (
                                        <div className="position-relative d-flex flex-row align-items-center" style={{ top: -3, marginBottom: -4 }}>
                                            <Skeleton
                                                width={26}
                                                height={26}
                                            />
                                            <div className="ml-1">
                                                <Skeleton
                                                    width={150}
                                                    height={21}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Form.Group>

                                <Form.Group>
                                    <div className="mb-2">
                                        <Form.Label className="mb-0 flex-grow-1 font-size-15">
                                            {t('groupSchedule.service')}
                                        </Form.Label>
                                    </div>

                                    {!!session && (
                                        <Select
                                            data-testid="service"
                                            styles={selectStyles}
                                            theme={theme}
                                            options={allServices.map(({ id, name }) => ({
                                                value: id,
                                                label: name,
                                            }))}
                                            value={session.service ? {
                                                value: session.service.id,
                                                label: session.service.name,
                                            } : null}
                                            onChange={(selected) => onChange({
                                                type: 'UPDATE',
                                                day: moment().isoWeekday(i + 1).format('dddd'),
                                                index: j,
                                                service: {
                                                    id: selected.value,
                                                    name: selected.label,
                                                },
                                            })}
                                            placeholder={t('placeholders.select')}
                                        />
                                    )}

                                    {!session && (
                                        <div className="position-relative" style={{ top: -3, marginBottom: -4 }}>
                                            <Skeleton
                                                width="100%"
                                                height={38}
                                            />
                                        </div>
                                    )}

                                    <Form.Control.Feedback
                                        className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].service && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].service}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <div className="mb-2">
                                        <Form.Label className="mb-0 flex-grow-1 font-size-15">
                                            {t('groupSchedule.employee')}
                                        </Form.Label>
                                    </div>

                                    {!!session && (
                                        <Select
                                            isClearable
                                            data-testid="employee"
                                            styles={selectStyles}
                                            theme={theme}
                                            options={employees ? employees.map(({ id, name }) => ({
                                                value: id,
                                                label: name,
                                            })) : []}
                                            value={session.employee?.id ? { value: session.employee.id, label: session.employee.name } : null}
                                            onChange={(selected) => onChange({
                                                type: 'UPDATE',
                                                day: moment().isoWeekday(i + 1).format('dddd'),
                                                index: j,
                                                employee: selected ? {
                                                    id: selected.value,
                                                    name: selected.label,
                                                } : null,
                                            })}
                                            placeholder={t('placeholders.select')}
                                        />
                                    )}
                                    {!session && (
                                        <div className="position-relative" style={{ top: -3, marginBottom: -4 }}>
                                            <Skeleton
                                                width="100%"
                                                height={38}
                                            />
                                        </div>
                                    )}

                                    <Form.Control.Feedback
                                        className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].employee && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].employee?.id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        ))}

                        <div className="py-4 d-flex justify-content-center border-top border-light">
                            <IconButton
                                data-testid="add"
                                type="button"
                                onClick={() => {
                                    if (loading) {
                                        return;
                                    }

                                    onChange({
                                        type: 'ADD',
                                        day: moment().isoWeekday(i + 1).format('dddd'),
                                    });
                                }}
                            >
                                <Plus />
                            </IconButton>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default GroupSchedule;
