// extracted by mini-css-extract-plugin
var _1 = "LogsOverviewInfo_container__-j8PJ";
var _2 = "LogsOverviewInfo_divider__aFQng";
var _3 = "LogsOverviewInfo_horisontal__gIRrX";
var _4 = "LogsOverviewInfo_info__m5Hxy";
var _5 = "LogsOverviewInfo_labelError__hlFBw";
var _6 = "LogsOverviewInfo_labelGreen__kXfSY";
var _7 = "LogsOverviewInfo_period__LLEDM";
var _8 = "LogsOverviewInfo_summaryNumber__uV3JB";
var _9 = "LogsOverviewInfo_summaryText__Soc5r";
var _a = "LogsOverviewInfo_title__KBl0+";
var _b = "LogsOverviewInfo_userContainer__smLN6";
export { _1 as "container", _2 as "divider", _3 as "horisontal", _4 as "info", _5 as "labelError", _6 as "labelGreen", _7 as "period", _8 as "summaryNumber", _9 as "summaryText", _a as "title", _b as "userContainer" }
