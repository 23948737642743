import { PERIOD_UNIT } from './PERIOD_UNIT';

export const SUBSCRIPTION_PROP = {
    TITLE: {
        MIN_LENGTH: 2,
        MAX_LENGTH: 250,
    },
    DESCRIPTION: {
        MIN_LENGTH: 2,
        MAX_LENGTH: 600,
    },
    PRICE: {
        MIN: 0,
        STEP: 1,
        MAX: 2000,
        TO_FIXED: 2,
        DEFAULT: 0.00,
    },
    MAX_USAGES: {
        STEP: 1,
        MAX: 1000,
        DEFAULT: 0,
    },
    CONTRACT_LENGTH: {
        MIN: 0,
        STEP: 1,
        MAX: 36,
        DEFAULT: 0,
    },
    SERVICES: {
        PERIOD: {
            ENUM: [
                PERIOD_UNIT.WEEKLY,
                PERIOD_UNIT.MONTHLY,
            ],
            DEFAULT: PERIOD_UNIT.WEEKLY,
        },
        MISSED_USAGES: {
            SELECTED: {
                DEFAULT: false,
            },
            VALUE: {
                MIN: 1,
                STEP: 1,
                MAX: 520,
                DEFAULT: 1,
            },
        },
    },
};
