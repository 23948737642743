import {
    call, put, select, take, takeEvery, takeLeading,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

// Actions
import qs from 'querystring-es3';
import moment from 'moment';
import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions';
import sentry from '../../services/sentry';

// Utils
import axios from '../../services/axios';
import {
    API_ADMIN_LOGIN_ROUTE,
    API_ADMIN_LOGOUT_ROUTE,
    API_ADMIN_REFRESH_TOKEN_ROUTE,
    API_ADMIN_RESET_PASSWORD_ROUTE,
    API_ADMIN_SET_PASSWORD_ROUTE,
} from '../../const/API_URL';

export function* signIn(action) {
    const { email, password, remember } = action.payload;

    try {
        const [locale] = (yield select((state) => state.locales.locale)).split('-');

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_LOGIN_ROUTE,
            data: qs.stringify({
                email,
                password,
                remember,
            }),
            headers: {
                locale,
            },
        });

        const {
            accessToken,
            tokenType,
            refreshToken,
            expiresIn,
            'company-api-key': companyAPIKey,
            user_role: userRole,
            employee_type: employeeType,
            resource_id: resourceId,
        } = resp.data.result;

        yield call(resolvePromiseAction, action);
        yield put(actions.signInSucceeded(
            accessToken,
            tokenType,
            refreshToken,
            moment().add(expiresIn, 'second').unix(),
            remember,
            companyAPIKey,
            userRole,
            employeeType,
            resourceId,
        ));
        yield put(actions.setPermissions(_.camelCase(userRole), employeeType));
    } catch (err) {
        if (err.response && err.response.data) {
            const { result } = err.response.data;
            yield call(rejectPromiseAction, action, result);
        } else {
            yield call(rejectPromiseAction, action);
            sentry.error(err);
        }

        yield put(actions.signInFailed());
    }
}

/* istanbul ignore next */
export function* requestPasswordReset(action) {
    try {
        const { email, historyPush } = action;
        const [locale] = (yield select((state) => state.locales.locale)).split('-');

        const {
            data: {
                result: { success },
            },
        } = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_RESET_PASSWORD_ROUTE,
            data: qs.stringify({
                email,
            }),
            headers: {
                locale,
            },
        });

        if (success) {
            yield put(actions.requestPasswordResetSucceeded());
            if (historyPush) {
                yield call(historyPush, '/reset-password-success', { email, sendDate: Date.now() });
            }
        }
    } catch (error) {
        const errorToastMessage = error.response?.data?.result?.message
            ?? error.response?.data?.result?.errors?.email
            ?? '';
        yield put(actions.showToast({
            message: errorToastMessage,
            appearance: 'error',
            isCustom: true,
        }));
        yield put(actions.requestPasswordResetFailed());
    }
}

export function* refreshToken(action) {
    try {
        const [locale] = (yield select((state) => state.locales.locale)).split('-');
        const refreshToken = yield select((state) => state.auth.refreshToken);

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_REFRESH_TOKEN_ROUTE,
            data: qs.stringify({
                refreshToken,
            }),
            headers: {
                locale,
            },
            _retry: true,
        });

        const {
            accessToken,
            tokenType,
            refreshToken: newRefreshToken,
            expiresIn,
            user_role: userRole,
            employee_type: employeeType,
        } = resp.data.result;

        yield call(resolvePromiseAction, action, {
            accessToken,
            tokenType,
            refreshToken: newRefreshToken,
            expires: moment().add(expiresIn, 'second').unix(),
            userRole,
            employeeType,
        });
    } catch (error) {
        yield put(actions.showToast({
            message: 'invalidRefreshToken',
            appearance: 'warning',
        }));

        yield put(actions.clearPermissions());
        yield call(rejectPromiseAction, action, { error });
    }
}

export function* signOut(action) {
    try {
        const { accessToken, tokenType, refreshToken } = yield select((state) => state.auth);
        const companyAPIKey = yield select((state) => state.company.apiKey);
        const [locale] = (yield select((state) => state.locales.locale)).split('-');

        yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_LOGOUT_ROUTE,
            params: { refreshToken },
            headers: {
                'X-Auth-Token': `${tokenType} ${accessToken}`,
                'company-api-key': companyAPIKey,
                locale,
            },
        });

        yield call(resolvePromiseAction, action);
        yield put(actions.clearPermissions());
        yield put(actions.signOutSucceeded());
    } catch (err) {
        if (err.response?.status === 401) {
            yield put(actions.refreshToken());
            yield take(actionTypes.REFRESH_TOKEN_RESOLVED);
            yield put(action);
            return;
        }

        yield put(actions.showToast({
            message: 'error',
            appearance: 'error',
        }));

        yield put(actions.signOutFailed());
    }
}

export function* executeFailedActions() {
    try {
        const failedActions = yield select((state) => state.auth.failedActions);
        for (const failedAction of failedActions) {
            yield put(failedAction);
        }
        yield put(actions.executeFailedActionsSucceeded());
    } catch (err) {
        sentry.error(err);
        yield put(actions.signOut());
    }
}

export function* setNewPassword(action) {
    const {
        newPassword, token, onFinish, onSuccess,
    } = action.payload;
    const [locale] = (yield select((state) => state.locales.locale)).split('-');

    try {
        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_SET_PASSWORD_ROUTE,
            data: qs.stringify({
                confirmationToken: token,
                password: newPassword,
            }),
            headers: {
                locale,
            },
        });

        const {
            accessToken,
            tokenType,
            refreshToken,
            expiresIn,
            'company-api-key': companyAPIKey,
            user_role: userRole,
            employee_type: employeeType,
            resource_id: resourceId,
        } = resp.data.result;
        const remember = false;

        yield put(actions.signInSucceeded(
            accessToken,
            tokenType,
            refreshToken,
            moment().add(expiresIn, 'second').unix(),
            remember,
            companyAPIKey,
            userRole,
            employeeType,
            resourceId,
        ));

        yield put(actions.setPermissions(_.camelCase(userRole), employeeType));
        onSuccess?.();
    } catch (err) {
        yield put(actions.showToast({
            message: 'setNewPassword.errors.linkExpired',
            appearance: 'error',
        }));
    }

    onFinish?.();
}

export const authSaga = [
    takeEvery(`${actionTypes.SIGN_IN}.TRIGGER`, signIn),
    takeEvery(actionTypes.REQUEST_PASSWORD_RESET, requestPasswordReset),
    takeLeading(actionTypes.REFRESH_TOKEN_TRIGGER, refreshToken),
    takeEvery(actions.signOut, signOut),
    takeEvery(actionTypes.EXECUTE_FAILED_ACTIONS, executeFailedActions),
    takeEvery(actionTypes.NEW_PASSWORD_SET, setNewPassword),
];
