const convertWorkBreaks = (obj) => {
    const array = [];
    for (const key in obj) {
        if (obj[key]?.length > 0) {
            obj[key].forEach((item) => {
                array.push({
                    from: item.from.format('HH:mm'),
                    to: item.to.format('HH:mm'),
                    weekday: key.toLocaleLowerCase(),
                });
            });
        }
    }

    return array;
};
export { convertWorkBreaks };
