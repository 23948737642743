import React, { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Plus } from 'react-feather';
import {
    Footer,
    SearchInput,
} from 'components';
import { Button } from 'components/Common/Button';
import IconButton from 'components/Common/IconButton/IconButton';
import SearchInput2 from 'components/Common/SearchInput2';
import { TextSubHeader } from 'components/Layout/TextSubHeader';

import { Content } from 'components/Common/Content';
import { useMobile } from 'hooks/useMobile';
import { Table } from 'components/Common/NewTable/Table';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import Container from 'components/Layout/Container/Container';
import Service from '../../../../../../components/Service/Service';
import * as styles from './page.module.scss';

import * as SERVICES_ACTIONS from '../../../../../../store/actions/services';

import * as SERVICES_SELECTORS from '../../../../../../store/selectors/services';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 10;

const Services = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        itemsCount,
        loading,
        pagesCount,
        page,
        showAll,
        search,
        items: services,
    } = useSelector(SERVICES_SELECTORS.servicesListSelector);
    const history = useHistory();
    const isMobile = useMobile();

    const showPrev = page > 1;
    const showNext = page < pagesCount;

    const handleFirstPage = useCallback(() => {
        dispatch(SERVICES_ACTIONS.setServicesPageFirst());
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(SERVICES_ACTIONS.setServicesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(SERVICES_ACTIONS.setServicesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(SERVICES_ACTIONS.setServicesPageLast());
    }, [dispatch]);

    const handleChangePage = useCallback((p) => {
        dispatch(SERVICES_ACTIONS.setServicesPage(p));
    }, [dispatch]);

    const handleShowAll = useCallback(() => handleChangePage({ page: 0 }), []);
    const handleShowPages = useCallback(() => handleChangePage({ page: 1 }), []);

    const onSearchHandler = (search) => {
        dispatch(SERVICES_ACTIONS.setServicesSearch({ search }));
    };

    const onAddServiceClick = () => {
        history.push('/services/services/add-service');
    };

    useEffect(() => {
        dispatch(SERVICES_ACTIONS.getServices());
    }, [dispatch, page, search]);

    const servicesList = useMemo(
        () => services.map((service, index) => (
            <Service
                service={service}
                key={service.id}
                t={t}
                disableUp={(!showPrev || showAll) && !index}
                disableDown={(!showNext || showAll) && index === (services.lengTableHeaderCell - 1)}
            />
        )),
        [services, page, t],
    );

    return (
        <>
            <TextSubHeader
                text={t('servicesRoute.services')}
                after={!isMobile && (
                    <SearchInput2
                        onSearch={onSearchHandler}
                    />
                )}
                rightActions={!isMobile && (
                    <Button color="yellow" onClick={onAddServiceClick}>
                        {t('servicesRoute.addService')}
                    </Button>
                )}
            />
            <Container>
                <Content loading={loading}>
                    <div className={styles.table}>
                        <Table>
                            <TableHeader>
                                <tr>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.name')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.category')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            align="right"
                                            name={t('servicesRoute.price')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.randomlySelectEmployee')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.time')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.bookingFrequency')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.bookable')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.additionalTime')}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        <ListTableHeaderCell
                                            name={t('servicesRoute.actions')}
                                        />
                                    </TableHeaderCell>
                                </tr>
                            </TableHeader>
                            <TableBody>
                                { servicesList }
                            </TableBody>
                            <ListTableFooter
                                page={page}
                                showAll={showAll}
                                itemsCount={itemsCount}
                                pagesCount={pagesCount}
                                columnsCount={COLUMNS_COUNT}
                                itemsPerPage={ITEMS_PER_PAGE}
                                onShowAll={handleShowAll}
                                onPrevPage={handlePrevPage}
                                onNextPage={handleNextPage}
                                onLastPage={handleLastPage}
                                onFirstPage={handleFirstPage}
                                onShowPages={handleShowPages}
                                onChangePage={handleChangePage}
                            />
                        </Table>
                    </div>
                </Content>
            </Container>
            <Footer>
                <div className="d-flex d-lg-none flex-row">
                    <SearchInput
                        onSearch={onSearchHandler}
                    />
                    <div className={styles.divider} />
                    <IconButton
                        color="white"
                        size={60}
                        onClick={onAddServiceClick}
                    >
                        <Plus size={28} />
                    </IconButton>
                </div>
            </Footer>
        </>
    );
};

export default Services;
