import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';

import * as styles from './InvoiceCreateCreditDialog.module.scss';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const InvoiceCreateCreditDialog = (props) => {
    const {
        isVisible,
        onClose,
        onConfirm,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const handleConfirm = useCallback(() => {
        onConfirm();
        onClose();
    }, [onClose]);

    return (
        <Dialog
            visible={isVisible}
            onClose={onClose}
            size="md"
        >
            <DialogHeader>
                <DialogTitle>
                    {t('invoiceCreateCreditDialog.title')}
                </DialogTitle>
            </DialogHeader>

            <DialogBody>
                <p className={styles.description}>
                    {t('invoiceCreateCreditDialog.description')}
                </p>
            </DialogBody>

            <DialogFooter className={styles.buttonWrapper}>
                <Button
                    color="outline"
                    stretched
                    onClick={onClose}
                >
                    {t('invoiceCreateCreditDialog.cancel')}
                </Button>

                <Button
                    stretched
                    onClick={handleConfirm}
                >
                    {t('invoiceCreateCreditDialog.confirm')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

InvoiceCreateCreditDialog.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};

InvoiceCreateCreditDialog.defaultProps = {
    isVisible: false,
    onClose: emptyFunc,
    onConfirm: emptyFunc,
};

export default InvoiceCreateCreditDialog;
