import React from 'react';

// Hooks
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { tokensSelector } from 'store/selectors/auth';
import { userSelector } from 'store/selectors/user';

// Components
import { SkeletonTheme } from 'react-loading-skeleton';
import { Container } from 'react-bootstrap';
import {
    Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { AllowRouteWrapper, AuthRoute, ToastManager } from 'components';

// Routes
import routes from 'pages';

// History
import history from 'services/history';

// Utils
import moment from 'moment-timezone';
import { intercomService } from 'services/intercomService';
import { useWorkerController } from './hooks/useWorkerController';
import * as actions from './store/actions';
import { permissionsPresetSelector } from './store/selectors/permissions';
import sentry from './services/sentry';

moment.tz.setDefault('Etc/GMT0');
moment.locale('en');

const App = () => {
    const isAppLoaded = useSelector((state) => state.app.loaded);
    const { accessToken, refreshToken } = useSelector(tokensSelector);
    const {
        email, fullName, loginDate, intercomHash,
    } = useSelector(userSelector);
    const { loaded: isCompanyLoaded, sections } = useSelector((state) => state.company);
    const preset = useSelector(permissionsPresetSelector);

    useWorkerController();

    const dispatch = useDispatch();

    // TODO all effects below can happen in something like initSaga
    useEffect(() => {
        if (!refreshToken || !!accessToken) {
            return;
        }

        dispatch(actions.refreshToken());
    }, [accessToken, refreshToken]);

    useEffect(() => {
        if (!email) {
            return;
        }

        intercomService(fullName, intercomHash, email, loginDate);
        sentry.setUser({ email });
    }, [email]);

    useEffect(() => {
        if (!isAppLoaded || !accessToken || isCompanyLoaded) {
            return;
        }

        dispatch(actions.getCompanyDetails()).catch(sentry.error);
    }, [isAppLoaded, accessToken, isCompanyLoaded]);

    const oldTokens = refreshToken && !accessToken;
    // wait for email and default tab loading
    const userNotLoaded = accessToken && !email;
    const shouldShowLoader = !isAppLoaded || oldTokens || userNotLoaded;

    if (shouldShowLoader) {
        return (
            <SkeletonTheme color="#E5EBF0" highlightColor="#D8E1EA">
                <Container className="d-flex justify-content-center align-items-center">
                    <TailSpin color="black" width={48} height={48} />
                </Container>
            </SkeletonTheme>
        );
    }

    return (
        <SkeletonTheme color="#E5EBF0" highlightColor="#D8E1EA">
            <ToastManager />
            <Router history={history}>
                <Switch>
                    {
                        routes
                            .map((route) => {
                                const {
                                    authRequired, component, exact, path, roles,
                                } = route;

                                const RouteComponent = authRequired ? AuthRoute : Route;

                                return (
                                    <AllowRouteWrapper
                                        key={path}
                                        path={path}
                                        sections={sections}
                                        permissionSections={preset?.section}
                                    >
                                        <RouteComponent
                                            roles={roles}
                                            component={component}
                                            exact={exact}
                                            path={path}
                                        />
                                    </AllowRouteWrapper>
                                );
                            })
                    }
                    <Redirect to="/sign-in" />
                </Switch>
            </Router>

        </SkeletonTheme>
    );
};

export default App;
