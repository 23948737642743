import React, {
    useRef, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker } from 'react-colorful';
import { Form } from 'react-bootstrap';
import { useClickOutside } from 'components/hooks';
import { LIGHT_COLOR, getContrastColor } from 'helpers/color/getContrastColor';
import * as styles from './ColorPicker.module.scss';

export const COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

const MAX_COLOR_LENGTH = 7;

const ColorPicker = (
    {
        color: externalColor,
        onChange,
        name,
        isInvalid,
        touched,
        errors,
    },
) => {
    const container = useRef();
    const [color, setColor] = useState(externalColor);
    const [colorText, setColorText] = useState(externalColor);
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        if (!touched) {
            setColorText(externalColor);
            if (COLOR_REGEX.test(externalColor)) {
                setColor(externalColor);
            }
        }
    }, [externalColor, touched]);

    const handleInput = useCallback((event) => {
        const newColor = event.target.value || '';
        let colorText = newColor.charAt(0) !== '#' ? `#${newColor}` : newColor;
        colorText = colorText.length <= MAX_COLOR_LENGTH ? colorText : colorText.substr(0, MAX_COLOR_LENGTH);
        setColorText(colorText);
        if (COLOR_REGEX.test(colorText)) {
            setColor(colorText);
        }
        onChange(colorText);
    }, [onChange, externalColor]);

    const handleChange = useCallback((newColor) => {
        setColorText(newColor);
        setColor(newColor);
        onChange(newColor);
    }, [onChange]);

    useClickOutside(container, () => toggle(false));

    return (
        <div className={styles.picker} ref={container}>
            {isOpen && (
                <div className={styles.popover}>
                    <HexColorPicker color={color} onChange={handleChange} className={styles.customLayout} />
                </div>
            )}
            <Form.Control
                className={styles.swatch}
                name={name}
                type="text"
                value={colorText}
                onChange={handleInput}
                isInvalid={isInvalid}
                style={{ backgroundColor: color, color: getContrastColor(color) }}
                onClick={() => toggle(true)}
            />
            {!!errors && (
                <Form.Control.Feedback type="invalid">
                    {errors}
                </Form.Control.Feedback>
            )}
        </div>
    );
};

ColorPicker.propTypes = {
    color: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    isInvalid: PropTypes.bool,
    touched: PropTypes.bool,
    errors: PropTypes.string,
};

ColorPicker.defaultProps = {
    color: LIGHT_COLOR,
    onChange: () => {},
    isInvalid: false,
};

export default ColorPicker;
