import * as PAYOUTS_TYPES from './types';

export const getPayouts = () => ({
    type: PAYOUTS_TYPES.PAYOUTS_GET,
});

export const setPayouts = ({ items, itemsCount, pagesCount }) => ({
    type: PAYOUTS_TYPES.PAYOUTS_SET,
    payload: {
        items,
        itemsCount,
        pagesCount,
    },
});

export const downloadPayout = ({ payoutId, system }) => ({
    type: PAYOUTS_TYPES.PAYOUTS_DOWNLOAD_PAYOUT,
    payload: { payoutId, system },
});

export const setPayoutsPage = ({ page }) => ({
    type: PAYOUTS_TYPES.PAYOUTS_PAGE_SET,
    payload: { page },
});

export const setPayoutsPageFirst = () => ({
    type: PAYOUTS_TYPES.PAYOUTS_PAGE_SET_FIRST,
});

export const setPayoutsPageLast = () => ({
    type: PAYOUTS_TYPES.PAYOUTS_PAGE_SET_LAST,
});

export const setPayoutsPagePrev = () => ({
    type: PAYOUTS_TYPES.PAYOUTS_PAGE_SET_PREV,
});

export const setPayoutsPageNext = () => ({
    type: PAYOUTS_TYPES.PAYOUTS_PAGE_SET_NEXT,
});

export const setPayoutsLoading = ({ loading }) => ({
    type: PAYOUTS_TYPES.PAYOUTS_LOADING_SET,
    payload: { loading },
});

export const setPayoutsSortOptions = ({ sortOptions }) => ({
    type: PAYOUTS_TYPES.PAYOUTS_SORT_OPTIONS_SET,
    payload: { sortOptions },
});

export const clearingPayouts = () => ({
    type: PAYOUTS_TYPES.PAYOUTS_CLEARING,
});

export const setPayoutSelectedId = ({ payoutId }) => ({
    type: PAYOUTS_TYPES.PAYOUT_SELECTED_ID_SET,
    payload: { payoutId },
});

export const setPayoutTransactionsType = ({ type }) => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_TYPE_SET,
    payload: { type },
});

export const getPayoutTransactions = ({ payoutId, transactionsType }) => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_GET,
    payload: {
        payoutId,
        transactionsType,
    },
});

export const setPayoutTransactions = ({ items, itemsCount, pagesCount }) => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_SET,
    payload: {
        items,
        itemsCount,
        pagesCount,
    },
});

export const setPayoutTransactionsLoading = ({ payoutTransactionsLoading }) => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_LOADING_SET,
    payload: { payoutTransactionsLoading },
});

export const setPayoutsTransactionsPage = ({ page }) => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET,
    payload: { page },
});

export const setPayoutsTransactionsPageFirst = () => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_FIRST,
});

export const setPayoutsTransactionsPageLast = () => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_LAST,
});

export const setPayoutsTransactionsPagePrev = () => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_PREV,
});

export const setPayoutsTransactionsPageNext = () => ({
    type: PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_NEXT,
});
