import React, {
    useEffect, useState, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

// Components
import classNames from 'classnames';
import useFileSelect from 'hooks/useFileSelect';

// Styles
import * as styles from './FileUpload.module.scss';
import FileCropModal from './FileCropModal';
import FilePreview from './FilePreview';

const FileUpload = ({
    className,
    label,
    defaultValue: externalValue = null,
    onChange,
    variant,
    isInvalid,
    square = false,
    loading = false,
    touched = false,
    allowedFormats = ['.jpg', '.jpeg', '.png'],
    allowedFileSize,
    disabledActions = false,
    compact = false,
    withoutEdit = false,
}) => {
    const { t } = useTranslation();

    const { addToast } = useToasts();
    const defaultValue = externalValue instanceof Blob ? URL.createObjectURL(externalValue) : externalValue;

    const handleFileSelectError = useCallback(() => {
        addToast(
            t('chooseOtherFile', {
                formats: allowedFormats.join(` ${t('separator')} `),
                size: allowedFileSize,
            }),
            { appearance: 'warning' },
        );
        handleCancel();
    }, [allowedFormats, allowedFileSize]);

    const handleFileSelect = useCallback((files) => {
        const [file] = files;
        setBlob(file);
    }, []);

    const [FileInputElement, handleSelectClick] = useFileSelect({
        multiple: false,
        sizeLimit: allowedFileSize,
        accept: allowedFormats,
        disabled: disabledActions,
        onError: handleFileSelectError,
        onSelect: handleFileSelect,
    });
    const [blob, setBlob] = useState(null);

    const handleCancel = useCallback(() => {
        setBlob(null);
    }, []);

    useEffect(() => {
        if (!touched) {
            handleCancel();
        }
    }, [touched]);

    const handleDelete = useCallback((e) => {
        e.preventDefault();

        setBlob(null);

        onChange?.(null);
    }, [onChange]);

    const handleSubmit = useCallback((blob) => {
        setBlob(null);
        onChange?.(blob);
    }, [onChange]);

    return (
        <div
            className={classNames(
                'position-relative d-flex flex-column justify-content-center align-items-center border-dashed border-darker-light rounded',
                styles.container,
                {
                    [styles.square]: square,
                    [styles.loading]: loading,
                    [styles.compact]: compact,
                },
                className,
            )}
        >
            {FileInputElement}
            <FilePreview
                label={label}
                image={blob || defaultValue}
                onAdd={handleSelectClick}
                onEdit={handleSelectClick}
                onDelete={handleDelete}
                variant={variant}
                isInvalid={isInvalid}
                loading={loading}
                disabledActions={disabledActions}
                compact={compact}
                withoutEdit={withoutEdit}
            />
            {blob && (
                <FileCropModal
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    square={square}
                    image={blob}
                />
            )}
        </div>
    );
};

export default FileUpload;
