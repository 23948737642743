import React from 'react';

const Pause = (props) => {
    const { width = '24', height = '25' } = props;

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 6.14355C8.84674 6.14355 9.41903 6.38061 9.84099 6.80256C10.2629 7.22452 10.5 7.79682 10.5 8.39355V17.3936C10.5 17.9903 10.2629 18.5626 9.84099 18.9845C9.41903 19.4065 8.84674 19.6436 8.25 19.6436C7.65326 19.6436 7.08097 19.4065 6.65901 18.9845C6.23705 18.5626 6 17.9903 6 17.3936V8.39355C6 7.79682 6.23705 7.22452 6.65901 6.80256C7.08097 6.38061 7.65326 6.14355 8.25 6.14355ZM15.75 6.14355C16.3467 6.14355 16.919 6.38061 17.341 6.80256C17.7629 7.22452 18 7.79682 18 8.39355V17.3936C18 17.9903 17.7629 18.5626 17.341 18.9845C16.919 19.4065 16.3467 19.6436 15.75 19.6436C15.1533 19.6436 14.581 19.4065 14.159 18.9845C13.7371 18.5626 13.5 17.9903 13.5 17.3936V8.39355C13.5 7.79682 13.7371 7.22452 14.159 6.80256C14.581 6.38061 15.1533 6.14355 15.75 6.14355Z" fill="currentColor" />
        </svg>
    );
};

export default Pause;
