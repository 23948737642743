const moment = require('moment-timezone');

const DEFAULT_TIMEZONES = [
    'Pacific/Midway', // (GMT-11:00)
    'Pacific/Tahiti', // (GMT-10:00)
    'Pacific/Gambier', // (GMT-09:00)
    'Pacific/Pitcairn', // (GMT-08:00)
    'America/Creston', // '(GMT-07:00)',
    'America/Costa_Rica', // '(GMT-06:00)',
    'America/Chicago', // '(GMT-05:00)',
    'America/New_York', // '(GMT-04:00)',
    'America/Araguaina', // '(GMT-03:00)',
    'America/Noronha', // '(GMT-02:00)',
    'Atlantic/Cape_Verde', // '(GMT-01:00)',
    'Africa/Abidjan', // '(GMT)',
    'Africa/Algiers', // '(GMT+01:00)',
    'Europe/Amsterdam', // '(GMT+02:00)',
    'Asia/Baghdad', // '(GMT+03:00)',
    'Asia/Yerevan', // '(GMT+04:00)',
    'Asia/Kabul', // '(GMT+04:30)',
    'Asia/Karachi', // '(GMT+05:00)',
    'Asia/Kolkata', // '(GMT+05:30)',
    'Asia/Kathmandu', // '(GMT+05:45)',
    'Asia/Almaty', // '(GMT+06:00)',
    'Asia/Bangkok', // '(GMT+07:00)',
    'Asia/Brunei', // '(GMT+08:00)',
    'Asia/Seoul', // '(GMT+09:00)',
    'Australia/Darwin', // '(GMT+09:30)',
    'Australia/Sydney', // '(GMT+10:00)',
    'Pacific/Efate', // '(GMT+11:00)',
    'Pacific/Fiji', // '(GMT+12:00)',
    'Pacific/Fakaofo', // '(GMT+13:00)',
];

const offsetToUtcString = (offset) => {
    const sign = offset >= 0 ? '+' : '-';
    const absoluteOffset = Math.abs(offset);
    const hours = Math.floor(absoluteOffset);
    const minutes = (absoluteOffset - hours) * 60;
    return `GMT${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const getTimezonesArray = (currentTimezone) => {
    // short list timezone names
    const timezones = moment.tz.names();

    const mappedZones = {};

    timezones.forEach((zone) => {
        const offset = moment.tz(zone).utcOffset() / 60;

        const offsetString = offsetToUtcString(offset);
        const isRegularZone = zone.includes('/') && DEFAULT_TIMEZONES.includes(zone);
        const isCurrentTimezone = currentTimezone === zone;

        if ((!mappedZones[offsetString] && isRegularZone) || isCurrentTimezone) {
            mappedZones[offsetString] = zone;
        }
    });

    const result = [];

    for (const [key, value] of Object.entries(mappedZones)) {
        result.push({
            label: key,
            value,
        });
    }

    return result.sort((a, b) => {
        const aOffset = parseFloat(a.label.replace('GMT', '').replace(':', '.'), 10);
        const bOffset = parseFloat(b.label.replace('GMT', '').replace(':', '.'), 10);

        return aOffset - bOffset;
    });
};
