import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

import { useTranslation } from 'react-i18next';

import { Switch } from 'components';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'addOrEdit.form.fields.buyable';

const SubscriptionBuyableToggle = (props) => {
    const {
        values,
        onToggleBuyable,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    return (
        <Row className="m-0 align-items-center flex-nowrap px-4 py-3 px-lg-0">
            <div className="px-2 px-lg-0 mr-3">
                <Switch
                    checked={values?.buyable}
                    onChange={onToggleBuyable}
                />
            </div>
            <div className="px-2 px-lg-0">
                <span className="px-0 font-weight-600 font-size-16">
                    {t(`${T_PREFIX}.label`)}
                </span>
            </div>
        </Row>
    );
};

SubscriptionBuyableToggle.propTypes = {
    values: PropTypes.object,
    onToggleBuyable: PropTypes.func,
};

SubscriptionBuyableToggle.defaultProps = {
    values: null,
    onToggleBuyable: emptyFunc,
};

export default SubscriptionBuyableToggle;
