import React from 'react';

const Info = ({ className }) => (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        className={className}
    >
        <circle
            cx="12.5001"
            cy="12"
            r="10"
            fill="currentColor"
            fillOpacity="0.15"
        />
        <path
            d="M11.5001 12C11.5001 11.4477 11.9478 11 12.5001 11C13.0524 11 13.5001 11.4477 13.5001 12V16C13.5001 16.5523 13.0524 17 12.5001 17C11.9478 17 11.5001 16.5523 11.5001 16V12Z"
            fill="currentColor"
        />
        <circle cx="12.5001" cy="8" r="1" fill="currentColor" />
    </svg>
);

export default Info;
