import { takeEvery } from 'redux-saga/effects';

// Actions
import * as actionTypes from '../actions/locales/actionTypes';

// Utils
import i18n from '../../services/i18n';

export function* changeLocale({ locale }) {
    yield i18n.changeLanguage(locale);
}

export const localesSaga = [
    takeEvery(actionTypes.CHANGE_LOCALE, changeLocale),
];
