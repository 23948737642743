import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LayoutContainer, SubHeader } from 'components';

import routes from './scenes';
import * as styles from './page.module.scss';

const Gates = () => (
    <LayoutContainer>
        <SubHeader isNavigationDataLoaded />
        <div className={styles.container}>
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <Route
                            key={path}
                            component={component}
                            exact={exact}
                            path={path}
                        />
                    ))
                }
                <Redirect to={routes[0].path} />
            </Switch>
        </div>
    </LayoutContainer>
);

export default Gates;
