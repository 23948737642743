import React from 'react';

const Copy = ({ className, height = 20, width = 21 }) => (
    <svg width={height} height={width} viewBox="0 0 21 20" fill="none" className={className}>
        <g id="Copy">
            <path id="Rectangle 5627" d="M7.16626 5.35779V5.33398C7.16626 4.22941 8.06169 3.33398 9.16626 3.33398H15.1663C16.2708 3.33398 17.1663 4.22941 17.1663 5.33398V11.334C17.1663 12.4386 16.2708 13.334 15.1663 13.334H15.1424" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M4.44403 6.66602C4.06094 6.66602 3.69354 6.8182 3.42266 7.08908C3.15177 7.35997 2.99959 7.72737 2.99959 8.11046V16.0549C2.99959 16.438 3.15177 16.8054 3.42266 17.0763C3.69354 17.3472 4.06094 17.4993 4.44403 17.4993H12.3885C12.7716 17.4993 13.139 17.3472 13.4099 17.0763C13.6807 16.8054 13.8329 16.438 13.8329 16.0549V8.11046C13.8329 7.72737 13.6807 7.35997 13.4099 7.08908C13.139 6.8182 12.7716 6.66602 12.3885 6.66602H4.44403Z" fill="currentColor" />
        </g>
    </svg>
);

export default Copy;
