import React from 'react';

// Hooks
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { InputGroup } from 'react-bootstrap';
import { Clock } from 'react-feather';

// Styles

// Utils
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { Button } from 'components/Common/Button';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import * as styles from './Schedule.module.scss';
import HourInput from '../HourInput/HourInput';

const Schedule = ({ value, onChange }) => {
    const openingHours = Object.entries(useSelector((state) => state.shop.workingDays || {})).reduce((openingHours, [day, hours]) => ({
        ...openingHours,
        [_.upperFirst(day)]: hours,
    }), {});
    const { locale } = useSelector((state) => state.locales);

    const [day, setDay] = useState(0);

    const { t } = useTranslation();

    return (
        <Column gap={16}>
            <div className={classNames(styles.container, 'border-top border-right border-light')}>
                {_.times(7, (i) => (
                    <button
                        key={`weekday-${i}`}
                        type="button"
                        className={classNames(styles.head, { [styles.selected]: day === i + 1 }, 'd-flex align-items-center border-top-0 border-right-0 border-bottom-0 border-left border-light')}
                        onClick={() => setDay(i + 1)}
                    >
                        <input
                            type="checkbox"
                            className={classNames(styles.checkbox, 'form-check-input ml-0')}
                            checked={value[moment().isoWeekday(i + 1).format('dddd')].enabled}
                            onChange={(e) => onChange({
                                type: 'UPDATE',
                                day: moment().isoWeekday(i + 1).format('dddd'),
                                enabled: e.target.checked,
                                get from() {
                                    const openHour = openingHours[this.day]?.from ?? '8:00';
                                    return moment(openHour, 'HH:mm');
                                },
                                get to() {
                                    const closeHour = openingHours[this.day]?.to ?? '22:00';
                                    return moment(closeHour, 'HH:mm');
                                },
                            })}
                        />
                        <span className="font-size-14">{moment().locale(locale).isoWeekday(i + 1).format('dddd')}</span>
                    </button>
                ))}

                {_.times(7, (i) => {
                    const day = value[moment().isoWeekday(i + 1).format('dddd')];

                    return (
                        <div
                            key={`weekday-${i}`}
                            className="d-flex justify-content-center px-4 py-2 flex-column border-top border-left border-bottom border-light"
                        >
                            <span>{day.enabled && day.from ? day.from.format('HH:mm') : '—:—'}</span>
                            <span>{day.enabled && day.to ? day.to.format('HH:mm') : '—:—'}</span>
                        </div>
                    );
                })}
            </div>

            <Button
                color="yellow"
                type="button"
                name="copyMondayToAllDays"
                onClick={() => onChange({
                    type: 'COPY_MONDAY_TO_ALL_DAYS',
                })}
                size="small"
            >
                {t('schedule.copyMondayToAllDays')}
            </Button>

            {!!day && value[moment().isoWeekday(day).format('dddd')].enabled && (
                <Row gap={16} stretched>
                    <Column stretched>
                        <label className="form-label">{t('schedule.from')}</label>
                        <InputGroup className="mb-3">
                            <HourInput
                                allowAllHours
                                data-testid="hourInputFrom"
                                placeholder={t('blockModal.selectTime')}
                                value={value[moment().isoWeekday(day).format('dddd')].from}
                                min={openingHours[moment().isoWeekday(day).format('dddd')].from}
                                max={openingHours[moment().isoWeekday(day).format('dddd')].to}
                                onChange={(from) => {
                                    onChange({
                                        type: 'UPDATE',
                                        day: moment().isoWeekday(day).format('dddd'),
                                        from,
                                    });
                                }}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text className="text-muted">
                                    <Clock size={18} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Column>

                    <Column stretched>
                        <label className="form-label">{t('schedule.to')}</label>
                        <InputGroup className="mb-3">
                            <HourInput
                                allowAllHours
                                data-testid="hourInputTo"
                                placeholder={t('blockModal.selectTime')}
                                value={value[moment().isoWeekday(day).format('dddd')].to}
                                min={openingHours[moment().isoWeekday(day).format('dddd')].from}
                                max={openingHours[moment().isoWeekday(day).format('dddd')].to}
                                onChange={(to) => onChange({
                                    type: 'UPDATE',
                                    day: moment().isoWeekday(day).format('dddd'),
                                    to,
                                })}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text className="text-muted">
                                    <Clock size={18} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Column>
                </Row>
            )}
        </Column>
    );
};

export default Schedule;
