import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { debounce } from 'lodash';
import * as styles from './IconButton.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    white: styles.white,
    outline: styles.outline,
    red: styles.red,
    transparent: styles.transparent,
};

const sizeClasses = {
    24: styles.size24,
    32: styles.size32,
    36: styles.size36,
    42: styles.size42,
    40: styles.size40,
    60: styles.size60,
};

const IconButton = forwardRef((props, ref) => {
    if (props.href) {
        return (
            <a
                className={
                    classNames(
                        styles.button,
                        styles.link,
                        colorClasses[props.color],
                        sizeClasses[props.size],
                        props.disabled ? styles.disabled : '',
                        props.stretched ? styles.stretched : '',
                        props.group ? styles.group : '',
                    )
                }
                ref={ref}
                data-testid={props['data-testid']}
                href={props.href}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
            >
                {props.children}
            </a>
        );
    }

    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    return (
        <button
            ref={ref}
            onClick={debouncedOnClick}
            disabled={props.disabled}
            type={props.type}
            data-testid={props['data-testid']}
            className={
                classNames(
                    styles.button,
                    colorClasses[props.color],
                    sizeClasses[props.size],
                    props.disabled ? styles.disabled : '',
                    props.stretched ? styles.stretched : '',
                    props.group ? styles.group : '',
                )
            }
        >
            {props.children}
        </button>
    );
});

IconButton.propTypes = {
    color: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    group: PropTypes.bool,
    href: PropTypes.string,
    size: PropTypes.number,
    disabled: PropTypes.bool,
    stretched: PropTypes.bool,
    type: PropTypes.string,
    'data-testid': PropTypes.string,
};

IconButton.defaultProps = {
    type: 'button',
    color: 'yellow',
    size: 32,
    stretched: false,
    'data-testid': 'data-test-icon-button',
};

export default IconButton;
