// extracted by mini-css-extract-plugin
var _1 = "BundleDetailsDialog_bundle__oIzlM";
var _2 = "BundleDetailsDialog_currencyIcon__Tpqfx";
var _3 = "BundleDetailsDialog_description__1cOK1";
var _4 = "BundleDetailsDialog_field__fCQzo";
var _5 = "BundleDetailsDialog_row__bPhjS";
var _6 = "BundleDetailsDialog_services__K2TEP";
var _7 = "BundleDetailsDialog_truncate__EgFis";
var _8 = "BundleDetailsDialog_value__lup-S";
export { _1 as "bundle", _2 as "currencyIcon", _3 as "description", _4 as "field", _5 as "row", _6 as "services", _7 as "truncate", _8 as "value" }
