import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { isAuthedSelector, roleSelector } from 'store/selectors/auth';

function AuthRoute(props) {
    const {
        component: Component, path, exact = false, roles,
    } = props;
    const isAuthed = useSelector(isAuthedSelector);
    const { role } = useSelector(roleSelector);

    return (
        <Route
            exact={exact}
            path={path}
            render={(props) => {
                if (!isAuthed) {
                    return <Redirect to="/sign-in" />;
                }

                if (roles.includes('all') || roles.includes(role)) {
                    return <Component {...props} />;
                }

                return <Redirect to="/agenda" />;
            }}
        />
    );
}

export default AuthRoute;
