import { camelCase } from 'lodash';

const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => camelizeKeys(v));
    } if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelizeKeys(obj[key]),
            }),
            {},
        );
    }
    return obj;
};

export const safeJsonParse = (jsonString, props = { camelize: true }) => {
    try {
        const json = JSON.parse(jsonString);
        if (props.camelize) {
            return camelizeKeys(json);
        }
        return json;
    } catch (e) {
        return null;
    }
};
