import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';

import * as SERVICES_TYPES from '../../actions/services/types';

import * as SERVICES_ACTIONS from '../../actions/services';
import * as TOAST_ACTIONS from '../../actions/toast';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { API_ADMIN_COMPANY_SERVICES_ROUTE } from '../../../const/API_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';

function* getCompanyServices(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(SERVICES_ACTIONS.setCompanyServicesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_SERVICES_ROUTE,
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const companyServices = result.map((item) => {
                const service = camelize(item);
                return {
                    id: service.id,
                    name: service.name,
                    description: service.description,
                    length: service.minutes,
                    price: service.price,
                    category: service.category,
                    bookableType: service.bookableType,
                    subOptions: service.subOptions || [],
                    restrictUnpaidBookings: service.restrictUnpaidBookings,
                };
            });
            yield put(SERVICES_ACTIONS.setCompanyServices({ companyServices }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'companyServices.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(SERVICES_ACTIONS.setCompanyServicesLoading({ loading: false }));
    }
}

export const companyServicesSaga = [
    takeLatest(SERVICES_TYPES.COMPANY_SERVICES_GET, getCompanyServices),
];
