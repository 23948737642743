export const SOLD_BUNDLE_PROP = {
    ENDS_AT: {
        VALUE: {
            DEFAULT: Date.now(),
        },
        UNLIMITED: {
            DEFAULT: false,
        },
    },
    PURCHASED_AT: {
        DEFAULT: Date.now(),
    },
    MAX_USAGES: {
        VALUE: {
            MIN: 1,
            MAX: 1000,
            STEP: 1,
            DEFAULT: 1,
        },
        UNLIMITED: {
            DEFAULT: false,
        },
    },
};
