import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { IconButton } from 'components/Common/IconButton';
import { Close, Search } from '../Icon/Icon';

import * as styles from './SearchInput.module.scss';

function SearchInput(props) {
    const { searchValue, onSearch } = props;
    const [input, setInput] = useState(searchValue);
    const { t } = useTranslation();

    useEffect(() => {
        setInput(() => searchValue);
    }, [searchValue]);

    const handleSearch = useCallback((e) => {
        e.preventDefault();
        onSearch(input);
    }, [onSearch, input]);

    const handleClear = useCallback((e) => {
        e.preventDefault();
        setInput('');
        onSearch('');
    }, [onSearch, setInput]);

    return (
        <Form
            className="flex-grow-1 flex-sm-grow-1 flex-md-grow-1"
            onSubmit={handleSearch}
        >
            <div className="w-100 h-100 position-relative">
                <div className="position-relative col-lg-8 px-0">
                    <span
                        className="position-absolute d-none d-lg-flex align-items-center text-darker-light"
                        style={{ left: '15px', top: '10px', zIndex: 1 }}
                    >
                        <Search height="20px" width="20px" />
                    </span>

                    <Form.Control
                        type="text"
                        placeholder={t('placeholders.search')}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        className={classNames(`${styles.capitalizeMobilePlaceholder} pl-lg-5`, {
                            'pr-lg-4': input,
                        })}
                    />

                    {input && (
                        <div className={styles.clear}>
                            <IconButton
                                color="transparent"
                                size={36}
                                onClick={handleClear}
                            >
                                <Close />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </Form>
    );
}

SearchInput.defaultProps = {
    searchValue: '',
};

export default SearchInput;
