import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDialog } from 'hooks/useDialog';

import Footer from 'components/Layout/Footer/Footer';
import { FilterIcon } from 'components/Icon/Icon';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Button } from 'components/Common/Button';

import IconButton from 'components/Common/IconButton/IconButton';
import * as styles from './ClientNotesMobileFooter.module.scss';
import { ClientNotesMobileFiltersDialog } from '../ClientNotesMobileFiltersDialog';
import ClientNoteFormDialog from '../ClientNoteFormDialog/ClientNoteFormDialog';

const T_PREFIX = 'notes';

const ClientNotesMobileFooter = (props) => {
    const {
        categories, selectedCategoriesIds, onSelect, saving,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const invoicesMobileFiltersDialog = useDialog(false);
    const editDialog = useDialog(false);

    return (
        <Footer className={styles.mobileButtonsContainer}>
            <div className="d-flex flex-row align-items-center">
                <div className={styles.submit}>
                    <Button
                        color="yellow"
                        stretched
                        onClick={editDialog.onShow}
                    >
                        {t(`${T_PREFIX}.add.label`)}
                    </Button>
                </div>
                <div className={styles.footerDivider} />

                <IconButton
                    color="white"
                    size={60}
                    onClick={invoicesMobileFiltersDialog.onShow}
                >
                    {!!selectedCategoriesIds.length && <div className={styles.yellowDot} />}
                    <FilterIcon />
                </IconButton>
            </div>

            {invoicesMobileFiltersDialog.visible && (
                <ClientNotesMobileFiltersDialog
                    categories={categories}
                    onSelect={onSelect}
                    selectedCategoriesIds={selectedCategoriesIds}
                    visible={invoicesMobileFiltersDialog.visible}
                    onClose={invoicesMobileFiltersDialog.onClose}
                />
            )}
            {editDialog.visible && (
                <ClientNoteFormDialog
                    categories={categories}
                    visible={editDialog.visible}
                    onClose={editDialog.onClose}
                    saving={saving}
                />
            )}
        </Footer>
    );
};

ClientNotesMobileFooter.propTypes = {
    categories: PropTypes.array,
    selectedCategoriesIds: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    saving: PropTypes.bool,
};

ClientNotesMobileFooter.defaultProps = {
    categories: [],
    selectedCategoriesIds: [],
    saving: false,
};

export default ClientNotesMobileFooter;
