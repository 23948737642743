import * as TYPES from '../../actions/bundles/actionTypes';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    hideArchived: true,
};

export const bundlesListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (TYPES.BUNDLES_SET): {
        const { bundles, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: bundles,
            page,
            itemsCount,
            pagesCount,
        };
    }
    case (TYPES.BUNDLES_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (TYPES.BUNDLES_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (TYPES.BUNDLES_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (TYPES.BUNDLES_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (TYPES.BUNDLES_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (TYPES.BUNDLES_SEARCH_SET): {
        const { search = '' } = payload;
        return {
            ...state,
            search: search.trim(),
            page: state.page && 1,
        };
    }
    case (TYPES.BUNDLES_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    case (TYPES.BUNDLES_HIDE_ARCHIVED_TOGGLE): {
        return {
            ...state,
            hideArchived: !state.hideArchived,
        };
    }
    default:
        return state;
    }
};
