export const T_WEEK_DAYS_MAP = {
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday',
    maandag: 'monday',
    dinsdag: 'tuesday',
    woensdag: 'wednesday',
    donderdag: 'thursday',
    vrijdag: 'friday',
    zaterdag: 'saturday',
    zondag: 'sunday',
    montag: 'monday',
    dienstag: 'tuesday',
    mittwoch: 'wednesday',
    donnerstag: 'thursday',
    freitag: 'friday',
    samstag: 'saturday',
    sonntag: 'sunday',
    pirmadienis: 'monday',
    antradienis: 'tuesday',
    trečiadienis: 'wednesday',
    ketvirtadienis: 'thursday',
    penktadienis: 'friday',
    šeštadienis: 'saturday',
    sekmadienis: 'sunday',
};
