import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { SubNavigation } from 'components';
import { useSubRoute } from 'components/hooks';
import NavigationService from 'services/navigationService';
import { THIRD_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import routes from './scenes';
import * as styles from './page.module.scss';

const subNavigationVisibleRoutes = ['list', 'sold'];

function Subscriptions() {
    const subNavigation = NavigationService.getSubscriptionsSubNavigation();
    const isNavigationDataLoaded = true;
    const [currentSubRoute] = useSubRoute();

    return (
        <>
            {
                subNavigationVisibleRoutes.includes(currentSubRoute) && (
                    <Row noGutters className={styles.withBorderBottom} style={{ height: THIRD_LEVEL_NAV_HEIGHT }}>
                        <SubNavigation
                            isDataLoaded={isNavigationDataLoaded}
                            subRoutes={subNavigation}
                            withQuery={false}
                        />
                    </Row>
                )
            }
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <Route
                            key={path}
                            component={component}
                            exact={exact}
                            path={path}
                        />
                    ))
                }

                <Redirect to={routes[0].path} />
            </Switch>
        </>
    );
}

export default Subscriptions;
