import React, {
    useMemo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { TailSpin } from 'react-loader-spinner';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

import { Button } from 'components/Common/Button';
import { useBool } from '../../../../hooks/useBool';
import { getError, isInvalid } from '../../../../services/validationService';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';
import { generateRandomString } from '../../../../helpers/string/generateRandomString';

import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { CheckBox } from '../../../Common/CheckBox';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';
import { FormInput } from '../../../Common/FormInput';
import DateInput from '../../../DateInput/DateInput';

import { Calendar2 } from '../../../Icon/Icon';

import * as styles from './InvoicesExportDialog.module.scss';

import * as INVOICE_ACTIONS from '../../../../store/actions/financials/invoices';
import { invoicesListSelector } from '../../../../store/selectors/financilas';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const PERIODS_MAP = {
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
    custom: 'custom',
};

const EXPORT_DATA_TRANSFORMER = {
    send: ({ values }) => ({
        to: values.dateTo.unix(),
        from: values.dateFrom.unix(),
        includePDF: values.exportPDF,
        email: values.email,
    }),
};

const GET_INVOICES_DATA_TRANSFORMER = {
    send: ({ values }) => ({
        to: values.dateTo.unix(),
        from: values.dateFrom.unix(),
    }),
};

const initialValues = {
    period: PERIODS_MAP.day,
    dateFrom: moment().utc().startOf('day'),
    dateTo: moment().utc().startOf('day'),
    exportPDF: false,
    email: '',
};

const T_PREFIX = 'export.dialog';

const InvoicesExportDialog = (props) => {
    const {
        isVisible,
        onClose,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const {
        exportEmail,
        exportPeriod,
        filters,
        isExportInProgress,
    } = useSelector(invoicesListSelector);
    const [withCurrentPeriodOffset, setWithCurrentPeriodOffset] = useState(false);

    const hasErrors = useBool(false);
    const dispatch = useDispatch();

    const validationSchema = useMemo(() => yup.object({
        dateFrom: yup
            .mixed()
            .required(t(`${T_PREFIX}.body.inputErrors.required`)),
        dateTo: yup
            .mixed()
            .required(t(`${T_PREFIX}.body.inputErrors.required`))
            .when('dateFrom', (dateFrom, schema) => schema.test({
                name: generateRandomString(),
                test: (dateTo) => dateTo.isSameOrAfter(dateFrom),
                message: t(`${T_PREFIX}.body.inputErrors.endAfterStart`),
            })),
        email: yup
            .string()
            .required(t(`${T_PREFIX}.body.inputErrors.required`))
            .email(t(`${T_PREFIX}.body.inputErrors.email`)),
    }), [t]);

    const handleSuccess = useCallback(({ values }) => () => {
        const exportInvoicesData = EXPORT_DATA_TRANSFORMER.send({ values });

        dispatch(INVOICE_ACTIONS.exportInvoices({
            exportInvoicesData,
            onSuccess: onClose,
        }));
        onClose();
    }, [dispatch, onClose]);

    const handleFormSubmit = useCallback((values) => {
        const invoicesAmountData = GET_INVOICES_DATA_TRANSFORMER.send({ values });

        dispatch(INVOICE_ACTIONS.getInvoicesAmount({
            invoicesAmountData,
            onSuccess: handleSuccess({ values }),
        }));
    }, [dispatch, handleSuccess]);

    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleFormSubmit,
    });

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const invalid = isInvalid(k, errors, touched);
            if (invalid) {
                hasErrors.onTrue();
            }
            const subValidation = {
                isInvalid: invalid,
                error: getError(k, errors),
            };
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [
        values,
        errors,
        touched,
        hasErrors.onFalse,
        hasErrors.onTrue,
    ]);

    const handleSetPeriod = useCallback(({ period }) => () => {
        setFieldValue('period', period);

        if (period === PERIODS_MAP.custom) {
            return;
        }

        if (withCurrentPeriodOffset) {
            setFieldValue('dateTo', values.dateTo.clone().endOf('day'));
            setFieldValue('dateFrom', values.dateTo.clone().endOf('day').subtract(1, period).add(1, 's'));
        } else {
            setFieldValue('dateFrom', moment().startOf(period));
            setFieldValue('dateTo', moment().endOf(period));
        }
    }, [values.dateFrom, values.period, withCurrentPeriodOffset]);

    const handleStartDateChange = useCallback((dateFrom) => {
        setFieldValue('dateFrom', dateFrom.clone().startOf('day'));
        setFieldTouched('dateFrom', true);
        setFieldTouched('dateTo', true);

        if (values.period !== PERIODS_MAP.custom) {
            setFieldValue('dateTo', dateFrom.clone().startOf('day').add(1, values.period).subtract(1, 's'));
            setFieldTouched('dateTo', true);
        }

        if (!dateFrom.isSame(values.dateFrom, 'day')) {
            setWithCurrentPeriodOffset(true);
        }
    }, [setFieldValue, setFieldTouched, values]);

    const handleEndDateChange = useCallback((dateTo) => {
        setFieldValue('dateTo', dateTo.clone().endOf('day'));
        setFieldTouched('dateTo', true);

        if (values.period !== PERIODS_MAP.custom) {
            setFieldValue('dateFrom', dateTo.clone().endOf('day').subtract(1, values.period).add(1, 's'));
            setFieldTouched('dateFrom', true);
        }

        if (!dateTo.isSame(values.dateTo, 'day')) {
            setWithCurrentPeriodOffset(true);
        }
    }, [setFieldValue, setFieldTouched, values]);

    const handleEmailChange = useCallback((e) => {
        setFieldValue('email', e.target.value);
    }, []);

    useEffect(() => {
        if (!isVisible) {
            return;
        }

        setFieldValue('email', exportEmail);
    }, [isVisible]);

    useEffect(() => {
        setFieldValue('dateFrom', moment.unix(filters.from));
        setFieldValue('dateTo', moment.unix(filters.to));
        setFieldValue('period', exportPeriod);
        setWithCurrentPeriodOffset(!(
            moment().startOf(exportPeriod).isSame(moment.unix(filters.from), 'day')
            && moment().endOf(exportPeriod).isSame(moment.unix(filters.to), 'day')
        ));
    }, [filters.from, filters.to, exportPeriod]);

    return (
        <Dialog
            visible={isVisible}
            onClose={onClose}
            size="md"
            className={styles.dialog}
        >
            <DialogHeader>
                <DialogTitle>
                    {t(`${T_PREFIX}.header.label`)}
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <Form
                    id="export-invoices"
                    onSubmit={handleSubmit}
                >
                    <Container>
                        <Row>
                            <Form.Group
                                as={Col}
                                xs={12}
                                className={classNames(
                                    styles.formGroup,
                                    styles.btnsGroupForm,
                                )}
                            >
                                <Form.Label>
                                    {t(`${T_PREFIX}.body.dateInput.period`)}
                                </Form.Label>
                                <Row className="m-0 p-0">
                                    <Button
                                        size="small"
                                        group
                                        color={values.period === PERIODS_MAP.day ? 'yellow' : 'outline'}
                                        onClick={handleSetPeriod({ period: PERIODS_MAP.day })}
                                    >
                                        {t(`${T_PREFIX}.body.periodBtns.day`)}
                                    </Button>
                                    <Button
                                        size="small"
                                        group
                                        color={values.period === PERIODS_MAP.week ? 'yellow' : 'outline'}
                                        onClick={handleSetPeriod({ period: PERIODS_MAP.week })}
                                    >
                                        {t(`${T_PREFIX}.body.periodBtns.week`)}
                                    </Button>
                                    <Button
                                        size="small"
                                        group
                                        color={values.period === PERIODS_MAP.month ? 'yellow' : 'outline'}
                                        onClick={handleSetPeriod({ period: PERIODS_MAP.month })}
                                    >
                                        {t(`${T_PREFIX}.body.periodBtns.month`)}
                                    </Button>
                                    <Button
                                        size="small"
                                        group
                                        color={values.period === PERIODS_MAP.year ? 'yellow' : 'outline'}
                                        onClick={handleSetPeriod({ period: PERIODS_MAP.year })}
                                    >
                                        {t(`${T_PREFIX}.body.periodBtns.year`)}
                                    </Button>
                                    <Button
                                        size="small"
                                        group
                                        color={values.period === PERIODS_MAP.custom ? 'yellow' : 'outline'}
                                        onClick={handleSetPeriod({ period: PERIODS_MAP.custom })}
                                    >
                                        {t(`${T_PREFIX}.body.periodBtns.custom`)}
                                    </Button>
                                </Row>
                            </Form.Group>
                        </Row>
                        <Row>
                            {values.period === PERIODS_MAP.day && (
                                <Form.Group
                                    as={Col}
                                    xs={12}
                                    className={styles.dateInputForm}
                                >
                                    <Form.Label>
                                        {t(`${T_PREFIX}.body.dateInput.date`)}
                                    </Form.Label>
                                    <InputGroup>
                                        <DateInput
                                            className={styles.dateInput}
                                            placeholder={t(`${T_PREFIX}.body.dateInput.selectDate`)}
                                            value={values.dateFrom}
                                            onChange={handleStartDateChange}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className={styles.calendar}>
                                                <Calendar2 />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            )}
                            {[
                                PERIODS_MAP.week,
                                PERIODS_MAP.month,
                                PERIODS_MAP.year,
                                PERIODS_MAP.custom].includes(values.period) && (
                                <>
                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        lg={6}
                                        className={styles.dateInputForm}
                                    >
                                        <Form.Label>
                                            {t(`${T_PREFIX}.body.dateInput.dateFrom`)}
                                        </Form.Label>
                                        <InputGroup>
                                            <DateInput
                                                className={styles.dateInput}
                                                placeholder={t(`${T_PREFIX}.body.dateInput.selectStartDate`)}
                                                value={values.dateFrom}
                                                onChange={handleStartDateChange}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text className={styles.calendar}>
                                                    <Calendar2 />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className={classNames({
                                                'd-block': validation?.dateTo?.isInvalid
                                                && values.period === PERIODS_MAP.custom,
                                            })}
                                        >
                                            {t(`${T_PREFIX}.body.inputErrors.startBeforeEnd`)}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        lg={6}
                                        className={styles.dateInputForm}
                                    >
                                        <Form.Label>
                                            {t(`${T_PREFIX}.body.dateInput.dateTo`)}
                                        </Form.Label>
                                        <InputGroup>
                                            <DateInput
                                                className={styles.dateInput}
                                                placeholder={t(`${T_PREFIX}.body.dateInput.selectEndDate`)}
                                                value={values.dateTo}
                                                onChange={handleEndDateChange}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text className={styles.calendar}>
                                                    <Calendar2 />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className={classNames({
                                                'd-block': validation?.dateTo?.isInvalid
                                                && values.period === PERIODS_MAP.custom,
                                            })}
                                        >
                                            {validation?.dateTo?.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )}
                        </Row>
                        <Row>
                            <Form.Group
                                as={Col}
                                xs={12}
                                className={styles.dateInputForm}
                            >
                                <Form.Label>
                                    {t(`${T_PREFIX}.body.dateInput.email`)}
                                </Form.Label>
                                <FormInput
                                    id="email"
                                    name="email"
                                    value={values.email}
                                    isInvalid={isInvalid('email', errors, touched)}
                                    onChange={handleEmailChange}
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={classNames({
                                        'd-block': validation?.email?.isInvalid,
                                    })}
                                >
                                    {validation?.email?.error}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group
                                as={Col}
                                xs={12}
                                className={classNames(
                                    styles.formGroup,
                                    styles.horizontal,
                                )}
                            >
                                <CheckBox
                                    id="exportPDF"
                                    name="exportPDF"
                                    checked={values.exportPDF}
                                    onChange={handleChange}
                                    className={styles.checkBox}
                                />
                                <Form.Label
                                    htmlFor="exportPDF"
                                    className={styles.checkBoxLabel}
                                >
                                    {t(`${T_PREFIX}.body.exportPDF`)}
                                </Form.Label>
                            </Form.Group>
                        </Row>
                    </Container>
                </Form>
            </DialogBody>
            <DialogFooter className={styles.dialogFooter}>
                <Button
                    color="outline"
                    className={styles.cancelButton}
                    onClick={onClose}
                >
                    {t(`${T_PREFIX}.footer.cancel`)}
                </Button>
                <Button
                    type="submit"
                    form="export-invoices"
                    disabled={isExportInProgress}
                >
                    {isExportInProgress
                        ? (
                            <TailSpin
                                color="white"
                                width={18}
                                height={18}
                            />
                        )
                        : t(`${T_PREFIX}.footer.export`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

InvoicesExportDialog.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

InvoicesExportDialog.defaultProps = {
    isVisible: false,
    onClose: emptyFunc,
};

export default InvoicesExportDialog;
