import React from 'react';
import PropTypes from 'prop-types';

const Phone2 = (props) => {
    const { className } = props;

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1905 10.9048L10.9048 13.1905L4.80952 7.09524L7.09524 4.80952L3.28571 1L1 3.28571C1 10.8598 7.14019 17 14.7143 17L17 14.7143L13.1905 10.9048Z"
                stroke="#B3BDC8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Phone2.propTypes = {
    className: PropTypes.string,
};

Phone2.defaultProps = {
    className: '',
};

export default Phone2;
