import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import axios from 'services/axios';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as CLIENTS_ACTIONS from '../../../actions/clients';
import * as CLIENTS_TYPES from '../../../actions/clients/types';
import * as CLIENTS_SELECTORS from '../../../selectors/clients';

import * as TOAST_ACTIONS from '../../../actions/toast';

import { HTTP_METHOD } from '../../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../../const/http/HTTP_STATUS_CODE';
import { API_ADMIN_CLIENT_BOOKINGS_ID_ROUTE } from '../../../../const/API_URL';

function* getClientBookings(action) {
    const { payload } = action;
    const { clientId } = payload;

    const {
        page,
        monthFilter,
        yearFilter,
        periodFilter,
    } = yield select(CLIENTS_SELECTORS.clientBookingsSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(CLIENTS_ACTIONS.setClientBookingsLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_BOOKINGS_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            params: {
                page,
                period: `${monthFilter}-${yearFilter}`,
                periodType: periodFilter,
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
                active_bookings,
            } = result;
            const bookings = items.map((item) => {
                const booking = camelize(item);
                return {
                    id: booking.id,
                    shop: {
                        name: booking.shop.name,
                    },
                    service: {
                        name: booking.service.name,
                    },
                    payment: {
                        amount: booking.payment.amount,
                        date: booking.payment.date,
                        profit: booking.payment.profit,
                        status: booking.payment.status,
                        transfered: booking.payment.transfered,
                        type: booking.payment.type,
                        transactionId: booking.transactionId,
                    },
                    cancellationDate: booking.cancellationDate,
                    status: booking.status,
                    birthdayDiscount: booking.birthdayDiscount,
                    loyaltyDiscount: booking.loyaltyDiscount,
                    permanentDiscount: booking.permanentDiscount,
                    dateTime: booking.datetime,
                    isRecurring: booking.isRecurring,
                };
            });
            yield put(CLIENTS_ACTIONS.setClientBookings({
                bookings,
                itemsCount,
                pagesCount,
                active_bookings,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.getBookings.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(CLIENTS_ACTIONS.setClientBookingsLoading({ loading: false }));
    }
}

export const clientBookingsSaga = [
    takeLatest(CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_GET, getClientBookings),
];
