import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './ListTableBodyRow.module.scss';

const ListTableBodyRow = (props) => {
    const {
        children,
        className,
        onClick,
    } = props;

    return (
        <tr className={classNames(styles.bodyRow, Boolean(onClick) && styles.interactive, className)} onClick={onClick || undefined} data-testid={props['data-testid']}>
            {children}
        </tr>
    );
};

ListTableBodyRow.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    'data-testid': PropTypes.string,
};

ListTableBodyRow.defaultProps = {
    children: null,
    className: '',
    'data-testid': 'data-test-list-table-body-row',
};

export default ListTableBodyRow;
