import React from 'react';
import PropTypes from 'prop-types';

import { SoldSubscriptionBookingsListItem } from '../SoldSubscriptionBookingsListItem';

import * as styles from './SoldSubscriptionBookingsList.module.scss';

const SoldSubscriptionBookingsList = (props) => {
    const { bookings } = props;

    return (
        <ul className={styles.bookingsList}>
            {bookings.map((booking) => (
                <SoldSubscriptionBookingsListItem
                    key={booking?.id}
                    booking={booking}
                />
            ))}
        </ul>
    );
};

SoldSubscriptionBookingsList.propTypes = {
    bookings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.number,
            isRecurring: PropTypes.bool,
            objectName: PropTypes.string,
        }),
    ),
};

SoldSubscriptionBookingsList.defaultProps = {
    bookings: [],
};

export default SoldSubscriptionBookingsList;
