import React from 'react';
import PropTypes from 'prop-types';

const Note = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.149902 4.00002C0.149902 2.42601 1.42589 1.15002 2.9999 1.15002H12.9999C14.5739 1.15002 15.8499 2.42601 15.8499 4.00002V12C15.8499 13.574 14.5739 14.85 12.9999 14.85H2.9999C1.42589 14.85 0.149902 13.574 0.149902 12V4.00002ZM2.9999 2.85002C2.36478 2.85002 1.8499 3.3649 1.8499 4.00002V12C1.8499 12.6352 2.36477 13.15 2.9999 13.15H12.9999C13.635 13.15 14.1499 12.6352 14.1499 12V4.00002C14.1499 3.3649 13.635 2.85002 12.9999 2.85002H2.9999ZM3.9999 5.50002C3.9999 5.22388 4.22376 5.00002 4.4999 5.00002H11.4999C11.776 5.00002 11.9999 5.22388 11.9999 5.50002V6.20002C11.9999 6.47617 11.776 6.70002 11.4999 6.70002H4.4999C4.22376 6.70002 3.9999 6.47617 3.9999 6.20002V5.50002ZM4.4999 8.00002C4.22376 8.00002 3.9999 8.22388 3.9999 8.50002V9.20002C3.9999 9.47617 4.22376 9.70002 4.4999 9.70002H9.4999C9.77604 9.70002 9.9999 9.47617 9.9999 9.20002V8.50002C9.9999 8.22388 9.77604 8.00002 9.4999 8.00002H4.4999Z"
                fill="currentColor"
            />
        </svg>
    );
};

Note.propTypes = {
    className: PropTypes.string,
};

Note.defaultProps = {
    className: '',
};

export default Note;
