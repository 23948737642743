import React, { useRef } from 'react';

// Components
import {
    Form, InputGroup, Modal,
} from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';

// Hooks
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

// Utils
import classNames from 'classnames';
import * as yup from 'yup';
import { Button } from 'components/Common/Button';
import * as actions from '../../store/actions';

const OverrideGroupModal = ({
    show, onHide, id, timestamp, registrations,
}) => {
    const { t } = useTranslation();

    const schema = useRef(
        yup.object({
            maxPeople: yup
                .number()
                .positive(
                    t('validationErrors.mustBePositiveNumber', {
                        name: t('addEventRoute.maxRegistrations'),
                    }),
                )
                .required(
                    t('validationErrors.cannotBeEmpty', {
                        name: t('addEventRoute.maxRegistrations'),
                    }),
                ),
        }),
    ).current;

    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        isSubmitting,
        touched,
        errors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: async ({ maxPeople }, { setSubmitting, setErrors }) => {
            try {
                if (registrations === maxPeople) return onHide();
                await dispatch(
                    actions.overrideGroupSession({ id, timestamp, maxPeople }),
                );
                addToast(t('groupDetailsModal.overrideGroupSessionSuccess'), {
                    appearance: 'success',
                });
                onHide();
            } catch ({ message, errors }) {
                if (message) {
                    addToast(message, {
                        appearance: 'error',
                    });
                } else {
                    setErrors(errors || {});
                }

                setSubmitting(false);
            }
        },
        initialValues: {
            maxPeople: registrations ?? 0,
        },
        enableReinitialize: true,
    });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            className="d-flex flex-column "
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="mb-0 font-weight-600">
                    {t('groupDetailsModal.override')}
                    {' '}
                    {t('groupDetailsModal.groupSession')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <label className="form-label">
                        {t('addEventRoute.maxRegistrations')}
                    </label>
                    <InputGroup>
                        <Form.Control
                            name="maxPeople"
                            type="number"
                            value={values.maxPeople}
                            onChange={handleChange}
                            isInvalid={touched.maxPeople && !!errors.maxPeople}
                        />
                        <InputGroup.Append className="border-left">
                            <div
                                className={classNames(
                                    {
                                        'border-light': !touched.maxPeople || !errors.maxPeople,
                                        'border-danger': touched.maxPeople && !!errors.maxPeople,
                                    },
                                    'd-flex flex-column border rounded-right',
                                )}
                            >
                                <button
                                    type="button"
                                    name="maxPeople.increment"
                                    className={classNames(
                                        {
                                            'text-darker-light':
                                                !touched.maxPeople || !errors.maxPeople,
                                            'text-danger': touched.maxPeople && !!errors.maxPeople,
                                        },
                                        'd-flex justify-content-center bg-transparent border-0 p-0',
                                    )}
                                    style={{ width: 23, height: 19 }}
                                    onClick={() => setFieldValue('maxPeople', values.maxPeople + 1)}
                                >
                                    <ChevronUp size={16} />
                                </button>

                                <button
                                    type="button"
                                    name="maxPeople.decrement"
                                    className={classNames(
                                        {
                                            'text-darker-light':
                                                !touched.maxPeople || !errors.maxPeople,
                                            'text-danger': touched.maxPeople && !!errors.maxPeople,
                                        },
                                        'd-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0',
                                    )}
                                    style={{ width: 23, height: 19 }}
                                    onClick={() => setFieldValue(
                                        'maxPeople',
                                        Math.max(values.maxPeople - 1, 0),
                                    )}
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </div>
                        </InputGroup.Append>
                    </InputGroup>

                    <Form.Control.Feedback
                        className={!!touched.maxPeople && !!errors.maxPeople && 'd-block'}
                        type="invalid"
                    >
                        {errors.maxPeople}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    type="button"
                    color="outline"
                    onClick={onHide}
                >
                    {t('groupDetailsModal.cancel')}
                </Button>

                <Button
                    color="yellow"
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                >
                    {(
                        t('groupDetailsModal.save')
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OverrideGroupModal;
