import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loaded: false,
    employees: null,
    filters: null,
    bookings: null,
    bookingsLoading: false,
    blocks: null,
};

// eslint-disable-next-line import/prefer-default-export
export const employees = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.GET_EMPLOYEES:
        return {
            ...state,
            loaded: false,
        };
    case actionTypes.GET_EMPLOYEES_SUCCEEDED:
        const { employees } = payload;
        return {
            ...state,
            loaded: true,
            employees,
            filters: {
                ...employees.map(({ id }) => id).reduce((filters, id) => ({ ...filters, [id]: true }), {}),
                ...state.filters,
            },
        };

    case actionTypes.GET_BOOKINGS:
        return {
            ...state,
            bookingsLoading: true,
        };
    case actionTypes.GET_BOOKINGS_SUCCEEDED:
        const { productType, bookings } = payload;
        if (productType === 'employee') {
            return {
                ...state,
                bookingsLoading: false,
                bookings,
                blocks: [], // TODO
            };
        }
        return state;

    case actionTypes.GET_BOOKINGS_FAILED:
        return {
            ...state,
            bookingsLoading: false,
        };

    case actionTypes.SET_EMPLOYEE_FILTER:
        const { id, visible } = payload;
        return {
            ...state,
            filters: {
                ...state.filters,
                [id]: visible,
            },
        };
    case actionTypes.ADD_EMPLOYEE_SUCCEEDED:
        const { employee } = payload;
        return {
            ...state,
            employees: [
                ...state.employees,
                employee,
            ],
            filters: {
                ...state.filters,
                [employee.id]: true,
            },
        };
    case actionTypes.EDIT_EMPLOYEE_SUCCEEDED: {
        const { employee } = payload;

        if (!state.employees) {
            return state;
        }

        return {
            ...state,
            employees: state.employees.map((item) => (item.id === employee.id ? {
                ...item,
                ...employee,
            } : item)),
        };
    }
    case actionTypes.ADD_BOOKING_SUCCEEDED: {
        const { booking } = payload;
        if (booking.product.type !== 'employee') {
            return state;
        }

        return {
            ...state,
            bookings: [
                ...state.bookings,
                booking,
            ],
        };
    }
    case actionTypes.EDIT_BOOKING_SUCCEEDED: {
        if (!state.bookings) {
            return state;
        }

        const { booking } = payload;
        return {
            ...state,
            bookings: state.bookings.map((existing) => (existing.id === booking.id ? booking : existing)),
        };
    }
    case actionTypes.DELETE_BOOKING_SUCCEEDED: {
        if (!state.bookings) {
            return state;
        }

        const { booking, timestamp } = payload;

        return {
            ...state,
            bookings: state.bookings
                .filter(({ id, time: { from } }) => {
                    if (timestamp && booking.id === id) {
                        return timestamp !== from;
                    }
                    return booking.id !== id;
                }),
        };
    }
    case actionTypes.OMIT_BOOKING_SUCCEEDED: {
        if (!state.bookings) {
            return state;
        }

        const { bookingID, timestamp } = payload;

        return {
            ...state,
            bookings: state.bookings.map((item) => (item.id === bookingID && timestamp === item.time.from
                ? { ...item, notPresent: true }
                : item)),
        };
    }
    case actionTypes.CHANGE_PAY_STATUS_BOOKING_SUCCEEDED: {
        if (!state.bookings) {
            return state;
        }

        const { bookingID, timestamp, paymentStatus } = payload;

        return {
            ...state,
            bookings: state.bookings.map((item) => (item.id === bookingID && timestamp === item.time.from
                ? {
                    ...item,
                    payment: {
                        ...item.payment,
                        paid: paymentStatus === 'paid',
                        paidUpfront: paymentStatus === 'paid' ? item.payment.finalCost : 0,
                        remainingCost: paymentStatus !== 'paid' ? item.payment.finalCost : 0,
                    },
                }
                : item)),
        };
    }

    case actionTypes.DELETE_EMPLOYEE_SUCCEEDED: {
        const id = payload.payload;

        const filters = { ...state.filters };

        delete filters[id];

        return {
            ...state,
            employees: state.employees.filter((obj) => Number(obj.id) !== Number(id)),
            filters,
        };
    }
    case actionTypes.ADD_TIME_BLOCK_SUCCEEDED: {
        const { block } = payload;
        if (block.product.type !== 'employees') {
            return state;
        }

        return {
            ...state,
            employees: state.employees.map((item) => {
                if (block.product.id === item.id) {
                    item.timeBlocks.push(!block.time.wholeDay ? {
                        from: block.time.from?.unix(),
                        to: block.time.to?.unix(),
                        id: block.id,
                        comment: block.note,
                    } : {
                        from: block.time.from?.startOf('day').unix(),
                        to: block.time.from?.endOf('day').unix(),
                        id: block.id,
                        comment: block.note,
                    });
                }
                return item;
            }),
        };
    }

    case actionTypes.EDIT_TIME_BLOCK_SUCCEEDED: {
        const { block } = payload;
        if (block.product.type !== 'employees') {
            return state;
        }

        return {
            ...state,
            employees: state.employees.map((originalItem) => {
                if (block.product.id === originalItem.id) {
                    const item = { ...originalItem };
                    const blockIndex = item.timeBlocks.findIndex((obj) => obj.id === block.blockId);
                    item.timeBlocks[blockIndex] = { ...item.timeBlocks[blockIndex] };
                    if (!block.time.wholeDay) {
                        item.timeBlocks[blockIndex].from = block.time.from?.unix();
                        item.timeBlocks[blockIndex].to = block.time.to?.unix();
                        item.timeBlocks[blockIndex].id = block.id;
                        item.timeBlocks[blockIndex].comment = block.note;
                    } else {
                        item.timeBlocks[blockIndex].from = block.time.from?.startOf('day').unix();
                        item.timeBlocks[blockIndex].to = block.time.from?.endOf('day').unix();
                        item.timeBlocks[blockIndex].id = block.id;
                        item.timeBlocks[blockIndex].comment = block.note;
                    }
                    return item;
                }
                return originalItem;
            }),
        };
    }

    case actionTypes.DELETE_TIME_BLOCK_SUCCEEDED: {
        const { block } = payload;
        if (block.product.type !== 'employees') {
            return state;
        }

        return {
            ...state,
            employees: state.employees.map((item) => {
                if (block.product.id === item.id) {
                    // eslint-disable-next-line no-param-reassign
                    item.timeBlocks = item.timeBlocks.filter((obj) => Number(obj.id) !== Number(block.blockId));
                }
                return item;
            }),
        };
    }
    case actionTypes.PRODUCTS_GET_BY_ID_SUCCESS: {
        const { item: { type, product } } = payload;
        if (type !== 'employee') return state;

        return {
            ...state,
            loaded: true,
            employees: state.employees.map((item) => (item.id === product.id ? {
                ...item,
                ...product,
            } : item)),
        };
    }
    case actionTypes.SELECT_SHOP_TRIGGER:
        return {
            ...state,
            loaded: false,
            employees: null,
            bookings: null,
            blocks: null,
        };

    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    default:
        return state;
    }
};
