import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './BundleDescriptionFormRow.module.scss';

const T_PREFIX = 'addOrEdit.form.fields.description';

const BundleDescriptionFormRow = (props) => {
    const {
        sold,
        values,
        validation,
        onChangeDescription,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <Row>
            <Col
                xs={12}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label>
                        {t(`${T_PREFIX}.label`)}
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        value={values.description}
                        name="description"
                        disabled={sold}
                        isInvalid={validation?.description?.isInvalid}
                        className={styles.description}
                        onChange={onChangeDescription}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.description?.isInvalid,
                        })}
                    >
                        {validation?.description?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

BundleDescriptionFormRow.propTypes = {
    sold: PropTypes.bool,
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onChangeDescription: PropTypes.func,
};

BundleDescriptionFormRow.defaultProps = {
    sold: false,
    validation: null,
    onChangeDescription: emptyFunc,
};

export default BundleDescriptionFormRow;
