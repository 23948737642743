import * as TYPES from '../../actions/bundles/actionTypes';

const INITIAL_STATE = {
    item: null,
    loading: false,
};

export const bundleItemReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (TYPES.BUNDLE_ITEM_SET): {
        const { bundle } = payload;
        return {
            ...state,
            item: bundle,
        };
    }
    case (TYPES.BUNDLE_ITEM_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    default:
        return state;
    }
};
