import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as TYPES from './actionTypes';

export const getBundleItem = ({ bundleId }) => ({
    type: TYPES.BUNDLE_ITEM_GET,
    payload: { bundleId },
});

export const setBundleItem = ({ bundle }) => ({
    type: TYPES.BUNDLE_ITEM_SET,
    payload: { bundle },
});

export const setBundleItemLoading = ({ loading }) => ({
    type: TYPES.BUNDLE_ITEM_LOADING_SET,
    payload: { loading },
});

export const getBundles = () => ({
    type: TYPES.BUNDLES_GET,
});

export const setBundles = ({
    bundles, itemsCount, pagesCount,
}) => ({
    type: TYPES.BUNDLES_SET,
    payload: {
        bundles,
        itemsCount,
        pagesCount,
    },
});

export const addBundlesItem = ({ bundle, history, onFinal }) => ({
    type: TYPES.BUNDLES_ITEM_ADD,
    payload: {
        bundle,
        history,
        onFinal,
    },
});

export const editBundlesItem = ({
    bundleId, bundle, history, onFinal,
}) => ({
    type: TYPES.BUNDLES_ITEM_EDIT,
    payload: {
        bundleId,
        bundle,
        history,
        onFinal,
    },
});

export const sellBundlesItem = ({
    bundleId, sellInfo, history, onFinal, onDone,
}) => ({
    type: TYPES.BUNDLES_ITEM_SELL,
    payload: {
        bundleId,
        sellInfo,
        history,
        onFinal,
        onDone,
    },
});

export const archiveBundlesItem = ({ bundleId }) => ({
    type: TYPES.BUNDLES_ITEM_ARCHIVE,
    payload: { bundleId },
});

export const toggleBundlesItemStatus = ({ bundleId }) => ({
    type: TYPES.BUNDLES_ITEM_STATUS_TOGGLE,
    payload: { bundleId },
});

export const setBundlesPage = ({ page }) => ({
    type: TYPES.BUNDLES_PAGE_SET,
    payload: { page },
});

export const setBundlesPageFirst = () => ({
    type: TYPES.BUNDLES_PAGE_SET_FIRST,
});

export const setBundlesPageLast = () => ({
    type: TYPES.BUNDLES_PAGE_SET_LAST,
});

export const setBundlesPagePrev = () => ({
    type: TYPES.BUNDLES_PAGE_SET_PREV,
});

export const setBundlesPageNext = () => ({
    type: TYPES.BUNDLES_PAGE_SET_NEXT,
});

export const setBundlesSearch = ({ search }) => ({
    type: TYPES.BUNDLES_SEARCH_SET,
    payload: { search },
});

export const setBundlesLoading = ({ loading }) => ({
    type: TYPES.BUNDLES_LOADING_SET,
    payload: { loading },
});

export const toggleBundlesHideArchived = () => ({
    type: TYPES.BUNDLES_HIDE_ARCHIVED_TOGGLE,
});

export const getSoldBundles = () => ({
    type: TYPES.SOLD_BUNDLES_GET,
});

export const setSoldBundles = ({
    soldBundles, itemsCount, pagesCount,
}) => ({
    type: TYPES.SOLD_BUNDLES_SET,
    payload: {
        soldBundles,
        itemsCount,
        pagesCount,
    },
});

export const editSoldBundlesItem = ({ soldBundleId, soldBundle }) => ({
    type: TYPES.SOLD_BUNDLES_ITEM_EDIT,
    payload: {
        soldBundleId,
        soldBundle,
    },
});

export const archiveSoldBundlesItem = ({ soldBundleId }) => ({
    type: TYPES.SOLD_BUNDLES_ITEM_ARCHIVE,
    payload: { soldBundleId },
});

export const setSoldBundlesPage = ({ page }) => ({
    type: TYPES.SOLD_BUNDLES_PAGE_SET,
    payload: { page },
});

export const setSoldBundlesPageFirst = () => ({
    type: TYPES.SOLD_BUNDLES_PAGE_SET_FIRST,
});

export const setSoldBundlesPageLast = () => ({
    type: TYPES.SOLD_BUNDLES_PAGE_SET_LAST,
});

export const setSoldBundlesPagePrev = () => ({
    type: TYPES.SOLD_BUNDLES_PAGE_SET_PREV,
});

export const setSoldBundlesPageNext = () => ({
    type: TYPES.SOLD_BUNDLES_PAGE_SET_NEXT,
});

export const setSoldBundlesSearch = ({ search }) => ({
    type: TYPES.SOLD_BUNDLES_SEARCH_SET,
    payload: { search },
});

export const setSoldBundlesLoading = ({ loading }) => ({
    type: TYPES.SOLD_BUNDLES_LOADING_SET,
    payload: { loading },
});

export const toggleSoldBundlesHideArchived = () => ({
    type: TYPES.SOLD_BUNDLES_HIDE_ARCHIVED_TOGGLE,
});

export const getCompanyBundles = () => ({
    type: TYPES.COMPANY_BUNDLES_GET,
});

export const setCompanyBundles = ({ companyBundles }) => ({
    type: TYPES.COMPANY_BUNDLES_SET,
    payload: { companyBundles },
});

export const setCompanyBundlesLoading = ({ loading }) => ({
    type: TYPES.COMPANY_BUNDLES_LOADING_SET,
    payload: { loading },
});

export const toggleSoldBundlePaymentStatus = ({ soldBundleId }) => ({
    type: TYPES.SOLD_BUNDLES_PAYMENT_STATUS_TOGGLE,
    payload: { soldBundleId },
});

export const setSoldBundlePaymentStatus = ({
    soldBundleId, status, paymentStatus, isManuallySet,
}) => ({
    type: TYPES.SOLD_BUNDLES_PAYMENT_STATUS_SET,
    payload: {
        soldBundleId,
        status,
        paymentStatus,
        isManuallySet,
    },
});

export const checkBundlesItem = createPromiseAction(TYPES.CHECK_BUNDLE_GET);
