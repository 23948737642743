/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import {
    Calendar2Filled, ManualIcon, Pen2Icon, Sent2Icon, ShoppingCartIcon, Users2Icon, Close,
} from 'components/Icon/Icon';
import {
    MESSAGES_ADD_PAGE,
    MESSAGES_LIST_PAGE,
    MESSAGES_ROOT_PAGE,
    SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE,
    SETTINGS_COMPANY_LOCATIONS_PAGE,
    // PROMOTIONS_ROOT_PAGE,
} from 'const/CLIENT_URL';
import { roles } from 'pages';

const siteNavigationMap = {
    agenda: {
        directsTo: '/agenda',
        exact: false,
        translation: 'navigation.agenda',
        icon: Calendar2Filled,
        subRoutes: [
            {
                directsTo: '/agenda/objects',
                exact: false,
                translation: 'navigation.objects',
                subRoutes: null,
            },
            {
                directsTo: '/agenda/employees',
                exact: false,
                translation: 'navigation.employees',
                subRoutes: null,
            },
            {
                directsTo: '/agenda/groups',
                exact: false,
                translation: 'navigation.groupSessions',
                subRoutes: null,
            },
            {
                directsTo: '/agenda/events',
                exact: false,
                translation: 'navigation.events',
                subRoutes: null,
            },
        ],
    },
    services: {
        directsTo: '/services',
        exact: false,
        translation: 'navigation.services',
        icon: ShoppingCartIcon,
        subRoutes: [
            {
                directsTo: '/services/services',
                exact: false,
                translation: 'navigation.services',
                subRoutes: [
                    {
                        directsTo: '/services/services/list',
                        exact: false,
                        translation: 'navigation.services',
                        subRoutes: null,
                    },
                    {
                        directsTo: '/services/services/add-service',
                        exact: false,
                        translation: 'navigation.services',
                        subRoutes: null,
                    },
                ],
            },
            {
                directsTo: '/services/bundles',
                exact: false,
                translation: 'navigation.bundles',
                subRoutes: [
                    {
                        directsTo: '/services/bundles/list',
                        exact: false,
                        translation: 'navigation.availableBundles',
                        subRoutes: null,
                    },
                    {
                        directsTo: '/services/bundles/sold',
                        exact: false,
                        translation: 'navigation.soldBundles',
                        subRoutes: null,
                    },
                ],
            },
            {
                directsTo: '/services/subscriptions',
                exact: false,
                translation: 'navigation.subscriptions',
                subRoutes: [
                    {
                        directsTo: '/services/subscriptions/list',
                        exact: false,
                        translation: 'navigation.availableSubscriptions',
                        subRoutes: null,
                    },
                    {
                        directsTo: '/services/subscriptions/sold',
                        exact: false,
                        translation: 'navigation.soldSubscriptions',
                        subRoutes: null,
                    },
                ],
            },
        ],
    },
    clients: {
        directsTo: '/clients',
        exact: false,
        translation: 'navigation.clients',
        icon: Users2Icon,
        subRoutes: [
            {
                directsTo: ({ clientId }) => `/clients/list/${clientId}/info`,
                exact: false,
                translation: 'navigation.info',
                subRoutes: null,
                mobileOnly: true,
            },
            {
                directsTo: ({ clientId }) => `/clients/list/${clientId}/notes`,
                exact: false,
                translation: 'navigation.notes',
                subRoutes: null,
            },
            {
                directsTo: ({ clientId }) => `/clients/list/${clientId}/bookings`,
                exact: false,
                translation: 'navigation.bookings',
                subRoutes: null,
            },
            {
                directsTo: ({ clientId }) => `/clients/list/${clientId}/bundles`,
                exact: false,
                translation: 'navigation.bundles',
                subRoutes: null,
            },
            {
                directsTo: ({ clientId }) => `/clients/list/${clientId}/subscriptions`,
                exact: false,
                translation: 'navigation.subscriptions',
                subRoutes: null,
            },
            // TODO add subroutes for client profile (bundles, subscriptions, invoices, reviews, comunication)
        ],
    },
    marketing: {
        directsTo: '/marketing',
        exact: false,
        translation: 'navigation.marketing',
        icon: Sent2Icon,
        subRoutes: [
            {
                directsTo: MESSAGES_ROOT_PAGE,
                exact: false,
                translation: 'navigation.messages',
                subRoutes: [
                    {
                        directsTo: MESSAGES_LIST_PAGE,
                        exact: false,
                        translation: 'navigation.messages',
                        subRoutes: null,
                    },
                    {
                        directsTo: MESSAGES_ADD_PAGE,
                        exact: false,
                        translation: 'navigation.messages',
                        subRoutes: null,
                    },
                ],
            },
            // {
            //     directsTo: PROMOTIONS_ROOT_PAGE,
            //     exact: false,
            //     translation: 'navigation.promotions',
            //     subRoutes: null,
            // },
        ],
    },
    // statistics: {
    //     directsTo: '/statistics',
    //     exact: false,
    //     translation: 'navigation.statistics',
    //     subRoutes: null,
    // },
    financials: {
        directsTo: '/financials',
        exact: false,
        translation: 'navigation.financials',
        icon: ManualIcon,
        subRoutes: [
            {
                directsTo: '/financials/clearings',
                exact: false,
                translation: 'navigation.clearings',
                subRoutes: null,
            },
            {
                directsTo: '/financials/invoices',
                exact: false,
                translation: 'navigation.invoices',
                subRoutes: null,
            },
            {
                directsTo: '/financials/payouts',
                exact: false,
                translation: 'navigation.payouts',
                subRoutes: null,
            },
        ],
    },
    /*
        No settings option is available now.
        We are just displaying these options inside the dropdown.
     */
    settings: {
        directsTo: '/settings',
        exact: false,
        translation: 'navigation.settings',
        icon: Pen2Icon,
        subRoutes: [
            {
                directsTo: '/settings/company',
                exact: false,
                translation: 'navigation.company',
                subRoutes: [
                    // general, location, birthday&loyaity, display&notification
                    {
                        directsTo: '/settings/company/general',
                        exact: false,
                        translation: 'navigation.general',
                        subRoutes: null,
                    },
                    {
                        directsTo: SETTINGS_COMPANY_LOCATIONS_PAGE,
                        exact: true,
                        translation: 'navigation.locations',
                        subRoutes: null,
                    },
                    {
                        directsTo: '/settings/company/discount-and-loyalty',
                        exact: false,
                        translation: 'navigation.discountAndLoyalty',
                        subRoutes: null,
                    },
                    {
                        directsTo: '/settings/company/display-and-notifications',
                        exact: false,
                        translation: 'navigation.displayAndNotifications',
                        subRoutes: null,
                    },
                ],
            },
            {
                directsTo: '/settings/invite-clients',
                exact: false,
                translation: 'navigation.inviteClients',
                subRoutes: null,
            },
            // TODO add subroutes for settings (emailTemplates, clientProfiles, widgetIntegration)
        ],
    },
    access: {
        isHide: false,
        directsTo: '/access',
        exact: false,
        translation: 'navigation.access',
        icon: Pen2Icon,
        subRoutes: [
            {
                directsTo: '/access/logs',
                exact: false,
                translation: 'navigation.logs',
                subRoutes: null,
            },
            {
                directsTo: '/access/gates',
                exact: false,
                translation: 'navigation.gates',
                subRoutes: null,
            },
            {
                directsTo: '/access/profiles',
                exact: false,
                translation: 'navigation.profiles',
                subRoutes: null,
            },
        ],
    },
};

const servicesKeyName = 'services';
const bundlesSubRouteIndex = 1;
const subscriptionsSubRouteIndex = 2;
const settingsKeyName = 'settings';
const setingsCompanySubRouteIndex = 0;
const setingsCompanyLocationsSubRouteIndex = 1;
const clients = 'clients';

const authorizationRoutes = [
    '/sign-up',
    '/sign-in',
    '/reset-password',
    '/reset-password-success',
    '/set-new-password',
];

class NavigationService {
    static getMainNavigation(role, sections, preset, productsList, withSettings = false) {
        // settings are not the part of the main navigation
        if (role === 'employee') {
            return [siteNavigationMap.agenda];
        }
        const routes = Object.entries(this.applySection(sections))
            .reduce((acc, [key, value]) => (key !== settingsKeyName || withSettings ? [...acc, value] : acc), []);

        return routes.filter(({ isHide }) => !isHide).map((route) => this.filterPermissionSections(route, preset?.section, productsList));
    }

    static getSubNavigationByMainRoute(mainRoute, sections, preset, productsList) {
        if (!sections) {
            return siteNavigationMap[mainRoute].subRoutes.filter(({ isHide }) => !isHide);
        }
        const { subRoutes } = this.applySection(sections)[mainRoute];
        if (!preset?.section) {
            return subRoutes.filter(({ isHide }) => !isHide);
        }
        return this.filterPermissionSections(siteNavigationMap[mainRoute], preset.section, productsList).subRoutes.filter(({ isHide }) => !isHide);
    }

    static getSettingsNavigation(role) {
        if (!roles.settings.includes(role)) {
            return [];
        }
        return siteNavigationMap[settingsKeyName].subRoutes;
    }

    static getSubscriptionsSubNavigation() {
        return siteNavigationMap[servicesKeyName].subRoutes[subscriptionsSubRouteIndex].subRoutes;
    }

    static getBundlesSubNavigation() {
        return siteNavigationMap[servicesKeyName].subRoutes[bundlesSubRouteIndex].subRoutes;
    }

    static getSettingsCompanySubNavigation({ shops }) {
        const { subRoutes } = siteNavigationMap[settingsKeyName].subRoutes[setingsCompanySubRouteIndex];

        if (shops && shops.length) {
            subRoutes[setingsCompanyLocationsSubRouteIndex].subRoutes = shops.map((shop) => ({
                directsTo: SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE({ id: shop.id }),
                exact: false,
                translation: shop.name,
            }));
        }

        return subRoutes;
    }

    static getClientsSubNavigation({ clientId, isMobile }) {
        return siteNavigationMap[clients].subRoutes.map((subRoute) => ({
            ...subRoute,
            directsTo: typeof subRoute.directsTo === 'function'
                ? subRoute.directsTo({ clientId })
                : subRoute.directsTo,
        })).filter(({ mobileOnly }) => !mobileOnly || mobileOnly && isMobile);
    }

    static applySection(sections) {
        return Object.entries(siteNavigationMap).reduce((acc, [key, val]) => {
            if (!sections) {
                return siteNavigationMap;
            }

            const foundSection = Object.entries(sections).find(([sectionKey, _]) => sectionKey === key);

            if (foundSection) {
                const [_, foundSectionValue] = foundSection;

                if (foundSectionValue === false) {
                    return acc;
                }

                // section without sub-sections
                if (foundSectionValue === true) {
                    return {
                        ...acc,
                        [key]: val,
                    };
                }

                // section with sub-sections
                const filteredSubRoutes = val.subRoutes.filter((subRoute) => {
                    const routeKey = subRoute.directsTo.replace(`/${key}/`, '');
                    return foundSectionValue[routeKey] === true;
                });

                return {
                    ...acc,
                    [key]: {
                        ...val,
                        subRoutes: filteredSubRoutes,
                    },
                };
            }

            return {
                ...acc,
                [key]: val,
            };
        }, {});
    }

    static checkPath(path, sections) {
        const paths = Object.values(this.applySection(sections))
            .reduce((acc, route) => {
                // settings aren't included
                if (!route.directsTo) return acc;

                acc.push(route.directsTo);
                if (route.subRoutes?.length) {
                    route.subRoutes.forEach((subRoute) => {
                        acc.push(subRoute.directsTo);
                    });
                }

                return acc;
            }, []);

        return {
            isAllowed: [...paths, ...authorizationRoutes].includes(path),
            firstAccessibleRoute: paths[0],
        };
    }

    static filterPermissionSections(route, sections, productsList) {
        if (!sections) return route;

        const transformToRouteView = (obj, withS = false) => Object.entries(obj ?? {})
            .reduce((acc, [key, value]) => ({
                ...acc,
                [`/agenda/${key}${withS ? 's' : ''}`]: value,
            }), {});

        const transformedSections = transformToRouteView(sections, true);
        const transformedProducts = transformToRouteView(productsList);

        // filter agenda routes with permissions of employee
        if (route.directsTo === '/agenda') {
            return {
                ...route,
                subRoutes: route.subRoutes.filter(
                    (subRoute) => {
                        if (transformedSections[subRoute.directsTo] === 'never') {
                            return false;
                        }

                        return !!transformedProducts[subRoute.directsTo];
                    },
                ),
            };
        }
        return { ...route, subRoutes: route.subRoutes.filter((subRoute) => typeof subRoute.directsTo === 'string') };
    }

    static getCurrentRoute(pathname) {
        const currentRoute = Object.values(siteNavigationMap)
            .reduce((acc, route) => {
                if (route.directsTo === pathname) {
                    return route;
                }

                if (route.subRoutes?.length) {
                    const subRoute = route.subRoutes.find((subRoute) => subRoute.directsTo === pathname);
                    if (subRoute) {
                        return subRoute;
                    }

                    const subSubRoute = route.subRoutes.find((subRoute) => subRoute.subRoutes?.length && subRoute.subRoutes.find((subSubRoute) => subSubRoute.directsTo === pathname));
                    if (subSubRoute) {
                        return subSubRoute;
                    }
                }

                return acc;
            }, null);

        return currentRoute;
    }

    static getParentRoute(pathname) {
        return pathname.split('/')[1];
    }
}

export default NavigationService;
