import { Button } from 'components/Common/Button';
import React from 'react';
import { Modal } from 'react-bootstrap';

const ProductModal = ({
    type, data, hide, children, cancelButtonTitle, editButtonTitle, buttonVisible,
}) => (
    <Modal centered size="lg" show={!!data} onHide={hide}>
        <Modal.Header closeButton>
            <Modal.Title className="mb-0 font-weight-600">
                {data?.name}
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
            <Button
                color="outline"
                onClick={hide}
            >
                {cancelButtonTitle}
            </Button>
            {buttonVisible && (
                <Button
                    color="yellow"
                    href={`/agenda/${type}/edit/${data.id}`}
                >
                    {editButtonTitle}
                </Button>
            )}
        </Modal.Footer>
    </Modal>
);

export default ProductModal;
