import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './DiscountRow.module.scss';

const T_PREFIX = 'bookings.list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const DiscountRow = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const { booking } = props;

    return (
        <>
            <ListTableBodyCell
                className="text-muted"
            >
                <span className={styles.discountName}>
                    {`${t(`${T_FIELDS}.loyaltyDiscount.label`)}:`}
                </span>
                <span className={styles.discountValue} data-testid="data-test-loyalty-discount-value">
                    {booking.loyaltyDiscount}
                    %
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                <span className={styles.discountName}>
                    {`${t(`${T_FIELDS}.birthdayDiscount.label`)}:`}
                </span>
                <span className={styles.discountValue} data-testid="data-test-birthday-discount-value">
                    {booking.birthdayDiscount}
                    %
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell
                colSpan={9}
                className="text-muted"
            >
                <span className={styles.discountName}>
                    {`${t(`${T_FIELDS}.permanentDiscount.label`)}:`}
                </span>
                <span className={styles.discountValue} data-testid="data-test-permanent-discount-value">
                    {booking.permanentDiscount}
                    %
                </span>
            </ListTableBodyCell>
            {false && (
                <ListTableBodyCell
                    colSpan={7}
                    className="text-muted"
                >
                    <span className={styles.discountName} data-testid="data-test-promotion-discount-name">
                        {`${t(`${T_FIELDS}.promotion.label`)}:`}
                    </span>
                    <span className={styles.discountValue}>??</span>
                </ListTableBodyCell>
            )}
        </>
    );
};

DiscountRow.propTypes = {
    booking: PropTypes.object,
};

DiscountRow.defaultProps = {
    booking: null,
};

export default DiscountRow;
