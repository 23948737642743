/* eslint-disable indent */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loaded: false,
    avatar: null,
    name: null,
};

export const user = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_USER_DETAILS_RESOLVED: {
            const {
                avatarUrl: avatar, firstName, lastName, email, defaultTab, soundNotificationsEnabled, intercomHash,
            } = payload;
            return {
                ...state,
                loaded: true,
                avatar,
                name: firstName,
                fullName: (`${firstName || ''} ${lastName || ''}`).trim(),
                email,
                defaultTab,
                soundNotificationsEnabled,
                loginDate: Date.now(),
                intercomHash,
            };
        }
        case actionTypes.SET_DEFAULT_TAB_RESOLVED: {
            return {
                ...state,
                ...payload,
            };
        }
        case actionTypes.TOGGLE_SOUND_NOTIFICATIONS_RESOLVED: {
            return {
                ...state,
                ...payload,
            };
        }
        case actionTypes.GET_USER_DETAILS_REJECTED:
        case actionTypes.REFRESH_TOKEN_REJECTED:
        case actionTypes.SIGN_OUT_SUCCEEDED:
            return initialState;
        default:
            return state;
    }
};
