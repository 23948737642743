import * as CLEARINGS_TYPES from '../../actions/financials/clearings/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
};

export const clearingsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (CLEARINGS_TYPES.CLEARINGS_SET): {
        const { clearings, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: clearings,
            page,
            itemsCount,
            pagesCount,
        };
    }
    case (CLEARINGS_TYPES.CLEARINGS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (CLEARINGS_TYPES.CLEARINGS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (CLEARINGS_TYPES.CLEARINGS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (CLEARINGS_TYPES.CLEARINGS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (CLEARINGS_TYPES.CLEARINGS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (CLEARINGS_TYPES.CLEARINGS_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    default:
        return state;
    }
};
