import React from 'react';
import PropTypes from 'prop-types';

const EmptyNotesIcon = (props) => {
    const { width, height } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 189 189"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M64.3248 159.354L125.084 159.584C128.141 159.595 130.959 157.982 132.477 155.352L162.658 103.079C164.177 100.449 164.166 97.2008 162.628 94.558L132.05 42.0552C130.511 39.4135 127.68 37.779 124.623 37.7675L63.8694 37.5414C60.8119 37.5313 57.9934 39.1455 56.4752 41.7763L26.2943 94.0494C24.7753 96.6788 24.7873 99.9259 26.3256 102.567L56.9043 155.07C58.4426 157.708 61.2706 159.341 64.3248 159.354Z" fill="#F9FAFB" />
            <path d="M85.1611 28.107L81.3892 21.6308C81.1995 21.305 81.198 20.9045 81.3854 20.5801L85.1082 14.1323C85.2954 13.8078 85.6431 13.6087 86.0202 13.6099L93.5149 13.6383C93.892 13.6397 94.2412 13.8413 94.431 14.1671L98.2021 20.6429C98.3917 20.9689 98.3931 21.3695 98.2057 21.6939L94.4829 28.1418C94.2957 28.4662 93.9481 28.6652 93.5711 28.6638L86.0764 28.6354C85.6997 28.6338 85.3508 28.4324 85.1611 28.107Z" fill="#E5EBF0" />
            <path d="M100.663 58.196L93.3798 46.1732C93.0134 45.5684 92.9976 44.8324 93.3384 44.2425L100.109 32.5155C100.45 31.9254 101.095 31.5708 101.802 31.5854L115.856 31.8812C116.563 31.8961 117.225 32.2779 117.591 32.8829L124.873 44.9048C125.239 45.51 125.255 46.2462 124.914 46.8363L118.143 58.5632C117.803 59.1532 117.158 59.5076 116.451 59.4927L102.397 59.1968C101.69 59.1816 101.029 58.8002 100.663 58.196Z" fill="#E5EBF0" />
            <path d="M40.1106 147.426L57.1732 147.49C58.0315 147.494 58.8229 147.041 59.2491 146.302L67.7245 131.623C68.1512 130.884 68.148 129.972 67.7163 129.23L59.1291 114.486C58.6971 113.744 57.902 113.285 57.0435 113.282L39.9827 113.218C39.1241 113.215 38.3326 113.669 37.9063 114.408L29.4309 129.087C29.0043 129.825 29.0077 130.737 29.4396 131.479L38.0268 146.223C38.4588 146.964 39.2529 147.422 40.1106 147.426Z" fill="#E5EBF0" />
            <path fillRule="evenodd" clipRule="evenodd" d="M79.6669 65.835C75.2486 65.835 71.6667 69.4168 71.6667 73.8352V116.168C71.6667 120.586 75.2486 124.168 79.667 124.168H110.333C114.752 124.168 118.333 120.586 118.333 116.168V92.7525C118.333 91.2797 117.139 90.0857 115.667 90.0857H97.5155C95.3063 90.0857 93.5154 88.2948 93.5154 86.0856V68.5017C93.5154 67.0289 92.3215 65.835 90.8487 65.835H79.6669ZM98.5373 69.6969V82.3135C98.5373 83.7863 99.7313 84.9802 101.204 84.9802H113.821C115.246 84.9802 115.96 83.2567 114.952 82.2488L101.269 68.5655C100.261 67.5575 98.5373 68.2714 98.5373 69.6969Z" fill="#FFC100" />
            <path fillRule="evenodd" clipRule="evenodd" d="M83.5 102C83.5 100.619 84.6193 99.5 86 99.5H107C108.381 99.5 109.5 100.619 109.5 102C109.5 103.381 108.381 104.5 107 104.5H86C84.6193 104.5 83.5 103.381 83.5 102Z" fill="#F9FAFB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M83.5 112C83.5 110.619 84.6193 109.5 86 109.5H101C102.381 109.5 103.5 110.619 103.5 112C103.5 113.381 102.381 114.5 101 114.5H86C84.6193 114.5 83.5 113.381 83.5 112Z" fill="#F9FAFB" />
        </svg>

    );
};

EmptyNotesIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

EmptyNotesIcon.defaultProps = {
    width: 0,
    height: 0,
};

export default EmptyNotesIcon;
