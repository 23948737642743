import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientBundlesTableHeaderRow.module.scss';

const T_PREFIX = 'clientBundlesTable.header';

const ClientBundlesTableHeaderRow = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.USER);

    return (
        <tr>
            <TableHeaderCell className={styles.serviceCell}>
                <ListTableHeaderCell
                    className={styles.serviceCell}
                    name={t(`${T_PREFIX}.service`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.bundleCell}>
                <ListTableHeaderCell
                    className={styles.serviceCell}
                    name={t(`${T_PREFIX}.bundle`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.bookings`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.purchasedAt`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.endsAt`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.paymentType`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_PREFIX}.profit`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.actions`)}
                />
            </TableHeaderCell>
        </tr>
    );
};

ClientBundlesTableHeaderRow.propTypes = {};

ClientBundlesTableHeaderRow.defaultProps = {};

export default ClientBundlesTableHeaderRow;
