import React from 'react';

// Hooks
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Badge } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import moment from 'moment';
import { formatPrice } from 'helpers/formatters/formatPrice';
import {
    Clock, Users, UserStar,
} from '../Icon/Icon';

// Styles
import * as styles from './GroupBooking.module.scss';

// Utils
import { useMobile } from '../../hooks/useMobile';

const GroupBooking = ({
    className, booking, week, year, onClick, collapseText = true,
}) => {
    const { t } = useTranslation();

    const isMobile = useMobile();
    const [shortTextMore, setShortTextMore] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1100) {
                setShortTextMore(5);
                return;
            }

            if (window.innerWidth < 1200) {
                setShortTextMore(3);
                return;
            }

            if (window.innerWidth < 1300) {
                setShortTextMore(1);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return /* istanbul ignore next */ () => window.removeEventListener('resize', handleResize);
    }, []);

    const shortText = (text, value) => {
        if (!text) return null;
        if (isMobile || !collapseText) return text;
        return text.length > value ? `${text.substring(0, value - 3)}...` : text;
    };

    return (
        <div className={classNames(styles.container, className, 'border border-left-0 border-light')} data-testid={`data-test-group-card-${moment(booking?.time?.from).format('YYYY-MM-DD')}-${booking?.service?.name}`}>
            <div
                className={classNames(styles.booking, { [styles.clickable]: !!onClick }, 'd-flex flex-column p-2 border-left border-5 border-light')}
                onClick={() => {
                    if (!onClick || !booking?.id || !booking?.time?.from) {
                        return;
                    }

                    onClick({ id: booking.id, timestamp: booking.time.from });
                }}
                data-testid="data-test-group-card-click"
            >
                <span className={classNames(styles.breakable, { [styles.staticHeight]: !isMobile }, 'font-size-14 font-weight-bold d-block')} data-testid="data-test-group-card-name">
                    {booking?.group?.name ? shortText(booking.group.name, 25 - shortTextMore) : <Skeleton width={100} />}
                </span>
                {booking?.service && (
                    <span className={classNames(styles.breakable, { [styles.staticHeight]: !isMobile }, 'font-size-14 font-weight-bold text-dark-gray d-block')} data-testid="data-test-group-card-service">
                        {booking.service?.name ? shortText(booking.service?.name, 25 - shortTextMore) : <Skeleton width={75} />}
                    </span>
                )}

                <div className={classNames(styles.staticHeight)}>
                    <div className="d-flex flex-row flex-wrap mt-2 w-50 w-lg-100">
                        <span className="flex-grow-1 font-size-14 font-weight-bold text-muted w-100" data-testid="data-test-group-card-price">
                            {booking ? (
                                formatPrice.toEuroWithComma({ amount: booking.payment?.finalCost ?? booking.price })
                            ) : <Skeleton width={50} />}
                        </span>

                        <span className="flex-grow-1 font-size-14 font-weight-bold text-muted w-100">
                            {booking ? (
                                <>
                                    <Users
                                        width={14}
                                        className="position-relative mr-1"
                                        style={{ top: -1 }}
                                        strokeWidth={3}
                                    />
                                    <span data-testid="data-test-group-card-places-available">
                                        {`${booking.places.total - booking.places.available}/${booking.places.total}`}
                                    </span>
                                </>
                            ) : <Skeleton width={75} />}
                        </span>
                    </div>

                    <span className="font-size-14 font-weight-bold text-muted">
                        {booking ? (
                            <>
                                <Clock
                                    width={14}
                                    height={14}
                                    className="position-relative mr-1"
                                    style={{ top: -1 }}
                                    strokeWidth={3}
                                />
                                <span data-testid="data-test-group-card-time">
                                    {`${(week && year ? moment(booking.time.from).isoWeek(week).year(year) : moment(booking.time.from)).format('HH:mm')} - ${(week && year ? moment(booking.time.to).isoWeek(week).year(year) : moment(booking.time.to)).format('HH:mm')}`}
                                </span>
                            </>
                        ) : <Skeleton width={65} />}
                    </span>
                </div>

                <span className={classNames(styles.breakable, { [styles.staticHeight]: !isMobile }, 'mt-3 font-size-14 font-weight-bold text-muted d-block')}>
                    {booking ? (
                        <>
                            <UserStar
                                width={14}
                                height={14}
                                className="position-relative mr-1"
                                style={{ top: -1 }}
                                strokeWidth={3}
                            />
                            <span data-testid="data-test-group-card-product">
                                {shortText(booking.product?.name, 20 - shortTextMore) ?? '-'}
                            </span>
                        </>
                    ) : <Skeleton width={75} />}
                </span>

                {(booking?.isCancelled || booking?.isFull) && (
                    <div className="d-flex flex-column mt-1">
                        {booking.isCancelled && (
                            <div>
                                <Badge variant="danger" className="text-uppercase my-1" pill data-testid="data-test-group-card-cancelled">
                                    {t('groupsTimeline.cancelled')}
                                </Badge>
                            </div>
                        )}

                        {booking.isFull && (
                            <div>
                                <Badge variant="success" className="text-uppercase my-1" pill data-testid="data-test-group-card-full">
                                    {t('groupsTimeline.full')}
                                </Badge>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default GroupBooking;
