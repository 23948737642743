import React from 'react';

// Components
import { Switch, Route, Redirect } from 'react-router-dom';

// Routes
import Index from '../EventsGroup/Index/Index';
import Add from '../EventsGroup/Add/Add';
import Edit from '../EventsGroup/Edit/Edit';

const Events = ({
    NoProductComponent,
    isProductExist,
    isAllowed,
}) => (
    <Switch>
        <Route path="/agenda/events/add" component={Add} />
        <Route path="/agenda/events/edit/:id" component={Edit} />
        <Route
            path="/agenda/events"
            exact
            render={(props) => {
                if (isAllowed === 'never'
                    || ((isAllowed === 'hasOwn' || isAllowed === 'hasAny') && !isProductExist)
                ) {
                    return <Redirect to="/agenda/employees" />;
                }
                return NoProductComponent ?? <Index {...props} />;
            }}
        />
        <Redirect to="/agenda/events" />
    </Switch>
);

export default Events;
