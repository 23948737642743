import React from 'react';

const ShoppingCartIcon = ({ width = '24', height = '24' }) => (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
        <path d="M21.08 6.99961C20.9072 6.70023 20.6598 6.45074 20.3618 6.27548C20.0639 6.10022 19.7256 6.00518 19.38 5.99961H6.58L6 3.73961C5.9414 3.52145 5.81066 3.32955 5.62908 3.19518C5.44749 3.06081 5.22576 2.99188 5 2.99961H3C2.73478 2.99961 2.48043 3.10497 2.29289 3.2925C2.10536 3.48004 2 3.73439 2 3.99961C2 4.26483 2.10536 4.51918 2.29289 4.70672C2.48043 4.89425 2.73478 4.99961 3 4.99961H4.24L7 15.2596C7.0586 15.4778 7.18934 15.6697 7.37092 15.804C7.55251 15.9384 7.77424 16.0073 8 15.9996H17C17.1847 15.9991 17.3656 15.9474 17.5227 15.8503C17.6798 15.7532 17.8069 15.6145 17.89 15.4496L21.17 8.88961C21.3122 8.59163 21.3783 8.26308 21.3626 7.93329C21.3469 7.60351 21.2498 7.28274 21.08 6.99961Z" fill="currentColor" />
        <path d="M7.5 21C8.32843 21 9 20.3284 9 19.5C9 18.6716 8.32843 18 7.5 18C6.67157 18 6 18.6716 6 19.5C6 20.3284 6.67157 21 7.5 21Z" fill="currentColor" />
        <path d="M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z" fill="currentColor" />
    </svg>
);

export default ShoppingCartIcon;
