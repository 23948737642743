import React from 'react';
import classNames from 'classnames';
import times from 'lodash/times';
import {
    bool, object, number, string,
} from 'prop-types';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HEADER } from 'const/e2e/TEST_ATTRIBUTES';
import NavigationService from '../../../services/navigationService';
import { permissionsPresetSelector } from '../../../store/selectors/permissions';

function SubNavigation(props) {
    const {
        isDataLoaded,
        parentRoute,
        subRoutes: predefinedSubRoutes = null,
        productsList,
        navClassNames,
        navLinksClassNames,
    } = props;
    const { sections } = useSelector(({ company }) => company);
    const preset = useSelector(permissionsPresetSelector);
    const subRoutes = predefinedSubRoutes
        || NavigationService.getSubNavigationByMainRoute(parentRoute, sections, preset, productsList);
    const { t } = useTranslation();

    if (!isDataLoaded) {
        return (
            <Nav
                className="px-4 flex-nowrap"
            >
                {
                    times(subRoutes.length, (index) => (
                        <div
                            key={`skeleton-section-${index}`}
                            style={{
                                padding: '1.157rem 0.5rem',
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 3,
                                borderBottomColor: 'transparent',
                            }}
                        >
                            <Skeleton height={16} width={75} />
                        </div>
                    ))
                }
            </Nav>
        );
    }

    return (
        <Nav
            className={
                classNames(
                    'flex-nowrap',
                    'px-4',
                    'h-100',
                    navClassNames,
                )

            }
        >
            {
                subRoutes.map(({ directsTo, translation, exact }) => (
                    <Nav.Link
                        key={directsTo}
                        as={NavLink}
                        to={directsTo}
                        exact={exact}
                        className={classNames('text-nowrap', navLinksClassNames)}
                        data-testid={`${HEADER.subNavigationSelector}_${translation}`}
                        replace
                    >
                        {t(translation)}
                    </Nav.Link>
                ))
            }
        </Nav>
    );
}

SubNavigation.propTypes = {
    date: object,
    isDataLoaded: bool,
    parentRoute: string,
    shopId: number,
    withQuery: bool,
};

export default SubNavigation;
