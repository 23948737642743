import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import classNames from 'classnames';
import _ from 'lodash';
import IconButton from 'components/Common/IconButton/IconButton';
import { Tooltip } from 'components/Common/Tooltip';
import { Row } from 'components/Common/Row';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { useConfirmDialog } from '../../hooks/useConfirmDialog';

import {
    DownIcon, Pen2Icon, Trash3Icon, UpIcon,
} from '../Icon/Icon';
import { ConfirmationModal } from '../index';
import { Switch } from '../index';

import { formatPrice } from '../../helpers/formatters/formatPrice';
import { formatTime } from '../../helpers/services/formatTime';
import * as styles from './Service.module.scss';

import * as SERVICES_ACTIONS from '../../store/actions/services';

const DIRECTIONS = {
    down: 'down',
    up: 'up',
};

const DEBOUNCE_DELAY = 300;

const Service = ({
    service, t, disableUp, disableDown,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const removeConfirmDialog = useConfirmDialog({
        onConfirm: useCallback(() => {
            dispatch(SERVICES_ACTIONS.removeServicesItem({ serviceId: service.id }));
        }, [dispatch, service.id]),
    });

    const onChangeToggle = useCallback(() => {
        dispatch(SERVICES_ACTIONS.toggleServicesItemRandomSelect({ serviceId: service.id }));
    }, [dispatch, service.id]);

    const onMoveUpServiceClick = _.debounce(useCallback(() => {
        dispatch(SERVICES_ACTIONS.changePositionServicesItem({ serviceId: service.id, direction: DIRECTIONS.up }));
    }, [dispatch, service.id]), DEBOUNCE_DELAY);

    const onMoveDownServiceClick = _.debounce(useCallback(() => {
        dispatch(SERVICES_ACTIONS.changePositionServicesItem({ serviceId: service.id, direction: DIRECTIONS.down }));
    }, [dispatch, service.id]), DEBOUNCE_DELAY);

    const handleEditClick = useCallback(() => {
        history.push(`/services/services/edit/${service.id}`);
    }, [history, service.id]);

    return (
        <ListTableBodyRow>
            <ListTableBodyCell className="position-relative">
                <div className={classNames('d-flex flex-column justify-content-around', styles.sorting)}>
                    <button type="button" disabled={disableUp} className={styles.button} onClick={onMoveUpServiceClick}>
                        <UpIcon
                            stroke={disableUp ? '#F0F2F4' : undefined}
                            height="7px"
                            width="14px"
                        />
                    </button>
                    <button type="button" disabled={disableDown} className={styles.button} onClick={onMoveDownServiceClick}>
                        <DownIcon
                            stroke={disableDown ? '#F0F2F4' : undefined}
                            height="7px"
                            width="14px"
                        />
                    </button>
                </div>
            </ListTableBodyCell>
            <ListTableBodyCell>
                {service.name}
            </ListTableBodyCell>
            <ListTableBodyCell>{service.category?.name}</ListTableBodyCell>
            <ListTableBodyCell align="right" className="text-muted">
                {formatPrice.toEuroWithComma({ amount: service.price })}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Switch checked={service.randomlySelectEmployee} onChange={onChangeToggle} />
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">{formatTime({ time: service.length })}</ListTableBodyCell>
            <ListTableBodyCell className="text-muted">{formatTime({ time: service.bookingFrequency })}</ListTableBodyCell>
            <ListTableBodyCell className="text-muted">{t(`servicesRoute.bookableTypes.${service.bookableType}.label`)}</ListTableBodyCell>
            <ListTableBodyCell className="text-muted">{formatTime({ time: service.additionalTime })}</ListTableBodyCell>
            <ListTableBodyCell>
                <Row gap={8}>
                    <Tooltip
                        tooltip={t('servicesRoute.edit')}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            color="gray"
                            size={32}
                            onClick={handleEditClick}

                        >
                            <Pen2Icon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        tooltip={t('servicesRoute.archive')}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            color="gray"
                            size={32}
                            onClick={removeConfirmDialog.onShow}

                        >
                            <Trash3Icon />
                        </IconButton>
                    </Tooltip>
                </Row>
            </ListTableBodyCell>
            <ConfirmationModal
                isShow={removeConfirmDialog.visible}
                titleText={t('servicesRoute.dialogs.services.remove.title')}
                bodyText={t('servicesRoute.dialogs.services.remove.text')}
                deleteText={t('servicesRoute.dialogs.services.remove.confirm')}
                dismissText={t('servicesRoute.dialogs.services.remove.reject')}
                confirmAction={removeConfirmDialog.onConfirm}
                hide={removeConfirmDialog.onClose}
            />
        </ListTableBodyRow>
    );
};

export default Service;
