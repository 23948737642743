import React from 'react';

// Components
import { Modal } from 'react-bootstrap';

const FiltersModal = ({
    children, title, isShow, hide,
}) => (
    <Modal
        centered
        size="lg"
        show={isShow}
        onHide={hide}
    >
        <Modal.Header closeButton>
            <Modal.Title className="mb-0 font-weight-600">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">{children}</Modal.Body>
    </Modal>
);

export default FiltersModal;
