import { useLocation } from 'react-router';

const URL_SEPARATOR = '/';

export const useSubRoute = ({ withDynamic = false } = {}) => {
    const { pathname } = useLocation();

    const sliceIndex = withDynamic ? -2 : -1;

    return pathname
        .split(URL_SEPARATOR)
        .slice(sliceIndex);
};
