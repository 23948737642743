import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Panel } from 'components/Common/Panel';
import { Row } from 'components/Common/Row';

import { Column } from 'components/Common/Column';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    BarChart, Bar, ResponsiveContainer, XAxis, CartesianGrid,
} from 'recharts';
import classNames from 'classnames';
import { EmptyTable } from 'components/Common/NewTable/EmptyTable';
import { useMobile } from 'hooks/useMobile';
import { emptyFunc } from 'helpers/function/emptyFunc';
import Skeleton from 'react-loading-skeleton';
import { PeriodPicker } from 'components/Common/PeriodPicker';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './LogsOverviewInfo.module.scss';
import { LogsOverviewUserInfo } from '../LogsOverviewUserInfo';

const T_PREFIX = 'logs.info';

const PopularTimesType = {
    DAY: 'day',
    WEEK: 'week',
    YEAR: 'year',
};

const LogsOverviewInfo = ({
    statistics, onChangeDate, date, loading,
}) => {
    const isTablet = useMobile(1300);

    const { locale } = useSelector((state) => state.locales);
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);

    const popularTimes = useMemo(() => {
        if (!statistics?.popularTimes || loading) {
            return [];
        }
        const data = Object.entries(statistics.popularTimes);
        switch (date.period) {
        case PopularTimesType.DAY: {
            return data.map(([index, time]) => ({
                name: index.padStart(2, '0'),
                value: time,
            })) || [];
        }
        case PopularTimesType.WEEK: {
            return data.map(([index, time]) => ({
                name: moment().isoWeekday(Number(index)).locale(locale).format('ddd'),
                value: time,
            })) || [];
        }
        case PopularTimesType.YEAR: {
            return data.map(([index, time]) => ({
                name: moment(Number(index), 'MM').locale(locale).format('MMM'),
                value: time,
            })) || [];
        }
        }
    }, [date, statistics?.popularTimes, loading]);

    const lastEntries = useMemo(() => {
        if (loading) {
            return Array.from({ length: 3 }, () => ({}));
        }
        if (!statistics?.latestEntries) {
            return [];
        }
        return statistics.latestEntries.map((stat) => ({
            name: `${stat.client?.firstName || ''} ${stat.client?.lastName || ''}`.trim() || '-',
            avatarUrl: stat.client?.avatarUrl || '',
            clientId: stat.client?.id,
            gate: stat.gate.name,
            details: stat.details,
            allowed: stat.allowed,
            direction: stat.direction,
            createdAt: moment.unix(stat.createdAt).tz('Europe/Amsterdam').locale(locale).format('HH:mm:ss'),
        }));
    }, [statistics?.latestEntries, loading]);

    return (
        <Panel stretched>
            <Row gap={isTablet ? 16 : 12} stretched align="start" wrap={isTablet}>
                <Column stretched={isTablet ? 100 : 40}>
                    <span className={styles.title}>
                        {t(`${T_PREFIX}.lastEntries.title`)}
                    </span>
                    {lastEntries.length ? (
                        lastEntries.map((stat, index) => (
                            <div key={index} className={styles.userContainer}>
                                <LogsOverviewUserInfo stat={stat} loading={loading} />
                                {(index < (statistics?.latestEntries?.length || 0) - 1) && <div className={classNames(styles.divider, styles.horisontal)} />}
                            </div>
                        ))
                    ) : <EmptyTable />}
                </Column>
                <div className={classNames(styles.divider, isTablet && styles.horisontal)} />
                <Column stretched={isTablet ? 100 : 30}>
                    <span className={styles.title}>{t(`${T_PREFIX}.summary.title`)}</span>
                    <Row gap={12} stretched wrap>
                        <Panel padding={12} stretched color="secondary">
                            <div color={styles.labelGreen}>
                                <Column gap={4}>
                                    <span className={classNames(styles.summaryText, styles.labelGreen)}>
                                        {t(`${T_PREFIX}.summary.totalEntries`)}
                                    </span>
                                    <span className={classNames(styles.summaryNumber, styles.labelGreen)}>
                                        {loading ? <Skeleton height={24} width={50} /> : statistics?.summary?.totalEntries ?? 0}
                                    </span>
                                </Column>
                            </div>
                        </Panel>
                        <Panel padding={12} stretched color="secondary">
                            <Column gap={4}>
                                <span className={styles.summaryText}>
                                    {t(`${T_PREFIX}.summary.totalExits`)}
                                </span>
                                <span className={styles.summaryNumber}>
                                    {loading ? <Skeleton height={24} width={50} /> : statistics?.summary?.totalExits ?? 0}
                                </span>
                            </Column>
                        </Panel>
                        <Panel padding={12} stretched color="secondary">
                            <Column gap={4}>
                                <span className={classNames(styles.summaryText, styles.labelError)}>
                                    {t(`${T_PREFIX}.summary.errors`)}
                                </span>
                                <span className={classNames(styles.summaryNumber, styles.labelError)}>
                                    {loading ? <Skeleton height={24} width={50} /> : statistics?.summary?.errors ?? 0}
                                </span>
                            </Column>
                        </Panel>
                        <Panel padding={12} stretched color="secondary">
                            <Column gap={4}>
                                <span className={styles.summaryText}>
                                    {t(`${T_PREFIX}.summary.averageTimeInLocation`)}
                                </span>
                                <span className={styles.summaryNumber}>
                                    {loading ? <Skeleton height={24} width={100} /> : `${Math.floor((statistics?.summary?.averageTime ?? 0) / 60)}h ${Math.floor((statistics?.summary?.averageTime ?? 0) % 60)}min`}
                                </span>
                            </Column>
                        </Panel>
                        <Panel padding={12} stretched color="secondary">
                            <Column gap={4}>
                                <span className={styles.summaryText}>
                                    {t(`${T_PREFIX}.summary.successRate`)}
                                </span>
                                <span className={styles.summaryNumber}>
                                    {loading ? <Skeleton height={24} width={100} /> : `${Math.floor((statistics?.summary?.successRate ?? 0) * 100) / 100}%`}
                                </span>
                            </Column>
                        </Panel>
                    </Row>
                </Column>
                <Column stretched={isTablet ? 100 : 30}>
                    <Row gap={12} padding={0} stretched spaceBetween wrap>
                        <span className={styles.title}>
                            {t(`${T_PREFIX}.popularTimes.title`)}

                        </span>
                        <Row gap={12} padding={0}>
                            <PeriodPicker
                                initDateFrom={date.dateFrom}
                                initDateTo={date.dateTo}
                                initPeriod={date.period}
                                onChange={onChangeDate}
                                periods={[PopularTimesType.DAY, PopularTimesType.WEEK, PopularTimesType.YEAR]}
                                align="right"
                            />
                        </Row>
                    </Row>
                    <Panel gap={12} padding={12} stretched color="secondary">
                        <ResponsiveContainer width="100%" height={135}>
                            {loading ? <Skeleton height="100%" width="100%" />
                                : (
                                    <BarChart data={popularTimes}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <Bar dataKey="value" fill="#FFC100" />
                                    </BarChart>
                                )}
                        </ResponsiveContainer>
                    </Panel>
                </Column>
            </Row>
        </Panel>
    );
};

LogsOverviewInfo.propTypes = {
    statistics: PropTypes.object,
    onChangeDate: PropTypes.func,
    onChangePeriod: PropTypes.func,
    period: PropTypes.oneOf(Object.values(PopularTimesType)),
    date: PropTypes.object,
    loading: PropTypes.bool,
};

LogsOverviewInfo.defaultProps = {
    statistics: {},
    onChangeDate: emptyFunc,
    onChangePeriod: emptyFunc,
    period: PopularTimesType.DAY,
    date: moment(),
    loading: false,
};

export default LogsOverviewInfo;
