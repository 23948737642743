import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import axios from 'services/axios';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { rejectPromiseAction, resolvePromiseAction } from '@adobe/redux-saga-promise';
import * as CLIENTS_ACTIONS from '../../../actions/clients/tables/notes';
import * as CLIENTS_SELECTORS from '../../../selectors/clients';

import * as TOAST_ACTIONS from '../../../actions/toast';

import { HTTP_METHOD } from '../../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_CLIENT_NOTES_CATEGORIES_ROUTE,
    API_ADMIN_CLIENT_NOTES_CATEGORY_ID_ROUTE,
    API_ADMIN_CLIENT_NOTES_CATEGORY_ROUTE,
    API_ADMIN_CLIENT_NOTES_CLIENT_ID_ROUTE,
    API_ADMIN_CLIENT_NOTES_CLIENT_NOTE_ID_ROUTE,
} from '../../../../const/API_URL';

function* getClientNotes(action) {
    const { payload } = action;
    const { clientId, categories } = payload;

    const { page } = yield select(CLIENTS_SELECTORS.clientNotesSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(CLIENTS_ACTIONS.setClientNotesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_NOTES_CLIENT_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            params: {
                page,
                categories,
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
            } = result;
            const notes = items.map(camelize);
            yield put(CLIENTS_ACTIONS.setClientNotes({
                notes,
                itemsCount,
                pagesCount,
            }));
            yield call(resolvePromiseAction, action, data);
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.getNotes.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action);
    } finally {
        yield put(CLIENTS_ACTIONS.setClientNotesLoading({ loading: false }));
    }
}

function* editClientNotesItem(action) {
    const { payload } = action;
    const { noteId, note } = payload;

    const { item: { id: clientId } } = yield select(CLIENTS_SELECTORS.clientItemSelector);
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_CLIENT_NOTES_CLIENT_NOTE_ID_ROUTE({ clientId, noteId }),
            headers: defaultHeaders,
            data: note,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield call(resolvePromiseAction, action, camelize(data));
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'notes.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action);
    }
}

function* deleteClientNotesItem(action) {
    const { payload } = action;
    const { noteId } = payload;

    const { item: { id: clientId } } = yield select(CLIENTS_SELECTORS.clientItemSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'DELETE',
            url: API_ADMIN_CLIENT_NOTES_CLIENT_NOTE_ID_ROUTE({ clientId, noteId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'notes.delete.success',
                appearance: 'success',
            }));
            const { selectedCategoriesIds } = yield select(CLIENTS_SELECTORS.clientNotesSelector);
            yield put(CLIENTS_ACTIONS.getClientNotes({ clientId, categories: selectedCategoriesIds }));
            yield call(resolvePromiseAction, action, data);
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'notes.delete.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action);
    }
}

function* addClientNotesItem(action) {
    const { payload } = action;
    const { note } = payload;

    const { item: { id: clientId } } = yield select(CLIENTS_SELECTORS.clientItemSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_CLIENT_NOTES_CLIENT_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            data: note,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            // yield put(TOAST_ACTIONS.showToast({
            //     message: 'notes.add.success',
            //     appearance: 'success',
            // }));
            const { selectedCategoriesIds } = yield select(CLIENTS_SELECTORS.clientNotesSelector);
            yield put(CLIENTS_ACTIONS.getClientNotes({ clientId, categories: selectedCategoriesIds }));
            yield call(resolvePromiseAction, action, data);
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'notes.add.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action);
    }
}

function* getClientNotesCategories(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_NOTES_CATEGORIES_ROUTE,
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const categories = result.map(camelize);
            yield call(resolvePromiseAction, action, { categories });
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.getNotesCategories.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action);
    }
}

function* addClientNotesCategory(action) {
    const { payload } = action;
    const { category } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_CLIENT_NOTES_CATEGORY_ROUTE,
            headers: defaultHeaders,
            data: category,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            /* yield put(TOAST_ACTIONS.showToast({
                message: 'notesCategories.add.success',
                appearance: 'success',
            })); */
            yield put(CLIENTS_ACTIONS.getClientNotesCategories());
            yield call(resolvePromiseAction, action, data);
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'notesCategories.add.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action);
    }
}

function* deleteClientNotesCategory(action) {
    const { payload } = action;
    const { categoryId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'DELETE',
            url: API_ADMIN_CLIENT_NOTES_CATEGORY_ID_ROUTE({ categoryId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            /* yield put(TOAST_ACTIONS.showToast({
                message: 'notesCategories.delete.success',
                appearance: 'success',
            })); */
            yield call(resolvePromiseAction, action, { categoryId });
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'notesCategories.delete.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action);
    }
}

export const clientNotesSaga = [
    takeLatest(CLIENTS_ACTIONS.getClientNotes, getClientNotes),
    takeLatest(CLIENTS_ACTIONS.editClientNotesItem, editClientNotesItem),
    takeLatest(CLIENTS_ACTIONS.deleteClientNotesItem, deleteClientNotesItem),
    takeLatest(CLIENTS_ACTIONS.addClientNotesItem, addClientNotesItem),
    takeLatest(CLIENTS_ACTIONS.getClientNotesCategories, getClientNotesCategories),
    takeLatest(CLIENTS_ACTIONS.addClientNotesCategory, addClientNotesCategory),
    takeLatest(CLIENTS_ACTIONS.deleteClientNotesCategory, deleteClientNotesCategory),
];
