import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './scenes';

function Access() {
    return (
        <Switch>
            {routes.map(({ component, exact, path }) => (
                <Route key={path} path={path} exact={exact} component={component} />
            ))}
            <Redirect to={routes[0].path} />
        </Switch>
    );
}

export default Access;
