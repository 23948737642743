import React from 'react';
import { Container } from 'react-bootstrap';

import { ClientReviewsTableHeader } from 'components/clients/ClientPage/ClientTables/ClientReviews/ClientReviewsTableHeader';
import { ClientReviewsTableRow } from 'components/clients/ClientPage/ClientTables/ClientReviews/ClientReviewsTableRow';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';

function ClientReviewsTable() {
    return (
        <Container
            fluid
            className="m-0 p-0 my-5 d-flex flex-column flex-grow-1"
        >
            <Table>
                <TableHeader>
                    <ClientReviewsTableHeader />
                </TableHeader>
                <TableBody>
                    <ListTableBodyRow>
                        <ClientReviewsTableRow />
                    </ListTableBodyRow>
                </TableBody>
            </Table>
        </Container>
    );
}

export default ClientReviewsTable;
