import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const getObjects = () => ({
    type: actionTypes.GET_OBJECTS,
});

export const getObjectsSucceeded = (objects) => ({
    type: actionTypes.GET_OBJECTS_SUCCEEDED,
    objects,
});

export const getObjectsFailed = () => ({
    type: actionTypes.GET_OBJECTS_FAILED,
});

export const setObjectFilter = (id, visible) => ({
    type: actionTypes.SET_OBJECT_FILTER,
    id,
    visible,
});

export const addObject = createPromiseAction(actionTypes.ADD_OBJECT);

export const addObjectSucceeded = (object) => ({
    type: actionTypes.ADD_OBJECT_SUCCEEDED,
    object,
});

export const addObjectFailed = () => ({
    type: actionTypes.ADD_OBJECT_FAILED,
});

export const editObject = createPromiseAction(actionTypes.EDIT_OBJECT);
export const editObjectSucceeded = (payload) => ({
    type: actionTypes.EDIT_OBJECT_SUCCEEDED,
    payload,
});
export const editObjectFailed = () => ({
    type: actionTypes.EDIT_OBJECT_FAILED,
});

export const deleteObject = createPromiseAction(actionTypes.DELETE_OBJECT);
export const deleteObjectSucceeded = (id) => ({
    type: actionTypes.DELETE_OBJECT_SUCCEEDED,
    payload: id,
});
export const deleteObjectFailed = () => ({ type: actionTypes.DELETE_OBJECT_FAILED });
