import { createSelector } from 'reselect';

export const eventsSelector = (state) => state.events;

export const eventsPageSelector = createSelector(
    eventsSelector,
    (state) => ({
        loadingEvents: state.loadingEvents,
        events: state.events,
        page: state.page,
        pagesCount: state.pagesCount,
        itemsCount: state.itemsCount,
        showAll: state.showAll,
    }),
);
