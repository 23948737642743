import * as actionTypes from '../actions/auth/actionTypes';

const initialState = {
    isSigningUp: false,
    accessToken: null,
    refreshToken: null,
    tokenType: null,
    expires: null,
    remember: true,
    isRequestingPasswordReset: false,
    userRole: null,
    employeeType: null,
    resourceId: null,
    failedActions: [],
};

export const auth = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.SIGN_IN_SUCCEEDED: {
        const {
            accessToken,
            tokenType,
            refreshToken,
            expires,
            remember,
            userRole,
            employeeType,
            resourceId,
        } = payload;

        return {
            ...state,
            isSigningIn: false,
            accessToken,
            refreshToken,
            tokenType,
            expires,
            remember,
            userRole,
            employeeType,
            resourceId,
        };
    }
    case actionTypes.REQUEST_PASSWORD_RESET:
        return {
            ...state,
            isRequestingPasswordReset: true,
        };
    case actionTypes.REQUEST_PASSWORD_RESET_SUCCEEDED:
    case actionTypes.REQUEST_PASSWORD_RESET_FAILED:
        return {
            ...state,
            isRequestingPasswordReset: false,
        };
    case actionTypes.REFRESH_TOKEN_RESOLVED: {
        const {
            accessToken, tokenType, refreshToken, expires, userRole, employeeType,
        } = payload.payload;
        return {
            ...state,
            accessToken,
            tokenType,
            refreshToken,
            expires,
            userRole,
            employeeType,
        };
    }

    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    case actionTypes.QUEUE_FAILED_ACTION: {
        const { action } = payload.payload;
        return {
            ...state,
            failedActions: [
                ...state.failedActions,
                action,
            ],
        };
    }
    case actionTypes.EXECUTE_FAILED_ACTIONS_SUCCEEDED: {
        return {
            ...state,
            failedActions: [],
        };
    }
    default:
        return state;
    }
};

export default auth;
