import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Container from 'components/Layout/Container/Container';
import { Table } from 'components/Common/NewTable/Table';
import { Content } from 'components/Common/Content';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { BundlesListTableColumns } from 'components/bundles/BundlesListTableColumns';
import { BundlesListTableRow } from 'components/bundles/BundlesListTableRow';
import { useDialog } from '../../../../../../hooks/useDialog';
import { useConfirmDialog } from '../../../../../../hooks/useConfirmDialog';

import { BundlesListHeader } from '../../../../../../components/bundles/BundlesListHeader';
import { BundlesListFooter } from '../../../../../../components/bundles/BundlesListFooter';
import { BundleDetailsDialog } from '../../../../../../components/bundles/BundleDetailsDialog';
import { ConfirmationModal } from '../../../../../../components';

import { BUNDLES_EDIT_ID_PAGE } from '../../../../../../const/CLIENT_URL';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as BUNDLES_ACTIONS from '../../../../../../store/actions/bundles';

import * as BUNDLES_SELECTORS from '../../../../../../store/selectors/bundles';

const ITEMS_PER_PAGE = 10;

const COLUMNS_COUNT = 7;

const T_PREFIX = 'list';
const T_DIALOGS = `${T_PREFIX}.dialogs`;

const BundlesList = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const history = useHistory();

    const dispatch = useDispatch();
    const {
        page,
        items: bundles,
        search,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        hideArchived,
    } = useSelector(BUNDLES_SELECTORS.bundlesListSelector);

    const detailsDialog = useDialog(false);

    const archiveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: useCallback((bundleId) => {
            dispatch(BUNDLES_ACTIONS.archiveBundlesItem({ bundleId }));
        }, [dispatch]),
    });

    const handleShowInfo = useCallback(({ bundleId }) => {
        detailsDialog.onSetShow(bundleId);
    }, [detailsDialog.onSetShow]);

    const handleArchive = useCallback(({ bundleId }) => {
        archiveConfirmDialog.onSetData(bundleId);
        archiveConfirmDialog.onShow();
    }, [archiveConfirmDialog.onSetData, archiveConfirmDialog.onShow]);

    const handleShowAll = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPage({ page: 0 }));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageFirst());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setBundlesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(BUNDLES_ACTIONS.setBundlesPage({ page: nextPage }));
    }, [dispatch]);

    const handleSearch = useCallback(({ search: nextSearch }) => {
        dispatch(BUNDLES_ACTIONS.setBundlesSearch({ search: nextSearch }));
    }, [dispatch]);

    const handleEdit = useCallback(({ bundleId }) => {
        history.push(BUNDLES_EDIT_ID_PAGE({ bundleId }));
    }, [history.push]);

    const handleToggleStatus = useCallback(({ bundleId }) => {
        dispatch(BUNDLES_ACTIONS.toggleBundlesItemStatus({ bundleId }));
    }, [dispatch]);

    const bundlesList = useMemo(() => (
        (!showAll && bundles.length > ITEMS_PER_PAGE)
            ? bundles.slice(0, Math.min(ITEMS_PER_PAGE, bundles.length))
            : bundles
    ), [showAll, bundles]);

    // const handleToggleHideArchived = useCallback(() => {
    //     dispatch(BUNDLES_ACTIONS.toggleBundlesHideArchived());
    // }, [dispatch]);

    useEffect(() => {
        dispatch(BUNDLES_ACTIONS.getBundles());
    }, [
        dispatch,
        page,
        search,
        hideArchived,
    ]);

    return (
        <>
            <BundlesListHeader
                search={search}
                onSearch={handleSearch}
            />
            <Container>
                <Content loading={loading}>
                    <Table>
                        <TableHeader>
                            <BundlesListTableColumns />
                        </TableHeader>
                        <TableBody>
                            {bundlesList.map((bundle) => (
                                <BundlesListTableRow
                                    key={bundle?.id}
                                    bundle={bundle}
                                    onEdit={handleEdit}
                                    onClick={handleShowInfo}
                                    onArchive={handleArchive}
                                    onToggleStatus={handleToggleStatus}
                                />
                            ))}
                        </TableBody>
                        <ListTableFooter
                            page={page}
                            showAll={showAll}
                            pagesCount={pagesCount}
                            itemsCount={itemsCount}
                            columnsCount={COLUMNS_COUNT}
                            itemsPerPage={ITEMS_PER_PAGE}
                            onShowAll={handleShowAll}
                            onPrevPage={handlePrevPage}
                            onNextPage={handleNextPage}
                            onLastPage={handleLastPage}
                            onFirstPage={handleFirstPage}
                            onShowPages={handleShowPages}
                            onChangePage={handleChangePage}
                        />
                    </Table>
                    <BundlesListFooter
                        search={search}
                        onSearch={handleSearch}
                    />
                </Content>
                <BundleDetailsDialog
                    visible={Boolean(detailsDialog.visible)}
                    bundleId={detailsDialog.visible}
                    onClose={detailsDialog.onClose}
                />
                <ConfirmationModal
                    isShow={archiveConfirmDialog.visible}
                    titleText={t(`${T_DIALOGS}.archive.title`)}
                    bodyText={t(`${T_DIALOGS}.archive.text`)}
                    deleteText={t(`${T_DIALOGS}.archive.actions.confirm`)}
                    dismissText={t(`${T_DIALOGS}.archive.actions.reject`)}
                    confirmAction={archiveConfirmDialog.onConfirm}
                    hide={archiveConfirmDialog.onReject}
                />
            </Container>
        </>
    );
};

export default BundlesList;
