import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import camelize from 'camelize';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import * as actions from '../../actions';
import axios from '../../../services/axios';
import * as TOAST_ACTIONS from '../../actions/toast';

import * as ACCESS_SELECTORS from '../../selectors/access';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import {
    API_ADMIN_COMPANY_GATES_LOGS_ROUTE,
} from '../../../const/API_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';

function* getAccessLogs(action) {
    const { gateId } = action.payload || {};
    const {
        page, search, date, filter,
    } = yield select(ACCESS_SELECTORS.accessLogsListSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_GATES_LOGS_ROUTE,
            headers: defaultHeaders,
            params: {
                page,
                gateId: gateId || undefined,
                search,
                startDate: date.dateFrom?.unix(),
                endDate: date.dateTo?.unix(),
                filter,
            },
        });

        const { data } = res;
        const { code, result } = data;
        const logs = camelize(result);
        if (code === HTTP_STATUS_CODE.OK) {
            yield call(resolvePromiseAction, action, logs);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'access.logs.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }

        yield call(handleFail);
    }
}

export const logsListSaga = [
    takeLatest(actions.getAccessLogs, getAccessLogs),
];
