import InvoicesList from './invoicesList';
import AddNewInvoice from './addNewInvoice';
import EditInvoice from './editInvoice';

const routes = [
    {
        component: InvoicesList,
        exact: true,
        path: '/financials/invoices/list',
    },
    {
        component: AddNewInvoice,
        exact: true,
        path: '/financials/invoices/add',
    },
    {
        component: EditInvoice,
        exact: true,
        path: '/financials/invoices/edit/:invoiceId',
    },

];

export default routes;
