import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isInvalid, getError } from 'services/validationService';
import TextButton from 'components/Common/TextButton/TextButton';
import { WindowedMenuList } from 'react-windowed-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AddClientDialog } from 'components/clients/AddClientDialog';
import { useDialog } from 'hooks/useDialog';
import { useDispatch, useSelector } from 'react-redux';
import * as COMPANY_SELECTORS from 'store/selectors/company';
import { getCompanyClients } from 'store/actions';
import * as CLIENTS_SELECTORS from 'store/selectors/clients';
import * as CLIENTS_ACTIONS from 'store/actions/clients';
import { generateRandomString } from 'helpers/string/generateRandomString';

import {
    styles, dangerTheme, theme,
} from 'styles/select';
import { getFullName } from 'helpers/services/getFullName';
import classNames from 'classnames';

function ClientsSelect({
    formik, title, invalid, error,
}) {
    const [isNecessarySetNewClientToValue, setIsNecessarySetNewClientToValue] = useState(false);
    const [clientNameAsyncSelectKey, setClientNameAsyncSelectKey] = useState(generateRandomString());
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        values,
        errors,
        touched,
        setFieldValue,
    } = formik;
    const addNewClientDialog = useDialog(false);
    const onClientNameLoadOptionForce = useCallback(() => {
        setClientNameAsyncSelectKey(generateRandomString());
    }, []);

    const { item: client, loading: isClientLoading } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const isRequiredClientAddress = useSelector(COMPANY_SELECTORS.companyIsRequiredClientAddressSelector);

    const onAddNewClientSuccess = useCallback(({ data }) => {
        const newClientId = data.id;
        onClientNameLoadOptionForce();
        setIsNecessarySetNewClientToValue(true);
        dispatch(CLIENTS_ACTIONS.getClientItem({ clientId: newClientId }));
    }, []);

    useEffect(() => {
        if (!isClientLoading && isNecessarySetNewClientToValue) {
            setIsNecessarySetNewClientToValue(false);
            const formatClient = {
                id: client.id,
                name: client.fullName.firstName,
                lastName: client.fullName.lastName,
                contactNumber: client.contactInfo.phone,
                email: client.contactInfo.email,
                note: client.note,
            };
            onClientNameChange(formatClient);
        }
    }, [isClientLoading]);

    const onClientNameLoadOption = useCallback((query) => (
        new Promise((resolve, reject) => {
            dispatch(getCompanyClients({ query }))
                .then((clients) => resolve(clients.map((client) => ({
                    ...client,
                    value: client.id,
                    label: getFullName({ client }),
                    contactNumber: client.contactNumber,
                }))))
                .catch(reject);
        })
    ), []);

    const onClientNameCreateOption = useCallback((name) => {
        setFieldValue('client.clientId', '');
        setFieldValue('client.isNew', true);
        setFieldValue('client.clientName', name);
    }, []);

    const onClientNameChange = useCallback((selected) => {
        if (!selected) {
            setFieldValue('client', null);
            return;
        }
        // eslint-disable-next-line no-underscore-dangle
        if (selected.__isNew__) {
            setFieldValue('client.clientId', '');
            setFieldValue('client.isNew', true);
            setFieldValue('client.clientName', selected.label);
            return;
        }
        const {
            id, name, lastName, email, contactNumber, note,
        } = selected;
        setFieldValue('client.isNew', false);
        setFieldValue('client.clientId', id || '');
        setFieldValue('client.clientName', name || '');
        setFieldValue('client.clientLastName', lastName);
        setFieldValue('client.clientEmail', email || '');
        setFieldValue('client.clientPhone', contactNumber || '');
        setFieldValue('client.clientNote', note || '');
    }, []);

    const getClientNameFormatOptionLabel = useCallback((
        { value, label, contactNumber },
        { selectValue },
    ) => (
        <span className="d-flex">
            <span className="flex-grow-1">
                {label}
            </span>
            <span
                className={classNames(
                    'font-italic text-right',
                    { 'text-muted': selectValue.every((option) => option.value !== value) },
                )}
            >
                {contactNumber}
            </span>
        </span>
    ), []);

    const onClickAddNewClient = useCallback((event) => {
        event.preventDefault();
        addNewClientDialog.onShow();
    }, []);

    const onClientNameCreateLabel = useCallback((name) => (
        <TextButton
            onClick={onClickAddNewClient}
            after={name}
            color="yellow"
            afterColor="black"
        >
            {`+ ${t('addBookingModal.addNewClient')} `}
        </TextButton>
    ), [t]);

    const clientNameValue = useMemo(() => (
        {
            value: values.client?.clientId,
            label: `${values.client?.clientName || ''} ${values.client?.clientLastName || ''}`,
        }
    ), [
        values.client?.clientName,
        values.client?.clientEmail,
        values.client?.clientPhone,
        values.client?.clientLastName,
    ]);

    return (
        <>
            <Form.Group>
                <Form.Label htmlFor="clientName" className="d-flex justify-content-between">
                    {title ?? t('addBookingModal.client')}
                    <TextButton
                        onClick={onClickAddNewClient}
                    >
                        {`+ ${t('addBookingModal.addNewClient')}`}
                    </TextButton>
                </Form.Label>

                <AsyncCreatableSelect
                    components={{ MenuList: WindowedMenuList }}
                    id="clientName"
                    key={clientNameAsyncSelectKey}
                    isClearable
                    isSearchable
                    name="client.name"
                    styles={styles}
                    theme={invalid ?? isInvalid('client', errors, touched) ? dangerTheme : theme}
                    value={clientNameValue}
                    onCreateOption={onClientNameCreateOption}
                    onChange={onClientNameChange}
                    formatOptionLabel={getClientNameFormatOptionLabel}
                    loadOptions={onClientNameLoadOption}
                    formatCreateLabel={onClientNameCreateLabel}
                    cacheOptions
                    defaultOptions
                />
                <Form.Control.Feedback
                    type="invalid"
                    className={(invalid ?? isInvalid('client', errors, touched)) && 'd-flex'}
                >
                    {error ?? getError('client', errors)}
                </Form.Control.Feedback>
            </Form.Group>
            <AddClientDialog
                visible={addNewClientDialog.visible}
                onClose={addNewClientDialog.onClose}
                onSuccess={onAddNewClientSuccess}
                isRequiredClientAddress={isRequiredClientAddress}
            />
        </>
    );
}

export default ClientsSelect;
