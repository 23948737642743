import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import SearchInput2 from 'components/Common/SearchInput2';

import { useMobile } from 'hooks/useMobile';
import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { BUNDLES_ADD_PAGE } from '../../../const/CLIENT_URL';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.header';

const BundlesListHeader = (props) => {
    const {
        search,
        onSearch,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const isMobile = useMobile();

    const handleSearch = useCallback((nextSearch) => {
        onSearch({ search: nextSearch });
    }, [onSearch]);

    return (
        <TextSubHeader
            text={t(`${T_PREFIX}.title`)}
            after={(
                !isMobile && (
                    <SearchInput2
                        searchValue={search}
                        onSearch={handleSearch}
                    />
                )
            )}
            rightActions={(
                !isMobile && (
                    <Button
                        href={BUNDLES_ADD_PAGE}
                    >
                        {t(`${T_PREFIX}.actions.add`)}
                    </Button>
                )
            )}
        />
    );
};

BundlesListHeader.propTypes = {
    search: PropTypes.string,
    onSearch: PropTypes.func,
};

BundlesListHeader.defaultProps = {
    search: '',
    onSearch: emptyFunc,
};

export default BundlesListHeader;
