import { WORKER_TYPES } from '../const/WORKER_TYPES';
import sentry from '../services/sentry';

const WORKERS = {
    [WORKER_TYPES.SOUND_WORKER]: '/soundWorker.js',
};

const COMMANDS = {
    SKIP_WAITING: 'SKIP_WAITING',
};

class WorkersController {
    constructor() {
        this.handlers = {};
        this.workers = {
            [WORKER_TYPES.SOUND_WORKER]: null,
        };
    }

    async registerWorker(workerType, scope = '/') {
        if (!navigator?.serviceWorker) {
            sentry.warn("This browser don't support Service Workers.");
            return;
        }

        if (this.workers[workerType]) {
            return;
        }

        try {
            const worker = await navigator.serviceWorker.register(
                WORKERS[workerType],
                { scope },
            );
            this.workers[workerType] = worker.active;
            worker.addEventListener('updatefound', () => {
                if (worker.installing) {
                    worker.installing.addEventListener('statechange', () => {
                        if (worker.waiting) {
                            if (navigator.serviceWorker.controller) {
                                worker.waiting.postMessage({
                                    command: COMMANDS.SKIP_WAITING,
                                });
                            }
                        }
                    });
                }
            });
        } catch (e) {
            sentry.error(e);
        }
    }

    addMessagesListener() {
        if (!navigator?.serviceWorker) {
            sentry.warn("This browser don't support Service Workers.");
            return;
        }
        navigator.serviceWorker.addEventListener('message', this.onMessage.bind(this));
    }

    removeMessagesListener() {
        if (!navigator?.serviceWorker) {
            sentry.warn("This browser don't support Service Workers.");
            return;
        }
        navigator.serviceWorker.removeEventListener('message', this.onMessage.bind(this));
    }

    onMessage(event) {
        this.handlers?.[event.data.command]?.(event.data.data);
    }

    async emit(workerType, message) {
        this.workers?.[workerType]?.postMessage(message);
    }

    on(workerType, event, cb) {
        this.handlers = {
            ...this.handlers,
            [event]: cb,
        };
    }

    off(workerType, event) {
        delete this.handlers[event];
    }
}

export default new WorkersController();
