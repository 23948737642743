import { combineReducers } from 'redux';

import { servicesListReducer } from './servicesList';
import { companyServicesReducer } from './companyServices';
import { serviceItemReducer } from './serviceItem';

export const servicesReducer = combineReducers({
    services: servicesListReducer,
    companyServices: companyServicesReducer,
    service: serviceItemReducer,
});
