import { createSelector } from 'reselect';

export const authSelector = (state) => state.auth;

export const roleSelector = createSelector(
    authSelector,
    (auth) => ({ role: auth.userRole }),
);

export const tokensSelector = createSelector(
    authSelector,
    (auth) => ({
        accessToken: auth.accessToken,
        refreshToken: auth.refreshToken,
    }),
);

export const isAuthedSelector = createSelector(
    tokensSelector,
    ({ accessToken, refreshToken }) => Boolean(accessToken && refreshToken),
);
