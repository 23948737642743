import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import * as actions from 'store/actions/bookings';

// Components
import { Timeline, BookingsDayList, BookingsListModal } from 'components';

const Week = ({
    productType = 'basic',
    globalDate,
    week,
    year,
    onBookingClick,
    clickedBookingId,
}) => {
    const isShopLoaded = useSelector((state) => state.shop.loaded);
    const dispatch = useDispatch();

    const [isModalVisible, setModalVisible] = useState(false);
    const [clickedHour, setClickedHour] = useState(null);
    const [granularity, setGranularity] = useState(null);

    useEffect(() => {
        if (!isShopLoaded) {
            return;
        }

        dispatch(
            actions.getBookings(
                globalDate.clone().startOf('isoWeek').unix(),
                globalDate.clone().endOf('isoWeek').unix(),
                productType === 'object' ? 'basic' : productType,
            ),
        );
    }, [isShopLoaded, week, year]);

    return (
        <>
            <Timeline
                dataType={`${productType}s`}
                className="mt-4 px-0 d-none d-lg-grid"
                globalDate={globalDate.clone()}
                week={week}
                year={year}
                view="week"
                onClick={(hour) => {
                    setGranularity('hour');
                    setClickedHour(hour);
                    setModalVisible(true);
                }}
            />

            <BookingsDayList
                className="mt-4 px-0 d-flex d-lg-none"
                globalDate={globalDate.clone()}
                onClick={(hour) => {
                    if (!hour) return;
                    setGranularity('day');
                    setClickedHour(hour);
                    setModalVisible(true);
                }}
                dataType={`${productType}s`}
            />

            <BookingsListModal
                hour={clickedHour}
                show={isModalVisible && !clickedBookingId}
                granularity={granularity}
                onBookingClick={onBookingClick}
                onHide={() => setModalVisible(false)}
                dataType={`${productType}s`}
            />
        </>
    );
};

export default Week;
