import React, { useEffect, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ClientBookingsTableHeader } from 'components/clients/ClientPage/ClientTables/ClientBookings/ClientBookingsTableHeader';
import { ClientBookingsTableRow } from 'components/clients/ClientPage/ClientTables/ClientBookings/ClientBookingsTableRow';
import { MonthYearCalendar } from 'components/MonthYearCalendar/index';

import { Content } from 'components/Common/Content';

import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { EmptyTable } from 'components/Common/NewTable/EmptyTable';
import * as styles from './page.module.scss';
import * as CLIENTS_SELECTORS from '../../../../../../store/selectors/clients';
import * as CLIENTS_ACTIONS from '../../../../../../store/actions/clients';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 10;

function ClientBookingsTable() {
    const { clientId } = useParams();

    const dispatch = useDispatch();

    const {
        page,
        items: bookings,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        yearFilter,
        monthFilter,
        periodFilter,
    } = useSelector(CLIENTS_SELECTORS.clientBookingsSelector);

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsPage({ page: nextPage }));
    }, [dispatch]);

    const handleDateChange = useCallback((data) => {
        dispatch(CLIENTS_ACTIONS.setClientBookingsFilters(data));
    }, [dispatch]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClientBookings({ clientId }));
    }, [dispatch, page, monthFilter, yearFilter, periodFilter]);

    return (
        <Container
            fluid
            className="m-0 p-0 mb-5 d-flex flex-column flex-grow-1"
        >
            <MonthYearCalendar
                year={yearFilter}
                month={monthFilter}
                period={periodFilter}
                onChange={handleDateChange}
            />

            <Content loading={loading}>
                { itemsCount
                    ? (
                        <Table>
                            <TableHeader>
                                <ClientBookingsTableHeader />
                            </TableHeader>
                            <TableBody>
                                {bookings.map((booking) => (
                                    <ClientBookingsTableRow key={booking.id} booking={booking} />
                                ))}
                            </TableBody>
                            <ListTableFooter
                                page={page}
                                showAll={showAll}
                                itemsCount={itemsCount}
                                pagesCount={pagesCount}
                                columnsCount={COLUMNS_COUNT}
                                itemsPerPage={ITEMS_PER_PAGE}
                                onShowAll={handleShowAll}
                                onPrevPage={handlePrevPage}
                                onNextPage={handleNextPage}
                                onLastPage={handleLastPage}
                                onFirstPage={handleFirstPage}
                                onShowPages={handleShowPages}
                                onChangePage={handleChangePage}
                            />
                        </Table>
                    )
                    : <EmptyTable className={styles.emptyTable} />}
            </Content>
        </Container>
    );
}

export default ClientBookingsTable;
