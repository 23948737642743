import i18n from 'services/i18n';

const UNBREAKABLE_SPACE = '\xa0';

export const formatTime = ({ time, forceMany }) => {
    const hrs = i18n.t('time.hrs');
    const hr = i18n.t('time.hr');
    const mins = i18n.t('time.mins');
    const min = i18n.t('time.min');
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const hoursLabel = hours > 1 || forceMany ? hrs : hr;
    const minutesLabel = !hours || minutes > 1 || forceMany ? mins : min;
    const hoursString = hours ? `${hours}${UNBREAKABLE_SPACE}${hoursLabel}` : '';
    const minutesString = !hours || minutes ? `${minutes}${UNBREAKABLE_SPACE}${minutesLabel}` : '';
    return `${hoursString} ${minutesString}`;
};
