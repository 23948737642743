import ServicesList from './servicesList';
import AddService from './addService';

const routes = [
    {
        component: ServicesList,
        exact: true,
        path: '/services/services/list',
    },
    {
        component: AddService,
        exact: true,
        path: '/services/services/add-service',
    },
    {
        component: AddService,
        exact: true,
        path: '/services/services/edit/:id',
    },
];

export default routes;
