import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { MESSAGES_ADD_PAGE } from 'const/CLIENT_URL';

import { Button } from 'components/Common/Button';
import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './AddMessageDialog.module.scss';

const T_PREFIX = 'list.dialogs.addMessage';

const AddMessageDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const history = useHistory();

    const {
        selectedClients,
        visible,
        onClose,
        onDeselectAll,
    } = props;

    const countSelectedClients = useMemo(
        () => Object.values(selectedClients).length,
        [selectedClients],
    );

    const isButtonDisabled = countSelectedClients === 0;

    const handleSend = useCallback(() => {
        const selectedClientsIds = Object.keys(selectedClients).map((id) => Number(id));

        onClose();
        onDeselectAll();

        history.push(MESSAGES_ADD_PAGE, { selectedClientsIds });
    }, [selectedClients, onClose, onDeselectAll, history]);

    return (
        <Dialog
            visible={visible}
            onClose={onClose}
        >
            <DialogHeader>
                <DialogTitle>
                    {t(`${T_PREFIX}.title`)}
                </DialogTitle>
            </DialogHeader>

            <DialogBody>
                <p className={styles.grayField}>
                    {t(`${T_PREFIX}.selectedClients`, { clients: countSelectedClients })}
                </p>
            </DialogBody>

            <DialogFooter>
                <Button
                    type="submit"
                    onClick={handleSend}
                    disabled={isButtonDisabled}
                >
                    {t(`${T_PREFIX}.actions.add`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

AddMessageDialog.propTypes = {
    selectedClients: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onDeselectAll: PropTypes.func,
};

AddMessageDialog.defaultProps = {
    selectedClients: {},
    visible: false,
    onClose: emptyFunc,
    onDeselectAll: emptyFunc,
};

export default AddMessageDialog;
