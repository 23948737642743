// extracted by mini-css-extract-plugin
var _1 = "ClearingsPageHeader_balanceIcon__cqrSo";
var _2 = "ClearingsPageHeader_cellCompanyInfoPanel__1KPSy";
var _3 = "ClearingsPageHeader_cellCompanyInfoPanelItemsWrapper__go3rh";
var _4 = "ClearingsPageHeader_companyInfoPanel__yW9VP";
var _5 = "ClearingsPageHeader_darkBadge__UX+6E";
var _6 = "ClearingsPageHeader_date__bZkyG";
var _7 = "ClearingsPageHeader_divider__e7Bhv";
var _8 = "ClearingsPageHeader_grayColor__2AeYb";
var _9 = "ClearingsPageHeader_header__4Uonx";
var _a = "ClearingsPageHeader_infoIcon__GBxTY";
var _b = "ClearingsPageHeader_point__kU8MQ";
var _c = "ClearingsPageHeader_strongText__KgDcj";
var _d = "ClearingsPageHeader_tooltip__BgSGC";
export { _1 as "balanceIcon", _2 as "cellCompanyInfoPanel", _3 as "cellCompanyInfoPanelItemsWrapper", _4 as "companyInfoPanel", _5 as "darkBadge", _6 as "date", _7 as "divider", _8 as "grayColor", _9 as "header", _a as "infoIcon", _b as "point", _c as "strongText", _d as "tooltip" }
