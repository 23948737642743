import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isNumber } from 'lodash';
import * as styles from './Column.module.scss';

const paddingClasses = {
    0: styles.padding0,
    4: styles.padding4,
    8: styles.padding8,
    12: styles.padding12,
    16: styles.padding16,
    32: styles.padding32,
};

const gapClasses = {
    0: styles.gap0,
    4: styles.gap4,
    8: styles.gap8,
    12: styles.gap12,
    16: styles.gap16,
    32: styles.gap32,
};

const Column = (props) => (
    <div
        name={props.name}
        className={
            classNames(
                styles.column,
                paddingClasses[props.padding],
                gapClasses[props.gap],
                props.stretched === true ? styles.stretched : '',
                styles[props.align],
            )
        }
        style={{
            width: isNumber(props.stretched) ? `${props.stretched}%` : undefined,
        }}
    >
        {props.children}
    </div>
);

Column.propTypes = {
    padding: PropTypes.number,
    gap: PropTypes.number,
    stretched: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    align: PropTypes.oneOf(['start', 'center', 'end']),
};

Column.defaultProps = {
    gap: 8,
    padding: 0,
    stretched: false,
    align: 'start',
};

export default Column;
