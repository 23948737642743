import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NumberInputWithControls } from 'components';
import { isInvalid, getError } from 'services/validationService';
import { instantFormValidation } from 'helpers/input/instantFieldValidation';

function SubscriptionInfoFormGroup({ formik }) {
    const { t } = useTranslation();
    const {
        values: { subscriptionInfo, soldCounter },
        errors,
        touched,
        handleChange,
        setFieldValue,
    } = formik;
    const onContractLengthChange = (e) => setFieldValue(
        'subscriptionInfo.contractLength',
        e.target.value ? Math.floor(e.target.value) : e.target.value,
    );

    const validationHandler = instantFormValidation(formik);

    return (
        <div className="d-flex flex-column flex-lg-row justify-content-between px-4 px-lg-0 mb-3">
            <Col className="px-2 pl-lg-0 pr-lg-3">
                <Form.Group
                    as={Col}
                    controlId="subscriptionTitle"
                    className="p-0 p-sm-0 p-md-0"
                    style={{ marginBottom: '26px' }}
                >
                    <Form.Label className="font-size-15">
                        {t('subscriptions.infoFormGroup.title')}
                    </Form.Label>
                    <Form.Control
                        value={subscriptionInfo.title}
                        name="subscriptionInfo.title"
                        isInvalid={isInvalid('subscriptionInfo.title', errors, touched)}
                        onChange={validationHandler(handleChange)}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={isInvalid('subscriptionInfo.title', errors, touched) && 'd-block'}
                    >
                        {getError('subscriptionInfo.title', errors)}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    controlId="contractLength align-self-end"
                    className="p-0 p-sm-0 p-md-0"
                >
                    <Form.Label className="font-size-15">
                        {t('subscriptions.infoFormGroup.contractLength')}
                    </Form.Label>
                    <NumberInputWithControls
                        value={subscriptionInfo.contractLength}
                        setFieldValue={setFieldValue}
                        fieldValueKey="subscriptionInfo.contractLength"
                        isInvalid={isInvalid('subscriptionInfo.contractLength', errors, touched)}
                        error={getError('subscriptionInfo.contractLength', errors)}
                        customOnChange={validationHandler(onContractLengthChange)}
                        disabled={!!soldCounter}
                    />
                </Form.Group>
            </Col>
            <Col className="px-2 pr-lg-0 pl-lg-3">
                <Form.Group
                    as={Col}
                    controlId="subscriptionDescription"
                    className="p-0 p-sm-0 p-md-0"
                >
                    <Form.Label className="font-size-15">
                        {t('subscriptions.infoFormGroup.description')}
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        name="subscriptionInfo.description"
                        value={subscriptionInfo.description}
                        isInvalid={isInvalid('subscriptionInfo.description', errors, touched)}
                        onChange={validationHandler(handleChange)}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={isInvalid('subscriptionInfo.description', errors, touched) && 'd-block'}
                    >
                        {getError('subscriptionInfo.description', errors)}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </div>
    );
}

export default SubscriptionInfoFormGroup;
