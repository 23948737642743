import Bundles from './bundles';
import Services from './services';
import Subscriptions from './subscriptions';

const routes = [
    {
        component: Services,
        exact: false,
        path: '/services/services',
    },
    {
        component: Bundles,
        exact: false,
        path: '/services/bundles',
    },
    {
        component: Subscriptions,
        exact: false,
        path: '/services/subscriptions',
    },
];

export default routes;
