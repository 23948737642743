import React from 'react';
import PropTypes from 'prop-types';
import BSTable from 'react-bootstrap/Table';

import { Badge } from 'components/Common/Badge';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as styles from './Table.module.scss';

const Table = (props) => {
    const { children } = props;
    const { t } = useTranslation(LOCALE_NAMESPACE.TABLE);

    return (
        <div className={styles.tableContainer}>
            {props.hasNewItems && (
                <div className={styles.newItems}>
                    <Badge onClick={props.onNewItemsClick} color="primary" size="small">
                        {t('list.newItemsAvaliable')}
                    </Badge>
                </div>
            )}
            <BSTable
                responsive
                className={styles.table}
            >
                {children}
            </BSTable>
        </div>
    );
};

Table.propTypes = {
    children: PropTypes.any,
    hasNewItems: PropTypes.bool,
    onNewItemsClick: PropTypes.func,
};

Table.defaultProps = {
    children: null,
    hasNewItems: false,
    onNewItemsClick: () => {},
};

export default Table;
