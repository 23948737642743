import React from 'react';
import PropTypes from 'prop-types';

const BinIcon = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 3V5H0V3H4V1C4 0.448 4.447 0 5 0H11C11.553 0 12 0.448 12 1V3H16ZM10 2H6V3H10V2Z"
                    fill="currentColor"
                />
                <path
                    d="M13 7V14H3V7H1V15C1 15.552 1.447 16 2 16H14C14.553 16 15 15.552 15 15V7H13Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

BinIcon.propTypes = {
    className: PropTypes.string,
};

BinIcon.defaultProps = {
    className: '',
};

export default BinIcon;
