import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { THIRD_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import NavigationService from '../../../../services/navigationService';

import { useSubRoute } from '../../../../components/hooks';

import { SubNavigation } from '../../../../components';

import * as BUNDLES_ACTIONS from '../../../../store/actions/bundles';

import routes from './scenes';

import * as styles from './page.module.scss';

const subNavigationVisibleRoutes = ['list', 'sold'];

const Bundles = () => {
    const subNavigation = NavigationService.getBundlesSubNavigation();
    const isNavigationDataLoaded = true;
    const [currentSubRoute] = useSubRoute();

    const dispatch = useDispatch();

    useEffect(() => () => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageFirst());
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPageFirst());
        dispatch(BUNDLES_ACTIONS.setBundlesSearch({ search: '' }));
        dispatch(BUNDLES_ACTIONS.setSoldBundlesSearch({ search: '' }));
    }, []);

    return (
        <>
            {
                subNavigationVisibleRoutes.includes(currentSubRoute) && (
                    <Row noGutters className={styles.withBorderBottom} style={{ height: THIRD_LEVEL_NAV_HEIGHT }}>
                        <SubNavigation
                            isDataLoaded={isNavigationDataLoaded}
                            subRoutes={subNavigation}
                            withQuery={false}
                        />
                    </Row>
                )
            }
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <Route
                            key={path}
                            component={component}
                            exact={exact}
                            path={path}
                        />
                    ))
                }

                <Redirect to={routes[0].path} />
            </Switch>
        </>
    );
};

export default Bundles;
