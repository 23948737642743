const PREFIX = '@@CLEARINGS/';

const CLEARINGS = `${PREFIX}CLEARINGS`;
export const CLEARINGS_GET = `${CLEARINGS}:GET`;
export const CLEARINGS_SET = `${CLEARINGS}:SET`;
const CLEARINGS_PAGE = `${CLEARINGS}:PAGE`;
export const CLEARINGS_PAGE_SET = `${CLEARINGS_PAGE}:SET`;
export const CLEARINGS_PAGE_SET_FIRST = `${CLEARINGS_PAGE}:FIRST`;
export const CLEARINGS_PAGE_SET_LAST = `${CLEARINGS_PAGE}:LAST`;
export const CLEARINGS_PAGE_SET_PREV = `${CLEARINGS_PAGE}:PREV`;
export const CLEARINGS_PAGE_SET_NEXT = `${CLEARINGS_PAGE}:NEXT`;
const CLEARINGS_LOADING = `${CLEARINGS}:LOADING`;
export const CLEARINGS_LOADING_SET = `${CLEARINGS_LOADING}:SET`;
