import * as SERVICES_TYPES from '../../actions/services/types';

const INITIAL_STATE = {
    item: null,
    loading: false,
};

export const serviceItemReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (SERVICES_TYPES.SERVICE_ITEM_SET): {
        const { service } = payload;
        return {
            ...state,
            item: service,
        };
    }
    case (SERVICES_TYPES.SERVICE_ITEM_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    default:
        return state;
    }
};
