import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientInvoicesTableHeader.module.scss';

const T_PREFIX = 'invoices.list.table.header.fields';
function ClientSubscriptionsTableHeader() {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    return (
        <tr>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.invoiceId`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.date`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_PREFIX}.amount`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.statusColumn}>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.status`)}
                />
            </TableHeaderCell>
        </tr>
    );
}

ClientSubscriptionsTableHeader.propTypes = {};

ClientSubscriptionsTableHeader.defaultProps = {};

export default ClientSubscriptionsTableHeader;
