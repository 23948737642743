import React, {
    forwardRef,
    useLayoutEffect,
    useRef,
} from 'react';
import PropTypes from 'prop-types';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

const ClickAwayListener = forwardRef((props, outerRef) => {
    const {
        children,
        onClickAway,
    } = props;

    const innerRef = useRef();
    const ref = outerRef || innerRef;

    useLayoutEffect(() => {
        const handleClickAway = (e) => {
            const { target } = e;
            if (!ref.current.contains(target)) {
                e.stopPropagation();
                onClickAway();
            }
        };
        window.addEventListener('click', handleClickAway, { capture: true });
        return () => window.removeEventListener('click', handleClickAway, { capture: true });
    }, []);

    return (
        <div style={{ margin: 'auto' }} ref={ref}>
            {children}
        </div>
    );
});

ClickAwayListener.propTypes = {
    children: PropTypes.node,
    onClickAway: PropTypes.func,
};

ClickAwayListener.defaultProps = {
    children: null,
    onClickAway: emptyFunc,
};

export default ClickAwayListener;
