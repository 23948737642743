import {
    call,
    put,
    take,
    takeEvery,
} from 'redux-saga/effects';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../services/axios';

import * as TYPES from '../actions/categories/actionTypes';

import * as AUTH_ACTIONS from '../actions/auth';
import * as CATEGORIES_ACTIONS from '../actions/categories';
import * as TOAST_ACTIONS from '../actions/toast';
import * as actionTypes from '../actions/actionTypes';

function* getCategories(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(CATEGORIES_ACTIONS.setLoading({ loading: true }));
        const response = yield call(axios.request, {
            _action: action,
            method: 'GET',
            url: 'api/admin/company/categories/list',
            headers: defaultHeaders,
        });
        const { data } = response;
        const { code, result } = data;
        if (code === 200) {
            yield put(CATEGORIES_ACTIONS.setCategories({
                categories: result,
            }));
        }
    } catch (e) {
        if (e.response && e.response.status === 401) {
            try {
                yield put(AUTH_ACTIONS.refreshToken());
                yield take(actionTypes.REFRESH_TOKEN_RESOLVED);
                yield put(CATEGORIES_ACTIONS.getCategories());
                return;
            } catch (err1) {
                yield put(TOAST_ACTIONS.showToast({
                    message: 'categories.get.error',
                    appearance: 'error',
                }));
                return;
            }
        }
        yield put(TOAST_ACTIONS.showToast({
            message: 'categories.get.error',
            appearance: 'error',
        }));
    } finally {
        yield put(CATEGORIES_ACTIONS.setLoading({ loading: false }));
    }
}

export const categoriesSaga = [
    takeEvery(TYPES.GET_CATEGORIES, getCategories),
];
