import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconButton from 'components/Common/IconButton/IconButton';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { Row } from 'components/Common/Row';
import { useDialog } from '../../../../hooks/useDialog';

import { Tooltip } from '../../../Common/Tooltip';
import { ListTableBodyCell } from '../../../Common/NewTable/ListTableBodyCell';
import MessageDeleteDialog from '../MessageDeleteDialog/MessageDeleteDialog';
import AddClientsDialog from '../AddClientsDialog/AddClientsDialog';

import {
    CopyIcon,
    Info2Icon,
    LetterIcon,
    MobileIcon,
    Pen2Icon,
    Sent2Icon,
    Trash3Icon,
} from '../../../Icon/Icon';

import * as styles from './MessagesTableBodyRow.module.scss';

import MESSAGE_NOTIFICATION_TYPE from '../../../../const/marketing/MESSAGE_NOTIFICATION_TYPE';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import MESSAGE_STATUS from '../../../../const/marketing/MESSAGE_STATUS';

const T_PREFIX = 'list.table.body.rows';
const T_FIELDS = `${T_PREFIX}.fields`;

const MessagesTableBodyRow = (props) => {
    const {
        message, onEdit, onClone, onClick, companyClients,
    } = props;

    const {
        name,
        beginDate,
        endDate,
        indefinite,
        status = MESSAGE_STATUS.PENDING,
        messageType,
        messageToAllClients,
        clients,
    } = message;

    const { t } = useTranslation(LOCALE_NAMESPACE.MESSAGES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const { locale } = useSelector((state) => state.locales);

    const messageDeleteDialog = useDialog(false);
    const addClientsDialog = useDialog(false);

    const messageEndDate = indefinite
        ? t(`${T_FIELDS}.date.indefinite`)
        : moment.unix(endDate).locale(locale).format('DD MMM YYYY');

    const clientsFormatted = useMemo(() => {
        if (messageToAllClients) {
            return (
                <div className={styles.grayColor}>
                    {t(`${T_FIELDS}.clients.allClients`)}
                </div>
            );
        }

        if (clients.length === 0) {
            return '';
        }

        return (
            <Tooltip
                tooltip={clients.map((c) => c.fullName).join(', ')}
                isDisabled={clients.length === 1}
                rootClassName={styles.clientRootTooltip}
            >
                <div>
                    {clients[0].fullName}
                    {clients.length > 1 && (
                        ` +${clients.length - 1}`
                    )}
                </div>

            </Tooltip>
        );
    }, [t, messageToAllClients, clients]);

    const statusClasses = {
        [styles.grayColor]: status === MESSAGE_STATUS.ENDED,
        [styles.greenColor]: status === MESSAGE_STATUS.ACTIVE,
    };

    const onEditClick = useCallback(
        (e) => {
            e.stopPropagation();
            if (!message.id) {
                return;
            }

            onEdit({ messageId: message.id });
        },
        [message.id, onEdit],
    );

    const onSentClick = useCallback((e) => {
        e.stopPropagation();
        if (!message.id) {
            return;
        }

        e.currentTarget.blur();
        addClientsDialog.onSetShow(message);
    }, [message.id, onClick]);

    const onInfoClick = useCallback((e) => {
        e.stopPropagation();
        if (!message.id) {
            return;
        }

        onClick({ message });
    }, [message.id, onClick]);

    const onDeleteClick = useCallback((e) => {
        e.stopPropagation();
        if (!message.id) {
            return;
        }

        e.currentTarget.blur();
        messageDeleteDialog.onSetShow(message);
    }, [message.id, onClick]);

    const handleCloneMessage = useCallback(() => {
        if (!message.id) {
            return;
        }

        onClone({ messageId: message.id });
    }, [message.id, onEdit]);

    return (
        <>
            <ListTableBodyRow onClick={onInfoClick}>
                <ListTableBodyCell>{name}</ListTableBodyCell>
                <ListTableBodyCell className="text-nowrap">
                    {moment.unix(beginDate).locale(locale).format('DD MMM YYYY · HH:mm')}
                </ListTableBodyCell>
                <ListTableBodyCell className="text-nowrap">{messageEndDate}</ListTableBodyCell>
                <ListTableBodyCell className="text-nowrap">{clientsFormatted}</ListTableBodyCell>
                <ListTableBodyCell className={classNames('text-nowrap', statusClasses)}>
                    <Tooltip
                        tooltip={t(`${T_FIELDS}.status.${status}.tooltip`)}
                        placement="bottom"
                        rootClassName={styles.rootTooltip}
                    >
                        <span>{t(`${T_FIELDS}.status.${status}.label`)}</span>
                    </Tooltip>
                </ListTableBodyCell>
                <ListTableBodyCell>
                    <div className={styles.sentToCell}>
                        {messageType.includes(MESSAGE_NOTIFICATION_TYPE.PUSH) && (
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.actions.sendToAppAndWidget`)}
                                placement="bottom"
                                rootClassName={styles.rootTooltip}
                            >
                                <button
                                    onClick={() => {}}
                                    className={styles.grayColor}
                                >
                                    <MobileIcon />
                                </button>
                            </Tooltip>
                        )}
                        {messageType.includes(MESSAGE_NOTIFICATION_TYPE.EMAIL) && (
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.actions.sendToEmail`)}
                                placement="bottom"
                                rootClassName={styles.rootTooltip}
                            >
                                <button
                                    onClick={() => {}}
                                    className={styles.grayColor}
                                >
                                    <LetterIcon />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </ListTableBodyCell>
                <ListTableBodyCell align="right">
                    <Row gap={8}>
                        <Tooltip
                            tooltip={t(`${T_PREFIX}.actions.edit`)}
                            placement="bottom"
                            rootClassName={styles.rootTooltip}
                            forButton
                        >
                            <IconButton
                                onClick={onEditClick}
                                color="gray"
                            >
                                <Pen2Icon />
                            </IconButton>
                        </Tooltip>
                        {status === MESSAGE_STATUS.ACTIVE && !message.messageToAllClients && (
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.actions.sent`)}
                                placement="bottom"
                                rootClassName={styles.rootTooltip}
                                forButton
                            >
                                <IconButton
                                    onClick={onSentClick}
                                    color="gray"
                                >
                                    <Sent2Icon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip
                            tooltip={t(`${T_PREFIX}.actions.info`)}
                            placement="bottom"
                            rootClassName={styles.rootTooltip}
                            forButton
                        >
                            <IconButton
                                onClick={onInfoClick}
                                color="gray"
                            >
                                <Info2Icon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            tooltip={t(`${T_PREFIX}.actions.delete`)}
                            placement="bottom"
                            rootClassName={styles.rootTooltip}
                            forButton
                        >
                            <IconButton
                                onClick={onDeleteClick}
                                color="gray"
                            >
                                <Trash3Icon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip forButton tooltip={tc('placeholders.duplicate')} placement="bottom">
                            <IconButton
                                onClick={handleCloneMessage}
                                color="gray"
                                size={32}
                            >
                                <CopyIcon height={24} width={24} />
                            </IconButton>
                        </Tooltip>
                    </Row>
                </ListTableBodyCell>
            </ListTableBodyRow>

            {messageDeleteDialog.visible && (
                <MessageDeleteDialog
                    message={messageDeleteDialog.visible}
                    onClose={messageDeleteDialog.onClose}
                />
            )}
            {addClientsDialog.visible && (
                <AddClientsDialog
                    message={addClientsDialog.visible}
                    onClose={addClientsDialog.onClose}
                    companyClients={companyClients}
                />
            )}
        </>
    );
};

MessagesTableBodyRow.propTypes = {
    message: PropTypes.object,
};

MessagesTableBodyRow.defaultProps = {
    message: null,
};

export default MessagesTableBodyRow;
