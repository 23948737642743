// https://dev.appybee.nl/book/side/mijn-sportschool?widget_locale=en if the environment is dev
// https://pre.appybee.nl/book/side/mijn-sportschool?widget_locale=en if the environment is pre
// https://portal.appybee.nl/book/side/mijn-sportschool?widget_locale=en if the environment is prod
// process.env.REACT_APP_BASE_URL = https://{env}.appybee.nl

// common view https://{env}.appybee.nl/book/{side/full/inline}/{slug}?widget_locale={en/nl/de}

export const getWidgetUrl = ({ side, slug, widgetLocale }) => {
    const widgetLocaleQuery = widgetLocale ? `?widget_locale=${widgetLocale}` : '';
    return `${process.env.REACT_APP_BASE_URL}/book/${side}/${slug}${widgetLocaleQuery}`;
};
