import { createSelector } from 'reselect';

const FULL_LOCALE_SEPARATOR = '-';

export const localesSelector = (state) => state.locales;

export const fullLocaleSelector = createSelector(
    localesSelector,
    (locales) => locales.locale,
);

export const shortLocaleSelector = createSelector(
    fullLocaleSelector,
    (fullLocale) => fullLocale.split(FULL_LOCALE_SEPARATOR)[0],
);
