import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Switch } from 'components';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import SubscriptionsTableListActions from '../SubscriptionsTableActions/SubscriptionsTableListActions';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

const SubscriptionsListTableRow = (props) => {
    const { t } = useTranslation();
    const {
        subscription,
        onToggleStatus,
        onArchive,
        onDuplicate,
    } = props;

    const handleToggleStatus = useCallback(() => {
        if (!subscription?.id) {
            return;
        }
        onToggleStatus({ subscriptionId: subscription.id });
    }, [onToggleStatus, subscription?.id]);

    const handleDuplicateSubscription = useCallback(() => {
        onDuplicate({ subscriptionId: subscription.id });
    }, [onDuplicate, subscription]);

    if (!subscription) {
        return null;
    }

    const { id } = subscription;
    const { title } = subscription.subscriptionInfo;
    const period = subscription.subscriptionInfo.contractLength;
    const price = subscription.pricing.priceWithVAT;
    const status = subscription.active;

    return (
        <ListTableBodyRow>
            <ListTableBodyCell>
                {title}
            </ListTableBodyCell>
            <ListTableBodyCell>
                {period}
                {' '}
                {`${period > 1 ? t('subscriptions.tablePeriod.months') : t('subscriptions.tablePeriod.month')}`}
            </ListTableBodyCell>
            <ListTableBodyCell align="right">
                {formatPrice.toEuroWithComma({
                    amount: price,
                })}
            </ListTableBodyCell>
            <ListTableBodyCell align="center">
                <Switch
                    checked={status}
                    onChange={handleToggleStatus}
                />
            </ListTableBodyCell>
            <ListTableBodyCell>
                <SubscriptionsTableListActions id={id} onArchive={onArchive} canArchive={!status} onDuplicate={handleDuplicateSubscription} />
            </ListTableBodyCell>
        </ListTableBodyRow>
    );
};

SubscriptionsListTableRow.propTypes = {
    subscription: PropTypes.object,
    onToggleStatus: PropTypes.func,
};

SubscriptionsListTableRow.defaultProps = {
    subscription: null,
    onToggleStatus: emptyFunc,
};

export default SubscriptionsListTableRow;
