import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import * as actions from 'store/actions';

// Components
import { GroupsTimeline, GroupDetailsModal } from 'components';

const Week = ({ globalDate, week, year }) => {
    const isShopLoaded = useSelector((state) => state.shop.loaded);
    const dispatch = useDispatch();

    const [clickedGroupSessionID, setClickedGroupSessionID] = useState(null);
    const [showGroupDetailsModal, setShowGroupDetailsModal] = useState(false);

    useEffect(() => {
        if (!isShopLoaded) {
            return;
        }

        const from = globalDate.clone().startOf('isoWeek').unix();
        const to = globalDate.clone().endOf('isoWeek').unix();

        dispatch(actions.getGroupSessions(from, to));
    }, [isShopLoaded, week, year]);

    return (
        <>
            <GroupsTimeline
                className="mt-4 px-0"
                globalDate={globalDate.clone()}
                week={week}
                year={year}
                onGroupSessionClick={({ id, timestamp }) => {
                    setClickedGroupSessionID({ id, timestamp });
                    setShowGroupDetailsModal(true);
                }}
            />
            {showGroupDetailsModal && (
                <GroupDetailsModal
                    groupSessionIDWithTimeStamp={clickedGroupSessionID}
                    week={week}
                    year={year}
                    show={showGroupDetailsModal}
                    onHide={() => setShowGroupDetailsModal(false)}
                />
            )}
        </>
    );
};

export default Week;
