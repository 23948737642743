import React, { useEffect } from 'react';

// Hooks
import { useDispatch, useSelector } from 'react-redux';

// Actions

// Components
import { Dropdown } from 'react-bootstrap';

// Utils
import emojiFlags from 'emoji-flags';
import emoji from 'react-easy-emoji';
import { useTranslation } from 'react-i18next';
import * as actions from '../../store/actions';

import { FOOTER } from '../../const/e2e/TEST_ATTRIBUTES';

const getEmoji = (locale) => emoji(emojiFlags.countryCode(locale.split('-')[1].toUpperCase())
    ? emojiFlags.countryCode(locale.split('-')[1].toUpperCase()).emoji
    : '');

const getName = (locale) => locale.split('-')[0].toUpperCase();

const LocalesDropdown = ({
    noOutline = false, size = 20, show = undefined, onToggle = undefined, drop = undefined,
}) => {
    const { t } = useTranslation();
    const { locale, locales } = useSelector((state) => state.locales);
    const dispatch = useDispatch();

    useEffect(() => {
        const title = document.querySelector('title');
        title.text = t('general.title');
    }, [locale]);

    const variant = `${noOutline ? 'link' : 'outline-light'} no-arrow lang`;

    return (
        <Dropdown
            show={show}
            onToggle={onToggle}
            data-testid={FOOTER.languageSwitchSelector}
            onSelect={(locale) => dispatch(actions.changeLocale(locale))}
            drop={drop}
        >
            <Dropdown.Toggle variant={variant}>
                <span
                    className="position-relative mr-2"
                    style={{
                        lineHeight: 1, fontSize: size, borderRadius: 4, marginRight: 6,
                    }}
                >
                    {getEmoji(locale)}
                </span>
                <span className="font-weight-bold text-dark" style={{ lineHeight: 1, fontSize: size - 4 }}>
                    {getName(locale)}
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="no-min-width">
                {locales.map((l) => (
                    <Dropdown.Item
                        key={`locale-${l}`}
                        eventKey={l}
                        className="no-active lang"
                        onClick={onToggle ? () => onToggle(false) : undefined}
                    >
                        <span
                            className="position-relative"
                            style={{
                                lineHeight: 1, fontSize: size, borderRadius: 4, marginRight: 6,
                            }}
                        >
                            {getEmoji(l)}
                        </span>
                        <span className="font-weight-bold" style={{ lineHeight: 1, fontSize: size - 4 }}>
                            {getName(l)}
                        </span>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LocalesDropdown;
