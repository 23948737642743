export const COUNTRIES_CONFIG = {
    AF: {
        country: 'Afghanistan',
        countryCode: 'af',
        phoneCode: '93',
    },
    AL: {
        country: 'Albania',
        countryCode: 'al',
        phoneCode: '355',
    },
    DZ: {
        country: 'Algeria',
        countryCode: 'dz',
        phoneCode: '213',
    },
    AD: {
        country: 'Andorra',
        countryCode: 'ad',
        phoneCode: '376',
    },
    AO: {
        country: 'Angola',
        countryCode: 'ao',
        phoneCode: '244',
    },
    AG: {
        country: 'Antigua and Barbuda',
        countryCode: 'ag',
        phoneCode: '1268',
    },
    AR: {
        country: 'Argentina',
        countryCode: 'ar',
        phoneCode: '54',
        phoneMask: '(99) 99999999',
    },
    AM: {
        country: 'Armenia',
        countryCode: 'am',
        phoneCode: '374',
    },
    AW: {
        country: 'Aruba',
        countryCode: 'aw',
        phoneCode: '297',
    },
    AU: {
        country: 'Australia',
        countryCode: 'au',
        phoneCode: '61',
        phoneMask: '999 999 999',
    },
    AT: {
        country: 'Austria',
        countryCode: 'at',
        phoneCode: '43',
    },
    AZ: {
        country: 'Azerbaijan',
        countryCode: 'az',
        phoneCode: '994',
    },
    BS: {
        country: 'Bahamas',
        countryCode: 'bs',
        phoneCode: '1242',
    },
    BH: {
        country: 'Bahrain',
        countryCode: 'bh',
        phoneCode: '973',
    },
    BD: {
        country: 'Bangladesh',
        countryCode: 'bd',
        phoneCode: '880',
    },
    BB: {
        country: 'Barbados',
        countryCode: 'bb',
        phoneCode: '1246',
    },
    BY: {
        country: 'Belarus',
        countryCode: 'by',
        phoneCode: '375',
        phoneMask: '(99) 999 99 99',
    },
    BE: {
        country: 'Belgium',
        countryCode: 'be',
        phoneCode: '32',
        phoneMask: '999 99 99 99',
    },
    BZ: {
        country: 'Belize',
        countryCode: 'bz',
        phoneCode: '501',
    },
    BJ: {
        country: 'Benin',
        countryCode: 'bj',
        phoneCode: '229',
    },
    BT: {
        country: 'Bhutan',
        countryCode: 'bt',
        phoneCode: '975',
    },
    BO: {
        country: 'Bolivia',
        countryCode: 'bo',
        phoneCode: '591',
    },
    BA: {
        country: 'Bosnia and Herzegovina',
        countryCode: 'ba',
        phoneCode: '387',
    },
    BW: {
        country: 'Botswana',
        countryCode: 'bw',
        phoneCode: '267',
    },
    BR: {
        country: 'Brazil',
        countryCode: 'br',
        phoneCode: '55',
        phoneMask: '(99) 999999999',
    },
    IO: {
        country: 'British Indian Ocean Territory',
        countryCode: 'io',
        phoneCode: '246',
    },
    BN: {
        country: 'Brunei',
        countryCode: 'bn',
        phoneCode: '673',
    },
    BG: {
        country: 'Bulgaria',
        countryCode: 'bg',
        phoneCode: '359',
    },
    BF: {
        country: 'Burkina Faso',
        countryCode: 'bf',
        phoneCode: '226',
    },
    BI: {
        country: 'Burundi',
        countryCode: 'bi',
        phoneCode: '257',
    },
    KH: {
        country: 'Cambodia',
        countryCode: 'kh',
        phoneCode: '855',
    },
    CM: {
        country: 'Cameroon',
        countryCode: 'cm',
        phoneCode: '237',
    },
    CA: {
        country: 'Canada',
        countryCode: 'ca',
        phoneCode: '1',
        phoneMask: '(999) 999-9999',
    },
    CV: {
        country: 'Cape Verde',
        countryCode: 'cv',
        phoneCode: '238',
    },
    BQ: {
        country: 'Caribbean Netherlands',
        countryCode: 'bq',
        phoneCode: '599',
    },
    CF: {
        country: 'Central African Republic',
        countryCode: 'cf',
        phoneCode: '236',
    },
    TD: {
        country: 'Chad',
        countryCode: 'td',
        phoneCode: '235',
    },
    CL: {
        country: 'Chile',
        countryCode: 'cl',
        phoneCode: '56',
    },
    CN: {
        country: 'China',
        countryCode: 'cn',
        phoneCode: '86',
        phoneMask: '99-999999999',
    },
    CO: {
        country: 'Colombia',
        countryCode: 'co',
        phoneCode: '57',
    },
    KM: {
        country: 'Comoros',
        countryCode: 'km',
        phoneCode: '269',
    },
    CD: {
        country: 'Congo',
        countryCode: 'cd',
        phoneCode: '243',
    },
    CG: {
        country: 'Congo',
        countryCode: 'cg',
        phoneCode: '242',
    },
    CR: {
        country: 'Costa Rica',
        countryCode: 'cr',
        phoneCode: '506',
        phoneMask: '9999-9999',
    },
    CI: {
        country: 'Côte d’Ivoire',
        countryCode: 'ci',
        phoneCode: '225',
    },
    HR: {
        country: 'Croatia',
        countryCode: 'hr',
        phoneCode: '385',
    },
    CU: {
        country: 'Cuba',
        countryCode: 'cu',
        phoneCode: '53',
    },
    CW: {
        country: 'Curaçao',
        countryCode: 'cw',
        phoneCode: '599',
    },
    CY: {
        country: 'Cyprus',
        countryCode: 'cy',
        phoneCode: '357',
        phoneMask: '99 999999',
    },
    CZ: {
        country: 'Czech Republic',
        countryCode: 'cz',
        phoneCode: '420',
    },
    DK: {
        country: 'Denmark',
        countryCode: 'dk',
        phoneCode: '45',
        phoneMask: '99 99 99 99',
    },
    DJ: {
        country: 'Djibouti',
        countryCode: 'dj',
        phoneCode: '253',
    },
    DM: {
        country: 'Dominica',
        countryCode: 'dm',
        phoneCode: '1767',
    },
    DO: {
        country: 'Dominican Republic',
        countryCode: 'do',
        phoneCode: '1',
    },
    EC: {
        country: 'Ecuador',
        countryCode: 'ec',
        phoneCode: '593',
    },
    EG: {
        country: 'Egypt',
        countryCode: 'eg',
        phoneCode: '20',
    },
    SV: {
        country: 'El Salvador',
        countryCode: 'sv',
        phoneCode: '503',
        phoneMask: '9999-9999',
    },
    GQ: {
        country: 'Equatorial Guinea',
        countryCode: 'gq',
        phoneCode: '240',
    },
    ER: {
        country: 'Eritrea',
        countryCode: 'er',
        phoneCode: '291',
    },
    EE: {
        country: 'Estonia',
        countryCode: 'ee',
        phoneCode: '372',
        phoneMask: '9999 999999',
    },
    ET: {
        country: 'Ethiopia',
        countryCode: 'et',
        phoneCode: '251',
    },
    FJ: {
        country: 'Fiji',
        countryCode: 'fj',
        phoneCode: '679',
    },
    FI: {
        country: 'Finland',
        countryCode: 'fi',
        phoneCode: '358',
        phoneMask: '99 999 99 99',
    },
    FR: {
        country: 'France',
        countryCode: 'fr',
        phoneCode: '33',
        phoneMask: '9 99 99 99 99',
    },
    GF: {
        country: 'French Guiana',
        countryCode: 'gf',
        phoneCode: '594',
    },
    PF: {
        country: 'French Polynesia',
        countryCode: 'pf',
        phoneCode: '689',
    },
    GA: {
        country: 'Gabon',
        countryCode: 'ga',
        phoneCode: '241',
    },
    GM: {
        country: 'Gambia',
        countryCode: 'gm',
        phoneCode: '220',
    },
    GE: {
        country: 'Georgia',
        countryCode: 'ge',
        phoneCode: '995',
    },
    DE: {
        country: 'Germany',
        countryCode: 'de',
        phoneCode: '49',
        phoneMask: '9999 99999999',
    },
    GH: {
        country: 'Ghana',
        countryCode: 'gh',
        phoneCode: '233',
    },
    GR: {
        country: 'Greece',
        countryCode: 'gr',
        phoneCode: '30',
    },
    GD: {
        country: 'Grenada',
        countryCode: 'gd',
        phoneCode: '1473',
    },
    GP: {
        country: 'Guadeloupe',
        countryCode: 'gp',
        phoneCode: '590',
    },
    GU: {
        country: 'Guam',
        countryCode: 'gu',
        phoneCode: '1671',
    },
    GT: {
        country: 'Guatemala',
        countryCode: 'gt',
        phoneCode: '502',
        phoneMask: '9999-9999',
    },
    GN: {
        country: 'Guinea',
        countryCode: 'gn',
        phoneCode: '224',
    },
    GW: {
        country: 'Guinea-Bissau',
        countryCode: 'gw',
        phoneCode: '245',
    },
    GY: {
        country: 'Guyana',
        countryCode: 'gy',
        phoneCode: '592',
    },
    HT: {
        country: 'Haiti',
        countryCode: 'ht',
        phoneCode: '509',
        phoneMask: '9999-9999',
    },
    HN: {
        country: 'Honduras',
        countryCode: 'hn',
        phoneCode: '504',
    },
    HK: {
        country: 'Hong Kong',
        countryCode: 'hk',
        phoneCode: '852',
        phoneMask: '9999 9999',
    },
    HU: {
        country: 'Hungary',
        countryCode: 'hu',
        phoneCode: '36',
    },
    IS: {
        country: 'Iceland',
        countryCode: 'is',
        phoneCode: '354',
        phoneMask: '999 9999',
    },
    IN: {
        country: 'India',
        countryCode: 'in',
        phoneCode: '91',
        phoneMask: '99999-99999',
    },
    ID: {
        country: 'Indonesia',
        countryCode: 'id',
        phoneCode: '62',
    },
    IR: {
        country: 'Iran',
        countryCode: 'ir',
        phoneCode: '98',
    },
    IQ: {
        country: 'Iraq',
        countryCode: 'iq',
        phoneCode: '964',
    },
    IE: {
        country: 'Ireland',
        countryCode: 'ie',
        phoneCode: '353',
        phoneMask: '99 9999999',
    },
    IL: {
        country: 'Israel',
        countryCode: 'il',
        phoneCode: '972',
        phoneMask: '999 999 9999',
    },
    IT: {
        country: 'Italy',
        countryCode: 'it',
        phoneCode: '39',
        phoneMask: '999 9999999',
    },
    JM: {
        country: 'Jamaica',
        countryCode: 'jm',
        phoneCode: '1876',
    },
    JP: {
        country: 'Japan',
        countryCode: 'jp',
        phoneCode: '81',
        phoneMask: '99 9999 9999',
    },
    JO: {
        country: 'Jordan',
        countryCode: 'jo',
        phoneCode: '962',
    },
    KZ: {
        country: 'Kazakhstan',
        countryCode: 'kz',
        phoneCode: '7',
        phoneMask: '999 999-99-99',
    },
    KE: {
        country: 'Kenya',
        countryCode: 'ke',
        phoneCode: '254',
    },
    KI: {
        country: 'Kiribati',
        countryCode: 'ki',
        phoneCode: '686',
    },
    XK: {
        country: 'Kosovo',
        countryCode: 'xk',
        phoneCode: '383',
    },
    KW: {
        country: 'Kuwait',
        countryCode: 'kw',
        phoneCode: '965',
    },
    KG: {
        country: 'Kyrgyzstan',
        countryCode: 'kg',
        phoneCode: '996',
    },
    LA: {
        country: 'Laos',
        countryCode: 'la',
        phoneCode: '856',
    },
    LV: {
        country: 'Latvia',
        countryCode: 'lv',
        phoneCode: '371',
    },
    LB: {
        country: 'Lebanon',
        countryCode: 'lb',
        phoneCode: '961',
    },
    LS: {
        country: 'Lesotho',
        countryCode: 'ls',
        phoneCode: '266',
    },
    LR: {
        country: 'Liberia',
        countryCode: 'lr',
        phoneCode: '231',
    },
    LY: {
        country: 'Libya',
        countryCode: 'ly',
        phoneCode: '218',
    },
    LI: {
        country: 'Liechtenstein',
        countryCode: 'li',
        phoneCode: '423',
    },
    LT: {
        country: 'Lithuania',
        countryCode: 'lt',
        phoneCode: '370',
    },
    LU: {
        country: 'Luxembourg',
        countryCode: 'lu',
        phoneCode: '352',
    },
    MO: {
        country: 'Macau',
        countryCode: 'mo',
        phoneCode: '853',
    },
    MK: {
        country: 'Macedonia',
        countryCode: 'mk',
        phoneCode: '389',
    },
    MG: {
        country: 'Madagascar',
        countryCode: 'mg',
        phoneCode: '261',
    },
    MW: {
        country: 'Malawi',
        countryCode: 'mw',
        phoneCode: '265',
    },
    MY: {
        country: 'Malaysia',
        countryCode: 'my',
        phoneCode: '60',
        phoneMask: '99-9999-9999',
    },
    MV: {
        country: 'Maldives',
        countryCode: 'mv',
        phoneCode: '960',
    },
    ML: {
        country: 'Mali',
        countryCode: 'ml',
        phoneCode: '223',
    },
    MT: {
        country: 'Malta',
        countryCode: 'mt',
        phoneCode: '356',
    },
    MH: {
        country: 'Marshall Islands',
        countryCode: 'mh',
        phoneCode: '692',
    },
    MQ: {
        country: 'Martinique',
        countryCode: 'mq',
        phoneCode: '596',
    },
    MR: {
        country: 'Mauritania',
        countryCode: 'mr',
        phoneCode: '222',
    },
    MU: {
        country: 'Mauritius',
        countryCode: 'mu',
        phoneCode: '230',
    },
    MX: {
        country: 'Mexico',
        countryCode: 'mx',
        phoneCode: '52',
    },
    FM: {
        country: 'Micronesia',
        countryCode: 'fm',
        phoneCode: '691',
    },
    MD: {
        country: 'Moldova',
        countryCode: 'md',
        phoneCode: '373',
        phoneMask: '(99) 99-99-99',
    },
    MC: {
        country: 'Monaco',
        countryCode: 'mc',
        phoneCode: '377',
    },
    MN: {
        country: 'Mongolia',
        countryCode: 'mn',
        phoneCode: '976',
    },
    ME: {
        country: 'Montenegro',
        countryCode: 'me',
        phoneCode: '382',
    },
    MA: {
        country: 'Morocco',
        countryCode: 'ma',
        phoneCode: '212',
    },
    MZ: {
        country: 'Mozambique',
        countryCode: 'mz',
        phoneCode: '258',
    },
    MM: {
        country: 'Myanmar',
        countryCode: 'mm',
        phoneCode: '95',
    },
    NA: {
        country: 'Namibia',
        countryCode: 'na',
        phoneCode: '264',
    },
    NR: {
        country: 'Nauru',
        countryCode: 'nr',
        phoneCode: '674',
    },
    NP: {
        country: 'Nepal',
        countryCode: 'np',
        phoneCode: '977',
    },
    NL: {
        country: 'Netherlands',
        countryCode: 'nl',
        phoneCode: '31',
        phoneMask: '99 99999999',
    },
    NC: {
        country: 'New Caledonia',
        countryCode: 'nc',
        phoneCode: '687',
    },
    NZ: {
        country: 'New Zealand',
        countryCode: 'nz',
        phoneCode: '64',
        phoneMask: '999-999-9999',
    },
    NI: {
        country: 'Nicaragua',
        countryCode: 'ni',
        phoneCode: '505',
    },
    NE: {
        country: 'Niger',
        countryCode: 'ne',
        phoneCode: '227',
    },
    NG: {
        country: 'Nigeria',
        countryCode: 'ng',
        phoneCode: '234',
    },
    KP: {
        country: 'North Korea',
        countryCode: 'kp',
        phoneCode: '850',
    },
    NO: {
        country: 'Norway',
        countryCode: 'no',
        phoneCode: '47',
        phoneMask: '999 99 999',
    },
    OM: {
        country: 'Oman',
        countryCode: 'om',
        phoneCode: '968',
    },
    PK: {
        country: 'Pakistan',
        countryCode: 'pk',
        phoneCode: '92',
        phoneMask: '999-9999999',
    },
    PW: {
        country: 'Palau',
        countryCode: 'pw',
        phoneCode: '680',
    },
    PS: {
        country: 'Palestine',
        countryCode: 'ps',
        phoneCode: '970',
    },
    PA: {
        country: 'Panama',
        countryCode: 'pa',
        phoneCode: '507',
    },
    PG: {
        country: 'Papua New Guinea',
        countryCode: 'pg',
        phoneCode: '675',
    },
    PY: {
        country: 'Paraguay',
        countryCode: 'py',
        phoneCode: '595',
    },
    PE: {
        country: 'Peru',
        countryCode: 'pe',
        phoneCode: '51',
    },
    PH: {
        country: 'Philippines',
        countryCode: 'ph',
        phoneCode: '63',
        phoneMask: '9999 9999999',
    },
    PL: {
        country: 'Poland',
        countryCode: 'pl',
        phoneCode: '48',
        phoneMask: '999-999-999',
    },
    PT: {
        country: 'Portugal',
        countryCode: 'pt',
        phoneCode: '351',
    },
    PR: {
        country: 'Puerto Rico',
        countryCode: 'pr',
        phoneCode: '1',
    },
    QA: {
        country: 'Qatar',
        countryCode: 'qa',
        phoneCode: '974',
    },
    RE: {
        country: 'Réunion',
        countryCode: 're',
        phoneCode: '262',
    },
    RO: {
        country: 'Romania',
        countryCode: 'ro',
        phoneCode: '40',
    },
    RU: {
        country: 'Russia',
        countryCode: 'ru',
        phoneCode: '7',
        phoneMask: '(999) 999-99-99',
    },
    RW: {
        country: 'Rwanda',
        countryCode: 'rw',
        phoneCode: '250',
    },
    KN: {
        country: 'Saint Kitts and Nevis',
        countryCode: 'kn',
        phoneCode: '1869',
    },
    LC: {
        country: 'Saint Lucia',
        countryCode: 'lc',
        phoneCode: '1758',
    },
    VC: {
        country: 'Saint Vincent and the Grenadines',
        countryCode: 'vc',
        phoneCode: '1784',
    },
    WS: {
        country: 'Samoa',
        countryCode: 'ws',
        phoneCode: '685',
    },
    SM: {
        country: 'San Marino',
        countryCode: 'sm',
        phoneCode: '378',
    },
    ST: {
        country: 'São Tomé and Príncipe',
        countryCode: 'st',
        phoneCode: '239',
    },
    SA: {
        country: 'Saudi Arabia',
        countryCode: 'sa',
        phoneCode: '966',
    },
    SN: {
        country: 'Senegal',
        countryCode: 'sn',
        phoneCode: '221',
    },
    RS: {
        country: 'Serbia',
        countryCode: 'rs',
        phoneCode: '381',
    },
    SC: {
        country: 'Seychelles',
        countryCode: 'sc',
        phoneCode: '248',
    },
    SL: {
        country: 'Sierra Leone',
        countryCode: 'sl',
        phoneCode: '232',
    },
    SG: {
        country: 'Singapore',
        countryCode: 'sg',
        phoneCode: '65',
        phoneMask: '9999-9999',
    },
    SK: {
        country: 'Slovakia',
        countryCode: 'sk',
        phoneCode: '421',
    },
    SI: {
        country: 'Slovenia',
        countryCode: 'si',
        phoneCode: '386',
    },
    SB: {
        country: 'Solomon Islands',
        countryCode: 'sb',
        phoneCode: '677',
    },
    SO: {
        country: 'Somalia',
        countryCode: 'so',
        phoneCode: '252',
    },
    ZA: {
        country: 'South Africa',
        countryCode: 'za',
        phoneCode: '27',
    },
    KR: {
        country: 'South Korea',
        countryCode: 'kr',
        phoneCode: '82',
        phoneMask: '999 9999 9999',
    },
    SS: {
        country: 'South Sudan',
        countryCode: 'ss',
        phoneCode: '211',
    },
    ES: {
        country: 'Spain',
        countryCode: 'es',
        phoneCode: '34',
        phoneMask: '999 999 999',
    },
    LK: {
        country: 'Sri Lanka',
        countryCode: 'lk',
        phoneCode: '94',
    },
    SD: {
        country: 'Sudan',
        countryCode: 'sd',
        phoneCode: '249',
    },
    SR: {
        country: 'Suriname',
        countryCode: 'sr',
        phoneCode: '597',
    },
    SZ: {
        country: 'Swaziland',
        countryCode: 'sz',
        phoneCode: '268',
    },
    SE: {
        country: 'Sweden',
        countryCode: 'se',
        phoneCode: '46',
        phoneMask: '(999) 999-999',
    },
    CH: {
        country: 'Switzerland',
        countryCode: 'ch',
        phoneCode: '41',
        phoneMask: '99 999 99 99',
    },
    SY: {
        country: 'Syria',
        countryCode: 'sy',
        phoneCode: '963',
    },
    TW: {
        country: 'Taiwan',
        countryCode: 'tw',
        phoneCode: '886',
    },
    TJ: {
        country: 'Tajikistan',
        countryCode: 'tj',
        phoneCode: '992',
    },
    TZ: {
        country: 'Tanzania',
        countryCode: 'tz',
        phoneCode: '255',
    },
    TH: {
        country: 'Thailand',
        countryCode: 'th',
        phoneCode: '66',
    },
    TL: {
        country: 'Timor-Leste',
        countryCode: 'tl',
        phoneCode: '670',
    },
    TG: {
        country: 'Togo',
        countryCode: 'tg',
        phoneCode: '228',
    },
    TO: {
        country: 'Tonga',
        countryCode: 'to',
        phoneCode: '676',
    },
    TT: {
        country: 'Trinidad and Tobago',
        countryCode: 'tt',
        phoneCode: '1868',
    },
    TN: {
        country: 'Tunisia',
        countryCode: 'tn',
        phoneCode: '216',
    },
    TR: {
        country: 'Turkey',
        countryCode: 'tr',
        phoneCode: '90',
        phoneMask: '999 999 99 99',
    },
    TM: {
        country: 'Turkmenistan',
        countryCode: 'tm',
        phoneCode: '993',
    },
    TV: {
        country: 'Tuvalu',
        countryCode: 'tv',
        phoneCode: '688',
    },
    UG: {
        country: 'Uganda',
        countryCode: 'ug',
        phoneCode: '256',
    },
    UA: {
        country: 'Ukraine',
        countryCode: 'ua',
        phoneCode: '380',
        phoneMask: '(99) 999 99 99',
    },
    AE: {
        country: 'United Arab Emirates',
        countryCode: 'ae',
        phoneCode: '971',
    },
    GB: {
        country: 'United Kingdom',
        countryCode: 'gb',
        phoneCode: '44',
        phoneMask: '9999 999999',
    },
    US: {
        country: 'United States',
        countryCode: 'us',
        phoneCode: '1',
        phoneMask: '(999) 999-9999',
    },
    UY: {
        country: 'Uruguay',
        countryCode: 'uy',
        phoneCode: '598',
    },
    UZ: {
        country: 'Uzbekistan',
        countryCode: 'uz',
        phoneCode: '998',
    },
    VU: {
        country: 'Vanuatu',
        countryCode: 'vu',
        phoneCode: '678',
    },
    VA: {
        country: 'Vatican City',
        countryCode: 'va',
        phoneCode: '39',
        phoneMask: '99 9999 9999',
    },
    VE: {
        country: 'Venezuela',
        countryCode: 've',
        phoneCode: '58',
    },
    VN: {
        country: 'Vietnam',
        countryCode: 'vn',
        phoneCode: '84',
    },
    YE: {
        country: 'Yemen',
        countryCode: 'ye',
        phoneCode: '967',
    },
    ZM: {
        country: 'Zambia',
        countryCode: 'zm',
        phoneCode: '260',
    },
    ZW: {
        country: 'Zimbabwe',
        countryCode: 'zw',
        phoneCode: '263',
    },
};

export const COUNTRY_CONFIGS_ARRAY = Object.values(COUNTRIES_CONFIG);
