import React from 'react';

const Pin2 = ({
    fill = 'currentColor', width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3042 2.03902C16.1519 1.88669 15.9191 1.84907 15.7265 1.94566C15.5339 2.04226 15.4249 2.25135 15.4559 2.46455L15.7667 4.60174L9.9339 7.83489C9.11308 7.42143 8.28655 7.19163 7.47522 7.23267C6.56888 7.27851 5.73347 7.65996 4.99568 8.39747C4.90188 8.49123 4.84917 8.61842 4.84916 8.75104C4.84915 8.88367 4.90183 9.01087 4.99561 9.10464L8.56287 12.6719L2.81986 20.4809C2.67336 20.6801 2.69448 20.9565 2.86956 21.1311C3.04463 21.3057 3.32107 21.3262 3.51991 21.1791L11.3106 15.4192L14.8951 19.0036C15.0903 19.1989 15.4069 19.1989 15.6021 19.0037C16.3381 18.2681 16.7116 17.4609 16.7515 16.5643C16.7872 15.7626 16.5538 14.9352 16.1592 14.077L19.404 8.23794L21.5366 8.54402C21.7497 8.5746 21.9585 8.46536 22.0548 8.27283C22.1511 8.08031 22.1134 7.84774 21.9612 7.69552L16.3042 2.03902Z" fill={fill} />
    </svg>
);

export default Pin2;
