import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AllowRouteWrapper, LayoutContainer, SubHeader } from 'components';
import routes from './scenes';
import { permissionsPresetSelector } from '../../store/selectors/permissions';

import * as INVOICES_ACTIONS from '../../store/actions/financials/invoices';

function Financials() {
    const dispatch = useDispatch();

    const { sections } = useSelector(({ company }) => company);
    const preset = useSelector(permissionsPresetSelector);

    useEffect(() => () => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageFirst());
    }, [dispatch]);
    return (
        <LayoutContainer>
            <SubHeader
                isNavigationDataLoaded
            />
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <AllowRouteWrapper
                            key={path}
                            path={path}
                            sections={sections}
                            permissionSections={preset?.section}
                        >
                            <Route
                                key={path}
                                path={path}
                                exact={exact}
                                component={component}
                            />
                        </AllowRouteWrapper>
                    ))
                }
                <Redirect to={routes[0].path} />
            </Switch>
        </LayoutContainer>
    );
}

export default Financials;
