import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as CLIENTS_ACTIONS from 'store/actions/clients';

import { Button } from 'components/Common/Button';
import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.dialogs.sendInvitation';

const SendInvitationDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const dispatch = useDispatch();

    const {
        selectedClients,
        visible,
        onClose,
        onDeselectAll,
    } = props;

    const nonRegisteredClients = useMemo(() => (
        Object.values(selectedClients).filter((o) => o.isRegistered === false)
    ), [selectedClients]);

    const clietnsWithoutEmail = useMemo(() => (
        Object.values(selectedClients).filter((o) => !o.email)
    ), [selectedClients]);

    const isButtonDisabled = (
        !nonRegisteredClients.length
        || nonRegisteredClients.length === clietnsWithoutEmail.length
    );

    const handleSend = useCallback(() => {
        const selectedClientsIds = Object.entries(selectedClients).map((c) => Number(c[0]));

        const data = new FormData();
        data.append('clients', selectedClientsIds);

        dispatch(CLIENTS_ACTIONS.sendClientsInvitation({ data }));
        onClose();
        onDeselectAll();
    }, [selectedClients]);

    return (
        <Dialog
            visible={visible}
            onClose={onClose}
        >
            <DialogHeader>
                <DialogTitle>
                    {t(`${T_PREFIX}.title`)}
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                {
                    nonRegisteredClients.length
                        ? (
                            <p className="text-muted">
                                {t(`${T_PREFIX}.nonRegisteredSelected`, {
                                    clients: nonRegisteredClients.length,
                                })}
                            </p>
                        )
                        : (
                            <p className="text-muted">
                                {t(`${T_PREFIX}.noSelectedClients`)}
                            </p>
                        )
                }
                {Boolean(clietnsWithoutEmail.length) && (
                    <span className="text-muted">
                        {t(`${T_PREFIX}.noEmailClients`, {
                            clients: clietnsWithoutEmail.length,
                        })}
                    </span>
                )}
            </DialogBody>
            <DialogFooter>
                <Button
                    type="submit"
                    onClick={handleSend}
                    disabled={isButtonDisabled}
                >
                    {t(`${T_PREFIX}.actions.send`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

SendInvitationDialog.propTypes = {
    selectedClients: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onDeselectAll: PropTypes.func,
};

SendInvitationDialog.defaultProps = {
    selectedClients: {},
    visible: false,
    onClose: emptyFunc,
    onDeselectAll: emptyFunc,
};

export default SendInvitationDialog;
