import {
    call,
    put,
    select, takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';

import * as SERVICES_TYPES from '../../actions/services/types';

import * as ACTIONS from '../../actions';
import * as SERVICES_ACTIONS from '../../actions/services';
import * as TOAST_ACTIONS from '../../actions/toast';

import * as SERVICES_SELECTORS from '../../selectors/services';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import {
    API_ADMIN_COMPANY_SERVICE_LIST_ROUTE,
    API_ADMIN_SERVICE_CHANGE_POSITION_ID_ROUTE,
    API_ADMIN_SERVICE_CREATE_ROUTE,
    API_ADMIN_SERVICE_DELETE_ID_ROUTE,
    API_ADMIN_SERVICE_EDIT_ID_ROUTE,
    API_ADMIN_SERVICE_TOGGLE_RANDOMLY_SELECT_ID_ROUTE,
} from '../../../const/API_URL';
import { SERVICES_LIST_PAGE } from '../../../const/CLIENT_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import { moveArrayItem } from '../../../helpers/array/moveArrayItem';

function* getServices(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { search, page } = yield select(SERVICES_SELECTORS.servicesListSelector);

    try {
        yield put(SERVICES_ACTIONS.setServicesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_SERVICE_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                search,
                page,
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const { items, itemsCount, pagesCount } = result;
            const services = Object.values(items).map((item) => {
                const service = camelize(item);
                return {
                    id: service.id,
                    name: service.name,
                    description: service.description,
                    length: service.minutes,
                    price: service.price,
                    category: service.category,
                    bookableType: service.bookableType,
                    bookingFrequency: service.bookingFrequency,
                    subOptions: service.subOptions || [],
                    additionalTime: service.additionalTime,
                    randomlySelectEmployee: service.randomlySelectEmployee,
                    restrictUnpaidBookings: service.restrictUnpaidBookings,
                };
            });
            yield put(SERVICES_ACTIONS.setServices({
                services,
                itemsCount,
                pagesCount,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'services.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(SERVICES_ACTIONS.setServicesLoading({ loading: false }));
    }
}

function* addServicesItem(action) {
    const { payload } = action;
    const { service, history, onFinal } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SERVICE_CREATE_ROUTE,
            headers: defaultHeaders,
            data: service,
        });
        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'services.add.success',
                appearance: 'success',
            }));
            yield put(SERVICES_ACTIONS.getCompanyServices());
            yield put(ACTIONS.getObjects());
            yield put(ACTIONS.getEmployees());
            history.push(SERVICES_LIST_PAGE);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'services.add.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
        } else {
            yield call(handleFail);
        }
    }

    onFinal?.();
}

function* editServicesItem(action) {
    const { payload } = action;
    const {
        serviceId,
        service,
        history,
        onFinal,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SERVICE_EDIT_ID_ROUTE({ serviceId }),
            headers: defaultHeaders,
            data: service,
        });
        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'services.edit.success',
                appearance: 'success',
            }));
            yield put(SERVICES_ACTIONS.getCompanyServices());
            yield put(ACTIONS.getObjects());
            yield put(ACTIONS.getEmployees());
            history.push(SERVICES_LIST_PAGE);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'services.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
        } else {
            yield call(handleFail);
        }
    }

    onFinal?.();
}

function* removeServicesItem(action) {
    const { payload } = action;
    const { serviceId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.DELETE,
            url: API_ADMIN_SERVICE_DELETE_ID_ROUTE({ serviceId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(SERVICES_ACTIONS.getServices());
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'services.remove.error',
                appearance: 'error',
            }));
        }
        // eslint-disable-next-line no-inner-declarations
        function* handleCustomFail() {
            const message = error.response?.data?.result?.message;
            if (message) {
                yield put(TOAST_ACTIONS.showToast({
                    message,
                    appearance: 'error',
                    isCustom: true,
                }));
            }
            return Boolean(message);
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        const handled = yield call(handleCustomFail);
        if (!handled) {
            yield call(handleFail);
        }
    }
}

function* toggleServicesItemRandomlySelect(action) {
    const { payload } = action;
    const { serviceId } = payload;

    const defaultHeaders = yield getDefaultHeaders();
    const { items, itemsCount, pagesCount } = yield select(SERVICES_SELECTORS.servicesListSelector);

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SERVICE_TOGGLE_RANDOMLY_SELECT_ID_ROUTE({ serviceId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const serviceIndex = items.findIndex((s) => String(s.id) === String(serviceId));
            if (serviceIndex < 0) {
                return;
            }
            const services = items.slice();
            services[serviceIndex] = {
                ...services[serviceIndex],
                randomlySelectEmployee: result.randomlySelectStatus,
            };
            yield put(SERVICES_ACTIONS.setServices({
                services,
                itemsCount,
                pagesCount,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'services.toggleRandomlySelect.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* changePositionServicesItem(action) {
    const { payload } = action;
    const { serviceId, direction } = payload;

    const defaultHeaders = yield getDefaultHeaders();
    const {
        items, itemsCount, pagesCount, showAll, page,
    } = yield select(SERVICES_SELECTORS.servicesListSelector);

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SERVICE_CHANGE_POSITION_ID_ROUTE({ serviceId }),
            headers: defaultHeaders,
            params: { direction },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const services = items.slice();
            const newIndex = result.new_position - 1;
            const newPage = Math.floor(newIndex / 10) + 1;

            if (!showAll && page !== newPage) {
                yield put(SERVICES_ACTIONS.setServicesPage({ page: newPage }));
                return;
            }

            const serviceIndex = items.findIndex((s) => String(s.id) === String(serviceId));
            if (serviceIndex < 0) {
                return;
            }

            const newPaginationIndex = showAll ? newIndex : newIndex % 10;

            moveArrayItem(services, serviceIndex, newPaginationIndex);
            yield put(SERVICES_ACTIONS.setServices({
                services,
                itemsCount,
                pagesCount,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'toasts.services.changePositionServicesItem.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const servicesListSaga = [
    takeLatest(SERVICES_TYPES.SERVICES_GET, getServices),
    takeEvery(SERVICES_TYPES.SERVICES_ITEM_ADD, addServicesItem),
    takeEvery(SERVICES_TYPES.SERVICES_ITEM_EDIT, editServicesItem),
    takeEvery(SERVICES_TYPES.SERVICES_ITEM_REMOVE, removeServicesItem),
    takeEvery(SERVICES_TYPES.SERVICES_ITEM_RANDOMLY_SELECT_TOGGLE, toggleServicesItemRandomlySelect),
    takeEvery(SERVICES_TYPES.SERVICES_ITEM_CHANGE_POSITION, changePositionServicesItem),
];
