import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { MoreHorizontal } from 'react-feather';
import { PenIcon, Trash2 } from 'components/Icon/Icon';

import { IconButton } from 'components/Common/IconButton';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { ContextMenu } from '../../../../../Common/ContextMenu';

import { formatPrice } from '../../../../../../helpers/formatters/formatPrice';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';

import { SOLD_SUBSCRIPTION_STATUS } from '../../../../../../const/subscriptions/SOLD_SUBSCRIPTION_STATUS';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientSubscriptionsTableRow.module.scss';

const STATUS_T_KEY_MAP = {
    paid: 'paid',
    open: 'unpaid',
    closed: 'closed',
};

const T_PREFIX = 'subscriptions.list.table.body.row.info';

const ClientSubscriptionsTableRow = (props) => {
    const {
        subscription,
        onEdit,
        onDelete,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const actionsMenuControlsRef = useRef();

    const {
        name,
        amount,
        contract,
        unpaidCounter,
        paymentsStatusInfo,
        subscriptionStatus,
        paymentType,
        pause,
        updatingAt,
        updatingSubscription,
    } = subscription || {};

    const {
        startDate,
        endDate,
        nextDebitDate,
    } = contract || {};

    const isOpenStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.OPEN;
    const isPaidStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.PAID;

    const updatingAtData = updatingAt ? moment.unix(updatingAt).format('DD.MM.YYYY') : '';

    const contractStartDate = startDate && moment(startDate).format('DD MMM YYYY');
    const contractEndDate = endDate && moment(endDate).format('DD MMM YYYY');
    const contractNextDebitDate = nextDebitDate && moment(nextDebitDate).format('DD MMM YYYY');

    const pauseStatus = useMemo(() => {
        if (!pause || !pause.from) {
            return '';
        }

        const pauseStartDate = moment.unix(pause.from).format('DD.MM.YYYY');
        const pauseEndDate = pause.unlimited
            ? t(`${T_PREFIX}.status.infinite`)
            : moment.unix(pause.to).format('DD.MM.YYYY');
        const status = pause.status ? 'paused' : 'willBePaused';
        return t(`${T_PREFIX}.status.statuses.${status}`, {
            startDate: pauseStartDate,
            endDate: pauseEndDate,
        });
    }, [
        pause?.status,
        pause?.from,
        pause?.to,
        pause?.unlimited,
        t,
    ]);

    const paymentStatus = useMemo(() => {
        if (!subscriptionStatus) {
            return '';
        }
        const status = t(`${T_PREFIX}.status.statuses.${STATUS_T_KEY_MAP[subscriptionStatus]}`);
        return (unpaidCounter && isOpenStatus)
            ? `${status} (${unpaidCounter})`
            : status;
    }, [
        subscriptionStatus,
        t,
        unpaidCounter,
        isOpenStatus,
    ]);

    const paymentInfoLabel = paymentsStatusInfo
        ? t(`${T_PREFIX}.status.paymentInfo.${paymentsStatusInfo}`)
        : null;

    const paymentTypeLabel = t(`${T_PREFIX}.status.paymentType.${paymentType}`);

    return (
        <>
            <ListTableBodyCell>
                <span>
                    {name}
                </span>
                <br />
                {Boolean(updatingAt && updatingSubscription) && (
                    <span className={styles.pauseStatus}>
                        {`${t(`${T_PREFIX}.changeSubscription.from`)} ${updatingAtData} ${updatingSubscription?.name}`}
                    </span>
                )}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                {contractStartDate}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                {contractEndDate}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                {contractNextDebitDate}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted" align="right">
                {formatPrice.toEuroWithComma({
                    amount,
                })}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={styles.lastColumn}
                colSpan={2}
            >
                <div
                    className={classNames(styles.statusCell, {
                        [styles.paid]: isPaidStatus,
                        [styles.unpaid]: isOpenStatus,
                    })}
                >
                    <span>
                        {Boolean(paymentStatus) && `${paymentStatus} (${paymentInfoLabel || paymentTypeLabel})`}
                    </span>
                    <span className={styles.pauseStatus}>
                        {pauseStatus}
                    </span>
                </div>
                {false
                && (
                    <ContextMenu
                        ref={actionsMenuControlsRef}
                        placement="bottom-end"
                        menuContainerClassName={styles.actionsMenuContainer}
                        Menu={(
                            <div className={styles.moreActionsContainer}>
                                <button
                                    type="button"
                                    className={styles.moreActionButton}
                                    onClick={onEdit}
                                >
                                    <PenIcon className={styles.moreActionButtonIcon} />
                                    <span>
                                        {t(`${T_PREFIX}.actions.edit`)}
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className={classNames(
                                        styles.moreActionButton,
                                        styles.delete,
                                    )}
                                    onClick={onDelete}
                                >
                                    <Trash2 className={styles.moreActionButtonIcon} />
                                    <span>
                                        {t(`${T_PREFIX}.actions.delete`)}
                                    </span>
                                </button>
                            </div>
                        )}
                    >
                        <IconButton>
                            <MoreHorizontal />
                        </IconButton>
                    </ContextMenu>
                )}
            </ListTableBodyCell>
        </>
    );
};

ClientSubscriptionsTableRow.propTypes = {
    subscription: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};

ClientSubscriptionsTableRow.defaultProps = {
    subscription: {
        id: 123,
        name: 'Service 1 + Service 2',
        contract: {
            startDate: '10 Mar 2020',
            endDate: '10 Mar 2020',
            nextDebitDate: '1 Mar 2021',
        },
        price: '49.90',
        status: 'paid',
    },
    onEdit: emptyFunc,
    onDelete: emptyFunc,
};

export default ClientSubscriptionsTableRow;
