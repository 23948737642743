// extracted by mini-css-extract-plugin
var _1 = "ClientReviewsTableRow_alignTop__dnxBI";
var _2 = "ClientReviewsTableRow_comment__bWNoD";
var _3 = "ClientReviewsTableRow_grayField__HcUfS";
var _4 = "ClientReviewsTableRow_greenField__dPaw6";
var _5 = "ClientReviewsTableRow_hide__TYbc9";
var _6 = "ClientReviewsTableRow_iconButton__3ZU4l";
var _7 = "ClientReviewsTableRow_redField__JUPSn";
var _8 = "ClientReviewsTableRow_yellowField__MXBEA";
export { _1 as "alignTop", _2 as "comment", _3 as "grayField", _4 as "greenField", _5 as "hide", _6 as "iconButton", _7 as "redField", _8 as "yellowField" }
