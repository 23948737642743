const { useRef, useEffect } = require('react');

function useDidUpdateEffect(fn, inputs) {
    const isMountingRef = useRef(false);

    useEffect(() => {
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        if (!isMountingRef.current) {
            return fn();
        }
        isMountingRef.current = false;
    }, inputs);
}

export default useDidUpdateEffect;
