import { combineReducers } from 'redux';

import { bundlesListReducer } from './bundlesList';
import { soldBundlesReducer } from './soldBundles';
import { bundleItemReducer } from './bundleItem';
import { companyBundlesReducer } from './companyBundles';

export const bundlesReducer = combineReducers({
    bundles: bundlesListReducer,
    soldBundles: soldBundlesReducer,
    bundle: bundleItemReducer,
    companyBundles: companyBundlesReducer,
});
