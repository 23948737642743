/* eslint-disable import/prefer-default-export */
import { PAYOUT_SORT_BY_TYPE } from '../../../const/payouts/PAYOUT_SORT_BY_TYPE';
import { PAYOUT_TRANSACTION_TYPE } from '../../../const/payouts/PAYOUT_TRANSACTION_TYPE';
import {
    PAYOUTS_LOADING_SET,
    PAYOUTS_PAGE_SET,
    PAYOUTS_PAGE_SET_FIRST,
    PAYOUTS_PAGE_SET_LAST,
    PAYOUTS_PAGE_SET_NEXT,
    PAYOUTS_PAGE_SET_PREV,
    PAYOUTS_SET,
    PAYOUTS_SORT_OPTIONS_SET,
    PAYOUT_TRANSACTIONS_SET,
    PAYOUT_TRANSACTIONS_LOADING_SET,
    PAYOUT_SELECTED_ID_SET,
    PAYOUT_TRANSACTIONS_TYPE_SET,
    PAYOUT_TRANSACTIONS_PAGE_SET,
    PAYOUT_TRANSACTIONS_PAGE_SET_FIRST,
    PAYOUT_TRANSACTIONS_PAGE_SET_LAST,
    PAYOUT_TRANSACTIONS_PAGE_SET_PREV,
    PAYOUT_TRANSACTIONS_PAGE_SET_NEXT,
} from '../../actions/financials/payouts/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    sortOptions: {
        sortBy: PAYOUT_SORT_BY_TYPE.CREATED,
        orderBy: 1,
    },
    payoutSelectedId: null,
    payoutTransactionsType: PAYOUT_TRANSACTION_TYPE.PAYMENTS,
    payoutTransactionsPage: 1,
    payoutTransactions: [],
    payoutTransactionsShowAll: false,
    payoutTransactionsLoading: false,
    payoutTransactionsItemsCount: 0,
    payoutTransactionsPagesCount: 0,
};

export const payoutsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (PAYOUTS_SET): {
        const { items, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items,
            page,
            itemsCount,
            pagesCount,
        };
    }
    case (PAYOUTS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (PAYOUTS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (PAYOUTS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (PAYOUTS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (PAYOUTS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (PAYOUTS_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    case (PAYOUTS_SORT_OPTIONS_SET): {
        const { sortOptions: { sortBy, orderBy } } = payload;
        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    case (PAYOUT_SELECTED_ID_SET): {
        const { payoutId } = payload;
        return {
            ...state,
            payoutSelectedId: payoutId,
            payoutTransactionsType: PAYOUT_TRANSACTION_TYPE.PAYMENTS,
        };
    }
    case (PAYOUT_TRANSACTIONS_TYPE_SET): {
        const { type } = payload;
        return {
            ...state,
            payoutTransactionsType: type,
            payoutTransactionsPage: 1,
        };
    }
    case (PAYOUT_TRANSACTIONS_SET): {
        const { items, itemsCount, pagesCount } = payload;
        const payoutTransactionsPage = Math.min(state.payoutTransactionsPage, pagesCount || 1);
        return {
            ...state,
            payoutTransactions: items,
            payoutTransactionsPage,
            payoutTransactionsItemsCount: itemsCount,
            payoutTransactionsPagesCount: pagesCount,
        };
    }
    case (PAYOUT_TRANSACTIONS_LOADING_SET): {
        const { payoutTransactionsLoading } = payload;
        return {
            ...state,
            payoutTransactionsLoading,
        };
    }

    case (PAYOUT_TRANSACTIONS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            payoutTransactionsPage: page,
            payoutTransactionsShowAll: page === 0,
        };
    }
    case (PAYOUT_TRANSACTIONS_PAGE_SET_FIRST): {
        return {
            ...state,
            payoutTransactionsPage: 1,
            payoutTransactionsShowAll: false,
        };
    }
    case (PAYOUT_TRANSACTIONS_PAGE_SET_LAST): {
        return {
            ...state,
            payoutTransactionsPage: state.payoutTransactionsPagesCount,
            payoutTransactionsShowAll: false,
        };
    }
    case (PAYOUT_TRANSACTIONS_PAGE_SET_PREV): {
        return {
            ...state,
            payoutTransactionsPage: Math.max(state.payoutTransactionsPage - 1, 1),
            payoutTransactionsShowAll: false,
        };
    }
    case (PAYOUT_TRANSACTIONS_PAGE_SET_NEXT): {
        return {
            ...state,
            payoutTransactionsPage: Math.min(
                state.payoutTransactionsPage + 1,
                state.payoutTransactionsPagesCount,
            ),
            payoutTransactionsShowAll: false,
        };
    }
    default:
        return state;
    }
};
