import React from 'react';

const GaleryIcon = ({
    className, width = '54', height = '57',
}) => (

    <svg
        width={width}
        height={height}
        viewBox="0 0 54 57"
        fill="none"
        className={className}
    >
        <circle cx="12.7621" cy="30.0951" r="4.33333" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
        <path d="M4.71411 55.4762L26.9998 36.9048L38.1427 46.8095" stroke="currentColor" strokeWidth="2" />
        <rect x="1.00024" y="15.8569" width="37.1429" height="39.619" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
        <path d="M8.4288 8.42871H45.5717V48.0478" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
        <path d="M15.8577 1H53.0005V40.619" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
    </svg>
);

export default GaleryIcon;
