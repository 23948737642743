import React from 'react';

const Mobile = ({ className }) => (
    <svg width="25" height="24" fill="none" className={className}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3994 4H8.39941C7.84713 4 7.39941 4.44772 7.39941 5V19C7.39941 19.5523 7.84713 20 8.39941 20H16.3994C16.9517 20 17.3994 19.5523 17.3994 19V5C17.3994 4.44772 16.9517 4 16.3994 4ZM8.39941 2C6.74256 2 5.39941 3.34315 5.39941 5V19C5.39941 20.6569 6.74256 22 8.39941 22H16.3994C18.0563 22 19.3994 20.6569 19.3994 19V5C19.3994 3.34315 18.0563 2 16.3994 2H8.39941Z"
            fill="currentColor"
        />
        <circle cx="12.3999" cy="16.5" r="1.5" fill="currentColor" />
    </svg>
);

export default Mobile;
