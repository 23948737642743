import { MESSAGES_ADD_PAGE, MESSAGES_EDIT_PAGE, MESSAGES_LIST_PAGE } from 'const/CLIENT_URL';
import MessagesList from './messagesList';
import AddOrEditMessage from './addOrEditMessage';

const routes = [
    {
        component: MessagesList,
        exact: true,
        path: MESSAGES_LIST_PAGE,
    },
    {
        component: AddOrEditMessage,
        exact: true,
        path: MESSAGES_ADD_PAGE,
    },
    {
        component: AddOrEditMessage,
        exact: true,
        path: `${MESSAGES_EDIT_PAGE}/:id`,
    },
];

export default routes;
