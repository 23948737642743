import React, { useCallback, useRef } from 'react';
import { Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';

import { IconButton } from 'components/Common/IconButton';
import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { HourInput } from '../../Common/HourInput';
import { DateInput } from '../../Common/DateInput';
import { Trash3Icon } from '../../Icon/Icon';

import * as styles from './DateCard.module.scss';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'dateCard';

const DateCard = (props) => {
    const {
        title,
        card,
        minDate,
        maxDate,
        minTime,
        maxTime,
        errors,
        onChange,
        onRemove,
        className,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const timesRef = useRef(null);

    const isShowStartDateField = card?.hasOwnProperty('startDate');
    const isShowTimeFields = card?.hasOwnProperty('startTime') && card?.hasOwnProperty('endTime');

    const onChangeData = useCallback((e) => {
        const newData = { ...card };

        newData[e.target.name] = e.target.value;

        onChange({ data: newData });
    }, [card, onChange]);

    const onChangeDateCallback = useCallback((name) => (value) => {
        onChangeData({ target: { name, value } });
    });

    const onCheckChangeData = useCallback((e) => {
        const newData = { ...card };

        let isNeedToUpdate = false;

        newData[name] = e.target;

        if (
            newData.startTime
            && newData.endTime
            && !timesRef.current.contains(e.relatedTarget)
            && newData.startTime.format('HH:mm') > newData.endTime.format('HH:mm')
        ) {
            const trueStartTime = newData.endTime;
            newData.endTime = newData.startTime;
            newData.startTime = trueStartTime;
            isNeedToUpdate = true;
        }

        if (isNeedToUpdate) {
            onChange({ data: newData });
        }
    }, [card, onChange]);

    if (!card) {
        return null;
    }

    return (
        <div className={classNames(styles.dateCardContainer, className)}>
            <div className={styles.header}>
                {title ?? t(`${T_PREFIX}.header.label`)}

                {Boolean(onRemove) && (
                    <IconButton
                        type="button"
                        color="transparent"
                        onClick={onRemove}
                    >
                        <Trash3Icon />
                    </IconButton>
                )}
            </div>

            <Form.Group className={styles.nameGroup}>
                <Form.Label className={styles.label}>
                    {t(`${T_PREFIX}.name.label`)}
                </Form.Label>

                <Form.Control
                    type="text"
                    name="name"
                    value={card.name}
                    isInvalid={errors.name}
                    onChange={onChangeData}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.name}
                </Form.Control.Feedback>
            </Form.Group>

            {isShowStartDateField && (
                <div className={styles.singleGroupWrapper}>
                    <Form.Group>
                        <Form.Label className={styles.label}>
                            {t(`${T_PREFIX}.startDate.label`)}
                        </Form.Label>

                        <DateInput
                            value={card.startDate}
                            onChange={onChangeDateCallback('startDate')}
                            isInvalid={errors.startDate}
                            format="DD/MM"
                            noDisplayYear
                            maxYear={maxDate.format('YYYY')}
                            minYear={minDate.format('YYYY')}
                            className={styles.dateInput}
                            placement="top"
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.startDate}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
            )}

            {isShowTimeFields && (
                <div
                    ref={timesRef}
                    onBlur={onCheckChangeData}
                    className={styles.multipleGroupWrapper}
                >
                    <Form.Group>
                        <Form.Label className={styles.label}>
                            {t(`${T_PREFIX}.startTime.label`)}
                        </Form.Label>

                        <HourInput
                            value={card.startTime}
                            allowAllHours
                            timespan={15}
                            min={minTime}
                            max={maxTime}
                            isInvalid={errors.startTime}
                            onChange={onChangeDateCallback('startTime')}
                            className={styles.timeInput}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.startTime}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className={styles.label}>
                            {t(`${T_PREFIX}.endTime.label`)}
                        </Form.Label>

                        <HourInput
                            value={card.endTime}
                            allowAllHours
                            timespan={15}
                            min={minTime}
                            max={maxTime}
                            isInvalid={errors.endTime}
                            onChange={onChangeDateCallback('endTime')}
                            className={styles.timeInput}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.endTime}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
            )}
        </div>
    );
};

DateCard.propTypes = {
    title: PropTypes.string,
    card: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    minTime: PropTypes.object,
    maxTime: PropTypes.object,
    errors: PropTypes.object,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
};

DateCard.defaultProps = {
    minDate: moment().add(-10, 'year'),
    maxDate: moment().add(10, 'year'),
    minTime: moment(),
    maxTime: moment(),
    errors: {},
    onChange: emptyFunc,
};

export default DateCard;
