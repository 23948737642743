import Agenda from 'routes/DashboardGroup/Agenda/Agenda';
import Access from './access';
import Statistics from './statistics';
import Financials from './financials';
import Services from './services';
import Marketing from './marketing';
import { ClientsPage } from './clients';
import Settings from './settings';
import SignUp from './signUp';
import SignIn from './signIn';
import ResetPassword from './resetPassword';
import ResetPasswordSuccess from './resetPasswordSuccess';
import SetNewPassword from './setNewPassword';

export const roles = {
    access: ['company_owner', 'admin'],
    agenda: ['all'],
    services: ['company_owner', 'admin'],
    clientsPage: ['company_owner', 'admin'],
    marketing: ['company_owner', 'admin'],
    statistics: ['company_owner', 'admin'],
    financials: ['company_owner', 'admin'],
    settings: ['company_owner', 'admin'],
    signUp: ['all'],
    signIn: ['all'],
    resetPassword: ['all'],
    resetPasswordSuccess: ['all'],
    setNewPassword: ['all'],
};

const routes = [
    {
        component: Access,
        authRequired: true,
        exact: false,
        path: '/access',
        roles: roles.access,
    },
    {
        component: Agenda,
        authRequired: true,
        exact: false,
        path: '/agenda',
        roles: roles.agenda,
    },
    {
        component: Services,
        authRequired: true,
        exact: false,
        path: '/services',
        roles: roles.services,
    },
    {
        component: ClientsPage,
        authRequired: true,
        exact: false,
        path: '/clients',
        roles: roles.clientsPage,
    },
    {
        component: Marketing,
        authRequired: true,
        exact: false,
        path: '/marketing',
        roles: roles.marketing,
    },
    {
        component: Statistics,
        authRequired: true,
        exact: true,
        path: '/statistics',
        roles: roles.statistics,
    },
    {
        component: Financials,
        authRequired: true,
        exact: false,
        path: '/financials',
        roles: roles.financials,
    },
    {
        component: Settings,
        authRequired: true,
        exact: false,
        path: '/settings',
        roles: roles.settings,
    },
    {
        component: SignUp,
        authRequired: false,
        exact: true,
        path: '/sign-up',
        roles: roles.signUp,
    },
    {
        component: SignIn,
        authRequired: false,
        exact: true,
        path: '/sign-in',
        roles: roles.signIn,
    },
    {
        component: ResetPassword,
        authRequired: false,
        exact: true,
        path: '/reset-password',
        roles: roles.resetPassword,
    },
    {
        component: ResetPasswordSuccess,
        authRequired: false,
        exact: true,
        path: '/reset-password-success',
        roles: roles.resetPasswordSuccess,
    },
    {
        component: SetNewPassword,
        authRequired: false,
        exact: true,
        path: '/set-new-password',
        roles: roles.setNewPassword,
    },
];

export default routes;
