import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import * as styles from './Typography.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    red: styles.red,
    green: styles.green,
};

const Typography = (props) => (
    <span
        className={classNames(
            props.className,
            styles.typography,
            colorClasses[props.color],
            props.uppercase && styles.uppercase,
            props.ellipsis && styles.ellipsis,
            styles[props.align],
        )}
        htmlFor={props.htmlFor}
        data-testid={props['data-testid']}
    >
        {props.children}
        {props.after}
    </span>
);

Typography.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    ellipsis: PropTypes.bool,
    after: PropTypes.node,
    color: PropTypes.oneOf(['yellow', 'black', 'gray', 'red', 'green']),
    uppercase: PropTypes.bool,
    htmlFor: PropTypes.string,
    'data-testid': PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
};

Typography.defaultProps = {
    className: '',
    children: null,
    ellipsis: false,
    after: null,
    color: 'black',
    uppercase: false,
    htmlFor: null,
    'data-testid': null,
    align: 'left',
};

export default Typography;
