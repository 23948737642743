import React from 'react';

const Trash3 = ({ className, width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        className={className}
    >
        <path
            d="M12 3C11.1716 3 10.5 3.67157 10.5 4.5V5H6.5C5.94772 5 5.5 5.44772 5.5 6C5.5 6.55228 5.94772 7 6.5 7H17.5C18.0523 7 18.5 6.55228 18.5 6C18.5 5.44772 18.0523 5 17.5 5H13.5V4.5C13.5 3.67157 12.8284 3 12 3Z"
            fill="currentColor"
        />
        <path
            d="M6.39186 10.8141C6.2149 9.60696 7.15066 8.52402 8.37071 8.52402H15.6293C16.8493 8.52402 17.7851 9.60696 17.6081 10.8141L16.3656 19.2901C16.2217 20.2721 15.3793 21 14.3868 21H9.61322C8.6207 21 7.77833 20.2721 7.63437 19.2901L6.39186 10.8141Z"
            fill="currentColor"
        />
    </svg>
);

export default Trash3;
