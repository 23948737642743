import { createSelector } from 'reselect';

const permissionsSelector = (state) => state.permissions.preset;

export const permissionsPresetSelector = createSelector(
    permissionsSelector,
    (preset) => (preset ? ({
        section: preset.section,
        booking: preset.booking,
        groupSession: preset.groupSession,
        event: preset.event,
        resource: preset.resource,
        block: preset.block,
        shop: preset.shop,
    }) : null),
);

export const permissionsGroupSessionSelector = createSelector(
    permissionsSelector,
    (preset) => ({
        banCancel: preset?.groupSession.cancel === false,
        banBooking: preset?.groupSession.addBooking === false,
        banOverride: preset?.groupSession.override === false,
        banSendNotification: preset?.groupSession.sendNotification === false,
    }),
);

export const permissionsEventSelector = createSelector(
    permissionsSelector,
    (preset) => ({
        banCancel: preset?.event.cancel === false,
        banBooking: preset?.event.addBooking === false,
        banSendNotification: preset?.event.sendNotification === false,
    }),
);

export const permissionsBookingSelector = (isRecurring) => createSelector(
    permissionsSelector,
    (preset) => ({
        banAdd: preset?.booking.add === false,
        banEdit: preset?.booking.edit === false,
        banDelete: isRecurring ? {
            oneRecurring: preset?.booking.deleteOneRecurring === false,
            wholeRecurring: preset?.booking.deleteWholeRecurring === false,
        } : preset?.booking.deleteRegular === false,
        banView: preset?.booking.view === false,
        banSetPaid: preset?.booking.setPaid === false,
        banSetPresent: preset?.booking.setPresent === false,
        banNewClient: preset?.booking.newClient === false,
    }),
);

export const permissionsSectionsSelector = createSelector(
    permissionsSelector,
    (preset) => ({
        allowObject: preset?.section.object,
        allowEmployee: preset?.section.employee,
        allowGroup: preset?.section.group,
        allowEvent: preset?.section.event,
    }),
);

export const permissionsShopSelector = createSelector(
    permissionsSelector,
    (preset) => ({
        banSwitchShop: preset?.shop.switch === false,
    }),
);

export const permissionsResourceSelector = createSelector(
    permissionsSelector,
    (preset) => ({
        banAdd: preset?.resource.add === false,
        banEdit: preset?.resource.edit === false,
        banDelete: preset?.resource.delete === false,
    }),
);

export const permissionsBlockSelector = createSelector(
    permissionsSelector,
    (preset) => ({
        banAdd: preset?.block.add === false,
        banEdit: preset?.block.edit === false,
        banAddResource: preset?.block.addToResource === false,
    }),
);
