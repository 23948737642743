import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDialog } from 'hooks/useDialog';

import { InvoicesExportDialog } from 'components/Financials/Invoices/InvoicesExportDialog';
import { InvoicesClearingDialog } from 'components/Financials/Invoices/InvoicesClearingDialog';
import { InvoicesPageMobileFooter } from 'components/Financials/Invoices/InvoicesPageMobileFooter';

import { InvoicesPageHeader } from 'components/Financials/Invoices/InvoicesPageHeader';
import { InvoicesTableHeader } from 'components/Financials/Invoices/InvoicesTableHeader';
import { InvoicesTableBodyRow } from 'components/Financials/Invoices/InvoicesTableBodyRow';

import * as INVOICES_ACTIONS from 'store/actions/financials/invoices';
import * as FINANCIALS_SELECTORS from 'store/selectors/financilas';

import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import Container from 'components/Layout/Container/Container';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { EmptyTable } from 'components/Common/NewTable/EmptyTable';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 9;

const InvoicesList = () => {
    const dispatch = useDispatch();

    const invoicesExportDialog = useDialog(false);
    const invoicesClearingDialog = useDialog(false);

    const {
        page,
        items: invoices,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        filters,
        search,
    } = useSelector(FINANCIALS_SELECTORS.invoicesListSelector);

    const invoicesList = useMemo(() => (
        (!showAll && invoices.length > ITEMS_PER_PAGE)
            ? invoices.slice(0, ITEMS_PER_PAGE)
            : invoices
    ), [showAll, invoices]);

    const handlePrevPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(INVOICES_ACTIONS.setInvoicesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(INVOICES_ACTIONS.setInvoicesPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(INVOICES_ACTIONS.getInvoices());
    }, [
        dispatch,
        page,
        showAll,
        filters,
        search,
    ]);

    const handleSearch = useCallback((nextSearch) => {
        dispatch(INVOICES_ACTIONS.setInvoicesSearch({ search: nextSearch }));
    }, [dispatch]);

    useEffect(() => () => {
        dispatch(INVOICES_ACTIONS.clearInvoicesFilters());
        dispatch(INVOICES_ACTIONS.setExportPeriod({ period: 'month' }));
    }, []);

    return (
        <>
            <InvoicesPageHeader
                onExport={invoicesExportDialog.onShow}
                searchValue={search}
                onSearch={handleSearch}
            />
            <Container>
                <Content loading={loading}>
                    {
                        !!itemsCount
                            && (
                                <Table>
                                    <TableHeader>
                                        <InvoicesTableHeader />
                                    </TableHeader>
                                    <TableBody>
                                        {invoicesList.map((invoice) => (
                                            <ListTableBodyRow key={invoice.id} data-testid={`data-test-invoice-list-row-${invoice.fullNumber}`}>
                                                <InvoicesTableBodyRow
                                                    invoice={invoice}
                                                />
                                            </ListTableBodyRow>
                                        ))}
                                    </TableBody>
                                    <ListTableFooter
                                        page={page}
                                        showAll={showAll}
                                        itemsCount={itemsCount}
                                        pagesCount={pagesCount}
                                        columnsCount={COLUMNS_COUNT}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        onShowAll={handleShowAll}
                                        onPrevPage={handlePrevPage}
                                        onNextPage={handleNextPage}
                                        onLastPage={handleLastPage}
                                        onFirstPage={handleFirstPage}
                                        onShowPages={handleShowPages}
                                        onChangePage={handleChangePage}
                                    />
                                </Table>
                            )
                    }
                    {
                        !itemsCount && <EmptyTable />
                    }
                </Content>
                <InvoicesPageMobileFooter
                    searchValue={search}
                    onSearch={handleSearch}
                />

                <InvoicesClearingDialog
                    isVisible={invoicesClearingDialog.visible}
                    onClose={invoicesClearingDialog.onClose}
                />

                <InvoicesExportDialog
                    isVisible={invoicesExportDialog.visible}
                    onClose={invoicesExportDialog.onClose}
                />
            </Container>
        </>
    );
};

export default InvoicesList;
