import React from 'react';
import PropTypes from 'prop-types';

import { TailSpin } from 'react-loader-spinner';
import * as styles from './Content.module.scss';

const Content = (props) => (
    props.loading ? (
        <div
            className={styles.loader}
        >
            <TailSpin
                color="black"
                width={100}
                height={100}
            />
        </div>
    ) : (
        <div
            className={styles.container}
            style={{ maxWidth: props.maxWidth }}
        >
            {props.children}
        </div>
    )
);

Content.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node.isRequired,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Content.defaultProps = {
    loading: false,
    maxWidth: '100%',
};

export default Content;
