import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './TableHeaderCell.module.scss';

const TableHeaderCell = (props) => {
    const {
        children,
        className,
        colSpan,
    } = props;

    return (
        <th
            className={classNames(styles.headerCell, className)}
            colSpan={colSpan}
        >
            {children}
        </th>
    );
};

TableHeaderCell.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    colSpan: PropTypes.number,
};

TableHeaderCell.defaultProps = {
    children: null,
    className: '',
    colSpan: null,
};

export default TableHeaderCell;
