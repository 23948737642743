import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Check } from 'react-feather';

import { useTranslation } from 'react-i18next';

import { Close } from 'components/Icon/Icon';
import * as COMPANY_SELECTORS from 'store/selectors/company';
import { useSelector } from 'react-redux';
import { Tooltip } from '../../../../Common/Tooltip';

import { LOCALE_NAMESPACE } from '../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ProfilePermission.module.scss';

const T_PREFIX = 'profile.details';

const ProfilePermissions = (props) => {
    const { client } = props;
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { accessEnabled, accessCardsEnabled } = useSelector(COMPANY_SELECTORS.companyAccessModeSelector);

    const accessCardsCodeEnabled = Boolean(accessEnabled && accessCardsEnabled);

    return (
        <Col className={
            classNames(
                styles.profileWalletContainer,
                'border-right-0',
            )
        }
        >
            <Row className={styles.profileWalletLine}>
                <Col className={classNames(styles.lineName)}>
                    {t(`${T_PREFIX}.fields.disallowPayment.label`)}
                </Col>
                <Col className={styles.lineContent}>
                    { client?.disallowPaymentInShop
                        ? (
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.fields.disallowPayment.disallowed`)}
                                placement="top-start"
                            >
                                <Check
                                    className={styles.lineName}
                                    size={20}
                                />
                            </Tooltip>
                        )
                        : (
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.fields.disallowPayment.allowed`)}
                                placement="top-start"
                            >
                                <span>
                                    <Close
                                        className={styles.lineName}
                                        height={20}
                                        width={20}
                                    />
                                </span>
                            </Tooltip>
                        )}
                </Col>
            </Row>
            <Row className={styles.profileWalletLine}>
                <Col className={styles.lineName}>
                    {t(`${T_PREFIX}.fields.block.label`)}
                </Col>
                <Col className={styles.lineContent}>
                    { client?.block
                        ? (
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.fields.block.blocked`)}
                                placement="top-start"
                            >
                                <Check
                                    className={styles.lineName}
                                    size={20}
                                />
                            </Tooltip>
                        )
                        : (
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.fields.block.unblocked`)}
                                placement="top-start"
                            >
                                <span>
                                    <Close
                                        className={styles.lineName}
                                        height={20}
                                        width={20}
                                    />
                                </span>
                            </Tooltip>
                        )}
                </Col>
            </Row>
            {accessCardsCodeEnabled && (
                <>
                    <Row className={styles.lineName}>
                        {t(`${T_PREFIX}.fields.accessCardCode.label`)}
                    </Row>
                    <Row
                        className={classNames(
                            styles.lineContent,
                            styles.note,
                        )}
                    >
                        { client?.accessCardCode }
                    </Row>
                </>
            )}
        </Col>
    );
};

ProfilePermissions.propTypes = {
    client: PropTypes.object,
};

ProfilePermissions.defaultProps = {
    client: null,
};

export default ProfilePermissions;
