import React from 'react';
import PropTypes from 'prop-types';

const Cake = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M13.6 3.5H9.05C8.91193 3.5 8.8 3.38807 8.8 3.25V1.4C8.8 1.17909 8.62091 1 8.4 1H7.6C7.37909 1 7.2 1.17909 7.2 1.4V3.25C7.2 3.38807 7.08807 3.5 6.95 3.5H2.4C1.0768 3.5 0 4.62167 0 6V14.3333C0 15.2525 0.7176 16 1.6 16H14.4C15.2824 16 16 15.2525 16 14.3333V6C16 4.62167 14.9232 3.5 13.6 3.5ZM2.4 5.16667H13.6C13.8122 5.16667 14.0157 5.25446 14.1657 5.41074C14.3157 5.56702 14.4 5.77899 14.4 6L14.4008 8.6C13.684 8.79 13.2256 9.21167 12.868 9.54333C12.4624 9.91833 12.1944 10.1667 11.5976 10.1667C11.0016 10.1667 10.7336 9.91833 10.328 9.54417C9.852 9.10333 9.2008 8.5 7.996 8.5C6.792 8.5 6.1408 9.10333 5.6648 9.54417C5.2592 9.91917 4.992 10.1667 4.396 10.1667C3.8016 10.1667 3.5344 9.91917 3.1304 9.54417C2.7728 9.2125 2.316 8.79 1.6 8.6V6C1.6 5.77899 1.68429 5.56702 1.83431 5.41074C1.98434 5.25446 2.18783 5.16667 2.4 5.16667ZM1.85 14.3333C1.71193 14.3333 1.6 14.2214 1.6 14.0833V10.405C1.7528 10.5075 1.8992 10.6333 2.0664 10.7892C2.5424 11.23 3.1928 11.8333 4.396 11.8333C5.6 11.8333 6.2512 11.23 6.7272 10.7892C7.1328 10.4142 7.4 10.1667 7.996 10.1667C8.592 10.1667 8.86 10.415 9.2656 10.7892C9.7416 11.23 10.3928 11.8333 11.5976 11.8333C12.8024 11.8333 13.4544 11.23 13.9304 10.7892C14.0992 10.6325 14.2464 10.5058 14.4008 10.4025L14.4016 14.0833C14.4016 14.2214 14.2896 14.3333 14.1516 14.3333H1.85Z"
                fill="currentColor"
            />
        </svg>
    );
};

Cake.propTypes = {
    className: PropTypes.string,
};

Cake.defaultProps = {
    className: '',
};

export default Cake;
