import {
    put, select, call, takeEvery,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import moment from 'moment';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import sentry from '../../services/sentry';

// Actions
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';

// Utils
import axios from '../../services/axios';

const englishDays = {
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday',
};

const dutchDays = {
    monday: 'maandag',
    tuesday: 'dinsdag',
    wednesday: 'woensdag',
    thursday: 'donderdag',
    friday: 'vrijdag',
    saturday: 'zaterdag',
    sunday: 'zondag',
};

const germanDays = {
    monday: 'montag',
    tuesday: 'dienstag',
    wednesday: 'mittwoch',
    thursday: 'donnerstag',
    friday: 'freitag',
    saturday: 'samstag',
    sunday: 'sonntag',
};

const lithuanianDays = {
    monday: 'pirmadienis',
    tuesday: 'antradienis',
    wednesday: 'trečiadienis',
    thursday: 'ketvirtadienis',
    friday: 'penktadienis',
    saturday: 'šeštadienis',
    sunday: 'sekmadienis',
};

const translateDay = (day, locale) => {
    switch (locale) {
    case 'en':
        return englishDays[day];
    case 'nl':
        return dutchDays[day];
    case 'de':
        return germanDays[day];
    case 'lt':
        return lithuanianDays[day];
    default:
        return englishDays[day];
    }
};

export function* getGroups(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/product/group`,
            headers: defaultHeaders,
        });

        const groups = Object.values(resp.data.result).map((group) => ({
            id: group.id,
            image: group.photo_url,
            name: group.name,
            description: group.description,
            sessions: Array.isArray(group.group_sessions) && group.group_sessions?.map((session) => {
                const translatedDay = translateDay(session.weekday, defaultHeaders.locale);

                return {
                    id: session.id,
                    price: parseFloat(session.cost),
                    time: {
                        from: moment(session.time * 1000).isoWeekday(translatedDay).valueOf(),
                        to: moment(session.time * 1000).isoWeekday(translatedDay).add(session.duration, 'minutes').valueOf(),
                    },
                    places: {
                        total: session.total,
                        show: session.show_sessions_left,
                    },
                    service: {
                        id: session.serviceId,
                        name: session.serviceName,
                    },
                    product: session.employee_id && {
                        id: session.employee_id,
                        name: session.employee_name,
                        type: 'employee',
                    },
                    address: {
                        street: session.street,
                        zip: session.zip,
                        city: session.city,
                        country: session.country,
                        latitude: session.latitude,
                        longitude: session.longitude,
                    },
                    contactNumber: session.contact_number,
                    isCancelled: session.isCancelled,
                };
            }),
            startDate: group.start_date,
            endDate: group.end_date,
            futureBooking: group.future_booking,
        }));

        yield put(actions.getGroupsSucceeded(groups));
    } catch (err) {
        yield put(actions.getGroupsFailed());
    }
}

export function* getGroupSessions(action) {
    try {
        const { from, to } = action;
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/group-sessions?from=${from}&to=${to}`,
            headers: defaultHeaders,
        });

        const groupSessions = resp.data.result.map((session) => ({
            id: session.id,
            group: {
                id: session.roomId,
                name: session.groupName,
            },
            price: parseFloat(session.cost),
            time: {
                from: moment(session.timestamp * 1000).valueOf(),
                to: moment(session.timestamp * 1000).add(session.duration, 'minutes').valueOf(),
            },
            places: {
                available: session.available,
                total: session.total,
            },
            service: {
                id: session.serviceId,
                name: session.serviceName,
            },
            product: session.employee_id && {
                id: session.employee_id,
                name: session.employee_name,
                type: 'employee',
            },
            address: {
                street: session.street,
                zip: session.zip,
                city: session.city,
                country: session.country,
                latitude: session.latitude,
                longitude: session.longitude,
            },
            contactNumber: session.contact_number,
            isCancelled: session.isCancelled,
            isFull: session.isClose,
        }));

        yield put(actions.getGroupSessionsSucceeded(groupSessions));
        yield put(actions.setBookingsFetchRange(from, to));
    } catch (err) {
        yield put(actions.getGroupSessionsFailed());
    }
}

export function* getGroupSessionBookings(action) {
    try {
        const { sessionID, time } = action;
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/group-session-bookings/${sessionID}?timestamp=${moment(time).unix()}`,
            headers: defaultHeaders,
        });

        const bookings = resp.data.result.map((booking) => ({
            id: booking.id,
            service: {
                name: booking.service,
            },
            groupSession: {
                id: booking.group_session_id,
            },
            time: {
                from: moment(booking.timestamp * 1000).valueOf(),
                to: moment(booking.timestamp * 1000).add(booking.minutes, 'minutes').valueOf(),
                extraMinutes: booking.add_time ?? 0,
                length: booking.length,
            },
            client: {
                id: booking.client.id,
                name: booking.client.name,
                firstName: booking.client.firstName,
                lastName: booking.client.lastName,
                contactNumber: booking.client.phone,
                email: booking.client.email,
                note: booking.client.note,
            },
            boughtWithBundle: booking.is_bought_with_bundle,
            payment: {
                paidUpfront: booking.paid ? booking.final_cost : 0,
                finalCost: booking.final_cost,
                remainingCost: booking.paid ? 0 : booking.final_cost,
                paid: booking.paid,
                isInProgress: booking.in_payment_process,
                usedBundle: booking.is_bought_with_bundle,
                type: booking.payment_type,
                paymentSystem: booking.payment_system,
                paymentInfo: booking.payment_info,
            },
            cancellationPolicy: booking.cancellation_policy,
            recurrence: {
                enabled: booking.is_recurring,
                period: booking.number_of_weeks,
                infinite: booking.is_infinite_recurring,
                end: booking.recurrence_end ? booking.recurrence_end * 1000 : null,
                days: booking.recurrence_days.map((day) => day.replace(/^\w/, (c) => c.toUpperCase())),
            },
            seats: booking.seats_amount,
            feedback: booking.feedback && {
                rating: booking.feedback.rated,
                comment: booking.feedback.comment,
            },
            alreadyStarted: booking.has_already_started,
            notPresent: booking.not_present,
            isEditable: booking.is_editable,
            hasPast: booking.has_past,
        }));

        yield put(actions.getGroupSessionBookingsSucceeded(bookings));
    } catch (err) {
        yield put(actions.getGroupSessionBookingsFailed());
    }
}

export function* addGroup(action) {
    const { group } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = new FormData();

        data.append('name', group.name);
        data.append('description', group.description);
        data.append('image', group.image);
        Object.entries(group.sessions).map(([day, sessions]) => {
            sessions.map(({
                from,
                maxRegistrations,
                showNumberOfRegistrations,
                service: { id: serviceID },
                employee,
            }, i) => {
                data.append(`groupSessions[${day.toLowerCase()}][${i}][timestamp]`, from.unix());
                data.append(`groupSessions[${day.toLowerCase()}][${i}][maxRegistrations]`, maxRegistrations);
                data.append(`groupSessions[${day.toLowerCase()}][${i}][showSessionsLeft]`, showNumberOfRegistrations);
                data.append(`groupSessions[${day.toLowerCase()}][${i}][service]`, serviceID);

                if (employee) {
                    data.append(`groupSessions[${day.toLowerCase()}][${i}][employee]`, employee.id);
                }
            });
        });
        data.append('contactNumber', group.contactNumber);
        data.append('street', group.address.street);
        data.append('zip', group.address.zip);
        data.append('city', group.address.city);
        data.append('country', group.address.country);
        data.append('latitude', group.address.latitude);
        data.append('longitude', group.address.longitude);
        if (group.time.from) {
            data.append('startDate', group.time.from.unix());
        }
        if (group.time.to) {
            data.append('endDate', group.time.to.unix());
        }
        if (group.futureBooking) { // future bookings in weeks
            data.append('futureBookingsInDays', group.futureBooking);
        }

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/add/group`,
            data,
            headers: defaultHeaders,
        });

        const resultGroup = {
            id: resp.data.result.id,
            image: resp.data.result.photo_url,
            name: resp.data.result.name,
            description: resp.data.result.description,
            sessions: resp.data.result.group_sessions.map((session) => ({
                id: session.id,
                price: parseFloat(session.cost),
                time: {
                    from: moment(session.time * 1000).isoWeekday(moment(session.weekday, 'dddd').isoWeekday()).valueOf(),
                    to: moment(session.time * 1000).isoWeekday(moment(session.weekday, 'dddd').isoWeekday()).add(session.duration, 'minutes').valueOf(),
                },
                places: {
                    total: session.total,
                    show: session.show_sessions_left,
                },
                service: {
                    id: session.serviceId,
                    name: session.serviceName,
                },
                product: session.employee_id && {
                    id: session.employee_id,
                    name: session.employee_name,
                    type: 'employee',
                },
                address: {
                    street: session.street,
                    zip: session.zip,
                    city: session.city,
                    country: session.country,
                    latitude: session.latitude,
                    longitude: session.longitude,
                },
                contactNumber: session.contact_number,
                isCancelled: session.isCancelled,
            })),
            startDate: group.time.from?.unix(),
            endDate: group.time.to?.unix(),
            futureBooking: group.futureBooking ?? null,
        };

        yield put(actions.addGroupSucceeded(resultGroup));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data && err.response.data.result && err.response.data.result.errors) {
            const { errors } = err.response.data.result;

            yield put(actions.addGroupFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    name: errors.name,
                    description: errors.description,
                    image: errors.image,
                    sessions: errors.groupSession,
                    contactNumber: errors.contactNumber,
                    'address.street': errors.street,
                    'address.zip': errors.zip,
                    'address.city': errors.city,
                    'address.country': errors.country,
                    'address.latitude': errors.latitude,
                    'address.longitude': errors.longitude,
                },
            });
            return;
        } if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.addGroupFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.addGroupFailed());
        yield call(rejectPromiseAction, action, {
            message: null,
            errors: null,
        });
    }
}

export function* editGroup(action) {
    const { group } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = new FormData();
        data.append('productId', group.id);
        if (group.image) {
            data.append('image', group.image);
        }
        data.append('name', group.name);
        data.append('description', group.description);
        data.append('allowLateBooking', group.allowLateBooking ? 1 : 0);
        Object.entries(group.sessions).map(([day, sessions]) => {
            sessions.map(({
                id, from, maxRegistrations, showNumberOfRegistrations, service: { id: serviceID }, employee,
            }, i) => {
                data.append(`groupSessions[${day.toLowerCase()}][${i}][id]`, id);
                data.append(`groupSessions[${day.toLowerCase()}][${i}][timestamp]`, from.unix());
                data.append(`groupSessions[${day.toLowerCase()}][${i}][maxRegistrations]`, maxRegistrations);
                data.append(`groupSessions[${day.toLowerCase()}][${i}][showSessionsLeft]`, showNumberOfRegistrations);
                data.append(`groupSessions[${day.toLowerCase()}][${i}][service]`, serviceID);

                if (employee) {
                    data.append(`groupSessions[${day.toLowerCase()}][${i}][employee]`, employee.id);
                }
            });
        });
        data.append('contactNumber', group.contactNumber);
        data.append('street', group.address.street);
        data.append('zip', group.address.zip);
        data.append('city', group.address.city);
        data.append('country', group.address.country);
        data.append('latitude', group.address.latitude);
        data.append('longitude', group.address.longitude);
        if (group.time.from) {
            data.append('startDate', group.time.from.unix());
        }
        if (group.time.to) {
            data.append('endDate', group.time.to.unix());
        }
        if (group.futureBooking) { // future bookings in weeks
            data.append('futureBookingsInDays', group.futureBooking);
        }

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/edit/group`,
            data,
            headers: defaultHeaders,
        });

        const resultGroup = {
            id: resp.data.result.id,
            image: resp.data.result.photo_url,
            name: resp.data.result.name,
            description: resp.data.result.description,
            sessions: Array.isArray(resp.data.result.group_sessions) && resp.data.result.group_sessions.map((session) => ({
                id: session.id,
                price: parseFloat(session.cost),
                time: {
                    from: moment(session.time * 1000).isoWeekday(moment(session.weekday, 'dddd').isoWeekday()).valueOf(),
                    to: moment(session.time * 1000).isoWeekday(moment(session.weekday, 'dddd').isoWeekday()).add(session.duration, 'minutes').valueOf(),
                },
                places: {
                    total: session.total,
                    show: session.show_sessions_left,
                },
                service: {
                    id: session.serviceId,
                    name: session.serviceName,
                },
                product: session.employee_id && {
                    id: session.employee_id,
                    name: session.employee_name,
                    type: 'employee',
                },
                address: {
                    street: session.street,
                    zip: session.zip,
                    city: session.city,
                    country: session.country,
                    latitude: session.latitude,
                    longitude: session.longitude,
                },
                contactNumber: session.contact_number,
                isCancelled: session.isCancelled,
            })),
            startDate: group.time.from?.unix(),
            endDate: group.time.to?.unix(),
            futureBooking: group.futureBooking ?? null,
        };

        yield put(actions.editGroupSucceeded(resultGroup));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err?.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;

            yield put(actions.editGroupFailed());

            if (typeof errors === 'string') {
                yield call(rejectPromiseAction, action, { message: errors });
                return;
            }

            yield call(rejectPromiseAction, action, {
                errors: {
                    name: errors.name,
                    description: errors.description,
                    image: errors.image,
                    sessions: errors.groupSession,
                    contactNumber: errors.contactNumber,
                    'address.street': errors.street,
                    'address.zip': errors.zip,
                    'address.city': errors.city,
                    'address.country': errors.country,
                    'address.latitude': errors.latitude,
                    'address.longitude': errors.longitude,
                },
            });
            return;
        } if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.editGroupFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.editGroupFailed());
        yield call(rejectPromiseAction, action, {
            message: null,
            errors: null,
        });
    }
}

export function* overrideGroupSession(action) {
    try {
        const { id, timestamp, maxPeople } = action.payload;

        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = new FormData();

        data.append('timestamp', timestamp);
        data.append('maxPeople', maxPeople);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/override/group/${id}`,
            data,
            headers: defaultHeaders,
        });

        yield put(actions.overrideGroupSessionSucceeded(id, timestamp * 1000, maxPeople));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;

            yield put(actions.overrideGroupSessionFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    maxPeople: errors.maxPeople,
                },
            });
            return;
        } if (err.response?.data) {
            const { message } = err.response.data?.result || {};

            yield put(actions.overrideGroupSessionFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }
        sentry.error(err);

        yield put(actions.overrideGroupSessionFailed());
        yield call(rejectPromiseAction, action, {
            message: null,
            errors: null,
        });
    }
}

export function* cancelGroupSession(action) {
    try {
        const { id, timestamp } = action.payload;

        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = new FormData();

        data.append('timestamp', timestamp);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/set-status/group/${id}`,
            data,
            headers: defaultHeaders,
        });

        yield put(actions.cancelGroupSessionSucceeded(id, timestamp * 1000));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result) {
            const { message } = err.response.data?.result || {};

            yield put(actions.cancelGroupSessionFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }
        yield put(actions.cancelGroupSessionFailed());
    }
}

export function* deleteGroupSession(action) {
    try {
        const { payload: { id } } = action;

        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/delete/${id}`,
            headers: defaultHeaders,
        });

        yield put(actions.deleteGroupSessionSucceeded(id));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.deleteGroupSessionFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.deleteGroupSessionFailed());
        yield call(rejectPromiseAction, action);
    }
}

export const groupsSaga = [
    takeEvery(actionTypes.GET_GROUPS, getGroups),
    takeEvery(actionTypes.GET_GROUP_SESSIONS, getGroupSessions),
    takeEvery(actionTypes.GET_GROUP_SESSION_BOOKINGS, getGroupSessionBookings),
    takeEvery(actions.addGroup, addGroup),
    takeEvery(actions.editGroup, editGroup),
    takeEvery(actions.overrideGroupSession, overrideGroupSession),
    takeEvery(actions.cancelGroupSession, cancelGroupSession),
    takeEvery(actions.deleteGroupSession, deleteGroupSession),
];
