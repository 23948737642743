import * as SERVICES_TYPES from '../../actions/services/types';

const INITIAL_STATE = {
    items: [],
    loading: false,
};

export const companyServicesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (SERVICES_TYPES.COMPANY_SERVICES_SET): {
        const { companyServices } = payload;
        return {
            ...state,
            items: companyServices,
        };
    }
    case (SERVICES_TYPES.COMPANY_SERVICES_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    default:
        return state;
    }
};
