import GatesList from './gatesList';
import GateEdit from './gateEdit';

import {
    ACCESS_GATES_PAGE,
    ACCESS_GATE_EDIT_PAGE,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: GateEdit,
        exact: true,
        path: ACCESS_GATE_EDIT_PAGE,
    },
    {
        component: GatesList,
        exact: true,
        path: ACCESS_GATES_PAGE,
    },
];

export default routes;
