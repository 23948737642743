import React from 'react';
import { Container } from 'react-bootstrap';

import { ClientDataBlock } from 'components/clients/ClientPage/ClientDataBlock';
import { ProfileNula } from 'components/clients/ClientPage/ProfileNula';
import * as CLIENTS_SELECTORS from 'store/selectors/clients';
import * as COMPANY_SELECTORS from 'store/selectors/company';
import { useSelector } from 'react-redux';

import { Column } from 'components/Common/Column';

const ClientInfoTable = () => {
    const { item: client } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);

    return (
        <Container
            fluid
            className="m-0 p-0 my-5 d-flex flex-column flex-grow-1"
        >
            <Column gap={16} stretched>
                <ClientDataBlock client={client} />
                {enableBusinessMode && <ProfileNula client={client} />}
            </Column>
        </Container>
    );
};

export default ClientInfoTable;
