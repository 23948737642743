import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';

import {
    Container, Row, Col,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import { Button } from 'components/Common/Button';
import { BackButton } from 'components/Common/BackButton';
import { useTimer } from '../../hooks/useTimer';

import LocalesDropdown from '../../components/LocalesDropdown/LocalesDropdown';

import login from '../../static/images/login.jpg';

import * as styles from './page.module.scss';

import { requestPasswordReset } from '../../store/actions';

const RESEND_DELAY_IN_SEC = 60;

const ResetPasswordSuccess = (props) => {
    const { location } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const timeAfterSending = location.state?.sendDate
        ? Math.min(moment().diff(location.state?.sendDate, 'seconds'), RESEND_DELAY_IN_SEC)
        : 0;

    const resendTimer = useTimer(RESEND_DELAY_IN_SEC - timeAfterSending);

    const onResendClick = useCallback((e) => {
        e.preventDefault();
        if (!location.state?.email) {
            return;
        }

        dispatch(requestPasswordReset(location.state?.email));
        resendTimer.onRestart();
    }, [
        location.state?.email,
        history,
    ]);

    useEffect(() => {
        resendTimer.onStart();
    }, []);

    if (!location.state?.email) {
        return <Redirect to="/" />;
    }

    return (
        <Container className="d-flex flex-column justify-content-center align-items-end">
            <div className="w-100 bg-white rounded shadow-container">
                <Row>
                    <Col md={6} className="d-none d-md-block pr-md-0">
                        <img className="w-100 h-100 object-fit-cover rounded-left" src={login} alt="" />
                    </Col>

                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center pl-md-0">
                        <div className="d-flex flex-column justify-content-center h-100 p-3 pt-5 px-md-4 px-lg-5 pt-md-5 pb-md-0">

                            <BackButton
                                onClick={history.goBack}
                            />

                            <h1 className="mt-4">
                                {t('resetPasswordSuccessRoute.title.label')}
                            </h1>

                            <h2 className={classNames('font-weight-normal text-muted', styles.description)}>
                                <Trans
                                    i18nKey="resetPasswordSuccessRoute.description"
                                    components={[<span className="text-primary" />]}
                                    values={{ email: location.state.email }}
                                />
                            </h2>

                            <Button
                                onClick={onResendClick}
                                color="yellow"
                                disabled={resendTimer.value}
                            >
                                {resendTimer.value
                                    ? t(
                                        'resetPasswordSuccessRoute.actions.resend.disabled',
                                        { seconds: resendTimer.value > 9 ? resendTimer.value : `0${resendTimer.value}` },
                                    )
                                    : t('resetPasswordSuccessRoute.actions.resend.default')}
                            </Button>
                        </div>

                        <div className="d-flex flex-direction-row justify-content-end align-items-end px-3 px-md-4 px-lg-5 py-4 pt-md-0">
                            <LocalesDropdown />
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default ResetPasswordSuccess;
