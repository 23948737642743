import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';

import { emptyFunc } from 'helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../../const/translations/LOCALE_NAMESPACE';
import { STATUS_FILTER_VALUES } from '../../../../../const/clients/STATUS_FILTER_VALUES';

import { newSelectStyles, theme } from '../../../../../styles/select';

const T_PREFIX = 'list.search.filters';

const REGISTRATION_OPTIONS = {
    ALL: {
        value: '',
        type: 'all',
    },
    REGISTERED: {
        value: STATUS_FILTER_VALUES.REGISTERED,
        type: 'registered',
    },
    NOT_REGISTERED: {
        value: STATUS_FILTER_VALUES.NOT_REGISTERED,
        type: 'notRegistered',
    },
};

const RegisteredStatusSelect = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const dispatch = useDispatch();

    const {
        formik,
        onRegisterSelectChange,
    } = props;

    const {
        values,
        setFieldValue,
    } = formik;

    const registrationOptions = useMemo(() => (
        Object.values(REGISTRATION_OPTIONS).map((item) => ({
            label: t(`${T_PREFIX}.registered.${item.type}`),
            value: item.value,
        }))
    ), [REGISTRATION_OPTIONS, t]);

    const handleRegisterStatusChange = useCallback((selected) => {
        if (!selected) {
            setFieldValue('registered.value', null);
            return setFieldValue('registered.label', null);
        }

        const { value, label } = selected;
        setFieldValue('registered.value', value ?? null);
        setFieldValue('registered.label', label ?? null);
        onRegisterSelectChange({ registeredStatus: value });
    }, [setFieldValue, dispatch]);

    return (
        <Select
            name="registration"
            isSearchable={false}
            theme={theme}
            styles={newSelectStyles}
            options={registrationOptions}
            value={values.registered ? {
                value: values?.registered?.value,
                label: values?.registered?.label,
            } : null}
            placeholder={t(`${T_PREFIX}.registered.registered`)}
            onChange={handleRegisterStatusChange}
        />
    );
};

RegisteredStatusSelect.propTypes = {
    formik: PropTypes.object,
    onRegisterSelectChange: PropTypes.func,
};

RegisteredStatusSelect.defaultProps = {
    formik: null,
    onRegisterSelectChange: emptyFunc,
};

export default RegisteredStatusSelect;
