import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
    AllowRouteWrapper,
    LayoutContainer,
    SubHeader,
} from 'components';
import { useDispatch, useSelector } from 'react-redux';

import routes from './scenes';
import { permissionsPresetSelector } from '../../store/selectors/permissions';
import { getMessages, selectShop } from '../../store/actions';

function Marketing() {
    const { sections, shops } = useSelector(({ company }) => company);
    const { id: shopID, name: shopName } = useSelector((state) => state.shop);
    const preset = useSelector(permissionsPresetSelector);
    const dispatch = useDispatch();

    const onSelectShop = (id) => {
        if (id === shopID) {
            return;
        }
        dispatch(selectShop({ id: parseInt(id), name: shops.find((shop) => shop.id === parseInt(id)).name }));
        dispatch(getMessages());
    };

    return (
        <LayoutContainer>
            <>
                <SubHeader
                    isDropdownDataLoaded={Boolean(shopName && shops)}
                    isNavigationDataLoaded
                    dropdownOptions={shops}
                    onDropdownItemSelect={onSelectShop}
                    shopId={shopID}
                    shopName={shopName}
                />

                <Switch>
                    {
                        routes.map(({ component, exact, path }) => (
                            <AllowRouteWrapper
                                key={path}
                                path={path}
                                sections={sections}
                                permissionSections={preset?.section}
                            >
                                <Route
                                    key={path}
                                    path={path}
                                    exact={exact}
                                    component={component}
                                />
                            </AllowRouteWrapper>
                        ))
                    }
                    <Redirect to={routes[0].path} />
                </Switch>
            </>
        </LayoutContainer>
    );
}

export default Marketing;
