import React, { useCallback, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { BackButton } from 'components/Common/BackButton';
import {
    SubscriptionInfoFormGroup,
    SubscriptionPricingFormGroup,
    SubscriptionServicesFormGroup,
    SubscriptionAddEditFormFooter,
} from 'components';
import { getVATRateSelectOptions } from 'components/subscriptions/SubscriptionPricingFormGroup/SubscriptionPricingFormGroup';
import { SubscriptionAvailabilityToggle } from 'components/subscriptions/SubscriptionAvailabityToggle';
import { SubscriptionBuyableToggle } from 'components/subscriptions/SubscriptionBuyableToggle';

import SubscriptionDataTransformations from 'services/subscriptionDataTransformations';
import SubscriptionsServicesOptionsService from 'services/subscriptionsServicesOptionsService';

import * as SERVICES_ACTIONS from 'store/actions/services';
import * as SUBSCRIPTIONS_ACTIONS from 'store/actions/subscriptions';

import * as SERVICES_SELECTORS from 'store/selectors/services';
import * as SUBSCRIPTIONS_SELECTORS from 'store/selectors/subscriptions';

import { SUBSCRIPTION_PROP } from 'const/subscriptions/SUBSCRIPTION_PROP';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Content } from 'components/Common/Content';

const formikInitialValues = {
    subscriptionInfo: {
        title: '',
        contractLength: '',
        description: '',
    },
    services: [],
    pricing: {
        priceWithVAT: '',
        VAT: getVATRateSelectOptions(false)[3],
        priceWithoutVAT: '',
    },
    active: true,
    buyable: true,
};

const T_PREFIX = 'addOrEdit';
const T_FIELDS = `${T_PREFIX}.form.fields`;
const T_SERVICES_FIELDS = `${T_FIELDS}.services.fields`;

function AddNewSubscription() {
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const dispatch = useDispatch();
    const history = useHistory();

    const { subscriptions, isDataLoaded } = useSelector(SUBSCRIPTIONS_SELECTORS.editSubscriptionSelector);
    const duplicatingSubscriptionId = history.location.state?.subscriptionId;
    const isDuplicateMode = Boolean(duplicatingSubscriptionId);
    const duplicatingSubscription = useMemo(() => (
        subscriptions.find((subscription) => subscription.id === Number(duplicatingSubscriptionId))
    ), [subscriptions, duplicatingSubscriptionId]);

    const validationSchema = useMemo(() => yup.object({
        subscriptionInfo: yup.object({
            title: yup
                .string()
                .min(
                    SUBSCRIPTION_PROP.TITLE.MIN_LENGTH,
                    t(`${T_FIELDS}.title.validation.minLength`, {
                        length: SUBSCRIPTION_PROP.TITLE.MIN_LENGTH,
                    }),
                )
                .max(
                    SUBSCRIPTION_PROP.TITLE.MAX_LENGTH,
                    t(`${T_FIELDS}.title.validation.maxLength`, {
                        length: SUBSCRIPTION_PROP.TITLE.MAX_LENGTH,
                    }),
                )
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: tc('subscriptions.infoFormGroup.title'),
                })),
            contractLength: yup
                .number()
                .max(
                    SUBSCRIPTION_PROP.CONTRACT_LENGTH.MAX,
                    t(`${T_FIELDS}.contractLength.validation.max`, {
                        value: SUBSCRIPTION_PROP.CONTRACT_LENGTH.MAX,
                    }),
                )
                .positive(tc('validationErrors.mustBePositiveNumber', {
                    name: tc('subscriptions.infoFormGroup.contractLength'),
                }))
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: tc('subscriptions.infoFormGroup.contractLength'),
                })),
            description: yup
                .string()
                .min(
                    SUBSCRIPTION_PROP.DESCRIPTION.MIN_LENGTH,
                    t(`${T_FIELDS}.description.validation.minLength`, {
                        length: SUBSCRIPTION_PROP.DESCRIPTION.MIN_LENGTH,
                    }),
                )
                .max(
                    SUBSCRIPTION_PROP.DESCRIPTION.MAX_LENGTH,
                    t(`${T_FIELDS}.description.validation.maxLength`, {
                        length: SUBSCRIPTION_PROP.DESCRIPTION.MAX_LENGTH,
                    }),
                ),
        }),
        pricing: yup.object({
            priceWithVAT: yup
                .number()
                .min(
                    SUBSCRIPTION_PROP.PRICE.MIN,
                    tc('validationErrors.mustBeAboveOrEqualZero', {
                        name: tc('subscriptions.pricingFormGroup.priceWithVAT'),
                    }),
                )
                .max(
                    SUBSCRIPTION_PROP.PRICE.MAX,
                    t(`${T_FIELDS}.price.validation.max`, {
                        value: SUBSCRIPTION_PROP.PRICE.MAX,
                    }),
                )
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: tc('subscriptions.pricingFormGroup.priceWithVAT'),
                })),
        }),
        services: yup.array().of(
            yup.object({
                name: yup.array().of(
                    yup.object({
                        label: yup.string(),
                        value: yup.number(),
                    }),
                ).required(tc('validationErrors.cannotBeEmpty', {
                    name: t('addOrEdit.form.fields.services.fields.name.label'),
                })),
                maximumUsages: yup
                    .number()
                    .max(
                        SUBSCRIPTION_PROP.MAX_USAGES.MAX,
                        t(`${T_FIELDS}.maximumUsages.validation.max`, {
                            value: SUBSCRIPTION_PROP.MAX_USAGES.MAX,
                        }),
                    )
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('addOrEdit.form.fields.services.fields.maxUsages.value.label'),
                    }))
                    .test({
                        test: (value) => value > 0 || value === -1,
                        message: tc('validationErrors.mustBePositiveNumber', {
                            name: t('addOrEdit.form.fields.services.fields.maxUsages.value.label'),
                        }),
                    }),
                period: yup.string()
                    .required(tc('validationErrors.cannotBeEmpty')),
                missedUsages: yup.object({
                    selected: yup.bool().required(),
                    value: yup
                        .number()
                        .when('selected', (selected, schema) => {
                            if (!selected) {
                                return schema.nullable();
                            }
                            return schema
                                .min(
                                    SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MIN,
                                    t(`${T_SERVICES_FIELDS}.missedUsages.validation.min`, {
                                        value: SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MIN,
                                    }),
                                )
                                .max(
                                    SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MAX,
                                    t(`${T_SERVICES_FIELDS}.missedUsages.validation.max`, {
                                        value: SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MAX,
                                    }),
                                )
                                .required(t(`${T_SERVICES_FIELDS}.missedUsages.validation.required`));
                        }),
                }).required(),
            }).required('at least one service should be added'),
        ),
    }), [t, tc]);

    useEffect(() => {
        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [dispatch]);

    const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);

    const initialValues = useMemo(() => {
        if (isDuplicateMode && duplicatingSubscription) {
            return SubscriptionDataTransformations.transformSubscriptionIntoDuplicate(duplicatingSubscription);
        }
        formikInitialValues.subscriptionInfo.description = tc('subscriptions.infoFormGroup.defaultDescriptionValue');
        return formikInitialValues;
    }, [duplicatingSubscription, isDuplicateMode]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: useCallback((values, { setSubmitting }) => {
            setSubmitting(true);
            const payload = SubscriptionDataTransformations.transformFormDataIntoPayload(values);
            const onFinal = () => setSubmitting(false);
            dispatch(SUBSCRIPTIONS_ACTIONS.addSubscription(payload, history, onFinal));
        }, [dispatch, history]),
    });

    const { values, setFieldValue, isSubmitting } = formik;

    const handleToggleActive = useCallback((value) => {
        setFieldValue('active', value);
    }, [setFieldValue]);

    const handleBuyableToggle = useCallback((value) => {
        setFieldValue('buyable', value);
    }, [setFieldValue]);

    const availableServicesOptions = useMemo(() => (
        SubscriptionsServicesOptionsService.getAvailableServicesOptions(companyServices, values)
    ), [companyServices, values]);

    return (
        <>
            <TextSubHeader
                text={tc('subscriptions.pageTitle.addNewSubscription')}
                before={(
                    <BackButton
                        href="/services/subscriptions/list"
                    />
                )}
            />
            <Form onSubmit={formik.handleSubmit}>
                <Content loading={!isDataLoaded}>
                    <div className="d-flex flex-column px-lg-5 align-self-stretch">
                        <SubscriptionInfoFormGroup formik={formik} />
                        <SubscriptionServicesFormGroup
                            formik={formik}
                            servicesOptions={availableServicesOptions}
                        />
                        <SubscriptionPricingFormGroup formik={formik} />
                        <SubscriptionAvailabilityToggle
                            values={values}
                            onToggleActive={handleToggleActive}
                        />
                        <SubscriptionBuyableToggle
                            values={values}
                            onToggleBuyable={handleBuyableToggle}
                        />
                    </div>
                    <SubscriptionAddEditFormFooter services={formik.values.services} isSubmitting={isSubmitting} />
                </Content>
            </Form>
        </>
    );
}

export default AddNewSubscription;
