// extracted by mini-css-extract-plugin
var _1 = "Panel_gap0__J5GQu";
var _2 = "Panel_gap12__16av8";
var _3 = "Panel_gap16__9hWhr";
var _4 = "Panel_gap24__Y2suj";
var _5 = "Panel_gap32__Ai4Dp";
var _6 = "Panel_gap4__Gom6j";
var _7 = "Panel_gap8__Zfa97";
var _8 = "Panel_padding12__Ew5wa";
var _9 = "Panel_padding16__dadP4";
var _a = "Panel_padding24__hPl1q";
var _b = "Panel_padding32__z2U89";
var _c = "Panel_panel__wjuUQ";
var _d = "Panel_primary__vnym5";
var _e = "Panel_secondary__neiY8";
var _f = "Panel_stretched__6Et8b";
export { _1 as "gap0", _2 as "gap12", _3 as "gap16", _4 as "gap24", _5 as "gap32", _6 as "gap4", _7 as "gap8", _8 as "padding12", _9 as "padding16", _a as "padding24", _b as "padding32", _c as "panel", _d as "primary", _e as "secondary", _f as "stretched" }
