import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { LayoutContainer } from '../../components';

import { SORT_BY } from '../../const/clients/SORT_BY';
import { SORT_ORDER } from '../../const/clients/SORT_ORDER';

import * as CLIENTS_ACTIONS from '../../store/actions/clients';

import routes from './scenes';

const ClientsPage = () => {
    const dispatch = useDispatch();

    useEffect(() => () => {
        dispatch(CLIENTS_ACTIONS.setClientsPageFirst());
        dispatch(CLIENTS_ACTIONS.setClientsSearch({ search: '' }));
        dispatch(CLIENTS_ACTIONS.setClientsSort({ sortBy: SORT_BY.ID, sortType: SORT_ORDER.DESC }));
        dispatch(CLIENTS_ACTIONS.clientsFiltrationRegistered({ registeredStatus: null }));
    }, [dispatch]);

    return (
        <LayoutContainer>
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <Route
                            key={path}
                            component={component}
                            exact={exact}
                            path={path}
                        />
                    ))
                }
                <Redirect to={routes[0].path} />
            </Switch>
        </LayoutContainer>
    );
};

ClientsPage.propTypes = {};

ClientsPage.defaultProps = {};

export default ClientsPage;
