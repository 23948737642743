import {
    put, call, takeEvery,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

// Actions
import camelize from 'camelize';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as actions from '../actions';

// Utils
import axios from '../../services/axios';

import * as actionTypes from '../actions/user/actionTypes';
import { HTTP_METHOD } from '../../const/http/HTTP_METHOD';
import { API_ADMIN_USER_DETAILS_ROUTE, API_ADMIN_USER_SET_DEFAULT_TAB_ROUTE, API_ADMIN_USER_TOGGLE_SOUND_NOTIFICATIONS_ROUTE } from '../../const/API_URL';

export function* getUserDetails(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_USER_DETAILS_ROUTE,
            headers: defaultHeaders,
        });

        const details = camelize(resp.data.result);

        yield call(resolvePromiseAction, action, details);
        yield put(actions.openWebsocketConnection());
    } catch (err) {
        let message = null;
        if (err.response && err.response.data && err.response.data.result) {
            message = err.response.data.result.message;
        }

        yield call(rejectPromiseAction, action, message);
    }
}

export function* setDefaultTab(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const data = new FormData();
        data.append('defaultTab', action.payload);

        const resp = yield axios.request({
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_USER_SET_DEFAULT_TAB_ROUTE,
            headers: defaultHeaders,
            data,
        });

        const details = camelize(resp.data.result);

        yield call(resolvePromiseAction, action, details);
    } catch (err) {
        let message = null;
        if (err.response && err.response.data && err.response.data.result) {
            message = err.response.data.result.message;
        }

        yield call(rejectPromiseAction, action, message);
    }
}

export function* toggleSoundNotifications(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const data = new FormData();
        data.append('soundNotificationsEnabled', action.payload);

        const resp = yield axios.request({
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_USER_TOGGLE_SOUND_NOTIFICATIONS_ROUTE,
            headers: defaultHeaders,
            data,
        });

        const details = camelize(resp.data.result);

        yield call(resolvePromiseAction, action, details);
    } catch (err) {
        let message = null;
        if (err.response && err.response.data && err.response.data.result) {
            message = err.response.data.result.message;
        }

        yield call(rejectPromiseAction, action, message);
    }
}

export const userSaga = [
    takeEvery(actionTypes.GET_USER_DETAILS_TRIGGER, getUserDetails),
    takeEvery(actionTypes.SET_DEFAULT_TAB_TRIGGER, setDefaultTab),
    takeEvery(actionTypes.TOGGLE_SOUND_NOTIFICATIONS_TRIGGER, toggleSoundNotifications),
];
