import React from 'react';

const Letter = ({ className }) => (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        className={className}
    >
        <path
            d="M6.3999 4.5C5.60425 4.5 4.84119 4.81607 4.27858 5.37868C3.71597 5.94129 3.3999 6.70435 3.3999 7.5V7.8015L12.3999 12.648L21.3999 7.803V7.5C21.3999 6.70435 21.0838 5.94129 20.5212 5.37868C19.9586 4.81607 19.1956 4.5 18.3999 4.5H6.3999ZM21.3999 9.5055L12.7554 14.16C12.6461 14.2188 12.524 14.2496 12.3999 14.2496C12.2758 14.2496 12.1537 14.2188 12.0444 14.16L3.3999 9.5055V16.5C3.3999 17.2957 3.71597 18.0587 4.27858 18.6213C4.84119 19.1839 5.60425 19.5 6.3999 19.5H18.3999C19.1956 19.5 19.9586 19.1839 20.5212 18.6213C21.0838 18.0587 21.3999 17.2957 21.3999 16.5V9.5055Z"
            fill="currentColor"
        />
    </svg>
);

export default Letter;
