/* eslint-disable import/prefer-default-export */
import * as CLIENTS_TYPES from '../../../actions/clients/tables/notes/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,

    categories: [],
    selectedCategoriesIds: [],

    savingNote: false,
    savingCategory: false,

    // Save for each client persist item
    persistCreateItem: {},
};

export const clientNotesListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (CLIENTS_TYPES.CLIENT_ITEM_NOTES_SET): {
        const {
            notes,
            itemsCount,
            pagesCount,
        } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: notes,
            page,
            itemsCount,
            pagesCount,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_NOTES_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    case CLIENTS_TYPES.GET_CLIENT_NOTE_CATEGORIES_RESOLVED: {
        const { categories } = payload;
        return {
            ...state,
            categories: categories.reverse(),
        };
    }
    case CLIENTS_TYPES.SET_CLIENT_NOTE_FILTER_TRIGGER: {
        const { categoryId } = payload;
        return {
            ...state,
            selectedCategoriesIds: state.selectedCategoriesIds.includes(categoryId) ? state.selectedCategoriesIds.filter((id) => id !== categoryId) : [...state.selectedCategoriesIds, categoryId],
        };
    }
    case CLIENTS_TYPES.CLEAR_CLIENT_NOTE_FILTER_TRIGGER: {
        return {
            ...state,
            selectedCategoriesIds: [],
        };
    }
    case CLIENTS_TYPES.DELETE_CLIENT_NOTE_CATEGORY_RESOLVED: {
        const { categoryId } = payload;
        return {
            ...state,
            categories: state.categories.filter((category) => category.id !== categoryId),
            selectedCategoriesIds: state.selectedCategoriesIds.filter((id) => id !== categoryId),
        };
    }
    case CLIENTS_TYPES.ADD_CLIENT_NOTE_CATEGORY_TRIGGER: {
        return {
            ...state,
            savingCategory: true,
        };
    }
    case CLIENTS_TYPES.ADD_CLIENT_NOTE_CATEGORY_RESOLVED:
    case CLIENTS_TYPES.ADD_CLIENT_NOTE_CATEGORY_REJECTED: {
        return {
            ...state,
            savingCategory: false,
        };
    }
    case CLIENTS_TYPES.EDIT_CLIENT_NOTE_TRIGGER:
    case CLIENTS_TYPES.ADD_CLIENT_NOTE_TRIGGER: {
        return {
            ...state,
            savingNote: true,
        };
    }
    case CLIENTS_TYPES.EDIT_CLIENT_NOTE_RESOLVED: {
        const { result } = payload;

        if (!state.selectedCategoriesIds.length || state.selectedCategoriesIds.includes(result.clientNoteCategory.id)) {
            return {
                ...state,
                savingNote: false,
                // add to top and remove from existed position
                items: [result, ...state.items.filter((item) => item.id !== result.id)],
            };
        }

        return {
            ...state,
            savingNote: false,
            items: state.items.filter((item) => item.id !== result.id),
            itemsCount: state.itemsCount - 1,
        };
    }
    case CLIENTS_TYPES.ADD_CLIENT_NOTE_RESOLVED:
    case CLIENTS_TYPES.EDIT_CLIENT_NOTE_REJECTED:
    case CLIENTS_TYPES.ADD_CLIENT_NOTE_REJECTED: {
        return {
            ...state,
            savingNote: false,
        };
    }
    case CLIENTS_TYPES.SET_CLIENT_NOTE_PERSIST_CREATE_ITEM_TRIGGER: {
        const { clientId, text, category } = payload;
        return {
            ...state,
            persistCreateItem: {
                ...state.persistCreateItem,
                [clientId]: {
                    text,
                    category,
                },
            },
        };
    }
    default:
        return state;
    }
};
