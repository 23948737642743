import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import camelize from 'camelize';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import * as actions from '../../actions';
import axios from '../../../services/axios';
import * as TOAST_ACTIONS from '../../actions/toast';

import * as ACCESS_SELECTORS from '../../selectors/access';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import {
    API_ADMIN_COMPANY_GATES_LIST_ROUTE,
} from '../../../const/API_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';

function* getAccessGates(action) {
    const { page, search, sortOptions } = yield select(ACCESS_SELECTORS.accessGatesListSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_GATES_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page,
                search,
                ...sortOptions,
            },
        });

        const { data } = res;
        const { code, result } = data;
        const gates = camelize(result);
        if (code === HTTP_STATUS_CODE.OK) {
            yield call(resolvePromiseAction, action, gates);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'access.gates.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export function* getGatesForSelect(action) {
    const { search } = action.payload || {};

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_GATES_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page: 0,
                search,
            },
        });

        const { data } = res;
        const { code, result } = data;
        const gates = camelize(result);
        if (code === HTTP_STATUS_CODE.OK) {
            yield call(resolvePromiseAction, action, gates);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'access.gates.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const gatesListSaga = [
    takeLatest(actions.getAccessGates, getAccessGates),
    takeLatest(actions.getGatesForSelect, getGatesForSelect),
];
