import React from 'react';
import { useLocation } from 'react-router';
import PropTypes, { object } from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { SECOND_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { useMobile } from 'hooks/useMobile';
import NavigationService from 'services/navigationService';
import ShopsDropdown from '../../ShopsDropdown/ShopsDropdown';
import SubNavigation from '../../Navigation/SubNavigation/SubNavigation';
import * as styles from './SubHeader.module.scss';

function SubHeader(props) {
    const {
        date,
        isDropdownDataLoaded,
        isNavigationDataLoaded,
        dropdownOptions,
        onDropdownItemSelect,
        shopId,
        shopName,
        showDropdown,
        withQuery,
        productsList,
        after,
    } = props;
    const { pathname } = useLocation();
    const parentRoute = NavigationService.getParentRoute(pathname);
    const isMobile = useMobile();

    const hasSubheader = !isMobile && showDropdown || !isMobile;

    if (!hasSubheader) {
        return null;
    }

    return (
        <Row noGutters className="shadow-nav" style={{ height: SECOND_LEVEL_NAV_HEIGHT, flexWrap: 'nowrap' }}>
            {!isMobile && showDropdown && (
                <Col md={4} lg={3} xl={3} className="d-none d-lg-block">
                    <ShopsDropdown
                        isDataLoaded={isDropdownDataLoaded}
                        options={dropdownOptions}
                        onSelect={onDropdownItemSelect}
                        shopId={shopId}
                        shopName={shopName}
                    />
                </Col>
            )}
            <Col className="d-flex align-items-center justify-content-between h-100 flex-lg-grow-1 flex-grow-0">
                <Row noGutters className={classNames('align-items-center justify-content-between flex-nowrap overflow-auto flex-grow-1 h-100 w-100', styles.hideScroll)}>
                    {!isMobile && (
                        <SubNavigation
                            productsList={productsList}
                            date={date}
                            isDataLoaded={isNavigationDataLoaded}
                            parentRoute={parentRoute}
                            shopId={shopId}
                            withQuery={withQuery}
                        />
                    )}
                    {after}
                </Row>
            </Col>
        </Row>
    );
}

SubHeader.propTypes = {
    date: object,
    isDropdownDataLoaded: PropTypes.bool,
    isNavigationDataLoaded: PropTypes.bool,
    dropdownOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    onDropdownItemSelect: PropTypes.func,
    shopId: PropTypes.number,
    shopName: PropTypes.string,
    showDropdown: PropTypes.bool,
    after: PropTypes.node,
};

export default SubHeader;
