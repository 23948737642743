import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { EmptyNotesIcon } from 'components/Icon/Icon';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';

import * as styles from './EmptyNotes.module.scss';

const T_PREFIX = 'notes';

const EmptyNotes = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const { className } = props;

    return (
        <div className={classNames(styles.emptyContainer, className)}>
            <EmptyNotesIcon width={189} height={189} />
            <p className={styles.text}>{t(`${T_PREFIX}.emptyNotes`)}</p>
        </div>
    );
};

export default EmptyNotes;
