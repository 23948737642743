import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { EmptyTableIcon } from 'components/Icon/Icon';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './EmptyTable.module.scss';

const T_PREFIX = 'list';

const EmptyTable = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.TABLE);

    const { className } = props;

    return (
        <div className={classNames(styles.emptyTableContainer, className)}>
            <EmptyTableIcon width={188} height={188} />
            <p className={styles.text}>{t(`${T_PREFIX}.emptyTable`)}</p>
        </div>
    );
};

EmptyTable.propTypes = {
    className: PropTypes.string,
};

EmptyTable.defaultProps = {
    className: '',
};

export default EmptyTable;
