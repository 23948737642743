import React from 'react';

const Balance = ({ className }) => (
    <svg
        width="55"
        height="56"
        viewBox="0 0 55 56"
        fill="none"
        className={className}
    >
        <path
            d="M27.5 23.8823V45.2941"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.353 27.5142C29.9977 26.1966 22.5784 25.2083 22.5784 29.7534C22.5784 35.1887 31.9996 33.2707 31.9996 38.0669C31.9996 42.8632 25.876 42.5033 21.6361 40.9435"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.2095 16.4707H21.7905C21.7905 16.4707 8.08826 31.1913 8.08826 40.2501C8.08826 47.0442 12.9412 52.706 27.5 52.706C42.0588 52.706 46.9118 47.0442 46.9118 40.2501C46.9118 31.1913 33.2095 16.4707 33.2095 16.4707Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.1617 16.4707L37.2058 8.23544L32.3529 6.58838"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.6471 6.58838L17.7942 8.23544L21.8383 16.4707"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.2647 9.88242L21.0294 3.29419H33.9706L30.7353 9.88242"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Balance;
