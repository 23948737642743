import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { isValidIBAN } from 'helpers/validation/iban';
import { Button } from 'components/Common/Button';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import { Dropdown } from 'react-bootstrap';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';
import { Dialog } from '../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../Common/Dialogs/Dialog/DialogBody';

import { useBool } from '../../../hooks/useBool';

import * as CLIENTS_ACTIONS from '../../../store/actions/clients';

import { getError, isInvalid } from '../../../services/validationService';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { CLIENT_PROP } from '../../../const/clients/CLIENT_PROP';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const DATA_TRANSFORMER = {
    send: ({ values }) => {
        const formData = new FormData();
        if (values.iban) {
            formData.append('iban', values.iban.trim());
        }
        if (values.ibanOwner) {
            formData.append('ibanOwner', values.ibanOwner.trim());
        }
        return formData;
    },
};

const T_PREFIX = 'list.dialogs.editIban';
const T_FORM = `${T_PREFIX}.body.form`;
const T_FIELDS = `${T_FORM}.fields`;
const T_ACTIONS = `${T_FORM}.actions`;
const T_FOOTER_ACTIONS = `${T_PREFIX}.footer.actions`;

const EditIbanDialog = (props) => {
    const {
        client,
        visible,
        onClose,
    } = props;

    const initialValues = useMemo(() => ({
        iban: CLIENT_PROP.IBAN.DEFAULT,
        ibanOwner: CLIENT_PROP.IBAN.DEFAULT,
    }), []);

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const showIbanInput = useBool(false);
    const showSendLink = useBool(false);

    const dispatch = useDispatch();

    const validationSchema = useMemo(() => yup.object(showIbanInput.value ? {
        iban: yup.string()
            .trim()
            .required(t(`${T_FIELDS}.iban.validation.required`))
            .max(
                CLIENT_PROP.IBAN.MAX_LENGTH,
                t(`${T_FIELDS}.iban.validation.maxLength`, {
                    length: CLIENT_PROP.IBAN.MAX_LENGTH,
                }),
            )
            .test({
                name: 'is-valid-iban',
                message: t(`${T_FIELDS}.iban.validation.invalid`),
                exclusive: true,
                test: (value) => !value || isValidIBAN(value),
            }),
        ibanOwner: yup.string()
            .trim()
            .required(t(`${T_FIELDS}.ibanOwner.validation.required`))
            .max(
                CLIENT_PROP.IBAN_OWNER.MAX_LENGTH,
                t(`${T_FIELDS}.ibanOwner.validation.maxLength`, {
                    length: CLIENT_PROP.IBAN_OWNER.MAX_LENGTH,
                }),
            ),
    } : {}), [t, showIbanInput.value]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: useCallback((values, { setSubmitting }) => {
            if (!client?.id) {
                return;
            }

            setSubmitting(true);

            const onSuccess = () => {
                dispatch(CLIENTS_ACTIONS.getClients());
                dispatch(CLIENTS_ACTIONS.getClientItem({ clientId: client.id }));
                onClose();
                setSubmitting(false);
            };
            const onError = (data) => {
                setErrors(data);
                setSubmitting(false);
            };

            if (showIbanInput.value) {
                const data = DATA_TRANSFORMER.send({ values });
                dispatch(CLIENTS_ACTIONS.updateIban({
                    clientId: client.id,
                    data,
                    onSuccess,
                    onError,
                }));
            }

            if (showSendLink.value) {
                dispatch(CLIENTS_ACTIONS.sendUpdateIbanLink({
                    clientId: client.id,
                    onSuccess,
                    onError,
                }));
            }
        }, [dispatch, onClose, client?.id, showIbanInput.value, showSendLink.value]),
        enableReinitialize: true,
    });

    const {
        values,
        errors,
        setErrors,
        touched,
        setFieldValue,
        setFieldTouched,
        resetForm,
        handleSubmit,
        isSubmitting,
    } = formik;

    useEffect(() => {
        if (!visible) {
            resetForm();
        }
    }, [visible, resetForm]);

    const handleChangeTextField = useCallback(({ field }) => (e) => {
        const { target: { value } } = e;
        setFieldTouched(field, true);
        setFieldValue(field, value);
    }, [setFieldValue, setFieldTouched]);

    const isIbanInvalid = isInvalid('iban', errors, touched);
    const isIbanOwnerInvalid = isInvalid('ibanOwner', errors, touched);

    const hasErrors = !!(isIbanInvalid || isIbanOwnerInvalid);

    return (
        <Dialog
            size="md"
            visible={visible}
            onClose={onClose}
        >
            <DialogHeader>
                <DialogTitle>
                    {showSendLink.value ? t(`${T_PREFIX}.header.sendLink.title`) : t(`${T_PREFIX}.header.edit.title`)}
                </DialogTitle>
            </DialogHeader>
            {!!showIbanInput.value && (
                <Form
                    id="update-iban"
                    onSubmit={handleSubmit}
                >
                    <DialogBody>
                        <Form.Group>
                            <Form.Label>
                                {t(`${T_FIELDS}.iban.label`)}
                            </Form.Label>
                            <Form.Control
                                type="string"
                                name="iban"
                                value={values.iban || ''}
                                onChange={handleChangeTextField({ field: 'iban' })}
                                isInvalid={isIbanInvalid}
                                placeholder={t(`${T_FIELDS}.iban.placeholder`)}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={isIbanInvalid && 'd-flex'}
                            >
                                {getError('iban', errors)}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                {t(`${T_FIELDS}.ibanOwner.label`)}
                            </Form.Label>
                            <Form.Control
                                type="string"
                                name="ibanOwner"
                                value={values.ibanOwner || ''}
                                onChange={handleChangeTextField({ field: 'ibanOwner' })}
                                isInvalid={isIbanOwnerInvalid}
                                placeholder={t(`${T_FIELDS}.ibanOwner.placeholder`)}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={isIbanOwnerInvalid && 'd-flex'}
                            >
                                {getError('ibanOwner', errors)}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </DialogBody>
                    <DialogFooter>
                        <Row gap={16}>
                            <Button
                                color="outline"
                                onClick={showIbanInput.onFalse}
                            >
                                {t(`${T_FOOTER_ACTIONS}.cancel.label`)}
                            </Button>
                            <Button
                                type="submit"
                                form="update-iban"
                                disabled={hasErrors}
                                loading={isSubmitting}
                            >
                                {t(`${T_FOOTER_ACTIONS}.edit.label`)}
                            </Button>
                        </Row>
                    </DialogFooter>
                </Form>
            )}
            {
                !!showSendLink.value && (
                    <Form
                        id="send-update-iban"
                        onSubmit={handleSubmit}
                    >
                        <DialogBody>
                            <span>
                                {t(`${T_FORM}.sendLinkDescription`)}
                            </span>
                        </DialogBody>
                        <DialogFooter>
                            <Row gap={16}>
                                <Button
                                    color="outline"
                                    onClick={showSendLink.onFalse}
                                >
                                    {t(`${T_FOOTER_ACTIONS}.cancel.label`)}
                                </Button>
                                <Button
                                    type="submit"
                                    form="send-update-iban"
                                >
                                    {t(`${T_FOOTER_ACTIONS}.send.label`)}
                                </Button>
                            </Row>
                        </DialogFooter>
                    </Form>
                )
            }
            {!showIbanInput.value && !showSendLink.value && (
                <DialogBody>
                    <Column>
                        <Button stretched color="outline" onClick={showIbanInput.onTrue}>{t(`${T_ACTIONS}.enterIban`)}</Button>
                        <Row gap={16} stretched>
                            <Dropdown.Divider className="w-100" />
                            <span className="text-muted font-size-14">
                                {t(`${T_FORM}.or`)}
                            </span>
                            <Dropdown.Divider className="w-100" />
                        </Row>
                        <Button
                            stretched
                            onClick={showSendLink.onTrue}
                            loading={isSubmitting}
                        >
                            {t(`${T_ACTIONS}.sendLink`)}
                        </Button>
                        <span className="text-muted font-size-12">{t(`${T_FORM}.sendLinkDescription`)}</span>
                    </Column>
                </DialogBody>
            )}
        </Dialog>
    );
};

EditIbanDialog.propTypes = {
    client: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

EditIbanDialog.defaultProps = {
    client: null,
    visible: false,
    onClose: emptyFunc,
};

export default EditIbanDialog;
