import React, {
    useState,
    useRef,
    useCallback,
    useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';

// Components
import { Modal } from 'react-bootstrap';
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from 'react-image-crop';

// Styles
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from 'components/Common/Button';
import { imgPreview } from './imgPreview';

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return convertToPixelCrop(
        centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    height: 100,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}

const FileUploadModal = ({
    onSubmit,
    onCancel,
    image,
    square = false,
}) => {
    const { t } = useTranslation();

    const aspect = square ? 1 : 16 / 9;
    const src = useMemo(() => (image instanceof Blob ? URL.createObjectURL(image) : image), [image]);
    const [crop, setCrop] = useState(null);
    const [isSavingProcess, setIsSavingProcess] = useState(false);

    const imageRef = useRef(null);

    const onImageLoad = useCallback((e) => {
        imageRef.current = e.currentTarget;
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }, [aspect, imageRef]);

    const handleSubmit = useCallback(async () => {
        setIsSavingProcess(true);
        const { blob } = await imgPreview(imageRef.current, crop);
        onSubmit(blob);
        setIsSavingProcess(false);
    }, [crop, imageRef, onSubmit]);

    return (
        <Modal size="xl" show={!!src} onHide={onCancel} centered>
            <Modal.Body className="d-flex justify-content-center">
                <ReactCrop
                    aspect={aspect}
                    crop={crop}
                    onChange={/* istanbul ignore next */ (crop) => setCrop(crop)}
                >
                    <img
                        src={src}
                        style={{
                            maxHeight: 'calc(100vh - 215px)',
                        }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    type="button"
                    color="outline"
                    onClick={onCancel}
                    disabled={isSavingProcess}
                >
                    {t('fileUpload.cancel')}
                </Button>

                <Button
                    type="button"
                    color="yellow"
                    onClick={handleSubmit}
                    loading={isSavingProcess}
                >
                    {t('fileUpload.done')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FileUploadModal;
