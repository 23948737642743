import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Switch } from 'components';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'addOrEdit.form.fields.status';

const SubscriptionAvailabilityToggle = (props) => {
    const {
        values,
        onToggleActive,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    return (
        <div className="d-flex align-items-center flex-nowrap px-4 py-3 px-lg-0">
            <div className="px-2 px-lg-0 mr-3">
                <Switch
                    checked={values?.active}
                    onChange={onToggleActive}
                />
            </div>
            <div className="px-2 px-lg-0">
                <span className="px-0 font-weight-600 font-size-16">
                    {t(`${T_PREFIX}.label`)}
                </span>
            </div>
        </div>
    );
};

SubscriptionAvailabilityToggle.propTypes = {
    values: PropTypes.object,
    onToggleActive: PropTypes.func,
};

SubscriptionAvailabilityToggle.defaultProps = {
    values: null,
    onToggleActive: emptyFunc,
};

export default SubscriptionAvailabilityToggle;
