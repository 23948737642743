import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import SearchInput2 from 'components/Common/SearchInput2';
import { Panel } from 'components/Common/Panel';
import { Row } from 'components/Common/Row';
import { ClientsProfileSelect } from './Selects/ClientsProfileSelect';
import { RegisteredStatusSelect } from './Selects/RegisteredStatusSelect';
import { ClientsShopSelect } from './Selects/ClientsShopSelect';

import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.search.filters';

const schema = yup.object({
    shop: yup.mixed({
        id: yup.number(),
        name: yup.string(),
    }).notRequired(),
    discount: yup.mixed({
        id: yup.number(),
        name: yup.string(),
    }).notRequired(),
    registered: yup.mixed({
        id: yup.number(),
        name: yup.string(),
    }).notRequired(),
});

const ClientsSearchFilterContainer = (props) => {
    const {
        search,
        onSearch,
        onDiscountSelectChange,
        onShopSelectChange,
        onRegisterSelectChange,
        discountProfiles,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const formik = useFormik({
        initialValues: {
            shop: {
                value: '',
                label: t(`${T_PREFIX}.shop.default`),
            },
            discount: {
                value: '',
                label: t(`${T_PREFIX}.clientProfile`),
            },
            registered: {
                value: '',
                label: t(`${T_PREFIX}.registered.all`),
            },
        },
        validationSchema: schema,
    });

    useEffect(() => {
        const { value } = formik.values?.registered || {};
        const option = value === 0 && 'notRegistered' || value === 1 && 'registered' || 'all';

        formik.setFieldValue('registered', {
            label: t(`${T_PREFIX}.registered.${option}`),
            value,
        });
    }, [t, formik.values?.registered?.value, formik.setFieldValue]);

    const handleSearch = useCallback((nextSearch) => {
        onSearch({ search: nextSearch });
    }, [onSearch]);

    return (
        <Panel color="secondary" stretched>
            <Row stretched spaceBetween gap={16}>
                <SearchInput2
                    searchValue={search}
                    onSearch={handleSearch}
                />
                <Row gap={16}>
                    {false && (
                        <ClientsShopSelect
                            formik={formik}
                            onShopSelectChange={onShopSelectChange}
                        />
                    )}
                    <ClientsProfileSelect
                        onDiscountSelectChange={onDiscountSelectChange}
                        discountProfiles={discountProfiles}
                    />
                    <RegisteredStatusSelect
                        formik={formik}
                        onRegisterSelectChange={onRegisterSelectChange}
                    />
                </Row>
            </Row>
        </Panel>
    );
};

ClientsSearchFilterContainer.propTypes = {
    search: PropTypes.string,
    onSearch: PropTypes.func,
    onDiscountSelectChange: PropTypes.func,
    onShopSelectChange: PropTypes.func,
    onRegisterSelectChange: PropTypes.func,
    discountProfiles: PropTypes.array,
};

ClientsSearchFilterContainer.defaultProps = {
    search: '',
    onSearch: emptyFunc,
    onDiscountSelectChange: emptyFunc,
    onShopSelectChange: emptyFunc,
    onRegisterSelectChange: emptyFunc,
    discountProfiles: [],
};

export default ClientsSearchFilterContainer;
