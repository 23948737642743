import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './BundlesListTableColumns.module.scss';

const T_PREFIX = 'list.table.header';

const BundlesListTableColumns = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <tr>
            <TableHeaderCell className={styles.nameCell}>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.name`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.services`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.maxUsages`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.validity`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.status`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_PREFIX}.price`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.actions`)}
                />
            </TableHeaderCell>
        </tr>
    );
};

BundlesListTableColumns.propTypes = {};

BundlesListTableColumns.defaultProps = {};

export default BundlesListTableColumns;
