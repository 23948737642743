import { useState, useEffect } from 'react';

export const useMobile = (width = 992) => {
    const [isMobile, setMobile] = useState(window.innerWidth < width);

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < width);
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};
