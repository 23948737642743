import * as CLEARINGS_TYPES from './types';

export const getClearings = () => ({
    type: CLEARINGS_TYPES.CLEARINGS_GET,
});

export const setClearings = ({
    clearings, itemsCount, pagesCount,
}) => ({
    type: CLEARINGS_TYPES.CLEARINGS_SET,
    payload: {
        clearings,
        itemsCount,
        pagesCount,
    },
});

export const setClearingsPage = ({ page }) => ({
    type: CLEARINGS_TYPES.CLEARINGS_PAGE_SET,
    payload: { page },
});

export const setClearingsPageFirst = () => ({
    type: CLEARINGS_TYPES.CLEARINGS_PAGE_SET_FIRST,
});

export const setClearingsPageLast = () => ({
    type: CLEARINGS_TYPES.CLEARINGS_PAGE_SET_LAST,
});

export const setClearingsPagePrev = () => ({
    type: CLEARINGS_TYPES.CLEARINGS_PAGE_SET_PREV,
});

export const setClearingsPageNext = () => ({
    type: CLEARINGS_TYPES.CLEARINGS_PAGE_SET_NEXT,
});

export const setClearingsLoading = ({ loading }) => ({
    type: CLEARINGS_TYPES.CLEARINGS_LOADING_SET,
    payload: { loading },
});
