import React from 'react';

const Sent2 = ({ className, width = '24', height = '24' }) => (

    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        className={className}
    >
        <g clipPath="url(#clip0_1454_596)">
            <path
                d="M18.0639 2.76822C18.3961 2.59964 18.7894 2.84053 18.7901 3.21307L18.8241 19.8288C18.8257 20.611 17.9691 21.0921 17.302 20.6837L14.4148 18.916C12.7144 17.8749 12.0403 15.7405 12.8343 13.9116L15.274 8.29241C15.3669 8.07837 15.0805 7.9056 14.9345 8.08767L11.1449 12.8149C9.88499 14.3864 7.65309 14.7761 5.93527 13.7243L3.17361 12.0334C2.50634 11.6249 2.54555 10.6429 3.24325 10.2888L18.0639 2.76822Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1454_596">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Sent2;
