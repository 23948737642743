import React from 'react';

const FromWidget = ({
    fill = 'currentColor',
    width = '100%',
    height = '100%',
    className,
    style,
}) => (
    <svg
        className={className}
        style={style}
        height={height}
        width={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 4H5C4.44771 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44771 19.5523 4 19 4ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5ZM10.7071 8.29289C11.0976 8.68342 11.0976 9.31658 10.7071 9.70711L8.41421 12L10.7071 14.2929C11.0976 14.6834 11.0976 15.3166 10.7071 15.7071C10.3166 16.0976 9.68342 16.0976 9.29289 15.7071L6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929L9.29289 8.29289C9.68342 7.90237 10.3166 7.90237 10.7071 8.29289ZM13.2929 9.70711C12.9024 9.31658 12.9024 8.68342 13.2929 8.29289C13.6834 7.90237 14.3166 7.90237 14.7071 8.29289L17.7071 11.2929C18.0976 11.6834 18.0976 12.3166 17.7071 12.7071L14.7071 15.7071C14.3166 16.0976 13.6834 16.0976 13.2929 15.7071C12.9024 15.3166 12.9024 14.6834 13.2929 14.2929L15.5858 12L13.2929 9.70711Z"
            fill={fill}
        />
    </svg>
);

export default FromWidget;
