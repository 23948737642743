import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
    Form, Dropdown,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';

import FileUpload from 'components/FileUpload/FileUpload';

import * as COMPANY_ACTIONS from 'store/actions/company';
import * as COMPANY_SELECTORS from 'store/selectors/company';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { debounce } from 'lodash';
import { ColorPicker, COLOR_REGEX } from 'components/ColorPicker';
import ImagesGallery from 'components/ImagesGallery';
import { Button } from 'components/Common/Button';
import * as styles from './page.module.scss';

const T_PREFIX = 'general';
const MAX_NAME_LENGTH = 64;
const MAX_DESCRIPTION_LENGTH = 1500;
const MAX_LICENSE_LINK_LENGTH = 250;
const MAX_INSTAGRAM_LINK_LENGTH = 250;
const MAX_FACEBOOK_LINK_LENGTH = 250;
const MAX_ALLOWED_FILE_SIZE = 5;
const MAX_COLOR_LENGTH = 7;
const MAX_GALERY_IMAGES = 10;

const LINK_REGEX = /^((http|https):\/\/.+)|(www\..+)/;

const General = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const dispatch = useDispatch();
    const {
        isSavingProcess,
        // loaded,
        logo,
        name,
        appColor,
        description,
        licenseLink,
        instagramLink,
        facebookLink,
        homeGallery,
    } = useSelector(COMPANY_SELECTORS.companySettingsGeneralSelector);

    const initialValues = {
        logo,
        name: name || '',
        appColor,
        description: description || '',
        licenseLink: licenseLink || '',
        instagramLink: instagramLink || '',
        facebookLink: facebookLink || '',
        homeGallery: (homeGallery || []).map(({ background, id }) => ({ id: String(id), image: background })),
    };

    const validationSchema = useMemo(() => yup.object({
        logo: yup
            .mixed()
            .required(t(`${T_PREFIX}.fileInputField.errors.required`)),
        name: yup
            .string()
            .required(t(`${T_PREFIX}.name.errors.required`))
            .max(MAX_NAME_LENGTH, t(`${T_PREFIX}.name.errors.maxLength`, { length: MAX_NAME_LENGTH })),
        appColor: yup
            .string()
            .matches(COLOR_REGEX, t(`${T_PREFIX}.color.errors.invalid`))
            .max(MAX_COLOR_LENGTH, t(`${T_PREFIX}.color.errors.maxLength`, { length: MAX_COLOR_LENGTH }))
            .required(t(`${T_PREFIX}.color.errors.required`)),
        description: yup
            .string()
            .max(MAX_DESCRIPTION_LENGTH, t(`${T_PREFIX}.description.errors.maxLength`, { length: MAX_DESCRIPTION_LENGTH })),
        licenseLink: yup
            .string()
            .required(t(`${T_PREFIX}.licenseLink.errors.required`))
            .matches(LINK_REGEX, t(`${T_PREFIX}.licenseLink.errors.invalid`))
            .max(MAX_LICENSE_LINK_LENGTH, t(`${T_PREFIX}.licenseLink.errors.maxLength`, { length: MAX_LICENSE_LINK_LENGTH })),
        instagramLink: yup
            .string()
            .matches(LINK_REGEX, t(`${T_PREFIX}.instagramLink.errors.invalid`))
            .max(MAX_INSTAGRAM_LINK_LENGTH, t(`${T_PREFIX}.instagramLink.errors.maxLength`, { length: MAX_INSTAGRAM_LINK_LENGTH })),
        facebookLink: yup
            .string()
            .matches(LINK_REGEX, t(`${T_PREFIX}.facebookLink.errors.invalid`))
            .max(MAX_FACEBOOK_LINK_LENGTH, t(`${T_PREFIX}.facebookLink.errors.maxLength`, { length: MAX_FACEBOOK_LINK_LENGTH })),
        homeGallery: yup.array(yup.object({
            id: yup.string(),
            image: yup.mixed(),
        })),
    }), [t]);

    const onSubmit = useCallback((company) => {
        const data = new FormData();
        data.append('name', company.name);
        data.append('appColor', company.appColor);
        data.append('description', company.description);
        data.append('licenseLink', company.licenseLink);
        data.append('instagramLink', company.instagramLink);
        data.append('facebookLink', company.facebookLink);
        if (company.logo) {
            data.append('logo', company.logo);
        }
        const imagesToSave = company.homeGallery.filter(({ id }) => !id.startsWith('new-'));
        const imagesToUpload = company.homeGallery.filter(({ id }) => id.startsWith('new-'));
        if (imagesToSave.length) { imagesToSave.forEach((c, i) => data.append(`imagesToSave[${i}]`, c.id)); }
        if (imagesToUpload.length) { imagesToUpload.forEach((c, i) => data.append(`imagesToUpload[${i}]`, c.image)); }

        dispatch(COMPANY_ACTIONS.setCompanySettingsGeneral({ data }));
    }, [dispatch]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        errors,
        resetForm,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const hasAnyChanges = useMemo(
        () => JSON.stringify(values) !== JSON.stringify(initialValues),
        [values, initialValues],
    );

    const handleDiscard = useCallback(() => {
        resetForm();
    }, [resetForm]);

    const onSubmitHandler = useCallback(debounce(() => {
        handleSubmit();
    }, 500), [handleSubmit]);

    const handleChangeLogo = useCallback((file) => {
        setFieldTouched('logo', true);
        setFieldValue('logo', file);
    }, []);

    const handleChangeColor = useCallback((color) => {
        setFieldTouched('appColor', true);
        setFieldValue('appColor', color);
    }, []);

    const handleChangeHomeGallery = useCallback((images) => {
        setFieldTouched('homeGallery', true);
        setFieldValue('homeGallery', images);
    }, []);

    return (
        <Form className={styles.form}>
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.logo.label`)}
                </Form.Label>
                <FileUpload
                    name="logo"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    label={() => (
                        <Trans
                            ns={LOCALE_NAMESPACE.SETTINGS}
                            i18nKey={`${T_PREFIX}.logo.text`}
                            components={[
                                <span
                                    className={classNames({
                                        'text-dark': !(touched.logo && !!errors.logo),
                                        'text-danger': touched.logo && !!errors.logo,
                                    })}
                                />,
                                <span className={styles.fileInputSub} />,
                            ]}
                        />
                    )}
                    isInvalid={touched.logo && !!errors.logo}
                    defaultValue={values.logo}
                    onChange={handleChangeLogo}
                    className={classNames('p-4 mb-3', styles.fileInput)}
                    allowedFileSize={MAX_ALLOWED_FILE_SIZE}
                    allowedFormats={['.jpg', '.jpeg', '.png']}
                    touched={touched.logo}
                    square
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.name.label`)}
                </Form.Label>
                <Form.Control
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.name}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.description.label`)}
                </Form.Label>
                <Form.Control
                    name="description"
                    type="text"
                    value={values.description}
                    onChange={handleChange}
                    isInvalid={touched.description && !!errors.description}
                    as="textarea"
                    rows={5}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.description}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.galleryImages.label`, { count: MAX_GALERY_IMAGES })}
                </Form.Label>
                <ImagesGallery
                    allowedFileSize={MAX_ALLOWED_FILE_SIZE}
                    allowedFormats={['.jpg', '.jpeg', '.png']}
                    maxImagesCount={MAX_GALERY_IMAGES}
                    images={values.homeGallery}
                    onChange={handleChangeHomeGallery}
                    withoutEdit
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.color.label`)}
                </Form.Label>
                <ColorPicker
                    color={values.appColor}
                    onChange={handleChangeColor}
                    name={appColor}
                    isInvalid={touched.appColor && !!errors.appColor}
                    touched={touched.appColor}
                    errors={errors.appColor}
                />
            </Form.Group>

            <Dropdown.Divider className="my-4" />
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.licenseLink.label`)}
                </Form.Label>
                <Form.Control
                    name="licenseLink"
                    type="text"
                    value={values.licenseLink}
                    onChange={handleChange}
                    isInvalid={touched.licenseLink && !!errors.licenseLink}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.licenseLink}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.instagramLink.label`)}
                </Form.Label>
                <Form.Control
                    name="instagramLink"
                    type="text"
                    value={values.instagramLink}
                    onChange={handleChange}
                    isInvalid={touched.instagramLink && !!errors.instagramLink}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.instagramLink}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(`${T_PREFIX}.facebookLink.label`)}
                </Form.Label>
                <Form.Control
                    name="facebookLink"
                    type="text"
                    value={values.facebookLink}
                    onChange={handleChange}
                    isInvalid={touched.facebookLink && !!errors.facebookLink}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.facebookLink}
                </Form.Control.Feedback>
            </Form.Group>
            <div className={styles.actionsButtonsWrapper}>
                <Button
                    disabled={!hasAnyChanges || isSavingProcess}
                    color="outline"
                    onClick={handleDiscard}
                >
                    {t('actions.discard')}
                </Button>
                <Button
                    onClick={onSubmitHandler}
                    disabled={!hasAnyChanges}
                    loading={isSavingProcess}
                >
                    {t('actions.save')}
                </Button>
            </div>
        </Form>
    );
};

export default General;
