import sentry from 'services/sentry';
import { canvasPreview } from './canvasPreview';

let previewUrl = '';
let canvas = null;

function toBlob(canvas) {
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            if (!blob) {
                return;
            }
            resolve(blob);
        }, 'image/jpeg', 0.7);
    });
}

// Returns an image source you should set to state and pass
// `{previewUrl && <img alt="Crop preview" src={previewUrl} />}`
export async function imgPreview(
    image,
    crop,
) {
    if (!canvas) {
        canvas = document.createElement('canvas');
    }
    canvasPreview(image, canvas, crop);

    const blob = await toBlob(canvas);

    if (!blob) {
        sentry.error('Failed to create blob');
        return '';
    }

    if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
    }

    previewUrl = URL.createObjectURL(blob);

    return { blob, previewUrl };
}
