import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Row from 'react-bootstrap/Row';
import { newSelectStyles, theme } from 'styles/select';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as styles from './SubscriptionsSoldTitleFilter.module.scss';
import * as SUBSCRIPTIONS_SELECTORS from '../../../store/selectors/subscriptions';

const animatedComponents = makeAnimated();

const SubscriptionsSoldTitleFilter = ({ onChange }) => {
    const { t } = useTranslation('subscriptions');
    const subscriptionsOptions = useSelector(SUBSCRIPTIONS_SELECTORS.subscriptionsOptionsSelector);

    const options = useMemo(() => (
        subscriptionsOptions
            .map((o) => ({
                value: o.id,
                label: o.title ?? '',
            }))
            .sort((o1, o2) => {
                const label1 = o1.label?.toLowerCase();
                const label2 = o2.label?.toLowerCase();

                if (label1 === label2) {
                    return 0;
                }

                return label1 > label2 ? 1 : -1;
            })
    ), [subscriptionsOptions]);
    const handleChange = useCallback((selected) => {
        onChange((selected ?? [])?.map((o) => o.value));
    }, [onChange]);

    return (
        <Row className={styles.filtersContainer}>
            <Select
                name="soldSubscriptionsTitles"
                isSearchable={false}
                theme={theme}
                styles={newSelectStyles}
                className={styles.filter}
                components={animatedComponents}
                isMulti
                onChange={handleChange}
                placeholder={t('sold.filters.type.placeholder')}
                options={options}
            />
        </Row>
    );
};

SubscriptionsSoldTitleFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
};

SubscriptionsSoldTitleFilter.defaultProps = {
};

export default SubscriptionsSoldTitleFilter;
