import React from 'react';
import PropTypes from 'prop-types';

const EyeCrossedIcon = (props) => {
    const { className } = props;

    return (
        <svg className={className} viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M13.4814 16.2676c-2.9629 0-5.36137-2.2934-5.58207-5.1997L3.80167 7.90115C3.263 8.57693 2.7673 9.29139 2.3673 10.0726c-.08936.1768-.13592.3721-.13592.5702 0 .198.04656.3933.13592.5701 2.11836 4.1332 6.31211 6.9297 11.1141 6.9297 1.0511 0 2.0652-.1563 3.0425-.4086l-2.0269-1.5684c-.3348.0647-.6747.0988-1.0156.102zM25.74 18.5371l-4.3184-3.3375c1.3118-1.1055 2.3906-2.4608 3.1738-3.9871.0894-.1768.1359-.3721.1359-.5701 0-.1981-.0465-.3934-.1359-.5702-2.1183-4.13316-6.3121-6.92964-11.114-6.92964-2.0112.00244-3.98964.50874-5.75473 1.47265L2.75714.774199C2.69234.723772 2.61823.68661 2.53906.664834 2.45989.643059 2.3772.637097 2.29573.647291c-.08148.010194-.16015.036342-.23152.076952-.07136.04061-.13403.094884-.18441.159722l-.7668.987105c-.10172.13086-.147298.29676-.126713.46121.020583.16446.105643.314.236483.41575L24.2056 20.5109c.0648.0504.1389.0876.2181.1094.0791.0218.1618.0277.2433.0175.0815-.0102.1601-.0363.2315-.0769.0714-.0406.134-.0949.1844-.1597l.7672-.9872c.1017-.1309.1472-.2968.1265-.4612-.0206-.1645-.1058-.314-.2366-.4157zm-7.1766-5.5469l-1.5352-1.1867c.1293-.3736.1979-.7656.2032-1.1609.0076-.5788-.1206-1.15137-.3744-1.67163-.2538-.52025-.6261-.97374-1.0869-1.32403s-.9974-.58762-1.5666-.69292c-.5692-.10531-1.1552-.07565-1.7108.08659.2355.31918.363.70526.3637 1.10195-.0059.132-.026.26298-.0602.39062L9.92081 6.31092c.99879-.83469 2.25889-1.2924 3.56059-1.29336.7388-.00041 1.4704.1448 2.153.42734.6827.28254 1.3029.69686 1.8254 1.21927.5224.52241.9367 1.14267 1.2192 1.82531.2826.68264.4278 1.41428.4274 2.15312 0 .8449-.2067 1.6324-.543 2.348v-.0004z" />
        </svg>
    );
};

EyeCrossedIcon.propTypes = {
    className: PropTypes.string,
};

EyeCrossedIcon.defaultProps = {
    className: '',
};

export default EyeCrossedIcon;
