import * as yup from 'yup';
import i18n from '../../../services/i18n';

export const getSchema = () => (
    yup.object({
        product: yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        }).typeError(i18n.t('validationErrors.pleasePick', { name: i18n.t('addBookingModal.product').toLowerCase() })),
        service: yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        }).typeError(i18n.t('validationErrors.pleasePick', { name: i18n.t('addBookingModal.service').toLowerCase() })),
        subOptionService: yup.mixed({
            id: yup.number(),
            name: yup.string(),
        }).notRequired(),
        time: yup.object({
            from: yup.mixed().required(i18n.t('validationErrors.cannotBeEmpty', { name: i18n.t('addBookingModal.date') })),
        }).required(),
        client: yup.object({
            clientId: yup.number().nullable(),
            clientName: yup.string().required(i18n.t('validationErrors.cannotBeEmpty', { name: i18n.t('addBookingModal.name') })),
            clientPhone: yup
                .string()
                .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]{7,14}$/, i18n.t('validationErrors.invalidPhoneNumber'))
                .required(i18n.t('validationErrors.cannotBeEmpty', { name: i18n.t('addBookingModal.contactNumber') })),
            clientEmail: yup
                .string()
                .email(i18n.t('validationErrors.invalidEmailAddress')),
            isNew: yup.boolean().notRequired(),
        }).required(),
        recurrence: yup.object({
            enabled: yup.bool(),
            period: yup
                .number()
                .when('enabled', {
                    is: true,
                    then: (period) => period
                        .positive(i18n.t('validationErrors.mustBePositiveNumber', { name: i18n.t('addBookingModal.interval') }))
                        .typeError(i18n.t('validationErrors.cannotBeEmpty', { name: i18n.t('addBookingModal.field') }))
                        .required(i18n.t('validationErrors.cannotBeEmpty', { name: i18n.t('addBookingModal.field') })),
                    otherwise: (period) => period
                        .nullable(),
                }),
            infinite: yup.bool().when('enabled', { is: true, then: (infinite) => infinite.required() }),
            end: yup.mixed().when('infinite', {
                is: false,
                then: (end) => end.required(i18n.t('validationErrors.cannotBeEmpty', { name: i18n.t('addBookingModal.endRecurring') })),
            }),
            days: yup.array().of(yup.string().oneOf([
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ])).when('enabled', {
                is: true,
                then: (days) => days.required(i18n.t('validationErrors.pleasePickOneOrMore', { name: i18n.t('addBookingModal.days') })),
            }),
        }),
        sendWhatsAppMessage: yup.bool(),
        paid: yup.bool(),
    })
);
