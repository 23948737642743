import React from 'react';

const Time = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <polyline fill="none" points="12 6 12 12 18 12" />
            <path d="M1,12a11,11,0,1,1,3.45,8" fill="none" stroke={fill} strokeLinecap="butt" />
            <polygon fill={fill} points="3 24 0.6 17.2 7.8 17.6 3 24" stroke="none" />
        </g>
    </svg>
);

export default Time;
