import * as SERVICES_TYPES from './types';

export const getServices = () => ({
    type: SERVICES_TYPES.SERVICES_GET,
});

export const setServices = ({
    services, itemsCount, pagesCount,
}) => ({
    type: SERVICES_TYPES.SERVICES_SET,
    payload: {
        services,
        itemsCount,
        pagesCount,
    },
});

export const addServicesItem = ({ service, history, onFinal }) => ({
    type: SERVICES_TYPES.SERVICES_ITEM_ADD,
    payload: {
        service,
        history,
        onFinal,
    },
});

export const editServicesItem = ({
    serviceId, service, history, onFinal,
}) => ({
    type: SERVICES_TYPES.SERVICES_ITEM_EDIT,
    payload: {
        serviceId,
        service,
        history,
        onFinal,
    },
});

export const removeServicesItem = ({ serviceId }) => ({
    type: SERVICES_TYPES.SERVICES_ITEM_REMOVE,
    payload: { serviceId },
});

export const changePositionServicesItem = ({ serviceId, direction }) => ({
    type: SERVICES_TYPES.SERVICES_ITEM_CHANGE_POSITION,
    payload: { serviceId, direction },
});

export const toggleServicesItemRandomSelect = ({ serviceId }) => ({
    type: SERVICES_TYPES.SERVICES_ITEM_RANDOMLY_SELECT_TOGGLE,
    payload: { serviceId },
});

export const setServicesPage = ({ page }) => ({
    type: SERVICES_TYPES.SERVICES_PAGE_SET,
    payload: { page },
});

export const setServicesPageFirst = () => ({
    type: SERVICES_TYPES.SERVICES_PAGE_SET_FIRST,
});

export const setServicesPageLast = () => ({
    type: SERVICES_TYPES.SERVICES_PAGE_SET_LAST,
});

export const setServicesPagePrev = () => ({
    type: SERVICES_TYPES.SERVICES_PAGE_SET_PREV,
});

export const setServicesPageNext = () => ({
    type: SERVICES_TYPES.SERVICES_PAGE_SET_NEXT,
});

export const setServicesSearch = ({ search }) => ({
    type: SERVICES_TYPES.SERVICES_SEARCH_SET,
    payload: { search },
});

export const setServicesLoading = ({ loading }) => ({
    type: SERVICES_TYPES.SERVICES_LOADING_SET,
    payload: { loading },
});

export const getCompanyServices = () => ({
    type: SERVICES_TYPES.COMPANY_SERVICES_GET,
});

export const setCompanyServices = ({ companyServices }) => ({
    type: SERVICES_TYPES.COMPANY_SERVICES_SET,
    payload: { companyServices },
});

export const setCompanyServicesLoading = ({ loading }) => ({
    type: SERVICES_TYPES.COMPANY_SERVICES_LOADING_SET,
    payload: { loading },
});

export const getServiceItem = ({ serviceId }) => ({
    type: SERVICES_TYPES.SERVICE_ITEM_GET,
    payload: { serviceId },
});

export const setServiceItem = ({ service }) => ({
    type: SERVICES_TYPES.SERVICE_ITEM_SET,
    payload: { service },
});

export const setServiceItemLoading = ({ loading }) => ({
    type: SERVICES_TYPES.SERVICE_ITEM_LOADING_SET,
    payload: { loading },
});
