import React from 'react';
import PropTypes from 'prop-types';

const ChevronLeft = (props) => {
    const { className } = props;

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="7.5"
                fill="white"
                stroke="#D8E1EA"
            />
            <path
                d="M22 15L17 20L22 25"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

ChevronLeft.propTypes = {
    className: PropTypes.string,
};

ChevronLeft.defaultProps = {
    className: '',
};

export default ChevronLeft;
