import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
    SubscriptionsHeader,
    SubscriptionsFooter,
    ConfirmationModal,
} from 'components';

import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/Common/NewTable/Table';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { SubscriptionsListTableColumns } from '../../../../../../components/subscriptions/SubscriptionsListTableColumns';
import { SubscriptionsListTableRow } from '../../../../../../components/subscriptions/SubscriptionsListTableRow';

import {
    deleteSubscription,
    getSubscriptionsList,
    setCurrentPage,
    setSearchValue,
    setShowAll,
    toggleSubscriptionStatus,
} from '../../../../../../store/actions';
import {
    subscriptionsListPageSelector,
    subscriptionsTableFooterSelector,
} from '../../../../../../store/selectors/subscriptions';

import { SUBSCRIPTIONS_ADD_PAGE } from '../../../../../../const/CLIENT_URL';

function SubscriptionsList() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        isDataLoaded,
        subscriptionsList,
        searchValue,
    } = useSelector(subscriptionsListPageSelector);

    const {
        currentPage,
        itemsCount,
        pagesCount,
        showAll,
    } = useSelector(subscriptionsTableFooterSelector);

    const archiveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: useCallback((subscriptionId) => {
            dispatch(deleteSubscription({ subscriptionId }));
        }, [dispatch]),
    });

    const handleSearch = useCallback((search) => {
        dispatch(setSearchValue(search));
    }, [dispatch]);

    const onButtonClickHandler = useCallback(() => {
        history.push(SUBSCRIPTIONS_ADD_PAGE);
    }, [history.push]);

    const handleToggleStatus = useCallback(({ subscriptionId }) => {
        dispatch(toggleSubscriptionStatus(subscriptionId));
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(setShowAll(true));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(setCurrentPage(currentPage - 1));
    }, [dispatch, currentPage]);

    const handleNextPage = useCallback(() => {
        dispatch(setCurrentPage(currentPage + 1));
    }, [dispatch, currentPage]);

    const handleLastPage = useCallback(() => {
        dispatch(setCurrentPage(pagesCount));
    }, [dispatch, pagesCount]);

    const handleFirstPage = useCallback(() => {
        dispatch(setCurrentPage(1));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(setShowAll(false));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(setCurrentPage(nextPage));
    }, [dispatch]);

    const handleDuplicateSubscription = useCallback(({ subscriptionId }) => {
        history.push(SUBSCRIPTIONS_ADD_PAGE, { subscriptionId });
    }, [dispatch]);

    const handleArchive = useCallback(({ subscriptionId }) => {
        archiveConfirmDialog.onSetData(subscriptionId);
        archiveConfirmDialog.onShow();
    }, [archiveConfirmDialog.onSetData, archiveConfirmDialog.onShow]);

    useEffect(() => {
        dispatch(getSubscriptionsList());
    }, [dispatch, currentPage, searchValue]);

    return (
        <>
            <SubscriptionsHeader
                onButtonClickHandler={onButtonClickHandler}
                onSearch={handleSearch}
                searchValue={searchValue}
            />
            <Container>
                <Content loading={!isDataLoaded}>
                    <Table>
                        <TableHeader>
                            <SubscriptionsListTableColumns />
                        </TableHeader>
                        <TableBody>
                            {subscriptionsList.map((subscription) => (
                                <SubscriptionsListTableRow
                                    key={subscription?.id}
                                    subscription={subscription}
                                    onToggleStatus={handleToggleStatus}
                                    onArchive={handleArchive}
                                    onDuplicate={handleDuplicateSubscription}
                                />
                            ))}
                        </TableBody>
                        <ListTableFooter
                            page={currentPage}
                            showAll={showAll}
                            itemsCount={itemsCount}
                            pagesCount={pagesCount}
                            columnsCount={5}
                            itemsPerPage={10}
                            onShowAll={handleShowAll}
                            onPrevPage={handlePrevPage}
                            onNextPage={handleNextPage}
                            onLastPage={handleLastPage}
                            onFirstPage={handleFirstPage}
                            onShowPages={handleShowPages}
                            onChangePage={handleChangePage}
                        />
                    </Table>
                    <SubscriptionsFooter
                        onButtonClickHandler={onButtonClickHandler}
                        onSearch={handleSearch}
                        searchValue={searchValue}
                    />
                    <ConfirmationModal
                        isShow={archiveConfirmDialog.visible}
                        titleText={t('subscriptions.archive.title')}
                        bodyText={t('subscriptions.archive.text')}
                        deleteText={t('subscriptions.archive.actions.confirm')}
                        dismissText={t('subscriptions.archive.actions.reject')}
                        confirmAction={archiveConfirmDialog.onConfirm}
                        hide={archiveConfirmDialog.onReject}
                    />
                </Content>
            </Container>
        </>
    );
}

export default SubscriptionsList;
