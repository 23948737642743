import React from 'react';

const Clock = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <circle cx="12" cy="12" fill="none" r="11" stroke={fill} />
            <polyline fill="none" points=" 12,6 12,12 18,12 " />
        </g>
    </svg>
);

export default Clock;
