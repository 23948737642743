import * as TYPES from '../../actions/messages/types';

const INITIAL_STATE = {
    item: null,
    isLoading: false,
    isSavingProcess: false,
};

export const messageItemReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case TYPES.MESSAGE_ITEM_SET: {
        const { message } = payload;
        return {
            ...state,
            item: message,
        };
    }
    case TYPES.MESSAGE_ITEM_LOADING_SET: {
        const { isLoading } = payload;
        return {
            ...state,
            isLoading,
        };
    }
    case TYPES.MESSAGE_ITEM_SAVING_PROCESS_SET: {
        const { isSavingProcess } = payload;
        return {
            ...state,
            isSavingProcess,
        };
    }
    default:
        return state;
    }
};
