import React from 'react';
import { ComingSoon } from 'components';

function ClientProfiles() {
    return (
        <ComingSoon />
    );
}

export default ClientProfiles;
