import React, { useRef, useMemo } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

// Actions

// Components
import {
    Modal, Form, Row, Col, InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import * as yup from 'yup';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { Button } from 'components/Common/Button';
import { Calendar, Clock } from '../Icon/Icon';
import DateInput from '../DateInput/DateInput';
import HourInput from '../HourInput/HourInput';

import * as BLOCK_ACTIONS from '../../store/actions/products';

// Styles
import { styles, theme, dangerTheme } from '../../styles/select';

import * as actions from '../../store/actions';
import { permissionsBlockSelector } from '../../store/selectors/permissions';

const T_WEEK_DAYS_MAP = {
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday',
    maandag: 'monday',
    dinsdag: 'tuesday',
    woensdag: 'wednesday',
    donderdag: 'thursday',
    vrijdag: 'friday',
    zaterdag: 'saturday',
    zondag: 'sunday',
    montag: 'monday',
    dienstag: 'tuesday',
    mittwoch: 'wednesday',
    donnerstag: 'thursday',
    freitag: 'friday',
    samstag: 'saturday',
    sonntag: 'sunday',
    pirmadienis: 'monday',
    antradienis: 'tuesday',
    trečiadienis: 'wednesday',
    ketvirtadienis: 'thursday',
    penktadienis: 'friday',
    šeštadienis: 'saturday',
    sekmadienis: 'sunday',
};

const MINUTES_IN_DAY = 1440;

const BlockModal = (props) => {
    const {
        show,
        onHide,
        type = 'objects',
        actionType,
        blockId,
        productBlock,
        from,
        to,
        note,
    } = props;

    const { t } = useTranslation();
    /* istanbul ignore next */
    const schema = useRef(yup.object({
        product: yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        }).typeError(t('validationErrors.pleasePick', { name: t('blockModal.product').toLowerCase() })),
        time: yup.object({
            from: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('blockModal.blockFrom') })),
            to: yup
                .mixed()
                .when('wholeDay', {
                    is: false,
                    then: (to) => to
                        .required(t('validationErrors.cannotBeEmpty', { name: t('blockModal.blockTo') })),
                }),
            wholeDay: yup.boolean().required(),
        }),
        note: yup.string(),
    })).current;

    const products = useSelector((state) => state[type][type]);
    const openingHours = useSelector((state) => Object.entries(state.shop.workingDays || {})
        .reduce((openingHours, [day, hours]) => ({
            ...openingHours,
            [_.upperFirst(day)]: hours,
        }), {}));
    const { banAddResource: banAddBlockToResource } = useSelector(permissionsBlockSelector);
    const { userRole, resourceId } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const defaultProductValue = useMemo(() => {
        if (userRole === 'employee' && products.length === 1) {
            return { id: products[0].id, name: products[0].name };
        }
        return productBlock ? { id: productBlock.id, name: productBlock.name } : null;
    }, [userRole, products, productBlock]);

    const {
        handleSubmit, handleChange, setFieldValue, values, isSubmitting, errors, resetForm, touched,
    } = useFormik({
        validationSchema: schema,
        initialValues: {
            blockId: blockId || null,
            product: defaultProductValue,
            time: {
                from: from || null,
                to: to || null,
                wholeDay: from ? moment.duration(to.diff(from)).asMinutes() >= MINUTES_IN_DAY - 1
                                && moment.duration(to.diff(from)).asMinutes() < MINUTES_IN_DAY : false,
            },
            note: note || '',
        },
        onSubmit: (values, { setSubmitting, setErrors, setFieldError }) => {
            const hourFrom = checkHourInputAvailability('from');
            const hourTo = checkHourInputAvailability('to');

            if (values.time.wholeDay) {
                if (!hourFrom.from || !hourFrom.to) {
                    setFieldError('time.from', t('timetable.shopClosedShort'));
                    return setSubmitting(false);
                }
            } else {
                if (!hourFrom.from || !hourFrom.to) {
                    setFieldError('time.from', t('timetable.shopClosedShort'));
                }

                if (!hourTo.from || !hourTo.to) {
                    setFieldError('time.to', t('timetable.shopClosedShort'));
                }

                if (!hourFrom.from || !hourFrom.to || !hourTo.from || !hourTo.to) {
                    return setSubmitting(false);
                }
            }

            if (actionType === 'add') {
                dispatch(actions.addTimeBlock({
                    block: {
                        ...values,
                        product: {
                            ...values.product,
                            type,
                        },
                    },
                }))
                    .then(() => {
                        addToast(t('blockModal.blockAddedSuccessfully'), {
                            appearance: 'success',
                        });

                        onHideHandler();
                    })
                    .catch(({ message, errors }) => {
                        if (message) {
                            addToast(message, {
                                appearance: 'error',
                            });
                        }

                        setErrors(errors || {});
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(actions.editTimeBlock({
                    block: {
                        ...values,
                        product: {
                            ...values.product,
                            type,
                        },
                    },
                }))
                    .then(() => {
                        onHideHandler();
                    })
                    .catch(({ errors }) => {
                        setErrors(errors || {});
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        },
    });

    const onHideHandler = () => {
        onHide();
        resetForm();
    };

    const onDeleteHandler = () => {
        dispatch(BLOCK_ACTIONS.deleteTimeBlock({
            block: {
                ...values,
                product: {
                    ...values.product,
                    type,
                },
            },
        }))
            .then(() => {
                onHideHandler();
            });
    };

    // option = 'from' | 'to';
    const checkHourInputAvailability = (option, extraTime) => {
        const { product, time } = values;

        const endTime = extraTime ?? time[option];

        const weekDay = (time.from || moment()).format('dddd').toLowerCase();
        const weekDayKey = T_WEEK_DAYS_MAP[weekDay];
        const defaultFrom = openingHours[_.upperFirst(weekDayKey)]?.from;
        const defaultTo = openingHours[_.upperFirst(weekDayKey)]?.to;

        if (!product?.id || !endTime) {
            return {
                from: defaultFrom,
                to: defaultTo,
            };
        }

        const foundProduct = products.find((product) => product.id === values.product.id);

        if (foundProduct?.hasOwnSchedule) {
            const scheduleForWeekday = foundProduct.workingDays[T_WEEK_DAYS_MAP[endTime.format('dddd').toLowerCase()]];

            if (scheduleForWeekday) {
                const { from, to } = scheduleForWeekday;
                return { from, to };
            }
            return { from: null, to: null };
        }

        return {
            from: defaultFrom,
            to: defaultTo,
        };
    };

    return (
        <Modal size="lg" show={show} onHide={onHideHandler} centered>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0 font-weight-600">
                        {actionType === 'add'
                            ? t('blockModal.title.add') : t('blockModal.title.edit')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group>
                        <Form.Label>
                            {t('blockModal.product')}
                        </Form.Label>
                        <Select
                            isDisabled={!!resourceId && banAddBlockToResource}
                            styles={styles}
                            theme={(touched.product && errors.product) ? dangerTheme : theme}
                            name="product"
                            options={products ? products.map(({ id: value, name: label }) => ({ value, label })) : []}
                            value={values.product ? { value: values.product.id, label: values.product.name } : null}
                            onChange={(product) => setFieldValue('product', { id: product.value, name: product.label })}
                            placeholder={t('placeholders.select')}
                        />
                        <Form.Control.Feedback
                            className={!!touched.product && !!errors.product && 'd-block'}
                            type="invalid"
                        >
                            {errors.product}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {values.time.wholeDay ? (
                        <Form.Group className="mb-3">
                            <label className="form-label">{t('blockModal.blockADay')}</label>
                            <InputGroup>
                                <DateInput
                                    name="time.from.date"
                                    value={values.time.from}
                                    placeholder={t('blockModal.selectDate')}
                                    onChange={(time) => setFieldValue('time.from', time)}
                                    isInvalid={!!touched.time?.from && !!errors.time?.from}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text className={classNames({
                                        'border-danger text-danger': errors.time?.from,
                                        'text-muted': !touched.time?.from || !errors.time?.from,
                                    })}
                                    >
                                        <Calendar width={18} />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>

                            <Form.Control.Feedback
                                className={touched.time?.from && errors.time?.from && 'd-block'}
                                type="invalid"
                            >
                                {errors.time?.from}
                            </Form.Control.Feedback>
                        </Form.Group>
                    ) : (
                        <Row className="mt-4">
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3">
                                    <label className="form-label">{t('blockModal.blockFrom')}</label>
                                    <InputGroup>
                                        <DateInput
                                            name="time.from.date"
                                            value={values.time.from}
                                            placeholder={t('blockModal.selectDate')}
                                            onChange={(time) => setFieldValue('time.from', time)}
                                            isInvalid={!!touched.time?.from && !!errors.time?.from}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className={classNames({
                                                'border-danger text-danger': touched.time?.from && errors.time?.from,
                                                'text-muted': !touched.time?.from || !errors.time?.from,
                                            })}
                                            >
                                                <Calendar width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <InputGroup>
                                        <HourInput
                                            name="time.from.time"
                                            value={values.time.from}
                                            placeholder={t('blockModal.selectTime')}
                                            onChange={(time) => setFieldValue('time.from', time)}
                                            isInvalid={!!touched.time?.from && !!errors.time?.from}
                                            min={checkHourInputAvailability('from').from}
                                            max={checkHourInputAvailability('from').to}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className={classNames({
                                                'border-danger text-danger': touched.time?.from && errors.time?.from,
                                                'text-muted': !touched.time?.from || !errors.time?.from,
                                            })}
                                            >
                                                <Clock width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    <Form.Control.Feedback
                                        className={touched.time?.from && errors.time?.from && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.time?.from}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3">
                                    <label className="form-label">{t('blockModal.blockTo')}</label>
                                    <InputGroup>
                                        <DateInput
                                            name="time.to.date"
                                            value={values.time.to}
                                            placeholder={t('blockModal.selectDate')}
                                            onChange={(time) => setFieldValue('time.to', time)}
                                            isInvalid={!!touched.time?.to && !!errors.time?.to}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className={classNames({
                                                'border-danger text-danger': touched.time?.to && errors.time?.to,
                                                'text-muted': !touched.time?.to || !errors.time?.to,
                                            })}
                                            >
                                                <Calendar width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <InputGroup>
                                        <HourInput
                                            name="time.to.time"
                                            value={values.time.to}
                                            placeholder={t('blockModal.selectTime')}
                                            onChange={(time) => setFieldValue('time.to', time)}
                                            isInvalid={!!touched.time?.to && !!errors.time?.to}
                                            min={checkHourInputAvailability('to').from}
                                            max={checkHourInputAvailability('to').to}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className={classNames({
                                                'border-danger text-danger': touched.time?.to && errors.time?.to,
                                                'text-muted': !touched.time?.to || !errors.time?.to,
                                            })}
                                            >
                                                <Clock width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    <Form.Control.Feedback
                                        className={touched.time?.to && errors.time?.to && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.time?.to}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}

                    <Form.Group className="my-3">
                        <Form.Check
                            name="time.wholeDay"
                            type="checkbox"
                            label={t('blockModal.blockADay')}
                            checked={values.time.wholeDay}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            {t('blockModal.notes')}
                        </Form.Label>
                        <Form.Control
                            name="note"
                            as="textarea"
                            rows={3}
                            value={values.note}
                            onChange={handleChange}
                            isInvalid={!!errors.note}
                        />

                        <Form.Control.Feedback className={errors.time && errors.time.to && 'd-block'} type="invalid">
                            {errors.note}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-between">
                    <Row>
                        <Button
                            type="button"
                            color="outline"
                            className="mr-3"
                            onClick={onHideHandler}
                        >
                            {t('blockModal.cancel')}
                        </Button>
                        {actionType === 'edit'
                        && (
                            <Button
                                type="button"
                                color="red"
                                onClick={onDeleteHandler}
                            >
                                {t('blockModal.actions.delete')}
                            </Button>
                        )}
                    </Row>

                    <Button type="submit" loading={isSubmitting}>
                        { (actionType === 'add'
                            ? t('blockModal.actions.add') : t('blockModal.actions.edit'))}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default BlockModal;
