import React from 'react';
import PropTypes from 'prop-types';

const CardboardBoxIcon = (props) => {
    const { className } = props;

    return (
        <svg
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fill="currentColor"
                d="M480 32H32C14.327 32 0 46.328 0 64v64h512V64c0-17.672-14.327-32-32-32zM32 448.008C32 465.672 46.325 480 63.995 480h384.011c17.67 0 31.994-14.328 31.994-31.992V160H32v288.008zM208 224h96c17.673 0 32 14.328 32 32s-14.327 32-32 32h-96c-17.673 0-32-14.328-32-32s14.327-32 32-32z"
            />
        </svg>
    );
};

CardboardBoxIcon.propTypes = {
    className: PropTypes.string,
};

CardboardBoxIcon.defaultProps = {
    className: '',
};

export default CardboardBoxIcon;
