import React from 'react';

const Sort = ({
    width = 24,
    height = 24,
    className = '',
    upClassName = '',
    downClassName = '',
}) => (
    <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className={upClassName} d="M12 6L15.1046 9.30324C15.4349 9.75885 15.2008 10.5403 14.7327 10.5403H9.52644C9.05831 10.5403 8.82425 9.75885 9.15456 9.30324L12 6Z" fill="currentColor" />
        <path className={downClassName} d="M12 18L8.89544 14.6968C8.56513 14.2412 8.79919 13.4597 9.26732 13.4597H14.4736C14.9417 13.4597 15.1758 14.2412 14.8454 14.6968L12 18Z" fill="currentColor" />
    </svg>
);

export default Sort;
