const PREFIX = '@MESSAGES/';

const MESSAGES = `${PREFIX}MESSAGES`;
export const MESSAGES_GET = `${MESSAGES}:GET`;
export const MESSAGES_SET = `${MESSAGES}:SET`;
export const MESSAGES_CLEAR = `${MESSAGES}:CLEAR`;
const MESSAGES_ITEM = `${MESSAGES}:ITEM`;
export const MESSAGES_ITEM_ADD = `${MESSAGES_ITEM}:ADD`;
export const MESSAGES_ITEM_EDIT = `${MESSAGES_ITEM}:EDIT`;
export const MESSAGES_ITEM_ADD_CLIENTS = `${MESSAGES_ITEM}:ADD_CLIENTS`;
export const MESSAGES_ITEM_REMOVE = `${MESSAGES_ITEM}:REMOVE`;
const MESSAGES_PAGE = `${MESSAGES}:PAGE`;
export const MESSAGES_PAGE_SET = `${MESSAGES_PAGE}:SET`;
export const MESSAGES_PAGE_SET_FIRST = `${MESSAGES_PAGE_SET}:FIRST`;
export const MESSAGES_PAGE_SET_LAST = `${MESSAGES_PAGE_SET}:LAST`;
export const MESSAGES_PAGE_SET_PREV = `${MESSAGES_PAGE_SET}:PREV`;
export const MESSAGES_PAGE_SET_NEXT = `${MESSAGES_PAGE_SET}:NEXT`;
export const MESSAGES_SHOW_ALL = `${MESSAGES}:SHOW_ALL`;
const MESSAGES_SEARCH = `${MESSAGES}:SEARCH`;
export const MESSAGES_SEARCH_SET = `${MESSAGES_SEARCH}:SET`;
const MESSAGES_LOADING = `${MESSAGES}:LOADING`;
export const MESSAGES_LOADING_SET = `${MESSAGES_LOADING}:SET`;
const MESSAGES_SORT_OPTIONS = `${MESSAGES}:SORT_OPTIONS`;
export const MESSAGES_SORT_OPTIONS_SET = `${MESSAGES_SORT_OPTIONS}:SET`;
const MESSAGE_ITEM_SAVING_PROCESS = `${MESSAGES}:SAVING_PROCESS`;
export const MESSAGE_ITEM_SAVING_PROCESS_SET = `${MESSAGE_ITEM_SAVING_PROCESS}:SET`;

const MESSAGE_ITEM = `${PREFIX}MESSAGE_ITEM`;
export const MESSAGE_ITEM_GET = `${MESSAGE_ITEM}:GET`;
export const MESSAGE_ITEM_SET = `${MESSAGE_ITEM}:SET`;
const MESSAGE_ITEM_LOADING = `${MESSAGE_ITEM}:LOADING`;
export const MESSAGE_ITEM_LOADING_SET = `${MESSAGE_ITEM_LOADING}:SET`;
export const MESSAGE_ITEM_SHOW_DETAILS = `${MESSAGES_ITEM}:SHOW_DETAILS`;
export const MESSAGE_ITEM_HIDE_DETAILS = `${MESSAGES_ITEM}:HIDE_DETAILS`;
