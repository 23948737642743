import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { Container, Dropdown } from 'react-bootstrap';
import {
    Switch, Link, Route, Redirect,
} from 'react-router-dom';
import {
    ChevronLeft, ChevronRight, Plus, Sliders,
} from 'react-feather';
import {
    Footer,
    BlockModal,
    AddBookingModal,
    BookingModal,
    EditBookingModal,
} from 'components';

// Utils
import moment from 'moment';
import { permissionsResourceSelector, permissionsBlockSelector } from 'store/selectors/permissions';
import { Row } from 'components/Common/Row';
import { Button } from 'components/Common/Button';
import IconButton from 'components/Common/IconButton/IconButton';
import {
    Planning, Day, Week, Month,
} from '..';
import { generateRandomString } from '../../../helpers/string/generateRandomString';

const TimeUnitsWrapper = (
    {
        type,
        viewType,
        date,
        week,
        month,
        year,
        setDate,
        setCalendarsDate,
        setWeek,
        setMonth,
        setYear,
        setShowOptionsModal,
    },
) => {
    const addBookingDetails = useSelector(
        (state) => state.bookings.addBookingDetails,
    );
    const { locale } = useSelector((state) => state.locales);
    const { banAdd } = useSelector(permissionsResourceSelector);
    const { banAdd: banBlockAdd } = useSelector(permissionsBlockSelector);

    const [showBlockModal, setShowBlockModal] = useState(false);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [clickedBookingID, setClickedBookingID] = useState(null);
    const [editBooking, setEditBooking] = useState(false);
    const [blockKey, setBlockKey] = useState(generateRandomString());

    useEffect(() => {
        if (!addBookingDetails) {
            return;
        }

        setShowBookingModal(true);
    }, [addBookingDetails]);

    useEffect(() => {
        setDate((date) => date.week(week).month(month).year(year));
    }, [week, month, year]);

    const { t } = useTranslation();
    return (
        <>
            <Container
                className="position-sticky d-flex flex-wrap bg-white pb-3 px-0 w-100 mw-100"
                style={{ top: 0, zIndex: 900 }}
                fluid={viewType === 'planning'}
            >
                <div
                    className="d-flex justify-content-center justify-content-md-start align-items-center flex-grow-1 mt-3 mr-4"
                >
                    <Row gap={0}>
                        <IconButton
                            size={32}
                            color="outline"
                            group
                            onClick={() => {
                                const newDate = date
                                    .clone()
                                    .subtract(1, viewType === 'planning' ? 'week' : viewType);
                                setDate(newDate);
                                setMonth(newDate.month());
                                setWeek(newDate.isoWeek());
                                setYear(newDate.year());
                            }}
                        >
                            <ChevronLeft size={18} />
                        </IconButton>

                        <IconButton
                            size={32}
                            color="outline"
                            group
                            onClick={() => {
                                const newDate = date
                                    .add(1, viewType === 'planning' ? 'week' : viewType);
                                setDate(newDate);
                                setWeek(newDate.isoWeek());
                                setMonth(newDate.month());
                                setYear(newDate.year());
                            }}
                        >
                            <ChevronRight size={18} />
                        </IconButton>
                    </Row>

                    <h3 className="mb-0 font-weight-normal mx-2 mx-xl-4">
                        {viewType === 'day' && date.clone().locale(locale).format('D MMM YYYY')}
                        {(viewType === 'week' || viewType === 'planning')
                        && `${date.clone().locale(locale).startOf('isoWeek').format('D')}${
                            !date
                                .clone()
                                .locale(locale)
                                .startOf('isoWeek')
                                .isSame(date.clone().endOf('isoWeek'), 'month')
                                ? date.clone().locale(locale).startOf('isoWeek').format(' MMM')
                                : ''
                        } - ${date.clone().locale(locale).endOf('isoWeek').format('D MMM YYYY')}`}
                        {viewType === 'month' && date.clone().locale(locale).format('MMMM YYYY')}
                    </h3>

                    <Button
                        size="small"
                        color={
                            date.isSame(new Date(), 'day') ? 'outline' : 'yellow'
                        }
                        onClick={() => {
                            setCalendarsDate(moment());
                            setDate(moment());
                            setWeek(moment().isoWeek());
                            setMonth(moment().month());
                            setYear(moment().year());
                        }}
                        data-testid="data-test-today-button"
                    >
                        {t('agendaRoute.today')}
                    </Button>
                </div>

                <div className="d-none d-md-flex justify-content-end flex-grow-0 mt-3">
                    <Row gap={16}>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="outline-light"
                                className="text-dark px-3"
                            >
                                {t(`agendaRoute.${viewType}`)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to={`/agenda/${type}`}>
                                    {t('agendaRoute.day')}
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to={`/agenda/${type}/week`}>
                                    {t('agendaRoute.week')}
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to={`/agenda/${type}/month`}>
                                    {t('agendaRoute.month')}
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to={`/agenda/${type}/planning`}>
                                    {t('agendaRoute.planning')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {!banBlockAdd && (
                            <Button
                                color="outline"
                                onClick={() => setShowBlockModal(true)}
                            >
                                {t('agendaRoute.addBlock')}
                            </Button>
                        )}

                        {!banAdd && (
                            <Button
                                color="outline"
                                href={`/agenda/${type}/add`}
                            >
                                {t(
                                    `agendaRoute.${type === 'objects' ? 'addObject' : 'addEmployee'}`,
                                )}
                            </Button>
                        )}
                        <Button
                            color="yellow"
                            onClick={() => setShowBookingModal(true)}
                        >
                            {t('agendaRoute.addBooking')}
                        </Button>
                    </Row>
                </div>
            </Container>

            <Switch>
                <Route
                    path="/agenda/:type/"
                    exact
                    render={() => (
                        <Day
                            productType={type.slice(0, -1)}
                            date={date}
                            onBookingClick={({ id, timestamp }) => setClickedBookingID({ id, timestamp })}
                            type={type}
                        />
                    )}
                />
                <Route
                    path="/agenda/:type/week"
                    render={() => (
                        <Week
                            productType={type.slice(0, -1)}
                            globalDate={date}
                            week={week}
                            year={year}
                            onBookingClick={({ id, timestamp }) => setClickedBookingID({ id, timestamp })}
                            clickedBookingId={clickedBookingID}
                        />
                    )}
                />
                <Route
                    path="/agenda/:type/month"
                    render={() => (
                        <Month
                            productType={type.slice(0, -1)}
                            globalDate={date}
                            month={month}
                            year={year}
                            setMonth={setMonth}
                            setYear={setYear}
                            onBookingClick={({ id, timestamp }) => setClickedBookingID({ id, timestamp })}
                            clickedBookingId={clickedBookingID}
                        />
                    )}
                />
                <Route
                    path="/agenda/:type/planning"
                    render={() => (
                        <Planning
                            week={week}
                            year={year}
                            productType={type.slice(0, -1)}
                            onBookingClick={({ id, timestamp }) => setClickedBookingID({ id, timestamp })}
                        />
                    )}
                />
                <Redirect to={`/agenda/${type}`} />
            </Switch>

            <Footer className="d-flex d-lg-none">
                <IconButton
                    color="white"
                    size={60}
                    onClick={() => setShowOptionsModal(true)}
                >
                    <Sliders size={24} />
                </IconButton>

                <Dropdown className="flex-grow-1" drop="up">
                    <Dropdown.Toggle
                        variant="outline-light"
                        className="w-100 h-100 text-dark px-3 py-3 rounded-0 border-top-0 border-bottom-0"
                    >
                        {t(`agendaRoute.${viewType}`)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/agenda/${type}`}>
                            {t('agendaRoute.day')}
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/agenda/${type}/week`}>
                            {t('agendaRoute.week')}
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/agenda/${type}/month`}>
                            {t('agendaRoute.month')}
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/agenda/${type}/planning`}>
                            {t('agendaRoute.planning')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown drop="up">
                    <Dropdown.Toggle
                        variant="outline-light"
                        className="border-0 text-dark px-3 px-sm-5 h-100 no-arrow"
                    >
                        <Plus size={24} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {!banBlockAdd && (
                            <Dropdown.Item>
                                <Button
                                    color="outline"
                                    stretched
                                    onClick={() => setShowBlockModal(true)}
                                >
                                    {t('agendaRoute.addBlock')}
                                </Button>
                            </Dropdown.Item>

                        )}

                        {!banAdd && (
                            <Dropdown.Item>
                                <Button
                                    color="outline"
                                    stretched
                                    href={`/agenda/${type}/add`}
                                >
                                    {t(
                                        `agendaRoute.${type === 'objects' ? 'addObject' : 'addEmployee'}`,
                                    )}
                                </Button>
                            </Dropdown.Item>

                        )}

                        <Dropdown.Item>
                            <Button
                                color="yellow"
                                stretched
                                onClick={() => setShowBookingModal(true)}
                            >
                                {t('agendaRoute.addBooking')}
                            </Button>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Footer>

            <BlockModal
                key={blockKey}
                show={showBlockModal}
                onHide={() => {
                    setShowBlockModal(false);
                    setBlockKey(generateRandomString());
                }}
                type={type}
                actionType="add"
            />

            {showBookingModal && (
                <AddBookingModal
                    show={showBookingModal}
                    onHide={() => setShowBookingModal(false)}
                    productType={type.slice(0, -1)}
                />
            )}

            <BookingModal
                bookingIDWithTimestamp={clickedBookingID}
                show={clickedBookingID && !editBooking}
                onHide={() => setClickedBookingID(null)}
                onEditChange={() => setEditBooking(true)}
                productType={type.slice(0, -1)}
            />

            <EditBookingModal
                bookingIDWithTimestamp={clickedBookingID}
                onBookingClick={setClickedBookingID}
                show={editBooking}
                onHide={() => setEditBooking(false)}
                productType={type.slice(0, -1)}
            />
        </>
    );
};

export default TimeUnitsWrapper;
