import { useCallback, useState } from 'react';

import { useDialog } from './useDialog';

export const useConfirmDialog = ({
    defaultState = false,
    onShow: onShowProp,
    onSetShow: onSetShowProp,
    onConfirm: onConfirmProp,
    onReject: onRejectProp,
} = {}) => {
    const [data, setData] = useState(null);

    const dialog = useDialog(defaultState);

    const onSetData = useCallback((d) => {
        setData(d);
    }, []);

    const onResetData = useCallback(() => {
        setData(null);
    }, []);

    const onShow = useCallback(() => {
        dialog.onShow();
        onShowProp?.();
    }, [dialog.onShow, onShowProp]);

    const onSetShow = useCallback((value) => {
        dialog.onSetShow(value);
        onSetShowProp?.(value);
        if (!value) {
            onResetData();
        }
    }, [dialog.onSetShow, onSetShowProp, onResetData]);

    const onConfirm = useCallback(() => {
        dialog.onClose();
        onConfirmProp?.(data);
        onResetData();
    }, [
        dialog.onClose,
        onConfirmProp,
        data,
        onResetData,
    ]);

    const onReject = useCallback(() => {
        dialog.onClose();
        onRejectProp?.(data);
        onResetData();
    }, [
        dialog.onClose,
        onRejectProp,
        data,
        onResetData,
    ]);

    return {
        data,
        onSetData,
        onResetData,
        ...dialog,
        onShow,
        onSetShow,
        onConfirm,
        onReject,
    };
};
