import React from 'react';

// Hooks
import {
    useState, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import classNames from 'classnames';
import * as styles from './BookingsList.module.scss';

// Utils

const BookingsList = ({
    bookings, style, onClick, productType,
}) => {
    const [bookingsVisible, setBookingsVisible] = useState(0);

    const ref = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (!ref.current) {
                return;
            }
            const visible = ref.current.clientHeight / 30;
            setBookingsVisible(Math.round(visible));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return /* istanbul ignore next */ () => window.removeEventListener('resize', handleResize);
    }, [bookings?.length]);

    const { t } = useTranslation();

    if (bookings.length === 0) {
        return <p>{t('bookingsDayList.noBookings')}</p>;
    }

    return (
        <ul
            ref={ref}
            className={classNames(styles.container, { [styles.hoverable]: !!onClick })}
            style={style}
            onClick={onClick}
        >
            {bookings.slice(0, Math.min(bookings.length, /* istanbul ignore next */ bookingsVisible)).map((
                {
                    id, service, product, payment, isFull, isCancelled, notPresent,
                },
            ) => (
                <li
                    key={`booking-${id}`}
                    className={classNames(
                        styles.booking,
                        {
                            [styles.paidAfterwards]: productType !== 'groups' && !payment?.isInProgress && !payment?.paid,
                            [styles.paid]: productType !== 'groups' && !payment?.isInProgress && payment?.paid,
                            [styles.full]: productType === 'groups' && isFull,
                            [styles.cancelled]: productType === 'groups' && isCancelled,
                            [styles.notPresent]: notPresent,
                            [styles.inProgress]: payment?.isInProgress,
                        },
                        'd-block font-size-14 font-weight-600',
                    )}
                >
                    {service.name}
                    <br />
                    <span className={styles.fullName}>
                        {product?.name}
                    </span>
                </li>
            ))}

            {(bookings.length - bookingsVisible > 0) && (
                <span className="font-size-14 font-weight-bold">
                    {t('weekRoute.more', { count: bookings.length - bookingsVisible })}
                </span>
            )}
        </ul>
    );
};

export default BookingsList;
