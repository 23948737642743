import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { NumberInputWithControls } from 'components';
import { styles as selectStyles, theme as selectTheme } from 'styles/select';
import { isInvalid, getError } from 'services/validationService';
import { instantFormValidation } from 'helpers/input/instantFieldValidation';
import { useSelector } from 'react-redux';
import { companyBuisnessModeSelector } from 'store/selectors/company';
import { Tooltip } from 'components/Common/Tooltip';

export const getVATRateSelectOptions = (enableBusinessMode) => {
    const result = [
        {
            label: '0%',
            value: 0,
        },
        {
            label: '6%',
            value: 6,
        },
        {
            label: '9%',
            value: 9,
        },
        {
            label: '21%',
            value: 21,
        },
    ];
    if (enableBusinessMode) {
        result.splice(3, 0, {
            label: '20%',
            value: 20,
        });
    }
    return result;
};

function SubscriptionPricingFormGroup({ formik, isPriceChangeDisabled }) {
    const { t } = useTranslation();
    const {
        values,
        setFieldValue,
        errors,
        touched,
    } = formik;

    const onPriceWithVATChange = (e) => {
        const {
            pricing: {
                VAT: { value: vatValue },
            },
        } = values;

        setFieldValue('pricing.priceWithoutVAT', (e.target.value / (1 + vatValue / 100)).toFixed(2));
        setFieldValue('pricing.priceWithVAT', e.target.value);
    };

    const onVATChange = (selected) => {
        const {
            pricing: { priceWithVAT },
        } = values;

        setFieldValue(
            'pricing.VAT',
            selected,
        );

        if (typeof parseFloat(priceWithVAT) === 'number' && !isNaN(parseFloat(priceWithVAT))) {
            setFieldValue('pricing.priceWithoutVAT', (priceWithVAT / (1 + selected.value / 100)).toFixed(2));
        }
    };

    const validationHandler = instantFormValidation(formik);

    const { enableBusinessMode } = useSelector(companyBuisnessModeSelector);

    return (
        <div className="d-flex flex-column flex-lg-row justify-content-between px-4 px-lg-0 py-4">
            <Form.Group
                as={Col}
                controlId="subscriptionPriceWithVAT"
                className="px-2 pl-lg-0 pr-lg-3"
            >
                <Form.Label className="font-size-15">
                    {t('subscriptions.pricingFormGroup.priceWithVAT')}
                </Form.Label>
                <Tooltip
                    isDisabled={!isPriceChangeDisabled}
                    tooltip={t('subscriptions.pricingFormGroup.priceChangeDisabled')}
                    forButton
                >
                    <NumberInputWithControls
                        customOnChange={validationHandler(onPriceWithVATChange)}
                        value={values.pricing.priceWithVAT}
                        fieldValueKey="pricing.priceWithVAT"
                        isInvalid={isInvalid('pricing.priceWithVAT', errors, touched)}
                        error={getError('pricing.priceWithVAT', errors)}
                        disabled={isPriceChangeDisabled}
                    />
                </Tooltip>
            </Form.Group>
            <Form.Group
                as={Col}
                controlId="subscriptionVAT"
                className="px-2 px-lg-3"
            >
                <Form.Label className="font-size-15">
                    {t('subscriptions.pricingFormGroup.VAT')}
                </Form.Label>
                <Tooltip
                    isDisabled={!isPriceChangeDisabled}
                    tooltip={t('subscriptions.pricingFormGroup.priceChangeDisabled')}
                    forButton
                >
                    <Select
                        styles={selectStyles}
                        theme={selectTheme}
                        name="pricing.VAT"
                        value={values.pricing.VAT}
                        options={getVATRateSelectOptions(enableBusinessMode)}
                        onChange={onVATChange}
                        placeholder={t('placeholders.select')}
                        isDisabled={isPriceChangeDisabled}
                    />
                </Tooltip>
            </Form.Group>
            <Form.Group
                as={Col}
                controlId="subscriptionPriceWithoutVAT"
                className="px-2 pr-lg-0 pl-lg-3"
            >
                <Form.Label className="font-size-15">
                    {t('subscriptions.pricingFormGroup.priceWithoutVAT')}
                </Form.Label>
                <Form.Control
                    disabled
                    value={values.pricing.priceWithoutVAT}
                />
            </Form.Group>
        </div>
    );
}

export default SubscriptionPricingFormGroup;
