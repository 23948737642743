import React from 'react';
import PropTypes from 'prop-types';

const Calendar2 = (props) => {
    const { className } = props;

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M17 6.86676H1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="1"
                y="2.6001"
                width="16"
                height="13.8667"
                rx="3"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.73334 1V4.2"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.2667 1V4.2"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    );
};

Calendar2.propTypes = {
    className: PropTypes.string,
};

Calendar2.defaultProps = {
    className: '',
};

export default Calendar2;
