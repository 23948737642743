import React from 'react';
import { useTranslation } from 'react-i18next';
import { Jumbotron } from 'react-bootstrap';
import * as styles from './ComingSoon.module.scss';

function ComingSoon() {
    const { t } = useTranslation();
    return (
        <Jumbotron className={styles.flex}>
            <h1>{t('comingSoon')}</h1>
        </Jumbotron>
    );
}

export default ComingSoon;
