import React, {
    useState, useEffect, useRef, useCallback, startTransition,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronUp, ChevronDown } from 'react-feather';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';
import momentAmsterdamTime from 'helpers/time/momentAmsterdamTime';

import * as styles from './HourInput.module.scss';

const HourInput = (props) => {
    const {
        className,
        value,
        min,
        max,
        format = 'HH:mm',
        timespan = 15,
        placeholder,
        onChange,
        isInvalid,
        allowAllHours = false,
        isDisabled = false,
        cycleTimeThroughSameDate,
        iconComponent: IconComponent,
    } = props;

    const [isVisible, setVisible] = useState(false);
    const [areHoursVisible, setHoursVisible] = useState(false);
    const [areMinutesVisible, setMinutesVisible] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({
        x: 'right',
        y: 'left',
    });

    const buttonRef = useRef(null);
    const ref = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (!isVisible) {
            return;
        }

        const handleClick = ({ target }) => {
            if (!ref.current
                || ref.current.contains(target)
                || target.id === 'hourDropdownButton'
                || target.id === 'minutesDropdownButton'
                || target.getAttribute('data-button-type') === 'hourButton'
                || target.getAttribute('data-button-type') === 'minuteButton'
            ) {
                return;
            }

            setVisible(false);
            setHoursVisible(false);
            setMinutesVisible(false);
        };

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, [ref, isVisible]);

    useEffect(() => {
        if (
            !onChange
            || !value
            || (
                value.minutes() % timespan === 0
                && value.seconds() === 0
                && value.milliseconds() === 0
            )
        ) {
            return;
        }

        onChange(value.clone().minutes(Math.round(value.minutes() / timespan) * timespan).seconds(0).milliseconds(0));
    }, [value, onChange]);

    useEffect(() => {
        const rect = buttonRef.current.getBoundingClientRect();
        setDropdownPosition({
            x: rect.x + 335 >= window.outerWidth ? 'left' : 'right',
            y: rect.y >= rect.height + 100 ? 'top' : 'bottom',
        });
    }, [ref, isVisible]);

    const displayValue = (value || momentAmsterdamTime())
        .clone()
        .minutes(Math.round((value || moment()).minutes() / timespan) * timespan);

    const handleTimeChange = useCallback(({ count, unit }) => {
        let newTime = displayValue.clone().add(count, unit);
        if (cycleTimeThroughSameDate && !newTime.isSame(displayValue, 'day')) {
            const diff = displayValue
                .clone()
                .startOf('day')
                .diff(
                    newTime
                        .clone()
                        .startOf('day'),
                    'days',
                );
            newTime = newTime.clone().add(diff, 'day');
        }
        onChange(newTime);
    }, [
        displayValue,
        cycleTimeThroughSameDate,
        onChange,
    ]);

    const handleHourIncrease = useCallback(() => {
        handleTimeChange({ count: 1, unit: 'hour' });
    }, [handleTimeChange]);

    const handleHourDecrease = useCallback(() => {
        handleTimeChange({ count: -1, unit: 'hour' });
    }, [handleTimeChange]);

    const handleMinuteIncrease = useCallback(() => {
        handleTimeChange({ count: timespan, unit: 'minutes' });
    }, [handleTimeChange, timespan]);

    const handleMinuteDecrease = useCallback(() => {
        handleTimeChange({ count: -timespan, unit: 'minutes' });
    }, [handleTimeChange, timespan]);

    let selectContent;
    if (areHoursVisible) {
        selectContent = (
            <div className="d-flex flex-row flex-wrap justify-content-center p-3">
                {_.times(24, (i) => (
                    <button
                        key={`hour-${i}`}
                        type="button"
                        data-button-type="hourButton"
                        className={classNames(styles.valueButton, 'border border-darker-light text-darker-gray rounded m-1')}
                        onClick={() => {
                            setHoursVisible(false);
                            onChange(displayValue.clone().hour(i));
                        }}
                        disabled={allowAllHours
                            ? false
                            : (!!max && moment(moment().hour(i).minute(0).format('HH:mm'), 'HH:mm').isAfter(moment(max, 'HH:mm')))
                            || (!!min && moment(displayValue.clone().hour(i).minute(0).format('HH:mm'), 'HH:mm').isBefore(moment(min, 'HH:mm')))}
                    >
                        {moment().hour(i).format('HH')}
                    </button>
                ))}
            </div>
        );
    } else if (areMinutesVisible) {
        selectContent = (
            <div className="d-flex flex-row flex-wrap justify-content-center p-3">
                {_.times(Math.round(60 / timespan), (i) => (
                    <button
                        key={`minute-${i}`}
                        type="button"
                        data-button-type="minuteButton"
                        className={classNames(styles.valueButton, 'border border-darker-light text-darker-gray rounded m-1')}
                        onClick={() => {
                            setMinutesVisible(false);
                            onChange(displayValue.clone().minutes(i * timespan));
                        }}
                        disabled={allowAllHours
                            ? false
                            : (!!max && moment(displayValue.clone().minutes(i * timespan).format('HH:mm'), 'HH:mm').isAfter(moment(max, 'HH:mm')))
                            || (!!min && moment(displayValue.clone().minutes(i * timespan).format('HH:mm'), 'HH:mm').isBefore(moment(min, 'HH:mm')))}
                    >
                        {moment().minutes(i * timespan).format('mm')}
                    </button>
                ))}
            </div>
        );
    } else {
        selectContent = (
            <div className="d-flex flex-row align-items-center">
                <div className={classNames(styles.section, 'd-flex flex-column flex-grow-1')}>
                    <button
                        data-testid="increment-hour"
                        type="button"
                        className={classNames(styles.chevronButton, 'border-0 bg-transparent mb-2')}
                        onClick={handleHourIncrease}
                        disabled={(
                            !allowAllHours
                            && (
                                (max && moment(displayValue.clone().add(1, 'hour').format('HH:mm'), 'HH:mm').isAfter(moment(max, 'HH:mm')))
                                || (min && max && moment(displayValue.clone().add(1, 'hour').format('HH:mm'), 'HH:mm').isBefore(moment(min, 'HH:mm')))
                            )
                        )}
                    >
                        <ChevronUp size={28} />
                    </button>

                    <button
                        id="hourDropdownButton"
                        data-testid="hour-dropdown"
                        type="button"
                        className={classNames(styles.value, 'border-0 bg-transparent font-weight-bold')}
                        onClick={() => setHoursVisible(true)}
                    >
                        {displayValue.format('HH')}
                    </button>

                    <button
                        data-testid="decrement-hour"
                        type="button"
                        className={classNames(styles.chevronButton, 'border-0 bg-transparent mt-2')}
                        onClick={handleHourDecrease}
                        disabled={(
                            !allowAllHours
                            && (
                                (min && moment(displayValue.clone().subtract(1, 'hour').format('HH:mm'), 'HH:mm').isBefore(moment(min, 'HH:mm')))
                                || (min && max && moment(displayValue.clone().subtract(1, 'hour').format('HH:mm'), 'HH:mm').isAfter(moment(max, 'HH:mm')))
                            )
                        )}
                    >
                        <ChevronDown size={28} />
                    </button>
                </div>

                <span className={classNames(styles.colon, 'flex-grow-0 font-weight-bold')}>:</span>

                <div className={classNames(styles.section, 'd-flex flex-column flex-grow-1')}>
                    <button
                        data-testid="increment-minutes"
                        type="button"
                        className={classNames(styles.chevronButton, 'border-0 bg-transparent mb-2')}
                        onClick={handleMinuteIncrease}
                        disabled={(
                            !allowAllHours
                            && (
                                (max && moment(displayValue.clone().add(timespan, 'minute').format('HH:mm'), 'HH:mm').isAfter(moment(max, 'HH:mm')))
                                || (min && max && moment(displayValue.clone().add(timespan, 'minute').format('HH:mm'), 'HH:mm').isBefore(moment(min, 'HH:mm')))
                            )
                        )}
                    >
                        <ChevronUp size={28} />
                    </button>

                    <button
                        id="minutesDropdownButton"
                        data-testid="minutes-dropdown"
                        type="button"
                        className={classNames(styles.value, 'border-0 bg-transparent font-weight-bold')}
                        onClick={() => setMinutesVisible(true)}
                    >
                        {displayValue.format('mm')}
                    </button>

                    <button
                        data-testid="decrement-minutes"
                        type="button"
                        className={classNames(styles.chevronButton, 'border-0 bg-transparent mt-2')}
                        onClick={handleMinuteDecrease}
                        disabled={(
                            !allowAllHours
                            && (
                                (max && moment(displayValue.clone().subtract(timespan, 'minute').format('HH:mm'), 'HH:mm').isBefore(moment(min, 'HH:mm')))
                                || (min && max && moment(displayValue.clone().subtract(timespan, 'minute').format('HH:mm'), 'HH:mm').isAfter(moment(max, 'HH:mm')))
                            )
                        )}
                    >
                        <ChevronDown size={28} />
                    </button>
                </div>
            </div>
        );
    }

    const isDisabledHandler = () => {
        if (isDisabled) {
            return true;
        }

        return allowAllHours ? false : (!max && !min);
    };

    return (
        <div className={styles.wrapper}>
            <button
                ref={buttonRef}
                type="button"
                className={classNames(
                    styles.button,
                    className,
                    'form-control text-left',
                    {
                        'is-invalid': isInvalid,
                        [styles.withIcon]: Boolean(IconComponent),
                    },
                )}
                onClick={() => startTransition(() => setVisible(() => (prev) => !prev))}
                disabled={isDisabledHandler()}
            >

                <span
                    className={classNames(
                        'text-nowrap',
                        {
                            'text-muted': !value || !allowAllHours || !(max && min),
                        },
                    )}
                >
                    {allowAllHours || (max && min)
                        ? value ? value.clone().format(format) : placeholder
                        : t('timetable.shopClosedShort')}
                </span>

                {IconComponent && !isInvalid && (
                    <IconComponent className={classNames(styles.icon, { 'is-invalid': isInvalid })} />
                )}
            </button>

            {isVisible && (
                <div
                    ref={ref}
                    className={classNames(
                        styles.select,
                        styles[dropdownPosition.x],
                        styles[dropdownPosition.y],
                        'bg-white',
                    )}
                >
                    {selectContent}
                </div>
            )}
        </div>
    );
};

export default HourInput;
