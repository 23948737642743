import React, {
    useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isInvalid } from 'services/validationService';
import CreatableSelect from 'react-select/creatable';
import { WindowedMenuList } from 'react-windowed-select';
import { components } from 'react-select';

import {
    styles, dangerTheme, theme,
} from 'styles/select';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { generateRandomString } from 'helpers/string/generateRandomString';

export const categoryInitialData = () => ({
    name: '',
    id: `new-${generateRandomString()}`,
});

const MAX_CATEGORIE_NAME = 50;

function CategorySelect({
    category,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    categories,
}) {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const resetFieldsError = useCallback(() => {
        setFieldTouched('category.name', false, false);
    }, []);

    const oncategorieCreateOption = useCallback((name) => {
        resetFieldsError();
        const categorieInitial = categoryInitialData();
        setFieldValue('category.id', categorieInitial.id);
        setFieldValue('category.name', name.trim());
    }, [category]);

    const oncategorieChange = useCallback((selected) => {
        resetFieldsError();
        if (!selected) {
            const categorieInitial = categoryInitialData();
            setFieldValue('category.id', categorieInitial.id);
            setFieldValue('category.name', category.name ?? categorieInitial.name);
            return;
        }
        const { id, name } = selected;
        setFieldValue('category.id', id);
        setFieldValue('category.name', name);
    }, [category]);

    const getcategorieFormatOptionLabel = useCallback((
        { label },
    ) => (
        <span
            className="flex-grow-1"
            style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }}
        >
            {label}
        </span>
    ), []);

    const getName = useCallback((name) => {
        const key = `notes.category.default.${name.replaceAll(' ', '_')}`;
        const translation = t(key);
        if (translation.includes(key)) {
            return name;
        }
        return translation;
    }, [t]);

    const categorieNameValue = useMemo(() => (
        category.name ? {
            value: category.id,
            label: getName(category.name),
        } : null
    ), [
        category,
        getName,
    ]);

    const options = useMemo(() => categories.map((category) => ({
        ...category,
        value: category.id,
        label: getName(category.name),
    })), [categories, getName]);

    const filterOption = useCallback(
        (category, query) => !query || category.label.toLowerCase().includes(query.toLowerCase()),
        [],
    );

    return (
        <CreatableSelect
            id="name"
            isSearchable
            name="category.name"
            styles={styles}
            theme={isInvalid('category.name', errors, touched) ? dangerTheme : theme}
            value={categorieNameValue}
            onCreateOption={oncategorieCreateOption}
            onChange={oncategorieChange}
            formatOptionLabel={getcategorieFormatOptionLabel}
            isLoading={!options.length}
            options={options}
            filterOption={filterOption}
            formatCreateLabel={(inputValue) => t('notes.category.create.label', { name: inputValue })}
            components={{
                // eslint-disable-next-line react/no-unstable-nested-components
                Input: (props) => (
                    <components.Input {...props} maxLength={MAX_CATEGORIE_NAME} />
                ),
                MenuList: WindowedMenuList,
            }}
            placeholder={t('notes.category.choose.label')}
        />
    );
}

export default CategorySelect;
