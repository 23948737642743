import React from 'react';

const Newspaper = (
    {
        fill = 'currentColor',
        secondaryFill = fill,
        strokeWidth = 2,
        width = '100%',
        // height = '100%',
        className,
        // style,
    },
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="none"
        width={width}
        viewBox="0 0 24 24"
        stroke={secondaryFill}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
            d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
        />
    </svg>
);

export default Newspaper;
