import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
    Button, Modal,
} from 'react-bootstrap';

import * as styles from './AddNewLocationDialog.module.scss';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'locations.addNewLocationDialog';

const E_MAIL = 'info@appybee.nl';

const AddNewLocationDialog = (props) => {
    const { isVisible, onClose } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);

    return (
        <Modal
            centered
            size="xs"
            show={isVisible}
        >
            <Modal.Header
                closeButton
                onHide={onClose}
                className={styles.header}
            >
                {t(`${T_PREFIX}.header`)}
            </Modal.Header>

            <Modal.Body className={styles.body}>
                <Trans
                    ns={LOCALE_NAMESPACE.SETTINGS}
                    i18nKey={`${T_PREFIX}.text`}
                    components={[
                        <a
                            href={`mailto:${E_MAIL}`}
                            className="text-primary"
                        />,
                    ]}
                    values={{ email: E_MAIL }}
                />
            </Modal.Body>

            <Modal.Footer className={styles.footer}>
                <Button
                    className={styles.actionButton}
                    onClick={onClose}
                >
                    {t(`${T_PREFIX}.actions.ok.label`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddNewLocationDialog;
