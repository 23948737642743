import React from 'react';

// Styles
import classNames from 'classnames';
import * as styles from './Switch.module.scss';

const Switch = ({
    checked, onChange, disabled = false, className, id,
}) => (
    <button
        type="button"
        className={classNames(styles.container, { [styles.checked]: checked }, className)}
        onClick={(e) => onChange(!checked, e)}
        disabled={disabled}
        id={id}
    >
        <div className={styles.knob} />
    </button>
);

export default Switch;
