export const geocode = (options) => {
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode(options, (result, status) => {
            if (status === 'OK' || status === 'ZERO_RESULTS') {
                resolve({ result, status });
            } else {
                reject({ result, status });
            }
        });
    });
};
