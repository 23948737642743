import ProfilesList from './profilesList';
import ProfileAdd from './profileAdd';

import {
    ACCESS_PROFILES_PAGE,
    ACCESS_PROFILE_ADD_PAGE,
    ACCESS_PROFILE_EDIT_PAGE,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: ProfileAdd,
        exact: true,
        path: ACCESS_PROFILE_ADD_PAGE,
    },
    {
        component: ProfileAdd,
        exact: true,
        path: ACCESS_PROFILE_EDIT_PAGE,
    },
    {
        component: ProfilesList,
        exact: true,
        path: ACCESS_PROFILES_PAGE,
    },
];

export default routes;
