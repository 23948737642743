import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon2 = (props) => {
    const { className } = props;

    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect
                x="0.5"
                y="0.5"
                width="18"
                height="18"
                rx="3.5"
                fill="white"
                stroke="#D8E1EA"
            />
            <rect
                x="14"
                y="9"
                width="1"
                height="9"
                transform="rotate(90 14 9)"
                fill="currentColor"
            />
            <rect
                x="9"
                y="5"
                width="1"
                height="9"
                fill="currentColor"
            />
        </svg>
    );
};

PlusIcon2.propTypes = {
    className: PropTypes.string,
};

PlusIcon2.defaultProps = {
    className: '',
};

export default PlusIcon2;
