export const GET_OBJECTS = 'GET_OBJECTS';
export const GET_OBJECTS_SUCCEEDED = 'GET_OBJECTS_SUCCEEDED';
export const GET_OBJECTS_FAILED = 'GET_OBJECTS_FAILED';

export const SET_OBJECT_FILTER = 'SET_OBJECT_FILTER';

export const ADD_OBJECT = 'ADD_OBJECT';
export const ADD_OBJECT_SUCCEEDED = 'ADD_OBJECT_SUCCEEDED';
export const ADD_OBJECT_FAILED = 'ADD_OBJECT_FAILED';

export const EDIT_OBJECT = 'EDIT_OBJECT';
export const EDIT_OBJECT_SUCCEEDED = 'EDIT_OBJECT_SUCCEEDED';
export const EDIT_OBJECT_FAILED = 'EDIT_OBJECT_FAILED';

export const DELETE_OBJECT = 'DELETE_OBJECT';
export const DELETE_OBJECT_SUCCEEDED = 'DELETE_OBJECT_SUCCEEDED';
export const DELETE_OBJECT_FAILED = 'DELETE_OBJECT_FAILED';
