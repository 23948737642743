import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Modal } from 'react-bootstrap';

import TextButton from 'components/Common/TextButton/TextButton';
import { Button } from 'components/Common/Button';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';
import { useDialog } from '../../../../hooks/useDialog';
import { useBool } from '../../../../hooks/useBool';

import MessageDeleteDialog from '../MessageDeleteDialog/MessageDeleteDialog';

import { Trash3Icon } from '../../../Icon/Icon';

import * as styles from './MessageDetailsDialog.module.scss';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import MESSAGE_STATUS from '../../../../const/marketing/MESSAGE_STATUS';
import { MESSAGES_EDIT_ID_PAGE } from '../../../../const/CLIENT_URL';

const T_PREFIX = 'list.dialog';
const T_BODY = `${T_PREFIX}.body`;
const T_ACTIONS = `${T_PREFIX}.actions`;

const MIN_CLIENT_SHOW = 3;

const MessageDetailsDialog = (props) => {
    const { message, onClose } = props;
    const {
        id,
        name,
        beginDate,
        endDate,
        indefinite,
        status,
        description,
        messageToAllClients,
        clients = [],
        photoUrl,
        videoUrl,
    } = message ?? {};

    const { t } = useTranslation(LOCALE_NAMESPACE.MESSAGES);

    const history = useHistory();

    const showAll = useBool(false);
    const sentToOtherClientsDialog = useDialog(false);
    const deleteMessageDialog = useDialog(false);

    const isShow = Boolean(
        message
        && !sentToOtherClientsDialog.visible
        && !deleteMessageDialog.visible,
    );

    const period = useMemo(() => {
        if (indefinite) {
            return t(`${T_BODY}.period.indefinite`);
        }

        const from = moment.unix(beginDate).format('DD.MM.YYYY');
        const to = moment.unix(endDate).format('DD.MM.YYYY');

        return `${from} - ${to}`;
    }, [
        indefinite,
        beginDate,
        endDate,
        t,
    ]);

    const statusClasses = useMemo(() => ({
        [styles.greenColor]: status === MESSAGE_STATUS.ACTIVE,
        [styles.grayColor]: status === MESSAGE_STATUS.ENDED,
    }), [status]);

    const showedClients = useMemo(() => {
        if (showAll.value) {
            return clients;
        }

        return clients.slice(0, MIN_CLIENT_SHOW);
    });

    const onDeleteClick = useCallback(() => {
        deleteMessageDialog.onSetShow(message);
    }, [message]);

    const onEditClick = useCallback(() => {
        if (!id) {
            return;
        }

        history.push(MESSAGES_EDIT_ID_PAGE({ messageId: id }));
    }, [history.push]);

    const onCloseDeleteDialog = useCallback((result) => {
        if (result) {
            onClose();
        }

        deleteMessageDialog.onClose();
    }, [onClose]);

    return (
        <>
            <Modal
                centered
                size="xl"
                show={isShow}
                onHide={onClose}
            >
                <Modal.Header
                    closeButton
                    onHide={onClose}
                >
                    <Modal.Title>
                        {t(`${T_PREFIX}.header`)}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className={styles.body}>
                    {Boolean(photoUrl) && (
                        <div className={styles.imageColumn}>
                            <img
                                src={photoUrl}
                                className={styles.image}
                            />
                        </div>
                    )}

                    <div className={styles.contentColumn}>
                        <div className={styles.title}>
                            {name}
                        </div>

                        <div className={styles.info}>
                            <div className={styles.infoItem}>
                                <span className={styles.infoItemTitle}>
                                    {t(`${T_BODY}.period.label`)}
                                    :
                                </span>

                                {period}
                            </div>

                            <div className={styles.infoItem}>
                                <span className={styles.infoItemTitle}>
                                    {t(`${T_BODY}.status.label`)}
                                    :
                                </span>

                                <span className={classNames(statusClasses)}>
                                    {t(`${T_BODY}.status.${status}`)}
                                </span>
                            </div>

                            <div className={classNames(styles.infoItem, 'w-100')}>
                                <span className={styles.infoItemTitle}>
                                    {t(`${T_BODY}.sentTo.label`)}
                                    :
                                </span>

                                {messageToAllClients
                                    ? t(`${T_BODY}.sentTo.toAllClients`)
                                    : (
                                        <div className={styles.infoItemUserListWrapper}>
                                            <div
                                                className={classNames(
                                                    styles.infoItemUserList,
                                                    { [styles.infoItemUserListWithScroll]: showAll.value },
                                                )}
                                            >
                                                {showedClients.map((client) => (
                                                    <div key={client.id}>
                                                        {client.fullName}
                                                        {' · '}
                                                        <span className={styles.grayColor}>
                                                            {client.email}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>

                                            {clients.length > MIN_CLIENT_SHOW && (
                                                <TextButton
                                                    color="yellow"
                                                    onClick={showAll.onToggle}
                                                    noPadding
                                                >
                                                    {showAll.value
                                                        ? t(`${T_BODY}.sentTo.hide`)
                                                        : t(`${T_BODY}.sentTo.showAll`)}
                                                </TextButton>
                                            )}
                                        </div>
                                    )}
                            </div>

                            <div className={classNames(styles.infoItem, 'w-100')}>
                                <span className={styles.infoItemTitle}>
                                    {t(`${T_BODY}.videoUrl.label`)}
                                    :
                                </span>

                                {!videoUrl
                                    ? '-'
                                    : (
                                        <a
                                            href={videoUrl}
                                            target="__blank"
                                            className={styles.infoItemVideoUrl}
                                        >
                                            {videoUrl}
                                        </a>
                                    )}
                            </div>
                        </div>

                        <div className={styles.description}>
                            {description}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className={styles.footer}>
                    <Button
                        color="outline"
                        onClick={onClose}
                    >
                        {t(`${T_ACTIONS}.back`)}
                    </Button>

                    <Row gap={12}>
                        <IconButton
                            size={40}
                            color="outline"
                            onClick={onDeleteClick}
                        >
                            <Trash3Icon />
                        </IconButton>

                        {status === MESSAGE_STATUS.PENDING && (
                            <Button
                                color="yellow"
                                onClick={onEditClick}
                            >
                                {t(`${T_ACTIONS}.edit`)}
                            </Button>
                        )}

                        {false && status === MESSAGE_STATUS.ENDED && (
                            <Button
                                color="yellow"
                                onClick={onEditClick}
                            >
                                {t(`${T_ACTIONS}.sentToOtherClients`)}
                            </Button>
                        )}
                    </Row>

                </Modal.Footer>
            </Modal>

            <MessageDeleteDialog
                message={deleteMessageDialog.visible}
                onClose={onCloseDeleteDialog}
            />
        </>
    );
};

export default MessageDetailsDialog;
