import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chip } from 'components/Common/Chip';
import IconButton from 'components/Common/IconButton/IconButton';
import moment from 'moment';
import { CopyIcon, Pen2Icon, Trash3Icon } from 'components/Icon/Icon';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as CLIENTS_ACTIONS from 'store/actions/clients/tables/notes';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'components/Common/Tooltip';
import { Row } from 'components/Common/Row';
import { ClientNoteAuthor } from '../ClientNoteAuthor';
import * as styles from './ClientNoteItem.module.scss';
import ClientNoteFormDialog from '../ClientNoteFormDialog/ClientNoteFormDialog';

const ClientNoteItem = (props) => {
    const { note } = props;
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { t: tc } = useTranslation();
    const dispatch = useDispatch();

    const editDialog = useConfirmDialog({
        defaultState: false,
    });

    const archiveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: useCallback(() => {
            dispatch(CLIENTS_ACTIONS.deleteClientNotesItem({ noteId: note.id }));
        }, [dispatch]),
    });

    const handleCloneNote = useCallback(() => {
        const formData = new FormData();
        formData.append('category', note.clientNoteCategory.id);
        formData.append('text', note.text);
        dispatch(CLIENTS_ACTIONS.addClientNotesItem({ note: formData }));
    }, []);

    const categoryName = useMemo(() => {
        const key = `notes.category.default.${note.clientNoteCategory.name.replaceAll(' ', '_')}`;
        const translation = t(key);
        if (translation.includes(key)) {
            return note.clientNoteCategory.name;
        }
        return translation;
    }, [t, note.clientNoteCategory.name]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Chip color="gray">
                    {categoryName}
                </Chip>
                <Row gap={8}>
                    <Tooltip forButton tooltip={tc('placeholders.edit')} placement="bottom">
                        <IconButton
                            onClick={editDialog.onShow}
                            color="gray"
                            size={32}
                        >
                            <Pen2Icon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip forButton tooltip={tc('placeholders.duplicate')} placement="bottom">
                        <IconButton
                            onClick={handleCloneNote}
                            color="gray"
                            size={32}
                        >
                            <CopyIcon height={24} width={24} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip forButton tooltip={tc('placeholders.delete')} placement="bottom">
                        <IconButton onClick={archiveConfirmDialog.onShow} color="gray">
                            <Trash3Icon />
                        </IconButton>
                    </Tooltip>
                </Row>
            </div>
            <div className={styles.content}>
                <div className={styles.author}>
                    <ClientNoteAuthor avatarUrl={note.author.avatarUrl} name={note.author.name} date={moment.unix(note.createdAt).format('DD.MM.YYYY HH:mm')} />
                    {note.editor && <ClientNoteAuthor avatarUrl={note.editor.avatarUrl} name={note.editor.name} date={moment.unix(note.editedAt).format('DD.MM.YYYY HH:mm')} editor /> }
                </div>
                <div className={styles.text}>
                    {note.text}
                </div>
            </div>
            {archiveConfirmDialog.visible && (
                <ConfirmationModal
                    isShow={archiveConfirmDialog.visible}
                    titleText={t('notes.note.delete.title')}
                    bodyText={t('notes.note.delete.text', { name: note.author.name })}
                    deleteText={t('notes.note.delete.actions.confirm')}
                    dismissText={t('notes.note.delete.actions.reject')}
                    confirmAction={archiveConfirmDialog.onConfirm}
                    hide={archiveConfirmDialog.onReject}
                />
            )}

            {editDialog.visible && (
                <ClientNoteFormDialog
                    categories={props.categories}
                    visible={editDialog.visible}
                    note={note}
                    onClose={editDialog.onClose}
                    saving={props.saving}
                />
            )}
        </div>
    );
};

ClientNoteItem.propTypes = {
    note: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    saving: PropTypes.bool,
};

export default ClientNoteItem;
