const PREFIX = '/api';

const API_ADMIN_ROUTE = `${PREFIX}/admin`;
const API_ADMIN_CLIENT_ROUTE = `${API_ADMIN_ROUTE}/client`;
const API_ADMIN_COMPANY_ROUTE = `${API_ADMIN_ROUTE}/company`;
const API_SUBSCRIPTION_ROUTE = `${PREFIX}/subscription`;

export const API_ADMIN_LOGIN_ROUTE = `${API_ADMIN_ROUTE}/login`;
export const API_ADMIN_RESET_PASSWORD_ROUTE = `${API_ADMIN_ROUTE}/reset-password`;
export const API_ADMIN_REFRESH_TOKEN_ROUTE = `${API_ADMIN_ROUTE}/refresh-token`;
export const API_ADMIN_LOGOUT_ROUTE = `${API_ADMIN_ROUTE}/logout`;
export const API_ADMIN_SET_PASSWORD_ROUTE = `${API_ADMIN_ROUTE}/set-password`;
const API_ADMIN_BUNDLE_ROUTE = `${API_ADMIN_ROUTE}/bundle`;
export const API_ADMIN_BUNDLE_CREATE_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/create`;
const API_ADMIN_BUNDLE_GET_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/get`;
export const API_ADMIN_BUNDLE_GET_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_GET_ROUTE}/${bundleId}`;
const API_ADMIN_BUNDLE_EDIT_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/edit`;
export const API_ADMIN_BUNDLE_EDIT_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_EDIT_ROUTE}/${bundleId}`;
const API_ADMIN_BUNDLE_SELL_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/sell`;
export const API_ADMIN_BUNDLE_SELL_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_SELL_ROUTE}/${bundleId}`;
const API_ADMIN_BUNDLE_CHECK_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/check`;
export const API_ADMIN_BUNDLE_CHECK_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_CHECK_ROUTE}/${bundleId}`;
const API_ADMIN_BUNDLE_ARCHIVE_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/archive`;
export const API_ADMIN_BUNDLE_ARCHIVE_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_ARCHIVE_ROUTE}/${bundleId}`;
const API_ADMIN_BUNDLE_TOGGLE_STATUS_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/toggle-status`;
export const API_ADMIN_BUNDLE_TOGGLE_STATUS_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_TOGGLE_STATUS_ROUTE}/${bundleId}`;
export const API_ADMIN_BUNDLE_LIST_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/list`;
export const API_ADMIN_BUNDLE_SOLD_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/sold-list`;
const API_ADMIN_BUNDLE_SOLD_EDIT_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/sold/edit`;
const API_ADMIN_BUNDLE_PAYMENT_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/payment`;
const API_ADMIN_BUNDLE_PAYMENT_TOGGLE_STATUS_ROUTE = `${API_ADMIN_BUNDLE_PAYMENT_ROUTE}/toggle-status`;
export const API_ADMIN_BUNDLE_PAYMENT_TOGGLE_STATUS_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_PAYMENT_TOGGLE_STATUS_ROUTE}/${bundleId}`;
export const API_ADMIN_BUNDLE_SOLD_EDIT_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_SOLD_EDIT_ROUTE}/${bundleId}`;
const API_ADMIN_BUNDLE_SOLD_ARCHIVE_ROUTE = `${API_ADMIN_BUNDLE_ROUTE}/sold/archive`;
export const API_ADMIN_BUNDLE_SOLD_ARCHIVE_ID_ROUTE = ({ bundleId }) => `${API_ADMIN_BUNDLE_SOLD_ARCHIVE_ROUTE}/${bundleId}`;

export const API_ADMIN_CLIENT_CREATE_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/create`;
const API_ADMIN_CLIENT_EDIT_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/edit`;
export const API_ADMIN_CLIENT_EDIT_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_EDIT_ROUTE}/${clientId}`;
const API_ADMIN_CLIENT_DELETE_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/delete`;
export const API_ADMIN_CLIENT_DELETE_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_DELETE_ROUTE}/${clientId}`;
const API_ADMIN_CLIENT_TOGGLE_BLOCK_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/toggle-block`;
export const API_ADMIN_CLIENT_TOGGLE_BLOCK_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_TOGGLE_BLOCK_ROUTE}/${clientId}`;
export const API_ADMIN_CLIENT_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_ROUTE}/${clientId}`;
export const API_ADMIN_CLIENT_APPLY_DISCOUNT_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/apply-discount`;
export const API_ADMIN_CLIENT_INVITATION_SEND_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/send-invitation`;
const API_ADMIN_CLIENT_BUNDLES_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/bundles`;
export const API_ADMIN_CLIENT_BUNDLES_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_BUNDLES_ROUTE}/${clientId}`;
const API_ADMIN_CLIENT_SUBSCRIPTIONS_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/subscriptions`;
export const API_ADMIN_CLIENT_SUBSCRIPTIONS_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_SUBSCRIPTIONS_ROUTE}/${clientId}`;
const API_ADMIN_CLIENT_BOOKINGS_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/bookings`;
export const API_ADMIN_CLIENT_BOOKINGS_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_BOOKINGS_ROUTE}/${clientId}`;
const API_ADMIN_CLIENT_UPDATE_IBAN_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/update-iban`;
export const API_ADMIN_CLIENT_UPDATE_IBAN_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_UPDATE_IBAN_ROUTE}/${clientId}`;
const API_ADMIN_CLIENT_SEND_UPDATE_IBAN_LINK_ROUTE = `${API_ADMIN_CLIENT_ROUTE}/send-update-iban-link`;
export const API_ADMIN_CLIENT_SEND_UPDATE_IBAN_LINK_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_SEND_UPDATE_IBAN_LINK_ROUTE}/${clientId}`;

const API_ADMIN_COMPANY_SERVICE_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/service`;
export const API_ADMIN_COMPANY_SERVICE_LIST_ROUTE = `${API_ADMIN_COMPANY_SERVICE_ROUTE}/list`;
export const API_ADMIN_COMPANY_SERVICES_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/services`;
const API_ADMIN_COMPANY_CLIENTS_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/client`;
export const API_ADMIN_COMPANY_CLIENTS_LIST_ROUTE = `${API_ADMIN_COMPANY_CLIENTS_ROUTE}/list`;
const API_ADMIN_COMPANY_INVOICE_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/invoice`;
export const API_ADMIN_COMPANY_INVOICE_LIST_ROUTE = `${API_ADMIN_COMPANY_INVOICE_ROUTE}/list`;
export const API_ADMIN_COMPANY_INVOICE_AMOUNT_ROUTE = `${API_ADMIN_COMPANY_INVOICE_ROUTE}/amount`;
export const API_ADMIN_COMPANY_INVOICE_EXPORT_ROUTE = `${API_ADMIN_COMPANY_INVOICE_ROUTE}/export`;
const API_ADMIN_COMPANY_INVOICE_ITEM_ROUTE = ({
    invoiceId,
}) => `${API_ADMIN_COMPANY_ROUTE}/invoice/${invoiceId}`;
export const API_ADMIN_COMPANY_INVOICE_ITEM_CHECK_CREDIT_ROUTE = ({
    invoiceId,
}) => `${API_ADMIN_COMPANY_INVOICE_ITEM_ROUTE({ invoiceId })}/check-credit-status`;
export const API_ADMIN_COMPANY_INVOICE_ITEM_CREATE_CREDIT_ROUTE = ({
    invoiceId,
}) => `${API_ADMIN_COMPANY_INVOICE_ITEM_ROUTE({ invoiceId })}/create-credit-invoice`;
export const API_ADMIN_COMPANY_INVOICE_ITEM_RESEND_ROUTE = ({
    invoiceId,
}) => `${API_ADMIN_COMPANY_INVOICE_ITEM_ROUTE({ invoiceId })}/resend`;
const API_ADMIN_COMPANY_CLEARINGS_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/clearings`;
export const API_ADMIN_COMPANY_CLEARINGS_LIST_ROUTE = `${API_ADMIN_COMPANY_CLEARINGS_ROUTE}/list`;
const API_ADMIN_COMPANY_MESSAGE_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/message`;
const API_ADMIN_COMPANY_MONEYBIRD_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/moneybird`;
export const API_ADMIN_COMPANY_MONEYBIRD_CLEARING_AVAILABILITY = `${API_ADMIN_COMPANY_MONEYBIRD_ROUTE}/clearing-availability`;
export const API_ADMIN_COMPANY_MONEYBIRD_MANUAL_CLEARING_ROUTE = `${API_ADMIN_COMPANY_MONEYBIRD_ROUTE}/manual-clearing`;
const API_ADMIN_COMPANY_PAYOUT_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/payout`;
export const API_ADMIN_COMPANY_DOWNLOAD_PAYOUT = ({ payoutId }) => `${API_ADMIN_COMPANY_PAYOUT_ROUTE}/download/${payoutId}`;
export const API_ADMIN_COMPANY_PAYOUT_LIST_ROUTE = `${API_ADMIN_COMPANY_PAYOUT_ROUTE}/list`;
export const API_ADMIN_COMPANY_PAYOUT_TRANSACTIONS_ROUTE = ({ payoutId }) => `${API_ADMIN_COMPANY_PAYOUT_ROUTE}/${payoutId}`;
const API_ADMIN_COMPANY_SETTINGS = `${API_ADMIN_COMPANY_ROUTE}/settings`;
export const API_ADMIN_COMPANY_SETTINGS_ROUTE = `${API_ADMIN_COMPANY_SETTINGS}/`;
export const API_ADMIN_COMPANY_SETTINGS_GENERAL_ROUTE = `${API_ADMIN_COMPANY_SETTINGS}/general`;
export const API_ADMIN_COMPANY_SETTINGS_SHOP_ROUTE = `${API_ADMIN_COMPANY_SETTINGS}/shop`;
export const API_ADMIN_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_ROUTE = `${API_ADMIN_COMPANY_SETTINGS}/display-and-notification`;
export const API_ADMIN_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_ROUTE = `${API_ADMIN_COMPANY_SETTINGS}/discount`;
const API_ADMIN_COMPANY_QR_CODE = `${API_ADMIN_COMPANY_ROUTE}/qr-codes`;
export const API_ADMIN_COMPANY_QR_CODE_ROUTE = `${API_ADMIN_COMPANY_QR_CODE}`;
export const API_ADMIN_COMPANY_CLIENT_CATEGORY_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/client-category`;

const API_ADMIN_SERVICE_ROUTE = `${API_ADMIN_ROUTE}/service`;
const API_ADMIN_SERVICE_GET_ROUTE = `${API_ADMIN_SERVICE_ROUTE}/get`;
export const API_ADMIN_SERVICE_GET_ID_ROUTE = ({ serviceId }) => `${API_ADMIN_SERVICE_GET_ROUTE}/${serviceId}`;
export const API_ADMIN_SERVICE_CREATE_ROUTE = `${API_ADMIN_SERVICE_ROUTE}/create`;
const API_ADMIN_SERVICE_EDIT_ROUTE = `${API_ADMIN_SERVICE_ROUTE}/edit`;
export const API_ADMIN_SERVICE_EDIT_ID_ROUTE = ({ serviceId }) => `${API_ADMIN_SERVICE_EDIT_ROUTE}/${serviceId}`;
const API_ADMIN_SERVICE_DELETE_ROUTE = `${API_ADMIN_SERVICE_ROUTE}/delete`;
export const API_ADMIN_SERVICE_DELETE_ID_ROUTE = ({ serviceId }) => `${API_ADMIN_SERVICE_DELETE_ROUTE}/${serviceId}`;
const API_ADMIN_SERVICE_TOGGLE_RANDOMLY_SELECT_ROUTE = `${API_ADMIN_SERVICE_ROUTE}/toggle-randomly-select`;
export const API_ADMIN_SERVICE_TOGGLE_RANDOMLY_SELECT_ID_ROUTE = ({
    serviceId,
}) => `${API_ADMIN_SERVICE_TOGGLE_RANDOMLY_SELECT_ROUTE}/${serviceId}`;
export const API_ADMIN_SERVICE_CHANGE_POSITION = `${API_ADMIN_SERVICE_ROUTE}/change-position`;
export const API_ADMIN_SERVICE_CHANGE_POSITION_ID_ROUTE = ({ serviceId }) => `${API_ADMIN_SERVICE_CHANGE_POSITION}/${serviceId}`;

const API_ADMIN_SUBSCRIPTION_ROUTE = `${API_ADMIN_ROUTE}/subscription`;
const API_ADMIN_SUBSCRIPTION_SELL_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/sell`;
export const API_ADMIN_SUBSCRIPTION_SELL_ID_ROUTE = ({ subscriptionId }) => `${API_ADMIN_SUBSCRIPTION_SELL_ROUTE}/${subscriptionId}`;
const API_ADMIN_SUBSCRIPTION_CHECK_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/check`;
export const API_ADMIN_SUBSCRIPTION_CHECK_ID_ROUTE = ({ subscriptionId }) => `${API_ADMIN_SUBSCRIPTION_CHECK_ROUTE}/${subscriptionId}`;
const API_ADMIN_SUBSCRIPTION_CHANGE_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/change`;
const API_ADMIN_SUBSCRIPTION_PAY_PRO_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/pay-pro`;
const API_ADMIN_SUBSCRIPTION_PAY_PRO_PAYMENT_LINK_ROUTE = `${API_ADMIN_SUBSCRIPTION_PAY_PRO_ROUTE}/payment-link`;
const API_ADMIN_SUBSCRIPTION_PAY_PRO_PAYMENT_LINK_RESEND_ROUTE = `${API_ADMIN_SUBSCRIPTION_PAY_PRO_PAYMENT_LINK_ROUTE}/resend`;
export const API_ADMIN_SUBSCRIPTION_LIST_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/list`;
export const API_ADMIN_SUBSCRIPTION_CHANGE_ID_ROUTE = ({ oldSubscriptionId }) => `${API_ADMIN_SUBSCRIPTION_CHANGE_ROUTE}/${oldSubscriptionId}`;
export const API_ADMIN_SUBSCRIPTION_PAY_PRO_PAYMENT_LINK_RESEND_ID_ROUTE = ({
    soldSubscriptionId,
}) => `${API_ADMIN_SUBSCRIPTION_PAY_PRO_PAYMENT_LINK_RESEND_ROUTE}/${soldSubscriptionId}`;
const API_ADMIN_SUBSCRIPTION_PAUSE_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/pause`;
export const API_ADMIN_SUBSCRIPTION_PAUSE_ID_ROUTE = ({ soldSubscriptionId }) => `${API_ADMIN_SUBSCRIPTION_PAUSE_ROUTE}/${soldSubscriptionId}`;
const API_ADMIN_SUBSCRIPTION_RESUME_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/resume`;
export const API_ADMIN_SUBSCRIPTION_RESUME_ID_ROUTE = ({
    soldSubscriptionId,
}) => `${API_ADMIN_SUBSCRIPTION_RESUME_ROUTE}/${soldSubscriptionId}`;
const API_ADMIN_SUBSCRIPTION_DELETE_ROUTE = `${API_ADMIN_SUBSCRIPTION_ROUTE}/sold-delete`;
export const API_ADMIN_SUBSCRIPTION_DELETE_ID_ROUTE = ({
    soldSubscriptionId,
}) => `${API_ADMIN_SUBSCRIPTION_DELETE_ROUTE}/${soldSubscriptionId}`;
const API_ADMIN_SUBSCRIPTION_ID_ROUTE = ({ soldSubscriptionId }) => `${API_ADMIN_SUBSCRIPTION_ROUTE}/${soldSubscriptionId}`;
export const API_ADMIN_SUBSCRIPTION_ID_BOOKINGS_ROUTE = ({
    soldSubscriptionId,
}) => `${API_ADMIN_SUBSCRIPTION_ID_ROUTE({ soldSubscriptionId })}/bookings`;

export const API_ADMIN_COMPANY_MESSAGE_CREATE_ROUTE = `${API_ADMIN_COMPANY_MESSAGE_ROUTE}/create`;
export const API_ADMIN_COMPANY_MESSAGE_GET_ID_ROUTE = ({ messageId }) => `${API_ADMIN_COMPANY_MESSAGE_ROUTE}/${messageId}`;
export const API_ADMIN_COMPANY_MESSAGE_EDIT_ID_ROUTE = ({ messageId }) => `${API_ADMIN_COMPANY_MESSAGE_ROUTE}/${messageId}/edit`;
export const API_ADMIN_COMPANY_MESSAGE_DELETE_ID_ROUTE = ({ messageId }) => `${API_ADMIN_COMPANY_MESSAGE_ROUTE}/${messageId}/delete`;

const API_ADMIN_SHOP_ROUTE = `${API_ADMIN_ROUTE}/shop`;
export const API_ADMIN_SHOP_BLOCK_EDIT_ROUTE = ({
    shopId,
    productId,
    blockId,
}) => `${API_ADMIN_SHOP_ROUTE}/${shopId}/${productId}/block/${blockId}/edit`;
export const API_ADMIN_SHOP_BLOCK_DELETE_ROUTE = ({
    shopId,
    productId,
    blockId,
}) => `${API_ADMIN_SHOP_ROUTE}/${shopId}/${productId}/block/${blockId}/delete`;

const API_ADMIN_PROMOTION_ROUTE = `${API_ADMIN_ROUTE}/promotion`;
export const API_ADMIN_PROMOTION_MESSAGES_ROUTE = `${API_ADMIN_PROMOTION_ROUTE}/messages`;
export const API_ADMIN_PROMOTION_MESSAGE_ROUTE = `${API_ADMIN_PROMOTION_ROUTE}/message`;
export const API_ADMIN_PROMOTION_MESSAGE_ID_ROUTE = ({ messageId }) => `${API_ADMIN_PROMOTION_MESSAGE_ROUTE}/${messageId}`;
export const API_ADMIN_PROMOTION_MESSAGE_ID_CLIENTS_ROUTE = ({ messageId }) => `${API_ADMIN_PROMOTION_MESSAGE_ID_ROUTE({ messageId })}/clients`;

export const API_ADMIN_REQEST_EXPORT_SOLD_SUBSCRIBTIONS = `${API_ADMIN_SUBSCRIPTION_ROUTE}/request-export-sold-subscription`;
export const API_ADMIN_REQEST_EXPORT_CLIENTS_LIST = `${API_ADMIN_CLIENT_ROUTE}/request-export-clients-list`;

export const API_SUBSCRIPTION_LIST_ROUTE = `${API_SUBSCRIPTION_ROUTE}/list`;

const API_ADMIN_USER_ROUTE = `${API_ADMIN_ROUTE}/user`;
export const API_ADMIN_USER_DETAILS_ROUTE = `${API_ADMIN_USER_ROUTE}/details`;
export const API_ADMIN_USER_SET_DEFAULT_TAB_ROUTE = `${API_ADMIN_USER_ROUTE}/set-default-tab`;
export const API_ADMIN_USER_TOGGLE_SOUND_NOTIFICATIONS_ROUTE = `${API_ADMIN_USER_ROUTE}/toggle-sound-notifications`;

const API_ADMIN_PRODUCTS = `${API_ADMIN_ROUTE}/products`;
export const API_ADMIN_PRODUCTS_ROUTE = `${API_ADMIN_PRODUCTS}/`;

const API_ADMIN_CUSTOM_INVOICES = `${API_ADMIN_ROUTE}/custom-invoices`;
export const API_ADMIN_CUSTOM_INVOICES_ROUTE = `${API_ADMIN_CUSTOM_INVOICES}/`;
export const API_ADMIN_CUSTOM_INVOICES_BILL_ROUTE = ({ bill }) => `${API_ADMIN_CUSTOM_INVOICES_ROUTE}${bill}`;

const API_ADMIN_CLIENT_NOTES = `${API_ADMIN_ROUTE}/notes`;
export const API_ADMIN_CLIENT_NOTES_CATEGORIES_ROUTE = `${API_ADMIN_CLIENT_NOTES}/categories`;
export const API_ADMIN_CLIENT_NOTES_CATEGORY_ROUTE = `${API_ADMIN_CLIENT_NOTES}/category`;
export const API_ADMIN_CLIENT_NOTES_CATEGORY_ID_ROUTE = ({ categoryId }) => `${API_ADMIN_CLIENT_NOTES_CATEGORY_ROUTE}/${categoryId}`;
export const API_ADMIN_CLIENT_NOTES_CLIENT_ID_ROUTE = ({ clientId }) => `${API_ADMIN_CLIENT_NOTES}/${clientId}`;
export const API_ADMIN_CLIENT_NOTES_CLIENT_NOTE_ID_ROUTE = ({ clientId, noteId }) => `${API_ADMIN_CLIENT_NOTES_CLIENT_ID_ROUTE({ clientId })}/${noteId}`;

export const API_ADMIN_COMPANY_INVOICE_TOGGLE_STATUS_ROUTE = ({ bill }) => `${API_ADMIN_COMPANY_INVOICE_ROUTE}/${bill}/toggle-status`;

const API_ADMIN_COMPANY_GATES_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/gates`;
export const API_ADMIN_COMPANY_GATES_LIST_ROUTE = `${API_ADMIN_COMPANY_GATES_ROUTE}/`;
export const API_ADMIN_COMPANY_GATES_LOGS_ROUTE = `${API_ADMIN_COMPANY_GATES_ROUTE}/logs`;
export const API_ADMIN_COMPANY_GATES_ID_ROUTE = ({ gateId }) => `${API_ADMIN_COMPANY_GATES_ROUTE}/${gateId}`;
export const API_ADMIN_COMPANY_GATES_TOGGLE_STATUS_BY_ID_ROUTE = ({ gateId }) => `${API_ADMIN_COMPANY_GATES_ROUTE}/toggle-status/${gateId}`;
export const API_ADMIN_COMPANY_GATES_STATISTICS_ROUTE = `${API_ADMIN_COMPANY_GATES_ROUTE}/statistics`;

const API_ADMIN_COMPANY_GATE_PROFILES_ROUTE = `${API_ADMIN_COMPANY_ROUTE}/gate-profiles`;
export const API_ADMIN_COMPANY_GATE_PROFILES_LIST_ROUTE = `${API_ADMIN_COMPANY_GATE_PROFILES_ROUTE}/`;
export const API_ADMIN_COMPANY_GATE_PROFILES_ID_ROUTE = ({ profileId }) => `${API_ADMIN_COMPANY_GATE_PROFILES_ROUTE}/${profileId}`;
