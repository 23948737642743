import { useCallback } from 'react';

export const useIncDec = ({
    min = -Infinity,
    max = Infinity,
    step = 1,
    value,
    toFixed = 0,
    onChange,
}) => {
    const onInc = useCallback(() => {
        const maxValue = Math.min((parseFloat(value) || 0) + step, max);
        const incValue = Number(Math.max(maxValue, min).toFixed(toFixed));
        onChange(incValue);
    }, [
        min,
        max,
        step,
        value,
        toFixed,
        onChange,
    ]);

    const onDec = useCallback(() => {
        const maxValue = Math.min((parseFloat(value) || 0) - step, max);
        const decValue = Number(Math.max(maxValue, min).toFixed(toFixed));
        onChange(decValue);
    }, [
        min,
        max,
        step,
        value,
        toFixed,
        onChange,
    ]);

    return {
        onInc,
        onDec,
    };
};
