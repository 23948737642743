import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as COMPANY_SELECTORS from 'store/selectors/company';
import * as LOCALES_SELECTORS from 'store/selectors/locales';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Image } from 'react-bootstrap';
import { CopyIcon, DownloadIcon } from 'components/Icon/Icon';
import { Button } from 'components/Common/Button';
import { useToasts } from 'react-toast-notifications';
import { copyText } from 'helpers/string/copyText';
import { PdfPoster } from 'components/QrCode/PdfPoster/PdfPoster';
import { getWidgetUrl } from 'helpers/http/getWidgetUrl';
import { Column } from 'components/Common/Column';
import { Panel } from 'components/Common/Panel';
import qrImage from './qrImage.png';
import directLinkImage from './directLinkImage.png';

import * as styles from './page.module.scss';

const General = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const locale = useSelector(LOCALES_SELECTORS.shortLocaleSelector);
    const { qrCode } = useSelector(COMPANY_SELECTORS.companyQRCodeSelector);
    const { slug } = useSelector(COMPANY_SELECTORS.companySlugSelector);
    const widgetUrl = getWidgetUrl({ side: 'full', slug });
    const {
        loaded,
        logo,
        name,
        appColor,
    } = useSelector(COMPANY_SELECTORS.posterSelector);
    const { addToast } = useToasts();

    return (
        <Column gap={16}>
            <Panel>
                <div className={styles.contentHeader}>
                    <div className={styles.contentTitleContainer}>
                        <div className={styles.contentTitle}>
                            {t('inviteClients.directLink.title')}
                        </div>
                        <div className={styles.contentSubTitle}>
                            {t('inviteClients.directLink.subTitle')}
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <Button
                            color="outline"
                            before={<CopyIcon />}
                            onClick={
                                () => {
                                    copyText(widgetUrl);
                                    addToast(t('inviteClients.copied'), {
                                        appearance: 'success',
                                        autoDismiss: true,
                                    });
                                }
                            }
                        >
                            {t('actions.copyLink')}
                        </Button>
                    </div>
                </div>

                <div className={styles.rowContent}>
                    <div className={styles.image}>
                        <Image src={directLinkImage} width="100%" height="100%" />
                    </div>
                    <Panel stretched padding={12} gap={12} color="secondary">
                        <div
                            className={styles.link}
                            onClick={
                                () => {
                                    copyText(widgetUrl);
                                    addToast(t('inviteClients.copied'), {
                                        appearance: 'success',
                                        autoDismiss: true,
                                    });
                                }
                            }
                        >
                            {widgetUrl}
                        </div>
                        <div className={styles.description}>
                            {t('inviteClients.directLink.description')}
                        </div>
                    </Panel>
                </div>

            </Panel>
            <Panel>
                <div className={styles.contentHeader}>
                    <div className={styles.contentTitleContainer}>
                        <div className={styles.contentTitle}>
                            {t('inviteClients.qrCode.title')}
                        </div>
                        <div className={styles.contentSubTitle}>
                            {t('inviteClients.qrCode.subTitle')}
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <Button
                            href={qrCode?.[locale]}
                            download="qrCode.png"
                            before={<DownloadIcon />}
                            color="outline"
                            loading={!qrCode?.[locale]}
                        >
                            {t('actions.downloadQrCode')}
                        </Button>
                        <PdfPoster loading={!loaded} logo={logo} name={name} appColor={appColor} qrCode={qrCode?.[locale]} />
                    </div>
                </div>

                <div className={styles.rowContent}>
                    <div className={styles.image}>
                        <Image src={qrImage} width="100%" height="100%" />
                    </div>
                    <Panel stretched padding={12} gap={12} color="secondary">
                        <div className={styles.qrCode}>
                            <Image src={qrCode?.[locale]} width={106} height={106} />
                        </div>
                        <div className={styles.description}>
                            {t('inviteClients.qrCode.description')}
                        </div>
                    </Panel>
                </div>

            </Panel>
        </Column>
    );
};

export default General;
