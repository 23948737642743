import React from 'react';

// Hooks
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Actions

// Components
import {
    Col, Container, Row, Form, InputGroup, Dropdown,
} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import LocalesDropdown from 'components/LocalesDropdown/LocalesDropdown';
import { Calendar, Phone } from 'components/Icon/Icon';

// Images
import { Button } from 'components/Common/Button';
import login from '../../static/images/login.jpg';
import * as actions from '../../store/actions';

const SignUp = () => {
    const isSigningUp = useSelector((state) => state.auth.isSigningUp);
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');

    const { t } = useTranslation();

    return (
        <Container className="d-flex">
            <div className="w-100 bg-white align-self-center rounded shadow-container">
                <Row>
                    <Col md={6} className="d-none d-md-block pr-md-0">
                        <img
                            className="w-100 h-100 object-fit-cover rounded-left"
                            src={login}
                            alt=""
                        />
                    </Col>

                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center pl-md-0">
                        <div
                            className="d-flex flex-column justify-content-center h-100 p-3 pt-5 px-md-4 px-lg-5 pt-md-5 pb-md-0"
                        >
                            <h3>Zonnestudio Leidschendam Damplein</h3>

                            <Form
                                className="mt-3"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    dispatch(actions.signUp(name, email, birthDate, contactNumber, password, repeatedPassword));
                                }}
                            >
                                <Form.Group>
                                    <Form.Label>
                                        {t('signUpRoute.name')}
                                    </Form.Label>
                                    <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>
                                        {t('signUpRoute.email')}
                                    </Form.Label>
                                    <Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>

                                <label className="form-label">{t('signUpRoute.birthDate')}</label>
                                <InputGroup className="mb-3">
                                    <InputMask
                                        mask="99/99/9999"
                                        value={birthDate}
                                        onChange={(e) => setBirthDate(e.target.value)}
                                    >
                                        <Form.Control type="text" />
                                    </InputMask>
                                    <InputGroup.Append>
                                        <InputGroup.Text className="text-muted">
                                            <Calendar width={18} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <label className="form-label">{t('signUpRoute.contactNumber')}</label>
                                <InputGroup className="mb-3">
                                    <Dropdown as={InputGroup.Prepend}>
                                        <Dropdown.Toggle variant="outline-secondary" className="px-2 text-dark">
                                            +31
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">Action</Dropdown.Item>
                                            <Dropdown.Item href="#">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#">Something else here</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item href="#">Separated link</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control
                                        value={contactNumber}
                                        onChange={(e) => setContactNumber(e.target.value)}
                                        type="text"
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text className="text-muted">
                                            <Phone width={18} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Form.Group>
                                    <Form.Label>
                                        {t('signUpRoute.password')}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="new-password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>
                                        {t('signUpRoute.repeatPassword')}
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={repeatedPassword}
                                        onChange={(e) => setRepeatedPassword(e.target.value)}
                                    />
                                </Form.Group>

                                <Button color="yellow" type="submit" loading={isSigningUp}>
                                    { t('signUpRoute.signUp') }
                                </Button>

                                <span className="d-block small my-3">
                                    {t('signUpRoute.agreement')}
                                </span>
                            </Form>
                        </div>

                        <div className="d-flex flex-direction-row justify-content-end align-items-end px-3 px-md-4 px-lg-5 py-4 pt-md-0">
                            <LocalesDropdown />
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default SignUp;
