import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as CLIENTS_TYPES from './types';

export const getClientNotes = createPromiseAction(CLIENTS_TYPES.CLIENT_ITEM_NOTES_GET);

export const setClientNotes = ({
    notes, itemsCount, pagesCount,
}) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_NOTES_SET,
    payload: {
        notes,
        itemsCount,
        pagesCount,
    },
});

export const setClientNotesPage = ({ page }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET,
    payload: { page },
});

export const setClientNotesPageFirst = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_FIRST,
});

export const setClientNotesPageLast = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_LAST,
});

export const setClientNotesPagePrev = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_PREV,
});

export const setClientNotesPageNext = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_NOTES_PAGE_SET_NEXT,
});

export const setClientNotesLoading = ({ loading }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_NOTES_LOADING_SET,
    payload: { loading },
});

export const editClientNotesItem = createPromiseAction(CLIENTS_TYPES.EDIT_CLIENT_NOTE);
export const addClientNotesItem = createPromiseAction(CLIENTS_TYPES.ADD_CLIENT_NOTE);
export const deleteClientNotesItem = createPromiseAction(CLIENTS_TYPES.DELETE_CLIENT_NOTE);

export const setClientNotesFilter = createPromiseAction(CLIENTS_TYPES.SET_CLIENT_NOTE_FILTER);
export const clearClientNotesFilter = createPromiseAction(CLIENTS_TYPES.CLEAR_CLIENT_NOTE_FILTER);

export const getClientNotesCategories = createPromiseAction(CLIENTS_TYPES.GET_CLIENT_NOTE_CATEGORIES);
export const addClientNotesCategory = createPromiseAction(CLIENTS_TYPES.ADD_CLIENT_NOTE_CATEGORY);
export const deleteClientNotesCategory = createPromiseAction(CLIENTS_TYPES.DELETE_CLIENT_NOTE_CATEGORY);

export const setClientNotePersistCreateItem = createPromiseAction(CLIENTS_TYPES.SET_CLIENT_NOTE_PERSIST_CREATE_ITEM);
