export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCEEDED = 'SIGN_UP_SUCCEEDED';
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCEEDED = 'REQUEST_PASSWORD_RESET_SUCCEEDED';
export const REQUEST_PASSWORD_RESET_FAILED = 'REQUEST_PASSWORD_RESET_FAILED';

export const AUTHENTICATE = 'AUTHENTICATE';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_TRIGGER = `${REFRESH_TOKEN}.TRIGGER`;
export const REFRESH_TOKEN_RESOLVED = `${REFRESH_TOKEN}.RESOLVED`;
export const REFRESH_TOKEN_REJECTED = `${REFRESH_TOKEN}.REJECTED`;

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED';
export const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED';

export const QUEUE_FAILED_ACTION = 'QUEUE_FAILED_ACTION';
export const EXECUTE_FAILED_ACTIONS = 'EXECUTE_FAILED_ACTIONS';
export const EXECUTE_FAILED_ACTIONS_SUCCEEDED = 'EXECUTE_FAILED_ACTIONS_SUCCEEDED';

export const NEW_PASSWORD_SET = 'NEW_PASSWORD_SET';
