import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubRoute } from 'components/hooks';
import { useMobile } from 'hooks/useMobile';
import { Trash3Icon } from 'components/Icon/Icon';
import { Button } from 'components/Common/Button';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';

const submitButtonTranslationOptions = {
    add: 'placeholders.createInvoice',
    edit: 'placeholders.saveChanges',
};

function InvoicesAddEditFormFooter({
    disabled, withDynamic, loading, onDelete,
}) {
    const [currentSubRoute] = useSubRoute(withDynamic);
    const { t } = useTranslation();
    const isMobile = useMobile();

    return (
        <div
            className="px-lg-5 w-100"
            style={{
                borderTop: '1px solid #E5EBF0',
            }}
        >
            <Row stretched spaceBetween padding={16}>
                <Button
                    href="/financials/invoices/list"
                    color="outline"
                    className="ml-2 ml-lg-0"
                >
                    {t('placeholders.cancel')}
                </Button>
                <Row>
                    {onDelete && (
                        isMobile ? (
                            <IconButton size={40} color="red" onClick={onDelete}>
                                <Trash3Icon />
                            </IconButton>
                        ) : (
                            <Button
                                color="red"
                                onClick={onDelete}
                            >
                                {t('placeholders.delete')}
                            </Button>
                        )
                    )}
                    <Button
                        type="submit"
                        color="yellow"
                        className="ml-2 mr-2 mr-lg-0"
                        loading={loading}
                        disabled={disabled}
                    >
                        {t(submitButtonTranslationOptions[currentSubRoute])}
                    </Button>
                </Row>
            </Row>
        </div>
    );
}

InvoicesAddEditFormFooter.defaultProps = {
    withDynamic: false,
};

export default InvoicesAddEditFormFooter;
