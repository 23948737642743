import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Button } from 'components/Common/Button';
import { companySelector } from '../../../../store/selectors/company';
import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import * as INVOICE_ACTIONS from '../../../../store/actions/financials/invoices';

import * as styles from './InvoicesClearingDialog.module.scss';

const InvoicesClearingDialog = (props) => {
    const {
        isVisible,
        onClose,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const { name, transactionManualClearingPrice } = useSelector(companySelector);
    const dispatch = useDispatch();

    const nextMondey = moment().day() ? moment().day(8) : moment().day(1);

    const handleSend = useCallback(() => {
        dispatch(INVOICE_ACTIONS.clearingInvoices());
        onClose();
    }, [onClose]);

    return (
        <Dialog
            visible={isVisible}
            onClose={onClose}
            size="md"
        >
            <DialogHeader>
                <DialogTitle>
                    {t('invoicesClearingDialog.title', { companyName: name, interpolation: { escapeValue: false } })}
                </DialogTitle>
            </DialogHeader>

            <DialogBody>
                <p className={styles.description}>
                    {t(
                        'invoicesClearingDialog.description',
                        {
                            date: nextMondey.format('DD MMM'),
                            price: transactionManualClearingPrice,
                        },
                    )}
                </p>
            </DialogBody>

            <DialogFooter className={styles.buttonWrapper}>
                <Button
                    color="outline"
                    stretched
                    onClick={onClose}
                >
                    {t('invoicesClearingDialog.cancel')}
                </Button>

                <Button
                    onClick={handleSend}
                    stretched
                >
                    {t('invoicesClearingDialog.confirm')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

InvoicesClearingDialog.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

InvoicesClearingDialog.defaultProps = {
    isVisible: false,
    onClose: emptyFunc,
};

export default InvoicesClearingDialog;
