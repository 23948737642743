import React from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components

// Styles

// Utils
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as styles from './BookingsDayList.module.scss';
import BookingsList from '../BookingsList/BookingsList';

const BookingsDayList = ({
    globalDate, dataType = 'objects', onClick, className,
}) => {
    const bookings = useSelector((state) => state[dataType]?.bookings
        ?.filter((booking) => !booking || !state[dataType]?.filters || state[dataType]?.filters[booking.product.id]));

    const { t } = useTranslation();

    const formattedEmpty = _.reduce(
        _.times(7, (i) => globalDate.isoWeekday(i + 1).format('DD/MM/YYYY')),
        (formatted, item) => ({
            ...formatted,
            [item]: [],
        }),
        {},
    );

    return (
        <div className={classNames(className, 'd-flex flex-column border-top')}>
            {bookings?.length === 0 && (
                <div className="d-flex justify-content-center mt-3">
                    <span>
                        {t('bookingsDayList.noBookings')}
                    </span>
                </div>

            )}
            {bookings && Object.entries(bookings
                .reduce((bookings, booking) => {
                    const start = moment(booking.time.from).startOf('day');
                    const formatted = start.format('DD/MM/YYYY');

                    return {
                        ...bookings,
                        [formatted]: [
                            ...bookings[formatted] || [],
                            booking,
                        ],
                    };
                }, formattedEmpty))
                .sort(([formattedA], [formattedB]) => moment(formattedA, 'DD/MM/YYYY').unix() - moment(formattedB, 'DD/MM/YYYY').unix())
                .map(([formatted, bookings], i) => {
                    const date = moment(formatted, 'DD/MM/YYYY');
                    return (
                        <div
                            key={`date-${formatted}`}
                            data-testid={`date-${i}`}
                            className={classNames(styles.day, 'd-flex flex-column align-items-center border-left border-right border-bottom p-3')}
                            onClick={() => onClick(bookings.length > 0 && date)}
                        >
                            <div
                                className={classNames(styles.label, 'd-flex flex-column justify-content-center align-items-center rounded-circle py-1 border-2', { 'border border-primary': date.isSame(moment(), 'day') })}
                            >
                                <span
                                    className="text-muted font-size-12 font-weight-600 mb-n1"
                                >
                                    {date.format('ddd').toUpperCase()}
                                </span>
                                <span className="font-size-14 font-weight-600 mb-n1">{date.format('D')}</span>
                            </div>
                            <BookingsList
                                key={`bookings-${formatted}`}
                                bookings={bookings}
                                style={{
                                    gridColumn: date.isoWeekday() + 2,
                                    gridRowStart: date.hour() - 8,
                                }}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default BookingsDayList;
