import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import * as styles from './TableHeader.module.scss';

const TableHeader = (props) => {
    const { children } = props;

    return (
        <thead className={classNames(styles.headerRow, props.align && styles[props.align])}>
            {children}
        </thead>
    );
};

TableHeader.propTypes = {
    children: PropTypes.any,
    align: PropTypes.oneOf(['left', 'center', 'right']),
};

TableHeader.defaultProps = {
    children: null,
    align: 'left',
};

export default TableHeader;
