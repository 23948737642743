const PREFIX = '@@CLIENTS/';

const CLIENTS = `${PREFIX}CLIENTS`;
const CLIENTS_INVITATION = `${CLIENTS}:INVITATION`;
export const CLIENTS_INVITATION_SEND = `${CLIENTS_INVITATION}:SEND`;
export const CLIENTS_APPLY_DISCOUNT = `${CLIENTS}:APPLY:DISCOUNT`;
const CLIENTS_FILTRATION = `${CLIENTS}:FILTRATION`;
const CLIENTS_FILTRATION_DISCOUNT = `${CLIENTS_FILTRATION}:DISCOUNT`;
export const CLIENTS_FILTRATION_DISCOUNT_GET = `${CLIENTS_FILTRATION_DISCOUNT}:GET`;
export const CLIENTS_FILTRATION_DISCOUNT_SET = `${CLIENTS_FILTRATION_DISCOUNT}:SET`;
export const CLIENTS_FILTRATION_DISCOUNT_GET_FAILED = `${CLIENTS_FILTRATION_DISCOUNT_GET}:FAILED`;
export const CLIENTS_FILTRATION_SHOP = `${CLIENTS_FILTRATION}:SHOP`;
export const CLIENTS_FILTRATION_REGISTERED = `${CLIENTS_FILTRATION}:REGISTERED`;
export const CLIENTS_GET = `${CLIENTS}:GET`;
export const CLIENTS_SET = `${CLIENTS}:SET`;
const CLIENTS_PAGE = `${CLIENTS}:PAGE`;
export const CLIENTS_PAGE_SET = `${CLIENTS_PAGE}:SET`;
export const CLIENTS_PAGE_SET_FIRST = `${CLIENTS_PAGE_SET}:FIRST`;
export const CLIENTS_PAGE_SET_LAST = `${CLIENTS_PAGE_SET}:LAST`;
export const CLIENTS_PAGE_SET_PREV = `${CLIENTS_PAGE_SET}:PREV`;
export const CLIENTS_PAGE_SET_NEXT = `${CLIENTS_PAGE_SET}:NEXT`;
const CLIENTS_ITEM = `${CLIENTS}:ITEM`;
export const CLIENTS_ITEM_ADD = `${CLIENTS_ITEM}:ADD`;
export const CLIENTS_ITEM_EDIT = `${CLIENTS_ITEM}:EDIT`;
export const CLIENTS_ITEM_INVITE = `${CLIENTS_ITEM}:INVITE`;
export const CLIENTS_ITEM_DELETE = `${CLIENTS_ITEM}:DELETE`;
const CLIENTS_ITEM_BLOCK = `${CLIENTS_ITEM}:BLOCK`;
export const CLIENTS_ITEM_BLOCK_TOGGLE = `${CLIENTS_ITEM_BLOCK}:TOGGLE`;
const CLIENTS_SEARCH = `${CLIENTS}:SEARCH`;
export const CLIENTS_SEARCH_SET = `${CLIENTS_SEARCH}:SET`;
const CLIENTS_LOADING = `${CLIENTS}:LOADING`;
export const CLIENTS_LOADING_SET = `${CLIENTS_LOADING}:SET`;
const CLIENTS_SORT = `${CLIENTS}:SORT`;
export const CLIENTS_SORT_SET = `${CLIENTS_SORT}:SET`;

const CLIENT_ITEM = `${PREFIX}:CLIENT:ITEM`;
export const CLIENT_ITEM_GET = `${CLIENT_ITEM}:GET`;
export const CLIENT_ITEM_GET_TRIGGER = `${CLIENT_ITEM_GET}.TRIGGER`;
export const CLIENT_ITEM_GET_REJECTED = `${CLIENT_ITEM_GET}.REJECTED`;
export const CLIENT_ITEM_GET_RESOLVED = `${CLIENT_ITEM_GET}.RESOLVED`;

export const CLIENT_ITEM_SILENT_GET = `${CLIENT_ITEM}:SILENT_GET`;
export const CLIENT_ITEM_SILENT_GET_TRIGGER = `${CLIENT_ITEM_SILENT_GET}.TRIGGER`;
export const CLIENT_ITEM_SILENT_GET_REJECTED = `${CLIENT_ITEM_SILENT_GET}.REJECTED`;
export const CLIENT_ITEM_SILENT_GET_RESOLVED = `${CLIENT_ITEM_SILENT_GET}.RESOLVED`;

export const CLIENT_SEND_UPDATE_IBAN_LINK = `${CLIENT_ITEM}:SEND_UPDATE_IBAN_LINK`;
export const CLIENT_SEND_UPDATE_IBAN_LINK_TRIGGER = `${CLIENT_SEND_UPDATE_IBAN_LINK}.TRIGGER`;
export const CLIENT_SEND_UPDATE_IBAN_LINK_REJECTED = `${CLIENT_SEND_UPDATE_IBAN_LINK}.REJECTED`;
export const CLIENT_SEND_UPDATE_IBAN_LINK_RESOLVED = `${CLIENT_SEND_UPDATE_IBAN_LINK}.RESOLVED`;

export const CLIENT_UPDATE_IBAN = `${CLIENT_ITEM}:UPDATE_IBAN`;
export const CLIENT_UPDATE_IBAN_TRIGGER = `${CLIENT_UPDATE_IBAN}.TRIGGER`;
export const CLIENT_UPDATE_IBAN_REJECTED = `${CLIENT_UPDATE_IBAN}.REJECTED`;
export const CLIENT_UPDATE_IBAN_RESOLVED = `${CLIENT_UPDATE_IBAN}.RESOLVED`;

const CLIENT_ITEM_BOOKINGS = `${CLIENT_ITEM}:BOOKINGS`;
const CLIENT_ITEM_BOOKINGS_LOADING = `${CLIENT_ITEM_BOOKINGS}:LOADING`;
export const CLIENT_ITEM_BOOKINGS_LOADING_SET = `${CLIENT_ITEM_BOOKINGS_LOADING}:SET`;
export const CLIENT_ITEM_BOOKINGS_GET = `${CLIENT_ITEM_BOOKINGS}:GET`;
export const CLIENT_ITEM_BOOKINGS_SET = `${CLIENT_ITEM_BOOKINGS}:SET`;
const CLIENT_ITEM_BOOKINGS_FILTERS = `${CLIENT_ITEM_BOOKINGS}:FILTERS`;
export const CLIENT_ITEM_BOOKINGS_FILTERS_SET = `${CLIENT_ITEM_BOOKINGS_FILTERS}:SET`;
const CLIENT_ITEM_BOOKINGS_PAGE = `${CLIENT_ITEM_BOOKINGS}:PAGE`;
export const CLIENT_ITEM_BOOKINGS_PAGE_SET = `${CLIENT_ITEM_BOOKINGS_PAGE}:SET`;
export const CLIENT_ITEM_BOOKINGS_PAGE_SET_FIRST = `${CLIENT_ITEM_BOOKINGS_PAGE}:FIRST`;
export const CLIENT_ITEM_BOOKINGS_PAGE_SET_LAST = `${CLIENT_ITEM_BOOKINGS_PAGE}:LAST`;
export const CLIENT_ITEM_BOOKINGS_PAGE_SET_PREV = `${CLIENT_ITEM_BOOKINGS_PAGE}:PREV`;
export const CLIENT_ITEM_BOOKINGS_PAGE_SET_NEXT = `${CLIENT_ITEM_BOOKINGS_PAGE}:NEXT`;

export const CLIENTS_EXPORT = `${CLIENTS}:EXPORT`;
