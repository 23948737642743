import React from 'react';

const UserStar = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <path
                d="M11,14c-2.824,0-5.329,0.638-6.975,1.193 C2.81,15.604,2,16.749,2,18.032V22c0,0,9.958,0,10,0"
                fill="none"
                stroke={fill}
                strokeLinecap="butt"
            />
            <path
                d="M11,14L11,14 c-2.761,0-5-3.239-5-6V7c0-2.761,2.239-5,5-5h0c2.761,0,5,2.239,5,5v1C16,10.761,13.761,14,11,14z"
                fill="none"
                stroke={fill}
            />
            <polygon
                points="19,13 20.545,16.131 24,16.633 21.5,19.07 22.09,22.511 19,20.886 15.91,22.511 16.5,19.07 14,16.633 17.455,16.131 "
                stroke="none"
                strokeLinecap="square"
            />
        </g>
    </svg>
);

export default UserStar;
