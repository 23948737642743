import React, {
    useState, useEffect, useMemo, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useFormik } from 'formik';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Form, Row, Col, InputGroup,
} from 'react-bootstrap';
import { ChevronLeft } from 'react-feather';
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import classNames from 'classnames';

import { generateRandomString } from 'helpers/string/generateRandomString';
import momentAmsterdamTime from 'helpers/time/momentAmsterdamTime';

import { InfoTooltip } from 'components/Common/InfoTooltip';
import DateInput from 'components/DateInput/DateInput';
import FileUpload from 'components/FileUpload/FileUpload';
import GroupSchedule from 'components/GroupSchedule/GroupSchedule';
import Map from 'components/Map/Map';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { Footer } from 'components';
import { Calendar } from 'components/Icon/Icon';

import { permissionsResourceSelector } from 'store/selectors/permissions';
import * as actions from 'store/actions';
import * as SERVICES_ACTIONS from 'store/actions/services';
import * as SERVICES_SELECTORS from 'store/selectors/services';
import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import Container from 'components/Layout/Container/Container';
import { BackButton } from 'components/Common/BackButton';

const Add = () => {
    const { t } = useTranslation();
    /* istanbul ignore next */
    const schema = useRef(yup.object({
        image: yup.mixed().required(t('addGroupRoute.pleaseSelectGroupSessionImage')),
        name: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.name') })),
        description: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.description') })),
        sessions: yup
            .object(_.times(7).reduce((schedule, i) => ({
                ...schedule,
                [moment().isoWeekday(i).format('dddd')]: yup.array(yup.object({
                    from: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('groupSchedule.startTime') })),
                    maxRegistrations: yup.number().required(t('validationErrors.cannotBeEmpty', { name: t('groupSchedule.maxRegistrations') })),
                    showNumberOfRegistrations: yup.boolean().required(),
                    service: yup.object({
                        id: yup.number().required(t('validationErrors.cannotBeEmpty', { name: t('groupSchedule.service') })),
                    }).typeError(t('validationErrors.cannotBeEmpty', { name: t('groupSchedule.service') })),
                    employee: yup.object({
                        id: yup.number().nullable(),
                    }).nullable(),
                })),
            }), {}))
            .test('is-session-defined', t('addGroupRoute.pleaseDefineAtLeastOneGroupSession'), function isSessionDefined(sessions) {
                const { path, message, createError } = this;
                if (Object.values(sessions).find((day) => day.length !== 0)) {
                    return true;
                }
                return createError({ path, message });
            })
            .required(),
        contactNumber: yup
            .string()
            .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, t('validationErrors.invalidPhoneNumber'))
            .required(t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.contactNumber') })),
        address: yup.object({
            city: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.city') })),
            street: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.streetAddress') })),
            zip: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.zipCode') })),
            country: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.country') })),
        }).required(),
        time: yup.object({
            from: yup.mixed().when('to', (to, schema) => schema.test({
                name: generateRandomString(),
                test: (from) => !to || !from || from.isSameOrBefore(to, 'date'),
                message: t('validationErrors.startBeforeEnd'),
            })),
            to: yup.mixed(),
        }),
        futureBooking: yup.number()
            .positive(t('validationErrors.mustBePositiveNumber', { name: t('addGroupRoute.days') }))
            .max(1000, t('validationErrors.mustBeNoLongerThen', { field: 'Future bookings', days: 1000 })),
    })).current;

    const { loaded: isShopLoaded, id: shopID } = useSelector((state) => state.shop);
    const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const {
        contactNumber, address: {
            street, zip, city, country, latitude, longitude,
        },
    } = useSelector((state) => state.shop);
    const { banAdd } = useSelector(permissionsResourceSelector);

    const dispatch = useDispatch();

    const [center, setCenter] = useState(null);
    const [isGettingAddress, setGettingAddress] = useState(false);
    const [isGettingCoordinates, setGettingCoordinates] = useState(false);

    useEffect(() => {
        if (!shopID || !isShopLoaded) {
            return;
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [shopID, isShopLoaded]);

    useEffect(() => {
        if (!latitude || !longitude) {
            return;
        }

        setCenter({
            latitude,
            longitude,
        });
    }, [latitude, longitude]);

    const { addToast } = useToasts();
    const { push } = useHistory();

    const initialValues = useMemo(() => ({
        image: null,
        name: '',
        description: '',
        sessions: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd'))
            .reduce((breaks, day) => ({
                ...breaks,
                [day]: [],
            }), {}),
        contactNumber: contactNumber || '',
        address: {
            street: street || '',
            zip: zip || '',
            city: city || '',
            country: country || '',
            latitude: latitude || 0,
            longitude: longitude || 0,
        },
        time: {
            from: null,
            to: null,
        },
        futureBooking: '',
        allowLateBooking: false,
    }), [street, zip, city, country, latitude, longitude]);

    const {
        values, touched, errors, handleChange, setFieldValue, handleSubmit, isSubmitting,
    } = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            dispatch(actions.addGroup({
                group: values,
            }))
                .then(() => {
                    addToast(t('addGroupRoute.groupSessionAddedSuccessfully'), {
                        appearance: 'success',
                    });
                    push('/agenda/groups');
                })
                .catch(({ message, errors }) => {
                    if (message) {
                        addToast(message, {
                            appearance: 'error',
                        });
                    } else {
                        setErrors(errors || {});
                    }
                    setSubmitting(false);
                });
        },
        enableReinitialize: true,
    });

    if (banAdd) {
        return <Redirect to="/agenda/groups" />;
    }

    return (
        <>
            <TextSubHeader
                text={t('addGroupRoute.addNewGroupSession')}
                before={(
                    <BackButton
                        href="/agenda/groups"
                    />
                )}
            />
            <Container>
                <Form noValidate onSubmit={handleSubmit} className="w-100">
                    <Row>
                        <Col xs={12} md={6} lg={6} xl={3}>
                            <FileUpload
                                name="image"
                                label={(touched.image && errors.image) || t('addGroupRoute.uploadGroupSessionImage')}
                                isInvalid={!!touched.image && !!errors.image}
                                onChange={(file) => setFieldValue('image', file)}
                                defaultValue={values.image}
                                square
                            />
                        </Col>

                        <Col xs={12} md={6} lg={6} xl={9} className="mt-4 mt-lg-0">
                            <Form.Group>
                                <Form.Label>
                                    {t('addGroupRoute.name')}
                                </Form.Label>
                                <Form.Control
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={!!touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addGroupRoute.description')}
                                </Form.Label>
                                <Form.Control
                                    id="description"
                                    name="description"
                                    as="textarea"
                                    rows={3}
                                    value={values.description}
                                    onChange={handleChange}
                                    isInvalid={!!touched.description && !!errors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.description}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    id="allowLateBooking"
                                    name="allowLateBooking"
                                    type="checkbox"
                                    label={(
                                        <Form.Label className="d-flex align-items-center m-0">
                                            {t('addGroupRoute.allowLateBooking.label')}
                                            <InfoTooltip
                                                text={t('addGroupRoute.allowLateBooking.tooltip')}
                                                placement="bottom"
                                            />
                                        </Form.Label>
                                    )}
                                    checked={values.allowLateBooking}
                                    onChange={handleChange}
                                />

                                <Form.Control.Feedback
                                    className={!!touched.allowLateBooking && !!errors.allowLateBooking && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.allowLateBooking}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label className="d-flex align-items-center">
                                            {t('addGroupRoute.startDate.label')}
                                            <InfoTooltip
                                                text={t('addGroupRoute.startDate.tooltip')}
                                                placement="bottom"
                                            />
                                        </Form.Label>
                                        <InputGroup>
                                            <DateInput
                                                name="time.from"
                                                value={values.time.from}
                                                placeholder={t('addGroupRoute.selectDate')}
                                                onChange={(time) => setFieldValue('time.from', time)}
                                                isInvalid={touched.time?.from && !!errors.time?.from}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text className={classNames({
                                                    'border-danger text-danger': touched.time?.from && errors.time?.from,
                                                    'text-muted': !touched.time?.from && !errors.time?.from,
                                                })}
                                                >
                                                    <Calendar width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>

                                        <Form.Control.Feedback
                                            className={touched.time?.from && errors.time?.from && 'd-block'}
                                            type="invalid"
                                        >
                                            {errors.time?.from}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label className="d-flex align-items-center">
                                            {t('addGroupRoute.endDate.label')}
                                            <InfoTooltip
                                                text={t('addGroupRoute.endDate.tooltip')}
                                                placement="bottom"
                                            />
                                        </Form.Label>
                                        <InputGroup>
                                            <DateInput
                                                name="time.to"
                                                value={values.time.to}
                                                placeholder={t('addGroupRoute.selectDate')}
                                                onChange={(time) => setFieldValue('time.to', time)}
                                                isInvalid={touched.time?.to && !!errors.time?.from}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text className={classNames({
                                                    'border-danger text-danger': touched.time?.to && errors.time?.from,
                                                    'text-muted': !touched.time?.to && !errors.time?.from,
                                                })}
                                                >
                                                    <Calendar width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>

                                        <Form.Control.Feedback
                                            className={touched.time?.to && errors.time?.from && 'd-block'}
                                            type="invalid"
                                        >
                                            {t('validationErrors.endAfterStart')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label className="d-flex align-items-center">
                                    {t('addGroupRoute.howLongBookingsCanBeMade.label')}
                                    <InfoTooltip
                                        text={t('addGroupRoute.howLongBookingsCanBeMade.tooltip')}
                                        placement="bottom"
                                    />
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text
                                            className={classNames({
                                                'border-danger text-danger': !!touched.futureBooking && !!errors.futureBooking,
                                            }, 'bg-very-light')}
                                        >
                                            {t('addGroupRoute.days')}
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="number"
                                        name="futureBooking"
                                        value={values.futureBooking}
                                        onChange={handleChange}
                                        isInvalid={!!touched.futureBooking && !!errors.futureBooking}
                                        className="default-borders"
                                    />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    {errors.futureBooking}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <h3 className="mb-4 mt-4 font-weight-normal">
                        {t('addGroupRoute.groupSessions')}
                    </h3>

                    <GroupSchedule
                        name="sessions"
                        value={values.sessions}
                        services={companyServices}
                        onChange={({
                            type, sessions, index, day, from, maxRegistrations, showNumberOfRegistrations, service, employee,
                        }) => {
                            switch (type) {
                            case 'ADD':
                                setFieldValue('sessions', {
                                    ...values.sessions,
                                    [day]: [
                                        ...values.sessions[day],
                                        {
                                            from: momentAmsterdamTime(),
                                            maxRegistrations: 10,
                                            showNumberOfRegistrations: false,
                                            service: companyServices[0] ?? null,
                                            employee: null,
                                        },
                                    ],
                                });
                                break;
                            case 'UPDATE':
                                setFieldValue('sessions', Object.keys(values.sessions).reduce((sessions, key) => {
                                    if (key === day) {
                                        return {
                                            ...sessions,
                                            [key]: values.sessions[key].map((schedule, i) => {
                                                if (i === index) {
                                                    return {
                                                        from: from || schedule.from,
                                                        maxRegistrations: maxRegistrations !== undefined ? maxRegistrations : schedule.maxRegistrations,
                                                        showNumberOfRegistrations: showNumberOfRegistrations !== undefined ? showNumberOfRegistrations : schedule.showNumberOfRegistrations,
                                                        service: service || schedule.service,
                                                        employee,
                                                    };
                                                }

                                                return schedule;
                                            }),
                                        };
                                    }

                                    return {
                                        ...sessions,
                                        [key]: values.sessions[key],
                                    };
                                }, {}));
                                break;
                            case 'REMOVE':
                                setFieldValue('sessions', Object.keys(values.sessions).reduce((sessions, key) => {
                                    if (key === day) {
                                        return {
                                            ...values.sessions,
                                            [key]: values.sessions[key].filter((_, i) => i !== index),
                                        };
                                    }

                                    return {
                                        ...sessions,
                                        [key]: values.sessions[key],
                                    };
                                }, {}));
                                break;
                            case 'SET':
                                setFieldValue('sessions', sessions);
                                break;
                            }
                        }}
                        errors={!!touched.sessions && errors.sessions}
                    />

                    <Form.Control.Feedback
                        className={!!touched.sessions && !!errors.sessions && typeof (errors.sessions) === 'string' && 'd-block'}
                        type="invalid"
                    >
                        {typeof (errors.sessions) === 'string' && errors.sessions}
                    </Form.Control.Feedback>

                    <h3 className="my-4 font-weight-normal">
                        {t('addGroupRoute.address')}
                    </h3>
                    <Row>
                        <Col xs={12} lg={8} className="d-flex flex-column">
                            {center ? (
                                <Map
                                    name="map"
                                    className="h-100"
                                    center={center}
                                    markerPosition={{
                                        latitude: values.address.latitude,
                                        longitude: values.address.longitude,
                                    }}
                                    onMarkerPositionChange={({ latitude, longitude }) => {
                                        setCenter({ latitude, longitude });
                                        setFieldValue('address', {
                                            ...values.address,
                                            latitude,
                                            longitude,
                                        });
                                    }}
                                />
                            ) : <div className="h-100" style={{ minHeight: 400 }} />}

                            <div className="mt-4">
                                <Button
                                    type="button"
                                    name="fillAddress"
                                    color="outline"
                                    size="small"
                                    onClick={() => {
                                        setGettingAddress(true);
                                        dispatch(actions.getAddressByCoordinates({
                                            latitude: values.address.latitude,
                                            longitude: values.address.longitude,
                                        }))
                                            .then(({
                                                street, zip, city, country,
                                            }) => setFieldValue('address', {
                                                ...values.address,
                                                street,
                                                zip,
                                                city,
                                                country,
                                            }))
                                            .finally(() => setGettingAddress(false));
                                    }}
                                    loading={isGettingAddress}
                                >
                                    {t('addGroupRoute.fillAddressByMarkerPosition')}
                                </Button>
                            </div>
                        </Col>

                        <Col xs={12} lg={4} className="mt-4 mt-lg-0">
                            <Form.Group className="mb-3">
                                <label className="form-label">{t('addGroupRoute.contactNumber')}</label>
                                <PhoneInput
                                    name="contactNumber"
                                    value={values.contactNumber}
                                    onChange={(contactNumber) => setFieldValue('contactNumber', contactNumber)}
                                    isInvalid={!!touched.contactNumber && !!errors.contactNumber}
                                />

                                <Form.Control.Feedback
                                    className={!!touched.contactNumber && !!errors.contactNumber && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.contactNumber}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addGroupRoute.city')}
                                </Form.Label>
                                <Form.Control
                                    name="address.city"
                                    type="text"
                                    placeholder={t('addGroupRoute.enterCity')}
                                    value={values.address.city}
                                    onChange={handleChange}
                                    isInvalid={!!touched.address && !!touched.address.city && !!errors.address && !!errors.address.city}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.city}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addGroupRoute.streetAddress')}
                                </Form.Label>
                                <Form.Control
                                    name="address.street"
                                    type="text"
                                    placeholder={t('addGroupRoute.enterStreetAddress')}
                                    value={values.address.street}
                                    onChange={handleChange}
                                    isInvalid={!!touched.address && !!touched.address.street && !!errors.address && !!errors.address.street}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.street}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addGroupRoute.zipCode')}
                                </Form.Label>
                                <Form.Control
                                    name="address.zip"
                                    type="text"
                                    placeholder={t('addGroupRoute.enterZipCode')}
                                    value={values.address.zip}
                                    onChange={handleChange}
                                    isInvalid={!!touched.address && !!touched.address.zip && !!errors.address && !!errors.address.zip}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.zip}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    {t('addGroupRoute.country')}
                                </Form.Label>
                                <Form.Control
                                    name="address.country"
                                    type="text"
                                    placeholder={t('addGroupRoute.enterCountry')}
                                    value={values.address.country}
                                    onChange={handleChange}
                                    isInvalid={!!touched.address && !!touched.address.country && !!errors.address && !!errors.address.country}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address && errors.address.country}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button
                                type="button"
                                name="placeMarker"
                                onClick={() => {
                                    setGettingCoordinates(true);
                                    dispatch(actions.getCoordinatesByAddress({
                                        street: values.address.street,
                                        zip: values.address.zip,
                                        city: values.address.city,
                                        country: values.address.country,
                                    }))
                                        .then(({ latitude, longitude }) => {
                                            setFieldValue('address', {
                                                ...values.address,
                                                latitude,
                                                longitude,
                                            });
                                            setCenter({
                                                latitude,
                                                longitude,
                                            });
                                        })
                                        .finally(() => setGettingCoordinates(false));
                                }}
                                loading={isGettingCoordinates}
                            >
                                {t('addGroupRoute.placeMarkerOnMap')}
                            </Button>
                        </Col>
                    </Row>

                    <div
                        className="d-flex align-items-center border-top pt-3 mt-3"
                    >
                        <Button
                            type="button"
                            color="outline"
                            href="/agenda/groups"
                        >
                            {t('addGroupRoute.cancel')}
                        </Button>

                        <div className="d-flex justify-content-end flex-grow-1">
                            <Button type="submit" loading={isSubmitting}>
                                {t('addGroupRoute.addGroupSession')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>

            <Footer className="d-flex d-lg-none">
                <Button
                    color="outline"
                    href="/agenda/groups"
                    className="rounded-0 px-3 px-sm-5"
                    size="extra-large"
                >
                    <ChevronLeft
                        className="text-darker-light mr-1 position-relative"
                        size={20}
                    />
                    {t('addGroupRoute.back')}
                </Button>
            </Footer>
        </>
    );
};

export default Add;
