import React, {
    cloneElement, forwardRef,
    useCallback, useImperativeHandle,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Overlay from 'react-bootstrap/Overlay';
import Card from 'react-bootstrap/Card';

import { ClickAwayListener } from '../ClickAwayListener';

import { useBool } from '../../../hooks/useBool';

import * as styles from './ContextMenu.module.scss';

const ContextMenu = forwardRef((props, controlsRef) => {
    const {
        children,
        placement,
        Menu,
        menuContainerClassName,
    } = props;

    const targetRef = useRef();

    const isVisible = useBool(false);

    const handleClickAway = useCallback(() => {
        isVisible.onFalse();
    }, [isVisible.onFalse]);

    const handleToggle = useCallback((e) => {
        e.stopPropagation();
        isVisible.onToggle();
    }, [isVisible.onToggle]);

    useImperativeHandle(controlsRef, () => ({
        isVisible: isVisible.value,
        onShow: isVisible.onTrue,
        onClose: isVisible.onFalse,
        onToggle: isVisible.onToggle,
    }));

    return (
        <>
            <Overlay
                show={isVisible.value}
                target={targetRef}
                placement={placement}
            >
                {({ ref, style }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Card
                            ref={ref}
                            style={style}
                            className={classNames(
                                styles.menuContainer,
                                menuContainerClassName,
                            )}
                        >
                            {Menu}
                        </Card>
                    </ClickAwayListener>
                )}
            </Overlay>
            {cloneElement(children, {
                ref: targetRef,
                onClick: handleToggle,
            })}
        </>
    );
});

ContextMenu.propTypes = {
    children: PropTypes.node,
    placement: PropTypes.oneOf([
        'auto-start',
        'auto',
        'auto-end',
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom-end',
        'bottom',
        'bottom-start',
        'left-end',
        'left',
        'left-start',
    ]),
    Menu: PropTypes.node,
    menuContainerClassName: PropTypes.string,
};

ContextMenu.defaultProps = {
    children: null,
    placement: 'top',
    Menu: null,
    menuContainerClassName: '',
};

export default ContextMenu;
