import React from 'react';

const BellOff = ({
    fill = 'currentColor', width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0005 21.6763C12.99 21.6763 13.8411 21.0814 14.2152 20.2293C14.4373 19.7236 13.9707 19.2573 13.4184 19.2573H10.5826C10.0303 19.2573 9.5637 19.7236 9.78577 20.2293C10.1599 21.0814 11.011 21.6763 12.0005 21.6763ZM20.1416 16.0177C20.0963 15.969 20.0485 15.9187 19.9987 15.8664C19.2597 15.0895 18.0856 13.8552 18.046 10.3684L10.3667 18.0478H19.2538C19.9764 18.0478 20.4633 17.4582 20.4671 16.8383C20.4689 16.5529 20.3684 16.2611 20.1416 16.0177ZM16.9097 6.67638L20.2929 3.29317C20.6834 2.90264 21.3166 2.90264 21.7071 3.29317C22.0976 3.68369 22.0976 4.31686 21.7071 4.70738L4.70711 21.7074C4.31658 22.0979 3.68342 22.0979 3.29289 21.7074C2.90237 21.3169 2.90237 20.6837 3.29289 20.2932L5.53826 18.0478H4.74725C4.02457 18.0478 3.53812 17.4582 3.53396 16.8383C3.53207 16.5529 3.63261 16.2615 3.85939 16.0177C3.90475 15.969 3.95256 15.9187 4.00235 15.8664C4.7541 15.0761 5.956 13.8125 5.956 10.186C5.956 7.24918 8.01518 4.8982 10.7918 4.32142V3.53372C10.7918 2.86585 11.333 2.32422 12.0005 2.32422C12.668 2.32422 13.2093 2.86585 13.2093 3.53372V4.32142C14.7325 4.63784 16.0398 5.48823 16.9097 6.67638Z" fill={fill} />
    </svg>
);

export default BellOff;
