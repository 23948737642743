import React from 'react';

const Sad = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <circle cx="12" cy="12" fill="none" r="11" stroke={fill} />
            <circle cx="7.5" cy="10.5" r="1.5" stroke="none" />
            <circle cx="16.5" cy="10.5" r="1.5" stroke="none" />
            <path d="M9,18a3,3,0,0,1,6,0" fill="none" strokeLinecap="butt" />
        </g>
    </svg>
);

export default Sad;
