import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const selectShop = createPromiseAction(actionTypes.SELECT_SHOP);

export const getShopDetails = createPromiseAction(actionTypes.GET_SHOP_DETAILS);

export const getShopDetailsSucceeded = (payload) => ({
    type: actionTypes.GET_SHOP_DETAILS_SUCCEEDED,
    payload,
});

export const getShopDetailsFailed = () => ({
    type: actionTypes.GET_SHOP_DETAILS_FAILED,
});
