import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { TailSpin } from 'react-loader-spinner';

import { ConfirmDialog as CancelBookingsDialog } from 'components/Common/Dialogs/ConfirmDialog';
import { Button } from 'components/Common/Button';
import { SoldSubscriptionBookingsList } from '../SoldSubscriptionBookingsList';

import { useBool } from '../../../hooks/useBool';

import { Calendar } from '../../Icon/Icon';

import DateInput from '../../DateInput/DateInput';

import { getError, isInvalid } from '../../../services/validationService';

import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { generateRandomString } from '../../../helpers/string/generateRandomString';

import * as SUBSCRIPTIONS_ACTIONS from '../../../store/actions/subscriptions';

import * as SUBSCRIPTIONS_SELECTORS from '../../../store/selectors/subscriptions';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';
import { SOLD_SUBSCRIPTION_PROP } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PROP';

import * as styles from './PauseSoldSubscriptionDialog.module.scss';

const T_PREFIX = 'sold.dialogs.pause';
const T_BODY = `${T_PREFIX}.body`;
const T_FIELDS = `${T_BODY}.form.fields`;
const T_FOOTER = `${T_PREFIX}.footer`;
const T_ACTIONS = `${T_FOOTER}.actions`;

const initialValues = {
    startDate: moment()
        .utc()
        .add(1, 'month')
        .startOf('month'),
    endDate: {
        date: moment()
            .utc()
            .add(1, 'month')
            .endOf('month')
            .startOf('day'),
        unlimited: false,
    },
};

const DATA_TRANSFORMER = {
    send: (data) => {
        const formData = new FormData();
        formData.append('startDate', data.startDate.unix());
        if (data.endDate.date && !data.endDate.unlimited) {
            formData.append('endDate', data.endDate.date.unix());
        }
        formData.append('indefinite', Number(data.endDate.unlimited));
        return formData;
    },
};

const PauseSoldSubscriptionDialog = (props) => {
    const {
        visible,
        soldSubscription,
        onClose,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    const soldSubscriptionIdRef = useRef(soldSubscription?.id);

    useEffect(() => {
        soldSubscriptionIdRef.current = soldSubscription?.id;
    }, [soldSubscription?.id]);

    const hasErrors = useBool(false);

    const [bookings, setBookings] = useState([]);
    const isLoading = useBool(false);
    const loadedBookings = useBool(false);

    useEffect(() => {
        if (!visible) {
            isLoading.onFalse();
            loadedBookings.onFalse();
            setBookings([]);
        }
    }, [visible]);

    const text = (
        <div className={styles.bookingsList}>
            <span className={styles.bookingsListTitle}>
                {t(`${T_BODY}.haveBookings`)}
            </span>
            <SoldSubscriptionBookingsList bookings={bookings} />
        </div>
    );

    const dispatch = useDispatch();
    const soldSubscriptions = useSelector(SUBSCRIPTIONS_SELECTORS.soldSubscriptionsListSelector);

    const validationSchema = useMemo(() => (
        yup.object({
            startDate: yup
                .mixed()
                .required(t(`${T_FIELDS}.startDate.validation.required`))
                .test({
                    name: generateRandomString(),
                    test: (startDate) => {
                        const today = moment().startOf('day');
                        const diff = startDate
                            .clone()
                            .diff(today, 'day');
                        return diff >= SOLD_SUBSCRIPTION_PROP.PAUSE.DAYS_BEFORE_NEXT_MONTH;
                    },
                    message: () => {
                        const today = moment().startOf('day');
                        const nextDebit = moment()
                            .add(1, 'month')
                            .startOf('month')
                            .add(-SOLD_SUBSCRIPTION_PROP.PAUSE.DAYS_BEFORE_NEXT_MONTH + 1, 'day');
                        const startDateMonthsDiff = today.isBefore(nextDebit) ? 1 : 2;
                        return t(`${T_FIELDS}.startDate.validation.dateOrAfter`, {
                            date: moment()
                                .add(startDateMonthsDiff, 'month')
                                .startOf('month')
                                .format('DD.MM.YY'),
                        });
                    },
                })
                .test({
                    name: generateRandomString(),
                    test: (startDate) => startDate.isSame(
                        startDate
                            .clone()
                            .startOf('month'),
                    ),
                    message: t(`${T_FIELDS}.startDate.validation.startOfMonth`),
                }),
            endDate: yup.object()
                .when('startDate', (startDate) => (
                    yup.object({
                        date: yup
                            .mixed()
                            .when('unlimited', (unlimited, schema) => {
                                if (unlimited) {
                                    return schema.nullable();
                                }
                                return schema
                                    .required(t(`${T_FIELDS}.endDate.date.validation.required`))
                                    .test({
                                        name: generateRandomString(),
                                        test: (endDate) => endDate.isAfter(startDate),
                                        message: t(`${T_FIELDS}.endDate.date.validation.afterDate`, {
                                            date: startDate?.format?.('DD.MM.YY'),
                                        }),
                                    })
                                    .test({
                                        name: generateRandomString(),
                                        test: (endDate) => endDate.isSame(
                                            endDate
                                                .clone()
                                                .endOf('month')
                                                .startOf('day'),
                                        ),
                                        message: t(`${T_FIELDS}.endDate.date.validation.endOfMonth`),
                                    });
                            }),
                        unlimited: yup
                            .bool(),
                    })
                )),
        })
    ), [t]);

    const handleSuccess = useCallback((data) => {
        const { startDate, endDate } = data;

        if (!soldSubscription?.id) {
            return;
        }
        const soldSubscriptionIndex = soldSubscriptions.findIndex((s) => String(s.id) === String(soldSubscription.id));
        if (soldSubscriptionIndex < 0) {
            return;
        }
        const soldSubscriptionsCopy = soldSubscriptions.slice();
        soldSubscriptionsCopy[soldSubscriptionIndex] = {
            ...soldSubscriptionsCopy[soldSubscriptionIndex],
            pause: {
                status: false,
                from: startDate.unix(),
                to: endDate.unlimited ? null : endDate.date.unix(),
                unlimited: endDate.unlimited,
            },
        };
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldSubscriptionsList(soldSubscriptionsCopy));
        onClose();
    }, [
        soldSubscription?.id,
        soldSubscriptions,
        dispatch,
        onClose,
    ]);

    const handlePauseSucces = useCallback((data, values) => {
        const { soldSubscriptionId: sId } = data;
        if (sId === soldSubscriptionIdRef.current) {
            handleSuccess(values);
            onClose();
        }
    }, [handleSuccess, onClose]);

    const handleFail = useCallback((data) => {
        const { soldSubscriptionId: sId } = data;
        if (sId === soldSubscriptionIdRef.current) {
            isLoading.onFalse();
        }
    }, []);

    const handleConfirmPause = useCallback((values) => {
        if (!soldSubscription?.id) {
            return;
        }

        const transformedValues = DATA_TRANSFORMER.send(values);
        isLoading.onTrue();
        dispatch(SUBSCRIPTIONS_ACTIONS.pauseSoldSubscriptionItem({
            soldSubscriptionId: soldSubscription?.id,
            data: transformedValues,
            onSuccess: (data) => handlePauseSucces(data, values),
            onError: handleFail,
        }));
    }, [soldSubscription?.id, dispatch, handlePauseSucces, handleFail]);

    const handleGetBookingsSuccess = useCallback((data, values) => {
        const {
            soldSubscriptionId: sId,
            bookings: sBookings,
        } = data;
        if (sId !== soldSubscriptionIdRef.current) {
            return;
        }
        if (!sBookings.length) {
            return handleConfirmPause(values);
        }
        loadedBookings.onTrue();
        isLoading.onFalse();
        setBookings(sBookings);
    }, [handleConfirmPause]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: useCallback((values) => {
            if (!soldSubscription?.id) {
                return;
            }
            isLoading.onTrue();
            dispatch(SUBSCRIPTIONS_ACTIONS.getSoldSubscriptionsItemBookings({
                soldSubscriptionId: soldSubscription.id,
                // TODO: temp workaround, remove on backend update
                // -1 is temporary hack for the correct date calculation on the backend
                startDate: values.startDate.unix() - 1,
                onSuccess: (data) => handleGetBookingsSuccess(data, values),
                onFail: handleFail,
            }));
        }, [soldSubscription?.id, dispatch, handleGetBookingsSuccess, handleFail]),
    });

    const {
        values,
        errors,
        touched,
        setValues,
        setTouched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
    } = formik;

    const onConfirmPause = useCallback(() => {
        handleConfirmPause(values);
    }, [values]);

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const value = values[k];
            let subValidation;
            const objects = ['endDate'];
            if (objects.includes(k)) {
                subValidation = Object.keys(value).reduce((res1, kk) => {
                    const invalid = isInvalid(`${k}.${kk}`, errors, touched);
                    if (invalid) {
                        hasErrors.onTrue();
                    }
                    return {
                        ...res1,
                        [kk]: {
                            isInvalid: invalid,
                            error: getError(`${k}.${kk}`, errors),
                        },
                    };
                }, {});
            } else {
                const invalid = isInvalid(k, errors, touched);
                if (invalid) {
                    hasErrors.onTrue();
                }
                subValidation = {
                    isInvalid: invalid,
                    error: getError(k, errors),
                };
            }
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [
        values,
        errors,
        touched,
        hasErrors.onFalse,
        hasErrors.onTrue,
    ]);

    const handleChangeStartDate = useCallback((sd) => {
        setFieldTouched('startDate', true);
        setFieldValue('startDate', sd.startOf('day'));
    }, [setFieldTouched, setFieldValue]);

    const handleChangeEndDate = useCallback((ed) => {
        setFieldTouched('endDate.date', true);
        setFieldValue('endDate.date', ed.startOf('day'));
    }, [setFieldTouched, setFieldValue]);

    const handleChangeEndDateUnlimited = useCallback((e) => {
        const { target } = e;
        const { checked } = target;
        setTouched({
            endDate: {
                date: true,
                unlimited: true,
            },
        });
        setFieldValue('endDate.unlimited', checked);
    }, [setTouched, setFieldValue]);

    useEffect(() => {
        if (!visible) {
            return;
        }

        const today = moment().startOf('day');
        const nextDebit = moment()
            .add(1, 'month')
            .startOf('month')
            .add(-SOLD_SUBSCRIPTION_PROP.PAUSE.DAYS_BEFORE_NEXT_MONTH + 1, 'day');
        const startDateMonthsDiff = today.isBefore(nextDebit) ? 1 : 2;
        const startDate = moment()
            .add(startDateMonthsDiff, 'month')
            .startOf('month');
        const endDate = startDate
            .clone()
            .endOf('month')
            .startOf('day');
        setTouched({
            startDate: true,
            endDate: {
                date: true,
                unlimited: true,
            },
        });
        setValues({
            startDate,
            endDate: {
                date: endDate,
                unlimited: false,
            },
        });
    }, [
        soldSubscription?.id,
        visible,
        soldSubscription?.nextDebitDate,
        setTouched,
        setValues,
    ]);

    return (
        !loadedBookings.value ? (
            <Modal
                show={visible}
                size="lg"
                centered
                fullscreen="lg-down"
                restoreFocus={false}
                onHide={onClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t(`${T_PREFIX}.header.title`)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Container
                            fluid
                            className="m-0 p-0"
                        >
                            <Row className="m-0 p-0">
                                <Col
                                    xs={12}
                                    className="m-0 p-0"
                                >
                                    <Form.Group>
                                        <Form.Label>
                                            {t(`${T_FIELDS}.startDate.label`)}
                                        </Form.Label>
                                        <InputGroup>
                                            <DateInput
                                                value={values.startDate}
                                                onChange={handleChangeStartDate}
                                                isInvalid={validation?.startDate?.isInvalid}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <Calendar width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className={classNames({
                                                'd-block': validation?.startDate?.isInvalid,
                                            })}
                                        >
                                            {validation?.startDate?.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="m-0 p-0">
                                <Col
                                    xs={12}
                                    className="m-0 p-0"
                                >
                                    <Form.Group>
                                        <Form.Label>
                                            {t(`${T_FIELDS}.endDate.date.label`)}
                                        </Form.Label>
                                        <InputGroup>
                                            <DateInput
                                                value={(values.endDate.unlimited
                                                    ? null
                                                    : values.endDate.date
                                                )}
                                                onChange={handleChangeEndDate}
                                                isInvalid={validation?.endDate?.date?.isInvalid}
                                                isDisabled={values.endDate.unlimited}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    <Calendar width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className={classNames({
                                                'd-block': validation?.endDate?.date?.isInvalid,
                                            })}
                                        >
                                            {validation?.endDate?.date?.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="m-0 p-0">
                                <Col
                                    xs={12}
                                    className="m-0 p-0"
                                >
                                    <Form.Group>
                                        <Form.Check
                                            id="endDate.unlimited"
                                            name="endDate.unlimited"
                                            label={t(`${T_FIELDS}.endDate.unlimited.label`)}
                                            checked={values.endDate.unlimited}
                                            onChange={handleChangeEndDateUnlimited}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className={classNames({
                                                'd-block': validation?.endDate?.unlimited?.isInvalid,
                                            })}
                                        >
                                            {validation?.endDate?.unlimited?.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button
                        color="outline"
                        onClick={onClose}
                    >
                        {t(`${T_PREFIX}.footer.actions.cancel`)}
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        loading={isLoading.value}
                    >
                        {t(`${T_PREFIX}.footer.actions.save`)}
                    </Button>
                </Modal.Footer>
            </Modal>
        ) : (
            <CancelBookingsDialog
                size="lg"
                title={t(`${T_PREFIX}.header.title`)}
                text={text}
                visible
                onClose={onClose}
                onReject={onClose}
                onConfirm={onConfirmPause}
                rejectProps={{ label: t(`${T_ACTIONS}.no`) }}
                confirmProps={{
                    label: isLoading.value
                        ? (
                            <TailSpin
                                color="white"
                                width={20}
                                height={20}
                            />
                        )
                        : t(`${T_ACTIONS}.yes`),
                    isLoading: isLoading.value,
                }}
            />
        )
    );
};

PauseSoldSubscriptionDialog.propTypes = {
    visible: PropTypes.bool,
    soldSubscription: PropTypes.object,
    onClose: PropTypes.func,
};

PauseSoldSubscriptionDialog.defaultProps = {
    visible: false,
    soldSubscription: null,
    onClose: emptyFunc,
};

export default PauseSoldSubscriptionDialog;
