import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';

import * as SERVICES_ACTIONS from 'store/actions/services';
import WindowedSelect, { components } from 'react-windowed-select';
import TextButton from 'components/Common/TextButton/TextButton';
import { dangerTheme, theme } from 'styles/select';
import { selectWithGrayItems } from 'styles/selectStyles/selectWithGrayItems';
import { getAllSubscriptions } from 'store/actions';
import * as styles from './ProfileSubscriptionSelect.module.scss';

const ProfileSubscriptionSelect = ({ formik }) => {
    const dispatch = useDispatch();

    const {
        values, setFieldValue, errors, touched,
    } = formik;

    useEffect(() => {
        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [
        dispatch,
    ]);

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const [value, setValue] = useState(null);
    const [accessSubscriptions, setAccessSubscriptions] = useState([]);

    const selectSubscriptionsOptions = useMemo(() => [
        { value: null, label: '' },
        ...accessSubscriptions.map((subscription, index) => ({
            value: subscription.id,
            label: `${subscription.name} - ${subscription.price_formatted}`,
            index,
        })),
    ], [accessSubscriptions]);

    const valuesSubscriptionsIdsSet = useMemo(() => new Set(values?.subscriptions?.map((c) => c.id)), [values?.subscriptions?.length]);

    useEffect(() => {
        dispatch(getAllSubscriptions())
            .then((subscriptions) => setAccessSubscriptions(subscriptions || []));
    }, []);

    const isAllSubscriptionsSelected = values?.subscriptions.length === accessSubscriptions.length;

    const handleSetValues = useCallback((subscriptions) => {
        const values = subscriptions.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (subscriptions.length > 100) {
            values.push({ value: -1, label: tc('weekRoute.more', { count: subscriptions.length - 100 }) });
        }
        setValue(values);
    }, []);

    useEffect(() => {
        if (values.subscriptions.length) {
            handleSetValues(values.subscriptions);
        }
    }, [!!values.subscriptions.length]);

    const handleChangeAllSubscriptionsSelect = useCallback(({ clear }) => {
        let subscriptions = [];
        if (isAllSubscriptionsSelected || clear) {
            setFieldValue('subscriptions', subscriptions);
        } else {
            subscriptions = accessSubscriptions.map((c) => ({
                id: c.id,
                name: c.name,
            }));
            setFieldValue('subscriptions', subscriptions);
        }
        handleSetValues(subscriptions);
    }, [isAllSubscriptionsSelected, accessSubscriptions]);

    const handleChangeSubscriptionSelect = useCallback((subscription, selected) => {
        if (!subscription.value) {
            return;
        }
        let subscriptions = [];
        if (selected) {
            subscriptions = [...values.subscriptions, { id: subscription.value, name: subscription.label }];
            setFieldValue('subscriptions', subscriptions);
        } else {
            subscriptions = values.subscriptions.filter((c) => c.id !== subscription.value);
            setFieldValue('subscriptions', subscriptions);
        }

        handleSetValues(subscriptions);
    }, [values?.subscriptions.length]);

    const handleChangeSubscriptionsSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllSubscriptionsSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeSubscriptionSelect(triggeredAction.removedValue, false);
                return;
            }
            const subscriptions = values.subscriptions.slice(0, 100);
            setFieldValue('subscriptions', subscriptions);
            handleSetValues(subscriptions);
        }
        if (triggeredAction.action === 'deselect-option') {
            handleChangeSubscriptionSelect(triggeredAction.option, false);
        }
        if (triggeredAction.action === 'select-option') {
            handleChangeSubscriptionSelect(triggeredAction.option, true);
        }
    }, [handleChangeSubscriptionSelect, handleChangeAllSubscriptionsSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllSubscriptionsSelected) {
            e.preventDefault();
            setFieldValue('subscriptions', []);
        }
    }, [isAllSubscriptionsSelected]);

    const isSubscriptionsSelectInvalid = !!touched.subscriptions && !!errors.subscriptions;

    return (
        <>
            <WindowedSelect
                id="subscriptions"
                name="subscriptions"
                styles={selectWithGrayItems}
                theme={isSubscriptionsSelectInvalid ? dangerTheme : theme}
                options={selectSubscriptionsOptions}
                value={value}
                onChange={handleChangeSubscriptionsSelect}
                isMulti
                isClearable
                backspaceRemovesValue
                onKeyDown={onKeyDownHandle}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder={t('profiles.add.accessRules.subscriptions.placeholder')}
                components={{
                    MultiValue: values.subscriptions.length === accessSubscriptions.length
                        ? (props) => (
                            props.index === 0
                                ? t('profiles.add.accessRules.subscriptions.allSubscriptions.label')
                                : null
                        )
                    // eslint-disable-next-line react/no-unstable-nested-components
                        : (props) => <components.MultiValue {...props} />,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Option: ({
                        innerRef, innerProps, data,
                    }) => {
                        const isChecked = valuesSubscriptionsIdsSet.has(data.value);

                        return (
                            <Form.Group
                                ref={innerRef}
                                {...innerProps}
                                className={styles.option}
                            >
                                {data.value === null
                                    ? (
                                        <TextButton color="yellow" onClick={handleChangeAllSubscriptionsSelect}>
                                            {t('profiles.add.accessRules.subscriptions.allSubscriptions.label')}
                                        </TextButton>
                                    )
                                    : (
                                        <>
                                            <Form.Check
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={() => {}}
                                                className={styles.checkbox}
                                            />
                                            {`${data.label}`}
                                        </>
                                    )}
                            </Form.Group>
                        );
                    },
                }}
            />

            <Form.Control.Feedback
                className={isSubscriptionsSelectInvalid && 'd-block'}
                type="invalid"
            >
                {errors.subscriptions}
            </Form.Control.Feedback>
        </>
    );
};

export default ProfileSubscriptionSelect;
