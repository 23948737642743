import { call, takeEvery } from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

// Actions
import * as actions from '../actions/maps';

// Utils
import { geocode } from '../../services/geocoder';

export function* getAddressByCoordinates(action) {
    const { latitude, longitude } = action.payload;

    try {
        const { result } = yield geocode({
            location: {
                lat: latitude,
                lng: longitude,
            },
        });

        if (result.length !== 0) {
            let street = '';
            let streetNumber = '';
            let zip = '';
            let city = '';
            let country = '';
            result[0].address_components.map(({ long_name: longName, types }) => {
                types.map((type) => {
                    switch (type) {
                    case 'street_number':
                        streetNumber = longName;
                        break;
                    case 'route':
                        street = longName;
                        break;
                    case 'locality':
                        city = longName;
                        break;
                    case 'postal_code':
                        zip = longName;
                        break;
                    case 'country':
                        country = longName;
                        break;
                    }
                });
            });

            yield call(resolvePromiseAction, action, {
                street: `${street}${streetNumber ? ` ${streetNumber}` : ''}`,
                zip,
                city,
                country,
            });
            return;
        }

        yield call(resolvePromiseAction, action, {
            street: '',
            zip: '',
            city: '',
            country: '',
        });
    } catch (err) {
        yield call(rejectPromiseAction, action, err);
    }
}

export function* getCoordinatesByAddress(action) {
    const {
        street, zip, city, country,
    } = action.payload;

    try {
        const { result } = yield geocode({
            address: `${street}, ${zip} ${city}, ${country}`,
        });

        if (result.length !== 0) {
            yield call(resolvePromiseAction, action, {
                latitude: result[0].geometry.location.lat(),
                longitude: result[0].geometry.location.lng(),
            });
            return;
        }

        yield call(resolvePromiseAction, action, {
            latitude: null,
            longitude: null,
        });
    } catch (err) {
        yield call(rejectPromiseAction, action, err);
    }
}

export const mapsSaga = [
    takeEvery(actions.getAddressByCoordinates, getAddressByCoordinates),
    takeEvery(actions.getCoordinatesByAddress, getCoordinatesByAddress),
];
