import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { debounce } from 'lodash';
import { TailSpin } from 'react-loader-spinner';
import * as styles from './Button.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    outline: styles.outline,
    red: styles.red,
    green: styles.green,
};

const colorLoaderNames = {
    yellow: 'white',
    black: 'white',
    gray: 'black',
    outline: 'black',
    red: 'white',
    green: 'white',
};

const colorBeforeNames = {
    yellow: styles.beforeWhite,
    black: styles.beforeWhite,
    gray: styles.beforeBlack,
    outline: styles.beforeBlack,
    red: styles.beforeWhite,
    green: styles.beforeWhite,
};

const sizeClasses = {
    small: styles.small,
    medium: styles.medium,
    large: styles.large,
    'extra-large': styles.extraLarge,
};

const Button = (props) => {
    const isDisabledStyle = (props.disabled || props.loading);
    if (props.href) {
        return (
            <a
                className={
                    classNames(
                        props.className,
                        styles.button,
                        styles.link,
                        colorClasses[props.color],
                        isDisabledStyle ? styles.disabled : '',
                        props.stretched ? styles.stretched : '',
                        props.uppercase ? styles.uppercase : '',
                        props.group ? styles.group : '',
                        sizeClasses[props.size],
                    )
                }
                style={props.style}
                href={props.href}
                name={props.name}
                download={props.download}
                data-testid={props['data-testid']}
                form={props.form}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
                target={props.target}
                rel={props.rel}
            >
                {
                    props.loading ? (
                        <TailSpin
                            color={isDisabledStyle ? 'black' : colorLoaderNames[props.color]}
                            width={18}
                            height={18}
                        />
                    )
                        : (
                            <>
                                {props.before && (
                                    <div className={classNames(styles.before, colorBeforeNames[props.color])}>
                                        { props.before }
                                    </div>
                                )}
                                {props.children}
                            </>
                        )
                }
            </a>
        );
    }

    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    return (
        <button
            onClick={debouncedOnClick}
            disabled={props.disabled}
            type={props.type}
            style={props.style}
            name={props.name}
            data-testid={props['data-testid']}
            form={props.form}
            className={
                classNames(
                    props.className,
                    styles.button,
                    colorClasses[props.color],
                    isDisabledStyle ? styles.disabled : '',
                    props.stretched ? styles.stretched : '',
                    props.uppercase ? styles.uppercase : '',
                    props.group ? styles.group : '',
                    sizeClasses[props.size],
                )
            }
        >
            {
                props.loading ? (
                    <TailSpin
                        color={isDisabledStyle ? 'black' : colorLoaderNames[props.color]}
                        width={18}
                        height={18}
                    />
                )
                    : (
                        <>
                            {props.before && (
                                <div className={classNames(styles.before, colorBeforeNames[props.color])}>
                                    { props.before }
                                </div>
                            )}
                            {props.children}
                        </>
                    )
            }
        </button>
    );
};

Button.propTypes = {
    color: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    stretched: PropTypes.bool,
    group: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    size: PropTypes.string,
    download: PropTypes.string,
    'data-testid': PropTypes.string,
    form: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    uppercase: PropTypes.bool,
};

Button.defaultProps = {
    color: 'yellow',
    type: 'button',
    size: 'medium',
    uppercase: true,
    'data-testid': 'data-test-button',
};

export default Button;
