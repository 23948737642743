import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './Row.module.scss';

const paddingClasses = {
    0: styles.padding0,
    4: styles.padding4,
    8: styles.padding8,
    12: styles.padding12,
    16: styles.padding16,
};

const gapClasses = {
    0: styles.gap0,
    4: styles.gap4,
    8: styles.gap8,
    12: styles.gap12,
    16: styles.gap16,
    32: styles.gap32,
};

const Row = (props) => (
    <div
        name={props.name}
        className={
            classNames(
                styles.row,
                paddingClasses[props.padding],
                gapClasses[props.gap],
                props.stretched ? styles.stretched : '',
                props.spaceBetween ? styles.spaceBetween : '',
                props.noOverflow ? styles.noOverflow : '',
                (props.wrap) ? styles.wrap : '',
                styles[props.align],
            )
        }
    >
        {props.children}
    </div>
);

Row.propTypes = {
    padding: PropTypes.number,
    gap: PropTypes.number,
    stretched: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    wrap: PropTypes.bool,
    align: PropTypes.oneOf(['start', 'center', 'end']),
    noOverflow: PropTypes.bool,
};

Row.defaultProps = {
    gap: 8,
    padding: 0,
    stretched: false,
    spaceBetween: false,
    wrap: false,
    align: 'center',
    noOverflow: false,
};

export default Row;
