import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import SearchInput2 from 'components/Common/SearchInput2';
import { Button } from 'components/Common/Button';
import Container from 'components/Layout/Container/Container';
import { Row } from 'components/Common/Row';
import { useMobile } from 'hooks/useMobile';
import { Column } from 'components/Common/Column';
import SalesInfo from '../SalesInfo';
import { SubscriptionsSoldTitleFilter } from '../SubscriptionsSoldTitleFilter';
import { SubscriptionsSoldStatusFilter } from '../SubscriptionsSoldStatusFilter';

import * as styles from './SubscriptionsHeader.module.scss';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const buttonTitleOptions = {
    list: 'placeholders.addSubscription',
    sold: 'placeholders.sellSubscription',
};

const pageTitleOptions = {
    list: 'subscriptions.pageTitle.subscriptionsList',
    sold: 'subscriptions.pageTitle.soldSubscriptions',
};

function SubscriptionsHeader(props) {
    const {
        activeCount,
        totalMonthly,
        totalAnnual,
        showSalesInfo,
        searchValue,
        onSearch,
        onButtonClickHandler,
        exportButton,
        showSubscriptionsFilter,
        onIdFilterChange,
        onStatusFilterChange,
    } = props;
    const { t } = useTranslation();
    const { t: ts } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { pathname } = useLocation();
    const [currentSubRoute] = pathname.split('/').slice(-1);
    const isMobile = useMobile();

    return (
        <>
            <TextSubHeader
                text={t(pageTitleOptions[currentSubRoute])}
                after={!isMobile && <SearchInput2 searchValue={searchValue} onSearch={onSearch} />}
                rightActions={[exportButton,
                    !isMobile && (
                        <Button
                            onClick={onButtonClickHandler}
                        >
                            {t(buttonTitleOptions[currentSubRoute])}
                        </Button>
                    ),
                ]}
            />
            {(showSubscriptionsFilter || showSalesInfo) && (
                <Container>
                    {/* desktop version */}
                    <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <Column gap={32}>
                            <SalesInfo
                                activeCount={activeCount}
                                totalMonthly={totalMonthly}
                                totalAnnual={totalAnnual}
                                showSalesInfo={showSalesInfo}
                            />
                            {showSubscriptionsFilter && (
                                <Row gap={16}>
                                    <span className={styles.filterTitle}>
                                        {ts('sold.filters.title')}
                                    </span>
                                    <SubscriptionsSoldStatusFilter
                                        onChange={onStatusFilterChange}
                                    />
                                    <SubscriptionsSoldTitleFilter
                                        onChange={onIdFilterChange}
                                    />
                                </Row>
                            )}
                        </Column>
                    </div>

                    {/* mobile version */}
                    <div className="d-lg-none">
                        <SalesInfo
                            activeCount={activeCount}
                            totalMonthly={totalMonthly}
                            totalAnnual={totalAnnual}
                            showSalesInfo={showSalesInfo}
                        />
                    </div>
                </Container>
            )}
        </>
    );
}

SubscriptionsHeader.propTypes = {
    activeCount: PropTypes.number,
    totalMonthly: PropTypes.string,
    totalAnnual: PropTypes.string,
    showSalesInfo: PropTypes.bool,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    onButtonClickHandler: PropTypes.func,
    exportButton: PropTypes.node,
    showSubscriptionsFilter: PropTypes.bool,
    onIdFilterChange: PropTypes.func,
    onStatusFilterChange: PropTypes.func,
};

export default SubscriptionsHeader;
