import React from 'react';
import { ComingSoon } from 'components';

function Location() {
    return (
        <ComingSoon />
    );
}

export default Location;
