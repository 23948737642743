import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from 'react-bootstrap/FormControl';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './FormInput.module.scss';

const FormInput = (props, ref) => {
    const {
        id,
        name,
        value,
        className,
        onChange,
        ...restProps
    } = props;

    const handleBlur = useCallback((e) => {
        const { target: { value } } = e;
        // trim value
        onChange({ target: { name, value: value.trim() } });
    }, [onChange]);

    return (
        <FormControl
            id={id}
            ref={ref}
            name={name}
            value={value}
            className={classNames(
                styles.formInput,
                className,
            )}
            onBlur={handleBlur}
            onChange={onChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        />
    );
};

FormInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
};

FormInput.defaultProps = {
    id: '',
    name: '',
    value: '',
    className: '',
    onClick: emptyFunc,
    onChange: emptyFunc,
};

export default forwardRef(FormInput);
