import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DownloadIcon } from 'components/Icon/Icon';

import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { Tooltip } from '../../../../../Common/Tooltip';

import { formatPrice } from '../../../../../../helpers/formatters/formatPrice';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientInvoicesTableRow.module.scss';

const T_PREFIX = 'invoices.list.table.body';

const ClientSubscriptionsTableRow = (props) => {
    const {
        invoice,
        onDownload,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const [isDownloaded, setIsDownloaded] = useState(false);

    const dowloadIconClass = {
        [styles.hide]: isDownloaded,
        [styles.show]: !isDownloaded,
    };

    const dowloadHintClass = {
        [styles.hide]: !isDownloaded,
        [styles.show]: isDownloaded,
    };

    const downloadedHintDisappear = () => {
        setIsDownloaded(false);
    };
    const handleDownloadClick = () => {
        setIsDownloaded(true);
        setTimeout(downloadedHintDisappear, 3000);
        onDownload();
    };

    return (
        <>
            <ListTableBodyCell>
                {invoice.id}
            </ListTableBodyCell>
            <ListTableBodyCell className={styles.grayField}>
                {invoice.date}
            </ListTableBodyCell>
            <ListTableBodyCell className={styles.grayField} align="right">
                {formatPrice.toEuroWithComma({
                    amount: invoice.price,
                })}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={classNames(
                    styles.greenField,
                    styles.lastColumn,
                )}
                colSpan={2}
            >
                {t(`${T_PREFIX}.paymentStatus.${invoice.status}`)}

                <span
                    className={classNames(
                        styles.grayField,
                        dowloadHintClass,
                    )}
                >
                    {t(`${T_PREFIX}.download.downloaded`)}
                </span>

                <div
                    className={classNames(
                        styles.grayField,
                        styles.posAbsolute,
                        dowloadIconClass,
                    )}
                >
                    <a
                        download
                        onClick={handleDownloadClick}
                    >
                        <Tooltip
                            placement="top"
                            tooltip={t(`${T_PREFIX}.download.tooltip`)}
                        >
                            <span className={styles.downloadIcon}>
                                <DownloadIcon />
                            </span>
                        </Tooltip>
                    </a>
                </div>

            </ListTableBodyCell>
        </>
    );
};

ClientSubscriptionsTableRow.propTypes = {
    invoice: PropTypes.object,
    onDownload: PropTypes.func,
};

ClientSubscriptionsTableRow.defaultProps = {
    invoice: {
        id: 'ABF20222503',
        date: '15 Feb 2022',
        amount: '49.90',
        status: 'paid',
    },
    onDownload: emptyFunc,
};

export default ClientSubscriptionsTableRow;
