// extracted by mini-css-extract-plugin
var _1 = "Sidebar_localesDropdown__yzTyY";
var _2 = "Sidebar_objectsCollapse__7sMWZ";
var _3 = "Sidebar_shopsDropdown__NX1mO";
var _4 = "Sidebar_showSidebarControlButton__Aiyjt";
var _5 = "Sidebar_showSidebarControlWrapper__xqb9h";
var _6 = "Sidebar_sidebarBodyWrapper__hu+RP";
var _7 = "Sidebar_sidebarIsHidden__AuuM6";
var _8 = "Sidebar_sidebarWrapper__r8azj";
export { _1 as "localesDropdown", _2 as "objectsCollapse", _3 as "shopsDropdown", _4 as "showSidebarControlButton", _5 as "showSidebarControlWrapper", _6 as "sidebarBodyWrapper", _7 as "sidebarIsHidden", _8 as "sidebarWrapper" }
