import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const getAccessGates = createPromiseAction(actionTypes.GET_ACCESS_GATES);

export const getGatesForSelect = createPromiseAction(actionTypes.GET_GATES_FOR_SELECT);

export const setAccessGatesSortOptions = ({ sortOptions }) => ({
    type: actionTypes.ACCESS_GATES_SORT_OPTIONS_SET,
    payload: { sortOptions },
});

export const showAllAccessGates = () => ({
    type: actionTypes.ACCESS_GATES_SHOW_ALL,
});

export const setAccessGatesPage = ({ page }) => ({
    type: actionTypes.ACCESS_GATES_PAGE_SET,
    payload: { page },
});

export const setAccessGatesPageFirst = () => ({
    type: actionTypes.ACCESS_GATES_PAGE_SET_FIRST,
});

export const setAccessGatesPageLast = () => ({
    type: actionTypes.ACCESS_GATES_PAGE_SET_LAST,
});

export const setAccessGatesPagePrev = () => ({
    type: actionTypes.ACCESS_GATES_PAGE_SET_PREV,
});

export const setAccessGatesPageNext = () => ({
    type: actionTypes.ACCESS_GATES_PAGE_SET_NEXT,
});

export const clearAccessGates = () => ({
    type: actionTypes.ACCESS_GATES_CLEAR,
});

export const getAccessLogs = createPromiseAction(actionTypes.GET_ACCESS_LOGS);
export const appendAccessLog = createPromiseAction(actionTypes.APPEND_ACCESS_LOG);

export const setAccessLogsSortOptions = ({ sortOptions }) => ({
    type: actionTypes.ACCESS_LOGS_SORT_OPTIONS_SET,
    payload: { sortOptions },
});

export const showAllAccessLogs = () => ({
    type: actionTypes.ACCESS_LOGS_SHOW_ALL,
});

export const setAccessLogsPage = ({ page }) => ({
    type: actionTypes.ACCESS_LOGS_PAGE_SET,
    payload: { page },
});

export const setAccessLogsPageFirst = () => ({
    type: actionTypes.ACCESS_LOGS_PAGE_SET_FIRST,
});

export const setAccessLogsPageLast = () => ({
    type: actionTypes.ACCESS_LOGS_PAGE_SET_LAST,
});

export const setAccessLogsPagePrev = () => ({
    type: actionTypes.ACCESS_LOGS_PAGE_SET_PREV,
});

export const setAccessLogsPageNext = () => ({
    type: actionTypes.ACCESS_LOGS_PAGE_SET_NEXT,
});

export const clearAccessLogs = () => ({
    type: actionTypes.ACCESS_LOGS_CLEAR,
});

export const setAccessLogsSearch = ({ search }) => ({
    type: actionTypes.ACCESS_LOGS_SEARCH_SET,
    payload: { search },
});

export const setAccessLogsSelectedDate = ({ dateFrom, dateTo, period }) => ({
    type: actionTypes.ACCESS_LOGS_SELECTED_DATE_SET,
    payload: { dateFrom, dateTo, period },
});

export const setAccessLogsFilter = ({ filter }) => ({
    type: actionTypes.ACCESS_LOGS_FILTER_SET,
    payload: { filter },
});

export const setSelectedDate = createPromiseAction(actionTypes.GET_ACCESS_GATE);
export const getAccessGate = createPromiseAction(actionTypes.GET_ACCESS_GATE);
export const saveAccessGate = createPromiseAction(actionTypes.SAVE_ACCESS_GATE);
export const toggleStatusAccessGate = createPromiseAction(actionTypes.TOGGLE_STATUS_ACCESS_GATE);

export const getAccessStatistics = createPromiseAction(actionTypes.GET_ACCESS_STATISTICS);

export const settAccessStatisticsSelectedDate = ({ dateFrom, dateTo, period }) => ({
    type: actionTypes.ACCESS_LOGS_STATISTICS_SELECTED_DATE_SET,
    payload: { dateFrom, dateTo, period },
});

export const getAccessProfile = createPromiseAction(actionTypes.GET_ACCESS_PROFILE);
export const getAccessProfiles = createPromiseAction(actionTypes.GET_ACCESS_PROFILES);
export const saveAccessProfile = createPromiseAction(actionTypes.SAVE_ACCESS_PROFILE);
export const createAccessProfile = createPromiseAction(actionTypes.CREATE_ACCESS_PROFILE);
export const deleteAccessProfile = createPromiseAction(actionTypes.DELETE_ACCESS_PROFILE);
