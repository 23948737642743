import Invoices from './invoices';
import Payments from './payments';
import Payouts from './payouts';
import Clearings from './clearings';

const routes = [
    {
        component: Clearings,
        exact: true,
        path: '/financials/clearings',
    },
    {
        component: Invoices,
        exact: false,
        path: '/financials/invoices',
    },
    {
        component: Payments,
        exact: true,
        path: '/financials/payments',
    },
    {
        component: Payouts,
        exact: true,
        path: '/financials/payouts',
    },
];

export default routes;
