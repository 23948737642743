// extracted by mini-css-extract-plugin
var _1 = "AddClientsDialog_body__5NaXX";
var _2 = "AddClientsDialog_checkbox__c3p6W";
var _3 = "AddClientsDialog_clientsSelect__59mjh";
var _4 = "AddClientsDialog_footer__7RE9Q";
var _5 = "AddClientsDialog_grayColor__3REk7";
var _6 = "AddClientsDialog_messageName__SQn60";
var _7 = "AddClientsDialog_option__xSDXi";
var _8 = "AddClientsDialog_sendAlsoToEmailTooltip__66o0Z";
var _9 = "AddClientsDialog_yellowColor__R-kex";
export { _1 as "body", _2 as "checkbox", _3 as "clientsSelect", _4 as "footer", _5 as "grayColor", _6 as "messageName", _7 as "option", _8 as "sendAlsoToEmailTooltip", _9 as "yellowColor" }
