import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

export const useBool = (defaultValue) => {
    const [value, setValue] = useState(defaultValue);

    const onSet = useCallback(debounce((newValue) => {
        setValue(() => newValue);
    }, 200), []);

    const onTrue = useCallback(debounce(() => {
        setValue(() => true);
    }, 200), []);

    const onFalse = useCallback(debounce(() => {
        setValue(() => false);
    }, 200), []);

    const onToggle = useCallback(debounce(() => {
        setValue((p) => !p);
    }, 200), []);

    return {
        value,
        onSet,
        onTrue,
        onFalse,
        onToggle,
    };
};
