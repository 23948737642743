import { bundlesListSaga } from './bundlesList';
import { soldBundlesSaga } from './soldBundles';
import { bundleItemSaga } from './bundleItem';
import { companyBundlesSaga } from './companyBundles';

export const bundlesSaga = [
    ...bundlesListSaga,
    ...soldBundlesSaga,
    ...bundleItemSaga,
    ...companyBundlesSaga,
];
