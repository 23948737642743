import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { formatPrice } from 'helpers/formatters/formatPrice';
import { Row } from 'components/Common/Row';
import { Tooltip } from 'components/Common/Tooltip';
import { IconButton } from 'components/Common/IconButton';
import { DownloadIcon, Info2Icon } from 'components/Icon/Icon';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { useDialog } from '../../../../hooks/useDialog';

import { PayoutsDetailsDialog } from '../PayoutsDetailsDialog';

import * as PAYOUTS_ACTIONS from '../../../../store/actions/financials/payouts';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.table.body.rows';
const T_FIELDS = `${T_PREFIX}.fields`;

const PayoutsTableBodyRow = (props) => {
    const { payout } = props;
    const {
        id,
        invoiceNumber,
        transactionCost,
        transferred,
        invoicingSystem,
        created,
    } = payout || {};
    const { t } = useTranslation(LOCALE_NAMESPACE.PAYOUTS);

    const dispatch = useDispatch();

    const detailsDialog = useDialog(false);

    const payoutDateCreated = created && moment(created).isValid()
        ? moment(created).format('DD MMM YYYY HH:mm')
        : '-';

    const handleDownloadPayout = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.downloadPayout({ payoutId: id, system: invoicingSystem.toLowerCase() }));
    }, [id]);

    const showDownloadButton = invoiceNumber !== '-';

    return (
        <>
            <ListTableBodyCell
                className="text-muted"
            >
                {invoiceNumber}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
                align="right"
            >
                {formatPrice.toEuroWithComma({ amount: transactionCost }) }
            </ListTableBodyCell>
            {false && (
                <ListTableBodyCell
                    className="text-muted"
                    align="right"
                >
                    {formatPrice.toEuroWithComma({ amount: transferred }) }
                </ListTableBodyCell>
            )}
            <ListTableBodyCell
                className="text-muted"
            >
                {payoutDateCreated}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                <Row gap={8}>
                    {showDownloadButton && (
                        <Tooltip
                            tooltip={t(`${T_FIELDS}.actions.download.label`)}
                            placement="top"
                            forButton
                        >
                            <IconButton
                                onClick={handleDownloadPayout}
                                color="gray"
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip
                        tooltip={t(`${T_FIELDS}.actions.details.label`)}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            onClick={detailsDialog.onShow}
                            color="gray"
                        >
                            <Info2Icon />
                        </IconButton>
                    </Tooltip>
                </Row>
            </ListTableBodyCell>

            {detailsDialog.visible && (
                <PayoutsDetailsDialog
                    isVisible={detailsDialog.visible}
                    onClose={detailsDialog.onClose}
                    payoutId={id}
                />
            )}
        </>
    );
};

PayoutsTableBodyRow.propTypes = {
    payout: PropTypes.object,
};

PayoutsTableBodyRow.defaultProps = {
    payout: null,
};

export default PayoutsTableBodyRow;
