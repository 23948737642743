import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { useDialog } from '../../../hooks/useDialog';

import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import { Tooltip } from '../../Common/Tooltip';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './SoldSubscriptionPaymentTableCells.module.scss';

import { SOLD_SUBSCRIPTION_PAYMENT_STATUS } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_STATUS';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';
import { SOLD_SUBSCRIPTION_PAYMENT_TYPE } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_TYPE';
import { SOLD_SUBSCRIPTION_PAYMENT_STATUS_INFO } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_STATUS_INFO';
import { SOLD_SUBSCRIPTION_PAYNL_PAYMENT_TYPE } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYNL_PAYMENT_TYPE';
import { SOLD_SUBSCRIPTION_PAYNL_PAYMENT_ERROR_MESSAGE } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYNL_PAYMENT_ERROR_MESSAGE';

const T_PREFIX = 'sold.table.body.row.payments';

const DISPLAYABLE_PAYNL_PAYMENT_TYPE = {
    [SOLD_SUBSCRIPTION_PAYNL_PAYMENT_TYPE.FLEXIBLE_DIRECT_DEBIT]: 'SEPA',
    [SOLD_SUBSCRIPTION_PAYNL_PAYMENT_TYPE.MANDATE_CREATION]: 'SEPA',
    [SOLD_SUBSCRIPTION_PAYNL_PAYMENT_TYPE.IDEAL]: 'iDEAL',
};

const SoldSubscriptionPaymentTableCells = (props) => {
    const {
        payment,
        soldSubscription,
        onTogglePaymentStatus,
    } = props;

    const {
        paymentId,
        paymentInfo,
        paymentPrice,
        paymentStatus,
        paymentStartDate,
        paymentStatusInfo,
        paymentStatusTitle,
        paymentPaused,
        isManuallySet,
        payNlOrderId,
        payProPaymentHash,
        payNlPaymentType,
        errorMessage,
    } = payment || {};
    const { locale } = useSelector((state) => state.locales);

    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    const confirmationChangeModal = useDialog(false);

    const [confirmationChangeModalBodyText, setConfirmationChangeModalBodyText] = useState('');

    const status = useMemo(() => {
        if (
            payNlPaymentType?.toLowerCase() === SOLD_SUBSCRIPTION_PAYNL_PAYMENT_TYPE.FLEXIBLE_DIRECT_DEBIT.toLowerCase()
            && errorMessage === SOLD_SUBSCRIPTION_PAYNL_PAYMENT_ERROR_MESSAGE.NOT_ACTIVATED_BY_SUCCESSFUL_DIRECT_DEBIT
        ) {
            return SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING_2ND_RUN;
        }

        if (paymentPaused) {
            return SOLD_SUBSCRIPTION_PAYMENT_STATUS.PAUSED;
        }

        if (paymentStatusTitle === SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING) {
            return SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING;
        }
        return paymentStatus
            ? SOLD_SUBSCRIPTION_PAYMENT_STATUS.PAID
            : SOLD_SUBSCRIPTION_PAYMENT_STATUS.UNPAID;
    }, [paymentStatus, paymentStatusTitle, paymentPaused]);

    const isSubscriptionManuallySet = soldSubscription.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL;
    const isManuallySetPaid = status === SOLD_SUBSCRIPTION_PAYMENT_STATUS.PAID && isManuallySet;
    const paymentButtonClassName = useMemo(() => classNames(styles.statusButton, styles[status], isSubscriptionManuallySet && styles.manual), [status]);
    const unknownErrorText = t(`${T_PREFIX}.status.paymentInfo.error_unknown`);
    const displayablePaymentType = DISPLAYABLE_PAYNL_PAYMENT_TYPE[payNlPaymentType?.toLowerCase()] || DISPLAYABLE_PAYNL_PAYMENT_TYPE[paymentInfo?.toLowerCase()];

    const statusInfo = useMemo(() => {
        if (soldSubscription.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL) {
            return;
        }

        if (status === SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING_2ND_RUN) {
            return t(`${T_PREFIX}.status.paymentInfo.mandateNotReadyYet`);
        }

        if (
            status === SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING
            && payNlPaymentType?.toLowerCase() === SOLD_SUBSCRIPTION_PAYNL_PAYMENT_TYPE.MANDATE_CREATION.toLowerCase()
            && errorMessage
        ) {
            return errorMessage;
        }

        if (status !== SOLD_SUBSCRIPTION_PAYMENT_STATUS.UNPAID) {
            return paymentStatusInfo
                ? t(`${T_PREFIX}.status.paymentInfo.${paymentStatusInfo}`)
                : '';
        }

        if (paymentStatusInfo) {
            return t(`${T_PREFIX}.status.paymentInfo.firstPaymentNotPaid`);
        }

        if (Number(errorMessage)) {
            if (Number(errorMessage)) {
                return t([`${T_PREFIX}.status.paymentInfo.error_${errorMessage}`, unknownErrorText]);
            }
        }

        return unknownErrorText;
    }, [status, paymentStatusInfo, errorMessage, t]);

    const paymentStatusTooltipText = useMemo(() => {
        if (
            status !== SOLD_SUBSCRIPTION_PAYMENT_STATUS.UNPAID
            || paymentStatusInfo
        ) {
            return null;
        }

        if (Number(errorMessage)) {
            const unknownErrorText = t(`${T_PREFIX}.status.paymentInfo.error_unknown`);
            return statusInfo === unknownErrorText
                ? `${unknownErrorText} - ${errorMessage}`
                : null;
        }

        return errorMessage;
    }, [status, paymentStatusInfo, errorMessage, statusInfo, t]);

    const handleTogglePaymentStatus = useCallback(() => {
        if (
            !soldSubscription?.id
            || !paymentId
            || paymentStatusInfo === SOLD_SUBSCRIPTION_PAYMENT_STATUS_INFO.FIRST_PAYMENT
            || [SOLD_SUBSCRIPTION_PAYMENT_STATUS.PAUSED, SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING].includes(status)
        ) {
            return;
        }

        if (soldSubscription.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.AUTOMATIC) {
            if ((status === SOLD_SUBSCRIPTION_PAYMENT_STATUS.UNPAID || isManuallySetPaid)) {
                setConfirmationChangeModalBodyText(
                    t(`sold.dialogs.confirmationChangePaymentType.bodyText.automatic.${status}`),
                );
                confirmationChangeModal.onShow();
            }
            return;
        }

        setConfirmationChangeModalBodyText(
            t('sold.dialogs.confirmationChangePaymentType.bodyText.manual'),
        );
        confirmationChangeModal.onShow();
    }, [
        soldSubscription?.id,
        soldSubscription.paymentType,
        paymentId,
        onTogglePaymentStatus,
        paymentStatusInfo,
        status,
        t,
    ]);

    const confirmTogglePaymentStatus = () => {
        onTogglePaymentStatus({
            soldSubscriptionId: soldSubscription?.id,
            paymentId,
        });
        confirmationChangeModal.onClose();
    };

    return (
        <>
            <ListTableBodyCell colSpan={2} />
            <ListTableBodyCell className={styles.startDateCell}>
                {Boolean(paymentStartDate) && moment.unix(paymentStartDate).locale(locale).format('DD MMM YYYY')}
            </ListTableBodyCell>
            <ListTableBodyCell colSpan={2} />
            <ListTableBodyCell className={styles.priceCell}>
                {paymentPrice}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Tooltip
                    tooltip={paymentStatusTooltipText}
                    isDisabled={!paymentStatusTooltipText}
                >
                    <button
                        type="button"
                        className={paymentButtonClassName}
                        onClick={handleTogglePaymentStatus}
                    >
                        {t(`${T_PREFIX}.status.statuses.${status}`)}
                        {statusInfo && ` (${statusInfo})`}
                        {isManuallySetPaid && t(`${T_PREFIX}.status.paymentInfo.manuallySet`)}
                    </button>
                </Tooltip>

                <ConfirmationModal
                    size="xs"
                    isShow={confirmationChangeModal.visible}
                    bodyText={confirmationChangeModalBodyText}
                    hide={confirmationChangeModal.onClose}
                    deleteText={t('sold.dialogs.confirmationChangePaymentType.yes')}
                    dismissText={t('sold.dialogs.confirmationChangePaymentType.no')}
                    confirmAction={confirmTogglePaymentStatus}
                />
            </ListTableBodyCell>
            <ListTableBodyCell>
                {
                    (displayablePaymentType || payProPaymentHash || payNlOrderId)
                    && [displayablePaymentType, payProPaymentHash, payNlOrderId].map((str) => str ?? '-').join(' | ')
                }
            </ListTableBodyCell>

        </>
    );
};

SoldSubscriptionPaymentTableCells.propTypes = {
    payment: PropTypes.object,
    soldSubscription: PropTypes.object,
    onTogglePaymentStatus: PropTypes.func,
};

SoldSubscriptionPaymentTableCells.defaultProps = {
    payment: null,
    soldSubscription: null,
    onTogglePaymentStatus: emptyFunc,
};

export default SoldSubscriptionPaymentTableCells;
