import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AsyncSelect from 'react-select/async';
import { WindowedMenuList } from 'react-windowed-select';
import Row from 'react-bootstrap/Row';

import { emptyFunc } from 'helpers/function/emptyFunc';

import * as actions from '../../../../../store/actions';

import { LOCALE_NAMESPACE } from '../../../../../const/translations/LOCALE_NAMESPACE';

import { newSelectStyles, theme } from '../../../../../styles/select';
import * as styles from './ClientsShopSelect.module.scss';

const T_PREFIX = 'list.search.filters';

const ClientsShopSelect = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const dispatch = useDispatch();

    const {
        formik,
        onShopSelectChange,
    } = props;

    const {
        values,
        setFieldValue,
    } = formik;

    const ALL_SHOPS_OPTION = useMemo(() => ({
        value: '',
        label: t(`${T_PREFIX}.shop.all`),
    }), [t]);

    const onCreateOption = useCallback((label) => {
        setFieldValue('shop.value', null);
        setFieldValue('shop.label', label);
    }, [setFieldValue]);

    const loadOptions = useCallback(async (query) => {
        const detailes = await dispatch(actions.getCompanyDetails({ query }));
        const shopOptions = detailes.shops.map((shop) => ({
            value: `${shop.id}_${shop.name}`,
            label: shop.name,
        }));
        return [ALL_SHOPS_OPTION].concat(shopOptions);
    }, [dispatch]);

    const handleShopChange = useCallback((selected) => {
        if (!selected) {
            setFieldValue('shop.value', null);
            return setFieldValue('shop.label', null);
        }

        const { value, label } = selected;
        setFieldValue('shop.value', value ?? null);
        setFieldValue('shop.label', label ?? null);
        onShopSelectChange({ shopId: value });
    }, [setFieldValue]);

    return (
        <Row className={styles.filtersContainer}>
            <AsyncSelect
                name="shop"
                isSearchable={false}
                styles={newSelectStyles}
                className={styles.filter}
                theme={theme}
                placeholder={t(`${T_PREFIX}.shop.default`)}
                value={values.shop ? {
                    value: values?.shop?.value,
                    label: values?.shop?.label,
                } : {
                    value: '',
                    label: t(`${T_PREFIX}.shop.default`),
                }}
                onCreateOption={onCreateOption}
                onChange={handleShopChange}
                // eslint-disable-next-line react/no-unstable-nested-components
                formatOptionLabel={({ label }) => (
                    <span className="d-flex">
                        <span className="flex-grow-1">
                            {label}
                        </span>
                    </span>
                )}
                loadOptions={loadOptions}
                cacheOptions
                defaultOptions
                components={{ MenuList: WindowedMenuList }}
            />
        </Row>
    );
};

ClientsShopSelect.propTypes = {
    formik: PropTypes.object,
    onShopSelectChange: PropTypes.func,
};

ClientsShopSelect.defaultProps = {
    formik: null,
    onShopSelectChange: emptyFunc,
};

export default ClientsShopSelect;
