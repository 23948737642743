// extracted by mini-css-extract-plugin
var _1 = "ProfileBlock_address__6O5bs";
var _2 = "ProfileBlock_avatar__S5x2h";
var _3 = "ProfileBlock_avatarContainer__y9I0Q";
var _4 = "ProfileBlock_icon__wE00x";
var _5 = "ProfileBlock_profileInfoLines__h6Qr8";
var _6 = "ProfileBlock_profileLine__0S1Dq";
var _7 = "ProfileBlock_tooltip__e1ZpO";
var _8 = "ProfileBlock_wideContent__zoZXy";
export { _1 as "address", _2 as "avatar", _3 as "avatarContainer", _4 as "icon", _5 as "profileInfoLines", _6 as "profileLine", _7 as "tooltip", _8 as "wideContent" }
