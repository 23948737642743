import io from 'socket.io-client';

let socket;

export const openConnection = () => {
    socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}/admin`, {
        path: '/ws',
        transports: ['websocket'],
    });
};

export const getSocket = () => socket;
