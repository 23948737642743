/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import {
    Navbar, Nav, NavDropdown, Dropdown,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigationService from 'services/navigationService';
import * as actions from 'store/actions';

import { useMobile } from 'hooks/useMobile';
import classNames from 'classnames';
import { LocalesDropdown } from 'components';

import ProfileBadge from 'components/ProfileBadge/ProfileBadge';
import { ChevronDown } from 'react-feather';
import SettingsDropdownMenu from 'components/SettingsDropdownMenu/SettingsDropdownMenu';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { LogoutIcon } from 'components/Icon/Icon';
import * as styles from './MainNavigation.module.scss';
import { HEADER } from '../../../const/e2e/TEST_ATTRIBUTES';

const Item = ({ section, onClose }) => {
    const { t } = useTranslation();
    const isMobile = useMobile();
    const { pathname } = useLocation();
    const parentRoute = NavigationService.getParentRoute(pathname);

    const [opened, setOpened] = useState(`/${parentRoute}` === section.directsTo);
    const title = isMobile ? (
        <>
            <section.icon />
            {t(section.translation)}
        </>
    ) : t(section.translation);
    //
    if (!isMobile || !section.subRoutes?.length) {
        return (
            <Nav.Link
                as={NavLink}
                to={section.directsTo}
                exact={section.exact}
                data-testid={`${HEADER.agendaTabsSelector}_${section.translation}`}
                onClick={onClose}
            >
                {title}
            </Nav.Link>
        );
    }

    const handleToggle = (isOpen, e) => {
        if (e && e.target === e.currentTarget) {
            setOpened(isOpen);
        }
    };

    return (
        <NavDropdown
            title={title}
            id={section.directsTo}
            show={opened}
            onToggle={handleToggle}
        >
            {section.subRoutes.map((subRoute) => (
                <NavDropdown.Item
                    key={subRoute.directsTo}
                    as={NavLink}
                    to={subRoute.directsTo}
                    exact={subRoute.exact}
                    data-testid={`${HEADER.agendaTabsSelector}_${subRoute.translation}`}
                    onClick={onClose}
                >
                    {t(subRoute.translation)}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
};

function MainNavigation({
    role, sections, preset, productsList, onClose, isMobile,
}) {
    const mainNavigation = NavigationService.getMainNavigation(role, sections, preset, productsList, isMobile);

    return (
        <Navbar.Collapse className={classNames('flex-lg-row flex-column justify-content-center order-4 order-lg-0 align-self-stretch', styles.container)}>
            <Nav className={classNames('justify-content-lg-between w-100 align-self-stretch', styles.mobile)}>
                {mainNavigation.map((section) => (
                    <Item
                        key={section.directsTo}
                        section={section}
                        onClose={onClose}
                    />
                ))}
            </Nav>
            {isMobile
            && (
                <>
                    <Dropdown.Divider className={styles.divider} />
                    <Profile role={role} isMobile={isMobile} />
                    <LanguageSwitch />
                    <Logout />
                </>
            )}
        </Navbar.Collapse>
    );
}

const Profile = ({ role, isMobile }) => (
    <div className={styles.profile}>
        <ProfileDropdown role={role} isMobile={isMobile} onlyAvatar={false} />
    </div>
);

const Logout = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    return (
        <div
            className={styles.logout}
            data-testid={HEADER.logoutButtonSelector}
            onClick={() => {
                dispatch(actions.signOut());
                addToast(t('auth.signedOutSuccessfully'), {
                    appearance: 'success',
                });
            }}
        >
            <span>{t('dashboardRoute.logout')}</span>
            <LogoutIcon />
        </div>
    );
};

const LanguageSwitch = () => {
    const { t } = useTranslation();

    const [showLanguage, setShowLanguage] = useState(false);

    const handleToggleLanguage = (isOpen) => {
        setShowLanguage(isOpen);
    };

    return (
        <div className={styles.language} onClick={(e) => handleToggleLanguage(!showLanguage, e)}>
            <span>{t('navigation.language')}</span>
            <LocalesDropdown noOutline size={24} show={showLanguage} onToggle={handleToggleLanguage} drop="up" />
        </div>
    );
};

export const ProfileDropdown = ({ role, isMobile, onlyAvatar }) => (
    <Dropdown className="align-self-stretch" alignRight>
        <Dropdown.Toggle as={ProfileDropdownButton} disabled={isMobile}>
            <ProfileBadge role={role} size={isMobile ? 42 : 44} onlyAvatar={onlyAvatar} />

            <ChevronDown
                className="ml-2 text-light-gray d-none d-lg-block"
                data-testid={HEADER.openDropDownArrowSelector}
            />
        </Dropdown.Toggle>

        <SettingsDropdownMenu />
    </Dropdown>
);

const ProfileDropdownButton = React.forwardRef(({ children, onClick, disabled }, ref) => (
    <button data-testid="profile-dropdown" ref={ref} onClick={onClick} className={classNames('d-flex align-items-center h-100 border-0 bg-transparent', disabled && styles.noPointerEvents)}>
        {children}
    </button>
));

export default MainNavigation;
