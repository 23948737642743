import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './Panel.module.scss';

const paddingClasses = {
    12: styles.padding12,
    16: styles.padding16,
    24: styles.padding24,
    32: styles.padding32,
};

const gapClasses = {
    0: styles.gap0,
    4: styles.gap4,
    8: styles.gap8,
    12: styles.gap12,
    16: styles.gap16,
    24: styles.gap24,
    32: styles.gap32,
};

const Panel = (props) => (
    <div
        name={props.name}
        className={
            classNames(
                styles.panel,
                paddingClasses[props.padding],
                gapClasses[props.gap],
                props.stretched ? styles.stretched : '',
                styles[props.color],
            )
        }
    >
        {props.children}
    </div>
);

Panel.propTypes = {
    padding: PropTypes.number,
    gap: PropTypes.number,
    stretched: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary']),
};

Panel.defaultProps = {
    gap: 32,
    padding: 32,
    stretched: false,
    color: 'primary',
};

export default Panel;
