export const ADD_TIME_BLOCK = 'ADD_TIME_BLOCK';
export const ADD_TIME_BLOCK_SUCCEEDED = 'ADD_TIME_BLOCK_SUCCEEDED';

export const PRODUCTS_GET_BY_ID = 'products/GET_BY_ID';
export const PRODUCTS_GET_BY_ID_SUCCESS = 'products/GET_BY_ID_SUCCESS';
export const PRODUCTS_GET_BY_ID_ERROR = 'products/GET_BY_ID_ERROR';

export const DELETE_TIME_BLOCK = 'DELETE_TIME_BLOCK';
export const DELETE_TIME_BLOCK_SUCCEEDED = 'DELETE_TIME_BLOCK_SUCCEEDED';

export const EDIT_TIME_BLOCK = 'EDIT_TIME_BLOCK';
export const EDIT_TIME_BLOCK_SUCCEEDED = 'EDIT_TIME_BLOCK_SUCCEEDED';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_TRIGGER = `${GET_PRODUCTS}.TRIGGER`;
export const GET_PRODUCTS_RESOLVED = `${GET_PRODUCTS}.RESOLVED`;
export const GET_PRODUCTS_REJECTED = `${GET_PRODUCTS}.REJECTED`;

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_TRIGGER = `${ADD_PRODUCT}.TRIGGER`;
export const ADD_PRODUCT_RESOLVED = `${ADD_PRODUCT}.RESOLVED`;
export const ADD_PRODUCT_REJECTED = `${ADD_PRODUCT}.REJECTED`;
