import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { handleKeyDownOnNumberInput } from 'helpers/input/handleKeyDownOnNumberInput';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './BundleMaxUsagesFormRow.module.scss';

const T_PREFIX = 'addOrEdit.form.fields.maxUsages';

const BundleMaxUsagesFormRow = (props) => {
    const {
        sold,
        values,
        validation,
        onIncMaxUsagesValue,
        onDecMaxUsagesValue,
        onChangeMaxUsagesValue,
        onToggleMaxUsagesUnlimited,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <Row>
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label>
                        {t(`${T_PREFIX}.value.label`)}
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            name="maxUsages.value"
                            value={values.maxUsages.value}
                            disabled={values.maxUsages.unlimited || sold}
                            isInvalid={validation?.maxUsages?.value?.isInvalid}
                            onChange={onChangeMaxUsagesValue}
                            onKeyDown={handleKeyDownOnNumberInput}
                        />
                        <InputGroup.Append>
                            <Col className="m-0 p-0 border border-light rounded-right">
                                <button
                                    type="button"
                                    disabled={values.maxUsages.unlimited || sold}
                                    className={styles.incDecButton}
                                    onClick={onIncMaxUsagesValue}
                                >
                                    <ChevronUp size={16} />
                                </button>
                                <button
                                    type="button"
                                    disabled={values.maxUsages.unlimited || sold}
                                    className={styles.incDecButton}
                                    onClick={onDecMaxUsagesValue}
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </Col>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.maxUsages?.value?.isInvalid,
                        })}
                    >
                        {validation?.maxUsages?.value?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col
                xs={12}
                lg={8}
                className="m-0 p-0 align-self-start"
            >
                <Form.Group
                    as={Col}
                    className={styles.checkbox}
                >
                    <Form.Check
                        id="maxUsages.unlimited"
                        name="maxUsages.unlimited"
                        label={t(`${T_PREFIX}.unlimited.label`)}
                        checked={values.maxUsages.unlimited}
                        disabled={sold}
                        onChange={onToggleMaxUsagesUnlimited}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.maxUsages?.unlimited?.isInvalid,
                        })}
                    >
                        {validation?.maxUsages?.unlimited?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

BundleMaxUsagesFormRow.propTypes = {
    sold: PropTypes.bool,
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onIncMaxUsagesValue: PropTypes.func,
    onDecMaxUsagesValue: PropTypes.func,
    onChangeMaxUsagesValue: PropTypes.func,
    onToggleMaxUsagesUnlimited: PropTypes.func,
};

BundleMaxUsagesFormRow.defaultProps = {
    sold: false,
    validation: null,
    onIncMaxUsagesValue: emptyFunc,
    onDecMaxUsagesValue: emptyFunc,
    onChangeMaxUsagesValue: emptyFunc,
    onToggleMaxUsagesUnlimited: emptyFunc,
};

export default BundleMaxUsagesFormRow;
