import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { instantFormValidation } from 'helpers/input/instantFieldValidation';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as COMPANY_SELECTORS from 'store/selectors/company';

import {
    styles as selectStyles,
    theme as selectTheme,
} from 'styles/select';

import NumberInputWithControls from 'components/NumberInputWithControls';
import { getError, isInvalid } from 'services/validationService';
import { Trash3Icon } from 'components/Icon/Icon';
import { getVATRateSelectOptions } from 'components/subscriptions/SubscriptionPricingFormGroup/SubscriptionPricingFormGroup';
import { useSelector } from 'react-redux';
import { handleKeyDownOnPriceInput } from 'helpers/input/handleKeyDownOnNumberInput';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { CHAR_SYMBOL } from 'const/string/CHAR_SYMBOL';
import ProductsSelect from 'components/products/ProductsSelect/ProductsSelect';
import { productsListSelector } from 'store/selectors/products';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { IconButton } from 'components/Common/IconButton';

const isSame = (initialProduct, product) => initialProduct && product
    && initialProduct.name === product.name
    && initialProduct.price === product.price
    && initialProduct.vatValue === product.vatValue;

function InvoiceProductFormGroup(props) {
    const {
        index,
        product,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        onDelete,
        isDeleteDisabled,
    } = props;

    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);
    const products = useSelector(productsListSelector);

    const initialProductData = useRef(null);

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    useEffect(() => {
        const initialProduct = initialProductData.current;

        if (initialProduct) {
            if (String(product.id).startsWith('new-') && isSame(initialProduct, product)) {
                setFieldValue(`products[${index}].id`, initialProduct.id);
                return;
            }

            if (!String(product.id).startsWith('new-') && !isSame(initialProduct, product)) {
                setFieldValue(`products[${index}].id`, `new-${initialProduct.id}`);
                return;
            }
        }

        if (!String(product.id).startsWith('new-')) {
            initialProductData.current = product;
        }
    }, [product]);

    const handleQuantityChange = useCallback((e) => {
        const { target } = e;
        const { value } = target;
        const muv = Math.floor(Math.min(Math.max(INVOICE_PROP.QUANTITY.MIN, value), INVOICE_PROP.QUANTITY.MAX));
        setFieldValue(`products[${index}].quantity`, muv);
    }, [setFieldValue, setFieldTouched]);

    const handlePriceChange = useCallback((e) => {
        const { target } = e;
        const { value } = target;
        if ([
            CHAR_SYMBOL.MINUS,
            CHAR_SYMBOL.PRICE.SEPARATOR.DOT,
        ].includes(value)) {
            return;
        }
        if (value === '') {
            setFieldValue(`products[${index}].price`, '');
            return;
        }
        const val = Math.min(Math.max(INVOICE_PROP.PRICE.MIN, value), INVOICE_PROP.PRICE.MAX);
        const muv = Math.trunc((val * 100).toFixed(2)) / 100;
        setFieldValue(`products[${index}].price`, muv);
    }, [setFieldValue, setFieldTouched]);

    const handleDelete = useCallback(() => {
        onDelete({ id: product.id, index });
    }, [onDelete, product.id, index]);

    const handleVATChange = useCallback((selected) => {
        setFieldValue(`products[${index}].vatValue`, selected.value);
    }, [setFieldValue, setFieldTouched, index]);

    const validationHandler = instantFormValidation({ setFieldTouched, touched });

    return (
        <Row
            className="mt-3 pb-3"
        >
            <Form.Group
                as={Col}
                sm={12}
                xl={4}
            >
                <ProductsSelect
                    product={product}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    products={products}
                    index={index}
                />
            </Form.Group>

            <Col>
                <Row>
                    <Form.Group
                        as={Col}
                    >
                        <Form.Label htmlFor={`quantity-${index}`} className="font-size-15">
                            {t('add.products.quantity.label')}
                        </Form.Label>
                        <NumberInputWithControls
                            id={`quantity-${index}`}
                            value={product.quantity}
                            customOnChange={validationHandler(handleQuantityChange)}
                            fieldValueKey={`products[${index}].quantity`}
                            isInvalid={isInvalid(`products[${index}].quantity`, errors, touched)}
                            error={getError(`products[${index}].quantity`, errors)}
                        />
                    </Form.Group>

                    <Form.Group
                        as={Col}
                    >
                        <Form.Label htmlFor={`price-${index}`} className="font-size-15">
                            {t('add.products.price.label')}
                        </Form.Label>
                        <Form.Control
                            id={`price-${index}`}
                            type="number"
                            name="price"
                            value={product.price}
                            isInvalid={isInvalid(`products[${index}].price`, errors, touched)}
                            onChange={validationHandler(handlePriceChange)}
                            onKeyDown={handleKeyDownOnPriceInput}
                        />

                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': isInvalid(`products[${index}].price`, errors, touched),
                            })}
                        >
                            {getError(`products[${index}].price`, errors)}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Col>
            <Form.Group
                as={Col}
                sm={12}
                lg={3}
                xl={2}
            >
                <Form.Label htmlFor={`vatValue-${index}`} className="font-size-15">
                    {t('add.products.vatValue.label')}
                </Form.Label>
                <Select
                    id={`vatValue-${index}`}
                    styles={selectStyles}
                    theme={selectTheme}
                    name="vatValue"
                    value={getVATRateSelectOptions(enableBusinessMode).find(({ value }) => value === product.vatValue)}
                    options={getVATRateSelectOptions(enableBusinessMode)}
                    onChange={handleVATChange}
                />
            </Form.Group>
            <Col
                sm={12}
                lg={3}
                xl={2}
                className="d-flex mt-lg-4"
                style={{ paddingTop: 10 }}
            >
                <div className="flex-grow-1">
                    <div className="d-flex flex-column">
                        <Form.Label htmlFor={`total-${index}`} style={{ lineHeight: 1 }} className="font-size-14 text-muted m-0 font-weight-500">
                            {t('add.products.total.label')}
                        </Form.Label>
                        <span id={`total-${index}`} className="font-size-15 font-weight-600">
                            {formatPrice.toEuroWithComma({ amount: (Number(product.price) * Number(product.quantity)) })}
                        </span>
                    </div>
                </div>
                <IconButton
                    type="button"
                    color="gray"
                    onClick={handleDelete}
                    size={36}
                    disabled={isDeleteDisabled}
                >
                    <Trash3Icon />
                </IconButton>
            </Col>
        </Row>
    );
}

export default InvoiceProductFormGroup;
