import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = (props) => {
    const { className } = props;

    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" className={className}>
            <g clipPath="url(#clip0_7510_132050)">
                <path d="M4.38907 15.3721L3.33426 19.9245C3.29787 20.0909 3.29912 20.2633 3.33791 20.4292C3.3767 20.5951 3.45205 20.7502 3.55847 20.8832C3.66488 21.0162 3.79966 21.1238 3.95297 21.198C4.10628 21.2723 4.27424 21.3114 4.44458 21.3124C4.52395 21.3204 4.60393 21.3204 4.6833 21.3124L9.26339 20.2576L18.0572 11.4971L13.1495 6.60059L4.38907 15.3721Z" fill="currentColor" />
                <path d="M20.8217 7.10999L17.5463 3.83453C17.3309 3.62028 17.0395 3.5 16.7357 3.5C16.4319 3.5 16.1405 3.62028 15.9252 3.83453L14.1042 5.65546L19.0063 10.5575L20.8273 8.73661C20.9338 8.62951 21.0182 8.50245 21.0756 8.36269C21.133 8.22294 21.1623 8.07323 21.1618 7.92215C21.1613 7.77107 21.131 7.62157 21.0726 7.48221C21.0143 7.34285 20.929 7.21636 20.8217 7.10999Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_7510_132050">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
};

EditIcon.propTypes = {
    className: PropTypes.string,
};

EditIcon.defaultProps = {
    className: '',
};

export default EditIcon;
