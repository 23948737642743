import moment from 'moment';
import * as INVOICES_TYPES from '../../actions/financials/invoices/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    totalRevenue: {},
    appyBeePay: {},
    manual: {},
    filters: {
        from: moment().startOf('month').format('X'),
        to: moment().endOf('month').format('X'),
    },
    search: '',
    exportEmail: '',
    exportPeriod: 'month',
    isExportInProgress: false,

    saving: false,
    customInvoicesLoading: false,
    customInvoices: [],

    customInvoiceLoading: false,
    customInvoice: null,
};

export const invoicesListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (INVOICES_TYPES.INVOICES_SET): {
        const {
            invoices,
            itemsCount,
            pagesCount,
            totalRevenue,
            appyBeePay,
            manual,
            exportEmail,
        } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: invoices,
            page,
            itemsCount,
            pagesCount,
            totalRevenue,
            appyBeePay,
            manual,
            exportEmail,
        };
    }
    case (INVOICES_TYPES.INVOICES_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (INVOICES_TYPES.INVOICES_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (INVOICES_TYPES.INVOICES_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (INVOICES_TYPES.INVOICES_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (INVOICES_TYPES.INVOICES_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (INVOICES_TYPES.INVOICES_SEARCH_SET): {
        const { search } = payload;
        return {
            ...state,
            search,
        };
    }
    case (INVOICES_TYPES.INVOICES_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    case (INVOICES_TYPES.INVOICES_FILTERS_SET): {
        const { filters } = payload;

        const newFilters = {
            ...state.filters,
            ...filters,
        };

        let hasNewFilters = false;
        Object.keys(filters).forEach((key) => {
            if (state.filters[key] !== filters[key]) {
                hasNewFilters = true;
            }

            if (!filters[key] && filters[key] !== 0) {
                delete newFilters[key];
            }
        });

        return {
            ...state,
            filters: hasNewFilters ? newFilters : state.filters,
        };
    }
    case INVOICES_TYPES.INVOICES_FILTERS_CLEAR: {
        return {
            ...state,
            filters: INITIAL_STATE.filters,
            search: '',
        };
    }
    case INVOICES_TYPES.INVOICES_EXPORT_EMAIL_SET: {
        const { email } = payload;

        return {
            ...state,
            exportEmail: email,
        };
    }
    case INVOICES_TYPES.INVOICES_EXPORT_PERIOD_SET: {
        const { exportPeriod } = payload;

        return {
            ...state,
            exportPeriod,
        };
    }
    case INVOICES_TYPES.INVOICES_EXPORT_IN_PROGRESS: {
        const { isExportInProgress } = payload;

        return {
            ...state,
            isExportInProgress,
        };
    }

    case INVOICES_TYPES.EDIT_CUSTOM_INVOICE_TRIGGER:
    case INVOICES_TYPES.ADD_CUSTOM_INVOICE_TRIGGER: {
        return {
            ...state,
            saving: true,
        };
    }
    case INVOICES_TYPES.EDIT_CUSTOM_INVOICE_RESOLVED:
    case INVOICES_TYPES.EDIT_CUSTOM_INVOICE_REJECTED:
    case INVOICES_TYPES.ADD_CUSTOM_INVOICE_RESOLVED:
    case INVOICES_TYPES.ADD_CUSTOM_INVOICE_REJECTED: {
        return {
            ...state,
            saving: false,
        };
    }
    case INVOICES_TYPES.GET_CUSTOM_INVOICES_TRIGGER: {
        return {
            ...state,
            customInvoicesLoading: true,
        };
    }
    case INVOICES_TYPES.GET_CUSTOM_INVOICES_RESOLVED: {
        return {
            ...state,
            customInvoicesLoading: false,
            customInvoices: payload,
        };
    }
    case INVOICES_TYPES.GET_CUSTOM_INVOICES_REJECTED: {
        return {
            ...state,
            customInvoicesLoading: false,
        };
    }
    case INVOICES_TYPES.GET_CUSTOM_INVOICE_TRIGGER: {
        return {
            ...state,
            customInvoiceLoading: true,
        };
    }
    case INVOICES_TYPES.GET_CUSTOM_INVOICE_RESOLVED: {
        return {
            ...state,
            customInvoiceLoading: false,
            customInvoice: payload,
        };
    }
    case INVOICES_TYPES.GET_CUSTOM_INVOICE_REJECTED: {
        return {
            ...state,
            customInvoiceLoading: false,
        };
    }
    default:
        return state;
    }
};
