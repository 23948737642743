import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const getGroups = () => ({
    type: actionTypes.GET_GROUPS,
});
export const getGroupsSucceeded = (groups) => ({
    type: actionTypes.GET_GROUPS_SUCCEEDED,
    groups,
});
export const getGroupsFailed = () => ({
    type: actionTypes.GET_GROUPS_FAILED,
});

export const getGroupSessions = (from, to, silent = false) => ({
    type: actionTypes.GET_GROUP_SESSIONS,
    from,
    to,
    silent,
});
export const getGroupSessionsSucceeded = (groupSessions) => ({
    type: actionTypes.GET_GROUP_SESSIONS_SUCCEEDED,
    groupSessions,
});
export const getGroupSessionsFailed = () => ({
    type: actionTypes.GET_GROUP_SESSIONS_FAILED,
});

export const setGroupsFilter = (id, visible) => ({
    type: actionTypes.SET_GROUPS_FILTER,
    id,
    visible,
});

export const getGroupSessionBookings = (sessionID, time) => ({
    type: actionTypes.GET_GROUP_SESSION_BOOKINGS,
    sessionID,
    time,
});
export const getGroupSessionBookingsSucceeded = (bookings) => ({
    type: actionTypes.GET_GROUP_SESSION_BOOKINGS_SUCCEEDED,
    bookings,
});
export const getGroupSessionBookingsFailed = () => ({
    type: actionTypes.GET_GROUP_SESSION_BOOKINGS_FAILED,
});

export const addGroup = createPromiseAction(actionTypes.ADD_GROUP);
export const addGroupSucceeded = (group) => ({
    type: actionTypes.ADD_GROUP_SUCCEEDED,
    group,
});
export const addGroupFailed = () => ({
    type: actionTypes.ADD_GROUP_FAILED,
});

export const editGroup = createPromiseAction(actionTypes.EDIT_GROUP);
export const editGroupSucceeded = (group) => ({
    type: actionTypes.EDIT_GROUP_SUCCEEDED,
    group,
});
export const editGroupFailed = () => ({
    type: actionTypes.EDIT_GROUP_FAILED,
});

export const overrideGroupSession = createPromiseAction(actionTypes.OVERRIDE_GROUP_SESSION);
export const overrideGroupSessionSucceeded = (id, timestamp, maxPeople) => ({
    type: actionTypes.OVERRIDE_GROUP_SESSION_SUCCEEDED,
    payload: { id, timestamp, maxPeople },
});
export const overrideGroupSessionFailed = () => ({
    type: actionTypes.OVERRIDE_GROUP_SESSION_FAILED,
});

export const cancelGroupSession = createPromiseAction(actionTypes.CANCEL_GROUP_SESSION);
export const cancelGroupSessionSucceeded = (id, timestamp) => ({
    type: actionTypes.CANCEL_GROUP_SESSION_SUCCEEDED,
    payload: { id, timestamp },
});
export const cancelGroupSessionFailed = () => ({
    type: actionTypes.OVERRIDE_GROUP_SESSION_FAILED,
});

export const deleteGroupSession = createPromiseAction(actionTypes.DELETE_GROUP_SESSION);
export const deleteGroupSessionSucceeded = (id) => ({
    type: actionTypes.DELETE_GROUP_SESSION_SUCCEEDED,
    payload: id,
});
export const deleteGroupSessionFailed = () => ({ type: actionTypes.DELETE_GROUP_SESSION_FAILED });
