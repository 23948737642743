import * as CLIENTS_TYPES from './types';

export const getClientBundles = ({ clientId }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_GET,
    payload: { clientId },
});

export const setClientBundles = ({
    bundles, itemsCount, pagesCount,
}) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_SET,
    payload: {
        bundles,
        itemsCount,
        pagesCount,
    },
});

export const editClientBundlesItem = ({ bundleId, bundle }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_EDIT,
    payload: {
        bundleId,
        bundle,
    },
});

export const archiveClientBundlesItem = ({ bundleId }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_ARCHIVE,
    payload: { bundleId },
});

export const setClientBundlesPage = ({ page }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_PAGE_SET,
    payload: { page },
});

export const setClientBundlesPageFirst = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_PAGE_SET_FIRST,
});

export const setClientBundlesPageLast = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_PAGE_SET_LAST,
});

export const setClientBundlesPagePrev = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_PAGE_SET_PREV,
});

export const setClientBundlesPageNext = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_PAGE_SET_NEXT,
});

export const setClientBundlesLoading = ({ loading }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_LOADING_SET,
    payload: { loading },
});

export const hideArchivedToggle = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_HIDE_ARCHIVED_TOGGLE,
});
