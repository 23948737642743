import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components/Common/Avatar';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Badge } from 'components/Common/Badge';
import { useTranslation } from 'react-i18next';
import * as styles from './ClientNoteAuthor.module.scss';

const ClientNoteAuthor = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    return (
        <div className={styles.container}>
            <Avatar src={props.avatarUrl} size={40} />
            <div className={styles.text}>
                <span className={styles.name}>
                    {props.name}
                </span>
                ·
                <span className={styles.date}>
                    {props.date}
                </span>
            </div>
            {props.editor && <Badge color="gray" size="small">{t('notes.note.edited.label')}</Badge>}
        </div>
    );
};

ClientNoteAuthor.propTypes = {
    avatarUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    editor: PropTypes.bool,
};

ClientNoteAuthor.defaultProps = {
    editor: false,
};

export default ClientNoteAuthor;
