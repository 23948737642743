import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { Button } from 'components/Common/Button';
import { useBool } from '../../../hooks/useBool';

import { Calendar } from '../../Icon/Icon';

import DateInput from '../../DateInput/DateInput';

import { getError, isInvalid } from '../../../services/validationService';

import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { generateRandomString } from '../../../helpers/string/generateRandomString';

import * as SUBSCRIPTIONS_ACTIONS from '../../../store/actions/subscriptions';

import * as SUBSCRIPTIONS_SELECTORS from '../../../store/selectors/subscriptions';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';
import { SOLD_SUBSCRIPTION_PROP } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PROP';

const T_PREFIX = 'sold.dialogs.resume';
const T_BODY = `${T_PREFIX}.body`;
const T_FIELDS = `${T_BODY}.form.fields`;

const initialValues = {
    startDate: moment()
        .utc()
        .add(2, 'month')
        .startOf('month'),
};

const DATA_TRANSFORMER = {
    send: (data) => {
        const formData = new FormData();
        formData.append('startDate', data.startDate.unix());
        return formData;
    },
};

const ResumeSoldSubscriptionDialog = (props) => {
    const {
        visible,
        soldSubscription,
        onClose,
    } = props;

    const {
        id: soldSubscriptionId,
        pause,
    } = soldSubscription || {};

    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    const hasErrors = useBool(false);

    const dispatch = useDispatch();
    const soldSubscriptions = useSelector(SUBSCRIPTIONS_SELECTORS.soldSubscriptionsListSelector);

    const validationSchema = useMemo(() => (
        yup.object({
            startDate: yup
                .mixed()
                .required(t(`${T_FIELDS}.startDate.validation.required`))
                .test({
                    name: generateRandomString(),
                    test: (startDate) => {
                        if (!pause?.from) {
                            return true;
                        }
                        const diff = pause.from - startDate.unix();
                        return diff <= 1;
                    },
                    message: t(`${T_FIELDS}.startDate.validation.dateOrAfter`, {
                        date: moment.unix(pause?.from)
                            .format('DD.MM.YY'),
                    }),
                })
                .test({
                    name: generateRandomString(),
                    test: (startDate) => {
                        const today = moment().startOf('day');
                        const diff = startDate
                            .clone()
                            .diff(today, 'day');
                        return diff >= SOLD_SUBSCRIPTION_PROP.RESUME.DAYS_BEFORE_NEXT_MONTH;
                    },
                    message: () => {
                        const today = moment().startOf('day');
                        const nextDebit = moment()
                            .add(1, 'month')
                            .startOf('month')
                            .add(-SOLD_SUBSCRIPTION_PROP.RESUME.DAYS_BEFORE_NEXT_MONTH + 1, 'day');
                        const startDateMonthsDiff = today.isBefore(nextDebit) ? 1 : 2;
                        return t(`${T_FIELDS}.startDate.validation.dateOrAfter`, {
                            date: moment()
                                .add(startDateMonthsDiff, 'month')
                                .startOf('month')
                                .format('DD.MM.YY'),
                        });
                    },
                })
                .test({
                    name: generateRandomString(),
                    test: (startDate) => {
                        // for some reason date.clone().startOf()
                        // sometimes changes original date by 1 sec
                        const timeStamp1 = startDate.unix();
                        const timeStamp2 = startDate.clone().startOf('month').unix();

                        const diff = timeStamp1 - timeStamp2;

                        return diff <= 1;
                    },
                    message: t(`${T_FIELDS}.startDate.validation.startOfMonth`),
                }),
        })
    ), [pause?.from, t]);

    const handleSuccess = useCallback((data) => {
        const { startDate: endDate } = data;

        if (!soldSubscriptionId) {
            return;
        }
        const soldSubscriptionIndex = soldSubscriptions.findIndex((s) => String(s.id) === String(soldSubscriptionId));
        if (soldSubscriptionIndex < 0) {
            return;
        }
        const soldSubscriptionsCopy = soldSubscriptions.slice();
        const isSameDay = endDate.unix() === soldSubscriptionsCopy[soldSubscriptionIndex].pause.from;
        const status = isSameDay ? false : soldSubscriptionsCopy[soldSubscriptionIndex].pause.status;
        const from = isSameDay ? null : soldSubscriptionsCopy[soldSubscriptionIndex].pause.from;
        const to = isSameDay ? null : endDate.clone().add(-1, 'day').startOf('day').unix();
        soldSubscriptionsCopy[soldSubscriptionIndex] = {
            ...soldSubscriptionsCopy[soldSubscriptionIndex],
            pause: {
                status,
                from,
                to,
                unlimited: false,
            },
        };
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldSubscriptionsList(soldSubscriptionsCopy));
        onClose();
    }, [
        soldSubscriptionId,
        soldSubscriptions,
        dispatch,
        onClose,
    ]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: useCallback((values) => {
            if (!soldSubscriptionId) {
                return;
            }
            const transformedValues = DATA_TRANSFORMER.send(values);
            dispatch(SUBSCRIPTIONS_ACTIONS.resumeSoldSubscriptionItem({
                soldSubscriptionId,
                data: transformedValues,
                onSuccess: () => handleSuccess(values),
            }));
        }, [
            soldSubscriptionId,
            dispatch,
            handleSuccess,
            onClose,
        ]),
    });

    const {
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
    } = formik;

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const invalid = isInvalid(k, errors, touched);
            if (invalid) {
                hasErrors.onTrue();
            }
            const subValidation = {
                isInvalid: invalid,
                error: getError(k, errors),
            };
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [
        values,
        errors,
        touched,
        hasErrors.onFalse,
        hasErrors.onTrue,
    ]);

    const handleChangeStartDate = useCallback((sd) => {
        setFieldTouched('startDate', true);
        setFieldValue('startDate', sd.startOf('day'));
    }, [setFieldTouched, setFieldValue]);

    useEffect(() => {
        if (!visible) {
            return;
        }
        const today = moment().startOf('day');
        const nextDebit = moment()
            .add(1, 'month')
            .startOf('month')
            .add(-SOLD_SUBSCRIPTION_PROP.RESUME.DAYS_BEFORE_NEXT_MONTH + 1, 'day');
        const startDateMonthsDiff = today.isBefore(nextDebit) ? 1 : 2;
        const startDateFromToday = moment()
            .add(startDateMonthsDiff, 'month')
            .startOf('month');
        const startDateFromPause = pause?.from && moment.unix(pause.from);
        const startDate = (startDateFromPause && startDateFromPause.unix() > startDateFromToday.unix())
            ? startDateFromPause
            : startDateFromToday;
        setFieldTouched('startDate', true);
        setFieldValue('startDate', startDate);
    }, [
        visible,
        pause?.from,
        setFieldTouched,
        setFieldValue,
    ]);

    return (
        <Modal
            show={visible}
            size="lg"
            centered
            fullscreen="lg-down"
            restoreFocus={false}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t(`${T_PREFIX}.header.title`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Container
                        fluid
                        className="m-0 p-0"
                    >
                        <Row className="m-0 p-0">
                            <Col
                                xs={12}
                                className="m-0 p-0"
                            >
                                <Form.Group>
                                    <Form.Label>
                                        {t(`${T_FIELDS}.startDate.label`)}
                                    </Form.Label>
                                    <InputGroup>
                                        <DateInput
                                            value={values.startDate}
                                            onChange={handleChangeStartDate}
                                            isInvalid={validation?.startDate?.isInvalid}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <Calendar width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': validation?.startDate?.isInvalid,
                                        })}
                                    >
                                        {validation?.startDate?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button
                    color="outline"
                    onClick={onClose}
                >
                    {t(`${T_PREFIX}.footer.actions.cancel`)}
                </Button>
                <Button
                    onClick={handleSubmit}
                >
                    {t(`${T_PREFIX}.footer.actions.save`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ResumeSoldSubscriptionDialog.propTypes = {
    visible: PropTypes.bool,
    soldSubscription: PropTypes.object,
    onClose: PropTypes.func,
};

ResumeSoldSubscriptionDialog.defaultProps = {
    visible: false,
    soldSubscription: null,
    onClose: emptyFunc,
};

export default ResumeSoldSubscriptionDialog;
