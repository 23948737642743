// extracted by mini-css-extract-plugin
var _1 = "Typography_black__Y7Eb2";
var _2 = "Typography_center__B6Tkl";
var _3 = "Typography_ellipsis__b-drt";
var _4 = "Typography_gray__Oa0uJ";
var _5 = "Typography_green__wilRe";
var _6 = "Typography_left__4FPIr";
var _7 = "Typography_red__VS8Xc";
var _8 = "Typography_right__p+6je";
var _9 = "Typography_typography__7bcu+";
var _a = "Typography_uppercase__iV6GP";
var _b = "Typography_yellow__pSXZB";
export { _1 as "black", _2 as "center", _3 as "ellipsis", _4 as "gray", _5 as "green", _6 as "left", _7 as "red", _8 as "right", _9 as "typography", _a as "uppercase", _b as "yellow" }
