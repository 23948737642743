import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    SubscriptionsHeader,
    SubscriptionsFooter,
    SellSubscriptionModal,
} from 'components';

import { SoldSubscriptionsTableColumns } from 'components/subscriptions/SoldSubscriptionsTableColumns';
import { SoldSubscriptionsTableRow } from 'components/subscriptions/SoldSubscriptionsTableRow';
import { PauseSoldSubscriptionDialog } from 'components/subscriptions/PauseSoldSubscriptionDialog';
import { ResumeSoldSubscriptionDialog } from 'components/subscriptions/ResumeSoldSubscriptionDialog';
import { ChangeSoldSubscriptionDialog } from 'components/subscriptions/ChangeSoldSubscriptionDialog';
import { CancelSoldSubscriptionDialog } from 'components/subscriptions/ChangeSoldSubscriptionDialog/CancelSoldSubscription';
import { DeleteSoldSubscriptionDialog } from 'components/subscriptions/DeleteSoldSubscriptionDialog';

import {
    CancelSoldSubscriptionModal,
    CancelSoldSubscriptionWithBookingsModal,
} from 'components/subscriptions/CancelSoldSubscriptionModals';
import EditSoldSubscriptionModal from 'components/subscriptions/EditSoldSubscriptionModal';

import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { Button } from 'components/Common/Button';
import { useDialog } from 'hooks/useDialog';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { CLIENTS_LIST_ID_SUBSCRIPTIONS_PAGE } from '../../../../../../const/CLIENT_URL';

import { useConfirmDialog } from '../../../../../../hooks/useConfirmDialog';

import * as SUBSCRIPTIONS_ACTIONS from '../../../../../../store/actions/subscriptions';

import * as SUBSCRIPTIONS_SELECTORS from '../../../../../../store/selectors/subscriptions';

function SoldSubscriptions() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const {
        activeCount,
        isDataLoaded,
        soldSubscriptionsList,
        searchValue,
        idFilter,
        statusFilter,
        totalMonthly,
        totalAnnual,
        cancelSoldSubscription: { showCancelModal, showCancelWithBookingsModal },
        editSubscriptionUsages: { showEditUsagesModal, paymentStartDate },
    } = useSelector(SUBSCRIPTIONS_SELECTORS.soldSubscriptionsListPageSelector);
    const {
        currentPage, itemsCount, pagesCount, showAll,
    } = useSelector(
        SUBSCRIPTIONS_SELECTORS.soldSubscriptionsTableFooterSelector,
    );

    const sellSeubscriptionModal = useDialog(false);

    const changeSoldSubscription = useSelector(
        SUBSCRIPTIONS_SELECTORS.changeSoldSubscriptionSelector,
    );

    const pauseDialog = useConfirmDialog({
        defaultState: false,
    });

    const resumeDialog = useConfirmDialog({
        defaultState: false,
    });

    const deleteDialog = useConfirmDialog({
        defaultState: false,
    });

    const changeDialog = useConfirmDialog({
        defaultState: false,
    });

    const handleSearch = useCallback((search) => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldSearchValue(search));
    }, [dispatch]);

    const handleStatusFilterChange = useCallback((filter) => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setStatusFilter(filter));
    }, [dispatch]);

    const handleIdFilterChange = useCallback((filter) => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setIdFilter(filter));
    }, [dispatch]);

    const handleTogglePaymentStatus = useCallback(
        ({ soldSubscriptionId, paymentId }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.toggleSubscriptionPaymentStatus(
                    soldSubscriptionId,
                    paymentId,
                ),
            );
        },
        [dispatch],
    );

    const handleToggleUserLock = useCallback(
        ({ clientId }) => {
            dispatch(SUBSCRIPTIONS_ACTIONS.toggleUserLock(clientId));
        },
        [dispatch],
    );

    const handleOpenClient = useCallback(
        ({ clientId }) => {
            history.push(CLIENTS_LIST_ID_SUBSCRIPTIONS_PAGE({ clientId }));
        },
        [history.push],
    );

    const handleCancel = useCallback(
        ({
            soldSubscriptionId, subscriptionStatus, nextDebitDate, contractStartDate, paymentType, price, payments, updatingAt, updatingSubscription,
        }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.toggleCancelSoldSubscriptionModal({
                    showCancelModal: true,
                    subscriptionId: soldSubscriptionId,
                    subscriptionStartDate: contractStartDate,
                    nextSubscriptionDate: updatingAt,
                    nextSubscriptionPrice: updatingSubscription?.price,
                    payments,
                    nextDebitDate,
                    subscriptionStatus,
                    paymentType,
                    price,
                }),
            );
        },
        [dispatch],
    );

    const handleShow = useCallback(
        ({ soldSubscriptionId, contractStartDate }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.openEditSubscriptionUsagesModal(
                    soldSubscriptionId,
                    contractStartDate,
                ),
            );
        },
        [dispatch],
    );

    const handlePause = useCallback(
        ({ soldSubscription }) => {
            pauseDialog.onSetData(soldSubscription);
            pauseDialog.onShow();
        },
        [pauseDialog.onSetData, pauseDialog.onShow],
    );

    const handleResume = useCallback(
        ({ soldSubscription }) => {
            resumeDialog.onSetData(soldSubscription);
            resumeDialog.onShow();
        },
        [resumeDialog.onSetData, resumeDialog.onShow],
    );

    const handleEdit = useCallback(
        (subscriptionData) => {
            if (subscriptionData) {
                changeDialog.onSetData(subscriptionData);
            }
            changeDialog.onShow();
        },
        [dispatch, changeDialog.onShow, changeDialog.onSetData],
    );

    const handleResend = useCallback(
        ({ soldSubscriptionId }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.resendSoldSubscriptionPayment({
                    soldSubscriptionId,
                }),
            );
        },
        [dispatch],
    );

    const handleShowAll = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldShowAll(true));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(currentPage - 1));
    }, [dispatch, currentPage]);

    const handleNextPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(currentPage + 1));
    }, [dispatch, currentPage]);

    const handleLastPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(pagesCount));
    }, [dispatch, pagesCount]);

    const handleFirstPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(1));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldShowAll(false));
    }, [dispatch]);

    const handleChangePage = useCallback(
        ({ page: nextPage }) => {
            dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(nextPage));
        },
        [dispatch],
    );

    const handleDelete = useCallback(
        ({ soldSubscriptionId }) => {
            deleteDialog.onSetData(soldSubscriptionId);
            deleteDialog.onShow();
        },
        [dispatch, deleteDialog.onSetData, deleteDialog.onShow],
    );

    const handleCloseDeleteDialog = useCallback(() => {
        deleteDialog.onClose();
        deleteDialog.onResetData();
    }, []);

    const handleCloseBookingsDialog = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.resetBookingsToCanceling());
        changeDialog.onShow();
    }, [dispatch, changeDialog.onShow]);

    const handleConfirmBookingsDialog = useCallback(async () => {
        await dispatch(
            SUBSCRIPTIONS_ACTIONS.changeSoldSubscription({
                soldSubscriptionId: changeDialog.data?.soldSubId,
                newSubscriptionId: changeSoldSubscription.newSubscriptionId,
                onSuccess: changeDialog.onClose,
                isConfirmed: true,
            }),
        );
    }, [
        changeDialog.data?.soldSubId,
        changeSoldSubscription.newSubscriptionId,
        dispatch,
        changeDialog.onClose,
    ]);

    useEffect(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.getSoldSubscriptionsList());
        dispatch(SUBSCRIPTIONS_ACTIONS.resetBookingsToCanceling());
    }, [dispatch, currentPage, searchValue, idFilter, statusFilter]);

    useEffect(
        () => () => {
            // on page unmount - clear the search value in the store
            dispatch(SUBSCRIPTIONS_ACTIONS.resetSoldSubscriptionsReducer());
        },
        [dispatch],
    );

    const onExport = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.reqestExportSoldSubscribtions());
    }, [dispatch]);

    useEffect(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.getSubscriptionsOptions());
    }, []);

    return (
        <>
            <SubscriptionsHeader
                showSalesInfo
                showSubscriptionsFilter
                activeCount={activeCount}
                totalMonthly={totalMonthly}
                totalAnnual={totalAnnual}
                onButtonClickHandler={sellSeubscriptionModal.onShow}
                searchValue={searchValue}
                onSearch={handleSearch}
                exportButton={
                    soldSubscriptionsList.length ? (
                        <Button
                            color="outline"
                            onClick={onExport}
                        >
                            {t('placeholders.export')}
                        </Button>
                    ) : null
                }
                onIdFilterChange={handleIdFilterChange}
                onStatusFilterChange={handleStatusFilterChange}
            />
            <Container>
                <Content loading={!isDataLoaded}>
                    <Table>
                        <TableHeader>
                            <SoldSubscriptionsTableColumns />
                        </TableHeader>
                        <TableBody>
                            {soldSubscriptionsList.map((soldSubscription) => (
                                <SoldSubscriptionsTableRow
                                    key={soldSubscription?.id}
                                    soldSubscription={soldSubscription}
                                    onShow={handleShow}
                                    onEdit={handleEdit}
                                    onPause={handlePause}
                                    onResume={handleResume}
                                    onCancel={handleCancel}
                                    onResend={handleResend}
                                    onDelete={handleDelete}
                                    onToggleUserLock={handleToggleUserLock}
                                    onTogglePaymentStatus={handleTogglePaymentStatus}
                                    onOpenClient={handleOpenClient}
                                />
                            ))}
                        </TableBody>
                        <ListTableFooter
                            page={currentPage}
                            showAll={showAll}
                            itemsCount={itemsCount}
                            pagesCount={pagesCount}
                            columnsCount={8}
                            itemsPerPage={10}
                            onShowAll={handleShowAll}
                            onPrevPage={handlePrevPage}
                            onNextPage={handleNextPage}
                            onLastPage={handleLastPage}
                            onFirstPage={handleFirstPage}
                            onShowPages={handleShowPages}
                            onChangePage={handleChangePage}
                        />
                    </Table>
                    <SubscriptionsFooter
                        onButtonClickHandler={sellSeubscriptionModal.onShow}
                        onSearch={handleSearch}
                    />
                </Content>
            </Container>
            {
                sellSeubscriptionModal.visible && (
                    <SellSubscriptionModal
                        onClose={sellSeubscriptionModal.onClose}
                    />
                )
            }
            {
                showCancelModal && (
                    <CancelSoldSubscriptionModal />
                )
            }
            {
                showCancelWithBookingsModal && (
                    <CancelSoldSubscriptionWithBookingsModal />
                )
            }
            {
                showEditUsagesModal && (
                    <EditSoldSubscriptionModal startDate={paymentStartDate} />
                )
            }

            <ChangeSoldSubscriptionDialog
                visible={changeDialog.visible}
                oldSubData={changeDialog.data}
                onClose={changeDialog.onClose}
            />
            <CancelSoldSubscriptionDialog
                visible={!!changeSoldSubscription.bookingsToCanceling?.length}
                bookings={changeSoldSubscription.bookingsToCanceling}
                onClose={handleCloseBookingsDialog}
                onConfirm={handleConfirmBookingsDialog}
            />

            <PauseSoldSubscriptionDialog
                visible={pauseDialog.visible}
                soldSubscription={pauseDialog.data}
                onClose={pauseDialog.onClose}
            />
            <ResumeSoldSubscriptionDialog
                visible={resumeDialog.visible}
                soldSubscription={resumeDialog.data}
                onClose={resumeDialog.onClose}
            />

            <DeleteSoldSubscriptionDialog
                isVisible={deleteDialog.visible}
                soldSubscriptionId={deleteDialog.data}
                onClose={handleCloseDeleteDialog}
            />
        </>
    );
}

export default SoldSubscriptions;
