import React, { useCallback, useEffect, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { IGNORED_NUMBER_SYMBOLS } from 'const/string/IGNORED_NUMBER_SYMBOLS';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { BUNDLES_LIST_PAGE } from 'const/CLIENT_URL';
import { Badge } from 'components/Common/Badge';
import { BackButton } from 'components/Common/BackButton';
import { AddOrEditBundleForm } from '../../../../../../components/bundles/AddOrEditBundleForm';
import { AddOrEditBundleFooter } from '../../../../../../components/bundles/AddOrEditBundleFooter';
import { Loader } from '../../../../../../components/Common/Loader';

import { useIncDec } from '../../../../../../hooks/useIncDec';
import { useBool } from '../../../../../../hooks/useBool';

import * as SERVICES_SELECTORS from '../../../../../../store/selectors/services';
import * as BUNDLES_SELECTORS from '../../../../../../store/selectors/bundles';

import * as SERVICES_ACTIONS from '../../../../../../store/actions/services';
import * as BUNDLES_ACTIONS from '../../../../../../store/actions/bundles';

import { BUNDLE_PROP } from '../../../../../../const/bundles/BUNDLE_PROP';
import { SELECT_CHANGE_ACTION } from '../../../../../../const/components/select/SELECT_CHANGE_ACTION';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import { getError, isInvalid } from '../../../../../../services/validationService';

const PAGE_MODE = {
    ADD: 'add',
    EDIT: 'edit',
};

const DEFAULT_VALUES = {
    name: BUNDLE_PROP.NAME.DEFAULT,
    services: BUNDLE_PROP.SERVICES.DEFAULT,
    maxUsages: {
        value: BUNDLE_PROP.MAX_USAGES.VALUE.DEFAULT,
        unlimited: BUNDLE_PROP.MAX_USAGES.UNLIMITED.DEFAULT,
    },
    validity: {
        value: BUNDLE_PROP.VALIDITY.VALUE.DEFAULT,
        unit: BUNDLE_PROP.VALIDITY.UNIT.DEFAULT,
        unlimited: BUNDLE_PROP.VALIDITY.UNLIMITED.DEFAULT,
    },
    price: BUNDLE_PROP.PRICE.DEFAULT,
    vatRate: BUNDLE_PROP.VAT_RATE.DEFAULT,
    description: BUNDLE_PROP.DESCRIPTION.DEFAULT,
    status: BUNDLE_PROP.STATUS.DEFAULT,
    isTrial: BUNDLE_PROP.TRIAL.DEFAULT,
};

const DATA_TRANSFORMER = {
    get: (data) => ({
        ...data,
        services: data.services.map((s) => ({
            value: s.id,
            label: s.name,
            isFixed: data.sold,
        })),
        maxUsages: {
            value: data.maxUsages.value || BUNDLE_PROP.MAX_USAGES.VALUE.DEFAULT,
            unlimited: data.maxUsages.unlimited,
        },
        validity: {
            ...data.validity,
            value: data.validity.value || BUNDLE_PROP.VALIDITY.VALUE.DEFAULT,
            unit: data.validity.unit || BUNDLE_PROP.VALIDITY.UNIT.DEFAULT,
            unlimited: data.validity.unlimited,
        },
    }),
    send: (data) => {
        const formData = new FormData();
        formData.append('name', data.name.trim());
        // eslint-disable-next-line array-callback-return
        data.services.map((s, i) => {
            formData.append(`services[${i}]`, s.value);
        });
        formData.append('maxUsages', data.maxUsages.value);
        formData.append('unlimitedUsages', Number(data.maxUsages.unlimited));
        formData.append('validityPeriodValue', data.validity.value);
        formData.append('validityPeriodType', data.validity.unit);
        formData.append('unlimitedPeriod', Number(data.validity.unlimited));
        formData.append('price', data.price);
        formData.append('vatValue', data.vatRate);
        formData.append('description', data.description.trim());
        formData.append('status', Number(!data.status));
        formData.append('isTrial', Number(data.isTrial));
        return formData;
    },
};

const T_PREFIX = 'addOrEdit';
const T_FORM = `${T_PREFIX}.form`;
const T_FIELDS = `${T_FORM}.fields`;

const AddOrEditBundle = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const params = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const { loading: companyServicesLoading } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const endServices = useSelector(SERVICES_SELECTORS.endServicesSelector);
    const { item: bundle, loading: bundleLoading } = useSelector(BUNDLES_SELECTORS.bundleItemSelector);

    const hasErrors = useBool(false);

    const pageMode = params.id ? PAGE_MODE.EDIT : PAGE_MODE.ADD;
    const isEditMode = pageMode === PAGE_MODE.EDIT;

    const validationSchema = useMemo(() => yup.object({
        name: yup
            .string()
            .trim()
            .min(BUNDLE_PROP.NAME.MIN_LENGTH, t(`${T_FIELDS}.name.validation.minLength`, {
                length: BUNDLE_PROP.NAME.MIN_LENGTH,
            }))
            .max(BUNDLE_PROP.NAME.MAX_LENGTH, t(`${T_FIELDS}.name.validation.maxLength`, {
                length: BUNDLE_PROP.NAME.MAX_LENGTH,
            }))
            .required(t(`${T_FIELDS}.name.validation.required`)),
        services: yup.array()
            .of(yup.object({
                value: yup
                    .number()
                    .required(),
                label: yup
                    .string()
                    .required(),
            }))
            .min(BUNDLE_PROP.SERVICES.MIN, t(`${T_FIELDS}.services.validation.minLength`, {
                length: BUNDLE_PROP.SERVICES.MIN,
            })),
        maxUsages: yup.object({
            value: yup
                .number()
                .min(
                    BUNDLE_PROP.MAX_USAGES.VALUE.MIN,
                    t(`${T_FIELDS}.maxUsages.value.validation.min`, {
                        value: BUNDLE_PROP.MAX_USAGES.VALUE.MIN,
                    }),
                )
                .max(
                    BUNDLE_PROP.MAX_USAGES.VALUE.MAX,
                    t(`${T_FIELDS}.maxUsages.value.validation.max`, {
                        value: BUNDLE_PROP.MAX_USAGES.VALUE.MAX,
                    }),
                )
                .required(t(`${T_FIELDS}.maxUsages.value.validation.required`)),
            unlimited: yup
                .bool(),
        }),
        validity: yup.object({
            value: yup
                .number()
                .min(
                    BUNDLE_PROP.VALIDITY.VALUE.MIN,
                    t(`${T_FIELDS}.validity.value.validation.min`, {
                        value: BUNDLE_PROP.VALIDITY.VALUE.MIN,
                    }),
                )
                .max(
                    BUNDLE_PROP.VALIDITY.VALUE.MAX,
                    t(`${T_FIELDS}.validity.value.validation.max`, {
                        value: BUNDLE_PROP.VALIDITY.VALUE.MAX,
                    }),
                )
                .required(t(`${T_FIELDS}.validity.value.validation.required`)),
            unit: yup
                .string()
                .oneOf(
                    BUNDLE_PROP.VALIDITY.UNIT.ENUM,
                    t(`${T_FIELDS}.validity.unit.validation.enum`),
                )
                .required(t(`${T_FIELDS}.validity.unit.validation.required`)),
            unlimited: yup
                .bool(),
        }),
        price: yup
            .number()
            .min(
                BUNDLE_PROP.PRICE.MIN,
                t(`${T_FIELDS}.price.validation.min`, {
                    value: formatPrice.toEuroWithComma({ amount: BUNDLE_PROP.PRICE.MIN }),
                }),
            )
            .max(
                BUNDLE_PROP.PRICE.MAX,
                t(`${T_FIELDS}.price.validation.max`, {
                    value: formatPrice.toEuroWithComma({ amount: BUNDLE_PROP.PRICE.MAX }),
                }),
            )
            .required(t(`${T_FIELDS}.price.validation.required`)),
        vatRate: yup
            .number()
            .oneOf(
                BUNDLE_PROP.VAT_RATE.ENUM,
                t(`${T_FIELDS}.vatRate.validation.enum`),
            )
            .required(t(`${T_FIELDS}.vatRate.validation.required`)),
        description: yup
            .string()
            .trim()
            .max(BUNDLE_PROP.DESCRIPTION.MAX_LENGTH, t(`${T_FIELDS}.description.validation.maxLength`, {
                length: BUNDLE_PROP.DESCRIPTION.MAX_LENGTH,
            })),
        status: yup
            .bool(),
        isTrial: yup
            .bool(),
    }), [t]);

    const formik = useFormik({
        initialValues: DEFAULT_VALUES,
        validationSchema,
        onSubmit: useCallback((values, { setSubmitting }) => {
            setSubmitting(true);
            const transformedValues = DATA_TRANSFORMER.send(values);
            if (isEditMode) {
                dispatch(BUNDLES_ACTIONS.editBundlesItem({
                    bundleId: params.id,
                    bundle: transformedValues,
                    history,
                    onFinal: () => setSubmitting(false),
                }));
            } else {
                dispatch(BUNDLES_ACTIONS.addBundlesItem({
                    bundle: transformedValues,
                    history,
                    onFinal: () => setSubmitting(false),
                }));
            }
        }, [
            dispatch,
            params.id,
            isEditMode,
            history,
        ]),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
    } = formik;

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const value = values[k];
            let subValidation;
            const objects = ['maxUsages', 'validity'];
            if (objects.includes(k)) {
                subValidation = Object.keys(value).reduce((res1, kk) => {
                    const invalid = isInvalid(`${k}.${kk}`, errors, touched);
                    if (invalid) {
                        hasErrors.onTrue();
                    }
                    return {
                        ...res1,
                        [kk]: {
                            isInvalid: invalid,
                            error: getError(`${k}.${kk}`, errors),
                        },
                    };
                }, {});
            } else {
                const invalid = isInvalid(k, errors, touched);
                if (invalid) {
                    hasErrors.onTrue();
                }
                subValidation = {
                    isInvalid: invalid,
                    error: getError(k, errors),
                };
            }
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [values, errors, touched]);

    const handleChangeName = useCallback((e) => {
        const { target: { value } } = e;
        setFieldTouched('name', true);
        setFieldValue('name', value);
    }, [setFieldValue, setFieldTouched]);

    const handleChangeServices = useCallback((s, { action, removedValue }) => {
        let copy = (s || []).slice();
        if (
            [SELECT_CHANGE_ACTION.POP_VALUE, SELECT_CHANGE_ACTION.REMOVE_VALUE].includes(action)
            && removedValue?.isFixed
        ) {
            return;
        }
        if ([SELECT_CHANGE_ACTION.CLEAR].includes(action)) {
            copy = values.services.filter((o) => o?.isFixed);
        }
        const fixedOptions = [];
        const unfixedOptions = [];
        // eslint-disable-next-line array-callback-return
        copy.map((o) => {
            if (o?.isFixed) {
                fixedOptions.push(o);
            } else {
                unfixedOptions.push(o);
            }
        });
        setFieldTouched('services', true);
        setFieldValue('services', fixedOptions.concat(unfixedOptions));
    }, [values.services, setFieldValue, setFieldTouched]);

    const handleChangeMaxUsagesValue = useCallback((e) => {
        const { target, nativeEvent } = e;
        const { value } = target;
        const { data } = nativeEvent;
        if (IGNORED_NUMBER_SYMBOLS.includes(data)) {
            return;
        }
        const num = parseInt(value, 10);
        const mu = (num || num === 0) ? num : '';
        setFieldTouched('maxUsages.value', true);
        setFieldValue('maxUsages.value', mu);
    }, [setFieldValue, setFieldTouched]);

    const {
        onInc: handleIncMaxUsagesValue,
        onDec: handleDecMaxUsagesValue,
    } = useIncDec({
        min: BUNDLE_PROP.MAX_USAGES.VALUE.MIN,
        max: BUNDLE_PROP.MAX_USAGES.VALUE.MAX,
        step: BUNDLE_PROP.MAX_USAGES.VALUE.STEP,
        value: values.maxUsages.value,
        onChange: useCallback((mu) => {
            setFieldValue('maxUsages.value', mu);
            setFieldTouched('maxUsages.value', true);
        }, [setFieldValue, setFieldTouched]),
    });

    const handleChangeValidityValue = useCallback((e) => {
        const { target, nativeEvent } = e;
        const { value } = target;
        const { data } = nativeEvent;
        if (IGNORED_NUMBER_SYMBOLS.includes(data)) {
            return;
        }
        const num = parseInt(value, 10);
        const vp = (num || num === 0) ? num : '';
        setFieldValue('validity.value', vp);
        setFieldTouched('validity.value', true);
    }, [setFieldValue, setFieldTouched]);

    const {
        onInc: handleIncValidityValue,
        onDec: handleDecValidityValue,
    } = useIncDec({
        min: BUNDLE_PROP.VALIDITY.VALUE.MIN,
        max: BUNDLE_PROP.VALIDITY.VALUE.MAX,
        step: BUNDLE_PROP.VALIDITY.VALUE.STEP,
        value: values.validity.value,
        onChange: useCallback((vp) => {
            setFieldValue('validity.value', vp);
            setFieldTouched('validity.value', true);
        }, [setFieldValue, setFieldTouched]),
    });

    const handleChangeValidityUnit = useCallback(({ value }) => {
        setFieldValue('validity.unit', value);
        setFieldTouched('validity.unit', true);
    }, [setFieldValue, setFieldTouched]);

    const handleChangePrice = useCallback((e) => {
        const { target, nativeEvent } = e;
        const { value } = target;
        const { data } = nativeEvent;
        if (IGNORED_NUMBER_SYMBOLS.includes(data)) {
            return;
        }
        const num = parseFloat(value);
        const pr = (num || num === 0) ? num : '';
        setFieldValue('price', pr);
        setFieldTouched('price', true);
    }, [setFieldValue, setFieldTouched]);

    const {
        onInc: handleIncPrice,
        onDec: handleDecPrice,
    } = useIncDec({
        min: BUNDLE_PROP.PRICE.MIN,
        max: BUNDLE_PROP.PRICE.MAX,
        step: BUNDLE_PROP.PRICE.STEP,
        value: values.price,
        toFixed: BUNDLE_PROP.PRICE.TO_FIXED,
        onChange: useCallback((pr) => {
            setFieldValue('price', pr);
            setFieldTouched('price', true);
        }, [setFieldValue, setFieldTouched]),
    });

    const handleChangeVatRate = useCallback(({ value }) => {
        setFieldValue('vatRate', value);
        setFieldTouched('vatRate', true);
    }, [setFieldValue, setFieldTouched]);

    const handleChangeDescription = useCallback((e) => {
        const { target: { value } } = e;
        setFieldTouched('description', true);
        setFieldValue('description', value);
    }, [setFieldValue, setFieldTouched]);

    const handleToggleTrial = useCallback((value) => {
        setFieldValue('isTrial', value);
        setFieldTouched('isTrial', true);
    }, [setFieldValue, setFieldTouched]);

    const handleToggleStatus = useCallback((value) => {
        setFieldValue('status', value);
        setFieldTouched('status', true);
    }, [setFieldValue, setFieldTouched]);

    useEffect(() => {
        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [dispatch]);

    useEffect(() => {
        if (isEditMode) {
            dispatch(BUNDLES_ACTIONS.getBundleItem({ bundleId: params.id }));
        }
    }, [dispatch, params.id, isEditMode]);

    useEffect(() => {
        if (!isEditMode || bundleLoading || !bundle) {
            return;
        }
        const transformedBundle = DATA_TRANSFORMER.get(bundle);
        // eslint-disable-next-line array-callback-return
        Object.entries(transformedBundle).map(([key, value]) => {
            setFieldValue(key, value);
        });
    }, [bundle, bundleLoading, isEditMode]);

    if (companyServicesLoading || bundleLoading) {
        return <Loader />;
    }

    return (
        <>
            <TextSubHeader
                text={t(`${T_PREFIX}.header.title.${pageMode}`)}
                before={(
                    <BackButton
                        href={BUNDLES_LIST_PAGE}
                    />
                )}
                after={isEditMode && (
                    <Badge
                        size="small"
                        color={values?.status ? 'green' : 'red'}
                    >
                        {t(`addOrEdit.header.status.${values?.status ? 'enabled' : 'disabled'}`)}
                    </Badge>
                )}
            />
            <Container
                fluid
                className="px-3 px-lg-5"
            >
                <Form onSubmit={handleSubmit}>
                    <AddOrEditBundleForm
                        sold={isEditMode && bundle?.sold}
                        values={values}
                        services={endServices}
                        validation={validation}
                        onIncPrice={handleIncPrice}
                        onDecPrice={handleDecPrice}
                        onChangeName={handleChangeName}
                        onChangePrice={handleChangePrice}
                        onToggleTrial={handleToggleTrial}
                        onToggleStatus={handleToggleStatus}
                        onChangeVatRate={handleChangeVatRate}
                        onChangeServices={handleChangeServices}
                        onIncValidityValue={handleIncValidityValue}
                        onDecValidityValue={handleDecValidityValue}
                        onChangeDescription={handleChangeDescription}
                        onIncMaxUsagesValue={handleIncMaxUsagesValue}
                        onDecMaxUsagesValue={handleDecMaxUsagesValue}
                        onChangeValidityUnit={handleChangeValidityUnit}
                        onChangeValidityValue={handleChangeValidityValue}
                        onChangeMaxUsagesValue={handleChangeMaxUsagesValue}
                        onToggleValidityUnlimited={handleChange}
                        onToggleMaxUsagesUnlimited={handleChange}
                    />
                    <AddOrEditBundleFooter
                        disabled={hasErrors.value || isSubmitting}
                        confirmText={t(`${T_PREFIX}.footer.actions.${pageMode}`)}
                    />
                </Form>
            </Container>
        </>
    );
};

export default AddOrEditBundle;
