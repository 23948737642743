import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Form, Modal } from 'react-bootstrap';
import WindowedSelect, { components } from 'react-windowed-select';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Button } from 'components/Common/Button';
import TextButton from 'components/Common/TextButton/TextButton';
import * as styles from './AddClientsDialog.module.scss';
import { selectWithGrayItems } from '../../../../styles/selectStyles/selectWithGrayItems';
import { dangerTheme, theme } from '../../../../styles/select';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import { addClientsToMessagesItem, getMessages } from '../../../../store/actions';

const T_PREFIX = 'addClientsDialog';
const T_BODY = `${T_PREFIX}.body`;
const T_ACTIONS = `${T_PREFIX}.actions`;

const initialValues = {
    messageToAllClients: false,
    clients: [],
    sendAlsoToEmail: false,
};

const AddClientsDialog = (props) => {
    const { message, onClose, companyClients } = props;

    const [value, setValue] = useState(null);

    const { t } = useTranslation(LOCALE_NAMESPACE.MESSAGES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const dispatch = useDispatch();
    const {
        isSavingProcess,
    } = useSelector((state) => state.messages.message);

    const validationSchema = useMemo(() => yup.object({
        sendAlsoToEmail: yup
            .boolean()
            .required(),
        clients: yup
            .array()
            .min(1, t(`${T_BODY}.selectClients.errors.atLeast`)),
    }), [t]);

    const messageClientIdsSet = useMemo(() => new Set(message.clients?.map((c) => c.id)), [message.clients?.length]);

    const filtredClients = useMemo(
        () => (messageClientIdsSet.size
            ? companyClients.filter((client) => !messageClientIdsSet.has(client.id))
            : companyClients),
        [messageClientIdsSet?.size],
    );

    const selectClientsOptions = useMemo(() => [
        { value: null, label: '' },
        ...filtredClients.map((client) => ({
            value: client.id,
            label: `${client.firstName} ${client.lastName}`,
            email: client.email,
            phone: client.contactNumber,
        })),
    ], [filtredClients]);

    const onSubmit = useCallback((sentMessage) => {
        const formData = new FormData();
        const isMessageToAllClients = sentMessage.clients.length === filtredClients.length;
        formData.append('messageToAllClients', isMessageToAllClients);
        if (!isMessageToAllClients) {
            sentMessage.clients?.forEach((c, i) => formData.append(`clients[${i}]`, c.id));
        }

        dispatch(addClientsToMessagesItem({
            messageId: message.id,
            message: formData,
            onSuccess: () => {
                dispatch(getMessages());
                onClose();
            },
        }));
    }, [dispatch, message.id]);

    const {
        handleSubmit,
        setFieldValue,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const isClientsSelectInvalid = !!touched.clients && !!errors.clients;

    const isAllClientsSelected = values.clients.length === filtredClients.length;

    const handleSetValues = useCallback((clients) => {
        const valies = clients.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (clients.length > 100) {
            valies.push({ value: -1, label: tc('weekRoute.more', { count: clients.length - 100 }) });
        }
        setValue(valies);
    }, []);

    const handleChangeAllClientsSelect = useCallback(({ clear }) => {
        let clients = [];
        if (isAllClientsSelected || clear) {
            setFieldValue('clients', clients);
        } else {
            clients = filtredClients.map((c) => ({
                id: c.id,
                name: `${c.firstName} ${c.lastName}`,
            }));
            setFieldValue('clients', clients);
        }
        handleSetValues(clients);
    }, [isAllClientsSelected, filtredClients]);

    const handleChangeClientSelect = useCallback((client, selected) => {
        if (!client.value) {
            return;
        }
        let clients = [];
        if (selected) {
            clients = [...values.clients, { id: client.value, name: client.label }];
            setFieldValue('clients', clients);
        } else {
            clients = values.clients.filter((c) => c.id !== client.value);
            setFieldValue('clients', clients);
        }

        handleSetValues(clients);
    }, [values?.clients.length]);

    const handleChangeClientsSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllClientsSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeClientSelect(triggeredAction.removedValue, false);
                return;
            }
            const clients = values.clients.slice(0, 100);
            setFieldValue('clients', clients);
            handleSetValues(clients);
        }
        if (triggeredAction.action === 'deselect-option') {
            handleChangeClientSelect(triggeredAction.option, false);
        }
        if (triggeredAction.action === 'select-option') {
            handleChangeClientSelect(triggeredAction.option, true);
        }
    }, [handleChangeClientSelect, handleChangeAllClientsSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllClientsSelected) {
            e.preventDefault();
            setFieldValue('clients', []);
        }
    }, [isAllClientsSelected]);

    useEffect(() => {
        if (!message) {
            return;
        }

        setFieldValue('clients', filtredClients.map((c) => ({
            id: c.id,
            name: `${c.firstName} ${c.lastName}`,
        })));

        handleSetValues(filtredClients);
    }, [message, filtredClients.length]);

    const valuesClientsIdsSet = useMemo(() => new Set(values?.clients?.map((c) => c.id)), [values?.clients?.length]);

    return (
        <Modal
            centered
            size="md"
            show={Boolean(message)}
            onHide={onClose}
        >
            <Modal.Header
                closeButton
                onHide={onClose}
            >
                <Modal.Title>
                    {t(`${T_PREFIX}.header`)}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className={styles.body}>
                <div className={styles.messageName}>
                    {message.name}
                </div>

                <Form.Group className={styles.clientsSelect}>
                    <WindowedSelect
                        name="clients"
                        styles={selectWithGrayItems}
                        theme={isClientsSelectInvalid ? dangerTheme : theme}
                        options={selectClientsOptions}
                        value={value}
                        onChange={handleChangeClientsSelect}
                        isMulti
                        isClearable
                        backspaceRemovesValue
                        onKeyDown={onKeyDownHandle}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        placeholder={t(`${T_BODY}.selectClients.placeholder`)}
                        components={{
                            MultiValue: isAllClientsSelected
                                ? (props) => (
                                    props.index === 0
                                        ? t(`${T_BODY}.selectClients.allClients`)
                                        : null
                                )
                                // eslint-disable-next-line react/no-unstable-nested-components
                                : (props) => <components.MultiValue {...props} />,
                            // eslint-disable-next-line react/no-unstable-nested-components
                            Option: ({
                                innerRef, innerProps, data,
                            }) => {
                                const isChecked = valuesClientsIdsSet.has(data.value);

                                return (
                                    <Form.Group
                                        ref={innerRef}
                                        {...innerProps}
                                        className={styles.option}
                                    >
                                        {data.value === null
                                            ? (
                                                <TextButton color="yellow" onClick={handleChangeAllClientsSelect}>
                                                    {t(`${T_BODY}.selectClients.allClients`)}
                                                </TextButton>
                                            )
                                            : (
                                                <>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={() => {}}
                                                        className={styles.checkbox}
                                                    />
                                                    {`${data.label}`}
                                                    <span className={classNames('ml-1', styles.grayColor)}>
                                                        {` · ${data.email} · ${data.phone}`}
                                                    </span>
                                                </>
                                            )}
                                    </Form.Group>
                                );
                            },
                        }}
                    />

                    <Form.Control.Feedback
                        className={isClientsSelectInvalid && 'd-block'}
                        type="invalid"
                    >
                        {errors.clients}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>

            <Modal.Footer className={styles.footer}>
                <Button
                    color="outline"
                    onClick={onClose}
                >
                    {t(`${T_ACTIONS}.cancel`)}
                </Button>

                <Button
                    onClick={handleSubmit}
                    loading={isSavingProcess}
                    color="yellow"
                >
                    {' '}
                    {t(`${T_ACTIONS}.send`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddClientsDialog;
