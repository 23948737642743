import React from 'react';

const FileUpload = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <polyline fill="none" points="23,15 16,15 16,18 8,18 8,15 1,15 " stroke={fill} strokeLinecap="butt" />
            <line fill="none" strokeLinecap="butt" x1="12" x2="12" y1="11" y2="2" />
            <polyline fill="none" points="19,6 20,6 23,15 23,23 1,23 1,15 4,6 5,6 " stroke={fill} />
            <polyline fill="none" points=" 9,5 12,2 15,5 " />
        </g>
    </svg>
);

export default FileUpload;
