import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './TableBody.module.scss';

const TableBody = (props) => {
    const { children } = props;

    return (
        <tbody className={styles.bodyRow}>
            {children}
        </tbody>
    );
};

TableBody.propTypes = {
    children: PropTypes.any,
};

TableBody.defaultProps = {
    children: null,
};

export default TableBody;
