import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useDialog } from 'hooks/useDialog';
import { useDispatch } from 'react-redux';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import * as BUNDLES_ACTIONS from '../../../store/actions/bundles';
import { Pen2Icon, Trash3Icon } from '../../Icon/Icon';

import { Tooltip } from '../../Common/Tooltip';

import { formatPrice } from '../../../helpers/formatters/formatPrice';
import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';
import { CLIENTS_LIST_ID_PAGE } from '../../../const/CLIENT_URL';

import * as styles from './SoldBundlesTableRow.module.scss';

const UNBREAKABLE_SPACE = '\xa0';

const INFINITY_SYMBOL = '∞';

const formatDate = ({ date }) => (
    Boolean(date) && moment.unix(date).format(`DD[${UNBREAKABLE_SPACE}]MMM[${UNBREAKABLE_SPACE}]YYYY`)
);

const T_PREFIX = 'sold.table.row';

const SoldBundlesTableRow = (props) => {
    const {
        soldBundle,
        onEdit,
        onArchive,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const confirmationChangeStatusPaid = useDialog(false);
    const dispatch = useDispatch();

    const history = useHistory();

    const isEnded = soldBundle.isEnded || soldBundle.bookings.value === soldBundle.bookings.max;

    const bookings = useMemo(() => {
        const { value, max, unlimited } = soldBundle?.bookings || {};
        const maxString = unlimited ? INFINITY_SYMBOL : max;
        return `${value}${UNBREAKABLE_SPACE}/${UNBREAKABLE_SPACE}${maxString}`.trim() || '';
    }, [soldBundle?.bookings]);

    const handleEdit = useCallback(() => {
        if (!soldBundle?.id) {
            return;
        }
        onEdit({ soldBundle });
    }, [onEdit, soldBundle]);

    const handleArchive = useCallback(() => {
        if (!soldBundle?.id) {
            return;
        }
        onArchive({ soldBundleId: soldBundle.id });
    }, [onArchive, soldBundle?.id]);

    const handleClientRedirect = useCallback(() => {
        if (!soldBundle?.client?.id) {
            return;
        }
        history.push(CLIENTS_LIST_ID_PAGE({ clientId: soldBundle.client.id }));
    }, [history.push, soldBundle?.client?.id]);

    const handleChangeStatus = useCallback(() => {
        if (soldBundle?.id) {
            dispatch(BUNDLES_ACTIONS.toggleSoldBundlePaymentStatus({ soldBundleId: soldBundle.id }));
            confirmationChangeStatusPaid.onClose();
        }
    }, [soldBundle?.id]);

    const isManuallySet = soldBundle.isManuallySet ? t(`${T_PREFIX}.status.manuallySet`) : '';
    const status = t(`${T_PREFIX}.status.${soldBundle.paid ? 'paid' : 'unpaid'}`);
    const isNotIdealPayment = soldBundle?.paymentType !== 'ideal';
    const isAutomaticPaidIdeal = soldBundle?.paymentType === 'ideal' && soldBundle.paid && !isManuallySet;
    const isManuallyChangeStatusEnabled = soldBundle?.profit > 0 && (isNotIdealPayment || !isAutomaticPaidIdeal);

    return (
        <>
            <ListTableBodyRow className={classNames(isEnded && styles.ended)}>
                <ListTableBodyCell>
                    <button
                        type="button"
                        className={classNames(
                            styles.client,
                            'cursor-pointer',
                        )}
                        onClick={handleClientRedirect}
                    >
                        {`${soldBundle?.client?.name} ${soldBundle?.client?.lastName}`}
                    </button>
                </ListTableBodyCell>
                <ListTableBodyCell className={styles.services}>
                    {soldBundle?.bundle?.services?.map?.((s) => (
                        <div key={s.id}>
                            <Tooltip
                                tooltip={s.name}
                                placement="top-start"
                            >
                                <span>
                                    {s.name}
                                </span>
                            </Tooltip>
                            <br />
                        </div>
                    ))}
                </ListTableBodyCell>
                <ListTableBodyCell className={styles.bundle}>
                    {soldBundle?.bundle?.name}
                </ListTableBodyCell>
                <ListTableBodyCell>
                    {bookings}
                </ListTableBodyCell>
                <ListTableBodyCell>
                    {formatDate({ date: soldBundle?.purchasedAt })}
                </ListTableBodyCell>
                <ListTableBodyCell>
                    {formatDate({ date: soldBundle?.endsAt }) || INFINITY_SYMBOL}
                </ListTableBodyCell>
                <ListTableBodyCell>
                    {tc(`paymentTypes.${soldBundle?.paymentType}`)}
                </ListTableBodyCell>
                <ListTableBodyCell align="right">
                    {formatPrice.toEuroWithComma({ amount: soldBundle?.profit })}
                </ListTableBodyCell>
                <ListTableBodyCell>
                    <button
                        type="button"
                        className={classNames(
                            styles.client,
                            isManuallyChangeStatusEnabled && 'cursor-pointer',
                            'text-left',
                            soldBundle.paid ? styles.paid : styles.unpaid,
                        )}
                        disabled={!isManuallyChangeStatusEnabled}
                        onClick={confirmationChangeStatusPaid.onShow}
                    >
                        {status + isManuallySet}
                    </button>
                </ListTableBodyCell>
                <ListTableBodyCell>
                    {!soldBundle.deleted && !isEnded && (
                        <Row gap={8}>
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.actions.edit`)}
                                placement="top"
                                forButton
                            >
                                <IconButton
                                    color="gray"
                                    size={32}
                                    disabled={!soldBundle?.id}
                                    onClick={handleEdit}
                                >
                                    <Pen2Icon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                tooltip={t(`${T_PREFIX}.actions.archive`)}
                                placement="top"
                                forButton
                            >
                                <IconButton
                                    color="gray"
                                    size={32}
                                    disabled={!soldBundle?.id}
                                    onClick={handleArchive}
                                >
                                    <Trash3Icon />
                                </IconButton>
                            </Tooltip>
                        </Row>
                    )}
                </ListTableBodyCell>
            </ListTableBodyRow>

            {confirmationChangeStatusPaid.visible && (
                <ConfirmationModal
                    size="xs"
                    isShow={confirmationChangeStatusPaid.visible}
                    bodyText={t('sold.dialogs.confirmationChangeStatusPaid.bodyText')}
                    hide={confirmationChangeStatusPaid.onClose}
                    deleteText={t('sold.dialogs.confirmationChangeStatusPaid.yes')}
                    dismissText={t('sold.dialogs.confirmationChangeStatusPaid.no')}
                    confirmAction={handleChangeStatus}
                />
            )}
        </>
    );
};

SoldBundlesTableRow.propTypes = {
    soldBundle: PropTypes.object,
    onEdit: PropTypes.func,
    onArchive: PropTypes.func,
};

SoldBundlesTableRow.defaultProps = {
    soldBundle: null,
    onEdit: emptyFunc,
    onArchive: emptyFunc,
};

export default SoldBundlesTableRow;
