import React, { useMemo } from 'react';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import { Button } from 'components/Common/Button';
import { Badge } from 'components/Common/Badge';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import ACCESS_GATES_STATUS from 'const/access/ACCESS_GATES_STATUS';
import { Panel } from 'components/Common/Panel';
import * as styles from './GateStatusBlock.module.scss';

const T_PREFIX = 'gates.statusBlock';

const badgeColor = {
    [ACCESS_GATES_STATUS.INACTIVE]: 'red',
    [ACCESS_GATES_STATUS.CREATED]: 'gray',
    [ACCESS_GATES_STATUS.ACTIVE]: 'green',
};

const statusColor = {
    [ACCESS_GATES_STATUS.INACTIVE]: styles.red,
    [ACCESS_GATES_STATUS.CREATED]: styles.gray,
    [ACCESS_GATES_STATUS.ACTIVE]: styles.green,
};

const GateStatusBlock = ({
    status, onToggle, /* , onConnect, */
}) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const action = useMemo(() => {
        if (status === ACCESS_GATES_STATUS.CREATED) {
            // return (
            //     <Button onClick={onConnect}>
            //         {t(`${T_PREFIX}.action.connect`)}
            //     </Button>
            // );
            return null;
        }
        if (status === ACCESS_GATES_STATUS.INACTIVE) {
            return (
                <Button onClick={onToggle}>
                    {t(`${T_PREFIX}.action.reconnect`)}
                </Button>
            );
        }
        if (status === ACCESS_GATES_STATUS.ACTIVE) {
            return (
                <Button color="outline" onClick={onToggle}>
                    {t(`${T_PREFIX}.action.disableGate`)}
                </Button>
            );
        }
    }, [status, t]);

    return (
        <Panel stretched padding={12} gap={32} color="secondary">
            <Column gap={16}>
                <div className={classNames(styles.status, statusColor[status])}>
                    {t(`${T_PREFIX}.status.${status}`)}
                </div>
                <Row spaceBetween stretched>
                    <Row>
                        <span>
                            {t(`${T_PREFIX}.statusLabel`)}
                        </span>
                        <Badge color={badgeColor[status]} size="small">
                            {t(`status.${status}`)}
                        </Badge>
                    </Row>
                    <Row>
                        {action}
                    </Row>
                </Row>
            </Column>
        </Panel>
    );
};

GateStatusBlock.propTypes = {};

GateStatusBlock.defaultProps = {};

export default GateStatusBlock;
