import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const getEmployees = () => ({
    type: actionTypes.GET_EMPLOYEES,
});

export const getEmployeesSucceeded = (employees) => ({
    type: actionTypes.GET_EMPLOYEES_SUCCEEDED,
    employees,
});

export const getEmployeesFailed = () => ({
    type: actionTypes.GET_EMPLOYEES_FAILED,
});

export const setEmployeeFilter = (id, visible) => ({
    type: actionTypes.SET_EMPLOYEE_FILTER,
    id,
    visible,
});

export const addEmployee = createPromiseAction(actionTypes.ADD_EMPLOYEE);

export const addEmployeeSucceeded = (employee) => ({
    type: actionTypes.ADD_EMPLOYEE_SUCCEEDED,
    employee,
});

export const addEmployeeFailed = () => ({
    type: actionTypes.ADD_EMPLOYEE_FAILED,
});

export const editEmployee = createPromiseAction(actionTypes.EDIT_EMPLOYEE);

export const editEmployeeSucceeded = (employee) => ({
    type: actionTypes.EDIT_EMPLOYEE_SUCCEEDED,
    employee,
});

export const editEmployeeFailed = () => ({
    type: actionTypes.EDIT_EMPLOYEE_FAILED,
});

export const deleteEmployee = createPromiseAction(actionTypes.DELETE_EMPLOYEE);

export const deleteEmployeeSucceeded = (id) => ({
    type: actionTypes.DELETE_EMPLOYEE_SUCCEEDED,
    payload: id,
});

export const deleteEmployeeFailed = () => ({
    type: actionTypes.DELETE_EMPLOYEE_FAILED,
});
