const PREFIX = '@@CLIENTS/';
const CLIENT_ITEM = `${PREFIX}:CLIENT:ITEM`;

const CLIENT_ITEM_REVIEWS = `${CLIENT_ITEM}:REVIEWS`;
const CLIENT_ITEM_REVIEWS_LOADING = `${CLIENT_ITEM_REVIEWS}:LOADING`;
export const CLIENT_ITEM_REVIEWS_LOADING_SET = `${CLIENT_ITEM_REVIEWS_LOADING}:SET`;
export const CLIENT_ITEM_REVIEWS_GET = `${CLIENT_ITEM_REVIEWS}:GET`;
export const CLIENT_ITEM_REVIEWS_SET = `${CLIENT_ITEM_REVIEWS}:SET`;
const CLIENT_ITEM_REVIEWS_PAGE = `${CLIENT_ITEM_REVIEWS}:PAGE`;
export const CLIENT_ITEM_REVIEWS_PAGE_SET = `${CLIENT_ITEM_REVIEWS_PAGE}:SET`;
export const CLIENT_ITEM_REVIEWS_PAGE_SET_FIRST = `${CLIENT_ITEM_REVIEWS_PAGE}:FIRST`;
export const CLIENT_ITEM_REVIEWS_PAGE_SET_LAST = `${CLIENT_ITEM_REVIEWS_PAGE}:LAST`;
export const CLIENT_ITEM_REVIEWS_PAGE_SET_PREV = `${CLIENT_ITEM_REVIEWS_PAGE}:PREV`;
export const CLIENT_ITEM_REVIEWS_PAGE_SET_NEXT = `${CLIENT_ITEM_REVIEWS_PAGE}:NEXT`;
