import React from 'react';

const Pen2 = ({ className, width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        className={className}
    >
        <g clipPath="url(#clip0_1454_1928)">
            <path
                d="M4.38907 14.8721L3.33426 19.4245C3.29787 19.5909 3.29912 19.7633 3.33791 19.9292C3.3767 20.0951 3.45205 20.2502 3.55847 20.3832C3.66488 20.5162 3.79966 20.6238 3.95297 20.698C4.10628 20.7723 4.27424 20.8114 4.44458 20.8124C4.52395 20.8204 4.60393 20.8204 4.6833 20.8124L9.26339 19.7576L18.0572 10.9971L13.1495 6.10059L4.38907 14.8721Z"
                fill="currentColor"
            />
            <path
                d="M20.8217 6.60999L17.5463 3.33453C17.3309 3.12028 17.0395 3 16.7357 3C16.4319 3 16.1405 3.12028 15.9252 3.33453L14.1042 5.15546L19.0063 10.0575L20.8273 8.23661C20.9338 8.12951 21.0182 8.00245 21.0756 7.86269C21.133 7.72294 21.1623 7.57323 21.1618 7.42215C21.1613 7.27107 21.131 7.12157 21.0726 6.98221C21.0143 6.84285 20.929 6.71636 20.8217 6.60999Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1454_1928">
                <rect
                    width={width}
                    height={height}
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
);

export default Pen2;
