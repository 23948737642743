import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';

const T_PREFIX = 'subscriptions.tableHeaders';

function SoldSubscriptionsTableColumns() {
    const { t } = useTranslation();

    return (
        <tr>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.client`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.subscriptionTitle`)}
                    wrap
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.contractStartDate`)}
                    wrap
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.contractEndDate`)}
                    wrap
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.nextDebitDate`)}
                    wrap
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_PREFIX}.price`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.status`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.actions`)}
                />
            </TableHeaderCell>
        </tr>
    );
}

export default SoldSubscriptionsTableColumns;
