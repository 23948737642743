import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Row, Col, Badge } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

// Styles

// Utils
import classNames from 'classnames';
import moment from 'moment';
import _, { random } from 'lodash';
import { formatPrice } from 'helpers/formatters/formatPrice';
import * as styles from './Event.module.scss';
import {
    CreditCard, Clock, Users, Pin, Box,
} from '../Icon/Icon';
import { getFullName } from '../../helpers/services/getFullName';

const Event = ({
    className, event, onClick, showParticipants = true,
}) => {
    const eventParticipantsSeatsSum = event?.participants.reduce((acc, { seats }) => (acc + seats), 0);
    const { t } = useTranslation();

    return (
        <Row className={classNames(styles.container, className, 'd-flex')} onClick={onClick}>
            <Col xs={12} md={{ span: 'auto' }} className={styles.imageContainer}>
                {!event && <Skeleton width="100%" height={100} />}
                {event && <img className={styles.image} src={event.image} alt={event?.name} />}
            </Col>

            <Col xs={12} md className={classNames({ 'justify-content-center': !showParticipants }, 'd-flex flex-column px-lg-0')}>
                <div className="d-flex align-content-center mt-3 mt-lg-0">
                    <p className="font-size-17 mb-0">
                        {!event && <Skeleton width={150} />}
                        {event && event.name}
                    </p>

                    <div className="ml-2 mr-n2">
                        {event && event.isCancelled && <Badge variant="danger" pill className="mx-2 text-uppercase">{t('event.cancelled')}</Badge>}
                        {event && eventParticipantsSeatsSum === event.totalPlaces && <Badge variant="success" pill className="mx-2 text-uppercase">{t('event.full')}</Badge>}
                    </div>
                </div>
                <p className="font-size-14 font-weight-bold text-muted mb-0">
                    {!event && <Skeleton width={185} />}
                    {event && event.subName}
                </p>

                <div className="d-flex mt-3 mt-lg-2">
                    <p className="d-flex align-items-center font-size-14 font-weight-bold text-muted mb-0 mr-4">
                        {!event && <Skeleton width={50} />}
                        {event && (
                            <>
                                <CreditCard className="mr-1" width={18} />
                                {formatPrice.toEuroWithComma({ amount: event.price })}
                            </>
                        )}
                    </p>

                    <p className="d-flex align-items-center font-size-14 font-weight-bold text-muted mb-0 mr-4">
                        {!event && <Skeleton width={65} />}
                        {event && (
                            <>
                                <Clock className="mr-1" width={18} />
                                {t('event.start', { hour: moment(event.time.from).format('HH:mm') })}
                            </>
                        )}
                    </p>

                    {showParticipants && (
                        <p className="d-flex align-items-center font-size-14 font-weight-bold text-muted mb-0">
                            {!event && <Skeleton width={60} />}
                            {event && (
                                <>
                                    <Users className="mr-1" width={18} />
                                    {`${eventParticipantsSeatsSum}/${event.totalPlaces}`}
                                </>
                            )}
                        </p>
                    )}
                </div>

                <div className="d-flex align-items-center mt-1 font-size-14 font-weight-bold text-muted mb-0">
                    {!event && <Skeleton width={100} />}
                    {event && (
                        <>
                            <Pin className="mr-1" width={18} />
                            <span>{`${event.address.street}, ${event.address.zip} ${event.address.city}`}</span>
                        </>
                    )}
                </div>
            </Col>

            {showParticipants && (
                <Col xs={12} lg={3} className="d-flex justify-content-lg-end flex-lg-grow-1">
                    <div className="d-flex flex-column">
                        <ul className={classNames(styles.participants, 'mb-0 mt-3 mt-lg-0')}>
                            {!event && _.times(4, (i) => (
                                <li key={`loading-participant-${i}`} className={classNames(styles.participant, 'd-flex flex-row flex-lg-row-reverse justify-content-start align-items-center font-size-14 font-weight-600')}>
                                    <Skeleton width={75 + random(75)} />
                                </li>
                            ))}
                            {event && event.participants.slice(0, 4).map(({
                                client,
                                boughtWithBundle,
                                payment: {
                                    paid,
                                    isInProgress,
                                    usedBundle,
                                    finalCost,
                                },
                                notPresent,
                            }, i) => (
                                <li
                                    key={`participant-${i}`}
                                    className={classNames(styles.participant, {
                                        [styles.notPaid]: !isInProgress && !paid && finalCost,
                                        [styles.paid]: (!isInProgress && paid) || !finalCost,
                                        [styles.inProgress]: isInProgress && !usedBundle,
                                        [styles.notPresent]: notPresent,
                                    }, 'd-flex flex-row flex-lg-row-reverse justify-content-start align-items-center font-size-14 font-weight-600')}
                                >
                                    {getFullName({ client })}
                                    {boughtWithBundle && <Box className="text-muted ml-1 ml-lg-0 mr-lg-1" width={16} />}
                                </li>
                            ))}
                        </ul>

                        {!event && <span className="font-size-14 font-weight-bold text-left text-lg-right"><Skeleton width={65} /></span>}
                        {event && event.participants.length > 4 && <span className="font-size-14 font-weight-bold text-left text-lg-right">{t('event.more', { count: event.participants.length - 4 })}</span>}
                    </div>
                </Col>
            )}
        </Row>
    );
};

export default Event;
