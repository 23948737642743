import React from 'react';
import PropTypes from 'prop-types';

const Buildings = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.66676 0H13.6668C14.5492 0 15.2668 0.717605 15.2668 1.60001V15.2001C15.2668 15.4123 15.1825 15.6158 15.0325 15.7658C14.8825 15.9158 14.679 16.0001 14.4668 16.0001H1.66673C1.45456 16.0001 1.25108 15.9158 1.10105 15.7658C0.951015 15.6158 0.866729 15.4123 0.866729 15.2001V11.2001C0.708528 11.2 0.553888 11.1531 0.422359 11.0652C0.290831 10.9773 0.188319 10.8524 0.127782 10.7062C0.0672457 10.56 0.0514032 10.3992 0.0822574 10.244C0.113112 10.0889 0.189277 9.94634 0.301126 9.83446L4.06675 6.06884V1.60001C4.06675 0.717605 4.78435 0 5.66676 0ZM2.46674 9.93126V14.4001H7.26677V9.93126L4.86675 7.53125L2.46674 9.93126ZM8.86678 14.4001H13.6668V1.60001H5.66676V6.06884L9.43238 9.83446C9.54462 9.94619 9.62114 10.0888 9.65221 10.244C9.68329 10.3993 9.66753 10.5604 9.60693 10.7067C9.54632 10.853 9.44361 10.978 9.31184 11.0659C9.18006 11.1537 9.02515 11.2004 8.86678 11.2001V14.4001ZM7.78473 3.19995C7.49854 3.19995 7.26654 3.43195 7.26654 3.71813V4.28177C7.26654 4.56796 7.49854 4.79996 7.78473 4.79996H8.34837C8.63455 4.79996 8.86655 4.56796 8.86655 4.28177V3.71813C8.86655 3.43195 8.63455 3.19995 8.34837 3.19995H7.78473ZM10.9847 3.19995C10.6986 3.19995 10.4666 3.43195 10.4666 3.71813V4.28177C10.4666 4.56796 10.6986 4.79996 10.9847 4.79996H11.5484C11.8346 4.79996 12.0666 4.56796 12.0666 4.28177V3.71813C12.0666 3.43195 11.8346 3.19995 11.5484 3.19995H10.9847ZM10.9847 6.42477C10.6986 6.42477 10.4666 6.65677 10.4666 6.94295V7.48179C10.4666 7.76798 10.6986 7.99998 10.9847 7.99998H11.5484C11.8346 7.99998 12.0666 7.76798 12.0666 7.48179V6.94295C12.0666 6.65677 11.8346 6.42477 11.5484 6.42477H10.9847Z"
                fill="currentColor"
            />
        </svg>
    );
};

Buildings.propTypes = {
    className: PropTypes.string,
};

Buildings.defaultProps = {
    className: '',
};

export default Buildings;
