/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';

const LogoIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="147px"
      height="48px"
      viewBox="0 0 147 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>logo</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <rect id="path-1" x="0" y="0" width="184" height="48"></rect>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="logo" transform="translate(-18.000000, 0.000000)">
          <g>
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Mask"></g>
            <g id="Group" mask="url(#mask-2)">
              <g transform="translate(18.000000, 0.000000)">
                <g id="Group_1">
                  <path
                    d="M53.0983427,22.1830048 L41.3921867,1.73565255 C40.803283,0.707010774 39.7148342,0.0737547176 38.5370267,0.0737547176 L15.1247147,0.0737547176 C13.9465652,0.0737547176 12.8577385,0.707000445 12.2681687,1.73565255 L0.56201274,22.1830048 C-0.0269921028,23.2118232 -0.0269921028,24.4793797 0.56201274,25.508198 L12.2681687,45.9527548 C12.8577385,46.9814069 13.9465652,47.6146527 15.1247147,47.6146527 L38.5370267,47.6146527 C39.7148342,47.6146527 40.803283,46.9813966 41.3921867,45.9527548 L53.0983427,25.5054025 C53.6862812,24.4772948 53.6862812,23.2111125 53.0983427,22.1830048 Z"
                    id="Path_1"
                    fill="#FFC100"
                    fillRule="nonzero"
                  ></path>
                </g>
                <g id="Group_2" transform="translate(61.000000, 15.000000)">
                  <path
                    d="M7.57804246,0.170581491 L5.8374828,0.170581491 C5.75441687,0.170923079 5.68011659,0.221558039 5.65049214,0.298013923 L0.0182772824,15.069095 C-0.00421664699,15.1296309 0.0041507736,15.1971252 0.0407723992,15.2505477 C0.0783502562,15.3034408 0.139735796,15.3350418 0.205267941,15.3350418 L1.8924017,15.3350418 C1.97569535,15.3340893 2.04989614,15.2829724 2.07939236,15.2062234 L3.52470361,11.337541 L9.93018811,11.337541 L11.3881529,15.2062234 C11.4166393,15.2837408 11.491464,15.3352874 11.5751435,15.3350418 L13.2327524,15.3350418 C13.2982923,15.3350418 13.3596792,15.3034318 13.3969997,15.250353 C13.4343203,15.1972742 13.4428205,15.1295296 13.4197431,15.069095 L7.76503312,0.298013923 C7.73540867,0.221558039 7.6611084,0.170923079 7.57804246,0.170581491 L7.57804246,0.170581491 Z M9.34672102,9.77926392 L4.07723992,9.77926392 C4.26469922,9.24644861 4.65555188,8.15311527 5.24979788,6.49926392 L6.70073291,2.42558149 C7.59210191,4.85233825 8.47503524,7.32203419 9.34672102,9.77926392 L9.34672102,9.77926392 Z"
                    id="Path_2"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M20.7643987,4.30105446 C19.8723656,4.29074618 18.9973585,4.5419381 18.2505694,5.02270987 C17.800856,5.30517631 17.4135397,5.67404045 17.1117541,6.10727068 L17.090665,4.72213554 C17.0902934,4.67070464 17.0691986,4.62152577 17.0320214,4.5854184 C16.9948443,4.54931103 16.9446303,4.52923309 16.8924268,4.52960176 L15.3894718,4.52960176 C15.3361279,4.53016006 15.2853344,4.55217843 15.2488773,4.59054771 C15.2122379,4.62744949 15.1924391,4.67746438 15.1939548,4.72906122 C15.2545011,6.03247338 15.2868378,6.9951423 15.2868378,7.59213554 L15.2868378,19.7813247 C15.2868351,19.8894145 15.375365,19.9772527 15.485076,19.9780139 L16.937417,19.9780139 C17.0473585,19.9772591 17.136295,19.889639 17.1370611,19.7813247 L17.1370611,13.9637572 C17.4415442,14.3585876 17.8241833,14.6884412 18.261817,14.9333518 C19.0175356,15.3590476 19.8757139,15.576278 20.7461214,15.5622031 C22.137035,15.6116755 23.4740803,15.0296325 24.373459,13.9831491 C26.1694748,11.5632354 26.1694748,8.2764749 24.373459,5.85656122 C23.4748531,4.8220429 22.145462,4.2490747 20.7643987,4.30105446 L20.7643987,4.30105446 Z M23.8293584,9.95517609 C23.8857326,11.0146269 23.5835939,12.06255 22.9703261,12.9346018 C22.4046752,13.6575957 21.5138101,14.0596495 20.5886556,14.0094666 C19.6217176,14.0625766 18.6825698,13.6804536 18.0354599,12.9706153 C17.3759678,12.1583396 17.0435911,11.1350361 17.1019125,10.0964599 C17.0493346,8.99286993 17.3850481,7.90522933 18.0523312,7.01730446 C18.6638885,6.25235878 19.6126052,5.82525582 20.5999032,5.87041257 C21.5231098,5.82171907 22.4106714,6.22753509 22.9689202,6.95358825 C23.5835074,7.83342367 23.8859809,8.88858462 23.8293584,9.95517609 L23.8293584,9.95517609 Z"
                    id="Path_3"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M33.5584964,4.30105446 C32.6664632,4.29074618 31.7914561,4.5419381 31.0446671,5.02270987 C30.5949537,5.30517631 30.2076374,5.67404045 29.9058518,6.10727068 L29.8861686,4.72213554 C29.8846261,4.61481381 29.7954666,4.52882978 29.6865244,4.52960176 L28.1835694,4.52960176 C28.1293704,4.52993007 28.0776362,4.55195432 28.0402502,4.59061549 C28.0028643,4.62927666 27.98304,4.68125168 27.9853312,4.73460176 C28.0457868,6.03801392 28.0767176,7.00068284 28.0767176,7.59767609 L28.0767176,19.7868653 C28.0774838,19.8951795 28.1664203,19.9827996 28.2763618,19.9835545 L29.7287028,19.9835545 C29.8384137,19.9827933 29.9269437,19.8949551 29.926941,19.7868653 L29.926941,13.9692977 C30.2317663,14.3638133 30.6143344,14.6936057 31.0516968,14.9388923 C31.8079326,15.364574 32.6665573,15.5817945 33.5374072,15.5677437 C34.9283208,15.6172161 36.2653661,15.035173 37.1647448,13.9886896 C38.9616564,11.5690642 38.9616564,8.28172712 37.1647448,5.86210176 C36.2679805,4.8261319 34.9395605,4.25109487 33.5584964,4.30105446 Z M36.624862,9.95517609 C36.6814699,11.0148569 36.3787744,12.0629957 35.7644238,12.9346018 C35.1990604,13.6571786 34.308828,14.0591841 33.3841592,14.0094666 C32.4168094,14.0625969 31.4772246,13.6805065 30.8295575,12.9706153 C30.1706567,12.1580274 29.8383541,11.1349521 29.8960102,10.0964599 C29.8438365,8.9927042 30.1800516,7.9050473 30.8478348,7.01730446 C31.4590984,6.25276713 32.4071895,5.82571016 33.3940008,5.87041257 C34.3176455,5.82149723 35.2057301,6.22731159 35.7644238,6.95358825 C36.3781445,7.83382431 36.6805333,8.88868976 36.624862,9.95517609 L36.624862,9.95517609 Z"
                    id="Path_4"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M49.3697516,4.5337541 L47.9188166,4.5337541 C47.8354975,4.53329886 47.7607799,4.58421821 47.7318259,4.6611896 L44.5684501,13.0412572 L41.3713316,4.6611896 C41.3420388,4.5844781 41.2675327,4.5337541 41.184341,4.5337541 L39.6476433,4.5337541 C39.5818516,4.53414204 39.5204139,4.56621693 39.4831478,4.61963554 C39.4460571,4.67353719 39.4386754,4.74210587 39.4634645,4.80247338 L43.6461503,15.2103788 L43.4563478,15.6896355 C42.9231002,16.9911175 41.7745849,17.9512231 40.3843584,18.2576761 C40.3282806,18.2750064 40.2828026,18.3157995 40.2600442,18.3691842 C40.2372858,18.4225689 40.2395594,18.4831209 40.266259,18.5347031 L40.8286369,19.5389261 C40.863704,19.6022609 40.9311516,19.6415989 41.00438,19.6414267 L41.0465584,19.6414267 C42.050808,19.4458625 42.9747272,18.9642519 43.7037941,18.256291 C44.4154163,17.5448693 44.9630462,16.6907011 45.309383,15.7519666 L49.562366,4.80939906 C49.5854435,4.74896449 49.5769432,4.68121983 49.5396227,4.62814102 C49.5023021,4.57506221 49.4409152,4.54341036 49.3753754,4.54345311 L49.3697516,4.5337541 Z"
                    id="Path_5"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M59.5389503,7.3261896 C60.060087,7.13155699 60.5181639,6.80181835 60.8647563,6.37183149 C61.3750948,5.67806932 61.6313592,4.83421898 61.5916297,3.97831798 C61.5916297,1.45183149 59.623307,0.170581491 55.7499291,0.170581491 L51.6417584,0.170581491 C51.5318169,0.171336321 51.4428804,0.258956442 51.4421142,0.36727068 L51.4421142,15.1383518 C51.4428804,15.246666 51.5318169,15.3342861 51.6417584,15.335041 L56.8831206,15.335041 C58.3155638,15.4258731 59.7356477,15.0244618 60.9013108,14.1992301 C62.1010427,13.2981823 62.6775287,11.8042505 62.3883881,10.3455507 C62.0992475,8.88685094 60.9946238,7.71635414 59.5389503,7.3261896 L59.5389503,7.3261896 Z M53.3851299,8.28470311 L56.3783864,8.28470311 C57.4452305,8.19477579 58.5147179,8.42511251 59.446158,8.94541257 C60.0678266,9.46918285 60.391951,10.2581993 60.3150318,11.0605139 C60.3645878,11.8125735 60.0309297,12.5395994 59.4250688,12.9997031 C58.6184479,13.5058404 57.6685024,13.7441804 56.7144072,13.6798045 L53.3851299,13.6798045 L53.3851299,8.28470311 Z M58.7994234,6.02970311 C58.055009,6.49858552 57.1766439,6.71772044 56.2954357,6.65439906 L53.3949715,6.65439906 L53.3949715,1.82166257 L55.8834938,1.82166257 C56.8620824,1.75948814 57.840164,1.95049009 58.7206904,2.37571663 C59.3167965,2.73963759 59.6556451,3.40195423 59.598,4.09051392 C59.6673893,4.8263407 59.3695816,5.54950939 58.7994234,6.02970311 L58.7994234,6.02970311 Z"
                    id="Path_6"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M69.3482272,4.26919636 C67.8769626,4.21272086 66.4540154,4.79199165 65.4523541,5.85517609 C64.4484084,6.9936902 63.9251782,8.46778142 63.9901715,9.97456798 C63.9204517,11.4723491 64.4715662,12.9337343 65.5170276,14.023318 C66.6106727,15.0656465 68.0925606,15.6169508 69.6125448,15.5469666 C70.366795,15.5477935 71.116302,15.429479 71.8325316,15.1965274 C72.5180594,14.9801717 73.1585629,14.6442515 73.7235274,14.2047707 C73.7939365,14.1489097 73.817068,14.0531951 73.7797652,13.972068 L73.3059618,12.937372 C73.2798078,12.8803888 73.227537,12.8391911 73.1653673,12.8265612 C73.1060932,12.8105023 73.0425942,12.8239447 72.995248,12.8625747 C72.0453336,13.6002517 70.8669581,13.993913 69.657535,13.9776085 C68.6354548,14.0254543 67.6390575,13.6532601 66.9061011,12.9498382 C66.2315568,12.2261053 65.865997,11.274141 65.8853851,10.2917639 L73.962538,10.2917639 C74.064759,10.2909106 74.150057,10.214623 74.1607762,10.1144666 C74.1678059,10.0452099 74.1706178,9.85544636 74.1706178,9.54794636 C74.2366606,8.16228977 73.7810555,6.80140072 72.8912081,5.72635852 C71.9917175,4.74184477 70.6912483,4.20698596 69.3482272,4.26919636 Z M65.9739597,8.89554771 C66.0685426,8.06512555 66.4450653,7.29052403 67.0424777,6.69733825 C67.6717929,6.10811672 68.5142066,5.79140094 69.3819699,5.81777744 C70.1999945,5.78403032 70.9932705,6.09835959 71.5597783,6.68071663 C72.1116992,7.29609016 72.4366674,8.07696391 72.4820781,8.89693284 L65.9739597,8.89554771 Z"
                    id="Path_7"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M84.532431,5.72774365 C83.6316192,4.74079021 82.328084,4.20522343 80.9824204,4.26919636 C79.5111558,4.21272086 78.0882086,4.79199165 77.0865473,5.85517609 C76.0826016,6.9936902 75.5593714,8.46778142 75.6243648,9.97456798 C75.5553977,11.4727475 76.1075476,12.934187 77.1540327,14.023318 C78.2476778,15.0656465 79.7295657,15.6169508 81.2495499,15.5469666 C82.0038021,15.5478174 82.7533129,15.4295023 83.4695367,15.1965274 C84.1550645,14.9801717 84.795568,14.6442515 85.3605325,14.2047707 C85.4309416,14.1489097 85.4540731,14.0531951 85.4167703,13.972068 L84.9429669,12.937372 C84.9168129,12.8803888 84.8645421,12.8391911 84.8023724,12.8265612 C84.742651,12.8105023 84.6787336,12.8239224 84.6308471,12.8625747 C83.681586,13.6004529 82.5035733,13.9941588 81.2945401,13.9776085 C80.2724599,14.0254543 79.2760626,13.6532601 78.5431062,12.9498382 C77.8685619,12.2261053 77.5030021,11.274141 77.5223902,10.2917639 L85.5981372,10.2917639 C85.7008965,10.2916145 85.7870025,10.2151466 85.7977813,10.1144666 C85.7977813,10.0452099 85.8076229,9.85544636 85.8076229,9.54794636 C85.8744977,8.16329491 85.420435,6.80302016 84.532431,5.72774365 L84.532431,5.72774365 Z M77.6081529,8.89554771 C77.7027358,8.06512555 78.0792585,7.29052403 78.6766709,6.69733825 C79.3059861,6.10811672 80.1483998,5.79140094 81.0161631,5.81777744 C81.8341877,5.78403032 82.6274637,6.09835959 83.1939715,6.68071663 C83.7458924,7.29609016 84.0708606,8.07696391 84.1162713,8.89693284 L77.6081529,8.89554771 Z"
                    id="Path_8"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                </g>
                <g id="Group_3" transform="translate(12.000000, 9.000000)">
                  <path
                    d="M28.1756482,10.1623128 C26.691845,8.92384789 24.7484758,8.37293189 22.8318482,8.64742576 C21.969137,8.75531405 21.1741321,9.16832252 20.5918482,9.81111545 C20.5407643,9.87460368 20.5376313,9.96395181 20.5841437,10.0308313 C20.6306562,10.0977108 20.7157705,10.1262421 20.7934482,10.1009926 C22.3786625,9.56765785 24.1074548,9.64775938 25.6360482,10.3253687 C26.8863504,10.8004532 27.7457267,11.955042 27.8382482,13.2840672 C27.7973289,14.3140456 27.2489592,15.2577327 26.3724482,15.8065562 C25.3550548,16.4921717 24.1498541,16.8484392 22.9214482,16.826701 C19.8078482,16.826701 17.9626482,14.9787339 15.8206482,12.835315 C14.4739156,11.3259643 12.8695169,10.0657332 11.0816482,9.11290164 C8.97035253,8.13387419 6.57114841,7.96175747 4.34064818,8.62930844 C1.76184818,9.429258 0.095848183,11.2549269 0.095848183,13.2784926 C0.111858464,14.5062263 0.665648279,15.6657855 1.61204818,16.4532054 C3.05908167,17.625311 4.94149506,18.1235869 6.78224818,17.8217602 C7.66093742,17.7427644 8.49457237,17.3990605 9.17204818,16.8364565 C9.23394256,16.7781019 9.24812182,16.6853582 9.20646909,16.6113161 C9.16481635,16.5372741 9.0779713,16.500847 8.99564818,16.5228874 C7.46575067,16.9882873 5.81861661,16.8893343 4.35604818,16.2441593 C3.10520013,15.7687197 2.24536235,14.6137011 2.15244818,13.2840672 C2.19360802,12.2541582 2.74191751,11.3105747 3.61824818,10.7615782 C4.636354,10.0776139 5.84116598,9.72194787 7.06924818,9.74282708 C10.1828482,9.74282708 12.0294482,11.5907942 14.1672482,13.7300321 L14.1672482,13.7300321 C16.0432482,15.605872 18.1698482,17.7311736 21.4472482,18.2259159 C21.9282166,18.2984438 22.4139926,18.3347814 22.9004482,18.3346672 C23.8338637,18.3375783 24.7624951,18.2018286 25.6556482,17.9318578 C28.2330482,17.1305146 29.8990482,15.3062394 29.8990482,13.2812799 C29.8200814,12.0379496 29.1882323,10.8944446 28.1756482,10.1623128 L28.1756482,10.1623128 Z"
                    id="Path_9"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M14.9960482,19.0174733 C13.0585306,19.0135389 11.147296,19.463754 9.41704818,20.3317065 C9.30320288,20.8650427 9.24502725,21.4086623 9.24344818,21.9539038 C9.24344818,22.154588 9.26584818,22.3469104 9.28124818,22.5420201 C12.9439134,21.046166 17.0509829,21.046166 20.7136482,22.5420201 C20.7276482,22.3469104 20.7514482,22.154588 20.7514482,21.9539038 C20.7494089,21.408614 20.690764,20.8649939 20.5764482,20.3317065 C18.8458143,19.4634421 16.9340508,19.0132154 14.9960482,19.0174733 Z"
                    id="Path_10"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M9.81744818,24.8916977 C10.0785269,25.5437861 10.4099343,26.16574 10.8058482,26.746633 C13.5444667,25.9605981 16.4504296,25.9605981 19.1890482,26.746633 C19.5844089,26.1644222 19.9157777,25.5416062 20.1774482,24.8889104 C16.8910513,23.3772734 13.1038451,23.3772734 9.81744818,24.8889104 L9.81744818,24.8916977 Z"
                    id="Path_11"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M12.4956482,28.7548687 C13.252481,29.4976571 14.0918212,30.1522393 14.9974482,30.7059652 C15.9027572,30.1517843 16.7420477,29.4972409 17.4992482,28.7548687 C15.8488747,28.4147988 14.1460216,28.4147988 12.4956482,28.7548687 L12.4956482,28.7548687 Z"
                    id="Path_12"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M10.3256482,1.14685339 C10.4948823,1.14948847 10.6535673,1.06524094 10.7456482,0.923870935 C11.568408,1.0660698 12.2208252,1.69343308 12.3920482,2.50704637 L11.7732482,2.86242466 C11.1180377,3.23932064 10.7137812,3.93479616 10.7120482,4.68809352 C10.7120482,4.68809352 10.6350482,7.19664615 11.0438482,7.19664615 C11.4526482,7.19664615 12.1456482,4.68809352 12.1456482,4.68809352 C12.1847017,4.45994421 12.3169987,4.25811125 12.5110482,4.13063738 L14.9974482,2.70076238 L17.4418482,4.1069455 C17.6579627,4.24932315 17.80539,4.47425052 17.8492482,4.72850909 C17.8492482,4.72850909 18.5408482,7.18967795 18.9496482,7.18967795 C19.3584482,7.18967795 19.2800482,4.72850909 19.2800482,4.72850909 C19.2789914,3.94659995 18.860427,3.2242072 18.1810482,2.83176458 L17.6000482,2.49729089 C17.7724793,1.68435045 18.4253488,1.05818999 19.2478482,0.916902733 C19.3404818,1.05767205 19.4988073,1.1417287 19.6678482,1.13988519 C19.8382095,1.14924552 19.999812,1.06409214 20.0878236,0.918587195 C20.1758351,0.773082249 20.1758351,0.59106642 20.0878236,0.445561474 C19.999812,0.300056528 19.8382095,0.21490315 19.6678482,0.224263479 C19.465086,0.222168414 19.2819736,0.344612185 19.2072482,0.532257997 C18.2609384,0.682863593 17.4926667,1.37444481 17.2472482,2.29660668 L15.3656482,1.21374813 C15.1367852,1.08287483 14.8553112,1.08287483 14.6264482,1.21374813 L12.7504482,2.2938194 C12.5015401,1.37429247 11.7347169,0.685105597 10.7904482,0.532257997 C10.7148928,0.344652673 10.531482,0.222381 10.3284482,0.224263479 C10.1580869,0.21490315 9.99648432,0.300056528 9.90847279,0.445561474 C9.82046125,0.59106642 9.82046125,0.773082249 9.90847279,0.918587195 C9.99648432,1.06409214 10.1580869,1.14924552 10.3284482,1.13988519 L10.3256482,1.14685339 Z"
                    id="Path_13"
                    fill="#161615"
                    fillRule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LogoIcon.propTypes = {
  className: PropTypes.string,
};

LogoIcon.defaultProps = {
  className: "",
};

export default LogoIcon;
