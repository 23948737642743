import {
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as CLIENTS_TYPES from '../../actions/clients/types';
import * as CLIENTS_ACTIONS from '../../actions/clients';
import * as CLIENTS_SELECTORS from '../../selectors/clients';

import * as TOAST_ACTIONS from '../../actions/toast';

import axios from '../../../services/axios';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { STATUS_FILTER_VALUES } from '../../../const/clients/STATUS_FILTER_VALUES';

import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_CLIENT_CREATE_ROUTE,
    API_ADMIN_CLIENT_DELETE_ID_ROUTE,
    API_ADMIN_COMPANY_CLIENTS_LIST_ROUTE,
    API_ADMIN_CLIENT_TOGGLE_BLOCK_ID_ROUTE,
    API_ADMIN_COMPANY_CLIENT_CATEGORY_ROUTE,
    API_ADMIN_CLIENT_APPLY_DISCOUNT_ROUTE,
    API_ADMIN_CLIENT_INVITATION_SEND_ROUTE,
    API_ADMIN_REQEST_EXPORT_CLIENTS_LIST,
} from '../../../const/API_URL';

function* getClients(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const {
        search,
        page,
        sortBy,
        sortType,
        statusFilter,
        shopFilter,
        discountFilter,
    } = yield select(CLIENTS_SELECTORS.clientsListSelector);

    const clientListParameters = {
        search,
        page,
        sortBy,
        sortType,
        statusFilter: Object.values(STATUS_FILTER_VALUES).includes(statusFilter) ? statusFilter : null,
        shopFilter: shopFilter || null,
        clientCategory: discountFilter || [],
    };

    try {
        yield put(CLIENTS_ACTIONS.setClientsLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_CLIENTS_LIST_ROUTE,
            headers: defaultHeaders,
            params: clientListParameters,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items, itemsCount, pagesCount, total,
            } = result;
            const clients = items.map((item) => {
                const client = camelize(item);
                return {
                    id: client.id,
                    avatar: client.avatarUrl,
                    fullName: {
                        firstName: client.firstName,
                        lastName: client.lastName,
                    },
                    contactInfo: {
                        phone: client.contactNumber,
                        email: client.email,
                        address: {
                            street: client.address,
                            zip: client.zip,
                            city: client.city,
                        },
                    },
                    isRegistered: client.registered,
                    isBlocked: client.blocked,
                    locale: client.locale,
                };
            });
            yield put(CLIENTS_ACTIONS.setClients({
                clients, itemsCount, pagesCount, total,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(CLIENTS_ACTIONS.setClientsLoading({ loading: false }));
    }
}

function* addClientsItem(action) {
    const { payload } = action;
    const { data, onSuccess, onError } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_CREATE_ROUTE,
            headers: defaultHeaders,
            data,
        });
        const { data: resData } = res;
        const { code, result } = resData;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(CLIENTS_ACTIONS.getClients());
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.add.success',
                appearance: 'success',
            }));

            if (onSuccess) {
                yield call(onSuccess, { data: result });
            }
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: error?.response?.data?.result?.errors?.email
                    ? 'clients.add.emailPhoneError'
                    : 'clients.add.error',
                insertion: { error: error?.response?.data?.result?.errors?.email },
                appearance: 'error',
            }));

            onError?.({
                ...error?.response?.data?.result?.errors,
                phone: error?.response?.data?.result?.errors?.contactNumber,
            });
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* deleteClientsItem(action) {
    const { payload } = action;
    const { clientId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_DELETE_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(CLIENTS_ACTIONS.getClients());
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.delete.success',
                appearance: 'success',
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: error?.response?.data?.result?.message,
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* clientsItemBlockToggle(action) {
    const { payload } = action;
    const { clientId } = payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_TOGGLE_BLOCK_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(CLIENTS_ACTIONS.getClients());
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.block.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* getCompanyDiscountProfiles(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(CLIENTS_ACTIONS.setClientsLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_CLIENT_CATEGORY_ROUTE,
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;

        if (code === HTTP_STATUS_CODE.OK) {
            const discountItems = camelize(result);
            yield call(resolvePromiseAction, action, discountItems);
            yield put(CLIENTS_ACTIONS.getCompanyDiscountProfilesSucceeded(discountItems));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.getClientProfiles.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(CLIENTS_ACTIONS.setClientsLoading({ loading: false }));
    }
}

function* applyDiscountProfiles(action) {
    const { payload } = action;
    const { data } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_APPLY_DISCOUNT_ROUTE,
            headers: defaultHeaders,
            data,
        });

        if (res?.data?.code === HTTP_STATUS_CODE.OK) {
            yield put(CLIENTS_ACTIONS.getClients());
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.applyDiscountProfiles.success',
                appearance: 'success',
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.applyDiscountProfiles.error',
                appearance: 'error',
            }));
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* sendClientsInvitation(action) {
    const { payload } = action;
    const { data } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_INVITATION_SEND_ROUTE,
            headers: defaultHeaders,
            data,
        });

        if (res?.data?.code === HTTP_STATUS_CODE.OK) {
            yield put(CLIENTS_ACTIONS.getClients());
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.sendInvitation.success',
                appearance: 'success',
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.sendInvitation.error',
                appearance: 'error',
            }));
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* requestExportClientList(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            method: HTTP_METHOD.POST,
            url: API_ADMIN_REQEST_EXPORT_CLIENTS_LIST,
            headers: defaultHeaders,
        });
        const { code } = res.data;

        if (code === HTTP_STATUS_CODE.OK) {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'clients.export.success',
                    appearance: 'success',
                }),
            );
        }
    } catch (error) {
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
            });
        }
    }
}

export const clientsListSaga = [
    takeLatest(CLIENTS_TYPES.CLIENTS_GET, getClients),
    takeEvery(CLIENTS_ACTIONS.getCompanyDiscountProfiles, getCompanyDiscountProfiles),
    takeEvery(CLIENTS_TYPES.CLIENTS_ITEM_ADD, addClientsItem),
    takeEvery(CLIENTS_TYPES.CLIENTS_ITEM_DELETE, deleteClientsItem),
    takeEvery(CLIENTS_TYPES.CLIENTS_ITEM_BLOCK_TOGGLE, clientsItemBlockToggle),
    takeEvery(CLIENTS_TYPES.CLIENTS_APPLY_DISCOUNT, applyDiscountProfiles),
    takeEvery(CLIENTS_TYPES.CLIENTS_INVITATION_SEND, sendClientsInvitation),
    takeEvery(CLIENTS_TYPES.CLIENTS_EXPORT, requestExportClientList),
];
