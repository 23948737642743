import BundlesList from './bundlesList';
import SoldBundles from './soldBundles';
import AddOrEditBundle from './addOrEditBundle';

const routes = [
    {
        component: BundlesList,
        exact: true,
        path: '/services/bundles/list',
    },
    {
        component: SoldBundles,
        exact: true,
        path: '/services/bundles/sold',
    },
    {
        component: AddOrEditBundle,
        exact: true,
        path: '/services/bundles/add',
    },
    {
        component: AddOrEditBundle,
        exact: true,
        path: '/services/bundles/edit/:id',
    },
];

export default routes;
