import React from 'react';
import { ComingSoon } from 'components';

function Promotions() {
    return (
        <ComingSoon />
    );
}

export default Promotions;
