import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loaded: false,
    id: null,
    name: null,
    workingDays: null,
    contactNumber: null,
    address: {
        street: null,
        zip: null,
        city: null,
        country: null,
    },
};

export const shop = (state = initialState, { type, payload }) => {
    switch (type) {
    case actionTypes.SELECT_SHOP_TRIGGER: {
        const { id, name } = payload;
        return {
            ...state,
            loaded: false,
            id,
            name,
            workingDays: null,
        };
    }
    case actionTypes.GET_SHOP_DETAILS_TRIGGER: {
        const { id, name } = payload;
        return {
            ...state,
            id,
            name,
            loaded: false,
        };
    }
    case actionTypes.GET_SHOP_DETAILS_SUCCEEDED: {
        const {
            id, name, workingDays, contactNumber, address,
        } = payload;
        return {
            ...state,
            loaded: true,
            id,
            name,
            workingDays,
            contactNumber,
            address,
        };
    }
    case actionTypes.GET_SHOP_DETAILS_FAILED: {
        return {
            ...state,
            loaded: false,
            id: null,
            name: null,
            workingDays: null,
        };
    }
    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    default:
        return state;
    }
};
