import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Pin2Icon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Common/Tooltip';

function DefaultTabDropdown(props) {
    const {
        isDataLoaded,
        options,
        onSelect,
        defaultTab,
    } = props;
    const { t } = useTranslation();

    const handleSelect = useCallback(debounce((tab) => {
        onSelect(tab);
    }, 500), []);

    if (!isDataLoaded) {
        return (
            <span className="mx-2 mx-xl-3">
                <Skeleton
                    height={38}
                    width={144}
                />
            </span>
        );
    }

    return (
        <Dropdown
            className="mx-2 mx-xl-3"
            data-testid="defaultTabDropdown"
            onSelect={handleSelect}
        >
            <Tooltip
                tooltip={t('agendaRoute.setDefaultTab.tooltip')}
                placement="top-start"
                isDisabled={defaultTab !== 'objects'}
            >
                <Dropdown.Toggle
                    variant="outline-light"
                    className="text-dark px-3 text-reset font-size-16 d-flex align-items-center"
                    style={{ height: '40px' }}
                >
                    <>
                        <Pin2Icon className="mr-2" width={24} />
                        <span>{t(`agendaRoute.${defaultTab}`)}</span>
                    </>
                </Dropdown.Toggle>
            </Tooltip>
            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
                {options.map((tab) => (
                    <Dropdown.Item
                        key={`route-${tab}`}
                        active={tab === defaultTab}
                        eventKey={tab}
                    >
                        {t(`agendaRoute.${tab}`)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

DefaultTabDropdown.propTypes = {
    isDataLoaded: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func,
    defaultTab: PropTypes.string,
};

export default DefaultTabDropdown;
