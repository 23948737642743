import React, {
    useState, useMemo, useEffect, useRef,
} from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
    useHistory, useParams, Redirect,
} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Store
import * as actions from 'store/actions';

// Components
import {
    Form, Row, Col, InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import {
    Footer, FileUpload, Switch, Schedule, WorkBreaks, PhoneInput, ConfirmationModal,
} from 'components';

// Styles

// Utils
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { permissionsResourceSelector } from 'store/selectors/permissions';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { Button } from 'components/Common/Button';
import { useMobile } from 'hooks/useMobile';
import Container from 'components/Layout/Container/Container';
import { BackButton } from 'components/Common/BackButton';
import classNames from 'classnames';
import { productsLoadersSelector } from 'store/selectors/products';
import { InfoIcon } from '../../../../../components/Icon/Icon';
import { Tooltip } from '../../../../../components/Common/Tooltip';

import { styles as selectStyles, dangerTheme, theme } from '../../../../../styles/select';
import * as styles from './Add.module.scss';

import * as SERVICES_ACTIONS from '../../../../../store/actions/services';

import * as SERVICES_SELECTORS from '../../../../../store/selectors/services';

const FieldLoader = ({ height = 38 }) => (
    <div className="position-relative" style={{ top: -3, marginBottom: -4 }}>
        <Skeleton
            width="100%"
            height={height}
        />
    </div>
);

const Add = () => {
    const { t } = useTranslation();
    /* istanbul ignore next */
    const schema = useRef(yup.object({
        image: yup.mixed().required(t('addEmployeeRoute.pleaseSelectEmployeeImage')),
        firstName: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEmployeeRoute.firstName') })),
        lastName: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEmployeeRoute.lastName') })),
        description: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEmployeeRoute.description') })),
        services: yup.array().of(yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        })).notRequired(),
        canLogin: yup.boolean().required(),
        email: yup
            .string()
            .email(t('validationErrors.invalidEmailAddress')),
        contactNumber: yup
            .string()
            .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, t('validationErrors.invalidPhoneNumber')),
        employeeType: yup
            .string()
            .when('canLogin', {
                is: true,
                then: (employeeType) => employeeType
                    .oneOf(['basic', 'plus', 'full'])
                    .required(),
            }),
        sendWelcome: yup
            .boolean()
            .when('canLogin', {
                is: true,
                then: (sendWelcome) => sendWelcome
                    .required(),
            }),

        futureBooking: yup.number()
            .positive(t('validationErrors.mustBePositiveNumber', { name: t('addGroupRoute.days') }))
            .max(1000, t('validationErrors.mustBeNoLongerThen', { field: 'Future bookings', days: 1000 })),
        hasOwnSchedule: yup.boolean().required(),
        schedule: yup
            .object(_.times(7).reduce((schedule, i) => ({
                ...schedule,
                [moment().isoWeekday(i).format('dddd')]: yup.object({
                    enabled: yup.boolean(),
                    from: yup.mixed().when(`schedule.${moment().isoWeekday(i).format('dddd')}.to`, (to, from) => (to ? from.required() : from.nullable())),
                    to: yup.mixed().when(`schedule.${moment().isoWeekday(i).format('dddd')}.from`, (from, to) => (from ? to.required() : to.nullable())),
                }).nullable(),
            }), {})),
        hasWorkBreaks: yup.boolean().required(),
        workBreaks: yup
            .object(_.times(7).reduce((schedule, i) => ({
                ...schedule,
                [moment().isoWeekday(i).format('dddd')]: yup.array(yup.object({
                    from: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('addBookingModal.field') })),
                    to: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('addBookingModal.field') })),
                })),
            }), {})),
    })).current;

    const { id } = useParams();
    const isMobile = useMobile();
    const [loading, setLoading] = useState(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isDeletingObject, setIsDeletingObject] = useState(false);

    const { loaded: isShopLoaded, id: shopID } = useSelector((state) => state.shop);
    const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const workingHours = useSelector((state) => state.shop?.workingDays);
    const { banAdd, banEdit, banDelete } = useSelector(permissionsResourceSelector);
    const isLoaded = useSelector(productsLoadersSelector());

    const dispatch = useDispatch();

    const employee = useSelector((state) => state.employees.employees.find((obj) => obj.id === Number(id)));

    const initialValues = useMemo(() => ({
        image: employee?.image ?? null,
        firstName: employee?.employee?.first_name ?? '',
        lastName: employee?.employee?.last_name ?? '',
        description: employee?.description ?? '',
        services: employee?.services ?? [],
        canLogin: employee?.employee?.make_login ?? false,
        email: employee?.employee?.email ?? '',
        isTimeslotRecalc: employee?.timeslotRecalc ?? true,
        contactNumber: employee?.employee?.contact_number ?? '',
        employeeType: employee?.employee?.employee_type ?? 'basic',
        futureBooking: employee?.futureBooking
            ? moment(employee?.futureBooking).startOf('day')
                .diff(moment().startOf('day'), 'days')
            : '',
        sendWelcome: false,
        hasOwnSchedule: employee?.hasOwnSchedule ?? false,
        schedule: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd')).reduce((schedule, day) => {
            const workBreak = employee?.workingDays?.[day.toLowerCase()];

            return {
                ...schedule,
                [day]: workBreak?.from && workBreak?.to ? {
                    enabled: true,
                    from: moment(workBreak.from, 'HH:mm'),
                    to: moment(workBreak.to, 'HH:mm'),
                } : {
                    enabled: false,
                    from: workingHours && moment(workingHours[day.toLowerCase()].from, 'HH:mm'),
                    to: workingHours && moment(workingHours[day.toLowerCase()].to, 'HH:mm'),
                },
            };
        }, {}),
        hasWorkBreaks: !!employee?.workBreaks?.length,
        workBreaks: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd')).reduce((breaks, day) => ({
            ...breaks,
            [day]: employee?.workBreaks
                .filter(({ weekday }) => weekday.toLowerCase() === day.toLowerCase())
                .map(({ from, to }) => ({
                    from: moment(from, 'HH:mm'),
                    to: moment(to, 'HH:mm'),
                })) ?? [],
        }), {}),
    }), [workingHours, employee]);

    const { addToast } = useToasts();

    const {
        handleSubmit, handleChange, setFieldValue, values, isSubmitting, touched, errors,
    } = useFormik({
        validationSchema: schema,
        initialValues,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            const schedule = Object.entries(values.schedule).map(([day, { enabled, from, to }]) => [
                day,
                {
                    from: enabled ? from : null,
                    to: enabled ? to : null,
                },
            ]).reduce((schedule, [day, value]) => ({
                ...schedule,
                [day]: value,
            }), {});

            const toast = !id
                ? t('addEmployeeRoute.employeeAddedSuccessfully')
                : t('addEmployeeRoute.employeeEditedSuccessfully');

            const handler = !id ? actions.addEmployee({
                employee: {
                    ...values,
                    schedule,
                },
            }) : actions.editEmployee({
                employee: {
                    ...values,
                    schedule,
                    id: Number(id),
                },
            });

            dispatch(handler)
                .then(() => {
                    addToast(toast, {
                        appearance: 'success',
                    });
                    push('/agenda/employees');
                })
                .catch(({ message, errors }) => {
                    if (errors?.schedule || errors?.workBreaks) {
                        const error = errors?.schedule || errors?.workBreaks;
                        addToast(error, {
                            appearance: 'error',
                        });
                    }
                    if (message) {
                        addToast(message, {
                            appearance: 'error',
                        });
                    } else {
                        setErrors(errors || {});
                    }

                    setSubmitting(false);
                });
        },
        enableReinitialize: true,
    });

    const { push } = useHistory();

    useEffect(() => {
        if (!shopID || !isShopLoaded || !isLoaded.employees) {
            return;
        }

        if (id) {
            setLoading(true);
            dispatch(actions.getProductById({ productId: id, type: 'employee' }))
                .catch(() => push('/agenda/employees'))
                .finally(() => setLoading(false));
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [shopID, isShopLoaded, isLoaded.employees]);

    const onDeleteHandler = async () => {
        if (!id || banDelete || isDeletingObject) {
            return;
        }

        setIsDeletingObject(true);
        try {
            await dispatch(actions.deleteEmployee(id));
            addToast(t('addEmployeeRoute.employeeDeletedSuccessfully'), {
                appearance: 'success',
            });
            setDeleteModalVisible(false);
            push('/agenda/employees');
        } catch (error) {
            if (!error?.message) return;
            addToast(error.message, {
                appearance: 'error',
            });
        } finally {
            setIsDeletingObject(false);
        }
    };

    if (banAdd || banEdit) {
        return <Redirect to="/agenda/employees" />;
    }

    return (
        <>
            <TextSubHeader
                text={!id ? t('addEmployeeRoute.addNewEmployee') : t('addEmployeeRoute.editEmployee')}
                before={(
                    <BackButton
                        href="/agenda/employees"
                    />
                )}
                rightActions={id && !banDelete && !isMobile && (
                    <Button
                        onClick={() => {
                            if (banDelete) return;
                            setDeleteModalVisible(true);
                        }}
                        color="red"
                    >
                        {t('agendaRoute.deleteResource')}
                    </Button>
                )}
            />
            <Container>
                <Form noValidate onSubmit={handleSubmit} className="w-100">
                    <Row>
                        <Col xs={12} md={6} lg={6} xl={3}>
                            <FileUpload
                                name="image"
                                label={(touched.image && errors.image) || t('addEmployeeRoute.uploadEmployeeImage')}
                                isInvalid={touched.image && !!errors.image}
                                onChange={(file) => setFieldValue('image', file)}
                                defaultValue={values.image}
                                square
                                loading={loading}
                            />
                        </Col>

                        <Col xs={12} md={6} lg={6} xl={9} className="mt-4 mt-md-0">
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addEmployeeRoute.firstName')}
                                        </Form.Label>

                                        {!loading && (
                                            <Form.Control
                                                name="firstName"
                                                type="text"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                isInvalid={touched.firstName && !!errors.firstName}
                                            />
                                        )}

                                        {loading && <FieldLoader />}

                                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addEmployeeRoute.lastName')}
                                        </Form.Label>

                                        {!loading && (
                                            <Form.Control
                                                name="lastName"
                                                type="text"
                                                value={values.lastName}
                                                onChange={handleChange}
                                                isInvalid={touched.lastName && !!errors.lastName}
                                            />
                                        )}

                                        {loading && <FieldLoader />}

                                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group>
                                <Form.Label>
                                    {t('addEmployeeRoute.description')}
                                </Form.Label>

                                {!loading && (
                                    <Form.Control
                                        name="description"
                                        as="textarea"
                                        rows={3}
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={touched.description && !!errors.description}
                                    />
                                )}

                                {loading && <FieldLoader />}

                                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                {!loading && (
                                    <Form.Check
                                        id="isTimeslotRecalc"
                                        name="isTimeslotRecalc"
                                        type="checkbox"
                                        label={t('addEmployeeRoute.timeslotRecalc')}
                                        checked={values.isTimeslotRecalc}
                                        onChange={handleChange}
                                    />
                                )}

                                {loading && <FieldLoader />}
                            </Form.Group>

                            <Form.Group>
                                <div className="d-flex align-items-center mb-2">
                                    <Form.Label className="d-flex mb-0 flex-grow-1">
                                        {t('addEmployeeRoute.services.label')}
                                        <InfoTooltip
                                            text={t('addEmployeeRoute.services.tooltip')}
                                        />
                                    </Form.Label>

                                    {!loading && (
                                        <>
                                            <button
                                                name="selectAll"
                                                className="bg-transparent border-0 p-0 font-size-14 text-dark-gray mr-2"
                                                type="button"
                                                onClick={() => setFieldValue('services', companyServices.map(({ id, name }) => ({
                                                    id,
                                                    name,
                                                })))}
                                            >
                                                {t('addEmployeeRoute.selectAll')}
                                            </button>

                                            <button
                                                name="removeAll"
                                                className="bg-transparent border-0 p-0 font-size-14 text-dark-gray"
                                                type="button"
                                                onClick={() => setFieldValue('services', [])}
                                            >
                                                {t('addEmployeeRoute.removeAll')}
                                            </button>
                                        </>
                                    )}
                                </div>

                                {!loading && (
                                    <Select
                                        name="services"
                                        styles={selectStyles}
                                        theme={touched.services && !!errors.services ? dangerTheme : theme}
                                        options={companyServices.map(({ id, name }) => ({ value: id, label: name }))}
                                        value={values.services.map(({ id, name }) => ({
                                            value: id,
                                            label: name,
                                        }))}
                                        onChange={(selected) => {
                                            if (!selected) {
                                                return setFieldValue('services', []);
                                            }
                                            setFieldValue('services', selected.map(({ value, label }) => ({
                                                id: value,
                                                name: label,
                                            })));
                                        }}
                                        isMulti
                                        isClearable={false}
                                        placeholder={t('placeholders.select')}
                                    />
                                )}

                                {loading && <FieldLoader />}

                                <Form.Control.Feedback
                                    className={touched.services && !!errors.services && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.services}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-0">
                                <Form.Label className="d-flex align-items-center">
                                    {t('addEmployeeRoute.howLongBookingsCanBeMade.label')}
                                    <InfoTooltip
                                        text={t('addEmployeeRoute.howLongBookingsCanBeMade.tooltip')}
                                        placement="bottom"
                                    />
                                </Form.Label>
                                {!loading && (
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text
                                                className={classNames({
                                                    'border-danger text-danger': !!touched.futureBooking && !!errors.futureBooking,
                                                }, 'bg-very-light')}
                                            >
                                                {t('addEmployeeRoute.days')}
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="number"
                                            name="futureBooking"
                                            value={values.futureBooking}
                                            onChange={handleChange}
                                            isInvalid={!!touched.futureBooking && !!errors.futureBooking}
                                            className="default-borders"
                                        />
                                    </InputGroup>
                                )}

                                {loading && <FieldLoader />}

                                <Form.Control.Feedback
                                    className={!!touched.futureBooking && !!errors.futureBooking && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.futureBooking}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <Form.Group className="d-flex align-items-center">
                                {!loading && (
                                    <Switch
                                        name="canLogin"
                                        checked={values.canLogin}
                                        onChange={(canLogin) => {
                                            setFieldValue('canLogin', canLogin);
                                            if (id) return;
                                            setFieldValue('sendWelcome', true);
                                        }}
                                        disabled={employee?.employee?.banned}
                                    />
                                )}

                                {loading && <FieldLoader />}

                                <Form.Label className="ml-3 mb-0 font-weight-normal">
                                    {t('addEmployeeRoute.makeLogin')}
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>

                    {values.canLogin && (
                        <>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addEmployeeRoute.emailAddress')}
                                        </Form.Label>
                                        <Form.Control
                                            name="email"
                                            type="text"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={touched.email && !!errors.email}
                                            disabled={(values.canLogin && employee?.employee?.email) || employee?.employee?.banned}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6}>
                                    <label className="form-label">{t('addEmployeeRoute.contactNumber')}</label>
                                    <PhoneInput
                                        name="contactNumber"
                                        value={values.contactNumber}
                                        onChange={(contactNumber) => setFieldValue('contactNumber', contactNumber)}
                                        isInvalid={touched.contactNumber && !!errors.contactNumber}
                                        disabled={employee?.employee?.banned}
                                    />
                                    <Form.Control.Feedback
                                        className={touched.contactNumber && !!errors.contactNumber && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.contactNumber}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addEmployeeRoute.employeeType')}
                                        </Form.Label>
                                        <div className="d-flex flex-column">
                                            {['basic', 'plus', 'full'].map((type) => (
                                                <div className={styles.checkboxWrapper}>
                                                    <Form.Check
                                                        key={type}
                                                        id={`employeeType.${type}`}
                                                        className="my-2"
                                                        type="radio"
                                                        name={type}
                                                        label={t(`addEmployeeRoute.${type}.label`)}
                                                        checked={values.employeeType === type}
                                                        onChange={() => setFieldValue('employeeType', type)}
                                                        disabled={employee?.employee?.banned}
                                                    />

                                                    <Tooltip
                                                        tooltip={t(`addEmployeeRoute.${type}.tooltip`)}
                                                        placement="bottom"
                                                    >
                                                        <div className={styles.infoIcon}>
                                                            <InfoIcon />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            ))}
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mb-4">
                                    <Form.Check
                                        id="sendWelcome"
                                        name="sendWelcome"
                                        type="checkbox"
                                        label={!id ? t('addEmployeeRoute.sendWelcome') : t('addEmployeeRoute.resetPassword')}
                                        checked={values.sendWelcome}
                                        onChange={handleChange}
                                        disabled={employee?.employee?.banned}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}

                    <Row className="mt-2">
                        <Col>

                            {!loading && (
                                <Form.Group className="d-flex align-items-center">
                                    <Switch
                                        name="hasOwnSchedule"
                                        checked={values.hasOwnSchedule}
                                        onChange={(hasOwnSchedule) => setFieldValue('hasOwnSchedule', hasOwnSchedule)}
                                    />
                                    <Form.Label className="ml-3 mb-0 font-weight-normal">
                                        {t('addEmployeeRoute.hasOwnSchedule')}
                                    </Form.Label>
                                </Form.Group>
                            )}

                            {loading && <FieldLoader />}

                            {(!loading && values.hasOwnSchedule) && (
                                <div className="mb-2">
                                    <Schedule
                                        name="schedule"
                                        value={values.schedule}
                                        onChange={({
                                            type, day, enabled, from, to,
                                        }) => {
                                            if (type === 'UPDATE') {
                                                setFieldValue('schedule', {
                                                    ...values.schedule,
                                                    [day]: {
                                                        enabled: enabled !== null && enabled !== undefined ? enabled : values.schedule[day].enabled,
                                                        from: from || values.schedule[day].from,
                                                        to: to || values.schedule[day].to,
                                                    },
                                                });
                                            } else {
                                                const mondayDay = values.schedule.Monday;
                                                if (!mondayDay?.enabled) return;

                                                setFieldValue('schedule', _.times(7)
                                                    .map((i) => moment().isoWeekday(i).format('dddd'))
                                                    .reduce((schedule, day) => ({
                                                        ...schedule,
                                                        [day]: { ...mondayDay },
                                                    }), {}));
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            {!loading && (
                                <Form.Group className="d-flex align-items-center">
                                    <Switch
                                        name="hasWorkBreaks"
                                        checked={values.hasWorkBreaks}
                                        onChange={(hasWorkBreaks) => setFieldValue('hasWorkBreaks', hasWorkBreaks)}
                                    />
                                    <Form.Label className="ml-3 mb-0 font-weight-normal">
                                        {t('addEmployeeRoute.workBreaks')}
                                    </Form.Label>
                                </Form.Group>
                            )}

                            {loading && <FieldLoader />}

                            {(!loading && values.hasWorkBreaks) && (
                                <WorkBreaks
                                    name="workBreaks"
                                    className="mt-4"
                                    value={values.workBreaks}
                                    onChange={({
                                        type, day, workBreak, index, value,
                                    }) => {
                                        switch (type) {
                                        case 'ADD':
                                            setFieldValue('workBreaks', {
                                                ...values.workBreaks,
                                                [day]: [
                                                    ...values.workBreaks[day],
                                                    workBreak,
                                                ],
                                            });
                                            break;
                                        case 'REMOVE':
                                            setFieldValue('workBreaks', {
                                                ...values.workBreaks,
                                                [day]: values.workBreaks[day].filter((_, i) => i !== index),
                                            });
                                            break;
                                        case 'UPDATE':
                                            setFieldValue('workBreaks', {
                                                ...values.workBreaks,
                                                [day]: values.workBreaks[day].map((el, i) => (i !== index ? el : workBreak)),
                                            });
                                            break;
                                        default:
                                            setFieldValue('workBreaks', value);
                                            break;
                                        }
                                    }}
                                    errors={errors.workBreaks}
                                    touched={touched.workBreaks}
                                    workingHours={workingHours}
                                />
                            )}
                        </Col>
                    </Row>

                    <div
                        className="d-flex align-items-center border-top pt-3 mt-3"
                    >
                        <Button
                            color="outline"
                            href="/agenda/employees"
                        >
                            {t('addEmployeeRoute.cancel')}
                        </Button>

                        <div className="d-flex justify-content-end flex-grow-1">
                            <Button type="submit" loading={isSubmitting}>
                                {t(`addEmployeeRoute.${!id ? 'addEmployee' : 'save'}`)}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>

            <Footer className="d-flex d-lg-none justify-content-between">
                {Boolean(id && !banDelete) && (
                    <Button
                        onClick={() => {
                            if (banDelete) return;
                            setDeleteModalVisible(true);
                        }}
                        color="red"
                        size="extra-large"
                        className="rounded-0 px-3 px-sm-5"
                    >
                        {t('agendaRoute.deleteResource')}
                    </Button>
                )}
            </Footer>

            <ConfirmationModal
                isShow={deleteModalVisible}
                hide={() => setDeleteModalVisible(false)}
                loading={isDeletingObject}
                confirmAction={onDeleteHandler}
                titleText={t('addEmployeeRoute.deleteEmployee')}
                bodyText={t('addEmployeeRoute.areYouSureYouWantToDeleteEmployee')}
                deleteText={t('addObjectRoute.delete')}
            />
        </>
    );
};

export default Add;
