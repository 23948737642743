import { ClientsList } from './clientsList';
import { ClientInfo } from './clientPage';

import {
    CLIENTS_LIST_ID_PATH,
    CLIENTS_LIST_PATH,
} from '../../../const/CLIENT_PATH';

const routes = [
    {
        component: ClientsList,
        exact: true,
        path: CLIENTS_LIST_PATH,
    },
    {
        component: ClientInfo,
        exact: false,
        path: CLIENTS_LIST_ID_PATH,
    },
];

export default routes;
