import * as TYPES from '../../actions/bundles/actionTypes';

const INITIAL_STATE = {
    items: [],
    loading: false,
};

export const companyBundlesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (TYPES.COMPANY_BUNDLES_SET): {
        const { companyBundles } = payload;
        return {
            ...state,
            items: companyBundles,
        };
    }
    case (TYPES.COMPANY_BUNDLES_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    default:
        return state;
    }
};
