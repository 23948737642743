/* eslint-disable import/prefer-default-export */
import color from 'color';

export const phoneSelect = {
    control: (provided, { isFocused }) => ({
        ...provided,
        height: '100%',
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderColor: `${provided.borderColor} !important`,
        borderRight: 0,
        boxShadow: isFocused && `0 0 0 0.2rem ${color(provided.borderColor).fade(0.5).string()}`,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    }),
    input: (provided) => ({
        ...provided,
    }),
    singleValue: (provided) => ({
        ...provided,
        position: 'static',
        fontWeight: 'bold',
        padding: 0,
        minWidth: 55,
        top: 0,
        transform: 'none',
        maxWidth: undefined,
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 4,
        paddingTop: 8,
        paddingBottom: 8,
    }),
    menu: (provided) => ({
        ...provided,
        minWidth: 300,
    }),
};
