// extracted by mini-css-extract-plugin
var _1 = "Chip_button__OyHUn";
var _2 = "Chip_capitalize__gZQbe";
var _3 = "Chip_chip__jNe1y";
var _4 = "Chip_chipDelete__NjXHc";
var _5 = "Chip_grayField__+7SYi";
var _6 = "Chip_greenField__JO7pU";
var _7 = "Chip_redField__wp48X";
var _8 = "Chip_regularField__oIq+m";
var _9 = "Chip_smallField__4a4Ra";
var _a = "Chip_text__-ay3h";
var _b = "Chip_yellowField__Kgpv4";
export { _1 as "button", _2 as "capitalize", _3 as "chip", _4 as "chipDelete", _5 as "grayField", _6 as "greenField", _7 as "redField", _8 as "regularField", _9 as "smallField", _a as "text", _b as "yellowField" }
