import LogsList from './logsList';

import {
    ACCESS_LOGS_PAGE,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: LogsList,
        exact: false,
        path: ACCESS_LOGS_PAGE,
    },
];

export default routes;
