import * as actionTypes from 'store/actions/actionTypes';

const initialState = null;

export const toast = (state = initialState, { type, payload }) => {
    switch (type) {
    case actionTypes.SHOW_TOAST: {
        return payload;
    }
    default:
        return state;
    }
};
