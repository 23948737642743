import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import {
    dangerTheme as selectDangerTheme,
    styles as selectStyles,
    theme as selectTheme,
} from '../../../../styles/select';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'addOrEdit.form.fields';

const BundleNameAndServicesFormRow = (props) => {
    const {
        sold,
        values,
        services,
        validation,
        onChangeName,
        onChangeServices,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const servicesSelectTheme = validation?.services?.isInvalid ? selectDangerTheme : selectTheme;

    const servicesOptions = useMemo(() => (
        services.map((s) => ({ label: s.name, value: s.id }))
    ), [services]);

    return (
        <Row>
            <Col
                xs={12}
                lg={6}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label>
                        {t(`${T_PREFIX}.name.label`)}
                    </Form.Label>
                    <Form.Control
                        name="name"
                        value={values.name}
                        disabled={sold}
                        isInvalid={validation?.name?.isInvalid}
                        onChange={onChangeName}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.name?.isInvalid,
                        })}
                    >
                        {validation?.name?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col
                xs={12}
                lg={6}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label>
                        {t(`${T_PREFIX}.services.label`)}
                    </Form.Label>
                    <Select
                        name="services"
                        value={values.services}
                        theme={servicesSelectTheme}
                        styles={selectStyles}
                        options={servicesOptions}
                        isMulti
                        onChange={onChangeServices}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.services?.isInvalid,
                        })}
                    >
                        {validation?.services?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

BundleNameAndServicesFormRow.propTypes = {
    sold: PropTypes.bool,
    values: PropTypes.object.isRequired,
    services: PropTypes.array,
    validation: PropTypes.object,
    onChangeName: PropTypes.func,
    onChangeServices: PropTypes.func,
};

BundleNameAndServicesFormRow.defaultProps = {
    sold: false,
    services: [],
    validation: null,
    onChangeName: emptyFunc,
    onChangeServices: emptyFunc,
};

export default BundleNameAndServicesFormRow;
