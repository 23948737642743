import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalFooter from 'react-bootstrap/ModalFooter';

import * as styles from './DialogFooter.module.scss';

const DialogFooter = (props) => {
    const {
        children,
        className,
    } = props;

    return (
        <ModalFooter
            className={classNames(
                styles.dialogFooter,
                className,
            )}
            data-testid={props['data-testid']}
        >
            {children}
        </ModalFooter>
    );
};

DialogFooter.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
};

DialogFooter.defaultProps = {
    children: null,
    className: '',
    'data-testid': 'data-test-dialog-footer',
};

export default DialogFooter;
