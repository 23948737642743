/* eslint-disable import/prefer-default-export */
import * as ACCESS_LOGS_TYPES from '../../actions/access/actionTypes';
import { ACCESS_LOGS_SORT_BY_TYPE } from '../../../const/access/ACCESS_LOGS_SORT_BY_TYPE';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    sortOptions: {
        sortBy: ACCESS_LOGS_SORT_BY_TYPE.START_DATE,
        orderBy: 1,
    },
    showAll: false,
    isLoading: false,
    itemsCount: 0,
    pagesCount: 0,
    itemsPerPage: 10,
    date: { period: 'day' },
    filter: null,
    hasNewItems: false,
};

export const accessLogsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (ACCESS_LOGS_TYPES.GET_ACCESS_LOGS_RESOLVED): {
        const { items, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items,
            page,
            itemsCount,
            pagesCount,
            hasNewItems: false,
            isLoading: false,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_CLEAR): {
        return INITIAL_STATE;
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: false,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_SHOW_ALL): {
        return {
            ...state,
            page: 0,
            showAll: true,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_SEARCH_SET): {
        const { search } = payload;

        return {
            ...state,
            search,
        };
    }
    case (ACCESS_LOGS_TYPES.ACCESS_LOGS_SELECTED_DATE_SET): {
        const { dateFrom, dateTo, period } = payload;

        return {
            ...state,
            date: {
                period,
                dateFrom,
                dateTo,
            },
        };
    }
    case ACCESS_LOGS_TYPES.ACCESS_LOGS_FILTER_SET: {
        const { filter } = payload;

        return {
            ...state, filter,
        };
    }
    case (ACCESS_LOGS_TYPES.GET_ACCESS_LOGS_TRIGGER): {
        const { silent } = payload ?? {};
        return {
            ...state,
            isLoading: !silent ?? true,
        };
    }
    case (ACCESS_LOGS_TYPES.GET_ACCESS_LOGS_REJECTED): {
        return {
            ...state,
            isLoading: false,
        };
    }
    case ACCESS_LOGS_TYPES.ACCESS_LOGS_SORT_OPTIONS_SET: {
        const { sortOptions: { sortBy, orderBy } } = payload;

        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    case ACCESS_LOGS_TYPES.APPEND_ACCESS_LOG_TRIGGER: {
        const { log } = payload;
        if (state.filter) {
            const isAllowedEntry = state.filter === 'allowed_entry' && log.direction === 'in' && log.allowed === true;
            const isDeniedEntry = state.filter === 'denied_entry' && log.direction === 'in' && log.allowed === false;
            const isExit = state.filter === 'exit' && log.direction === 'out' && log.allowed === true;
            const isError = state.filter === 'error' && log.allowed === null;

            if (!isAllowedEntry && !isDeniedEntry && !isExit && !isError) {
                return state;
            }
        }

        if (state.page > 1 || state.search) {
            return {
                ...state,
                hasNewItems: true,
            };
        }
        const oldItems = state.items;
        if (oldItems.length >= 10 && !state.showAll) {
            oldItems.pop();
        }
        return {
            ...state,
            itemsCount: state.itemsCount + 1,
            items: [log, ...oldItems],
        };
    }
    default:
        return state;
    }
};
