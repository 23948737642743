import i18n from 'services/i18n';
import * as actionTypes from '../actions/locales/actionTypes';

const LOCALES = ['en-GB', 'nl-NL', 'de-DE'];

const initialState = {
    locale: LOCALES.find((l) => l.toLowerCase().startsWith(i18n.language?.toLowerCase?.())) || 'en-GB',
    locales: LOCALES,
};

export const locales = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.CHANGE_LOCALE:
        const { locale } = payload;
        return {
            ...state,
            locale,
        };
    default:
        return state;
    }
};
