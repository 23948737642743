import { VALIDITY_UNIT, VALIDITY_UNITS_ARRAY } from './VALIDITY_UNIT';

export const BUNDLE_PROP = {
    NAME: {
        MIN_LENGTH: 2,
        MAX_LENGTH: 250,
        DEFAULT: '',
    },
    SERVICES: {
        MIN: 1,
        DEFAULT: [],
    },
    MAX_USAGES: {
        VALUE: {
            MIN: 1,
            MAX: 1000,
            STEP: 1,
            DEFAULT: 1,
        },
        UNLIMITED: {
            DEFAULT: false,
        },
    },
    VALIDITY: {
        VALUE: {
            MIN: 1,
            MAX: 360,
            STEP: 1,
            DEFAULT: 1,
        },
        UNIT: {
            DEFAULT: VALIDITY_UNIT.DAYS,
            ENUM: VALIDITY_UNITS_ARRAY,
        },
        UNLIMITED: {
            DEFAULT: false,
        },
    },
    PRICE: {
        MIN: 0,
        MAX: 9900,
        STEP: 1,
        DEFAULT: 0.00,
        TO_FIXED: 2,
    },
    VAT_RATE: {
        ENUM: [0, 6, 9, 20, 21],
        DEFAULT: 21,
    },
    DESCRIPTION: {
        DEFAULT: '',
        MAX_LENGTH: 600,
    },
    STATUS: {
        DEFAULT: true,
    },
    TRIAL: {
        DEFAULT: false,
    },
};
