import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { Avatar } from 'components/Common/Avatar';
import { Row } from 'components/Common/Row';
import { CLIENTS_LIST_ID_PAGE } from 'const/CLIENT_URL';
import * as LOCALES_SELECTORS from 'store/selectors/locales';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { safeJsonParse } from 'helpers/json/safeJsonParse';
import LogsBadge from '../LogsBadge/LogsBadge';

import * as styles from './LogsTableBodyRow.module.scss';

const LogsTableBodyRow = (props) => {
    const { log } = props;

    const {
        client, createdAt, details, gate,
    } = log || {};

    const { locale } = useSelector((state) => state.locales);
    const shortLocale = useSelector(LOCALES_SELECTORS.shortLocaleSelector);
    const detailsObjetct = safeJsonParse(details);

    return (
        <ListTableBodyRow>
            <ListTableBodyCell className="text-nowrap">
                {moment.unix(createdAt).tz('Europe/Amsterdam').locale(locale).format('DD MMM YYYY · HH:mm')}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">
                <Row gap={8}>
                    <a href={client?.id ? CLIENTS_LIST_ID_PAGE({ clientId: client.id }) : undefined}>
                        <Avatar
                            src={client?.avatarUrl || ''}
                            size={40}
                        />
                    </a>
                    <a href={client?.id ? CLIENTS_LIST_ID_PAGE({ clientId: client.id }) : undefined} className={styles.name}>
                        {`${client?.firstName || ''} ${client?.lastName || ''}`.trim() || '-'}
                    </a>
                </Row>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">
                {gate.name}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">
                {gate.shop.name}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <LogsBadge log={log} />
            </ListTableBodyCell>

            <ListTableBodyCell className="text-nowrap">{detailsObjetct?.[shortLocale] || ''}</ListTableBodyCell>
        </ListTableBodyRow>
    );
};

LogsTableBodyRow.propTypes = {
    log: PropTypes.object,
};

LogsTableBodyRow.defaultProps = {
    log: null,
};

export default LogsTableBodyRow;
