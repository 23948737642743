import { Button } from 'components/Common/Button';
import { Column } from 'components/Common/Column';
import React from 'react';
import { Form } from 'react-bootstrap';

import { ChevronDown, ChevronUp } from 'react-feather';

function NumberInputWithControls(props) {
    const {
        className,
        customOnChange,
        disabled,
        error,
        isInvalid,
        step,
        value,
        setFieldValue,
        fieldValueKey,
    } = props;

    const onChange = typeof customOnChange === 'function'
        ? customOnChange
        : (e) => setFieldValue(fieldValueKey, e.target.value);
    const increment = () => onChange({
        target: { name: fieldValueKey, value: Number(value) + step },
    });
    const decrement = () => onChange({
        target: { name: fieldValueKey, value: Number(value) - step },
    });

    return (
        <div className="position-relative">
            <Form.Control
                className={className}
                disabled={disabled}
                isInvalid={isInvalid}
                type="number"
                name={fieldValueKey}
                value={value}
                onChange={onChange}
            />
            <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
            <div
                className="position-absolute"
                style={{ top: 0, right: 0, height: 38 }}
            >
                <Column gap={0}>
                    <Button
                        color="outline"
                        size="small"
                        style={{
                            borderTop: 'none',
                            borderRight: 'none',
                            height: 19,
                            minHeight: 19,
                        }}
                        className="border-radius-0"
                        onClick={increment}
                        disabled={disabled}
                    >
                        <ChevronUp size={14} />
                    </Button>
                    <Button
                        color="outline"
                        size="small"
                        style={{
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            height: 19,
                            minHeight: 19,
                        }}
                        className="border-radius-0"
                        onClick={decrement}
                        disabled={disabled}
                    >
                        <ChevronDown size={14} />
                    </Button>
                </Column>
            </div>
        </div>
    );
}

NumberInputWithControls.defaultProps = {
    className: '',
    customOnChange: null,
    disabled: false,
    step: 1,
    value: '',
    setFieldValue: () => {},
    fieldValueKey: '',
};

export default NumberInputWithControls;
