// extracted by mini-css-extract-plugin
var _1 = "Badge_badge__qYPS2";
var _2 = "Badge_betaField__dt7E1";
var _3 = "Badge_button__T3b2n";
var _4 = "Badge_capitalize__yg5Ya";
var _5 = "Badge_extraSmallField__8oGPa";
var _6 = "Badge_grayField__aIsPj";
var _7 = "Badge_greenField__UFet7";
var _8 = "Badge_primaryField__OS0iw";
var _9 = "Badge_redField__jjU18";
var _a = "Badge_regularField__pT53y";
var _b = "Badge_smallField__RnKpI";
var _c = "Badge_yellowField__KdMOQ";
export { _1 as "badge", _2 as "betaField", _3 as "button", _4 as "capitalize", _5 as "extraSmallField", _6 as "grayField", _7 as "greenField", _8 as "primaryField", _9 as "redField", _a as "regularField", _b as "smallField", _c as "yellowField" }
