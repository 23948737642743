export const DARK_COLOR = '#000000';
export const LIGHT_COLOR = '#FFFFFF';

export function getContrastColor(backgroundColor) {
    if (!backgroundColor) return DARK_COLOR;

    let color = backgroundColor;
    // If the color is in the 3-character format, expand it to 6 characters
    if (color.length === 4) {
        color = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
    }

    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? DARK_COLOR : LIGHT_COLOR;
}
