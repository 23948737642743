import React from 'react';

// Hooks
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import * as actions from 'store/actions/bookings';

// Components
import moment from 'moment';
import { PlanningList } from '..';

// Utils

const Planning = ({
    week, year, productType = 'object', onBookingClick,
}) => {
    const isShopLoaded = useSelector((state) => state.shop.loaded);
    const bookings = useSelector((state) => state?.[`${productType}s`]?.bookings
        ?.filter(
            (booking) => state?.[`${productType}s`]?.filters?.[booking.product.id],
        )
        .filter(({ time: { from } }) => moment(from)
            .startOf('isoWeek')
            .isSame(moment().isoWeek(week).year(year), 'isoWeek'))
        .reduce((bookings, booking) => {
            const date = moment(booking.time.from).format('DD/MM/YYYY');
            if (bookings[date]) {
                return {
                    ...bookings,
                    [date]: [...bookings[date], booking],
                };
            }

            return {
                ...bookings,
                [date]: [booking],
            };
        }, {}));

    const bookingsLoading = useSelector((state) => state[`${productType}s`].bookingsLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isShopLoaded) {
            return;
        }

        const date = moment().isoWeek(week).year(year);
        dispatch(
            actions.getBookings(
                date.clone().startOf('isoWeek').unix(),
                date.clone().endOf('isoWeek').unix(),
                productType === 'object' ? 'basic' : productType,
            ),
        );
    }, [isShopLoaded, week, year]);

    return <PlanningList bookings={bookings} onBookingClick={onBookingClick} loading={bookingsLoading} />;
};

export default Planning;
