import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';

import { emptyFunc } from '../../../../../helpers/function/emptyFunc';

import * as styles from './Dialog.module.scss';

const Dialog = (props) => {
    const {
        children,
        size,
        visible,
        restoreFocus,
        className,
        onClose,
        dialogClassName,
    } = props;

    return (
        <Modal
            show={visible}
            size={size}
            centered
            restoreFocus={restoreFocus}
            contentClassName={classNames(
                styles.dialog,
                className,
            )}
            dialogClassName={dialogClassName}
            onHide={onClose}
            data-testid={props['data-testid']}
        >
            {children}
        </Modal>
    );
};

Dialog.propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf([
        'sm',
        'md',
        'lg',
        'xl',
    ]),
    visible: PropTypes.bool,
    restoreFocus: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func,
    dialogClassName: PropTypes.string,
    'data-testid': PropTypes.string,
};

Dialog.defaultProps = {
    children: null,
    size: 'lg',
    visible: false,
    restoreFocus: false,
    className: '',
    onClose: emptyFunc,
    dialogClassName: '',
    'data-testid': 'data-test-dialog',
};

export default Dialog;
