import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'components/Common/Row';

import { Column } from 'components/Common/Column';
import { useSelector } from 'react-redux';
import { CLIENTS_LIST_ID_PAGE } from 'const/CLIENT_URL';
import classNames from 'classnames';
import { Avatar } from 'components/Common/Avatar';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'components/Common/Tooltip';
import * as LOCALES_SELECTORS from 'store/selectors/locales';
import { safeJsonParse } from 'helpers/json/safeJsonParse';
import LogsBadge from '../LogsBadge/LogsBadge';

import * as styles from './LogsOverviewInfo.module.scss';

const LogsOverviewUserInfo = ({
    stat, loading,
}) => {
    const shortLocale = useSelector(LOCALES_SELECTORS.shortLocaleSelector);
    const detailsObjetct = safeJsonParse(stat.details);
    const details = useMemo(() => detailsObjetct?.[shortLocale], [detailsObjetct, shortLocale]);

    return (
        <Row gap={12} padding={12} align="start" stretched spaceBetween noOverflow>
            {loading ? <Skeleton height={40} width="100%" containerClassName="w-100" />
                : (
                    <>
                        <Row gap={12} align="start" noOverflow>
                            <a href={CLIENTS_LIST_ID_PAGE({ clientId: stat.clientId })}>
                                <Avatar
                                    src={stat.avatarUrl}
                                    size={40}
                                />
                            </a>
                            <div className={styles.info}>
                                <Column gap={0}>
                                    <a className={styles.name} href={CLIENTS_LIST_ID_PAGE({ clientId: stat.clientId })}>
                                        {stat.name}
                                    </a>
                                    <Row gap={4}>
                                        <span className={styles.label}>
                                            {stat.gate}
                                        </span>
                                        {!!details && (
                                            <>
                                                <span className={classNames(styles.labelSecondary, !stat.allowed && styles.labelError)}>
                                                    •
                                                </span>
                                                <Tooltip tooltip={details}>
                                                    <span className={classNames(styles.labelSecondary, !stat.allowed && styles.labelError)}>
                                                        {details}
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )}
                                    </Row>
                                </Column>
                            </div>
                        </Row>
                        <Column gap={4} align="end">
                            <LogsBadge log={stat} size="extra-small" />
                            <span className={styles.labelSecondary}>{stat.createdAt}</span>
                        </Column>
                    </>
                )}
        </Row>
    );
};

LogsOverviewUserInfo.propTypes = {
    stat: PropTypes.object,
    loading: PropTypes.bool,
};

LogsOverviewUserInfo.defaultProps = {
    stat: {},
    loading: false,
};

export default LogsOverviewUserInfo;
