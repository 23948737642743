import { INVOICE_STATUS } from './INVOICE_STATUS';

export const INVOICE_PROP = {
    NOTE: {
        MAX_LENGTH: 500,
        DEFAULT: '',
    },
    VAT_RATE: {
        ENUM: [0, 6, 9, 20, 21],
        DEFAULT: 21,
    },
    STATUS: {
        ENUM: Object.values(INVOICE_STATUS),
        DEFAULT: INVOICE_STATUS.DRAFT,
    },
    QUANTITY: {
        MIN: 1,
        MAX: 999,
        DEFAULT: 1,
    },
    PRICE: {
        MIN: -9900,
        MAX: 9900,
        DEFAULT: 0,
    },
    PRODUCTS: {
        MAX_COUNT: 50,
    },
};
