import React, { } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { emptyFunc } from 'helpers/function/emptyFunc';

import { Button } from 'components/Common/Button';
import { Form, Modal } from 'react-bootstrap';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as styles from './ClientNotesMobileFiltersDialog.module.scss';
import { ClientNotesFilter } from '../ClientNotesFilter';

const ClientNotesMobileFiltersDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { t: tc } = useTranslation();

    const {
        visible,
        onClose,
        selectedCategoriesIds,
        categories,
        onSelect,
    } = props;

    return (
        <Modal
            show={visible}
            centered
            restoreFocus={false}
            onHide={onClose}
            size="xs"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('notes.filter.label')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex' }}>
                <Form onSubmit={onClose} className={styles.container}>
                    <div>
                        <ClientNotesFilter
                            selectedCategoriesIds={selectedCategoriesIds}
                            categories={categories}
                            onSelect={onSelect}
                        />
                    </div>
                    <div className={styles.actions}>
                        <Button
                            color="yellow"
                            onClick={onClose}
                        >
                            {tc('placeholders.apply')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

ClientNotesMobileFiltersDialog.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    categories: PropTypes.array,
    selectedCategoriesIds: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
};

ClientNotesMobileFiltersDialog.defaultProps = {
    visible: false,
    categories: [],
    selectedCategoriesIds: [],
    onClose: emptyFunc,
};

export default ClientNotesMobileFiltersDialog;
