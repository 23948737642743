import * as actionTypes from '../actions/actionTypes';

const initialState = {
    addBookingDetails: null,
};

export const bookings = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.SET_ADD_BOOKING_DETAILS:
        const { time, product, service } = payload;
        return {
            ...state,
            addBookingDetails: {
                time,
                product,
                service,
            },
        };
    case actionTypes.CLEAR_ADD_BOOKING_DETAILS:
        return {
            ...state,
            addBookingDetails: null,
        };

    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_IN_SUCCEEDED:
        return state;

    default:
        return state;
    }
};
