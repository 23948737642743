const PREFIX = '@@CLIENTS/';
const CLIENT_ITEM = `${PREFIX}:CLIENT:ITEM`;

const CLIENT_ITEM_SUBSCRIPTIONS = `${CLIENT_ITEM}:SUBSCRIPTIONS`;
const CLIENT_ITEM_SUBSCRIPTIONS_LOADING = `${CLIENT_ITEM_SUBSCRIPTIONS}:LOADING`;
export const CLIENT_ITEM_SUBSCRIPTIONS_LOADING_SET = `${CLIENT_ITEM_SUBSCRIPTIONS_LOADING}:SET`;
export const CLIENT_ITEM_SUBSCRIPTIONS_GET = `${CLIENT_ITEM_SUBSCRIPTIONS}:GET`;
export const CLIENT_ITEM_SUBSCRIPTIONS_SET = `${CLIENT_ITEM_SUBSCRIPTIONS}:SET`;
const CLIENT_ITEM_SUBSCRIPTIONS_PAGE = `${CLIENT_ITEM_SUBSCRIPTIONS}:PAGE`;
export const CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET = `${CLIENT_ITEM_SUBSCRIPTIONS_PAGE}:SET`;
export const CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_FIRST = `${CLIENT_ITEM_SUBSCRIPTIONS_PAGE}:FIRST`;
export const CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_LAST = `${CLIENT_ITEM_SUBSCRIPTIONS_PAGE}:LAST`;
export const CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_PREV = `${CLIENT_ITEM_SUBSCRIPTIONS_PAGE}:PREV`;
export const CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_NEXT = `${CLIENT_ITEM_SUBSCRIPTIONS_PAGE}:NEXT`;
