import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { debounce } from 'lodash';
import * as styles from './Badge.module.scss';

const colorClasses = {
    red: styles.redField,
    green: styles.greenField,
    gray: styles.grayField,
    yellow: styles.yellowField,
    primary: styles.primaryField,
    beta: styles.betaField,
};

const sizeClasses = {
    regular: styles.regularField,
    small: styles.smallField,
    'extra-small': styles.extraSmallField,
};

const Badge = (props) => {
    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    return (
        <div
            className={
                classNames(
                    styles.badge,
                    props.capitalize && styles.capitalize,
                    colorClasses[props.color],
                    sizeClasses[props.size],
                    props.onClick && styles.button,
                )
            }
            onClick={props.onClick ? debouncedOnClick : undefined}
            role={props.onClick ? 'button' : undefined}
            data-testid={props['data-testid']}
        >
            {props.children}
        </div>
    );
};

Badge.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    capitalize: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    'data-testid': PropTypes.string,
};

Badge.defaultProps = {
    color: 'gray',
    size: 'regular',
    capitalize: false,
    'data-testid': 'data-test-badge',
};

export default Badge;
