import React from 'react';
import PropTypes from 'prop-types';
import ModalTitle from 'react-bootstrap/ModalTitle';

const DialogTitle = (props) => {
    const { children } = props;

    return (
        <ModalTitle data-testid={props['data-testid']}>
            {children}
        </ModalTitle>
    );
};

DialogTitle.propTypes = {
    children: PropTypes.node,
    'data-testid': PropTypes.string,
};

DialogTitle.defaultProps = {
    children: null,
    'data-testid': 'data-test-dialog-title',
};

export default DialogTitle;
