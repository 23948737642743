const prefix = '@@USER';

export const GET_USER_DETAILS = `${prefix}__GET_USER_DETAILS`;
export const GET_USER_DETAILS_TRIGGER = `${GET_USER_DETAILS}.TRIGGER`;
export const GET_USER_DETAILS_RESOLVED = `${GET_USER_DETAILS}.RESOLVED`;
export const GET_USER_DETAILS_REJECTED = `${GET_USER_DETAILS}.REJECTED`;

export const SET_DEFAULT_TAB = `${prefix}__SET_DEFAULT_TAB`;
export const SET_DEFAULT_TAB_TRIGGER = `${SET_DEFAULT_TAB}.TRIGGER`;
export const SET_DEFAULT_TAB_RESOLVED = `${SET_DEFAULT_TAB}.RESOLVED`;
export const SET_DEFAULT_TAB_REJECTED = `${SET_DEFAULT_TAB}.REJECTED`;

export const TOGGLE_SOUND_NOTIFICATIONS = `${prefix}__TOGGLE_SOUND_NOTIFICATIONS`;
export const TOGGLE_SOUND_NOTIFICATIONS_TRIGGER = `${TOGGLE_SOUND_NOTIFICATIONS}.TRIGGER`;
export const TOGGLE_SOUND_NOTIFICATIONS_RESOLVED = `${TOGGLE_SOUND_NOTIFICATIONS}.RESOLVED`;
export const TOGGLE_SOUND_NOTIFICATIONS_REJECTED = `${TOGGLE_SOUND_NOTIFICATIONS}.REJECTED`;
