import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Tooltip } from 'components/Common/Tooltip';
import { CopyIcon, Pen2Icon, Trash3Icon } from 'components/Icon/Icon';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';

function SubscriptionsTableListActions({
    id, canArchive, onArchive, onDuplicate,
}) {
    const { t } = useTranslation();
    const history = useHistory();

    const handleEdit = () => history.push(`/services/subscriptions/edit/${id}`);
    const handleArchive = () => onArchive({ subscriptionId: id });

    return (
        <Row gap={8}>
            <Tooltip
                tooltip={t('subscriptions.tableActions.edit')}
                placement="top"
                forButton
            >
                <IconButton
                    color="gray"
                    size={32}
                    onClick={handleEdit}
                >
                    <Pen2Icon />
                </IconButton>
            </Tooltip>
            <Tooltip
                tooltip={canArchive ? t('subscriptions.tableActions.archive') : t('subscriptions.tableActions.archiveDisabled')}
                placement="top"
                forButton
            >
                <IconButton
                    color="gray"
                    size={32}
                    onClick={handleArchive}
                    disabled={!canArchive}
                >
                    <Trash3Icon />
                </IconButton>
            </Tooltip>
            <Tooltip forButton tooltip={t('placeholders.duplicate')} placement="bottom">
                <IconButton
                    onClick={onDuplicate}
                    color="gray"
                    size={32}
                >
                    <CopyIcon height={24} width={24} />
                </IconButton>
            </Tooltip>
        </Row>
    );
}

export default SubscriptionsTableListActions;
