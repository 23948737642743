import React, {
    useState, useEffect, useRef, startTransition,
} from 'react';
import moment from 'moment';
import classNames from 'classnames';

import Calendar from '../../Calendar/Calendar';

import * as styles from './DateInput.module.scss';

const DateInput = (
    {
        className,
        value,
        format = 'DD/MM/YYYY',
        placeholder,
        onChange,
        isInvalid,
        isDisabled = false,
        align = 'left',
        iconComponent: IconComponent,
        noDisplayYear = false,
        maxYear,
        minYear,
        placement = 'bottom',
        minDate = null,
        maxDate = null,
    },
) => {
    const [isVisible, setVisible] = useState(false);
    const [month, setMonth] = useState((value || moment()).month());
    const [year, setYear] = useState((value || moment()).year());

    const ref = useRef(null);

    useEffect(() => {
        if (!isVisible) {
            return;
        }

        const handleClick = (e) => {
            if (!ref.current || ref.current.contains(e.target)) {
                return;
            }

            setVisible(false);
        };

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, [isVisible]);

    useEffect(() => {
        if (value) {
            setYear(value.year());
            setMonth(value.month());
        }
    }, [value]);
    return (
        <div className={styles.wrapper}>
            <button
                type="button"
                className={classNames(
                    styles.button,
                    className,
                    'form-control',
                    {
                        'is-invalid': isInvalid,
                        [styles.withIcon]: Boolean(IconComponent),
                    },
                )}
                onClick={() => startTransition(() => setVisible((prev) => !prev))}
                disabled={isDisabled}
            >
                <span className={classNames(styles.buttonText, { 'text-muted': !value })}>
                    {value
                        ? value.format(format)
                        : placeholder}
                </span>

                {IconComponent && !isInvalid && (
                    <IconComponent className={styles.icon} />
                )}
            </button>

            {isVisible && (
                <div
                    ref={ref}
                    className={classNames(
                        styles.calendar,
                        styles[align] ?? styles.left,
                        styles[placement] ?? styles.bottom,
                        'bg-white',
                    )}
                >
                    <Calendar
                        selectedDate={value}
                        onSelectDate={(date) => {
                            setVisible(false);
                            if (!value) {
                                onChange(date?.clone());
                                return;
                            }

                            onChange(date?.clone().hours(value.hours()).minutes(value.minutes()) ?? value);
                        }}
                        month={month}
                        year={year}
                        maxYear={maxYear}
                        minYear={minYear}
                        noDisplayYear={noDisplayYear}
                        onChangeTimespan={(month, year) => {
                            setMonth(month);
                            setYear(year);
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            )}
        </div>
    );
};

export default DateInput;
