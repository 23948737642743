import { useLocation } from 'react-router';

function useSubRoute(withDynamic = false) {
    const { pathname } = useLocation();

    return withDynamic
        ? pathname.split('/').slice(-2)
        : pathname.split('/').slice(-1);
}

export default useSubRoute;
