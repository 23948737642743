import { createSelector } from 'reselect';

export const bundlesSelector = (state) => state.bundles;

export const bundleItemSelector = createSelector(
    bundlesSelector,
    (bundlesState) => bundlesState.bundle,
);

export const bundlesListSelector = createSelector(
    bundlesSelector,
    (bundlesState) => bundlesState.bundles,
);

export const soldBundlesSelector = createSelector(
    bundlesSelector,
    (bundlesState) => bundlesState.soldBundles,
);

export const companyBundlesSelector = createSelector(
    bundlesSelector,
    (bundlesState) => bundlesState.companyBundles,
);
