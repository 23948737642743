import React from 'react';

const Check = ({ className }) => (
    <svg
        width="12"
        height="12"
        fill="none"
        className={className}
    >
        <path
            d="M2 5.9L4.8 8.7L10 3.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Check;
