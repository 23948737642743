import React from 'react';

const FromAdmin = ({
    fill = 'currentColor',
    width = '100%',
    height = '100%',
    className,
    style,
}) => (
    <svg
        className={className}
        style={style}
        height={height}
        width={width}
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M14.1414 19.9938L13.5592 22.5061C13.5392 22.598 13.5399 22.6931 13.5613 22.7847C13.5827 22.8762 13.6243 22.9618 13.683 23.0352C13.7417 23.1086 13.8161 23.168 13.9007 23.209C13.9853 23.25 14.078 23.2715 14.172 23.2721C14.2158 23.2765 14.2599 23.2765 14.3037 23.2721L16.8314 22.69L21.6844 17.8553L18.976 15.153L14.1414 19.9938Z"
            fill={fill}
        />
        <path
            d="M23.21 15.4341L21.4024 13.6265C21.2835 13.5082 21.1227 13.4418 20.955 13.4418C20.7874 13.4418 20.6266 13.5082 20.5077 13.6265L19.5028 14.6314L22.2081 17.3367L23.2131 16.3318C23.2719 16.2727 23.3184 16.2025 23.3501 16.1254C23.3818 16.0483 23.398 15.9657 23.3977 15.8823C23.3974 15.7989 23.3807 15.7164 23.3485 15.6395C23.3163 15.5626 23.2692 15.4928 23.21 15.4341Z"
            fill={fill}
        />
        <path
            d="M3.08691 19.4777C3.08691 19.307 3.30883 18.5591 5.02497 17.7327C6.58741 16.9803 8.856 16.465 11.3898 16.465C12.5092 16.465 13.5795 16.5656 14.5616 16.7429L16.237 15.0653C14.7757 14.6823 13.119 14.465 11.3898 14.465C5.84008 14.465 1.08691 16.7028 1.08691 19.4777C1.08691 20.8646 2.21122 21.9889 3.59812 21.9889H11.6261L12.0896 19.9889H3.59812C3.31579 19.9889 3.08691 19.76 3.08691 19.4777Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4142 2.33993C10.0822 2.33993 8.80468 2.86907 7.8628 3.81096C6.92091 4.75284 6.39177 6.03031 6.39177 7.36234C6.39177 8.69437 6.92091 9.97184 7.8628 10.9137C8.80468 11.8556 10.0822 12.3848 11.4142 12.3848C12.7462 12.3848 14.0237 11.8556 14.9656 10.9137C15.9074 9.97184 16.4366 8.69437 16.4366 7.36234C16.4366 6.03031 15.9074 4.75284 14.9656 3.81096C14.0237 2.86907 12.7462 2.33993 11.4142 2.33993ZM9.27701 5.22517C9.84382 4.65836 10.6126 4.33993 11.4142 4.33993C12.2158 4.33993 12.9845 4.65836 13.5514 5.22517C14.1182 5.79198 14.4366 6.56075 14.4366 7.36234C14.4366 8.16393 14.1182 8.9327 13.5514 9.49951C12.9845 10.0663 12.2158 10.3848 11.4142 10.3848C10.6126 10.3848 9.84382 10.0663 9.27701 9.49951C8.7102 8.9327 8.39177 8.16393 8.39177 7.36234C8.39177 6.56075 8.7102 5.79198 9.27701 5.22517Z"
            fill={fill}
        />
    </svg>
);

export default FromAdmin;
