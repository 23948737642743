import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { soundsManager } from 'services/soundPlay';
import sentry from 'services/sentry';
import { userSoundNotificationsEnabledSelector } from 'store/selectors/user';
import WorkersController from 'controllers/WorkersController';
import { WORKER_TYPES } from 'const/WORKER_TYPES';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

soundsManager.unlockAudioContext();

export const useWorkerController = () => {
    const soundNotificationsEnabled = useSelector(userSoundNotificationsEnabledSelector);
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const handleShowWarningToast = useCallback(debounce(() => {
        addToast(
            t('makeAnyInteractionWithPage'),
            { appearance: 'warning', autoDismissTimeout: 15000 },
        );
    }, 500), []);

    const onStartNotifyBookingNewSound = useCallback(debounce(() => {
        try {
            if (soundNotificationsEnabled) {
                const playing = soundsManager.play('newBooking', false);
                if (!playing) {
                    handleShowWarningToast();
                }
            }
        } catch (e) {
            sentry.warn(e);
        }
    }, 500), [soundNotificationsEnabled]);

    const onStartNotifyBookingCancelSound = useCallback(debounce(() => {
        try {
            if (soundNotificationsEnabled) {
                const playing = soundsManager.play('cancelBooking', false);
                if (!playing) {
                    handleShowWarningToast();
                }
            }
        } catch (e) {
            sentry.warn(e);
        }
    }, 500), [soundNotificationsEnabled]);

    // Since messages can come to many tabs, the worker service stops the repetition of playback
    useEffect(() => {
        (async () => {
            await WorkersController.registerWorker(
                WORKER_TYPES.SOUND_WORKER,
            );
        })();

        WorkersController.addMessagesListener();
        WorkersController.on(
            WORKER_TYPES.SOUND_WORKER,
            'START_NOTIFY_BOOKING_NEW',
            onStartNotifyBookingNewSound,
        );
        WorkersController.on(
            WORKER_TYPES.SOUND_WORKER,
            'START_NOTIFY_BOOKING_CANCEL',
            onStartNotifyBookingCancelSound,
        );

        return () => {
            WorkersController.removeMessagesListener();
            WorkersController.off(
                WORKER_TYPES.SOUND_WORKER,
                'START_NOTIFY_BOOKING_NEW',
            );
            WorkersController.off(
                WORKER_TYPES.SOUND_WORKER,
                'START_NOTIFY_BOOKING_CANCEL',
            );
        };
    }, [soundNotificationsEnabled]);
};
