import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { BUNDLES_LIST_PAGE } from '../../../const/CLIENT_URL';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './AddOrEditBundleFooter.module.scss';

const T_PREFIX = 'addOrEdit.footer';

const AddOrEditBundleFooter = (props) => {
    const {
        disabled,
        confirmText,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <Row
            xs="auto"
            className={classNames(
                'mx-0 mb-5 py-3 pt-lg-4 justify-content-between align-items-center',
                styles.footer,
            )}
        >
            <Button
                href={BUNDLES_LIST_PAGE}
                color="outline"
            >
                {t(`${T_PREFIX}.actions.cancel`)}
            </Button>
            <Button
                type="submit"
                disabled={disabled}
            >
                {confirmText}
            </Button>
        </Row>
    );
};

AddOrEditBundleFooter.propTypes = {
    disabled: PropTypes.bool,
    confirmText: PropTypes.string,
};

AddOrEditBundleFooter.defaultProps = {
    disabled: false,
    confirmText: '',
};

export default AddOrEditBundleFooter;
