import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, InputGroup } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './NumberInput.module.scss';

function NumberInput(props) {
    const {
        name,
        value,
        onChange,
        className,
        placeholder,
        isInvalid = false,
        step = 1,
        min,
        max,
        withoutButtons = false,
    } = props;

    const onChangeValue = useCallback((e) => {
        let nextValue = Number(e.target.value);

        if (max !== undefined && nextValue > max) {
            nextValue = max;
        }

        if (min !== undefined && nextValue < min) {
            nextValue = min;
        }

        e.target.value = nextValue;
        onChange(nextValue);
    }, [value, max, min, onChange]);

    const onIncrementValue = useCallback(() => {
        const nextValue = value + step;

        if (max !== undefined && nextValue > max) {
            onChange(max);
            return;
        }

        onChange(nextValue);
    }, [value, step, max, onChange]);

    const onDecrementValue = useCallback(() => {
        const nextValue = value - step;

        if (min !== undefined && nextValue < min) {
            onChange(min);
            return;
        }

        onChange(nextValue);
    }, [value, step, min, onChange]);

    return (
        <InputGroup
            className={classNames(
                styles.numberInputContainer,
                {
                    [styles.invalid]: isInvalid,
                    'is-invalid': isInvalid,
                },
                className,
            )}
        >
            <Form.Control
                type="number"
                name={name}
                value={value}
                onChange={onChangeValue}
                isInvalid={isInvalid}
                placeholder={placeholder}
                className={styles.numberInputInput}
            />
            {!withoutButtons && (
                <InputGroup.Append className={styles.numberInputButtons}>
                    <button
                        type="button"
                        className={styles.numberInputButtonsItem}
                        onClick={onIncrementValue}
                        disabled={value === max}
                    >
                        <ChevronUp className={styles.numberInputButtonsItemIcon} />
                    </button>

                    <button
                        type="button"
                        className={styles.numberInputButtonsItem}
                        onClick={onDecrementValue}
                        disabled={value === min}
                    >
                        <ChevronDown className={styles.numberInputButtonsItemIcon} />
                    </button>
                </InputGroup.Append>
            )}
        </InputGroup>
    );
}

NumberInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    isInvalid: PropTypes.bool,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    withoutButtons: PropTypes.bool,
};

NumberInput.defaultProps = {
    value: 0,
    onChange: emptyFunc,
    isInvalid: false,
    step: 1,
    withoutButtons: false,
    min: 0,
};

export default NumberInput;
