import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './CheckBox.module.scss';

const CheckBox = (props) => {
    const {
        id,
        name,
        checked,
        className,
        onClick,
        onChange,
    } = props;

    return (
        <Form.Check.Input
            id={id}
            name={name}
            checked={checked}
            className={classNames(styles.checkBox, className)}
            onClick={onClick}
            onChange={onChange}
            data-testid={props['data-testid']}
        />
    );
};

CheckBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    'data-testid': PropTypes.string,
};

CheckBox.defaultProps = {
    id: '',
    name: '',
    checked: false,
    className: '',
    onClick: emptyFunc,
    onChange: emptyFunc,
    'data-testid': 'data-test-checkbox',
};

export default CheckBox;
