import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { Loader } from '../../Common/Loader';
import { Tooltip } from '../../Common/Tooltip';

import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { formatPrice } from '../../../helpers/formatters/formatPrice';

import { BUNDLES_EDIT_ID_PAGE } from '../../../const/CLIENT_URL';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as BUNDLES_ACTIONS from '../../../store/actions/bundles';

import * as BUNDLES_SELECTORS from '../../../store/selectors/bundles';

import * as styles from './BundleDetailsDialog.module.scss';

const UNBREAKABLE_SPACE = '\xa0';

const INFINITY_SYMBOL = '∞';

const BADGE_TYPE = {
    SUCCESS: 'success',
    DANGER: 'danger',
};

const BUNDLE_STATUS = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
};

const T_PREFIX = 'list.dialogs.details';

const BundleDetailsDialog = (props) => {
    const {
        visible,
        bundleId,
        onClose,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const dispatch = useDispatch();
    const { item: bundle, loading } = useSelector(BUNDLES_SELECTORS.bundleItemSelector);

    const badgeType = bundle?.status ? BADGE_TYPE.SUCCESS : BADGE_TYPE.DANGER;
    const bundleStatus = bundle?.status ? BUNDLE_STATUS.ENABLED : BUNDLE_STATUS.DISABLED;

    const maxUsages = useMemo(() => {
        if (!bundle?.maxUsages) {
            return '';
        }
        const { value, unlimited } = bundle.maxUsages;
        return unlimited ? INFINITY_SYMBOL : value;
    }, [bundle?.maxUsages]);

    const validity = useMemo(() => {
        if (!bundle?.validity) {
            return '';
        }
        const { value, unit, unlimited } = bundle.validity;
        if (unlimited) {
            return INFINITY_SYMBOL;
        }
        const unitString = t(`${T_PREFIX}.body.fields.validity.units.${unit}`, {
            count: value,
        });
        return `${value}${UNBREAKABLE_SPACE}${unitString}`;
    }, [bundle?.validity, t]);

    useEffect(() => {
        if (!visible || !bundleId) {
            return;
        }
        dispatch(BUNDLES_ACTIONS.getBundleItem({ bundleId }));
    }, [visible, bundleId]);

    return (
        <Modal
            show={visible}
            size="lg"
            centered
            fullscreen="lg-down"
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t(`${T_PREFIX}.header.title`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Loader />
                ) : (
                    <Container
                        fluid
                        className="p-0"
                    >
                        <Row className={styles.row}>
                            <Col xs={3}>
                                <span className={styles.field}>
                                    {t(`${T_PREFIX}.body.fields.name.label`)}
                                </span>
                            </Col>
                            <Col xs={9}>
                                <p className={styles.truncate}>
                                    <span className={classNames(styles.value, styles.bundle)}>
                                        {bundle?.name}
                                    </span>
                                </p>
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={3}>
                                <span className={styles.field}>
                                    {t(`${T_PREFIX}.body.fields.services.label`)}
                                </span>
                            </Col>
                            <Col xs={9}>
                                <p className={classNames(styles.value, styles.services, styles.truncate)}>
                                    {bundle?.services?.map?.((s) => (
                                        <div key={s.id}>
                                            <Tooltip
                                                tooltip={s.name}
                                                placement="top-start"
                                            >
                                                <span className="w-100">
                                                    {s.name}
                                                </span>
                                            </Tooltip>
                                            <br />
                                        </div>
                                    ))}
                                </p>
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={3}>
                                <span className={styles.field}>
                                    {t(`${T_PREFIX}.body.fields.maxUsages.label`)}
                                </span>
                            </Col>
                            <Col xs={9}>
                                <span className={styles.value}>
                                    {maxUsages}
                                </span>
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={3}>
                                <span className={styles.field}>
                                    {t(`${T_PREFIX}.body.fields.price.label`)}
                                </span>
                            </Col>
                            <Col
                                xs={9}
                                className="d-flex align-items-center flex-nowrap"
                            >
                                <span className={styles.value}>
                                    {formatPrice.toEuroWithComma({ amount: bundle?.price }) }
                                </span>
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={3}>
                                <span className={styles.field}>
                                    {t(`${T_PREFIX}.body.fields.validity.label`)}
                                </span>
                            </Col>
                            <Col xs={9}>
                                <span className={styles.value}>
                                    {validity}
                                </span>
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={3}>
                                <span className={styles.field}>
                                    {t(`${T_PREFIX}.body.fields.status.label`)}
                                </span>
                            </Col>
                            <Col xs={9}>
                                <Badge
                                    pill
                                    variant={badgeType}
                                    className="text-uppercase"
                                >
                                    {t(`${T_PREFIX}.body.fields.status.statuses.${bundleStatus}`)}
                                </Badge>
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={3}>
                                <span className={styles.field}>
                                    {t(`${T_PREFIX}.body.fields.description.label`)}
                                </span>
                            </Col>
                            <Col xs={9}>
                                <span className={classNames(styles.value, styles.description)}>
                                    {bundle?.description}
                                </span>
                            </Col>
                        </Row>
                    </Container>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="outline"
                    className="mr-auto"
                    onClick={onClose}
                >
                    {t(`${T_PREFIX}.footer.actions.cancel`)}
                </Button>
                <Button
                    href={BUNDLES_EDIT_ID_PAGE({ bundleId })}
                    disabled={loading}
                >
                    {t(`${T_PREFIX}.footer.actions.edit`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

BundleDetailsDialog.propTypes = {
    visible: PropTypes.bool,
    bundleId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    onClose: PropTypes.func,
};

BundleDetailsDialog.defaultProps = {
    visible: false,
    bundleId: null,
    onClose: emptyFunc,
};

export default BundleDetailsDialog;
