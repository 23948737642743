import General from './general';
import Locations from './locations';
import Location from './location';
import DisplayAndNotifications from './displayAndNotifications';
import DiscountAndLoyalty from './discountAndLoyalty';

import {
    SETTINGS_COMPANY_GENERAL_PAGE,
    SETTINGS_COMPANY_LOCATIONS_PAGE,
    // SETTINGS_COMPANY_LOCATIONS_ADD_PAGE,
    SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE,
    SETTINGS_COMPANY_DISPLAY_AND_NOTIFICATIONS_PAGE,
    SETTINGS_COMPANY_DISCOUNT_AND_LOYALTY_PAGE,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: General,
        exact: false,
        path: SETTINGS_COMPANY_GENERAL_PAGE,
    },
    {
        component: Locations,
        exact: true,
        path: SETTINGS_COMPANY_LOCATIONS_PAGE,
    },
    // {
    //     component: Location,
    //     exact: true,
    //     path: SETTINGS_COMPANY_LOCATIONS_ADD_PAGE,
    // },
    {
        component: Location,
        exact: true,
        path: SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE,
    },
    {
        component: DisplayAndNotifications,
        exact: false,
        path: SETTINGS_COMPANY_DISPLAY_AND_NOTIFICATIONS_PAGE,
    },
    {
        component: DiscountAndLoyalty,
        exact: false,
        path: SETTINGS_COMPANY_DISCOUNT_AND_LOYALTY_PAGE,
    },
];

export default routes;
