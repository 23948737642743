import React from 'react';

// Styles

import * as styles from './AnimatedBurger.module.scss';

const AnimatedBurger = ({ active, size = 20, color = 'black' }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <svg style={{ flex: 1, transform: `scale(${size / 20})` }} fill={color} className={active ? styles.activeX : styles.nonactiveX} viewBox="-15 -5 130 80" width="40" height="40">
            <rect y="0" width="100" height="10" rx="5" />
            <rect y="30" width="100" height="10" rx="5" />
            <rect y="60" width="100" height="10" rx="5" />
        </svg>
    </div>
);

export default AnimatedBurger;
