// HEADER

export const HEADER = {
    agendaTabsSelector: 'agendaTabs',
    subNavigationSelector: 'subNavigation',
    nameAndRoleBoxSelector: 'nameAndRoleBox',
    openDropDownArrowSelector: 'openDropDownArrow',
    logoutButtonSelector: 'logoutButton',
};

// FOOTER

export const FOOTER = {
    languageSwitchSelector: 'languageSwitch',
};

// SIGN_IN_PAGE

export const SIGN_IN_PAGE = {
    emailFieldTitleSelector: 'emailFieldTitle',
    emailFieldInputSelector: 'emailFieldInput',
    passwordFieldTitleSelector: 'passwordFieldTitle',
    passwordFieldInputSelector: 'passwordFieldInput',
    rememberMeCheckboxTitleSelector: 'rememberMeCheckboxTitle',
    forgotPasswordButtonSelector: 'forgotPasswordButton',
    signInButtonSelector: 'signInButton',
};
