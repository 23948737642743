export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCEEDED = 'GET_EVENTS_SUCCEEDED';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';

export const ADD_EVENT = 'ADD_EVENT';
export const ADD_EVENT_SUCCEEDED = 'ADD_EVENT_SUCCEEDED';
export const ADD_EVENT_FAILED = 'ADD_EVENT_FAILED';

export const EDIT_EVENT = 'EDIT_EVENT';
export const EDIT_EVENT_SUCCEEDED = 'EDIT_EVENT_SUCCEEDED';
export const EDIT_EVENT_FAILED = 'EDIT_EVENT_SUCCEEDED';

export const CANCEL_EVENT = 'CANCEL_EVENT';
export const CANCEL_EVENT_SUCCEEDED = 'CANCEL_EVENT_SUCCEEDED';
export const CANCEL_EVENT_FAILED = 'CANCEL_EVENT_FAILED';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCEEDED = 'DELETE_EVENT_SUCCEEDED';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_SUCCEEDED';

export const GET_EVENT_BOOKINGS = 'GET_EVENT_BOOKINGS';
export const GET_EVENT_BOOKINGS_SUCCEEDED = 'GET_EVENT_BOOKINGS_SUCCEEDED';
export const GET_EVENT_BOOKINGS_FAILED = 'GET_EVENT_BOOKINGS_FAILED';

const PREFIX = '@@EVENTS/';
export const EVENTS_SET_LOADING = `${PREFIX}EVENTS_SET_LOADING`;
export const SET_EVENTS_PAGE = `${PREFIX}SET_PAGE`;
export const SET_EVENTS_SHOW_ALL = `${PREFIX}SET_SHOW_ALL`;
