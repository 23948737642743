// extracted by mini-css-extract-plugin
var _1 = "Column_center__H4Lj6";
var _2 = "Column_column__ThHo5";
var _3 = "Column_end__bPGJn";
var _4 = "Column_gap0__7K-pn";
var _5 = "Column_gap12__6KHB1";
var _6 = "Column_gap16__TiZCJ";
var _7 = "Column_gap32__vLLfv";
var _8 = "Column_gap4__IJ2aa";
var _9 = "Column_gap8__x7yzE";
var _a = "Column_padding0__TMAuD";
var _b = "Column_padding12__TffsL";
var _c = "Column_padding16__9B04H";
var _d = "Column_padding32__8AYe8";
var _e = "Column_padding4__wUFAw";
var _f = "Column_padding8__sS4-c";
var _10 = "Column_start__ftNs8";
var _11 = "Column_stretched__O29cx";
export { _1 as "center", _2 as "column", _3 as "end", _4 as "gap0", _5 as "gap12", _6 as "gap16", _7 as "gap32", _8 as "gap4", _9 as "gap8", _a as "padding0", _b as "padding12", _c as "padding16", _d as "padding32", _e as "padding4", _f as "padding8", _10 as "start", _11 as "stretched" }
