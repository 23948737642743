import React from 'react';

const CreditCard = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <line fill="none" x1="1" x2="23" y1="7" y2="7" />
            <line fill="none" x1="1" x2="23" y1="11" y2="11" />
            <path
                d="M21,21H3 c-1.105,0-2-0.895-2-2V5c0-1.105,0.895-2,2-2h13h5c1.105,0,2,0.895,2,2v14C23,20.105,22.105,21,21,21z"
                fill="none"
                stroke={fill}
            />
            <line fill="none" x1="5" x2="10" y1="16" y2="16" />
            <line fill="none" x1="18" x2="19" y1="16" y2="16" />
        </g>
    </svg>
);

export default CreditCard;
