import { ComingSoon } from 'components/ComingSoon';
import BookingsPage from './bookings';
import BundlesPage from './bundles';
import SubscriptionsPage from './subscriptions';
import InvoicesPage from './invoices';
import ReviewsPage from './reviews';
import NotesPage from './notes';
import InfoPage from './info/page';

import {
    CLIENTS_LIST_ID_BOOKINGS_PATH,
    CLIENTS_LIST_ID_BUNDLES_PATH,
    CLIENTS_LIST_ID_SUBSCRIPTIONS_PATH,
    CLIENTS_LIST_ID_INVOICES_PATH,
    CLIENTS_LIST_ID_REVIEWS_PATH,
    CLIENTS_LIST_ID_COMMUNICATION_PATH,
    CLIENTS_LIST_ID_NOTES_PATH,
    CLIENTS_LIST_ID_INFO_PATH,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: InfoPage,
        exact: true,
        path: CLIENTS_LIST_ID_INFO_PATH,
        mobileOnly: true,
    },
    {
        component: NotesPage,
        exact: true,
        path: CLIENTS_LIST_ID_NOTES_PATH,
    },
    {
        component: BookingsPage,
        exact: true,
        path: CLIENTS_LIST_ID_BOOKINGS_PATH,
    },
    {
        component: BundlesPage,
        exact: true,
        path: CLIENTS_LIST_ID_BUNDLES_PATH,
    },
    {
        component: SubscriptionsPage,
        exact: true,
        path: CLIENTS_LIST_ID_SUBSCRIPTIONS_PATH,
    },
    {
        component: InvoicesPage,
        exact: true,
        path: CLIENTS_LIST_ID_INVOICES_PATH,
    },
    {
        component: ReviewsPage,
        exact: true,
        path: CLIENTS_LIST_ID_REVIEWS_PATH,
    },
    {
        component: ComingSoon,
        exact: true,
        path: CLIENTS_LIST_ID_COMMUNICATION_PATH,
    },

];

export default routes;
