import React from 'react';

const Warning = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <polygon fill="none" points="16.556 1 7.444 1 1 7.444 1 16.556 7.444 23 16.556 23 23 16.556 23 7.444 16.556 1" stroke={fill} />
            <line fill="none" x1="12" x2="12" y1="7" y2="13" />
            <circle cx="12" cy="17" r="1" stroke="none" strokeLinecap="butt" />
        </g>
    </svg>
);

export default Warning;
