import React from 'react';
import PropTypes from 'prop-types';

const Location = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="19"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4284 6.33333C13.4284 9.6 8.09505 15 8.09505 15C8.09505 15 2.76172 9.6 2.76172 6.33333C2.76172 2.93333 5.49505 1 8.09505 1C10.6951 1 13.4284 2.93333 13.4284 6.33333Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="8.09521"
                cy="6.33337"
                r="2"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Location.propTypes = {
    className: PropTypes.string,
};

Location.defaultProps = {
    className: '',
};

export default Location;
