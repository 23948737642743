import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';

import { emptyFunc } from 'helpers/function/emptyFunc';

import { newSelectStyles, theme } from 'styles/select';

const animatedComponents = makeAnimated();

const T_PREFIX = 'list.search.filters';

const ClientProfileSelect = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const {
        onDiscountSelectChange,
        discountProfiles,
    } = props;

    const discountProfileOptions = useMemo(() => (
        discountProfiles.map((item) => ({
            value: item.id,
            label: item.name,
        }))
    ), [discountProfiles]);

    const handleDiscountChange = useCallback((selected) => {
        const clientCategory = selected?.map((item) => item.value);
        onDiscountSelectChange({ discountItems: clientCategory });
    }, [onDiscountSelectChange]);

    return (
        <Select
            name="discounts"
            isSearchable={false}
            theme={theme}
            styles={newSelectStyles}
            components={animatedComponents}
            isMulti
            onChange={handleDiscountChange}
            placeholder={t(`${T_PREFIX}.clientProfile`)}
            options={discountProfileOptions}
        />
    );
};

ClientProfileSelect.propTypes = {
    onDiscountSelectChange: PropTypes.func,
    discountProfiles: PropTypes.array,
};

ClientProfileSelect.defaultProps = {
    onDiscountSelectChange: emptyFunc,
    discountProfiles: [],
};

export default ClientProfileSelect;
