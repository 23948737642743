import {
    call,
    put,
    race,
    take,
} from 'redux-saga/effects';

import * as AUTH_TYPES from '../../store/actions/auth/actionTypes';

import * as AUTH_ACTIONS from '../../store/actions/auth';

export function* retryWithRefreshToken({
    action, onFail, onError,
}) {
    try {
        yield put(AUTH_ACTIONS.refreshToken());
        const [success, fail] = yield race([
            take(AUTH_TYPES.REFRESH_TOKEN_RESOLVED),
            take(AUTH_TYPES.REFRESH_TOKEN_REJECTED),
        ]);
        if (success) {
            yield put(action);
            return;
        }
        if (fail && onFail) {
            yield call(onFail);
            return;
        }
    } catch (error) {
        if (onError) {
            yield call(onError, error);
            return;
        }
        throw error;
    }
}
