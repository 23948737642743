const ACCESS_GATES = 'ACCESS_GATES';
export const ACCESS_GATES_CLEAR = `${ACCESS_GATES}:CLEAR`;

export const GET_ACCESS_GATE = 'GET_ACCESS_GATE';
export const GET_ACCESS_GATE_TRIGGER = `${GET_ACCESS_GATE}.TRIGGER`;
export const GET_ACCESS_GATE_RESOLVED = `${GET_ACCESS_GATE}.RESOLVED`;
export const GET_ACCESS_GATE_REJECTED = `${GET_ACCESS_GATE}.REJECTED`;

export const GET_ACCESS_GATES = 'GET_ACCESS_GATES';
export const GET_ACCESS_GATES_TRIGGER = `${GET_ACCESS_GATES}.TRIGGER`;
export const GET_ACCESS_GATES_RESOLVED = `${GET_ACCESS_GATES}.RESOLVED`;
export const GET_ACCESS_GATES_REJECTED = `${GET_ACCESS_GATES}.REJECTED`;

export const GET_GATES_FOR_SELECT = 'GET_GATES_FOR_SELECT';
export const GET_GATES_FOR_SELECT_TRIGGER = `${GET_GATES_FOR_SELECT}.TRIGGER`;
export const GET_GATES_FOR_SELECT_RESOLVED = `${GET_GATES_FOR_SELECT}.RESOLVED`;
export const GET_GATES_FOR_SELECT_REJECTED = `${GET_GATES_FOR_SELECT}.REJECTED`;

export const SAVE_ACCESS_GATE = 'SAVE_ACCESS_GATE';
export const SAVE_ACCESS_GATE_TRIGGER = `${SAVE_ACCESS_GATE}.TRIGGER`;
export const SAVE_ACCESS_GATE_RESOLVED = `${SAVE_ACCESS_GATE}.RESOLVED`;
export const SAVE_ACCESS_GATE_REJECTED = `${SAVE_ACCESS_GATE}.REJECTED`;

export const TOGGLE_STATUS_ACCESS_GATE = 'TOGGLE_STATUS_ACCESS_GATE';
export const TOGGLE_STATUS_ACCESS_GATE_TRIGGER = `${TOGGLE_STATUS_ACCESS_GATE}.TRIGGER`;
export const TOGGLE_STATUS_ACCESS_GATE_RESOLVED = `${TOGGLE_STATUS_ACCESS_GATE}.RESOLVED`;
export const TOGGLE_STATUS_ACCESS_GATE_REJECTED = `${TOGGLE_STATUS_ACCESS_GATE}.REJECTED`;

const ACCESS_GATES_PAGE = `${ACCESS_GATES}:PAGE`;
export const ACCESS_GATES_PAGE_SET = `${ACCESS_GATES_PAGE}:SET`;
export const ACCESS_GATES_PAGE_SET_FIRST = `${ACCESS_GATES_PAGE_SET}:FIRST`;
export const ACCESS_GATES_PAGE_SET_LAST = `${ACCESS_GATES_PAGE_SET}:LAST`;
export const ACCESS_GATES_PAGE_SET_PREV = `${ACCESS_GATES_PAGE_SET}:PREV`;
export const ACCESS_GATES_PAGE_SET_NEXT = `${ACCESS_GATES_PAGE_SET}:NEXT`;
export const ACCESS_GATES_SHOW_ALL = `${ACCESS_GATES}:SHOW_ALL`;
const ACCESS_GATES_SEARCH = `${ACCESS_GATES}:SEARCH`;
export const ACCESS_GATES_SEARCH_SET = `${ACCESS_GATES_SEARCH}:SET`;
const ACCESS_GATES_SORT_OPTIONS = `${ACCESS_GATES}:SORT_OPTIONS`;
export const ACCESS_GATES_SORT_OPTIONS_SET = `${ACCESS_GATES_SORT_OPTIONS}:SET`;

const ACCESS_LOGS = 'ACCESS_LOGS';
export const ACCESS_LOGS_CLEAR = `${ACCESS_LOGS}:CLEAR`;

export const GET_ACCESS_LOGS = 'GET_ACCESS_LOGS';
export const GET_ACCESS_LOGS_TRIGGER = `${GET_ACCESS_LOGS}.TRIGGER`;
export const GET_ACCESS_LOGS_RESOLVED = `${GET_ACCESS_LOGS}.RESOLVED`;
export const GET_ACCESS_LOGS_REJECTED = `${GET_ACCESS_LOGS}.REJECTED`;

export const APPEND_ACCESS_LOG = 'APPEND_ACCESS_LOG';
export const APPEND_ACCESS_LOG_TRIGGER = `${APPEND_ACCESS_LOG}.TRIGGER`;
export const APPEND_ACCESS_LOG_RESOLVED = `${APPEND_ACCESS_LOG}.RESOLVED`;
export const APPEND_ACCESS_LOG_REJECTED = `${APPEND_ACCESS_LOG}.REJECTED`;

const ACCESS_LOGS_PAGE = `${ACCESS_LOGS}:PAGE`;
export const ACCESS_LOGS_PAGE_SET = `${ACCESS_LOGS_PAGE}:SET`;
export const ACCESS_LOGS_PAGE_SET_FIRST = `${ACCESS_LOGS_PAGE_SET}:FIRST`;
export const ACCESS_LOGS_PAGE_SET_LAST = `${ACCESS_LOGS_PAGE_SET}:LAST`;
export const ACCESS_LOGS_PAGE_SET_PREV = `${ACCESS_LOGS_PAGE_SET}:PREV`;
export const ACCESS_LOGS_PAGE_SET_NEXT = `${ACCESS_LOGS_PAGE_SET}:NEXT`;
export const ACCESS_LOGS_SHOW_ALL = `${ACCESS_LOGS}:SHOW_ALL`;
const ACCESS_LOGS_SEARCH = `${ACCESS_LOGS}:SEARCH`;
export const ACCESS_LOGS_SEARCH_SET = `${ACCESS_LOGS_SEARCH}:SET`;
const ACCESS_LOGS_SORT_OPTIONS = `${ACCESS_LOGS}:SORT_OPTIONS`;
export const ACCESS_LOGS_SORT_OPTIONS_SET = `${ACCESS_LOGS_SORT_OPTIONS}:SET`;

const ACCESS_LOGS_SELECTED_DATE = `${ACCESS_LOGS}:SELECTED_DATE`;
export const ACCESS_LOGS_SELECTED_DATE_SET = `${ACCESS_LOGS_SELECTED_DATE}:SET`;

const ACCESS_LOGS_FILTER = `${ACCESS_LOGS}:FILTER`;
export const ACCESS_LOGS_FILTER_SET = `${ACCESS_LOGS_FILTER}:SET`;

export const GET_ACCESS_STATISTICS = 'GET_ACCESS_STATISTICS';
export const GET_ACCESS_STATISTICS_TRIGGER = `${GET_ACCESS_STATISTICS}.TRIGGER`;
export const GET_ACCESS_STATISTICS_RESOLVED = `${GET_ACCESS_STATISTICS}.RESOLVED`;
export const GET_ACCESS_STATISTICS_REJECTED = `${GET_ACCESS_STATISTICS}.REJECTED`;

const ACCESS_LOGS_STATISTICS_SELECTED_DATE = `${GET_ACCESS_STATISTICS}:SELECTED_DATE`;
export const ACCESS_LOGS_STATISTICS_SELECTED_DATE_SET = `${ACCESS_LOGS_STATISTICS_SELECTED_DATE}:SET`;

export const GET_ACCESS_PROFILES = 'GET_ACCESS_PROFILES';
export const GET_ACCESS_PROFILES_TRIGGER = `${GET_ACCESS_PROFILES}.TRIGGER`;
export const GET_ACCESS_PROFILES_RESOLVED = `${GET_ACCESS_PROFILES}.RESOLVED`;
export const GET_ACCESS_PROFILES_REJECTED = `${GET_ACCESS_PROFILES}.REJECTED`;

export const SAVE_ACCESS_PROFILE = 'SAVE_ACCESS_PROFILE';
export const SAVE_ACCESS_PROFILE_TRIGGER = `${SAVE_ACCESS_PROFILE}.TRIGGER`;
export const SAVE_ACCESS_PROFILE_RESOLVED = `${SAVE_ACCESS_PROFILE}.RESOLVED`;
export const SAVE_ACCESS_PROFILE_REJECTED = `${SAVE_ACCESS_PROFILE}.REJECTED`;

export const GET_ACCESS_PROFILE = 'GET_ACCESS_PROFILE';
export const GET_ACCESS_PROFILE_TRIGGER = `${GET_ACCESS_PROFILE}.TRIGGER`;
export const GET_ACCESS_PROFILE_RESOLVED = `${GET_ACCESS_PROFILE}.RESOLVED`;
export const GET_ACCESS_PROFILE_REJECTED = `${GET_ACCESS_PROFILE}.REJECTED`;

export const CREATE_ACCESS_PROFILE = 'CREATE_ACCESS_PROFILE';
export const CREATE_ACCESS_PROFILE_TRIGGER = `${CREATE_ACCESS_PROFILE}.TRIGGER`;
export const CREATE_ACCESS_PROFILE_RESOLVED = `${CREATE_ACCESS_PROFILE}.RESOLVED`;
export const CREATE_ACCESS_PROFILE_REJECTED = `${CREATE_ACCESS_PROFILE}.REJECTED`;

export const DELETE_ACCESS_PROFILE = 'DELETE_ACCESS_PROFILE';
export const DELETE_ACCESS_PROFILE_TRIGGER = `${DELETE_ACCESS_PROFILE}.TRIGGER`;
export const DELETE_ACCESS_PROFILE_RESOLVED = `${DELETE_ACCESS_PROFILE}.RESOLVED`;
export const DELETE_ACCESS_PROFILE_REJECTED = `${DELETE_ACCESS_PROFILE}.REJECTED`;

const ACCESS_PROFILES = 'ACCESS_PROFILES';
export const ACCESS_PROFILES_CLEAR = `${ACCESS_PROFILES}:CLEAR`;

const ACCESS_PROFILES_PAGE = `${ACCESS_PROFILES}:PAGE`;
export const ACCESS_PROFILES_PAGE_SET = `${ACCESS_PROFILES_PAGE}:SET`;
export const ACCESS_PROFILES_PAGE_SET_FIRST = `${ACCESS_PROFILES_PAGE_SET}:FIRST`;
export const ACCESS_PROFILES_PAGE_SET_LAST = `${ACCESS_PROFILES_PAGE_SET}:LAST`;
export const ACCESS_PROFILES_PAGE_SET_PREV = `${ACCESS_PROFILES_PAGE_SET}:PREV`;
export const ACCESS_PROFILES_PAGE_SET_NEXT = `${ACCESS_PROFILES_PAGE_SET}:NEXT`;
export const ACCESS_PROFILES_SHOW_ALL = `${ACCESS_PROFILES}:SHOW_ALL`;
const ACCESS_PROFILES_SEARCH = `${ACCESS_PROFILES}:SEARCH`;
export const ACCESS_PROFILES_SEARCH_SET = `${ACCESS_PROFILES_SEARCH}:SET`;
