import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';

import { ClientInvoicesTableHeader } from 'components/clients/ClientPage/ClientTables/ClientInvoices/ClientInvoicesTableHeader';
import { ClientInvoicesTableRow } from 'components/clients/ClientPage/ClientTables/ClientInvoices/ClientInvoicesTableRow';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';

function ClientBundlesTable() {
    const handleDownload = useCallback(() => {
        // eslint-disable-next-line no-console
        console.log('Download');
    }, []);

    return (
        <Container
            fluid
            className="m-0 p-0 my-5 d-flex flex-column flex-grow-1"
        >
            <Table>
                <TableHeader>
                    <ClientInvoicesTableHeader />
                </TableHeader>
                <TableBody>
                    <ListTableBodyRow>
                        <ClientInvoicesTableRow
                            onDownload={handleDownload}
                        />
                    </ListTableBodyRow>
                    <ListTableBodyRow>
                        <ClientInvoicesTableRow />
                    </ListTableBodyRow>
                    <ListTableBodyRow>
                        <ClientInvoicesTableRow />
                    </ListTableBodyRow>
                </TableBody>
            </Table>
        </Container>
    );
}

export default ClientBundlesTable;
