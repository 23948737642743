import React from 'react';

const Up = ({
    fill = 'none', stroke = '#B3BDC8', strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} width={width} height={height} viewBox="0 0 9 6" fill={fill}>
        <path d="M1 4.66699L4.5 1.33366L8 4.66699" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Up;
