/* eslint-disable import/prefer-default-export */
import * as MESSAGES_TYPES from '../../actions/messages/types';
import { MESSAGES_SORT_BY_TYPE } from '../../../const/marketing/MESSAGES_SORT_BY_TYPE';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    sortOptions: {
        sortBy: MESSAGES_SORT_BY_TYPE.START_DATE,
        orderBy: 1,
    },
    showAll: false,
    isLoading: false,
    itemsCount: 0,
    pagesCount: 0,
    itemsPerPage: 10,
};

export const messagesListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (MESSAGES_TYPES.MESSAGES_SET): {
        const { items, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items,
            page,
            itemsCount,
            pagesCount,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_CLEAR): {
        return INITIAL_STATE;
    }
    case (MESSAGES_TYPES.MESSAGES_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: false,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_SHOW_ALL): {
        return {
            ...state,
            page: 0,
            showAll: true,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_SEARCH_SET): {
        const { search } = payload;

        return {
            ...state,
            search,
        };
    }
    case (MESSAGES_TYPES.MESSAGES_LOADING_SET): {
        const { isLoading } = payload;
        return {
            ...state,
            isLoading,
        };
    }
    case MESSAGES_TYPES.MESSAGES_SORT_OPTIONS_SET: {
        const { sortOptions: { sortBy, orderBy } } = payload;

        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    case (MESSAGES_TYPES.MESSAGE_ITEM_SHOW_DETAILS): {
        const { message } = payload;
        return {
            ...state,
            showMessageDetailsDialog: true,
            message,
        };
    }
    case (MESSAGES_TYPES.MESSAGE_ITEM_HIDE_DETAILS): {
        return {
            ...state,
            showMessageDetailsDialog: false,
            message: null,
        };
    }
    default:
        return state;
    }
};
