import { get } from 'lodash';

export function isInvalid(key, errors, touched) {
    const hasError = !!get(errors, key, null);
    const isTouched = !!get(touched, key, null);

    return hasError && isTouched;
}

export function getError(key, errors) {
    return get(errors, key, '');
}
