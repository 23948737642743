import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { handleKeyDownOnNumberInput } from 'helpers/input/handleKeyDownOnNumberInput';
import { getVATRateSelectOptions } from 'components/subscriptions/SubscriptionPricingFormGroup/SubscriptionPricingFormGroup';
import { useSelector } from 'react-redux';
import {
    dangerTheme as selectDangerTheme,
    styles as selectStyles,
    theme as selectTheme,
} from '../../../../styles/select';
import * as COMPANY_SELECTORS from '../../../../store/selectors/company';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { BUNDLE_PROP } from '../../../../const/bundles/BUNDLE_PROP';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './BundlePriceFormRow.module.scss';

const T_PREFIX = 'addOrEdit.form.fields';

const BundlePriceFormRow = (props) => {
    const {
        sold,
        values,
        validation,
        onIncPrice,
        onDecPrice,
        onChangePrice,
        onChangeVatRate,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);

    const vatRateSelectTheme = validation?.vatRate?.isInvalid ? selectDangerTheme : selectTheme;

    const vatRateValue = useMemo(() => ({
        value: values.vatRate,
        label: `${values.vatRate}%`,
    }), [values.vatRate]);

    const priceWithoutVat = (values.price / (1 + values.vatRate / 100))
        .toFixed(BUNDLE_PROP.PRICE.TO_FIXED);

    return (
        <Row>
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label>
                        {t(`${T_PREFIX}.price.label`)}
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            name="price"
                            value={values.price}
                            disabled={sold}
                            isInvalid={validation?.price?.isInvalid}
                            onChange={onChangePrice}
                            onKeyDown={handleKeyDownOnNumberInput}
                        />
                        <InputGroup.Append>
                            <Col className="m-0 p-0 border border-light rounded-right">
                                <button
                                    type="button"
                                    disabled={sold}
                                    className={styles.incDecButton}
                                    onClick={onIncPrice}
                                >
                                    <ChevronUp size={16} />
                                </button>
                                <button
                                    type="button"
                                    disabled={sold}
                                    className={styles.incDecButton}
                                    onClick={onDecPrice}
                                >
                                    <ChevronDown size={16} />
                                </button>
                            </Col>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.price?.isInvalid,
                        })}
                    >
                        {validation?.price?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label>
                        {t(`${T_PREFIX}.vatRate.label`)}
                    </Form.Label>
                    <Select
                        name="vatRate"
                        value={vatRateValue}
                        theme={vatRateSelectTheme}
                        styles={selectStyles}
                        options={getVATRateSelectOptions(enableBusinessMode)}
                        isDisabled={sold}
                        onChange={onChangeVatRate}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.vatRate?.isInvalid,
                        })}
                    >
                        {validation?.vatRate?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label>
                        {t(`${T_PREFIX}.priceWithoutVat.label`)}
                    </Form.Label>
                    <Form.Control
                        type="number"
                        name="priceWithoutVat"
                        value={priceWithoutVat}
                        disabled
                    />
                </Form.Group>
            </Col>
        </Row>
    );
};

BundlePriceFormRow.propTypes = {
    sold: PropTypes.bool,
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onIncPrice: PropTypes.func,
    onDecPrice: PropTypes.func,
    onChangePrice: PropTypes.func,
    onChangeVatRate: PropTypes.func,
};

BundlePriceFormRow.defaultProps = {
    sold: false,
    validation: null,
    onIncPrice: emptyFunc,
    onDecPrice: emptyFunc,
    onChangePrice: emptyFunc,
    onChangeVatRate: emptyFunc,
};

export default BundlePriceFormRow;
