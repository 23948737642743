import { gatesListSaga } from './gatesList';
import { logsListSaga } from './logsList';
import { gateItemSaga } from './gateItem';
import { logsStatisticsSaga } from './logsStatistics';
import { profilesListSaga } from './profilesList';
import { profileItemSaga } from './profileItem';

export const accessSaga = [
    ...gatesListSaga,
    ...logsListSaga,
    ...gateItemSaga,
    ...logsStatisticsSaga,
    ...profilesListSaga,
    ...profileItemSaga,
];
