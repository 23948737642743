import * as CLIENTS_TYPES from './types';

export const getClientSubscriptions = ({ clientId }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_GET,
    payload: { clientId },
});

export const setClientSubscriptions = ({
    subscriptions, itemsCount, pagesCount,
}) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_SET,
    payload: {
        subscriptions,
        itemsCount,
        pagesCount,
    },
});

export const setClientSubscriptionsPage = ({ page }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET,
    payload: { page },
});

export const setClientSubscriptionsPageFirst = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_FIRST,
});

export const setClientSubscriptionsPageLast = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_LAST,
});

export const setClientSubscriptionsPagePrev = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_PREV,
});

export const setClientSubscriptionsPageNext = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_NEXT,
});

export const setClientSubscriptionsLoading = ({ loading }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_LOADING_SET,
    payload: { loading },
});
