import React from 'react';
import PropTypes from 'prop-types';

const MoreHorizontalIcon = (props) => {
    const { className, style } = props;

    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 4"
            className={className}
            style={style}
        >
            <circle
                cx="9"
                cy="2"
                r="1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="16"
                cy="2"
                r="1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="2"
                cy="2"
                r="1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

MoreHorizontalIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.string),
};

MoreHorizontalIcon.defaultProps = {
    className: '',
    style: {},
};

export default MoreHorizontalIcon;
