import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { InfoTooltip } from 'components/Common/InfoTooltip';

import {
    BalanceIcon,
    SubscriptionsCountIcon,
} from '../../Icon/Icon';

import * as styles from './SalesInfo.module.scss';

function SalesInfo(props) {
    const {
        activeCount,
        totalMonthly,
        totalAnnual,
        showSalesInfo,
    } = props;
    const { t } = useTranslation();

    if (!showSalesInfo) {
        return null;
    }

    return (
        <Row className={styles.salesInfoPanel}>
            <Row className={styles.cellSalesInfoPanel}>
                <div className="d-flex flex-row flex-nowrap">
                    <SubscriptionsCountIcon className={styles.cellSalesInfoPanelIcon} />

                    <div className={styles.cellSalesInfoPanelItemsWrapper}>
                        <div>
                            <p className="d-inline-flex">
                                {t('subscriptions.salesInfo.totalNumber.label')}
                                <InfoTooltip
                                    text={t('subscriptions.salesInfo.totalNumber.tooltip')}
                                    placement="bottom"
                                />
                            </p>

                            <p className="text-dark font-weight-bold">
                                {!isNaN(activeCount)
                                    ? `#${activeCount}`
                                    : <Skeleton />}
                            </p>
                        </div>
                    </div>
                </div>
            </Row>

            <div className={styles.divider} />

            <Row className={styles.cellSalesInfoPanel}>
                <div className="d-flex flex-row flex-nowrap">
                    <BalanceIcon className={styles.cellSalesInfoPanelIcon} />
                    <div className="d-flex flex-wrap" style={{ gap: '40px' }}>
                        <div className="d-flex flex-column">
                            <p className="d-inline-flex">
                                {t('subscriptions.salesInfo.totalMonthly.label')}
                                <InfoTooltip
                                    text={t('subscriptions.salesInfo.totalMonthly.tooltip')}
                                    placement="bottom"
                                />
                            </p>

                            <p className="text-dark font-weight-bold">
                                {!isNaN(activeCount)
                                    ? totalMonthly
                                    : <Skeleton />}
                            </p>
                        </div>
                        <div className="d-flex flex-column">
                            <p className="d-inline-flex">
                                {t('subscriptions.salesInfo.totalAnnual.label')}
                                <InfoTooltip
                                    text={t('subscriptions.salesInfo.totalAnnual.tooltip')}
                                    placement="bottom"
                                />
                            </p>

                            <p className="text-dark font-weight-bold">
                                {!isNaN(activeCount)
                                    ? totalAnnual
                                    : <Skeleton />}
                            </p>
                        </div>
                    </div>
                </div>
            </Row>
        </Row>
    );
}

SalesInfo.propTypes = {
    activeCount: PropTypes.number,
    totalMonthly: PropTypes.string,
    totalAnnual: PropTypes.string,
    showSalesInfo: PropTypes.bool,
};

export default SalesInfo;
