import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { formatPrice } from 'helpers/formatters/formatPrice';
import { IconButton } from 'components/Common/IconButton';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { Tooltip } from '../../../Common/Tooltip';

import { DownloadIcon } from '../../../Icon/Icon';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.table.body.rows';
const T_FIELDS = `${T_PREFIX}.fields`;

const ClearingsTableBodyRow = (props) => {
    const { clearing } = props;
    const {
        number,
        date,
        totalAmount,
        url,
    } = clearing || {};
    const { t } = useTranslation(LOCALE_NAMESPACE.CLEARINGS);

    const clearingDate = date && moment(date).isValid()
        ? moment(date).format('DD MMM YYYY HH:mm')
        : '-';

    const isShowDownloadIcon = Boolean(url);

    return (
        <>
            <ListTableBodyCell
                className="text-muted"
            >
                {number}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
            >
                {clearingDate}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
                align="right"
            >
                {formatPrice.toEuroWithComma({ amount: totalAmount }) }
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                {isShowDownloadIcon && (
                    <Tooltip
                        tooltip={t(`${T_FIELDS}.actions.download.label`)}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            download
                            href={url}
                            color="gray"
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </ListTableBodyCell>

        </>
    );
};

ClearingsTableBodyRow.propTypes = {
    clearing: PropTypes.object,
};

ClearingsTableBodyRow.defaultProps = {
    clearing: null,
};

export default ClearingsTableBodyRow;
