export const CHAR_SYMBOL = {
    EXP: 'e',
    PLUS: '+',
    MINUS: '-',
    INFINITY: '∞',
    CURRENCY: {
        EURO: '€',
    },
    PRICE: {
        SEPARATOR: {
            DOT: '.',
            COMMA: ',',
        },
    },
};
