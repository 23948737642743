import React from 'react';

const OneAndHalfCercle = ({ className }) => (
    <svg
        width="65"
        height="64"
        fill="none"
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.2139 29.1624C56.2139 35.84 52.6752 41.6911 47.3708 44.9427C48.1193 42.6958 48.5246 40.2921 48.5246 37.7937C48.5246 25.3103 38.4048 15.1904 25.9214 15.1904C25.8126 15.1904 25.7039 15.1912 25.5954 15.1927C28.8418 12.3732 33.0806 10.6665 37.718 10.6665C47.933 10.6665 56.2139 18.9474 56.2139 29.1624ZM43.3292 42.7046C42.8667 44.347 42.1782 45.8946 41.2991 47.3118C41.2985 47.3119 41.2978 47.3121 41.2972 47.3122C38.1078 52.45 32.4148 55.8711 25.9228 55.8711C15.9361 55.8711 7.84025 47.7753 7.84025 37.7885C7.84025 27.8018 15.9361 19.7059 25.9228 19.7059C35.9096 19.7059 44.0054 27.8018 44.0054 37.7885C44.0054 39.4925 43.7697 41.1415 43.3292 42.7046Z"
            fill="currentColor"
        />
    </svg>
);

export default OneAndHalfCercle;
