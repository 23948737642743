import React from 'react';

// Hooks
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { ChevronLeft, ChevronRight } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import {
    OverlayTrigger, Popover,

} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { permissionsResourceSelector } from 'store/selectors/permissions';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Button } from 'components/Common/Button';
import { ProductModal } from '..';
import { Clock } from '../Icon/Icon';

// Styles
import * as styles from './Timeline.module.scss';

const PopoverBody = ({
    vertical = true, buttonVisible, buttonText, titleText, extraRecordsText, product, type,
}) => product && (
    <div className={`${vertical ? 'row' : 'd-flex flex-column align-items-center'} p-2`}>
        <div
            className={`${!vertical && 'mb-4'} col-${!vertical ? 12 : product.services.length ? 5 : 12} d-flex flex-column align-items-center`}
            style={{ gap: 8 }}
        >
            <img
                className="w-100"
                src={product.image}
                alt={product.name}
            />
            {buttonVisible && (
                <Button
                    color="yellow"
                    href={`/agenda/${type}/edit/${product.id}`}
                >
                    {buttonText}
                </Button>
            )}
        </div>
        {product.services.length !== 0 && (
            <div className={`${!vertical && 'text-center'} col-7 pl-${vertical ? '0' : '3'}`}>
                <h5>{titleText}</h5>
                {product.services.slice(0, 4).map((service) => (
                    <div
                        key={service.id}
                        className="mb-2"
                    >
                        <p className="font-size-16 m-0"><strong>{service.name}</strong></p>
                        <div>
                            <p className="d-inline-block text-muted font-size-16 m-0 mr-1">
                                <Clock
                                    width={14}
                                    height={14}
                                    strokeWidth={2.5}
                                    className="position-relative mr-1"
                                />
                                {typeof service.minutes === 'number'
                                    ? `${service.minutes} min`
                                    : service.length}
                            </p>

                            <p className="d-inline-block text-muted font-size-16 m-0">
                                {formatPrice.toEuroWithComma({ amount: service.price })}
                            </p>
                        </div>
                    </div>
                ))}

                {product.services.length > 4 && (
                    <span>{extraRecordsText(product.services.length - 4)}</span>
                )}
            </div>
        )}
    </div>
);

const TimelineHeader = ({
    globalDate, year, view = 'day', dataType = 'objects', index, setIndex, products, showScroll, windowWidth, objectsVisible,
}) => {
    const { t } = useTranslation();

    const { locale } = useSelector((state) => state.locales);

    const { banEdit } = useSelector(permissionsResourceSelector);

    const [popoverModalData, setPopoverModalData] = useState(null);

    return (
        <>
            {view === 'day' && products && _.times(Math.min(objectsVisible, products.length), (i) => (
                <div
                    key={`product-title-${i}`}
                    className={classNames(styles.productTitle, 'd-flex align-items-center px-2')}
                    style={{
                        gridColumn: i + 3,
                        minWidth: 200,
                        top: showScroll ? 0 : undefined,
                    }}
                    data-testid="data-test-timeline-header"
                >
                    {i === 0 && index !== 0 ? (
                        <button
                            data-testid="backArrow"
                            className="bg-transparent border-0 p-0 text-muted"
                            onClick={() => setIndex(Math.max(index - 1, 0))}
                        >
                            <ChevronLeft />
                        </button>
                    ) : <div style={{ width: 24, height: 25.98 }} />}

                    <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="bottom"
                        overlay={(
                            <Popover
                                className={`popover ${!products[i + index]?.services.length ? 'small' : ''}`}
                                content={false}
                                id={products[i + index]?.id}
                            >
                                <PopoverBody
                                    product={products[i + index]}
                                    titleText={t('agendaRoute.prices')}
                                    buttonText={t('agendaRoute.edit')}
                                    buttonVisible={!banEdit}
                                    extraRecordsText={(number) => t('agendaRoute.morePrices', { number })}
                                    type={dataType}
                                />
                            </Popover>
                        )}
                    >
                        {({ ref, onClick }) => (
                            <span
                                ref={ref}
                                className="flex-grow-1 text-center text-break cursor-pointer"
                                onClick={() => (windowWidth < 768 ? setPopoverModalData(products[i + index]) : onClick())}
                            >
                                {products[i + index]?.name}
                            </span>
                        )}
                    </OverlayTrigger>

                    {i === Math.min(objectsVisible, products.length) - 1 && index < products.length - Math.min(products.length, objectsVisible) ? (
                        <button
                            data-testid="nextArrow"
                            className={classNames('bg-transparent border-0 p-0 text-muted')}
                            onClick={() => setIndex(Math.min(products.length - Math.min(products.length, objectsVisible), index + 1))}
                        >
                            <ChevronRight />
                        </button>
                    ) : <div style={{ width: 24, height: 25.98 }} />}
                </div>
            ))}

            {view === 'day' && !products && _.times(4, (i) => (
                <div
                    key={`product-title-${i}`}
                    className={classNames(styles.productTitle, 'd-flex align-items-center px-2')}
                    style={{ gridColumn: i + 3 }}
                    data-testid="data-test-timeline-header"
                >
                    <div className="flex-grow-1 text-center px-4">
                        <Skeleton width={185} height={22} />
                    </div>
                </div>
            ))}

            {view === 'week' && _.times(7, (i) => (
                <div
                    key={`weekday-${i}`}
                    className={classNames(
                        styles.productTitle,
                        moment().isSame(globalDate.clone().isoWeekday(i + 2), 'day') && styles.selectedNext,
                        moment().isSame(globalDate.clone().isoWeekday(i + 1), 'day') && styles.selected,
                        'd-flex align-items-center px-2',
                    )}
                    style={{ gridColumn: i + 3, gridRowStart: 1 }}
                    data-testid="data-test-timeline-header"
                >
                    <div style={{ width: 24, height: 25.98 }} />
                    <div className="flex-grow-1 d-flex flex-column">
                        <span className="text-center text-muted text-uppercase font-size-12 font-weight-normal">
                            {globalDate.clone().locale(locale).isoWeekday(i + 1).format('ddd')}
                        </span>
                        <span className="text-center font-size-14 mt-n1">
                            {globalDate.clone().isoWeekday(i + 1).format('D.MM')}
                        </span>
                    </div>
                    <div style={{ width: 24, height: 25.98 }} />
                </div>
            ))}

            {view === 'month' && _.times(7, (i) => (
                <div
                    key={`weekday-${i}`}
                    className={classNames(styles.productTitle, styles.month, 'd-none d-lg-flex align-items-center px-2')}
                    style={{ gridColumn: i + 1 }}
                    data-testid="data-test-timeline-header"
                >
                    <div style={{ width: 24, height: 25.98 }} />
                    <span className="flex-grow-1 text-center">
                        {moment().locale(locale).year(year).isoWeekday(i + 1)
                            .format('ddd')}
                    </span>
                    <div style={{ width: 24, height: 25.98 }} />
                </div>
            ))}

            {popoverModalData && (
                <ProductModal
                    data={popoverModalData}
                    type={dataType}
                    hide={() => setPopoverModalData(null)}
                    cancelButtonTitle={t('agendaRoute.close')}
                    editButtonTitle={t('agendaRoute.edit')}
                    buttonVisible={!banEdit}
                >
                    <PopoverBody
                        buttonVisible={false}
                        vertical={false}
                        product={popoverModalData}
                        titleText={t('agendaRoute.prices')}
                        buttonText={t('agendaRoute.edit')}
                        extraRecordsText={(number) => t('agendaRoute.morePrices', { number })}
                        type={dataType}
                    />
                </ProductModal>
            )}
        </>
    );
};

export default TimelineHeader;
