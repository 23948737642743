import React, { useEffect, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useConfirmDialog } from 'hooks/useConfirmDialog';

import { ClientBundlesTableHeaderRow } from 'components/clients/ClientPage/ClientTables/ClientBundles/ClientBundlesTableHeaderRow';
import { ConfirmationModal, Switch } from 'components';
import { ClientBundleEditFormDialog } from 'components/clients/ClientPage/ClientTables/ClientBundles/ClientBundleEditFormDialog';

import { Content } from 'components/Common/Content';
import { EmptyTable } from 'components/Common/NewTable/EmptyTable';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { ListTableFooter } from 'components/Common/NewTable/ListTableFooter';
import { ClientBundlesTableBodyRow } from 'components/clients/ClientPage/ClientTables/ClientBundles/ClientBundlesTableBodyRow';
import * as CLIENTS_ACTIONS from '../../../../../../store/actions/clients/tables/bundles';
import * as CLIENTS_SELECTORS from '../../../../../../store/selectors/clients';

import * as styles from './page.module.scss';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 8;

function ClientBundlesTable() {
    const { clientId } = useParams();

    const { t } = useTranslation(LOCALE_NAMESPACE.USER);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const dispatch = useDispatch();

    const {
        page,
        items: bundles,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        hideArchived,
    } = useSelector(CLIENTS_SELECTORS.clientBundlesSelector);

    const editDialog = useConfirmDialog({
        defaultState: false,
    });

    const archiveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: useCallback((bundleId) => {
            dispatch(CLIENTS_ACTIONS.archiveClientBundlesItem({ bundleId }));
        }, [dispatch]),
    });

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBundlesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBundlesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBundlesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBundlesPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBundlesPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setClientBundlesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_ACTIONS.setClientBundlesPage({ page: nextPage }));
    }, [dispatch]);

    const handleEdit = useCallback(({ bundle }) => {
        editDialog.onSetData(bundle);
        editDialog.onShow();
    }, [editDialog.onSetData, editDialog.onShow]);

    const handleArchive = useCallback(({ bundleId }) => {
        archiveConfirmDialog.onSetData(bundleId);
        archiveConfirmDialog.onShow();
    }, [archiveConfirmDialog.onSetData, archiveConfirmDialog.onShow]);

    const handleToggleHideArchived = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.hideArchivedToggle());
    }, [dispatch]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClientBundles({ clientId }));
    }, [dispatch, page, hideArchived]);

    return (
        <Container
            fluid
            className="m-0 p-0 mb-5 mt-4 d-flex flex-column flex-grow-1"
        >
            <div className="m-0 mb-2 p-0 d-flex align-items-center justify-content-start">
                <Switch
                    checked={hideArchived}
                    className={styles.hide}
                    onChange={handleToggleHideArchived}
                />

                <span>
                    {tc('bundles.list.header.actions.hideArchived')}
                </span>
            </div>
            <Content loading={loading}>
                {itemsCount
                    ? (
                        <Table>
                            <TableHeader>
                                <ClientBundlesTableHeaderRow />
                            </TableHeader>
                            <TableBody>
                                {bundles.map((bundle) => (
                                    <ClientBundlesTableBodyRow
                                        key={bundle?.id}
                                        bundle={bundle}
                                        onEdit={handleEdit}
                                        onArchive={handleArchive}
                                    />
                                ))}
                            </TableBody>
                            <ListTableFooter
                                page={page}
                                showAll={showAll}
                                itemsCount={itemsCount}
                                pagesCount={pagesCount}
                                columnsCount={COLUMNS_COUNT}
                                itemsPerPage={ITEMS_PER_PAGE}
                                onShowAll={handleShowAll}
                                onPrevPage={handlePrevPage}
                                onNextPage={handleNextPage}
                                onLastPage={handleLastPage}
                                onFirstPage={handleFirstPage}
                                onShowPages={handleShowPages}
                                onChangePage={handleChangePage}
                            />
                        </Table>
                    )
                    : <EmptyTable />}
            </Content>

            <ConfirmationModal
                isShow={archiveConfirmDialog.visible}
                titleText={t('clientBundlesTable.dialogs.archive.title')}
                bodyText={t('clientBundlesTable.dialogs.archive.text')}
                deleteText={t('clientBundlesTable.dialogs.archive.actions.confirm')}
                dismissText={t('clientBundlesTable.dialogs.archive.actions.reject')}
                confirmAction={archiveConfirmDialog.onConfirm}
                hide={archiveConfirmDialog.onReject}
            />

            <ClientBundleEditFormDialog
                visible={editDialog.visible}
                bundle={editDialog.data}
                onClose={editDialog.onClose}
            />
        </Container>
    );
}

export default ClientBundlesTable;
