import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components/Common/Badge';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'logs.list.table.body.rows';

const LogsBadge = (props) => {
    const { log } = props;

    const {
        allowed, direction,
    } = log || {};

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);

    return (
        <Badge size={props.size} color={allowed ? 'green' : 'red'}>
            {typeof allowed !== 'boolean' ? t(`${T_PREFIX}.scanResult.error`)
                : (allowed ? (direction === 'in'
                    ? t(`${T_PREFIX}.scanResult.allowedEntry`)
                    : t(`${T_PREFIX}.scanResult.exit`))
                    : (direction === 'in'
                        ? t(`${T_PREFIX}.scanResult.deniedEntry`)
                        : t(`${T_PREFIX}.scanResult.error`)))}
        </Badge>
    );
};

LogsBadge.propTypes = {
    log: PropTypes.object,
    size: PropTypes.oneOf(['small', 'extra-small']),
};

LogsBadge.defaultProps = {
    log: null,
    size: 'small',
};

export default LogsBadge;
