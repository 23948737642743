/* eslint-disable import/prefer-default-export */
import * as CLIENTS_TYPES from '../../actions/clients/types';

const INITIAL_STATE = {
    item: {},
    loading: false,
};

export const clientItemReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case CLIENTS_TYPES.CLIENT_ITEM_GET_RESOLVED: {
        const { client } = payload;
        return {
            ...state,
            item: client,
            loading: false,
        };
    }
    case CLIENTS_TYPES.CLIENT_ITEM_GET_TRIGGER: {
        return {
            ...state,
            loading: true,
        };
    }
    case CLIENTS_TYPES.CLIENT_ITEM_GET_REJECTED: {
        return {
            ...state,
            loading: false,
        };
    }
    default:
        return state;
    }
};
