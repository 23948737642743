import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalBody from 'react-bootstrap/ModalBody';

import * as styles from './DialogBody.module.scss';

const DialogBody = (props) => {
    const {
        children,
        className,
    } = props;

    return (
        <ModalBody
            className={classNames(
                styles.dialogBody,
                className,
            )}
            data-testid={props['data-testid']}
        >
            {children}
        </ModalBody>
    );
};

DialogBody.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
};

DialogBody.defaultProps = {
    children: null,
    className: '',
    'data-testid': 'data-test-dialog-body',
};

export default DialogBody;
