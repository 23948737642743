import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useSearchParams = () => {
    const location = useLocation();
    const history = useHistory();
    const [searchParams, setSearchParamsState] = useState(new URLSearchParams(location.search));

    // Update searchParams state when location changes
    useEffect(() => {
        setSearchParamsState(new URLSearchParams(location.search));
    }, [location.search]);

    // Function to set search params
    const setSearchParams = (params) => {
        const search = new URLSearchParams(params);
        Object.entries(params).forEach(([key, value]) => {
            if (!value) {
                search.delete(key);
            }
        });
        history.push({ search: search.toString() });
    };

    return [searchParams, setSearchParams];
};

export default useSearchParams;
