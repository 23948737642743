import {
    useCallback, useEffect, useRef, useState,
} from 'react';

export const useTimer = (initValue) => {
    const [value, setValue] = useState(initValue);
    const intervalIdRef = useRef(null);

    const onStart = useCallback(() => {
        if (!value) {
            return;
        }

        intervalIdRef.current = setInterval(setValue, 1000, (value) => value - 1);
    }, [value]);

    const onStop = useCallback(() => {
        setValue(0);

        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }
    }, []);

    const onRestart = useCallback(() => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }

        setValue(initValue);
        intervalIdRef.current = setInterval(setValue, 1000, (value) => value - 1);
    }, []);

    useEffect(() => () => clearInterval(intervalIdRef.current), []);

    useEffect(() => {
        if (value === 0) {
            clearInterval(intervalIdRef.current);
        }
    }, [value]);

    return {
        value,
        onStart,
        onStop,
        onRestart,
    };
};
