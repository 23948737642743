import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useBool } from './useBool';

export const useDialog = (defaultState = false) => {
    const {
        value,
        onSet,
        onTrue,
        onFalse,
        onToggle,
    } = useBool(defaultState);

    const handleShow = useCallback(debounce((e) => {
        e?.preventDefault?.();
        onTrue();
    }, 200), []);

    const handleClose = useCallback(debounce((e) => {
        e?.preventDefault?.();
        onFalse();
    }, 200), []);

    const handleSetShow = useCallback(debounce((newValue) => {
        onSet(newValue);
    }, 200), []);

    const handleToggle = useCallback(debounce((e) => {
        e?.preventDefault?.();
        onToggle();
    }, 200), []);

    return {
        visible: value,
        onShow: handleShow,
        onClose: handleClose,
        onSetShow: handleSetShow,
        onToggle: handleToggle,
    };
};
