import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { Tooltip } from 'components/Common/Tooltip';
import { Dropdown } from 'react-bootstrap';
import { productInitialData } from 'pages/financials/scenes/invoices/scenes/editInvoice/page';
import { useMobile } from 'hooks/useMobile';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Button } from 'components/Common/Button';
import InvoiceProductFormGroup from './InvoiceProductFormGroup/InvoiceProductFormGroup';

import * as styles from './InvoiceProductsFormGroup.module.scss';

function InvoiceProductsFormGroup({ formik }) {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const {
        values: { products },
        errors,
        touched,
        setFieldValue,
        handleChange,
        setFieldTouched,
    } = formik;

    const isMobile = useMobile();

    const handleAddProduct = useCallback(() => {
        setFieldValue('products', [...products, productInitialData()]);
    }, [setFieldValue, products]);

    const handleDeleteProduct = useCallback(({ id, index }) => {
        const filteredproducts = products.filter((s, i) => String(s.id) !== String(id) || String(index) !== String(i));
        setFieldValue('products', filteredproducts);
    }, [products, setFieldValue]);

    const subTotal = useMemo(() => products.reduce((acc, product) => {
        const price = Number(product.price);
        const quantity = Number(product.quantity);
        const vat = Number(product.vatValue);
        const productTotal = price * quantity;
        return acc + productTotal / (1 + vat / 100);
    }, 0));

    const total = useMemo(
        () => products.reduce((acc, product) => {
            const price = Number(product.price);
            const quantity = Number(product.quantity);
            const productTotal = price * quantity;
            return acc + productTotal;
        }, 0),
        [products],
    );

    const vats = useMemo(() => products.reduce((acc, product) => {
        const vat = Number(product.vatValue);
        const price = Number(product.price);
        const quantity = Number(product.quantity);
        const vatTotal = (price * quantity) * (1 - 100 / (100 + vat));
        if (!vat) {
            return acc;
        }
        const vatIndex = acc.findIndex((v) => v.vat === vat);
        if (vatIndex === -1) {
            return [...acc, { vat, total: vatTotal }];
        }
        acc[vatIndex].total += vatTotal;
        return acc;
    }, []), [products]);

    return (
        <div className={classNames(styles.productsGroup, 'py-4 py-lg-5 px-4 px-lg-5')}>
            <div className="px-2 px-lg-0">
                <h5 className="font-weight-600">
                    {t('add.products.label')}
                </h5>
                {
                    products.map((product, i) => (
                        <div
                            key={i}
                        >
                            <InvoiceProductFormGroup
                                index={i}
                                product={product}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                setFieldTouched={setFieldTouched}
                                onDelete={handleDeleteProduct}
                                isDeleteDisabled={Boolean(products.length === 1)}
                            />
                            <Dropdown.Divider />
                        </div>
                    ))
                }
                <Col
                    className="p-0 pt-4 d-flex flex-grow-1 justify-content-between flex-column flex-xl-row"
                    style={{ gap: 10 }}
                >
                    <Tooltip
                        isDisabled={products.length < INVOICE_PROP.PRODUCTS.MAX_COUNT}
                        tooltip={t('add.addProduct.tooltip', { count: INVOICE_PROP.PRODUCTS.MAX_COUNT })}
                        placement="bottom-start"
                    >
                        <Button
                            onClick={handleAddProduct}
                            disabled={products.length >= INVOICE_PROP.PRODUCTS.MAX_COUNT}
                            color="yellow"
                        >
                            {t('add.addProduct.label')}
                        </Button>
                    </Tooltip>
                    {isMobile && (
                        <>
                            <span className="font-size-14 text-muted mr-2 font-weight-600">
                                {t('add.addProduct.tooltip', { count: INVOICE_PROP.PRODUCTS.MAX_COUNT })}
                            </span>
                            <Dropdown.Divider />
                        </>
                    )}
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between">
                            <span className="font-size-16 text-muted mr-2 font-weight-600">
                                {t('add.products.subtotal.label')}
                            </span>
                            <span className="font-size-16 font-weight-600">
                                {formatPrice.toEuroWithComma({ amount: subTotal })}
                            </span>
                        </div>
                        {vats.map((value) => (
                            <div className="d-flex justify-content-between" key={value.vat}>
                                <span className="font-size-16 text-muted mr-2 font-weight-600">
                                    {t('add.products.vat.label', { vat: value.vat })}
                                </span>
                                <span className="font-size-16 font-weight-600">
                                    {formatPrice.toEuroWithComma({ amount: value.total })}
                                </span>
                            </div>
                        ))}
                        <div className="d-flex justify-content-between">
                            <span className="font-size-16 text-muted mr-2 font-weight-600">
                                {t('add.products.total.label')}
                            </span>
                            <span className="font-size-16 font-weight-600">
                                {formatPrice.toEuroWithComma({ amount: total })}
                            </span>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    );
}

export default InvoiceProductsFormGroup;
