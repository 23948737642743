import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { debounce } from 'lodash';
import Trash3 from 'components/Icon/icons/trash3';
import { useTranslation } from 'react-i18next';
import * as styles from './Chip.module.scss';
import { Tooltip } from '../Tooltip';

const colorClasses = {
    red: styles.redField,
    green: styles.greenField,
    gray: styles.grayField,
    yellow: styles.yellowField,
};

const sizeClasses = {
    regular: styles.regularField,
    small: styles.smallField,
};

const Chip = (props) => {
    const { t } = useTranslation();
    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );
    const debouncedOnDelete = useCallback(
        props.onDeleteClick ? debounce((e) => {
            e?.stopPropagation();
            props.onDeleteClick();
        }, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    return (
        <button
            className={
                classNames(
                    styles.chip,
                    props.capitalize && styles.capitalize,
                    colorClasses[props.color],
                    sizeClasses[props.size],
                    props.onClick && styles.button,
                )
            }
            type="button"
            onClick={props.onClick ? debouncedOnClick : undefined}
        >
            <div className={styles.text}>
                {props.children}
            </div>
            {!!props.onDeleteClick && (
                <Tooltip tooltip={t('placeholders.delete')} placement="bottom">
                    <div onClick={debouncedOnDelete} className={styles.chipDelete} role="button">
                        <Trash3 width={16} height={16} />
                    </div>
                </Tooltip>
            )}
        </button>
    );
};

Chip.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    capitalize: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

Chip.defaultProps = {
    color: 'gray',
    size: 'regular',
    capitalize: false,
};

export default Chip;
