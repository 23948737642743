import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import camelize from 'camelize';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import * as actions from '../../actions';
import axios from '../../../services/axios';
import * as TOAST_ACTIONS from '../../actions/toast';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';
import * as ACCESS_SELECTORS from '../../selectors/access';

import { API_ADMIN_COMPANY_GATES_STATISTICS_ROUTE } from '../../../const/API_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';

function* getAccessStatistics(action) {
    const { gateId } = action.payload;

    const {
        date,
    } = yield select(ACCESS_SELECTORS.accessLogsStatisticsSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_GATES_STATISTICS_ROUTE,
            headers: defaultHeaders,
            params: {
                gateId,
                startDate: date.dateFrom?.unix(),
                endDate: date.dateTo?.unix(),
            },
        });

        const { data } = res;
        const { code, result } = data;
        const gate = camelize(result);
        if (code === HTTP_STATUS_CODE.OK) {
            yield call(resolvePromiseAction, action, { gate });
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'access.statistics.get.error',
                appearance: 'error',
            }));
            yield call(rejectPromiseAction, action);
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const logsStatisticsSaga = [
    takeLatest(actions.getAccessStatistics, getAccessStatistics),
];
