/* eslint-disable no-console */
import sentry from './sentry';

class SoundsManager {
    async decodeBuffer(buffer) {
        try {
            if (!this.context) {
                throw new Error('AudioContext is not available.');
            }
            if (this.context.state === 'suspended') {
                await this.context.resume();
            }
            if (this.context.state !== 'running') {
                throw new Error('AudioContext is not in a running state. Unable to decode audio data.');
            }

            return new Promise((resolve, reject) => {
                this.context.decodeAudioData(buffer, (audio) => {
                    resolve(audio);
                }, (error) => {
                    reject(new Error(`Failed to decode audio data: ${error.message}`));
                });
            });
        } catch (error) {
            sentry.error('[notify] Error decoding buffer:', error);
            return null;
        }
    }

    async prepareBuffers() {
        const decodedAudioBuffers = Object.entries(this.soundsMap).map(async ([key, url]) => {
            try {
                const response = await fetch(url);
                if (!response?.ok) {
                    throw new Error(`[notify] Failed to fetch audio file: ${response.statusText}`);
                }

                const buffer = await response.arrayBuffer();
                const audio = await this.decodeBuffer(buffer);
                return [key, audio];
            } catch (error) {
                sentry.error(`[notify] Error preparing buffer for ${key}:`, error);
                return [key, null];
            }
        });

        const buffersArray = await Promise.all(decodedAudioBuffers);
        const buffersFiltered = buffersArray.filter(([_, audio]) => audio !== null);
        this.buffers = Object.fromEntries(buffersFiltered);
    }

    unlockAudioContext() {
        const addFirstInteractionListener = () => {
            document.body.addEventListener('mousedown', this.unlock, {
                once: true,
                passive: true,
            });
            document.body.addEventListener('touchstart', this.unlock, {
                once: true,
                passive: true,
            });
        };
        if (!document.body) {
            document.addEventListener('DOMContentLoaded', addFirstInteractionListener);
        } else {
            addFirstInteractionListener();
        }
    }

    playBuffer(audioBuffer, loop = false) {
        this.stopBuffer(audioBuffer);
        const source = this.context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.context.destination);
        source.loop = loop;
        source.start();
        this.playing.set(audioBuffer, source);
        return source;
    }

    stopBuffer(audioBuffer) {
        const source = this.playing.get(audioBuffer);
        if (source) {
            source.stop();
            this.playing.delete(audioBuffer);
        }
    }

    constructor(soundsMaps) {
        this.disabled = false;
        this.context = new window.AudioContext();
        this.unlock = () => {
            console.log('[notify] AudioContext unlocking...');
            const buffer = this.context.createBuffer(1, 1, 22050);
            const source = this.playBuffer(buffer);
            source.onended = () => {
                console.log('[notify] AudioContext unlocked');
                this.stopBuffer(buffer);
            };
        };
        this.playing = new Map();
        this.play = (type, loop) => {
            if (this.disabled) {
                return false;
            }
            this.playBuffer(this.buffers[type], loop);
            return this.context.state === 'running';
        };
        this.stop = (type) => {
            if (this.disabled) {
                return;
            }
            this.stopBuffer(this.buffers[type]);
        };
        this.stopAll = () => {
            Object.values(this.buffers).forEach((buffer) => {
                this.stopBuffer(buffer);
            });
        };
        if ('AudioContext' in window || 'webkitAudioContext' in window) {
            this.soundsMap = soundsMaps;
            this.soundsKeys = Object.fromEntries(Object.keys(soundsMaps).map((key) => [
                `${key}`.toUpperCase(),
                key,
            ]));
            this.prepareBuffers();
        } else {
            this.disabled = true;
        }
    }
}
export const soundsManager = new SoundsManager({
    newBooking: '/sounds/newBookingSound.mp3',
    cancelBooking: '/sounds/cancelBookingSound.mp3',
});
