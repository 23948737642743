export const constPreset = {
    admin: {
        objectSection: 'always',
        employeeSection: 'always',
        groupSection: 'always',
        eventSection: 'always',
        addBooking: true,
        editBooking: true,
        viewBooking: true,
        setPaid: true,
        setPresent: true,
        cancelRegular: true,
        cancelOccurrence: true,
        cancelWholeRecurring: true,
        addBookingGroupSession: true,
        cancelGroupSession: true,
        overrideGroupSession: true,
        addBookingEvent: true,
        cancelEvent: true,
        sendNotificationGroupSession: true,
        sendNotificationEvent: true,
        addResource: true,
        editResource: true,
        deleteResource: true,
        editDeleteBlock: true,
        addBlockChooseResource: true,
        addBlock: true,
        switchShop: true,
        addClient: true,
    },
    companyOwner: {
        objectSection: 'always',
        employeeSection: 'always',
        groupSection: 'always',
        eventSection: 'always',
        addBooking: true,
        editBooking: true,
        viewBooking: true,
        setPaid: true,
        setPresent: true,
        cancelRegular: true,
        cancelOccurrence: true,
        cancelWholeRecurring: true,
        addBookingGroupSession: true,
        cancelGroupSession: true,
        overrideGroupSession: true,
        addBookingEvent: true,
        cancelEvent: true,
        sendNotificationGroupSession: true,
        sendNotificationEvent: true,
        addResource: true,
        editResource: true,
        deleteResource: true,
        editDeleteBlock: true,
        addBlockChooseResource: true,
        addBlock: true,
        switchShop: true,
        addClient: true,
    },
    employee: {
        basic: {
            objectSection: 'never',
            employeeSection: 'hasOwn',
            groupSection: 'hasOwn',
            eventSection: 'hasOwn',
            addBooking: true,
            editBooking: true,
            viewBooking: true,
            setPaid: true,
            setPresent: true,
            cancelRegular: true,
            cancelOccurrence: true,
            cancelWholeRecurring: true,
            addBookingGroupSession: true,
            cancelGroupSession: true,
            overrideGroupSession: true,
            addBookingEvent: true,
            cancelEvent: true,
            sendNotificationGroupSession: true,
            sendNotificationEvent: true,
            addResource: false,
            editResource: false,
            deleteResource: false,
            editDeleteBlock: true,
            addBlockChooseResource: false,
            addBlock: true,
            switchShop: false,
            addClient: true,
        },
        plus: {
            objectSection: 'never',
            employeeSection: 'hasOwn',
            groupSection: 'hasOwn',
            eventSection: 'hasOwn',
            addBooking: true,
            editBooking: true,
            viewBooking: true,
            setPaid: true,
            setPresent: true,
            cancelRegular: true,
            cancelOccurrence: true,
            cancelWholeRecurring: true,
            addBookingGroupSession: true,
            cancelGroupSession: true,
            overrideGroupSession: true,
            addBookingEvent: true,
            cancelEvent: true,
            sendNotificationGroupSession: true,
            sendNotificationEvent: true,
            addResource: false,
            editResource: false,
            deleteResource: false,
            editDeleteBlock: true,
            addBlockChooseResource: false,
            addBlock: true,
            switchShop: false,
            addClient: true,
        },
        full: {
            objectSection: 'hasAny',
            employeeSection: 'hasAny',
            groupSection: 'hasAny',
            eventSection: 'hasAny',
            addBooking: true,
            editBooking: true,
            viewBooking: true,
            setPaid: true,
            setPresent: true,
            cancelRegular: true,
            cancelOccurrence: true,
            cancelWholeRecurring: true,
            addBookingGroupSession: true,
            cancelGroupSession: true,
            overrideGroupSession: true,
            addBookingEvent: true,
            cancelEvent: true,
            sendNotificationGroupSession: true,
            sendNotificationEvent: true,
            addResource: false,
            editResource: false,
            deleteResource: false,
            editDeleteBlock: true,
            addBlockChooseResource: true,
            addBlock: true,
            switchShop: true,
            addClient: true,
        },
    },
};
