import { BOOKABLE_TYPE, BOOKABLE_TYPES_ARRAY } from './BOOKABLE_TYPE';

export const SERVICE_PROP = {
    NAME: {
        MIN_LENGTH: 2,
        MAX_LENGTH: 250,
    },
    TIME: {
        MIN: 1,
        STEP: 1,
        DEFAULT: 1,
    },
    PRICE: {
        MIN: 0,
        STEP: 1,
        MAX: 4000,
        TO_FIXED: 2,
        DEFAULT: 0.00,
    },
    VAT_RATE: {
        ENUM: [0, 6, 9, 20, 21],
        DEFAULT: 21,
    },
    BOOKABLE: {
        DEFAULT: BOOKABLE_TYPE.ALWAYS,
        ENUM: [...BOOKABLE_TYPES_ARRAY],
    },
    SEATS_AMOUNT: {
        MIN: 1,
        STEP: 1,
        DEFAULT: 1,
    },
    RANDOM_SELECT: {
        DEFAULT: true,
    },
    RESTRICT_UNPAID_BOOKINGS: {
        DEFAULT: false,
    },
    HIDE_PRICE: {
        DEFAULT: true,
    },
    ADDITIONAL_TIME: {
        MIN: 0,
        STEP: 1,
        MAX: 60,
        DEFAULT: 0,
    },
    BOOKING_FREQUENCY: {
        MIN: 15,
        STEP: 15,
        MAX: 720,
        DEFAULT: 15,
    },
};
