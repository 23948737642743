import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { debounce } from 'lodash';
import * as styles from './TextButton.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    red: styles.red,
    green: styles.green,
};

const TextButton = (props) => {
    if (props.href) {
        return (
            <a
                onClick={props.onClick}
                className={
                    classNames(
                        styles.button,
                        styles.link,
                        props.capitalize && styles.capitalize,
                        props.noPadding && styles.noPadding,
                        props.disabled && styles.disabled,
                        colorClasses[props.color],
                    )
                }
                href={props.href}
                type={props.type}
                data-testid={props['data-testid']}
            >
                {props.before && (
                    <div className={styles.before}>
                        { props.before }
                    </div>
                )}
                {props.children}
                <span className={colorClasses[props.afterColor]}>{props.after}</span>
            </a>
        );
    }

    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    return (
        <button
            onClick={debouncedOnClick}
            className={
                classNames(
                    styles.button,
                    props.capitalize && styles.capitalize,
                    props.noPadding && styles.noPadding,
                    props.disabled && styles.disabled,
                    colorClasses[props.color],
                )
            }
            type={props.type}
            data-testid={props['data-testid']}
        >
            {props.before && (
                <div className={styles.before}>
                    { props.before }
                </div>
            )}
            {props.children}
            <span className={colorClasses[props.afterColor]}>{props.after}</span>
        </button>
    );
};

TextButton.propTypes = {
    color: PropTypes.string,
    after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    afterColor: PropTypes.string,
    capitalize: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    href: PropTypes.string,
    noPadding: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    'data-testid': PropTypes.string,
};

TextButton.defaultProps = {
    color: 'yellow',
    afterColor: 'black',
    capitalize: false,
    noPadding: false,
    disabled: false,
    type: 'button',
    'data-testid': 'data-test-text-button',
};

export default TextButton;
