// extracted by mini-css-extract-plugin
var _1 = "page_actions__W0rE5";
var _2 = "page_contentHeader__lMd6J";
var _3 = "page_contentSubTitle__p8bwH";
var _4 = "page_contentTitle__Oil96";
var _5 = "page_contentTitleContainer__EoaaF";
var _6 = "page_description__5sLzt";
var _7 = "page_image__zIRMf";
var _8 = "page_link__vg6tX";
var _9 = "page_qrCode__JOZzS";
var _a = "page_rowContent__w5kf4";
export { _1 as "actions", _2 as "contentHeader", _3 as "contentSubTitle", _4 as "contentTitle", _5 as "contentTitleContainer", _6 as "description", _7 as "image", _8 as "link", _9 as "qrCode", _a as "rowContent" }
