import React from 'react';
import { ComingSoon } from 'components';

function EmailTemplates() {
    return (
        <ComingSoon />
    );
}

export default EmailTemplates;
