import * as MESSAGES_TYPES from './types';

export const getMessages = () => ({
    type: MESSAGES_TYPES.MESSAGES_GET,
});

export const clearMessages = () => ({
    type: MESSAGES_TYPES.MESSAGES_CLEAR,
});

export const setMessagesLoading = ({ isLoading }) => ({
    type: MESSAGES_TYPES.MESSAGES_LOADING_SET,
    payload: { isLoading },
});

export const setMessages = ({
    items, itemsCount, pagesCount,
}) => ({
    type: MESSAGES_TYPES.MESSAGES_SET,
    payload: {
        items,
        itemsCount,
        pagesCount,
    },
});

export const setMessagesSortOptions = ({ sortOptions }) => ({
    type: MESSAGES_TYPES.MESSAGES_SORT_OPTIONS_SET,
    payload: { sortOptions },
});

export const showAllMessages = () => ({
    type: MESSAGES_TYPES.MESSAGES_SHOW_ALL,
});

export const setMessagesPage = ({ page }) => ({
    type: MESSAGES_TYPES.MESSAGES_PAGE_SET,
    payload: { page },
});

export const setMessagesPageFirst = () => ({
    type: MESSAGES_TYPES.MESSAGES_PAGE_SET_FIRST,
});

export const setMessagesPageLast = () => ({
    type: MESSAGES_TYPES.MESSAGES_PAGE_SET_LAST,
});

export const setMessagesPagePrev = () => ({
    type: MESSAGES_TYPES.MESSAGES_PAGE_SET_PREV,
});

export const setMessagesPageNext = () => ({
    type: MESSAGES_TYPES.MESSAGES_PAGE_SET_NEXT,
});

export const addMessagesItem = ({ message, history }) => ({
    type: MESSAGES_TYPES.MESSAGES_ITEM_ADD,
    payload: {
        message,
        history,
    },
});

export const editMessagesItem = ({ messageId, message, history }) => ({
    type: MESSAGES_TYPES.MESSAGES_ITEM_EDIT,
    payload: {
        messageId,
        message,
        history,
    },
});

export const addClientsToMessagesItem = ({ messageId, message, onSuccess }) => ({
    type: MESSAGES_TYPES.MESSAGES_ITEM_ADD_CLIENTS,
    payload: {
        messageId,
        message,
        onSuccess,
    },
});

export const removeMessagesItem = ({ messageId, history }) => ({
    type: MESSAGES_TYPES.MESSAGES_ITEM_REMOVE,
    payload: { messageId, history },
});

export const setMessagesSearch = ({ search }) => ({
    type: MESSAGES_TYPES.MESSAGES_SEARCH_SET,
    payload: { search },
});

export const getMessageItem = ({ messageId }) => ({
    type: MESSAGES_TYPES.MESSAGE_ITEM_GET,
    payload: { messageId },
});

export const setMessageItem = ({ message }) => ({
    type: MESSAGES_TYPES.MESSAGE_ITEM_SET,
    payload: { message },
});

export const setMessageItemLoading = ({ isLoading }) => ({
    type: MESSAGES_TYPES.MESSAGE_ITEM_LOADING_SET,
    payload: { isLoading },
});

export const setMessageItemSavingProcess = ({ isSavingProcess }) => ({
    type: MESSAGES_TYPES.MESSAGE_ITEM_SAVING_PROCESS_SET,
    payload: { isSavingProcess },
});

export const showMessageDetailsDialog = (message) => ({
    type: MESSAGES_TYPES.MESSAGE_ITEM_SHOW_DETAILS,
    payload: { message },
});

export const hideMessageDetailsDialog = () => ({
    type: MESSAGES_TYPES.MESSAGE_ITEM_HIDE_DETAILS,
});
