import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import axios from 'services/axios';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as CLIENTS_ACTIONS from '../../../actions/clients/tables/bundles';
import * as CLIENTS_TYPES from '../../../actions/clients/tables/bundles/types';
import * as CLIENTS_SELECTORS from '../../../selectors/clients';

import * as TOAST_ACTIONS from '../../../actions/toast';

import { HTTP_METHOD } from '../../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_BUNDLE_SOLD_ARCHIVE_ID_ROUTE,
    API_ADMIN_BUNDLE_SOLD_EDIT_ID_ROUTE,
    API_ADMIN_CLIENT_BUNDLES_ID_ROUTE,
} from '../../../../const/API_URL';

function* getClientBundles(action) {
    const { payload } = action;
    const { clientId } = payload;

    const { page, hideArchived } = yield select(CLIENTS_SELECTORS.clientBundlesSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(CLIENTS_ACTIONS.setClientBundlesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_BUNDLES_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            params: {
                page,
                hideInactive: Number(hideArchived),
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
            } = result;
            const bundles = items.map((item) => {
                const bundle = camelize(item);
                return {
                    id: bundle.id,
                    name: bundle.bundleName,
                    usages: bundle.bookingsInfo.value,
                    maxUsages: bundle.bookingsInfo.max,
                    price: bundle.price,
                    profit: bundle.profit,
                    services: bundle.services.map((s) => ({
                        id: s.id,
                        name: s.name,
                    })),
                    dateTime: bundle.purchasedAt,
                    endsAt: bundle.endsAt,
                    purchasedAt: bundle.purchasedAt,
                    paymentType: bundle.paymentInfo,
                    isEnded: bundle.isEnded,
                    deleted: bundle.deleted,
                };
            });
            yield put(CLIENTS_ACTIONS.setClientBundles({
                bundles,
                itemsCount,
                pagesCount,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.getBundles.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(CLIENTS_ACTIONS.setClientBundlesLoading({ loading: false }));
    }
}

function* editClientBundlesItem(action) {
    const { payload } = action;
    const { bundleId, bundle } = payload;

    const { item: { id: clientId } } = yield select(CLIENTS_SELECTORS.clientItemSelector);
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_SOLD_EDIT_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
            data: bundle,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.edit.success',
                appearance: 'success',
            }));
            yield put(CLIENTS_ACTIONS.getClientBundles({ clientId }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundles.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* archiveClientBundlesItem(action) {
    const { payload } = action;
    const { bundleId } = payload;

    const { item: { id: clientId } } = yield select(CLIENTS_SELECTORS.clientItemSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'POST',
            url: API_ADMIN_BUNDLE_SOLD_ARCHIVE_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.archive.success',
                appearance: 'success',
            }));
            yield put(CLIENTS_ACTIONS.getClientBundles({ clientId }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'soldBundles.archive.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const clientBundlesSaga = [
    takeLatest(CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_GET, getClientBundles),
    takeLatest(CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_EDIT, editClientBundlesItem),
    takeLatest(CLIENTS_TYPES.CLIENT_ITEM_BUNDLES_ARCHIVE, archiveClientBundlesItem),
];
