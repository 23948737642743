import React, { useCallback } from 'react';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { SubscriptionServiceFormGroup } from './SubscriptionServiceFormGroup';

import { SUBSCRIPTION_PROP } from '../../../const/subscriptions/SUBSCRIPTION_PROP';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import { generateRandomString } from '../../../helpers/string/generateRandomString';

import * as styles from './SunscriptionServicesFormGroup.module.scss';

function SubscriptionServicesFormGroup({ formik, servicesOptions }) {
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const {
        values: { services, soldCounter },
        errors,
        touched,
        setFieldValue,
        handleChange,
        setFieldTouched,
    } = formik;
    const noServices = !services.length;

    const handleAddService = useCallback(() => {
        const serviceInitialData = {
            name: [],
            maximumUsages: '',
            unlimitedUsages: false,
            period: SUBSCRIPTION_PROP.SERVICES.PERIOD.DEFAULT,
            missedUsages: {
                selected: SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.SELECTED.DEFAULT,
                value: SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.DEFAULT,
            },
            id: generateRandomString(),
        };

        setFieldValue('services', [...services, serviceInitialData]);
    }, [setFieldValue, services]);

    const handleDeleteService = useCallback(({ id }) => {
        const filteredServices = services.filter((s) => String(s.id) !== String(id));
        setFieldValue('services', filteredServices);
    }, [services, setFieldValue]);

    return (
        <div className={classNames(styles.servicesGroup, 'py-4 px-4 px-lg-5')}>
            <div className="px-2 px-lg-0">
                <h5 className="font-weight-600">
                    {tc('servicesRoute.services')}
                </h5>
                {
                    noServices && (
                        <span className={styles.noServicesMessage}>
                            {t('addOrEdit.form.fields.services.empty')}
                        </span>
                    )
                }
                {
                    services.map((service, i) => (
                        <SubscriptionServiceFormGroup
                            key={service.id}
                            index={i}
                            service={service}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            setFieldTouched={setFieldTouched}
                            servicesOptions={servicesOptions}
                            isDisabled={Boolean(soldCounter)}
                            onDelete={handleDeleteService}
                        />
                    ))
                }
                <Col className="p-0 pt-4">
                    <Button
                        onClick={handleAddService}
                        disabled={Boolean(soldCounter)}
                    >
                        {tc('servicesRoute.addService')}
                    </Button>
                </Col>
            </div>
        </div>
    );
}

export default SubscriptionServicesFormGroup;
