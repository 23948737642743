import ClientProfiles from './clientProfiles';
import Company from './company';
import InviteClients from './inviteClients';
import EmailTemplates from './emailTemplates';
import Location from './location';
import WidgetIntegration from './widgetIntegration';

const routes = [
    {
        component: Company,
        exact: false,
        path: '/settings/company',
    },
    {
        component: InviteClients,
        exact: false,
        path: '/settings/invite-clients',
    },
    {
        component: Location,
        exact: true,
        path: '/settings/location',
    },
    {
        component: EmailTemplates,
        exact: true,
        path: '/settings/email-templates',
    },
    {
        component: ClientProfiles,
        exact: true,
        path: '/settings/client-profiles',
    },
    {
        component: WidgetIntegration,
        exact: true,
        path: '/settings/widget-integration',
    },
];

export default routes;
