import moment from 'moment';

import { TIME_PERIOD } from '../../../../const/clients/TIME_PERIOD';
import * as CLIENTS_TYPES from '../../../actions/clients/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    active_bookings: 0,
    yearFilter: +moment().format('YYYY'),
    monthFilter: +moment().format('M'),
    periodFilter: TIME_PERIOD.MONTH,
};

export const clientBookingsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (CLIENTS_TYPES.CLIENT_ITEM_GET_RESOLVED): {
        return INITIAL_STATE;
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_SET): {
        const {
            bookings,
            itemsCount,
            pagesCount,
            active_bookings,
        } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: bookings,
            page,
            itemsCount,
            pagesCount,
            active_bookings,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    case CLIENTS_TYPES.CLIENT_ITEM_BOOKINGS_FILTERS_SET: {
        const { year, month, period } = payload;

        return {
            ...state,
            yearFilter: year || state.yearFilter,
            monthFilter: month || state.monthFilter,
            periodFilter: period || state.periodFilter,
        };
    }
    default:
        return state;
    }
};
