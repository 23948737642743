export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_SUCCEEDED = 'GET_BOOKINGS_SUCCEEDED';
export const GET_BOOKINGS_FAILED = 'GET_BOOKINGS_FAILED';

export const ADD_BOOKING = 'ADD_BOOKING';
export const ADD_BOOKING_SUCCEEDED = 'ADD_BOOKING_SUCCEEDED';
export const ADD_BOOKING_FAILED = 'ADD_BOOKING_FAILED';

export const GET_BOOKING_PAYMENT_OPTIONS = 'GET_BOOKING_PAYMENT_OPTIONS';
export const GET_BOOKING_PAYMENT_OPTIONS_SUCCEEDED = 'GET_BOOKING_PAYMENT_OPTIONS_SUCCEEDED';
export const GET_BOOKING_PAYMENT_OPTIONS_FAILED = 'GET_BOOKING_PAYMENT_OPTIONS_FAILED';

export const EDIT_BOOKING = 'EDIT_BOOKING';
export const EDIT_BOOKING_SUCCEEDED = 'EDIT_BOOKING_SUCCEEDED';
export const EDIT_BOOKING_FAILED = 'EDIT_BOOKING_FAILED';

export const DELETE_BOOKING = 'DELETE_BOOKING';
export const DELETE_BOOKING_SUCCEEDED = 'DELETE_BOOKING_SUCCEEDED';
export const DELETE_BOOKING_FAILED = 'DELETE_BOOKING_FAILED';

export const OMIT_BOOKING = 'OMIT_BOOKING';
export const OMIT_BOOKING_SUCCEEDED = 'OMIT_BOOKING_SUCCEEDED';
export const OMIT_BOOKING_FAILED = 'OMIT_BOOKING_FAILED';

export const CHANGE_PAY_STATUS_BOOKING = 'CHANGE_PAY_STATUS_BOOKING';
export const CHANGE_PAY_STATUS_BOOKING_SUCCEEDED = 'CHANGE_PAY_STATUS_BOOKING_SUCCEEDED';
export const CHANGE_PAY_STATUS_BOOKING_FAILED = 'CHANGE_PAY_STATUS_BOOKING_FAILED';

export const SET_ADD_BOOKING_DETAILS = 'SET_ADD_BOOKING_DETAILS';
export const CLEAR_ADD_BOOKING_DETAILS = 'CLEAR_ADD_BOOKING_DETAILS';
