import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import camelize from 'camelize';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';

import * as MESSAGES_TYPES from '../../actions/messages/types';

import * as MESSAGES_ACTIONS from '../../actions/messages';
import * as TOAST_ACTIONS from '../../actions/toast';

import * as MESSAGES_SELECTORS from '../../selectors/messages';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import {
    API_ADMIN_PROMOTION_MESSAGES_ROUTE,
} from '../../../const/API_URL';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';

function* getMessages(action) {
    const { page, search, sortOptions } = yield select(MESSAGES_SELECTORS.messagesListSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(MESSAGES_ACTIONS.setMessagesLoading({ isLoading: true }));

        const res = yield call(axios.request, {
            method: HTTP_METHOD.GET,
            url: API_ADMIN_PROMOTION_MESSAGES_ROUTE,
            headers: defaultHeaders,
            params: {
                page,
                search,
                ...sortOptions,
            },
        });

        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(MESSAGES_ACTIONS.setMessages(camelize(result)));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'messages.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(MESSAGES_ACTIONS.setMessagesLoading({ isLoading: false }));
    }
}

export const messagesListSaga = [
    takeLatest(MESSAGES_TYPES.MESSAGES_GET, getMessages),
];
