import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientBookingsTableHeader.module.scss';

const T_PREFIX = 'bookings.list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;
function ClientBookingsTableHeader() {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    return (
        <tr>
            <TableHeaderCell className={styles.wideCol}>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.location.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.wideCol}>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.serviceName.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.timeColumn}>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.paymentDate.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.timeColumn}>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.cancellationDate.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.timeColumn}>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.serviceDate.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.paymentType.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.paymentStatus.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.transactionId.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.amount.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.status.label`)}
                />
            </TableHeaderCell>
        </tr>
    );
}

ClientBookingsTableHeader.propTypes = {};

ClientBookingsTableHeader.defaultProps = {};

export default ClientBookingsTableHeader;
