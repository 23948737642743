import React from 'react';

const Search2 = (props) => {
    const { width = '25', height = '24' } = props;

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.3926 14.6086L21.2343 19.4505C21.4045 19.6208 21.5001 19.8518 21.5 20.0925C21.4999 20.3333 21.4042 20.5641 21.2339 20.7343C21.0636 20.9045 20.8327 21.0001 20.5919 21C20.3511 20.9999 20.1203 20.9042 19.9501 20.7339L15.1083 15.892C13.6609 17.0131 11.8408 17.5406 10.0183 17.3674C8.19573 17.1941 6.50764 16.333 5.29742 14.9593C4.0872 13.5855 3.44576 11.8023 3.5036 9.97241C3.56143 8.14251 4.31419 6.40337 5.60874 5.10879C6.90329 3.81421 8.64238 3.06143 10.4722 3.0036C12.3021 2.94576 14.0853 3.58722 15.459 4.79746C16.8327 6.00771 17.6937 7.69585 17.867 9.51844C18.0403 11.341 17.5127 13.1612 16.3917 14.6086H16.3926ZM10.7003 15.5995C12.1324 15.5995 13.5059 15.0306 14.5185 14.0179C15.5312 13.0052 16.1001 11.6318 16.1001 10.1996C16.1001 8.76748 15.5312 7.39399 14.5185 6.38132C13.5059 5.36865 12.1324 4.79973 10.7003 4.79973C9.26823 4.79973 7.89479 5.36865 6.88214 6.38132C5.86949 7.39399 5.30059 8.76748 5.30059 10.1996C5.30059 11.6318 5.86949 13.0052 6.88214 14.0179C7.89479 15.0306 9.26823 15.5995 10.7003 15.5995Z" fill="currentColor" />
        </svg>
    );
};

export default Search2;
