import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './Avatar.module.scss';

const T_PREFIX = 'avatar';

const Avatar = (props) => {
    const {
        src,
        size,
        className,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.USER);

    return (
        <div
            style={{
                width: size, height: size, minWidth: size, minHeight: size,
            }}
            className={classNames(styles.avatarContainer, className)}
            data-testid={props['data-testid']}
        >
            {src ? (
                <img
                    src={src}
                    alt={t(`${T_PREFIX}.alt.default`)}
                    className={styles.avatar}
                />
            ) : <div className={styles.empty} />}
        </div>
    );
};

Avatar.propTypes = {
    src: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
};

Avatar.defaultProps = {
    className: '',
    'data-testid': 'data-test-avatar',
};

export default Avatar;
