import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { Bell, BellOff } from 'components/Icon/Icon';
import { Tooltip } from 'components/Common/Tooltip';

import IconButton from 'components/Common/IconButton/IconButton';
import * as styles from './NotificationsBell.module.scss';

const NotificationsBell = (props) => {
    const {
        isDataLoaded,
        enabled,
        onChange,
    } = props;

    const { t } = useTranslation();

    const handleChange = useCallback(debounce(() => {
        onChange(!enabled);
    }, 500), [enabled]);

    if (!isDataLoaded) {
        return (
            <span>
                <Skeleton
                    height={38}
                    width={40}
                />
            </span>
        );
    }

    return (
        <Tooltip
            tooltip={t(`agendaRoute.notificationsBell.${enabled}.tooltip`)}
            placement="bottom-start"
        >
            <IconButton
                size={40}
                color="outline"
                onClick={handleChange}
            >
                {enabled ? <Bell className={styles.bell} width={24} /> : <BellOff width={24} />}
            </IconButton>
        </Tooltip>
    );
};

NotificationsBell.propTypes = {
    isDataLoaded: PropTypes.bool,
    enabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default NotificationsBell;
