import React from 'react';

const Reload = ({
    fill = 'currentColor',
    width = '16',
    height = '16',
    className,
}) => (
    <svg className={className} height={height} width={width} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9113 2.00463C14.277 2.04765 14.5385 2.37896 14.4955 2.74463L14.1033 6.07796C14.0822 6.25762 13.989 6.42098 13.845 6.53054C13.7011 6.64011 13.5188 6.68647 13.34 6.659L10.3988 6.20702C10.0349 6.15109 9.78521 5.81074 9.84113 5.44683C9.89706 5.08291 10.2374 4.83323 10.6013 4.88915L12.0941 5.11855C11.2612 4.0242 9.94003 3.3334 8.45774 3.3334C5.92087 3.3334 3.8334 5.4299 3.8334 8.00006C3.8334 8.36825 3.53492 8.66673 3.16673 8.66673C2.79854 8.66673 2.50006 8.36825 2.50006 8.00006C2.50006 4.70356 5.1745 2.00006 8.45774 2.00006C10.2709 2.00006 11.9071 2.80636 12.9927 4.10726L13.1713 2.58884C13.2143 2.22317 13.5456 1.96161 13.9113 2.00463ZM13.8334 7.3334C14.2016 7.3334 14.5001 7.63187 14.5001 8.00006C14.5001 11.2966 11.8256 14.0001 8.54239 14.0001C6.72924 14.0001 5.09298 13.1938 4.00747 11.8929L3.82883 13.4113C3.78581 13.777 3.4545 14.0385 3.08884 13.9955C2.72317 13.9525 2.46161 13.6212 2.50463 13.2555L2.89679 9.92217C2.91792 9.74251 3.01116 9.57915 3.15511 9.46959C3.29906 9.36002 3.48135 9.31366 3.66015 9.34113L6.60132 9.79311C6.96524 9.84903 7.21492 10.1894 7.159 10.5533C7.10307 10.9172 6.76272 11.1669 6.3988 11.111L4.90603 10.8816C5.73896 11.9759 7.06009 12.6667 8.54239 12.6667C11.0793 12.6667 13.1667 10.5702 13.1667 8.00006C13.1667 7.63187 13.4652 7.3334 13.8334 7.3334Z"
            fill={fill}
        />
    </svg>
);

export default Reload;
