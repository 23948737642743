import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { ArrowSmallLeft } from 'components/Icon/Icon';
import { IconButton } from '../IconButton';

const BackButton = (props) => {
    const history = useHistory();

    const handleClick = useCallback(() => {
        if (props.disabled) {
            return;
        }
        if (history.length > 1) {
            history.goBack();
        }
        if (props.href) {
            history.push(props.href);
        }
        if (props.onClick) {
            props.onClick();
        }
    }, [history, props.href, props.onClick, props.disabled]);

    return (
        <IconButton
            onClick={handleClick}
            size={40}
            color="outline"
            disabled={props.disabled}
            data-testid={props['data-testid']}
        >
            <ArrowSmallLeft />
        </IconButton>
    );
};

BackButton.propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    'data-testid': PropTypes.string,
};

BackButton.defaultProps = {
    'data-testid': 'data-test-back-button',
};

export default BackButton;
