import React, {
    useCallback, useMemo,
} from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isInvalid, getError } from 'services/validationService';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { WindowedMenuList } from 'react-windowed-select';
import { components } from 'react-select';

import {
    styles, dangerTheme, theme,
} from 'styles/select';
import classNames from 'classnames';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { productInitialData } from 'pages/financials/scenes/invoices/scenes/editInvoice/page';
import { formatPrice } from 'helpers/formatters/formatPrice';

const MAX_PRODUCT_NAME = 250;

function ProductsSelect({
    product,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    products,
    index,
}) {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const resetFieldsError = useCallback(() => {
        setFieldTouched(`products[${index}].name`, false, false);
        setFieldTouched(`products[${index}].price`, false, false);
        setFieldTouched(`products[${index}].vatValue`, false, false);
        setFieldTouched(`products[${index}].quantity`, false, false);
    }, []);

    const onProductCreateOption = useCallback((name) => {
        resetFieldsError();
        const productInitial = productInitialData();
        setFieldValue(`products[${index}].id`, productInitial.id);
        setFieldValue(`products[${index}].name`, name);
        setFieldValue(`products[${index}].price`, product.price ?? productInitial.price);
        setFieldValue(`products[${index}].vatValue`, product.vatValue ?? productInitial.vatValue);
        setFieldValue(`products[${index}].quantity`, product.quantity ?? productInitial.quantity);
    }, [product]);

    const onProductChange = useCallback((selected) => {
        resetFieldsError();
        if (!selected) {
            const productInitial = productInitialData();
            setFieldValue(`products[${index}].id`, productInitial.id);
            setFieldValue(`products[${index}].name`, product.name ?? productInitial.name);
            setFieldValue(`products[${index}].price`, product.price ?? productInitial.price);
            setFieldValue(`products[${index}].vatValue`, product.vatValue ?? productInitial.vatValue);
            setFieldValue(`products[${index}].quantity`, product.quantity ?? productInitial.quantity);
            return;
        }
        const {
            id, name, price, vatValue,
        } = selected;
        setFieldValue(`products[${index}].id`, id);
        setFieldValue(`products[${index}].name`, name);
        setFieldValue(`products[${index}].price`, price);
        setFieldValue(`products[${index}].vatValue`, vatValue);
        setFieldValue(`products[${index}].quantity`, product.quantity);
    }, [product]);

    const getProductFormatOptionLabel = useCallback((
        { value, label, info },
        { selectValue },
    ) => (
        <span className="d-flex flex-column">
            <span
                className="flex-grow-1"
                style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                {label}
            </span>
            <span
                className={classNames(
                    { 'text-muted': selectValue.every((option) => option.value !== value) },
                )}
            >
                {info}
            </span>
        </span>
    ), []);

    const productNameValue = useMemo(() => (
        {
            value: product.id,
            label: product.name,
        }
    ), [
        product,
    ]);

    const onProductLoadOption = useCallback((query) => {
        const result = (
            products.filter(((product) => product.name.toLowerCase().includes(query.toLowerCase()))).map((product) => ({
                ...product,
                value: product.id,
                label: product.name,
                info: `${formatPrice.toEuroWithComma({ amount: product.price })} · ${t('add.products.vat.label', { vat: product.vatValue })}`,
            })));
        return Promise.resolve(result);
    }, [products]);

    return (
        <>
            <Form.Label htmlFor={`name-${index}`} className="font-size-15">
                {t('add.products.name.label')}
            </Form.Label>
            <AsyncCreatableSelect
                id={`name-${index}`}
                isSearchable
                name={`products[${index}].name`}
                styles={styles}
                theme={isInvalid(`products[${index}].name`, errors, touched) ? dangerTheme : theme}
                value={productNameValue}
                onCreateOption={onProductCreateOption}
                onChange={onProductChange}
                formatOptionLabel={getProductFormatOptionLabel}
                loadOptions={onProductLoadOption}
                cacheOptions
                defaultOptions
                formatCreateLabel={(inputValue) => t('add.products.create.label', { name: inputValue })}
                components={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Input: (props) => (
                        <components.Input {...props} maxLength={MAX_PRODUCT_NAME} />
                    ),
                    MenuList: WindowedMenuList,
                }}
            />
            <Form.Control.Feedback
                type="invalid"
                className={classNames({
                    'd-flex': isInvalid(`products[${index}].name`, errors, touched),
                })}
            >
                {getError(`products[${index}].name`, errors)}
            </Form.Control.Feedback>
        </>
    );
}

export default ProductsSelect;
