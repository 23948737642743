import { MARKETING_ROOT_PAGE, PROMOTIONS_ROOT_PAGE } from 'const/CLIENT_URL';
import Messages from './messages';
import Promotions from './promotions';

const routes = [
    {
        component: Messages,
        exact: false,
        path: MARKETING_ROOT_PAGE,
    },
    {
        component: Promotions,
        exact: false,
        path: PROMOTIONS_ROOT_PAGE,
    },
];

export default routes;
