import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { DOTS, usePagination } from 'hooks/usePagination';
import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';
import { Button } from '../Button';
import { Row } from '../Row';
import { Column } from '../Column';
import { IconButton } from '../IconButton';

const T_PREFIX = 'list.footer';

const Pagination = (props) => {
    const {
        page,
        showAll,
        pagesCount,
        itemsCount,
        itemsPerPage,
        onShowAll,
        onPrevPage,
        onNextPage,
        onShowPages,
        onChangePage,
        paginatorOnSeparateLine,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.TABLE);

    const showPrev = page > 1;
    const showNext = page < pagesCount;

    const showingMessage = useMemo(() => {
        const from = itemsCount && itemsPerPage && (
            page > 0
                ? (page - 1) * itemsPerPage + 1
                : 1
        );

        const to = (showAll || page * itemsPerPage > itemsCount)
            ? itemsCount
            : page * itemsPerPage;

        return t(`${T_PREFIX}.showing`, {
            from,
            to,
            count: itemsCount,
        });
    }, [
        t,
        page,
        itemsCount,
        itemsPerPage,
    ]);

    const pagination = usePagination({
        pagesCount,
        siblingCount: 1,
        currentPage: page,
    });

    return paginatorOnSeparateLine
        ? (
            <Column padding={16} gap={0}>
                <Row spaceBetween stretched padding={8}>
                    <Row gap={8}>
                        <span data-testid="data-test-pagination-show-label">
                            {t(`${T_PREFIX}.show.label`)}
                            :
                        </span>
                        <Row gap={8}>
                            <Button
                                size="small"
                                color={showAll ? 'gray' : 'yellow'}
                                onClick={onShowPages}
                                uppercase={false}
                                data-testid="data-test-pagination-show-pages"
                            >
                                {itemsPerPage}
                            </Button>
                            <Button
                                size="small"
                                color={showAll ? 'yellow' : 'gray'}
                                onClick={onShowAll}
                                uppercase={false}
                                data-testid="data-test-pagination-show-all"
                            >
                                {t(`${T_PREFIX}.show.all`)}
                            </Button>
                        </Row>
                    </Row>
                    <span data-testid="data-test-pagination-show-label">
                        {showingMessage}
                    </span>
                </Row>

                <Row padding={8}>
                    {!showAll && pagesCount > 1 && (
                        <Row gap={8}>
                            {showPrev && (
                                <IconButton onClick={onPrevPage} color="gray" data-testid="data-test-pagination-prev">
                                    <ChevronLeft size={18} />
                                </IconButton>
                            )}
                            {
                                pagination?.map((p, i) => (
                                    p === DOTS
                                        ? <span key={i}>{p}</span>
                                        : (
                                            <IconButton
                                                key={`${p}${i}`}
                                                color={p === page ? 'yellow' : 'transparent'}
                                                onClick={() => onChangePage({ page: p })}
                                                data-testid={`data-test-pagination-page-${p}`}
                                            >
                                                {p}
                                            </IconButton>
                                        )
                                ))
                            }
                            {showNext && (
                                <IconButton onClick={onNextPage} color="gray" data-testid="data-test-pagination-next">
                                    <ChevronRight size={18} />
                                </IconButton>
                            )}
                        </Row>
                    )}
                </Row>
            </Column>
        )
        : (
            <Row stretched spaceBetween gap={8}>
                <Row gap={8}>
                    <span data-testid="data-test-pagination-show-label">
                        {t(`${T_PREFIX}.show.label`)}
                        :
                    </span>
                    <Row gap={8}>
                        <Button
                            size="small"
                            color={showAll ? 'gray' : 'yellow'}
                            onClick={onShowPages}
                            uppercase={false}
                            data-testid="data-test-pagination-show-pages"
                        >
                            {itemsPerPage}
                        </Button>
                        <Button
                            size="small"
                            color={showAll ? 'yellow' : 'gray'}
                            onClick={onShowAll}
                            uppercase={false}
                            data-testid="data-test-pagination-show-all"
                        >
                            {t(`${T_PREFIX}.show.all`)}
                        </Button>
                    </Row>
                </Row>
                <span>
                    {showingMessage}
                </span>
                {!showAll && pagesCount > 1 && (
                    <Row gap={8}>
                        {showPrev && (
                            <IconButton onClick={onPrevPage} color="gray" data-testid="data-test-pagination-prev">
                                <ChevronLeft size={18} />
                            </IconButton>
                        )}
                        {
                            pagination?.map((p, i) => (
                                p === DOTS
                                    ? <span key={i}>{p}</span>
                                    : (
                                        <IconButton
                                            key={`${p}${i}`}
                                            color={p === page ? 'yellow' : 'transparent'}
                                            onClick={() => onChangePage({ page: p })}
                                            data-testid={`data-test-pagination-page-${p}`}
                                        >
                                            {p}
                                        </IconButton>
                                    )
                            ))
                        }
                        {showNext && (
                            <IconButton onClick={onNextPage} color="gray" data-testid="data-test-pagination-next">
                                <ChevronRight size={18} />
                            </IconButton>
                        )}
                    </Row>
                )}
            </Row>
        );
};

Pagination.propTypes = {
    page: PropTypes.number,
    showAll: PropTypes.bool,
    pagesCount: PropTypes.number,
    itemsCount: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onShowAll: PropTypes.func,
    onPrevPage: PropTypes.func,
    onNextPage: PropTypes.func,
    onLastPage: PropTypes.func,
    onFirstPage: PropTypes.func,
    onShowPages: PropTypes.func,
    onChangePage: PropTypes.func,
    paginatorOnSeparateLine: PropTypes.bool,
};

Pagination.defaultProps = {
    page: 1,
    showAll: false,
    pagesCount: 0,
    itemsCount: 0,
    itemsPerPage: 0,
    onShowAll: emptyFunc,
    onPrevPage: emptyFunc,
    onNextPage: emptyFunc,
    onLastPage: emptyFunc,
    onFirstPage: emptyFunc,
    onShowPages: emptyFunc,
    onChangePage: emptyFunc,
    paginatorOnSeparateLine: false,
};

export default Pagination;
