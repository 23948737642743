import React from 'react';

const Up = ({
    fill = 'none', stroke = '#B3BDC8', strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} width={width} height={height} viewBox="0 0 9 6" fill={fill}>
        <path d="M1 1.3335L4.5 4.66683L8 1.3335" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default Up;
