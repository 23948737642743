import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Close, Search2 } from '../../Icon/Icon';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './SearchInput2.module.scss';
import { IconButton } from '../IconButton';

function SearchInput(props) {
    const {
        searchValue,
        onSearch,
        placeholder,
        stretched,
    } = props;

    const [input, setInput] = useState(searchValue);
    const { t } = useTranslation();

    useEffect(() => {
        setInput(() => searchValue);
    }, [searchValue]);

    const handleSearch = useCallback((e) => {
        e.preventDefault();
        onSearch(input);
    }, [onSearch, input]);

    const handleClear = useCallback((e) => {
        e.preventDefault();
        setInput('');
        onSearch('');
    }, [onSearch, setInput]);

    const onInputChange = useCallback((e) => setInput(e.target.value), [setInput]);

    return (
        <Form
            className={classNames(
                stretched && 'flex-grow-1 flex-sm-grow-1 flex-md-grow-1 w-100',
            )}
            onSubmit={handleSearch}
        >
            <div className="w-100 h-100 position-relative">
                <div className={styles.searchBtn}>
                    <IconButton
                        color="transparent"
                        type="submit"
                        size={36}
                        onClick={handleSearch}
                    >
                        <Search2 />
                    </IconButton>
                </div>
                <Form.Control
                    className={classNames(
                        'pl-lg-5',
                        styles.searchInput,
                    )}
                    type="text"
                    placeholder={placeholder ?? t('placeholders.search')}
                    value={input}
                    onChange={onInputChange}
                />
                {input && (
                    <div className={styles.clear}>
                        <IconButton
                            color="transparent"
                            size={24}
                            onClick={handleClear}
                        >
                            <Close height="16px" width="16px" />
                        </IconButton>
                    </div>
                )}
            </div>
        </Form>
    );
}

SearchInput.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    stretched: PropTypes.bool,
};

SearchInput.defaultProps = {
    searchValue: '',
    onSearch: emptyFunc,
    stretched: false,
};

export default SearchInput;
