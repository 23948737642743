import React from 'react';

import { Newspaper, Box, Discount } from 'components/Icon/Icon';

const BookingPaymentType = ({ className, width, type }) => {
    let Icon = null;

    switch (type) {
    case 'bundle':
        Icon = Box;
        break;
    case 'subscription':
        Icon = Newspaper;
        break;
    case 'discount':
        Icon = Discount;
        break;
    }

    return Icon && <Icon className={className} width={width} height={width} />;
};

export default BookingPaymentType;
