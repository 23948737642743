import React from 'react';
import PropTypes from 'prop-types';

const InvoiceCredit = (props) => {
    const { className } = props;

    return (
        <svg
            width="24"
            height="24"
            fill="none"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.99951 7C1.99951 5.34315 3.34266 4 4.99951 4H18.9995C20.6564 4 21.9995 5.34315 21.9995 7V8H1.99951V7ZM1.99951 10H21.9995V11.4984C21.2298 11.1773 20.3851 11 19.499 11C15.9092 11 12.999 13.9101 12.999 17.5C12.999 18.3859 13.1763 19.2304 13.4972 20H4.99951C3.34266 20 1.99951 18.6569 1.99951 17V10ZM5.99951 15C5.44723 15 4.99951 15.4477 4.99951 16C4.99951 16.5523 5.44723 17 5.99951 17H10.9995C11.5518 17 11.9995 16.5523 11.9995 16C11.9995 15.4477 11.5518 15 10.9995 15H5.99951ZM14.999 17.5C14.999 15 16.999 13 19.499 13C21.999 13 23.999 15 23.999 17.5C23.999 20 21.999 22 19.499 22C16.999 22 14.999 20 14.999 17.5ZM22.499 17.5C22.499 15.84 21.159 14.5 19.499 14.5C18.939 14.5 18.419 14.65 17.999 14.92L22.079 19C22.349 18.58 22.499 18.06 22.499 17.5ZM20.999 20.08L16.919 16C16.649 16.42 16.499 16.94 16.499 17.5C16.499 19.16 17.839 20.5 19.499 20.5C20.059 20.5 20.579 20.35 20.999 20.08Z"
                fill="currentColor"
            />
        </svg>
    );
};

InvoiceCredit.propTypes = {
    className: PropTypes.string,
};

InvoiceCredit.defaultProps = {
    className: '',
};

export default InvoiceCredit;
