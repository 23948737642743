/* eslint-disable import/prefer-default-export */
import color from 'color';

export const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#FFC100',
        primary75: color('#FFC100').lighten(0.25).hex(),
        primary50: color('#FFC100').lighten(0.5).hex(),
        primary25: color('#FFC100').lighten(0.75).hex(),
        neutral20: '#D8E1EA',
        neutral30: '#566270',
    },
});
