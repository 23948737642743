import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIsVisible } from 'react-is-visible';

import { useBool } from 'hooks/useBool';

import {
    Sad,
    Smile,
    Speechless,
    PlusBordered,
    MinusIcon,
} from 'components/Icon/Icon';

import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';

import * as styles from './ClientReviewsTableRow.module.scss';

const ClientReviewsTableRow = (props) => {
    const { review } = props;

    const nodeRef = useRef();
    const isVisible = useIsVisible(nodeRef);

    const isToggleVisible = useBool(false);
    const showComment = useBool(false);

    useEffect(() => {
        if (!isVisible) {
            isToggleVisible.onTrue();
        } else if (isVisible && !showComment.value) {
            isToggleVisible.onFalse();
        }
    }, [
        isVisible,
        isToggleVisible.onTrue,
        showComment.value,
        isToggleVisible.onFalse,
    ]);

    const commentClass = {
        [styles.hide]: !showComment.value,
    };

    const REVIEW_VALUES = {
        SMILE: 'smile',
        SPEECHLESS: 'sppechless',
        SAD: 'sad',
    };

    const REVIEW_SMILES = {
        smile: <Smile
            width={32}
            height={32}
            className={styles.greenField}
        />,
        speechless: <Speechless
            width={32}
            height={32}
            className={styles.yellowField}
        />,
        sad: <Sad
            width={32}
            height={32}
            className={styles.redField}
        />,
    };

    return (
        <>
            <ListTableBodyCell className={styles.alignTop}>
                {isToggleVisible.value
                    ? (
                        <button
                            type="button"
                            className={styles.iconButton}
                            onClick={showComment.onToggle}
                        >
                            {showComment.value ? (
                                <MinusIcon size={18} />
                            ) : (
                                <PlusBordered size={18} />
                            )}
                        </button>
                    ) : null}
                {review.shop.name}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={
                    classNames(
                        styles.grayField,
                        styles.alignTop,
                    )
                }
            >
                {review.services}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={
                    classNames(
                        styles.grayField,
                        styles.alignTop,
                    )
                }
            >
                {review.product}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={
                    classNames(
                        styles.grayField,
                        styles.alignTop,
                    )
                }
            >
                {review.date}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={
                    classNames(
                        styles.grayField,
                        styles.alignTop,
                    )
                }
            >
                {review.duration}
            </ListTableBodyCell>
            <ListTableBodyCell className={styles.alignTop}>
                {REVIEW_SMILES[review?.rated] || REVIEW_SMILES[REVIEW_VALUES.SMILE]}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={
                    classNames(
                        'commentCell',
                        styles.grayField,
                        styles.comment,
                    )
                }
            >
                <div
                    className={
                        classNames(
                            styles.comment,
                            commentClass,
                        )
                    }
                >
                    {review.comment}
                    <div ref={nodeRef} />
                </div>
            </ListTableBodyCell>
        </>
    );
};

ClientReviewsTableRow.propTypes = {
    review: PropTypes.object,
    showDiscounts: PropTypes.bool,
    onToggleShowDiscounts: PropTypes.func,
};

ClientReviewsTableRow.defaultProps = {
    review: {
        shop: {
            name: 'Shop 1',
        },
        services: 'Service 1',
        product: 'Product',
        date: '21 Jun 2019 14:00',
        duration: '3 hours',
        rated: 'smile',
        comment: 'Euismod volutpat, bibendum et dignissim nunc. In ut ut fermentum lacinia cursus enim. Iaculis diam vitae tortor, ut ut aliquet venenatis eu a. Pellentesque mauris ut elementum phasellus aliquam sed dignissim turpis. Urna sit massa morbi cras ut egestas dictum massa. ',
    },
    showDiscounts: false,
    onToggleShowDiscounts: emptyFunc,
};

export default ClientReviewsTableRow;
