export const downloadFile = ({ url }) => {
    const a = document.createElement('a');
    a.href = url;
    a.style.display = 'none';

    const download = url.split('/').pop();

    a.setAttribute('download', download);
    a.setAttribute('target', '_blank');

    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 10);
};
