/* eslint-disable import/prefer-default-export */
import { getIn } from 'formik';

export const instantFormValidation = ({ touched, setFieldTouched }) => (handler) => (e) => {
    if (!e?.target?.name || !touched || !setFieldTouched) {
        return;
    }
    const name = e?.target?.name;
    if (!getIn(touched, name)) {
        setFieldTouched(name, true, true);
    }
    handler(e);
};
