import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Row from 'react-bootstrap/Row';
import { newSelectStyles, theme } from 'styles/select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as styles from './SubscriptionsSoldStatusFilter.module.scss';
import {
    SOLD_SUBSCRIPTIONS_STATUS_FILTER_OPTIONS,
} from '../../../const/subscriptions/SOLD_SUBSCRIPTIONS_STATUS_FILTER_OPTIONS';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const animatedComponents = makeAnimated();

const T_PREFIX = 'sold.filters.status';

const SubscriptionsSoldStatusFilter = ({ onChange }) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    const options = useMemo(() => Object.values(SOLD_SUBSCRIPTIONS_STATUS_FILTER_OPTIONS).map((v) => ({
        value: v,
        label: t(`${T_PREFIX}.values.${v}`),
    })), [t]);
    const handleChange = useCallback((selected) => {
        onChange(selected?.value);
    }, [onChange]);

    return (
        <Row className={styles.filtersContainer}>
            <Select
                name="soldSubscriptionStatuses"
                isSearchable={false}
                theme={theme}
                styles={newSelectStyles}
                isClearable
                className={styles.filter}
                components={animatedComponents}
                onChange={handleChange}
                placeholder={t(`${T_PREFIX}.placeholder`)}
                options={options}
            />
        </Row>
    );
};

SubscriptionsSoldStatusFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
};

SubscriptionsSoldStatusFilter.defaultProps = {
};

export default SubscriptionsSoldStatusFilter;
