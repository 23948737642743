import React from 'react';

const Question = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '100%', height = '100%', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <circle cx="12" cy="12" fill="none" r="11" stroke={fill} />
            <circle cx="12" cy="18" r="1" stroke="none" />
            <path d="M9.853,6.56c1.98-.875,4.542-.771,5.417.645s.271,3.063-1.229,4.334S12,13.5,12,14.5" fill="none" />
        </g>
    </svg>
);

export default Question;
