import React from 'react';

const Change = (props) => {
    const { width = '24', height = '24' } = props;

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7929 3.29289C16.1834 2.90237 16.8166 2.90237 17.2071 3.29289L20.7071 6.79289C21.0976 7.18342 21.0976 7.81658 20.7071 8.20711L17.2071 11.7071C16.8166 12.0976 16.1834 12.0976 15.7929 11.7071C15.4024 11.3166 15.4024 10.6834 15.7929 10.2929L17.5858 8.5H7C6.44772 8.5 6 8.05228 6 7.5C6 6.94772 6.44772 6.5 7 6.5H17.5858L15.7929 4.70711C15.4024 4.31658 15.4024 3.68342 15.7929 3.29289ZM8.20711 12.2929C8.59763 12.6834 8.59763 13.3166 8.20711 13.7071L6.41421 15.5H16C16.5523 15.5 17 15.9477 17 16.5C17 17.0523 16.5523 17.5 16 17.5H6.41421L8.20711 19.2929C8.59763 19.6834 8.59763 20.3166 8.20711 20.7071C7.81658 21.0976 7.18342 21.0976 6.79289 20.7071L3.29289 17.2071C2.90237 16.8166 2.90237 16.1834 3.29289 15.7929L6.79289 12.2929C7.18342 11.9024 7.81658 11.9024 8.20711 12.2929Z" fill="currentColor" />
        </svg>
    );
};

export default Change;
