/* eslint-disable import/prefer-default-export */
import color from 'color';

export const newSelectStyles = {
    control: (provided, { isFocused }) => ({
        ...provided,
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderColor: `${provided.borderColor} !important`,
        borderRadius: 4,
        boxShadow: isFocused && `0 0 0 0.2rem ${color(provided.borderColor).fade(0.5).string()}`,
    }),
    indicatorSeparator: () => null,
    indicatorsContainer: (provided) => {
        const copy = {
            ...provided,
            alignItems: 'center',
        };
        return copy;
    },
    dropdownIndicator: (provided, state) => {
        const copy = {
            ...provided,
        };
        if (state.selectProps.menuIsOpen) {
            copy.transform = 'rotate(180deg)';
            copy.transition = 'all 150ms';
        }
        return copy;
    },
    multiValue: (provided, state) => {
        const { data } = state;
        const { isFixed } = data;
        const copy = {
            ...provided,
            backgroundColor: '#FFFFFF',
            border: '1px solid #D8E1EA',
            borderRadius: 4,
        };
        if (isFixed) {
            copy.paddingRight = '6px';
        }
        return copy;
    },
    multiValueRemove: (provided, state) => {
        const { data } = state;
        const { isFixed } = data;
        const copy = { ...provided };
        if (isFixed) {
            copy.display = 'none';
        }
        return copy;
    },
};
