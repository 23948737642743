import React, { useCallback } from 'react';

// Hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Components
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

// Store
import * as actions from 'store/actions';

// Utils
import moment from 'moment';
import classNames from 'classnames';
import _, { uniq } from 'lodash';
import { permissionsEventSelector, permissionsResourceSelector } from 'store/selectors/permissions';
import { Button } from 'components/Common/Button';
import { BookingPaymentType, Event, Feedback } from '..';
import { getFullName } from '../../helpers/services/getFullName';
import { CLIENTS_LIST_ID_PAGE, MESSAGES_ADD_PAGE } from '../../const/CLIENT_URL';

const EventDetailsModal = (
    {
        event,
        clickedEventID,
        setClickedEventID,
        showBookingModal,
        clickedBookingID,
        cancelModalVisible,
        setClickedBookingID,
        setCancelModalVisible,
    },
) => {
    const bookings = useSelector((state) => state.events.bookings);
    const bookingSeatsSum = bookings?.reduce((acc, { seats }) => acc + seats, 0);
    const {
        banCancel,
        banBooking,
        banSendNotification,
    } = useSelector(permissionsEventSelector);
    const { banEdit } = useSelector(permissionsResourceSelector);

    const dispatch = useDispatch();
    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        if (!event) return;

        dispatch(actions.getEventBookings({ id: event.resourceId }));
    }, [event]);

    const handleRedirectClient = useCallback(({ clientId }) => () => {
        if (!clientId) {
            return;
        }
        history.push(CLIENTS_LIST_ID_PAGE({ clientId }));
    }, [history.push]);

    return (
        <Modal
            centered
            size="lg"
            show={clickedEventID && !showBookingModal && !clickedBookingID && !cancelModalVisible}
            onHide={() => setClickedEventID(null)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="mb-0 font-weight-600">
                    {t('eventsRoute.eventDetails')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h3 className="font-weight-normal pb-3">{event && moment(event.time.from).format('D MMM YYYY')}</h3>

                <Event
                    event={event}
                    showParticipants={false}
                />

                <div className="d-flex align-items-center mt-4">
                    <h4 className="mb-0 font-size-20 font-weight-bold">
                        {t('eventsRoute.seats')}
                    </h4>
                    <span className="ml-2 font-weight-bold font-size-16 text-muted">
                        {event && `${bookingSeatsSum ?? 0}/${event.totalPlaces}`}
                    </span>
                </div>

                {bookings?.map(({
                    id,
                    client,
                    feedback,
                    payment: {
                        paid,
                        isInProgress,
                        usedBundle,
                        finalCost,
                        type,
                    },
                    notPresent,
                    seats,
                    time: {
                        from,
                    },
                }, index) => (
                    <div
                        key={`participant-${index}`}
                        className={classNames({
                            'border-danger': !usedBundle && !isInProgress && !paid && finalCost !== 0,
                            'border-success': usedBundle || (!isInProgress && paid) || finalCost === 0,
                            'border-info': !usedBundle && isInProgress,
                            'border-dark': notPresent,
                        }, 'd-flex flex-sm-row flex-column justify-content-between mt-3 pl-3 border-left border-5')}
                    >
                        <div
                            className="d-flex align-items-center justify-content-between justify-content-sm-start"
                        >
                            <button
                                type="button"
                                className="p-0 mr-3"
                                onClick={handleRedirectClient({ clientId: client.id })}
                            >
                                {getFullName({ client })}
                            </button>
                            <div className="d-flex">
                                {type && (
                                    <BookingPaymentType
                                        className="text-muted mr-3"
                                        width={24}
                                        type={type}
                                    />
                                )}
                                {feedback && (
                                    <Feedback
                                        feedback={feedback}
                                        size={24}
                                        classNames="mr-0 mr-sm-3"
                                    />
                                )}
                            </div>
                            <span
                                className="d-none d-sm-inline text-muted text-uppercase font-size-12 cursor-pointer"
                                onClick={() => setClickedBookingID({ id, timestamp: from })}
                            >
                                <strong>{t('groupSchedule.showInfo')}</strong>
                            </span>
                        </div>
                        <div>
                            <span className="text-muted mr-3">{t('groupDetailsModal.seat', { count: seats ?? 1 })}</span>
                            <span>{client?.contactNumber}</span>
                        </div>
                        <div className="d-block d-sm-none">
                            <span
                                className="text-muted text-uppercase font-size-12 cursor-pointer"
                                onClick={() => setClickedBookingID({ id, timestamp: from })}
                            >
                                <strong>{t('groupSchedule.showInfo')}</strong>
                            </span>
                        </div>
                    </div>
                ))}

                {!bookings && _.times(event?.participants?.length, (i) => (
                    <div
                        key={`booking-${i}`}
                        className="d-flex justify-content-between mt-3 px-3 border-left border-5"
                    >
                        <div>
                            <span className="mr-3">
                                <Skeleton width={125 + i * 3 * (i % 2 === 0 ? 1 : -1)} />
                            </span>
                        </div>

                        <div>
                            <span className="mr-3">
                                <Skeleton width={75} />
                                <span><Skeleton width={100} /></span>
                            </span>
                        </div>
                    </div>
                ))}

                <div className={`d-flex justify-content-${event?.isCancelled ? 'end' : 'between'} mt-3`}>
                    {!event?.isCancelled && !banBooking && (
                        <Button
                            color="outline"
                            size="small"
                            onClick={() => {
                                if (banBooking) return;

                                dispatch(actions.setAddBookingDetails(moment(event.time.from), {
                                    id: event.resourceId,
                                    name: event.name,
                                    type: 'event',
                                    eventId: event.id,
                                }, {
                                    id: event.service.id,
                                    name: event.service.name,
                                }));
                            }}
                            disabled={(bookingSeatsSum === event?.totalPlaces) || banBooking}
                        >
                            {t('agendaRoute.addBooking')}
                        </Button>
                    )}
                    {!banSendNotification && (
                        <Button
                            disabled={banSendNotification || !bookingSeatsSum}
                            id="sendMessage"
                            size="small"
                            onClick={() => {
                                const selectedClientsIds = uniq(bookings?.map(({ client }) => client.id));
                                history.push(MESSAGES_ADD_PAGE, { selectedClientsIds });
                            }}
                        >
                            {t('groupDetailsModal.sendMessage')}
                        </Button>
                    )}
                </div>
                {!banCancel && (
                    <div className="d-flex justify-content-end mt-4">
                        <Button
                            color={event?.isCancelled ? 'green' : 'red'}
                            size="small"
                            onClick={() => {
                                if (banCancel) return;

                                setCancelModalVisible(true);
                            }}
                            disabled={banCancel}
                        >
                            {t(`eventsRoute.${event?.isCancelled ? 'reactivate' : 'cancel'}`)}
                            {' '}
                            {t('eventsRoute.event')}
                        </Button>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    data-testid="close-event-modal"
                    color="outline"
                    onClick={() => setClickedEventID(null)}
                >
                    {t('eventsRoute.close')}
                </Button>
                {!banEdit && (
                    <div className="mx-n2">
                        <Button
                            className="mx-2"
                            href={`/agenda/events/edit/${event?.resourceId}`}
                        >
                            {t('groupDetailsModal.edit')}
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default EventDetailsModal;
