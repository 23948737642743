import { createSelector } from 'reselect';

export const servicesSelector = (state) => state.services;

export const servicesListSelector = createSelector(
    servicesSelector,
    (services) => services.services,
);

export const companyServicesSelector = createSelector(
    servicesSelector,
    (services) => services.companyServices,
);

export const serviceItemSelector = createSelector(
    servicesSelector,
    (services) => services.service,
);

export const endServicesSelector = createSelector(
    companyServicesSelector,
    (companyServices) => companyServices.items.flatMap(
        (companyService) => (
            companyService?.subOptions?.length
                ? companyService?.subOptions
                : companyService
        ),
    ),
);
