import React from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Styles

// Utils
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner';
import * as styles from './GroupsTimeline.module.scss';
import GroupBooking from '../GroupBooking/GroupBooking';
import { useMobile } from '../../hooks/useMobile';

const GroupsTimeline = ({
    globalDate, className, week, year, onGroupSessionClick,
}) => {
    const { locale } = useSelector((state) => state.locales);

    const groups = useSelector((state) => state.groups.sessions && state.groups.filters && state.groups.sessions
        .filter((product) => !!state.groups.filters[product.product?.id] || !product.product?.id)
        .sort((left, right) => (moment(left.time.from).isBefore(right.time.from) ? -1 : 1))
        .reduce((sessions, session) => ({
            ...sessions,
            [moment(session.time.from).format('dddd')]: [
                ...(sessions[moment(session.time.from).format('dddd')] || []),
                session,
            ],
        }), {}));

    const sessionsLoading = useSelector((state) => state.groups.sessionsLoading);

    const isMobile = useMobile();

    if (sessionsLoading) {
        return (
            <div className="w-100 h-100 d-flex justify-content-center pt-5">
                <TailSpin
                    color="black"
                    width={75}
                    height={75}
                />
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.container, className, 'd-flex flex-column flex-lg-row')}
        >
            {_.times(7, (i) => {
                if (isMobile && (!groups || (groups[globalDate.isoWeekday(i + 1).format('dddd')] || []).length === 0)) {
                    return null;
                }

                const selected = moment().isSame(globalDate.clone().isoWeekday(i + 1), 'day');

                return (
                    <div
                        key={`weekday-${i}`}
                        className={classNames(
                            styles.column,
                            selected && styles.selected,
                            'd-flex flex-column',
                        )}
                    >
                        <div
                            className={classNames(
                                styles.weekday,
                                selected && styles.selected,
                                'd-flex align-items-center px-2',
                            )}
                            style={{ gridColumn: i + 1 }}
                        >
                            <div className="flex-grow-1 d-flex flex-column">
                                <span className="text-center text-muted text-uppercase font-size-12 font-weight-normal">
                                    {globalDate.clone().locale(locale).isoWeekday(i + 1).format('ddd')}
                                </span>
                                <span className="text-center font-size-14 mt-n1">
                                    {globalDate.isoWeekday(i + 1).format('D.MM')}
                                </span>
                            </div>
                        </div>

                        {groups && (groups[globalDate.isoWeekday(i + 1).format('dddd')] || []).map((booking) => (
                            <GroupBooking
                                key={`booking-${booking.id}`}
                                booking={booking}
                                week={week}
                                year={year}
                                onClick={onGroupSessionClick}
                            />
                        ))}

                        {!groups && _.times(Math.round(1 + (i * 4.65) % 2.74), (j) => (
                            <GroupBooking key={`booking-${j}`} />
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default GroupsTimeline;
