/* eslint-disable import/prefer-default-export */
import * as CLIENTS_TYPES from '../../../actions/clients/tables/subscriptions/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
};

export const clientSubscriptionsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_SET): {
        const {
            subscriptions,
            itemsCount,
            pagesCount,
        } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: subscriptions,
            page,
            itemsCount,
            pagesCount,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_LOADING_SET): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }
    default:
        return state;
    }
};
