import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { CLIENT_PROP } from 'const/clients/CLIENT_PROP';
import * as CLIENTS_SELECTORS from 'store/selectors/clients';
import * as CLIENTS_ACTIONS from 'store/actions/clients/tables/notes';
import moment from 'moment';
import { Button } from 'components/Common/Button';
import { debounce } from 'lodash';
import { useDidUpdateEffect } from 'components/hooks';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';
import { getError, isInvalid } from '../../../../../../services/validationService';

import * as styles from './ClientNoteFormDialog.module.scss';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';
import CategorySelect, { categoryInitialData } from '../CategorySelect/CategorySelect';
import { ClientNoteAuthor } from '../ClientNoteAuthor';

const T_PREFIX = 'notes';

const emptyValues = {
    text: '',
    category: categoryInitialData(),
};

const ClientNoteFormDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const editMode = !!props.note;
    const { item: { id: clientId } } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const { persistCreateItem } = useSelector(CLIENTS_SELECTORS.clientNotesSelector);

    const initialValues = useMemo(() => {
        if (!editMode) {
            return persistCreateItem[clientId] || emptyValues;
        }

        return {
            text: props.note?.text || emptyValues.text,
            category: props.note?.clientNoteCategory || emptyValues.category,
        };
    }, []);

    const dispatch = useDispatch();

    const validationSchema = useMemo(() => yup.object({
        text: yup.string()
            .trim()
            .max(
                CLIENT_PROP.NOTE.MAX_LENGTH,
                tc('validationErrors.maxLength', {
                    name: t('notes.note.label'),
                    length: CLIENT_PROP.NOTE.MAX_LENGTH,
                }),
            ),
        category: yup.object({
            name: yup.string()
                .trim()
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: t('notes.category.label'),
                })),
        }),
    }), [t]);

    const onSubmit = useCallback(async ({ text, category, manually }) => {
        let categoryId = String(category.id);
        if (categoryId.startsWith('new-')) {
            const formData = new FormData();
            formData.append('name', category.name.trim());
            const res = await dispatch(CLIENTS_ACTIONS.addClientNotesCategory({ category: formData }));
            if (res.code === 200) {
                categoryId = res?.result?.id;
            }
        }
        if (!categoryId) {
            return;
        }
        if (editMode) {
            const formData = new FormData();
            formData.append('category', categoryId);
            formData.append('text', text.trim());
            const res = await dispatch(CLIENTS_ACTIONS.editClientNotesItem({ note: formData, noteId: props.note.id }));
            if (res.code === 200 && manually) {
                props.onClose?.();
            }
        }
        if (!editMode) {
            const formData = new FormData();
            formData.append('category', categoryId);
            formData.append('text', text.trim());
            const res = await dispatch(CLIENTS_ACTIONS.addClientNotesItem({ note: formData }));
            if (res.code === 200) {
                props.onClose?.();
                dispatch(CLIENTS_ACTIONS.setClientNotePersistCreateItem({ category: categoryInitialData(), text: '', clientId }));
            }
        }
    }, [dispatch, history, props.note]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const {
        values,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
    } = formik;

    const debouncedHandleSubmit = useCallback(debounce(handleSubmit, 5000, { leading: false, trailing: true }), [handleSubmit]);

    useEffect(() => {
        if (!editMode) {
            dispatch(CLIENTS_ACTIONS.setClientNotePersistCreateItem({ category: values?.category, text: values?.text, clientId }));
        }
    }, [values?.category, values?.text, clientId]);

    useEffect(() => () => {
        debouncedHandleSubmit.flush();
    }, []);

    useDidUpdateEffect(() => {
        if (editMode) {
            debouncedHandleSubmit();
        }
    }, [values?.category, values?.text]);

    const handleButtonSubmit = useCallback(() => {
        if (editMode) {
            debouncedHandleSubmit.cancel();
            setFieldValue('manually', true);
        }
        handleSubmit();
    }, [editMode, handleSubmit]);

    return (
        <Modal
            show={props.visible}
            centered
            restoreFocus={false}
            onHide={props.onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {editMode ? t(`${T_PREFIX}.edit.label`) : t(`${T_PREFIX}.add.label`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex' }}>
                <Form className={styles.container}>
                    <div className={styles.content}>
                        {editMode && (
                            <ClientNoteAuthor
                                avatarUrl={props.note.author.avatarUrl}
                                name={props.note.author.name}
                                date={moment.unix(props.note.createdAt).format('DD.MM.YYYY HH:mm')}
                            />
                        )}
                        <CategorySelect
                            categories={props.categories}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            category={values?.category}
                        />
                        <Form.Group>
                            <Form.Control
                                id="text"
                                as="textarea"
                                rows={8}
                                name="text"
                                value={values?.text}
                                onChange={handleChange}
                                maxLength={CLIENT_PROP.NOTE.MAX_LENGTH}
                                placeholder={t('notes.add.label')}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-flex': isInvalid('text', errors, touched),
                                })}
                            >
                                {getError('text', errors)}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className={styles.actions}>
                        {!editMode ? (
                            <Button
                                color="outline"
                                onClick={props.onClose}
                            >
                                {tc('placeholders.cancel')}
                            </Button>
                        ) : <div />}
                        <Button
                            color="yellow"
                            onClick={handleButtonSubmit}
                            loading={props.saving}
                            disabled={!values?.text || !values?.category?.name}
                        >
                            {editMode ? t(`${T_PREFIX}.edit.action.label`) : t(`${T_PREFIX}.add.action.label`)}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

ClientNoteFormDialog.propTypes = {
    visible: PropTypes.bool,
    note: PropTypes.object,
    categories: PropTypes.array,
    onClose: PropTypes.func,
    saving: PropTypes.bool,
};

ClientNoteFormDialog.defaultProps = {
    visible: false,
    categories: [],
    onClose: emptyFunc,
    note: null,
};

export default ClientNoteFormDialog;
