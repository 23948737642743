import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';

import * as BUNDLES_TYPES from '../../actions/bundles/actionTypes';

import * as BUNDLES_ACTIONS from '../../actions/bundles';
import * as TOAST_ACTIONS from '../../actions/toast';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { API_ADMIN_BUNDLE_GET_ID_ROUTE } from '../../../const/API_URL';
import { CHAR_SYMBOL } from '../../../const/string/CHAR_SYMBOL';

function* getBundleItem(action) {
    const { payload } = action;
    const { bundleId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(BUNDLES_ACTIONS.setBundleItemLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: 'GET',
            url: API_ADMIN_BUNDLE_GET_ID_ROUTE({ bundleId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === 200) {
            const item = camelize(result);
            const bundle = {
                id: item.id,
                sold: item.sold,
                name: item.name,
                maxUsages: {
                    value: item.maxUsages === CHAR_SYMBOL.INFINITY ? null : item.maxUsages,
                    unlimited: item.maxUsages === CHAR_SYMBOL.INFINITY,
                },
                validity: {
                    ...item.validity,
                    value: item.validity.value,
                    unit: item.validity.unit,
                    unlimited: !item.validity.value && !item.validity.unit,
                },
                price: item.price,
                vatRate: item.vatRate,
                status: item.status,
                isTrial: item.isTrial,
                services: item.services.map((s) => ({
                    id: s.id,
                    name: s.name,
                })),
                description: item.description || '',
            };
            yield put(BUNDLES_ACTIONS.setBundleItem({ bundle }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'bundleItem.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(BUNDLES_ACTIONS.setBundleItemLoading({ loading: false }));
    }
}

export const bundleItemSaga = [
    takeLatest(BUNDLES_TYPES.BUNDLE_ITEM_GET, getBundleItem),
];
