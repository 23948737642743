const PREFIX = '@@CLIENTS/';
const CLIENT_ITEM = `${PREFIX}:CLIENT:ITEM`;

const CLIENT_ITEM_NOTES = `${CLIENT_ITEM}:NOTES`;
const CLIENT_ITEM_NOTES_LOADING = `${CLIENT_ITEM_NOTES}:LOADING`;
export const CLIENT_ITEM_NOTES_LOADING_SET = `${CLIENT_ITEM_NOTES_LOADING}:SET`;
export const CLIENT_ITEM_NOTES_GET = `${CLIENT_ITEM_NOTES}:GET`;
export const CLIENT_ITEM_NOTES_SET = `${CLIENT_ITEM_NOTES}:SET`;
const CLIENT_ITEM_NOTES_PAGE = `${CLIENT_ITEM_NOTES}:PAGE`;
export const CLIENT_ITEM_NOTES_PAGE_SET = `${CLIENT_ITEM_NOTES_PAGE}:SET`;
export const CLIENT_ITEM_NOTES_PAGE_SET_FIRST = `${CLIENT_ITEM_NOTES_PAGE}:FIRST`;
export const CLIENT_ITEM_NOTES_PAGE_SET_LAST = `${CLIENT_ITEM_NOTES_PAGE}:LAST`;
export const CLIENT_ITEM_NOTES_PAGE_SET_PREV = `${CLIENT_ITEM_NOTES_PAGE}:PREV`;
export const CLIENT_ITEM_NOTES_PAGE_SET_NEXT = `${CLIENT_ITEM_NOTES_PAGE}:NEXT`;

const CLIENT_ITEM_NOTES_FILTER = `${CLIENT_ITEM_NOTES}:FILTER`;
export const SET_CLIENT_NOTE_FILTER = `${CLIENT_ITEM_NOTES_FILTER}:SET`;
export const SET_CLIENT_NOTE_FILTER_TRIGGER = `${SET_CLIENT_NOTE_FILTER}.TRIGGER`;
export const CLEAR_CLIENT_NOTE_FILTER = `${CLIENT_ITEM_NOTES_FILTER}:CLEAR`;
export const CLEAR_CLIENT_NOTE_FILTER_TRIGGER = `${CLEAR_CLIENT_NOTE_FILTER}.TRIGGER`;

export const GET_CLIENT_NOTE_CATEGORIES = `${CLIENT_ITEM_NOTES}:GET_CATEGORIES`;
export const GET_CLIENT_NOTE_CATEGORIES_TRIGGER = `${GET_CLIENT_NOTE_CATEGORIES}.TRIGGER`;
export const GET_CLIENT_NOTE_CATEGORIES_RESOLVED = `${GET_CLIENT_NOTE_CATEGORIES}.RESOLVED`;
export const GET_CLIENT_NOTE_CATEGORIES_REJECTED = `${GET_CLIENT_NOTE_CATEGORIES}.REJECTED`;

export const ADD_CLIENT_NOTE_CATEGORY = `${CLIENT_ITEM_NOTES}:ADD_CATEGORY`;
export const ADD_CLIENT_NOTE_CATEGORY_TRIGGER = `${ADD_CLIENT_NOTE_CATEGORY}.TRIGGER`;
export const ADD_CLIENT_NOTE_CATEGORY_RESOLVED = `${ADD_CLIENT_NOTE_CATEGORY}.RESOLVED`;
export const ADD_CLIENT_NOTE_CATEGORY_REJECTED = `${ADD_CLIENT_NOTE_CATEGORY}.REJECTED`;

export const DELETE_CLIENT_NOTE_CATEGORY = `${CLIENT_ITEM_NOTES}:DELETE_CATEGORY`;
export const DELETE_CLIENT_NOTE_CATEGORY_TRIGGER = `${DELETE_CLIENT_NOTE_CATEGORY}.TRIGGER`;
export const DELETE_CLIENT_NOTE_CATEGORY_RESOLVED = `${DELETE_CLIENT_NOTE_CATEGORY}.RESOLVED`;
export const DELETE_CLIENT_NOTE_CATEGORY_REJECTED = `${DELETE_CLIENT_NOTE_CATEGORY}.REJECTED`;

export const ADD_CLIENT_NOTE = `${CLIENT_ITEM_NOTES}:ADD`;
export const ADD_CLIENT_NOTE_TRIGGER = `${ADD_CLIENT_NOTE}.TRIGGER`;
export const ADD_CLIENT_NOTE_RESOLVED = `${ADD_CLIENT_NOTE}.RESOLVED`;
export const ADD_CLIENT_NOTE_REJECTED = `${ADD_CLIENT_NOTE}.REJECTED`;

export const DELETE_CLIENT_NOTE = `${CLIENT_ITEM_NOTES}:DELETE`;
export const DELETE_CLIENT_NOTE_TRIGGER = `${DELETE_CLIENT_NOTE}.TRIGGER`;
export const DELETE_CLIENT_NOTE_RESOLVED = `${DELETE_CLIENT_NOTE}.RESOLVED`;
export const DELETE_CLIENT_NOTE_REJECTED = `${DELETE_CLIENT_NOTE}.REJECTED`;

export const EDIT_CLIENT_NOTE = `${CLIENT_ITEM_NOTES}:EDIT`;
export const EDIT_CLIENT_NOTE_TRIGGER = `${EDIT_CLIENT_NOTE}.TRIGGER`;
export const EDIT_CLIENT_NOTE_RESOLVED = `${EDIT_CLIENT_NOTE}.RESOLVED`;
export const EDIT_CLIENT_NOTE_REJECTED = `${EDIT_CLIENT_NOTE}.REJECTED`;

export const SET_CLIENT_NOTE_PERSIST_CREATE_ITEM = `${CLIENT_ITEM_NOTES}:PERSIST_CREATE_ITEM`;
export const SET_CLIENT_NOTE_PERSIST_CREATE_ITEM_TRIGGER = `${SET_CLIENT_NOTE_PERSIST_CREATE_ITEM}.TRIGGER`;
