import { combineReducers } from 'redux';
import { accessGatesListReducer } from './accessGatesList';
import { accessLogsListReducer } from './accessLogsList';
import { accessGateItemReducer } from './accessGateItem';
import { accessLogsStatisticsReducer } from './accessLogsStatistics';
import { accessProfileItemReducer } from './accessProfileItem';
import { accessProfilesListReducer } from './accessProfilesList';

export const accessReducer = combineReducers({
    gates: accessGatesListReducer,
    logs: accessLogsListReducer,
    gate: accessGateItemReducer,
    logsStatistics: accessLogsStatisticsReducer,
    profile: accessProfileItemReducer,
    profiles: accessProfilesListReducer,
});
