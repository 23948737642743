import React from 'react';

import { Redirect } from 'react-router';
import NavigationService from '../../services/navigationService';

const AllowRouteWrapper = ({
    children, path, sections,
}) => {
    const { isAllowed, firstAccessibleRoute } = NavigationService.checkPath(path, sections);

    if (isAllowed) {
        return children;
    }

    return <Redirect to={firstAccessibleRoute} />;
};

export default AllowRouteWrapper;
