import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalHeader from 'react-bootstrap/ModalHeader';

import * as styles from './DialogHeader.module.scss';

const DialogHeader = (props) => {
    const {
        children,
        closeButton,
        className,
    } = props;

    return (
        <ModalHeader
            closeButton={closeButton}
            className={classNames(
                styles.dialogHeader,
                className,
            )}
            data-testid={props['data-testid']}
        >
            {children}
        </ModalHeader>
    );
};

DialogHeader.propTypes = {
    children: PropTypes.node,
    closeButton: PropTypes.bool,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
};

DialogHeader.defaultProps = {
    children: null,
    closeButton: true,
    className: '',
    'data-testid': 'data-test-dialog-header',
};

export default DialogHeader;
