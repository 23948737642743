import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Common/InfoTooltip';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import * as styles from './NoteFormRow.module.scss';

const T_PREFIX = 'sell.form';
const T_FIELDS = `${T_PREFIX}.fields`;

const NOTE_MAX_LENGTH = 200;

const NoteFormRow = (props) => {
    const {
        values,
        validation,
        onChangeNote,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <Row>
            <Col
                xs={12}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Form.Label htmlFor="note" className="d-flex align-items-center">
                        {t(`${T_FIELDS}.note.label`)}
                        <InfoTooltip
                            text={t(`${T_FIELDS}.note.tooltip`)}
                            placement="bottom"
                        />
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        value={values.note}
                        name="note"
                        className={styles.note}
                        onChange={onChangeNote}
                        maxLength={NOTE_MAX_LENGTH}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.note?.isInvalid,
                        })}
                    >
                        {validation?.note?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

NoteFormRow.propTypes = {
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onChangeNote: PropTypes.func,
};

NoteFormRow.defaultProps = {
    validation: null,
    onChangeNote: emptyFunc,
};

export default NoteFormRow;
