import React from 'react';
import { LayoutContainer, ComingSoon } from 'components';

function Statistics() {
    return (
        <LayoutContainer>
            <ComingSoon />
        </LayoutContainer>
    );
}

export default Statistics;
