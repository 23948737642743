import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from 'react-redux';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

// import { setAccessLogsSortOptions } from '../../../../store/actions';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
// import { ACCESS_LOGS_SORT_BY_TYPE } from '../../../../const/access/ACCESS_LOGS_SORT_BY_TYPE';

const T_PREFIX = 'logs.list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const LogsTableHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    // const { sortOptions } = useSelector((state) => state.messages.messages);
    // const dispatch = useDispatch();

    // const handleCellClick = useCallback((sortBy) => {
    //     if (sortOptions.sortBy === sortBy) {
    //         dispatch(setAccessLogsSortOptions({
    //             sortOptions: {
    //                 orderBy: sortOptions.orderBy * -1,
    //             },
    //         }));
    //     } else {
    //         dispatch(setAccessLogsSortOptions({
    //             sortOptions: {
    //                 sortBy,
    //                 orderBy: 1,
    //             },
    //         }));
    //     }
    // }, [sortOptions]);

    return (
        <tr>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.timestamp`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.client`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.gateName`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.location`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.scanResult`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.details`)}
                />
            </TableHeaderCell>
        </tr>
    );
};

LogsTableHeader.propTypes = {};

LogsTableHeader.defaultProps = {};

export default LogsTableHeader;
