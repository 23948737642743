import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';

import * as CLIENTS_ACTIONS from 'store/actions/clients';

import { Button } from 'components/Common/Button';
import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import { newSelectStyles, theme } from '../../../../styles/select';
import * as styles from './ApplyDiscountProfileDialog.module.scss';

const T_PREFIX = 'list.dialogs.applyDiscountProfile';

const ApplyDiscountProfileDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const dispatch = useDispatch();

    const NO_DISCOUNT = {
        value: null,
        label: t(`${T_PREFIX}.noDiscountProfile`),
    };

    const {
        selectedClients,
        visible,
        onClose,
        onDeselectAll,
        discountProfiles,
    } = props;

    const schema = yup.object({
        discount: yup.object({
            value: yup.number(),
            label: yup.string(),
        }),
    });

    const formik = useFormik({
        initialValues: {
            discount: null,
        },
        validationSchema: schema,
    });

    const {
        setFieldValue,
        setFieldTouched,
        values,
    } = formik;

    const discountProfileOptions = useMemo(() => {
        const discountOptions = discountProfiles.map((item) => ({
            value: item.id,
            label: item.name,
        }));
        return [NO_DISCOUNT, ...discountOptions];
    }, [discountProfiles, t]);

    const handleDiscountChange = useCallback((selected) => {
        if (!selected) {
            return setFieldValue('discount', null);
        }

        setFieldValue('discount', selected ?? null);
        setFieldTouched('discount', true);
    }, [setFieldValue, values]);

    const handleApplyClientProfile = useCallback(() => {
        const selectedClientsIds = Object.entries(selectedClients).map((c) => Number(c[0]));

        const data = new FormData();
        data.append('clients', selectedClientsIds);

        if (values?.discount?.value) {
            data.append('clientCategory', values?.discount?.value);
        }

        dispatch(CLIENTS_ACTIONS.applyDiscountProfiles({ data }));
        onClose();
        onDeselectAll();
        setFieldValue('discount', null);
    }, [selectedClients, values, dispatch]);

    return (
        <Dialog
            visible={visible}
            onClose={onClose}
        >
            <DialogHeader>
                <DialogTitle>
                    {t(`${T_PREFIX}.title`)}
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <p className={styles.grayField}>
                    {t(`${T_PREFIX}.description`)}
                </p>
                {
                    Object.keys(selectedClients).length
                        ? (
                            <Form
                                id="discount-profile-apply"
                            >
                                <Container>
                                    <Row>
                                        <Select
                                            value={values.discount ? {
                                                value: values.discount.value,
                                                label: values.discount.label,
                                            } : null}
                                            isSearchable={false}
                                            theme={theme}
                                            styles={newSelectStyles}
                                            className={styles.filter}
                                            placeholder={t(`${T_PREFIX}.discountProfile`)}
                                            options={discountProfileOptions}
                                            onChange={handleDiscountChange}
                                        />
                                    </Row>
                                </Container>
                            </Form>
                        )
                        : (
                            <p className={styles.grayField}>
                                {t(`${T_PREFIX}.noSelectedClients`)}
                            </p>
                        )
                }
            </DialogBody>
            <DialogFooter>
                <Button
                    type="submit"
                    disabled={!Object.keys(selectedClients).length || !values?.discount}
                    onClick={handleApplyClientProfile}
                >
                    {t(`${T_PREFIX}.actions.apply`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

ApplyDiscountProfileDialog.propTypes = {
    selectedClients: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onDeselectAll: PropTypes.func,
    discountProfiles: PropTypes.array,
};

ApplyDiscountProfileDialog.defaultProps = {
    selectedClients: {},
    visible: false,
    onClose: emptyFunc,
    onDeselectAll: emptyFunc,
    discountProfiles: [],
};

export default ApplyDiscountProfileDialog;
