import { createSelector } from 'reselect';

export const subscriptionsSelector = (state) => state.subscriptions;

export const soldSubscriptionsSelector = createSelector(
    subscriptionsSelector,
    (subscriptionsState) => subscriptionsState.soldSubscriptions,
);

export const subscriptionsListSelector = createSelector(
    subscriptionsSelector,
    (subscriptionsState) => subscriptionsState.subscriptions,
);

export const soldSubscriptionsListSelector = createSelector(
    soldSubscriptionsSelector,
    (subscriptions) => subscriptions.items,
);

const soldSubscriptionsListSalesInfoSelector = createSelector(
    soldSubscriptionsSelector,
    ({ activeCount, totalMonthly, totalAnnual }) => ({
        activeCount,
        totalMonthly,
        totalAnnual,
    }),
);

const sellSubscriptionSelector = createSelector(
    soldSubscriptionsSelector,
    ({ sellSubscription }) => sellSubscription,
);

export const soldSubscriptionsPagesSelector = createSelector(
    soldSubscriptionsSelector,
    ({ currentPage, pagesCount }) => ({
        currentPage,
        pagesCount,
    }),
);

export const subscriptionsPagesSelector = createSelector(
    subscriptionsListSelector,
    ({ currentPage, pagesCount }) => ({
        currentPage,
        pagesCount,
    }),
);

const subscriptionsItemsCountSelector = createSelector(
    subscriptionsListSelector,
    ({ itemsCount }) => itemsCount,
);

const soldSubscriptionsItemsCountSelector = createSelector(
    soldSubscriptionsSelector,
    ({ itemsCount }) => itemsCount,
);

const soldSubscriptionsShowAllSelector = createSelector(
    soldSubscriptionsSelector,
    ({ showAll }) => showAll,
);

const subscriptionsShowAllSelector = createSelector(
    subscriptionsListSelector,
    ({ showAll }) => showAll,
);

const subscriptionsSearchSelector = createSelector(
    subscriptionsListSelector,
    ({ searchValue }) => searchValue,
);

const soldSubscriptionsSearchSelector = createSelector(
    soldSubscriptionsSelector,
    ({ searchValue }) => searchValue,
);

const soldSubscriptionsStatusFilterSelector = createSelector(
    soldSubscriptionsSelector,
    ({ statusFilter }) => statusFilter,
);

const soldSubscriptionsIdFilterSelector = createSelector(
    soldSubscriptionsSelector,
    ({ idFilter }) => idFilter,
);

export const subscriptionsOptionsSelector = createSelector(
    soldSubscriptionsSelector,
    ({ subscriptionsOptions }) => subscriptionsOptions,
);

export const editSubscriptionUsagesSelector = createSelector(
    soldSubscriptionsSelector,
    ({ editSubscriptionUsages }) => editSubscriptionUsages,
);

const subscriptionsListTableSelector = createSelector(
    subscriptionsListSelector,
    ({ items }) => items,
);

const subscriptionsIsDataLoadedSelector = createSelector(
    subscriptionsListSelector,
    (subscriptions) => subscriptions.isDataLoaded,
);

const soldSubscriptionsIsDataLoadedSelector = createSelector(
    soldSubscriptionsSelector,
    (subscriptions) => subscriptions.isDataLoaded,
);

export const cancelSoldSubscriptionSelector = createSelector(
    soldSubscriptionsSelector,
    ({ cancelSoldSubscription }) => cancelSoldSubscription,
);

export const changeSoldSubscriptionSelector = createSelector(
    soldSubscriptionsSelector,
    ({ changeSoldSubscription }) => changeSoldSubscription,
);

export const subscriptionsListPageSelector = createSelector(
    subscriptionsListTableSelector,
    subscriptionsIsDataLoadedSelector,
    subscriptionsPagesSelector,
    subscriptionsItemsCountSelector,
    subscriptionsSearchSelector,
    (subscriptionsList, isDataLoaded, pages, itemsCount, searchValue) => ({
        subscriptionsList,
        isDataLoaded,
        ...pages,
        itemsCount,
        searchValue,
    }),
);

export const soldSubscriptionsListPageSelector = createSelector(
    soldSubscriptionsListSelector,
    soldSubscriptionsIsDataLoadedSelector,
    soldSubscriptionsPagesSelector,
    soldSubscriptionsItemsCountSelector,
    soldSubscriptionsSearchSelector,
    soldSubscriptionsIdFilterSelector,
    soldSubscriptionsStatusFilterSelector,
    soldSubscriptionsListSalesInfoSelector,
    sellSubscriptionSelector,
    cancelSoldSubscriptionSelector,
    editSubscriptionUsagesSelector,
    (
        soldSubscriptionsList,
        isDataLoaded,
        pages,
        itemsCount,
        searchValue,
        idFilter,
        statusFilter,
        salesInfo,
        sellSubscription,
        cancelSoldSubscription,
        editSubscriptionUsages,
    ) => ({
        soldSubscriptionsList,
        isDataLoaded,
        ...pages,
        itemsCount,
        searchValue,
        idFilter,
        statusFilter,
        ...salesInfo,
        sellSubscription,
        cancelSoldSubscription,
        editSubscriptionUsages,
    }),
);

export const soldSubscriptionsTableFooterSelector = createSelector(
    soldSubscriptionsPagesSelector,
    soldSubscriptionsItemsCountSelector,
    soldSubscriptionsShowAllSelector,
    ({ currentPage, pagesCount }, itemsCount, showAll) => ({
        currentPage,
        itemsCount,
        pagesCount,
        showAll,
    }),
);

export const subscriptionsTableFooterSelector = createSelector(
    subscriptionsPagesSelector,
    subscriptionsItemsCountSelector,
    subscriptionsShowAllSelector,
    ({ currentPage, pagesCount }, itemsCount, showAll) => ({
        currentPage,
        itemsCount,
        pagesCount,
        showAll,
    }),
);

export const editSubscriptionSelector = createSelector(
    subscriptionsListSelector,
    subscriptionsIsDataLoadedSelector,
    ({ items }, isDataLoaded) => ({
        subscriptions: items,
        isDataLoaded,
    }),
);

export const sellSubscriptionModalSelector = createSelector(
    sellSubscriptionSelector,
    ({ clients, subscriptions }) => ({
        subscriptions: subscriptions.map(({
            id, name, price, description, price_formatted,
        }) => ({
            label: `${name} - ${price_formatted}`,
            description,
            value: id,
            price: price_formatted,
            isFree: !price,
        })),

        clients: clients.map((client) => ({
            label: client.firstName,
            value: {
                clientId: client.client_id,
                clientName: client.firstName,
                clientEmail: client.email,
                clientPhone: client.contactNumber,
                note: client.note,
            },
        })),
    }),
);

export const soldSubscriptionListsQuerySelector = createSelector(
    soldSubscriptionsPagesSelector,
    soldSubscriptionsSearchSelector,
    soldSubscriptionsIdFilterSelector,
    soldSubscriptionsStatusFilterSelector,
    ({ currentPage }, searchValue, idFilter, statusFilter) => ({
        currentPage,
        searchValue,
        idFilter,
        statusFilter,
    }),
);

export const subscriptionListsQuerySelector = createSelector(
    subscriptionsPagesSelector,
    subscriptionsSearchSelector,
    ({ currentPage }, searchValue) => ({
        currentPage,
        searchValue,
    }),
);
