import React from 'react';

// Components
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Map = ({
    id = 'google-map', center = { latitude: 50, longitude: 20 }, markerPosition, onMarkerPositionChange, children,
}) => (
    <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyC1f4ALyhBGuHEb0Buul_gCIsn1cEj1ewQ"
        loadingElement={<div style={{ height: '100%' }} />}
    >
        <GoogleMap
            id={id}
            zoom={14}
            center={{ lat: center.latitude, lng: center.longitude }}
            mapContainerStyle={{ height: '100%' }}
        >
            {markerPosition && (
                <Marker
                    position={{
                        lat: markerPosition.latitude,
                        lng: markerPosition.longitude,
                    }}
                    draggable
                    onDragEnd={({ latLng: { lat, lng } }) => {
                        if (!onMarkerPositionChange) {
                            return;
                        }

                        onMarkerPositionChange({
                            latitude: lat(),
                            longitude: lng(),
                        });
                    }}
                />
            )}

            {children}
        </GoogleMap>
    </LoadScript>
);

export default Map;
