const PREFIX = '@@CLIENTS/';
const CLIENT_ITEM = `${PREFIX}:CLIENT:ITEM`;

const CLIENT_ITEM_BUNDLES = `${CLIENT_ITEM}:BUNDLES`;
const CLIENT_ITEM_BUNDLES_LOADING = `${CLIENT_ITEM_BUNDLES}:LOADING`;
export const CLIENT_ITEM_BUNDLES_LOADING_SET = `${CLIENT_ITEM_BUNDLES_LOADING}:SET`;
export const CLIENT_ITEM_BUNDLES_GET = `${CLIENT_ITEM_BUNDLES}:GET`;
export const CLIENT_ITEM_BUNDLES_SET = `${CLIENT_ITEM_BUNDLES}:SET`;
export const CLIENT_ITEM_BUNDLES_EDIT = `${CLIENT_ITEM_BUNDLES}:EDIT`;
export const CLIENT_ITEM_BUNDLES_ARCHIVE = `${CLIENT_ITEM_BUNDLES}:ARCHIVE`;
const CLIENT_ITEM_BUNDLES_PAGE = `${CLIENT_ITEM_BUNDLES}:PAGE`;
export const CLIENT_ITEM_BUNDLES_PAGE_SET = `${CLIENT_ITEM_BUNDLES_PAGE}:SET`;
export const CLIENT_ITEM_BUNDLES_PAGE_SET_FIRST = `${CLIENT_ITEM_BUNDLES_PAGE}:FIRST`;
export const CLIENT_ITEM_BUNDLES_PAGE_SET_LAST = `${CLIENT_ITEM_BUNDLES_PAGE}:LAST`;
export const CLIENT_ITEM_BUNDLES_PAGE_SET_PREV = `${CLIENT_ITEM_BUNDLES_PAGE}:PREV`;
export const CLIENT_ITEM_BUNDLES_PAGE_SET_NEXT = `${CLIENT_ITEM_BUNDLES_PAGE}:NEXT`;

const CLIENT_ITEM_BUNDLES_HIDE_ARCHIVED = `${CLIENT_ITEM_BUNDLES}:HIDE_ARCHIVED`;
export const CLIENT_ITEM_BUNDLES_HIDE_ARCHIVED_TOGGLE = `${CLIENT_ITEM_BUNDLES_HIDE_ARCHIVED}:TOGGLE`;
