import React, { useCallback } from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Actions
import * as actions from 'store/actions';
import { permissionsResourceSelector } from 'store/selectors/permissions';

// Components
import Skeleton from 'react-loading-skeleton';
import Event from 'components/Event/Event';
import BookingModal from 'components/BookingModal/BookingModal';
import EditBookingModal from 'components/EditBookingModal/EditBookingModal';
import AddBookingModal from 'components/AddBookingModal/AddBookingModal';
import { ConfirmationModal, EventDetailsModal } from 'components';

// Utils
import moment from 'moment';
import * as EVENTS_ACTIONS from 'store/actions/events';
import momentAmsterdamTime from 'helpers/time/momentAmsterdamTime';

import { Loader } from 'components/Common/Loader';
import { random, times } from 'lodash';
import { Button } from 'components/Common/Button';
import { Pagination } from '../../../../../components/Common/Pagination';
import { eventsPageSelector } from '../../../../../store/selectors/events';
import * as styles from './index.module.scss';

const groupEventsByDate = (events) => events.reduce((events, event) => {
    const date = moment(event.time.from).format('D MMM YYYY');
    if (events[date]) {
        return {
            ...events,
            [date]: [
                ...events[date],
                event,
            ],
        };
    }

    return {
        ...events,
        [date]: [event],
    };
}, {});

const Index = () => {
    const addBookingDetails = useSelector((state) => state.bookings.addBookingDetails);
    const { banAdd } = useSelector(permissionsResourceSelector);

    const [clickedEventID, setClickedEventID] = useState(null);
    const [isSendingNotification, _] = useState(false);
    const [clickedBookingID, setClickedBookingID] = useState(null);
    const [editBooking, setEditBooking] = useState(false);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [isCancellingEvent, setIsCancellingEvent] = useState(false);

    useEffect(() => {
        if (!addBookingDetails) {
            return;
        }

        setShowBookingModal(true);
    }, [addBookingDetails]);

    // eslint-disable-next-line react/no-unstable-nested-components
    const EventGroup = (stringDate, events) => (
        <div key={events[0].id} className="mt-2 mb-4">
            <h3 className="font-weight-normal border-bottom pb-3">{stringDate}</h3>

            <div className="mt-4 mb-5 cursor-pointer">
                {events.map((event) => (
                    <Event
                        key={`event-${event.id}`}
                        className="mt-5 mt-md-4"
                        event={event}
                        onClick={() => setClickedEventID(event.resourceId)}
                    />
                ))}
            </div>
        </div>
    );

    const {
        loadingEvents,
        events: eventsArray,
        pagesCount,
        itemsCount,
        page,
        showAll,
    } = useSelector(eventsPageSelector);

    const event = useSelector((state) => (state.events.events || []).find(({ resourceId }) => resourceId === clickedEventID));
    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const { t } = useTranslation();

    const renderEvents = () => {
        if (!eventsArray) return;
        const sortedEvents = eventsArray.sort((a, b) => a.time.from - b.time.from);

        const pastEvents = groupEventsByDate(sortedEvents.filter((event) => moment(event.time.from).isBefore(momentAmsterdamTime())));

        const upcomingEvents = groupEventsByDate(sortedEvents.filter((event) => moment(event.time.from).isAfter(momentAmsterdamTime())));

        const upcomingEventsComponents = Object.entries(upcomingEvents).map(([date, events]) => EventGroup(date, events));
        const pastEventsComponents = Object.entries(pastEvents).map(([date, events]) => EventGroup(date, events));

        return (
            <>
                {upcomingEventsComponents.length !== 0 && <h2 className="mb-3">{t('eventsRoute.comingEvents')}</h2>}
                {upcomingEventsComponents}
                {pastEventsComponents.length !== 0 && <h2 className="mb-3">{t('eventsRoute.passedEvents')}</h2>}
                {pastEventsComponents.reverse()}
            </>
        );
    };

    const onCancelHandler = async () => {
        if (isCancellingEvent) {
            return;
        }

        setIsCancellingEvent(true);
        try {
            await dispatch(actions.cancelEvent({ id: event?.id }));
            addToast(t('eventsRoute.statusEventSuccess'), {
                appearance: 'success',
            });
            setCancelModalVisible(false);
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        } finally {
            setIsCancellingEvent(false);
        }
    };
    const handleShowAll = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsShowAll({ showAll: true }));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: page - 1 }));
    }, [dispatch, page]);

    const handleNextPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: page + 1 }));
    }, [dispatch, page]);

    const handleLastPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: pagesCount }));
    }, [dispatch, pagesCount]);

    const handleFirstPage = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: 1 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(EVENTS_ACTIONS.setEventsShowAll({ showAll: false }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(EVENTS_ACTIONS.setEventsPage({ page: 1 }));
    }, [dispatch]);

    return (
        <>
            <div>
                <div className="d-flex justify-content-between mb-5">
                    <h3 className={styles.header}>{t('eventsRoute.events')}</h3>

                    {!banAdd && (
                        <div className="d-flex align-items-center">
                            <Button
                                color="yellow"
                                href="/agenda/events/add"
                            >
                                {t('eventsRoute.addEvent')}
                            </Button>
                        </div>
                    )}
                </div>

                {loadingEvents ? <Loader /> : renderEvents()}

                {!eventsArray && times(random(4), (i) => (
                    <div key={`events-loading-${i}`} className="mt-2 mb-4">
                        <h3 className="font-weight-normal border-bottom pb-3">
                            <Skeleton width={125 + random(50)} />
                        </h3>

                        <div className="mt-4 mb-5">
                            {times(1 + random(4), (i) => (
                                <Event
                                    key={`event-loading-${i}`}
                                    className="mt-5 mt-md-4 cursor-pointer"
                                />
                            ))}
                        </div>
                    </div>
                ))}
                <Pagination
                    page={page}
                    pagesCount={pagesCount}
                    itemsCount={itemsCount}
                    itemsPerPage={10}
                    onChangePage={handleChangePage}
                    onShowPages={handleShowPages}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPrevPage={handlePrevPage}
                    onNextPage={handleNextPage}
                    onShowAll={handleShowAll}
                    showAll={showAll}
                />
            </div>

            <EventDetailsModal
                event={event}
                clickedEventID={clickedEventID}
                setClickedEventID={setClickedEventID}
                showBookingModal={showBookingModal}
                clickedBookingID={clickedBookingID}
                cancelModalVisible={cancelModalVisible}
                setClickedBookingID={setClickedBookingID}
                isSendingNotification={isSendingNotification}
                setCancelModalVisible={setCancelModalVisible}
            />

            <ConfirmationModal
                isShow={cancelModalVisible}
                hide={() => setCancelModalVisible(false)}
                loading={isCancellingEvent}
                confirmAction={onCancelHandler}
                deleteColor={event?.isCancelled ? 'green' : 'red'}
                titleText={`${t(`eventsRoute.${event?.isCancelled ? 'reactivate' : 'cancel'}`)} ${t('eventsRoute.event')}`}
                bodyText={t(`eventsRoute.areYouSureYouWantTo${event?.isCancelled ? 'Reactivate' : 'Cancel'}Event`)}
                deleteText={t(`eventsRoute.${event?.isCancelled ? 'reactivate' : 'cancel'}`)}
                dismissText={t('groupDetailsModal.back')}
            />

            {showBookingModal && (
                <AddBookingModal
                    show={showBookingModal}
                    onHide={() => setShowBookingModal(false)}
                    productType="event"
                />
            )}

            <BookingModal
                bookingIDWithTimestamp={clickedBookingID}
                show={clickedBookingID && !editBooking}
                onHide={() => setClickedBookingID(null)}
                onEditChange={() => setEditBooking(true)}
                productType="event"
            />

            <EditBookingModal
                bookingIDWithTimestamp={clickedBookingID}
                show={editBooking}
                onHide={() => setEditBooking(false)}
                productType="event"
            />
        </>
    );
};

export default Index;
