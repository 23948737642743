import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PlusBordered, MinusIcon, ReloadIcon } from 'components/Icon/Icon';

import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { Tooltip } from '../../../../../Common/Tooltip';

import { formatPrice } from '../../../../../../helpers/formatters/formatPrice';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientBookingsTableColumns.module.scss';

const PAYMENT_STATUSES = {
    PAID: 'paid',
    UNPAID: 'unpaid',
    FAILED: 'failed',
    REFUNDED: 'refunded',
    REFUND_PENDING: 'refund-pending',
};

const BOOKING_STATUSES = {
    ACTIVE: 'active',
    PRESENT: 'present',
    NOT_PRESENT: 'not present',
    CANCELED: 'canceled',
};

const T_PREFIX = 'bookings.list.table';
const T_BODY = `${T_PREFIX}.body.rows.fields`;

const ClientBookingsTableColumns = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const {
        booking,
        showDiscounts,
        onToggleShowDiscounts,
    } = props;

    const {
        payment,
        status,
        dateTime,
        cancellationDate,
        isRecurring,
    } = booking || {};

    const {
        transactionId,
        amount,
        date,
        type,
    } = payment || {};

    const paymentStatus = booking.payment.status;
    const serviceDate = moment(dateTime * 1000).format('DD MMM YYYY HH:mm');

    const paymentDate = moment(date).format('DD MMM YYYY HH:mm');

    const amountLabel = formatPrice.toEuroWithComma({ amount });

    const paymentStatusStyles = {
        [styles.greenField]: paymentStatus === PAYMENT_STATUSES.PAID,
        [styles.redField]: [PAYMENT_STATUSES.FAILED, PAYMENT_STATUSES.UNPAID].includes(paymentStatus),
        [styles.grayField]: [PAYMENT_STATUSES.REFUNDED, PAYMENT_STATUSES.REFUND_PENDING].includes(paymentStatus),
    };

    const bookingStatusStyles = {
        [styles.greenField]: status === BOOKING_STATUSES.ACTIVE,
        [styles.blueField]: status === BOOKING_STATUSES.PRESENT,
        [styles.redField]: status === BOOKING_STATUSES.CANCELED,
        [styles.grayField]: status === BOOKING_STATUSES.NOT_PRESENT,
    };

    return (
        <>
            <ListTableBodyCell data-testid="data-test-shop-name">
                <button
                    type="button"
                    className={styles.iconButton}
                    onClick={onToggleShowDiscounts}
                    data-testid="data-test-show-discounts-button"
                >
                    {showDiscounts ? (
                        <MinusIcon size={18} />
                    ) : (
                        <PlusBordered size={18} />
                    )}
                </button>
                {booking.shop.name}
            </ListTableBodyCell>
            <ListTableBodyCell data-testid="data-test-service-name">
                <div className={styles.bookingServiceName}>
                    {Boolean(isRecurring) && (
                        <Tooltip
                            tooltip={t(`${T_BODY}.recurringBooking`)}
                            placement="bottom"
                        >
                            <div className={styles.reloadIconWrapper} data-testid="data-test-recurring-icon">
                                <ReloadIcon />
                            </div>
                        </Tooltip>
                    )}

                    {booking.service.name}
                </div>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted" data-testid="data-test-payment-date">
                {paymentDate}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted" data-testid="data-test-cancellation-date">
                {cancellationDate ? moment(cancellationDate).format('DD MMM YYYY HH:mm') : '-'}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted" data-testid="data-test-service-date">
                {serviceDate}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted" data-testid="data-test-payment-type">
                {t(`${T_BODY}.paymentType.${type}`)}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={
                    classNames(
                        styles.capitalize,
                        paymentStatusStyles,
                    )
                }
                data-testid="data-test-payment-status"
            >
                {t(`${T_BODY}.paymentStatus.${paymentStatus}`)}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted" data-testid="data-test-transaction-id">
                {transactionId ?? '-'}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted" align="right" data-testid="data-test-amount">
                {amountLabel}
            </ListTableBodyCell>
            <ListTableBodyCell
                className={
                    classNames(
                        styles.capitalize,
                        bookingStatusStyles,
                    )
                }
                data-testid="data-test-booking-status"
            >
                {t(`${T_BODY}.bookingStatus.${status}`)}
            </ListTableBodyCell>
        </>
    );
};

ClientBookingsTableColumns.propTypes = {
    booking: PropTypes.object,
    showDiscounts: PropTypes.bool,
    onToggleShowDiscounts: PropTypes.func,
};

ClientBookingsTableColumns.defaultProps = {
    booking: null,
    showDiscounts: false,
    onToggleShowDiscounts: emptyFunc,
};

export default ClientBookingsTableColumns;
