import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
    Container, Row, Col, Form,
} from 'react-bootstrap';
import { useHistory } from 'react-router';

import classNames from 'classnames';
import { Button } from 'components/Common/Button';
import LocalesDropdown from '../../components/LocalesDropdown/LocalesDropdown';

import { CeckIcon, LogoIcon } from '../../components/Icon/Icon';
import login from '../../static/images/login.jpg';

import * as styles from './page.module.scss';

import { setNewPassword } from '../../store/actions';

import { SIGN_IN_PAGE } from '../../const/e2e/TEST_ATTRIBUTES';
import { generateRandomString } from '../../helpers/string/generateRandomString';
import { AGENDA, SIGN_IN } from '../../const/CLIENT_URL';

const MIN_PASS_LENGTH = 8;
const MAX_PASS_LENGTH = 24;
const MIN_PASS_CAPITAL_LETTERS = 1;
const MIN_PASS_LOWERCASE_LETTERS = 1;
const MIN_PASS_DIGITS = 1;

const SetNewPassword = (props) => {
    const { location } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [token, setToken] = useState(null);

    const passRules = useMemo(() => [
        {
            pattern: new RegExp(`^.{${MIN_PASS_LENGTH},${MAX_PASS_LENGTH}}$`),
            message: t('setNewPasswordRoute.newPassword.rules.length', { min: MIN_PASS_LENGTH, max: MAX_PASS_LENGTH }),
        },
        {
            pattern: {
                test: (str) => str && Array.from(str.matchAll(/[A-Z]/g)).length >= 1,
            },
            message: t('setNewPasswordRoute.newPassword.rules.capitalLetter', { min: MIN_PASS_CAPITAL_LETTERS }),
        },
        {
            pattern: {
                test: (str) => str && Array.from(str.matchAll(/[a-z]/g)).length >= 1,
            },
            message: t('setNewPasswordRoute.newPassword.rules.lowercaseLetter', { min: MIN_PASS_LOWERCASE_LETTERS }),
        },
        {
            pattern: {
                test: (str) => str && Array.from(str.matchAll(/[0-9]/g)).length >= 1,
            },
            message: t('setNewPasswordRoute.newPassword.rules.digit', { min: MIN_PASS_DIGITS }),
        },
    ], [t]);

    const validationSchema = useMemo(() => (
        yup.object({
            newPassword: yup
                .string()
                .trim()
                .required()
                .test((value) => passRules.every((rule) => rule.pattern.test(value))),
            repeatedNewPassword: yup
                .string()
                .trim()
                .required(t('setNewPasswordRoute.repeatNewPassword.errors.required'))
                .when('newPassword', (newPassword, schema) => schema.test({
                    name: generateRandomString(),
                    test: (repeatedNewPassword) => repeatedNewPassword === newPassword,
                    message: t('setNewPasswordRoute.repeatNewPassword.errors.notEqualPasswords'),
                })),
        })
    ), [t]);

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
    } = useFormik({
        validationSchema,
        initialValues: {
            newPassword: '',
            repeatedNewPassword: '',
        },
        onSubmit: ({ newPassword }, { setSubmitting }) => {
            if (!token) {
                return;
            }

            setSubmitting(true);
            dispatch(setNewPassword({
                newPassword,
                token,
                onFinish: () => setSubmitting(false),
                onSuccess: () => history.push(AGENDA),
            }));
        },
    });

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (!token) {
            history.push(SIGN_IN);
            return;
        }
        setToken(token);
    }, []);

    return (
        <Container className="d-flex flex-column justify-content-center align-items-end">
            <div className="w-100 bg-white rounded shadow-container">
                <Row>
                    <Col md={6} className="d-none d-md-block pr-md-0">
                        <img className="w-100 h-100 object-fit-cover rounded-left" src={login} alt="" />
                    </Col>

                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center pl-md-0">
                        <div className="d-flex flex-column justify-content-center h-100 p-3 pt-5 px-md-4 px-lg-5 pt-md-5 pb-md-0">
                            <LogoIcon className="mb-4" />

                            <h1>
                                {t('setNewPasswordRoute.title.label')}
                            </h1>

                            <Form className="mt-3" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label data-testid={SIGN_IN_PAGE.emailFieldTitleSelector}>
                                        {t('setNewPasswordRoute.newPassword.label')}
                                    </Form.Label>
                                    <Form.Control
                                        name="newPassword"
                                        type="password"
                                        autocomplete="new-password"
                                        value={values.newPassword}
                                        onChange={handleChange}
                                        isInvalid={touched.newPassword && !!errors.newPassword}
                                    />
                                    <div className={styles.passwordCheckBlock}>
                                        <div className={styles.ruleColumn}>
                                            {passRules.slice(0, 2).map((rule) => {
                                                const isValid = (values.newPassword || touched.newPassword)
                                                    && rule.pattern.test(values.newPassword);

                                                return (
                                                    <div
                                                        key={rule.message}
                                                        className={classNames(
                                                            styles.rule,
                                                            {
                                                                [styles.isValid]: isValid,
                                                                [styles.isInvalid]: !isValid && touched.newPassword,
                                                            },
                                                        )}
                                                    >
                                                        <CeckIcon />
                                                        <div>{rule.message}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className={styles.ruleColumn}>
                                            {passRules.slice(2).map((rule) => {
                                                const isValid = (values.newPassword || touched.newPassword)
                                                    && rule.pattern.test(values.newPassword);

                                                return (
                                                    <div
                                                        key={rule.message}
                                                        className={classNames(
                                                            styles.rule,
                                                            {
                                                                [styles.isValid]: isValid,
                                                                [styles.isInvalid]: !isValid && touched.newPassword,
                                                            },
                                                        )}
                                                    >
                                                        <CeckIcon />
                                                        <div>{rule.message}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label data-testid={SIGN_IN_PAGE.passwordFieldTitleSelector}>
                                        {t('setNewPasswordRoute.repeatNewPassword.label')}
                                    </Form.Label>
                                    <Form.Control
                                        name="repeatedNewPassword"
                                        type="password"
                                        value={values.repeatedNewPassword}
                                        onChange={handleChange}
                                        isInvalid={touched.repeatedNewPassword && !!errors.repeatedNewPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.repeatedNewPassword}</Form.Control.Feedback>
                                </Form.Group>

                                <Button
                                    color="yellow"
                                    type="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >
                                    {t('setNewPasswordRoute.save.label')}
                                </Button>
                            </Form>
                        </div>

                        <div className="d-flex flex-direction-row justify-content-end align-items-end px-3 px-md-4 px-lg-5 py-4 pt-md-0">
                            <LocalesDropdown />
                        </div>
                    </Col>
                </Row>
            </div>

            <span className="small text-muted mt-2">{process.env.VERSION}</span>
        </Container>
    );
};

export default SetNewPassword;
