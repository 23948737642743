import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import moment from 'moment';

import { ConfirmDialog } from '../../Common/Dialogs/ConfirmDialog';
import { SoldSubscriptionBookingsList } from '../SoldSubscriptionBookingsList';

import { useBool } from '../../../hooks/useBool';

import * as SUBSCRIPTION_ACTIONS from '../../../store/actions/subscriptions';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './DeleteSoldSubscriptionDialog.module.scss';

const T_PREFIX = 'sold.dialogs.delete';
const T_FOOTER = `${T_PREFIX}.footer`;
const T_ACTIONS = `${T_FOOTER}.actions`;

const DeleteSoldSubscriptionDialog = (props) => {
    const {
        isVisible,
        soldSubscriptionId,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    const soldSubscriptionIdRef = useRef(soldSubscriptionId);

    const isLoading = useBool(false);
    const loadedBookings = useBool(false);

    const [bookings, setBookings] = useState([]);

    const haveBookings = loadedBookings.value && Boolean(bookings.length);

    const size = haveBookings ? 'lg' : 'md';

    const text = haveBookings
        ? (
            <div className={styles.bookingsList}>
                <span className={styles.bookingsListTitle}>
                    {t(`${T_PREFIX}.haveBookings`)}
                </span>
                <SoldSubscriptionBookingsList bookings={bookings} />
            </div>
        )
        : t(`${T_PREFIX}.text`);

    const handleDeleteSuccess = useCallback((data) => {
        const { soldSubscriptionId: sId } = data;
        if (sId === soldSubscriptionIdRef.current) {
            onClose();
        }
    }, [onClose]);

    const handleFail = useCallback((data) => {
        const { soldSubscriptionId: sId } = data;
        if (sId === soldSubscriptionIdRef.current) {
            isLoading.onFalse();
        }
    }, []);

    const handleConfirmDelete = useCallback(() => {
        if (!soldSubscriptionId) {
            return;
        }
        isLoading.onTrue();
        dispatch(SUBSCRIPTION_ACTIONS.deleteSoldSubscriptionsItem({
            soldSubscriptionId,
            onSuccess: handleDeleteSuccess,
            onFail: handleFail,
        }));
    }, [
        soldSubscriptionId,
        dispatch,
        handleDeleteSuccess,
        handleFail,
    ]);

    // const handleGetBookingsSuccess = useCallback((data) => {
    //     const {
    //         soldSubscriptionId: sId,
    //         bookings: sBookings,
    //     } = data;
    //     if (sId !== soldSubscriptionIdRef.current) {
    //         return;
    //     }
    //     if (!sBookings.length) {
    //         return handleConfirmDelete();
    //     }
    //     loadedBookings.onTrue();
    //     isLoading.onFalse();
    //     setBookings(sBookings);
    // }, [handleConfirmDelete]);

    // const handleGetBookings = useCallback(() => {
    //     if (!soldSubscriptionId) {
    //         return;
    //     }
    //     isLoading.onTrue();
    //     dispatch(SUBSCRIPTION_ACTIONS.getSoldSubscriptionsItemBookings({
    //         startDate: moment()
    //             .add(-1, 'day')
    //             .endOf('day') // TODO: endOf('day') is temp workaround, remove on backend update
    //             .unix(),
    //         soldSubscriptionId,
    //         onSuccess: handleGetBookingsSuccess,
    //         onFail: handleFail,
    //     }));
    // }, [
    //     soldSubscriptionId,
    //     dispatch,
    //     handleGetBookingsSuccess,
    //     handleFail,
    // ]);

    useEffect(() => {
        if (!isVisible) {
            setBookings([]);
            isLoading.onFalse();
            loadedBookings.onFalse();
        }
    }, [isVisible]);

    useEffect(() => {
        soldSubscriptionIdRef.current = soldSubscriptionId;
    }, [soldSubscriptionId]);

    return (
        <ConfirmDialog
            size={size}
            text={text}
            title={t(`${T_PREFIX}.title`)}
            visible={isVisible && Boolean(soldSubscriptionId)}
            onClose={onClose}
            onReject={onClose}
            onConfirm={handleConfirmDelete}
            rejectProps={{ label: t(`${T_ACTIONS}.cancel`) }}
            confirmProps={{
                label: t(`${T_ACTIONS}.yes`),
                isLoading: isLoading.value,
            }}
        />
    );
};

DeleteSoldSubscriptionDialog.propTypes = {
    isVisible: PropTypes.bool,
    soldSubscriptionId: PropTypes.number,
    onClose: PropTypes.func,
};

DeleteSoldSubscriptionDialog.defultProps = {
    isVisible: false,
    soldSubscriptionId: null,
    onClose: emptyFunc,
};

export default DeleteSoldSubscriptionDialog;
