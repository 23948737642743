import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import * as actions from 'store/actions/bookings';

// Components
import { Timeline, BookingsListModal } from 'components';

// Selectors
import { productDataSelector } from 'store/selectors/products';

// Utils
import moment from 'moment';
import { useMobile } from 'hooks/useMobile';
import Calendar from '../../Calendar/Calendar';

const Month = ({
    productType = 'basic',
    month,
    year,
    setMonth,
    setYear,
    onBookingClick,
    clickedBookingId,
}) => {
    const isShopLoaded = useSelector((state) => state.shop.loaded);
    const workingDays = useSelector(
        (state) => isShopLoaded && state.shop.workingDays,
    );
    const dispatch = useDispatch();

    const { bookings } = useSelector(productDataSelector(`${productType}s`));
    const bookingsLoading = useSelector((state) => state[`${productType}s`].bookingsLoading);

    const [isModalVisible, setModalVisible] = useState(false);
    const [clickedDay, setClickedDay] = useState(null);
    const isMobile = useMobile();

    useEffect(() => {
        if (!isShopLoaded) {
            return;
        }

        const date = moment().month(month).year(year);
        dispatch(
            actions.getBookings(
                date.clone().startOf('month').unix(),
                date.clone().endOf('month').unix(),
                productType === 'object' ? 'basic' : productType,
            ),
        );
    }, [isShopLoaded, month, year]);

    return (
        <>
            {isMobile ? (
                <Calendar
                    loading={bookingsLoading}
                    month={month}
                    year={year}
                    selectedDate={moment()}
                    bookings={bookings}
                    workingDays={workingDays}
                    onSelectDate={(day) => {
                        setClickedDay(day);
                        setModalVisible(true);
                    }}
                    onChangeTimespan={(month, year) => {
                        setMonth(month);
                        setYear(year);
                    }}
                />
            ) : (
                <Timeline
                    dataType={`${productType}s`}
                    className="mt-4 px-0 d-grid"
                    month={month}
                    year={year}
                    view="month"
                    onClick={(day) => {
                        setClickedDay(day);
                        setModalVisible(true);
                    }}
                />
            )}

            <BookingsListModal
                hour={clickedDay}
                show={isModalVisible && !clickedBookingId}
                granularity="day"
                onHide={() => setModalVisible(false)}
                onBookingClick={onBookingClick}
                dataType={`${productType}s`}
            />
        </>
    );
};

export default Month;
