import * as actionTypes from './actionTypes';

export const loadApp = () => ({
    type: actionTypes.LOAD_APP,
});

export const loadAppSucceeded = () => ({
    type: actionTypes.LOAD_APP_SUCCEEDED,
});

export const dateChanged = (date) => ({
    type: actionTypes.DATE_CHANGED,
    payload: date,
});

export const setBookingsFetchRange = (from, to) => ({
    type: actionTypes.SET_BOOKINGS_FETCH_RANGE,
    payload: {
        from, to,
    },
});
