// extracted by mini-css-extract-plugin
var _1 = "InvoicesPageHeader_categoryPriceText__-ohs2";
var _2 = "InvoicesPageHeader_cellInvoiceInfoPanel__17LzG";
var _3 = "InvoicesPageHeader_cellInvoiceInfoPanelIcon__8hFWE";
var _4 = "InvoicesPageHeader_divider__ULpDD";
var _5 = "InvoicesPageHeader_filtrationButtonsContainer__WJukX";
var _6 = "InvoicesPageHeader_footerDivider__Bod-v";
var _7 = "InvoicesPageHeader_footerIcon__mxPPr";
var _8 = "InvoicesPageHeader_invoiceInfoPanel__69FvI";
var _9 = "InvoicesPageHeader_labelBeforeValue__nYjD+";
var _a = "InvoicesPageHeader_mobileButtonsContainer__vkpnJ";
var _b = "InvoicesPageHeader_regularPriceText__yeNTS";
var _c = "InvoicesPageHeader_totalPriceText__cuD1j";
export { _1 as "categoryPriceText", _2 as "cellInvoiceInfoPanel", _3 as "cellInvoiceInfoPanelIcon", _4 as "divider", _5 as "filtrationButtonsContainer", _6 as "footerDivider", _7 as "footerIcon", _8 as "invoiceInfoPanel", _9 as "labelBeforeValue", _a as "mobileButtonsContainer", _b as "regularPriceText", _c as "totalPriceText" }
