import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as CLIENTS_ACTIONS from 'store/actions/clients/tables/notes';
import * as CLIENTS_SELECTORS from 'store/selectors/clients';

import { Button } from 'components/Common/Button';
import { CLIENT_PROP } from 'const/clients/CLIENT_PROP';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { getError, isInvalid } from 'services/validationService';
import * as styles from './ClientNotesCreate.module.scss';
import CategorySelect, { categoryInitialData } from '../CategorySelect/CategorySelect';

const T_PREFIX = 'notes';

const emptyValues = {
    text: '',
    category: categoryInitialData(),
};

const ClientNotesCreate = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const { item: { id: clientId } } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const { persistCreateItem } = useSelector(CLIENTS_SELECTORS.clientNotesSelector);

    const dispatch = useDispatch();

    const validationSchema = useMemo(() => yup.object({
        text: yup.string()
            .trim()
            .max(
                CLIENT_PROP.NOTE.MAX_LENGTH,
                tc('validationErrors.maxLength', {
                    name: t('notes.note.label'),
                    length: CLIENT_PROP.NOTE.MAX_LENGTH,
                }),
            ),
        category: yup.object({
            name: yup.string()
                .trim()
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: t('notes.category.label'),
                })),
        }),
    }), [t]);

    const onSubmit = useCallback(async ({ text, category }) => {
        let categoryId = String(category.id);
        if (categoryId.startsWith('new-')) {
            const formData = new FormData();
            formData.append('name', category.name.trim());
            const res = await dispatch(CLIENTS_ACTIONS.addClientNotesCategory({ category: formData }));
            if (res.code === 200) {
                categoryId = res?.result?.id;
            }
        }
        if (categoryId) {
            const formData = new FormData();
            formData.append('category', categoryId);
            formData.append('text', text.trim());
            const res = await dispatch(CLIENTS_ACTIONS.addClientNotesItem({ note: formData }));
            if (res.code === 200) {
                setValues(emptyValues);
            }
        }
    }, [dispatch, history]);

    const initialValues = useMemo(() => persistCreateItem[clientId] || emptyValues, [clientId]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const {
        values,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        setValues,
    } = formik;

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.setClientNotePersistCreateItem({ category: values?.category, text: values?.text, clientId }));
    }, [values?.category, values?.text, clientId]);

    const showCategory = !isInvalid('text', errors, touched) && !!values?.text?.length;

    return (
        <Form onSubmit={handleSubmit} className={styles.container}>
            <Form.Group>
                <Form.Control
                    id="text"
                    as="textarea"
                    rows={4}
                    name="text"
                    value={values.text}
                    onChange={handleChange}
                    maxLength={CLIENT_PROP.NOTE.MAX_LENGTH}
                    placeholder={t('notes.add.label')}
                />
                <Form.Control.Feedback
                    type="invalid"
                    className={classNames({
                        'd-flex': isInvalid('text', errors, touched),
                    })}
                >
                    {getError('text', errors)}
                </Form.Control.Feedback>
            </Form.Group>
            <div className={classNames(styles.category, showCategory && styles.show)}>
                <CategorySelect
                    categories={props.categories}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    category={values?.category}
                />
                <Button
                    color="yellow"
                    stretched
                    type="submit"
                    loading={props.saving}
                    disabled={!values?.text || !values?.category?.name}
                >
                    {t(`${T_PREFIX}.add.action.label`)}
                </Button>
            </div>
        </Form>
    );
};

ClientNotesCreate.propTypes = {
    categories: PropTypes.array,
    saving: PropTypes.bool,
};

ClientNotesCreate.defaultProps = {
    categories: [],
};

export default ClientNotesCreate;
