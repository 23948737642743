import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import * as ACCESS_ACTIONS from 'store/actions/access';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';

import * as SERVICES_ACTIONS from 'store/actions/services';
import WindowedSelect, { components } from 'react-windowed-select';
import TextButton from 'components/Common/TextButton/TextButton';
import { dangerTheme, theme } from 'styles/select';
import { selectWithGrayItems } from 'styles/selectStyles/selectWithGrayItems';
import * as styles from './ProfilesWindowedSelect.module.scss';

const ProfilesWindowedSelect = ({ formik }) => {
    const dispatch = useDispatch();

    const {
        values, setFieldValue, errors, touched,
    } = formik;

    useEffect(() => {
        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [
        dispatch,
    ]);

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const [value, setValue] = useState(null);
    const [accessProfiles, setAccessProfiles] = useState([]);

    const selectProfilesOptions = useMemo(() => [
        { value: null, label: '' },
        ...accessProfiles.map((gate, index) => ({
            value: gate.id,
            label: gate.name,
            index,
        })),
    ], [accessProfiles]);

    const valuesProfilesIdsSet = useMemo(() => new Set(values?.profiles?.map((c) => c.id)), [values?.profiles?.length]);

    useEffect(() => {
        dispatch(ACCESS_ACTIONS.getAccessProfiles())
            .then((profiles) => setAccessProfiles(profiles || []));
    }, []);

    const isAllProfilesSelected = values?.profiles.length === accessProfiles.length;

    const handleSetValues = useCallback((profiles) => {
        const valies = profiles.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (profiles.length > 100) {
            valies.push({ value: -1, label: tc('weekRoute.more', { count: profiles.length - 100 }) });
        }
        setValue(valies);
    }, []);

    useEffect(() => {
        if (values.profiles.length) {
            handleSetValues(values.profiles);
        }
    }, [!!values.profiles.length]);

    const handleChangeAllProfilesSelect = useCallback(({ clear }) => {
        let profiles = [];
        if (isAllProfilesSelected || clear) {
            setFieldValue('profiles', profiles);
        } else {
            profiles = accessProfiles.map((c) => ({
                id: c.id,
                name: c.name,
            }));
            setFieldValue('profiles', profiles);
        }
        handleSetValues(profiles);
    }, [isAllProfilesSelected, accessProfiles]);

    const handleChangeProfileSelect = useCallback((gate, selected) => {
        if (!gate.value) {
            return;
        }
        let profiles = [];
        if (selected) {
            profiles = [...values.profiles, { id: gate.value, name: gate.label }];
            setFieldValue('profiles', profiles);
        } else {
            profiles = values.profiles.filter((c) => c.id !== gate.value);
            setFieldValue('profiles', profiles);
        }

        handleSetValues(profiles);
    }, [values?.profiles.length]);

    const handleChangeProfilesSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllProfilesSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeProfileSelect(triggeredAction.removedValue, false);
                return;
            }
            const profiles = values.profiles.slice(0, 100);
            setFieldValue('profiles', profiles);
            handleSetValues(profiles);
        }
        if (triggeredAction.action === 'deselect-option') {
            handleChangeProfileSelect(triggeredAction.option, false);
        }
        if (triggeredAction.action === 'select-option') {
            handleChangeProfileSelect(triggeredAction.option, true);
        }
    }, [handleChangeProfileSelect, handleChangeAllProfilesSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllProfilesSelected) {
            e.preventDefault();
            setFieldValue('profiles', []);
        }
    }, [isAllProfilesSelected]);

    const isProfilesSelectInvalid = !!touched.profiles && !!errors.profiles;

    return (
        <>
            <WindowedSelect
                id="customProfiles"
                name="profiles"
                styles={selectWithGrayItems}
                theme={isProfilesSelectInvalid ? dangerTheme : theme}
                options={selectProfilesOptions}
                value={value}
                onChange={handleChangeProfilesSelect}
                isMulti
                isClearable
                backspaceRemovesValue
                onKeyDown={onKeyDownHandle}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder={t('gates.item.profilesSelect.placeholder')}
                components={{
                    MultiValue: values.profiles.length === accessProfiles.length
                        ? (props) => (
                            props.index === 0
                                ? t('gates.item.profilesSelect.allProfiles.label')
                                : null
                        )
                    // eslint-disable-next-line react/no-unstable-nested-components
                        : (props) => <components.MultiValue {...props} />,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Option: ({
                        innerRef, innerProps, data,
                    }) => {
                        const isChecked = valuesProfilesIdsSet.has(data.value);

                        return (
                            <Form.Group
                                ref={innerRef}
                                {...innerProps}
                                className={styles.option}
                            >
                                {data.value === null
                                    ? (
                                        <TextButton color="yellow" onClick={handleChangeAllProfilesSelect}>
                                            {t('gates.item.profilesSelect.allProfiles.label')}
                                        </TextButton>
                                    )
                                    : (
                                        <>
                                            <Form.Check
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={() => {}}
                                                className={styles.checkbox}
                                            />
                                            {`${data.label}`}
                                        </>
                                    )}
                            </Form.Group>
                        );
                    },
                }}
            />

            <Form.Control.Feedback
                className={isProfilesSelectInvalid && 'd-block'}
                type="invalid"
            >
                {errors.profiles}
            </Form.Control.Feedback>
        </>
    );
};

export default ProfilesWindowedSelect;
