import React, {
    useCallback, useEffect, useMemo,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
    Form, Modal, Row, Col, InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import { ChevronDown, ChevronUp } from 'react-feather';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { Button } from 'components/Common/Button';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { formatPrice } from 'helpers/formatters/formatPrice';
import ClientsSelect from 'components/clients/ClientsSelect/ClientsSelect';
import { formatTime } from 'helpers/services/formatTime';
import { useDialog } from '../../hooks/useDialog';

import DateInput from '../DateInput/DateInput';
import { Calendar, Clock } from '../Icon/Icon';
import HourInput from '../HourInput/HourInput';
import PhoneInput from '../PhoneInput/PhoneInput';
import { AddBookingConfirmModal } from '..';

import { styles, theme, dangerTheme } from '../../styles/select';

import * as actions from '../../store/actions';
import * as SERVICES_ACTIONS from '../../store/actions/services';
import { permissionsBookingSelector } from '../../store/selectors/permissions';
import * as SERVICES_SELECTORS from '../../store/selectors/services';
import { PRODUCT_TYPE } from '../../const/product/PRODUCT_TYPE';
import { T_WEEK_DAYS_MAP } from '../../const/components/addBookingModal/T_WEEK_DAYS_MAP';
import { getSchema } from '../../const/components/addBookingModal/YUP_SCHEMA';

const AddBookingModal = ({
    show,
    onHide,
    productType = 'object',
    callback,
}) => {
    const isShortFormBooking = productType === PRODUCT_TYPE.GROUP || productType === PRODUCT_TYPE.EVENT;

    const { locale } = useSelector((state) => state.locales);
    const { banAdd, banNewClient } = useSelector(
        permissionsBookingSelector(false),
    );

    const { loaded: isShopLoaded, id: shopID } = useSelector(
        (state) => state.shop,
    );
    const products = useSelector((state) => {
        if (isShortFormBooking) {
            return null;
        }

        return state[`${productType}s`][`${productType}s`];
    });
    const addBookingDetails = useSelector((state) => state.bookings.addBookingDetails);

    const subOptionsService = useSelector((state) => {
        if ([PRODUCT_TYPE.EVENT, PRODUCT_TYPE.GROUP].includes(productType)) {
            const { items: companyServices } = SERVICES_SELECTORS.companyServicesSelector(state);
            const service = companyServices.find((s) => s.id === addBookingDetails?.service?.id);

            if (!service) {
                return [];
            }

            return service.subOptions.length
                ? service.subOptions.map((subOption) => ({
                    ...subOption,
                    restrictUnpaidBookings: service.restrictUnpaidBookings,
                }))
                : [service];
        }
        return [];
    });

    const openingHours = useSelector((state) => Object.entries(state.shop.workingDays || {}).reduce(
        (openingHours, [day, hours]) => ({
            ...openingHours,
            [_.upperFirst(day)]: hours,
        }),
        {},
    ));

    const addBookingConfirmModal = useDialog(false);
    const addNewClientDialog = useDialog(false);

    const isShow = useMemo(
        () => show && !addBookingConfirmModal.visible && !addNewClientDialog.visible,
        [show, addBookingConfirmModal.visible, addNewClientDialog.visible],
    );

    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const { t } = useTranslation();

    const getBookingPaymentOptsHandler = async (
        values,
        { setSubmitting, setErrors, setValues },
    ) => {
        try {
            const options = await dispatch(
                actions.getBookingPaymentOptions({
                    booking: values,
                }),
            );

            setValues({ ...values, options });

            addBookingConfirmModal.onShow();
        } catch (err) {
            if (err?.message) {
                addToast(err.message, {
                    appearance: 'error',
                });
            } else {
                setErrors(err?.errors ?? {});
            }
        }
        setSubmitting(false);
    };

    const formik = useFormik({
        initialValues: {
            product: addBookingDetails?.product ?? null,
            service: addBookingDetails?.service ?? null,
            subOptionService: subOptionsService?.length
                ? {
                    id: subOptionsService[0].id,
                    name: subOptionsService[0].name,
                }
                : null,
            time: {
                from: addBookingDetails?.time ?? null,
            },
            client: {
                id: null,
                clientName: '',
                clientLastName: '',
                clientPhone: '',
                clientEmail: '',
                clientNote: '',
                isNew: false,
            },
            recurrence: {
                enabled: false,
                period: 1,
                infinite: true,
                end: null,
                days:
                    addBookingDetails?.time
                        ? [addBookingDetails.time.locale('en').format('dddd')]
                        : [],
            },
            sendWhatsAppMessage: false,
            description: '',
        },
        validationSchema: getSchema(),
        onSubmit: getBookingPaymentOptsHandler,
        enableReinitialize: true,
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setErrors,
    } = formik;

    const isClientSelected = Boolean(values.client?.clientId);
    const servicesAll = useMemo(
        () => values.product
            && products?.find(({ id }) => id === values.product.id)?.services,
        [products, values.product?.id],
    );

    const services = useMemo(
        () => servicesAll?.flatMap((service) => (
            service?.subOptions?.length
                ? service.subOptions.map((subOption) => ({ ...subOption, restrictUnpaidBookings: service.restrictUnpaidBookings }))
                : service)),
        [servicesAll],
    );

    const isProductInvalid = Boolean(touched.product && errors.product);
    const productTheme = isProductInvalid ? dangerTheme : theme;

    const productOptions = useMemo(
        () => (products || []).map(({ id, name }) => ({ value: id, label: name })),
        [products],
    );

    useEffect(() => {
        setFieldTouched('description', true);
        setFieldValue('description', values.client?.clientNote || '');
    }, [values.client?.clientNote]);

    const productValue = useMemo(
        () => (values.product
            ? {
                value: values.product?.id,
                label: values.product?.name,
            }
            : null),
        [values.product?.id, values.product?.name],
    );

    const shortFormBookingServiceOptions = useMemo(() => {
        const service = subOptionsService
            || services?.find(({ id }) => values.service?.id === id)?.subOptions;

        return (service || []).map(({ id, name }) => ({ value: id, label: name }));
    }, [subOptionsService, services, values.service?.id]);

    const shortFormBookingServiceValue = useMemo(
        () => (values.subOptionService
            ? {
                value: values.subOptionService?.id,
                label: values.subOptionService?.name,
            }
            : null),
        [values.subOptionService?.id, values.subOptionService?.name],
    );

    const isServiceInvalid = Boolean(touched.service && errors.service);
    const serviceTheme = isServiceInvalid ? dangerTheme : theme;

    const serviceOptions = useMemo(
        () => (services || []).map(({
            id, name, length, price,
        }) => ({
            value: id,
            label: `${name}: ${isNaN(length) ? length : formatTime({ time: Number(length), forceMany: true })} - ${formatPrice.toEuroWithComma({ amount: price })}`,
        })),
        [services],
    );

    const serviceValue = useMemo(
        () => (values.service
            ? {
                value: values.service?.id,
                label: values.service?.name,
            }
            : null),
        [values.service?.id, values.service?.name],
    );

    const isDateInvalid = Boolean(touched.time?.from && errors.time?.from);

    const isClientNameInvalid = Boolean(
        touched.client?.clientName && errors.client?.clientName,
    );
    const isClientIbanInvalid = Boolean(
        touched.client?.clientName && errors.client?.iban,
    );
    const isEmailAndPhoneFieldDisable = banNewClient || (values.client?.clientName && !values.client?.isNew);

    const isClientEmailInvalid = Boolean(
        touched.client?.clientEmail && errors.client?.clientEmail,
    );
    const isClientPhoneInvalid = Boolean(
        touched.client?.clientPhone && errors.client?.clientPhone,
    );
    const isDescriptionInvalid = Boolean(
        touched.description && errors.description,
    );
    const isRecurrencePeriodInvalid = Boolean(
        touched.recurrence?.period && errors.recurrence?.period,
    );
    const isRecurrenceEndInvalid = Boolean(
        touched.recurrence?.end && errors.recurrence?.end,
    );
    const isRecurrenceDaysInvalid = Boolean(
        touched.recurrence?.days && errors.recurrence?.days,
    );

    const recurrenceDays = useMemo(
        () => _.times(7, (i) => {
            const localeDay = moment()
                .locale(locale)
                .isoWeekday(i + 1)
                .format('dddd');
            const day = moment()
                .locale('en')
                .isoWeekday(i + 1)
                .format('dddd');
            const isChecked = values.recurrence.days.includes(day);
            const onChange = (e) => {
                if (isChecked && !e.target.checked) {
                    setFieldValue(
                        'recurrence.days',
                        values.recurrence.days.filter((weekday) => weekday !== day),
                    );
                } else {
                    setFieldValue('recurrence.days', [...values.recurrence.days, day]);
                }
            };

            return (
                <Form.Check
                    key={`day-${i}`}
                    id={`recurrence.days[${i}]`}
                    type="checkbox"
                    checked={isChecked}
                    label={localeDay}
                    onChange={onChange}
                />
            );
        }),
        [locale, values.recurrence?.days],
    );

    const onHideModal = useCallback(() => {
        dispatch(actions.clearAddBookingDetails());
        onHide();
    }, [onHide]);

    const onChangeProduct = useCallback((product) => {
        setFieldValue('service', null);
        setFieldValue('subOptionService', null);
        setFieldValue('product', {
            id: product.value,
            name: product.label,
            type: productType,
        });
    }, []);

    const onChangeShortFormBookingService = useCallback((service) => {
        setFieldValue('subOptionService', {
            id: service.value,
            name: service.label,
        });
    }, []);

    const onChangeService = useCallback((service) => {
        setFieldValue('service', {
            id: service.value,
            name: service.label,
        });
    }, []);

    const onChangeDateFrom = useCallback(
        (time) => setFieldValue('time.from', time),
        [],
    );

    const onChangeContactNumber = useCallback(
        (clientPhone) => setFieldValue('client.clientPhone', clientPhone),
        [],
    );

    const onIncrementRecurrencePeriod = useCallback(
        () => setFieldValue('recurrence.period', values.recurrence.period + 1),
        [values.recurrence.period],
    );
    const onDecrementRecurrencePeriod = useCallback(
        () => setFieldValue(
            'recurrence.period',
            Math.max(values.recurrence.period - 1, 0),
        ),
        [values.recurrence.period],
    );

    const onRecurrenceEndChange = useCallback(
        (date) => setFieldValue('recurrence.end', date),
        [],
    );

    const onAddBookingHandler = async (
        values,
        setSubmitting,
        setErrors,
        { option },
    ) => {
        setSubmitting(true);
        try {
            await dispatch(
                actions.addBooking({
                    booking: {
                        ...values,
                        paid: !!option.paid,
                        paymentType: option.type,
                        paymentEntityId: option.id,
                    },
                }),
            );

            if (callback) {
                await callback();
            }

            await dispatch(actions.clearAddBookingDetails());

            onHide();

            addToast(t('addBookingModal.bookingAddedSuccessfully'), {
                appearance: 'success',
            });
        } catch ({ message, errors }) {
            if (message) {
                addToast(message, {
                    appearance: 'error',
                });
            } else {
                setErrors(errors || {});
            }
        }
        setSubmitting(false);
        addBookingConfirmModal.onClose();
    };

    const onConfirmAction = useCallback(
        ({ option }) => onAddBookingHandler(values, setSubmitting, setErrors, { option }),
        [onAddBookingHandler, values, setSubmitting, setErrors],
    );

    const checkHourInputAvailability = () => {
        const { product, time } = values;
        const weekDay = (values.time.from || moment()).format('dddd').toLowerCase();
        const weekDayKey = T_WEEK_DAYS_MAP[weekDay];
        const defaultFrom = openingHours[_.upperFirst(weekDayKey)]?.from;
        const defaultTo = openingHours[_.upperFirst(weekDayKey)]?.to;

        if (!product?.id || !time.from) {
            return {
                from: defaultFrom,
                to: defaultTo,
            };
        }

        const foundProduct = products.find(
            (product) => product.id === values.product.id,
        );

        if (foundProduct?.hasOwnSchedule) {
            const scheduleForWeekday = foundProduct.workingDays[weekDayKey];
            if (scheduleForWeekday) {
                const { from, to } = scheduleForWeekday;
                return { from, to };
            }
            return { from: null, to: null };
        }

        return {
            from: defaultFrom,
            to: defaultTo,
        };
    };

    useEffect(() => {
        if (!shopID || !isShopLoaded) {
            return;
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [shopID, isShopLoaded]);

    if (banAdd) {
        return null;
    }

    const selectedService = useMemo(() => {
        if (services) {
            return services.find((service) => service.id === values.service?.id);
        }
        if (subOptionsService) {
            return subOptionsService.find((service) => service.id === values.subOptionService?.id);
        }
    }, [services, subOptionsService, values.service?.id, values.subOptionService?.id]);

    const clientError = (errors.client && (errors.client?.clientName || errors.client?.iban) || '');

    const isAutomaticPaymentAvailable = Boolean(values.client?.clientEmail);

    return (
        <>
            <Modal centered size="lg" show={isShow} onHide={onHideModal}>
                <Form noValidate onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title className="mb-0 font-weight-700">
                            {t('addBookingModal.title')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {!isShortFormBooking && (
                            <Form.Group>
                                <Form.Label>{t(`addBookingModal.${productType}`)}</Form.Label>

                                <Select
                                    name="product"
                                    styles={styles}
                                    theme={productTheme}
                                    options={productOptions}
                                    value={productValue}
                                    onChange={onChangeProduct}
                                    placeholder={t(
                                        `addBookingModal.pickAn${productType === 'object' ? 'Object' : 'Employee'
                                        }`,
                                    )}
                                />

                                <Form.Control.Feedback
                                    className={isProductInvalid && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.product}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        {isShortFormBooking && (
                            <Form.Group>
                                <Form.Label>{t('addBookingModal.service')}</Form.Label>

                                <Select
                                    name="service"
                                    styles={styles}
                                    theme={theme}
                                    options={shortFormBookingServiceOptions}
                                    value={shortFormBookingServiceValue}
                                    onChange={onChangeShortFormBookingService}
                                    placeholder="Sub-service"
                                />
                            </Form.Group>
                        )}

                        {!isShortFormBooking && (
                            <Form.Group>
                                <Form.Label>{t('addBookingModal.service')}</Form.Label>

                                <Select
                                    name="service"
                                    styles={styles}
                                    theme={serviceTheme}
                                    options={serviceOptions}
                                    value={serviceValue}
                                    onChange={onChangeService}
                                    placeholder={t(
                                        values.product
                                            ? 'addBookingModal.pickAService'
                                            : `addBookingModal.pleaseSelect${productType === 'object' ? 'Object' : 'Employee'
                                            }First`,
                                    )}
                                    isDisabled={!values.product}
                                />

                                <Form.Control.Feedback
                                    className={isServiceInvalid && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.service}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        {!isShortFormBooking && (
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('addBookingModal.date')}</Form.Label>

                                        <InputGroup>
                                            <DateInput
                                                name="time.from.date"
                                                value={values.time.from}
                                                placeholder={t('addBookingModal.selectDate')}
                                                onChange={onChangeDateFrom}
                                                isInvalid={isDateInvalid}
                                            />

                                            <InputGroup.Append>
                                                <InputGroup.Text
                                                    className={
                                                        isDateInvalid
                                                            ? 'border-danger text-danger'
                                                            : 'text-muted'
                                                    }
                                                >
                                                    <Calendar width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>

                                        <Form.Control.Feedback
                                            className={isDateInvalid && 'd-block'}
                                            type="invalid"
                                        >
                                            {Boolean(errors.time) && errors.time.from}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('addBookingModal.time')}</Form.Label>

                                        <InputGroup>
                                            <HourInput
                                                name="time.from.time"
                                                placeholder={t('addBookingModal.selectTime')}
                                                value={values.time.from}
                                                min={checkHourInputAvailability().from}
                                                max={checkHourInputAvailability().to}
                                                onChange={onChangeDateFrom}
                                                isInvalid={isDateInvalid}
                                            />

                                            <InputGroup.Append>
                                                <InputGroup.Text
                                                    className={
                                                        isDateInvalid
                                                            ? 'border-danger text-danger'
                                                            : 'text-muted'
                                                    }
                                                >
                                                    <Clock width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>

                                        <Form.Control.Feedback
                                            className={isDateInvalid && 'd-block'}
                                            type="invalid"
                                        >
                                            {Boolean(errors.time) && errors.time.from}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col md={12}>
                                <ClientsSelect
                                    formik={formik}
                                    error={clientError}
                                    invalid={isClientNameInvalid || isClientIbanInvalid}
                                    title={(
                                        <div className="font-size-22 font-weight-700">
                                            {t('addBookingModal.client')}
                                        </div>
                                    )}
                                />
                            </Col>
                        </Row>

                        {isClientSelected && (
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>{t('addBookingModal.email')}</Form.Label>

                                        <Form.Control
                                            disabled={isEmailAndPhoneFieldDisable}
                                            name="client.clientEmail"
                                            value={values.client?.clientEmail}
                                            onChange={handleChange}
                                            isInvalid={isClientEmailInvalid}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.client && errors.client?.clientEmail}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addBookingModal.contactNumber')}
                                        </Form.Label>

                                        <PhoneInput
                                            disabled={isEmailAndPhoneFieldDisable}
                                            name="client.clientPhone"
                                            value={values.client?.clientPhone}
                                            onChange={onChangeContactNumber}
                                            isInvalid={isClientPhoneInvalid}
                                        />

                                        <Form.Control.Feedback
                                            className={isClientPhoneInvalid && 'd-block'}
                                            type="invalid"
                                        >
                                            {errors.client && errors.client?.clientPhone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}

                        <Form.Group>
                            <Form.Label htmlFor="note" className="d-flex align-items-center">
                                {t('addBookingModal.note.label')}
                                <InfoTooltip
                                    text={t('addBookingModal.note.tooltip')}
                                    placement="bottom"
                                />
                            </Form.Label>
                            <Form.Control
                                name="description"
                                as="textarea"
                                rows={3}
                                value={values.description}
                                onChange={handleChange}
                                isInvalid={isDescriptionInvalid}
                                disabled={!isClientSelected}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {productType !== 'event' && (
                            <Form.Group>
                                <Form.Check
                                    id="recurrence.enabled"
                                    label={t('addBookingModal.recurring')}
                                    name="recurrence.enabled"
                                    checked={values.recurrence.enabled}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        )}

                        {values.recurrence.enabled && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {t('editBookingModal.chooseEveryNumberOfWeeks')}
                                    </Form.Label>

                                    <InputGroup>
                                        <Form.Control
                                            type="number"
                                            name="recurrence.period"
                                            value={values.recurrence.period || ''}
                                            onChange={handleChange}
                                            isInvalid={isRecurrencePeriodInvalid}
                                        />

                                        <InputGroup.Append className="border-left">
                                            <div
                                                className={classNames(
                                                    'd-flex flex-column border rounded-right',
                                                    {
                                                        'border-danger': isRecurrencePeriodInvalid,
                                                        'border-light': !isRecurrencePeriodInvalid,
                                                    },
                                                )}
                                            >
                                                <button
                                                    name="recurrence.period.add"
                                                    type="button"
                                                    className={classNames(
                                                        'd-flex justify-content-center bg-transparent border-0 p-0',
                                                        {
                                                            'text-danger': isRecurrencePeriodInvalid,
                                                            'text-darker-light': !isRecurrencePeriodInvalid,
                                                        },
                                                    )}
                                                    style={{ width: 23, height: 19 }}
                                                    onClick={onIncrementRecurrencePeriod}
                                                >
                                                    <ChevronUp size={16} />
                                                </button>

                                                <button
                                                    name="recurrence.period.subtract"
                                                    type="button"
                                                    className={classNames(
                                                        'd-flex justify-content-center bg-transparent border-right-0 border-bottom-0 border-left-0 p-0',
                                                        {
                                                            'border-danger text-danger':
                                                                isRecurrencePeriodInvalid,
                                                            'text-darker-light': !isRecurrencePeriodInvalid,
                                                        },
                                                    )}
                                                    style={{ width: 23, height: 19 }}
                                                    onClick={onDecrementRecurrencePeriod}
                                                >
                                                    <ChevronDown size={16} />
                                                </button>
                                            </div>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    <Form.Control.Feedback
                                        className={isRecurrencePeriodInvalid && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.recurrence && errors.recurrence.period}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mt-4">
                                    <Form.Check
                                        id="recurrence.infinite"
                                        label={t('addBookingModal.infinite')}
                                        name="recurrence.infinite"
                                        checked={values.recurrence.infinite}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                {!values.recurrence.infinite && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('addBookingModal.endRecurring')}</Form.Label>

                                        <InputGroup>
                                            <DateInput
                                                name="recurrence.end"
                                                className={isRecurrenceEndInvalid && 'border-danger'}
                                                value={values.recurrence.end}
                                                placeholder={t('addBookingModal.selectDate')}
                                                onChange={onRecurrenceEndChange}
                                                isInvalid={isRecurrenceEndInvalid}
                                            />

                                            <InputGroup.Append>
                                                <InputGroup.Text
                                                    className={classNames({
                                                        'border-danger text-danger': isRecurrenceEndInvalid,
                                                        'text-muted': !isRecurrenceEndInvalid,
                                                    })}
                                                >
                                                    <Calendar width={18} />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>

                                        <Form.Control.Feedback
                                            className={isRecurrenceEndInvalid && 'd-block'}
                                            type="invalid"
                                        >
                                            {errors.recurrence && errors.recurrence.end}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}

                                {!isShortFormBooking && (
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addBookingModal.daysRecurring')}
                                        </Form.Label>

                                        {recurrenceDays}

                                        <Form.Control.Feedback
                                            className={isRecurrenceDaysInvalid && 'd-block'}
                                            type="invalid"
                                        >
                                            {errors.recurrence && errors.recurrence.days}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </>
                        )}
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-between">
                        <Button
                            color="outline"
                            onClick={onHideModal}
                        >
                            {t('addBookingModal.cancel')}
                        </Button>

                        <Button color="yellow" type="submit" disabled={isSubmitting} loading={isSubmitting}>
                            {t('addBookingModal.makeABooking')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {addBookingConfirmModal.visible && (
                <AddBookingConfirmModal
                    isShow={addBookingConfirmModal.visible}
                    hide={addBookingConfirmModal.onClose}
                    loading={isSubmitting}
                    restrictUnpaidBookings={selectedService?.restrictUnpaidBookings}
                    paymentOpts={values.options}
                    confirmAction={onConfirmAction}
                    price={selectedService?.price}
                    recurrence={values.recurrence.enabled}
                    isAutomaticPaymentAvailable={isAutomaticPaymentAvailable}
                />
            )}
        </>
    );
};

export default AddBookingModal;
