/* eslint-disable import/prefer-default-export */
import color from 'color';

export const selectWithGrayItems = {
    control: (provided, { isFocused }) => ({
        ...provided,
        maxHeight: 110,
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderColor: `${provided.borderColor} !important`,
        borderRadius: 4,
        boxShadow: isFocused && `0 0 0 0.2rem ${color(provided.borderColor).fade(0.5).string()}`,
        fontSize: 14,
        scrollbarColor: 'transparent',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#202024',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 8,
        paddingRight: 0,

    }),
    indicatorSeparator: () => null,
    indicatorsContainer: (provided) => {
        const copy = {
            ...provided,
            position: 'sticky',
            top: 6,
            alignSelf: 'flex-start',
            alignItems: 'center',
        };
        return copy;
    },
    dropdownIndicator: (provided, state) => {
        const copy = {
            ...provided,
        };
        if (state.selectProps.menuIsOpen) {
            copy.transform = 'rotate(180deg)';
            copy.transition = 'all 150ms';
        }
        return copy;
    },
    multiValue: (provided, state) => {
        const { data } = state;
        const { isFixed } = data;
        const copy = {
            ...provided,
            backgroundColor: '#818E9C1A',
            border: '1px solid #D8E1EA',
            borderRadius: 4,
        };
        if (isFixed) {
            copy.paddingRight = '6px';
        }
        return copy;
    },
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#818E9C',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#B3BDC8',
        ':hover': {
            backgroundColor: 'transparent',
            color: '#566270',
        },
    }),
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
};
