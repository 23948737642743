import React from 'react';

const CardIcon = ({ width = '24', height = '24' }) => (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.25012 7C2.25012 5.34315 3.59327 4 5.25012 4H19.2501C20.907 4 22.2501 5.34315 22.2501 7V8H2.25012V7ZM2.25012 10H22.2501V17C22.2501 18.6569 20.907 20 19.2501 20H5.25012C3.59327 20 2.25012 18.6569 2.25012 17V10ZM6.25012 15C5.69784 15 5.25012 15.4477 5.25012 16C5.25012 16.5523 5.69784 17 6.25012 17H11.2501C11.8024 17 12.2501 16.5523 12.2501 16C12.2501 15.4477 11.8024 15 11.2501 15H6.25012Z" fill="#202024" />
    </svg>

);

export default CardIcon;
