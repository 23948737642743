// extracted by mini-css-extract-plugin
var _1 = "AddClientDialog_blockButton__l3Fkg";
var _2 = "AddClientDialog_blockIcon__rV1SO";
var _3 = "AddClientDialog_checkBox__2RSr4";
var _4 = "AddClientDialog_checkBoxLabel__HaSgo";
var _5 = "AddClientDialog_delete__Q0iS8";
var _6 = "AddClientDialog_formGroup__Jm8YC";
var _7 = "AddClientDialog_horizontal__uZeUq";
var _8 = "AddClientDialog_note__JW0Ux";
var _9 = "AddClientDialog_visible__oX4zW";
export { _1 as "blockButton", _2 as "blockIcon", _3 as "checkBox", _4 as "checkBoxLabel", _5 as "delete", _6 as "formGroup", _7 as "horizontal", _8 as "note", _9 as "visible" }
