import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';

import * as BUNDLES_TYPES from '../../actions/bundles/actionTypes';

import * as BUNDLES_ACTIONS from '../../actions/bundles';
import * as TOAST_ACTIONS from '../../actions/toast';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import {
    API_ADMIN_BUNDLE_LIST_ROUTE,
} from '../../../const/API_URL';

const INFINITY_SYMBOL = '∞';

const HIDE_INACTIVE = {
    HIDE: 1,
    SHOW: 0,
};

function* getCompanyBundles(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(BUNDLES_ACTIONS.setCompanyBundlesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: 'GET',
            url: API_ADMIN_BUNDLE_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                hideInactive: HIDE_INACTIVE.HIDE,
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === 200) {
            const { items } = result;
            const companyBundles = items.map((item) => {
                const companyBundle = camelize(item);
                return {
                    id: companyBundle.id,
                    name: companyBundle.name,
                    maxUsages: {
                        value: companyBundle.maxUsages === INFINITY_SYMBOL ? null : companyBundle.maxUsages,
                        unlimited: companyBundle.maxUsages === INFINITY_SYMBOL,
                    },
                    validity: {
                        ...companyBundle.validity,
                        value: companyBundle.validity.value,
                        unit: companyBundle.validity.unit,
                        unlimited: !companyBundle.validity.value && !companyBundle.validity.unit,
                    },
                    price: companyBundle.price,
                    status: companyBundle.status,
                    services: companyBundle.services.map((s) => ({
                        id: s.id,
                        name: s.name,
                    })),
                    description: companyBundle.description,
                };
            });
            yield put(BUNDLES_ACTIONS.setCompanyBundles({ companyBundles }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'companyBundles.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(BUNDLES_ACTIONS.setCompanyBundlesLoading({ loading: false }));
    }
}

export const companyBundlesSaga = [
    takeLatest(BUNDLES_TYPES.COMPANY_BUNDLES_GET, getCompanyBundles),
];
