import React from 'react';

const Bee = ({ className }) => (
    <svg
        width="69"
        height="70"
        fill="none"
        className={className}
    >
        <path
            d="M56.6436 26.4319C54.0746 24.2877 50.7099 23.3338 47.3915 23.8091C45.8978 23.9959 44.5214 24.7109 43.5133 25.8239C43.4248 25.9338 43.4194 26.0885 43.4999 26.2043C43.5804 26.3201 43.7278 26.3695 43.8623 26.3257C46.6069 25.4023 49.6 25.541 52.2466 26.7142C54.4113 27.5368 55.8992 29.5358 56.0594 31.8368C55.9886 33.6201 55.0391 35.2539 53.5216 36.2041C51.7601 37.3912 49.6735 38.008 47.5466 37.9704C42.1559 37.9704 38.9611 34.7709 35.2526 31.0598C32.9209 28.4466 30.1431 26.2647 27.0476 24.615C23.3922 22.9199 19.2383 22.6219 15.3765 23.7777C10.9116 25.1627 8.02719 28.3236 8.02719 31.8271C8.05491 33.9528 9.01372 35.9604 10.6523 37.3237C13.1576 39.3531 16.4168 40.2158 19.6038 39.6932C21.1251 39.5564 22.5684 38.9614 23.7414 37.9873C23.8486 37.8862 23.8731 37.7257 23.801 37.5975C23.7289 37.4693 23.5785 37.4062 23.436 37.4444C20.7872 38.2502 17.9354 38.0788 15.4032 36.9618C13.2375 36.1386 11.7488 34.1389 11.5879 31.8368C11.6592 30.0536 12.6085 28.42 14.1258 27.4694C15.8885 26.2853 17.9744 25.6695 20.1007 25.7056C25.4915 25.7056 28.6886 28.9051 32.3899 32.6089V32.6089C35.638 35.8567 39.3199 39.5364 44.9943 40.3929C45.827 40.5185 46.668 40.5814 47.5103 40.5811C49.1264 40.5863 50.7342 40.3512 52.2805 39.8838C56.743 38.4964 59.6274 35.3379 59.6274 31.832C59.4907 29.6793 58.3967 27.6995 56.6436 26.4319V26.4319Z"
            fill="currentColor"
        />
        <path
            d="M33.8248 41.7627C30.4703 41.7559 27.1612 42.5354 24.1655 44.0381C23.9684 44.9615 23.8677 45.9027 23.865 46.8467C23.865 47.1942 23.9038 47.5272 23.9304 47.865C30.2718 45.2751 37.3827 45.2751 43.7241 47.865C43.7483 47.5272 43.7895 47.1942 43.7895 46.8467C43.786 45.9026 43.6845 44.9614 43.4865 44.0381C40.4902 42.5348 37.1802 41.7553 33.8248 41.7627Z"
            fill="currentColor"
        />
        <path
            d="M24.8588 51.9335C25.3109 53.0625 25.8847 54.1394 26.5701 55.1451C31.3117 53.7842 36.343 53.7842 41.0845 55.1451C41.769 54.1371 42.3427 53.0588 42.7958 51.9287C37.1058 49.3115 30.5488 49.3115 24.8588 51.9287V51.9335Z"
            fill="currentColor"
        />
        <path
            d="M29.4958 58.6218C30.8061 59.9078 32.2593 61.0411 33.8273 61.9998C35.3947 61.0403 36.8478 59.9071 38.1588 58.6218C35.3014 58.033 32.3532 58.033 29.4958 58.6218H29.4958Z"
            fill="currentColor"
        />
        <path
            d="M25.7388 10.8222C26.0318 10.8267 26.3065 10.6809 26.4659 10.4361C27.8904 10.6823 29.02 11.7685 29.3165 13.1772L28.2451 13.7925C27.1107 14.445 26.4108 15.6491 26.4078 16.9534C26.4078 16.9534 26.2744 21.2966 26.9822 21.2966C27.69 21.2966 28.8898 16.9534 28.8898 16.9534C28.9575 16.5584 29.1865 16.2089 29.5225 15.9882L33.8274 13.5126L38.0595 15.9472C38.4337 16.1937 38.6889 16.5831 38.7649 17.0233C38.7649 17.0233 39.9623 21.2845 40.6701 21.2845C41.3778 21.2845 41.2421 17.0233 41.2421 17.0233C41.2403 15.6696 40.5156 14.4188 39.3393 13.7394L38.3334 13.1603C38.6319 11.7528 39.7623 10.6687 41.1863 10.4241C41.3467 10.6678 41.6208 10.8133 41.9135 10.8101C42.2085 10.8263 42.4883 10.6789 42.6406 10.427C42.793 10.175 42.793 9.85991 42.6406 9.60799C42.4883 9.35607 42.2085 9.20864 41.9135 9.22484C41.5625 9.22122 41.2454 9.43321 41.1161 9.75809C39.4776 10.0188 38.1475 11.2162 37.7226 12.8128L34.4648 10.938C34.0686 10.7114 33.5813 10.7114 33.185 10.938L29.937 12.808C29.506 11.216 28.1784 10.0227 26.5435 9.75809C26.4127 9.43328 26.0951 9.22158 25.7436 9.22484C25.4486 9.20864 25.1689 9.35607 25.0165 9.60799C24.8641 9.85991 24.8641 10.175 25.0165 10.427C25.1689 10.6789 25.4486 10.8263 25.7436 10.8101L25.7388 10.8222Z"
            fill="currentColor"
        />
    </svg>
);

export default Bee;
