import React from 'react';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LOCALE_NAMESPACE } from '../../const/translations/LOCALE_NAMESPACE';
import { HEADER } from '../../const/e2e/TEST_ATTRIBUTES';

function ProfileBadge({ role, size = 40, onlyAvatar = false }) {
    const { avatar, name } = useSelector((state) => state.user);

    const { t } = useTranslation(LOCALE_NAMESPACE.USER);

    return (
        <>
            {avatar ? (
                <img
                    style={{
                        width: size,
                        height: size,
                        borderWidth: 2,
                    }}
                    className="border border-muted rounded-circle"
                    src={avatar}
                    alt=""
                />
            ) : (
                <div style={{
                    position: 'relative',
                    top: -2,
                }}
                >
                    <Skeleton
                        width={size}
                        height={size}
                        circle
                    />
                </div>
            )}

            {!onlyAvatar && (
                name ? (
                    <div
                        className="d-flex flex-column justify-content-center align-items-start ml-2"
                        data-testid={HEADER.nameAndRoleBoxSelector}
                    >
                        <p className="mb-0 font-weight-bold text-left" style={{ lineHeight: '20px' }}>{name}</p>
                        {role
                        && (
                            <span className="text-muted text-left small text-nowrap">
                                {_.upperFirst(t(`roles.${role}`))}
                            </span>
                        )}
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-start ml-2">
                        <Skeleton
                            className="d-flex"
                            width={150}
                            height={16}
                        />
                        <Skeleton
                            className="d-flex"
                            width={50}
                            height={14}
                        />
                    </div>
                )
            )}
        </>
    );
}

export default ProfileBadge;
