import color from 'color';

export const PHONE_CODE_SELECT = {
    control: (provided, { isFocused }) => ({
        ...provided,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 8,
        boxShadow: isFocused && `0 0 0 0.2rem ${color(provided.borderColor).fade(0.5).string()}`,
        height: '100%',
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
    }),
    singleValue: (provided) => ({
        ...provided,
        width: 'min-content',
        padding: 0,
        top: 0,
        position: 'static',
        transform: 'none',
        fontWeight: 800,
    }),
    indicatorSeparator: () => null,
    menu: (provided) => ({
        ...provided,
        minWidth: 300,
    }),
};
