import React, { useRef } from 'react';
import cl from 'classnames';
import { Sad, Smile, Speechless } from '../Icon/Icon';

const Feedback = ({ feedback, size = 24, classNames }) => {
    const icon = useRef(null);

    switch (Number(feedback.rating)) {
    case 1:
        icon.current = {
            component: <Sad width={size} height={size} />,
            color: 'text-danger',
        };
        break;
    case 2:
        icon.current = {
            component: <Speechless width={size} height={size} />,
            color: 'text-warning',
        };
        break;
    default:
        icon.current = {
            component: <Smile width={size} height={size} />,
            color: 'text-success',
        };
    }

    return (
        <div className={cl(classNames, icon.current.color)}>
            {icon.current.component}
        </div>
    );
};

export default Feedback;
