import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconButton from 'components/Common/IconButton/IconButton';
import { Badge } from 'components/Common/Badge';
import moment from 'moment';
import { ACCESS_GATE_EDIT_ITEM_PAGE, ACCESS_LOGS_SELECTED_GATE_PAGE } from 'const/CLIENT_URL';

import { Row } from 'components/Common/Row';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { Tooltip } from '../../../Common/Tooltip';
import { ListTableBodyCell } from '../../../Common/NewTable/ListTableBodyCell';

import {
    EditIcon, TableIcon,
} from '../../../Icon/Icon';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import ACCESS_GATES_STATUS from '../../../../const/access/ACCESS_GATES_STATUS';

const statusClasses = {
    [ACCESS_GATES_STATUS.INACTIVE]: 'red',
    [ACCESS_GATES_STATUS.CREATED]: 'gray',
    [ACCESS_GATES_STATUS.ACTIVE]: 'green',
};

const T_PREFIX = 'gates.list.table.body.rows';

const GatesTableBodyRow = (props) => {
    const {
        gate, onClick,
    } = props;

    const {
        id,
        status,
        name,
        shop,
        lastActivity,
    } = gate || {};

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { locale } = useSelector((state) => state.locales);

    const onInfoClick = useCallback((e) => {
        e.stopPropagation();
        if (!id) {
            return;
        }

        onClick({ gate });
    }, [id, onClick]);

    return (
        <ListTableBodyRow onClick={onInfoClick}>
            <ListTableBodyCell>{name}</ListTableBodyCell>
            <ListTableBodyCell>
                <Badge size="small" color={statusClasses[status]}>
                    {t(`status.${status}`)}
                </Badge>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">{shop.name}</ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">{lastActivity ? moment.unix(lastActivity).locale(locale).format('DD MMM YYYY · HH:mm') : '-'}</ListTableBodyCell>
            <ListTableBodyCell>
                <Row gap={8}>
                    <Tooltip
                        tooltip={t(`${T_PREFIX}.actions.openLogs`)}
                        placement="bottom"
                        forButton
                    >
                        <IconButton
                            href={ACCESS_LOGS_SELECTED_GATE_PAGE({ id })}
                            color="gray"
                        >
                            <TableIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        tooltip={t(`${T_PREFIX}.actions.edit`)}
                        placement="bottom"
                        forButton
                    >
                        <IconButton
                            href={ACCESS_GATE_EDIT_ITEM_PAGE({ id })}
                            color="gray"
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Row>
            </ListTableBodyCell>
        </ListTableBodyRow>
    );
};

GatesTableBodyRow.propTypes = {
    gate: PropTypes.object,
};

GatesTableBodyRow.defaultProps = {
    gate: null,
};

export default GatesTableBodyRow;
