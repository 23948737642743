import React, { useCallback } from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Components
import { Modal } from 'react-bootstrap';

import Skeleton from 'react-loading-skeleton';

// Utils
import moment from 'moment';
import classNames from 'classnames';
import _, { uniq } from 'lodash';
import * as actions from 'store/actions';
import { permissionsGroupSessionSelector, permissionsResourceSelector } from 'store/selectors/permissions';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Button } from 'components/Common/Button';
import {
    ConfirmationModal, Feedback, BookingModal, EditBookingModal, OverrideGroupModal, BookingPaymentType,
} from '..';
import {
    Clock, Pin, Time, UserStar,
} from '../Icon/Icon';
import { getFullName } from '../../helpers/services/getFullName';
import { CLIENTS_LIST_ID_PAGE, MESSAGES_ADD_PAGE } from '../../const/CLIENT_URL';

const GroupDetailsModal = ({ groupSessionIDWithTimeStamp, show, onHide }) => {
    const session = useSelector((state) => state.groups.sessions && state.groups.sessions.find((session) => session.id === groupSessionIDWithTimeStamp?.id && session.time.from === groupSessionIDWithTimeStamp?.timestamp));
    const bookings = useSelector((state) => session && state.groups.bookings);
    const areBookingDetailsPresent = useSelector((state) => !!state.bookings?.addBookingDetails);
    const { locale } = useSelector((state) => state.locales);
    const {
        banCancel,
        banBooking,
        banOverride,
        banSendNotification,
    } = useSelector(permissionsGroupSessionSelector);
    const { banEdit } = useSelector(permissionsResourceSelector);

    const dispatch = useDispatch();
    const history = useHistory();

    const [clickedBookingID, setClickedBookingID] = useState(null);
    const [editBooking, setEditBooking] = useState(false);
    const [overrideGroup, setOverrideGroup] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [isCancelingSession, setIsCancellingSession] = useState(false);

    useEffect(() => {
        if (!session) {
            return;
        }

        dispatch(actions.getGroupSessionBookings(session.id, session.time.from));
    }, [session?.places?.available]);

    const { addToast } = useToasts();

    const { t } = useTranslation();

    const cancelHandler = async () => {
        if (banCancel || isCancelingSession) {
            return;
        }

        setIsCancellingSession(true);
        try {
            await dispatch(actions.cancelGroupSession({
                id: session?.id,
                timestamp: (session?.time.from || 0) / 1000,
            }));
            addToast(t('groupDetailsModal.statusGroupSessionSuccess'), {
                appearance: 'success',
            });
            setCancelModalVisible(false);
        } catch ({ message }) {
            if (message) {
                addToast(message, {
                    appearance: 'error',
                });
            }
        } finally {
            setIsCancellingSession(false);
        }
    };

    const handleRedirectClient = useCallback(({ clientId }) => () => {
        if (!clientId) {
            return;
        }
        history.push(CLIENTS_LIST_ID_PAGE({ clientId }));
    }, [history.push]);

    return (
        <>
            <Modal
                size="lg"
                show={show && !clickedBookingID && !areBookingDetailsPresent && !overrideGroup && !cancelModalVisible}
                onHide={onHide}
                scrollable
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0 font-weight-600" data-testid="data-test-group-details-modal-title">
                        {t('groupDetailsModal.title')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column">
                    <h3 className="font-weight-normal pb-2">
                        {session?.time?.from && moment(session.time.from).locale(locale).format('D MMM YYYY')}
                    </h3>

                    {session?.group?.name && (
                        <span className="font-size-17">{session.group.name}</span>
                    )}
                    {session?.service?.name && (
                        <span className="font-size-14 font-weight-bold text-muted">{session.service.name}</span>
                    )}

                    <div className="d-flex flex-row mt-3 mx-n2 w-100">
                        <div className="d-flex flex-sm-row flex-column">
                            <p className="font-size-14 font-weight-bold text-muted mx-2 mb-0">
                                {formatPrice.toEuroWithComma({ amount: session?.price })}
                            </p>

                            <p className="font-size-14 font-weight-bold text-muted mx-2 mb-0">
                                <Clock
                                    width={14}
                                    className="position-relative mr-1"
                                    style={{ top: -1 }}
                                    strokeWidth={3}
                                />
                                {session && `${moment(session.time.from).format('HH:mm')} - ${moment(session.time.to).format('HH:mm')}`}
                            </p>
                            <p className="font-size-14 font-weight-bold text-muted mx-2 mb-0">
                                <UserStar
                                    width={14}
                                    className="position-relative mr-1"
                                    style={{ top: -1 }}
                                    strokeWidth={3}
                                />
                                {session?.product?.name ?? '-'}
                            </p>
                        </div>
                    </div>

                    <p className="flex-grow-1 font-size-14 font-weight-bold text-muted mt-3 mt-sm-1">
                        <Pin className="mr-1" width={18} />
                        <span>{`${session?.address.street}, ${session?.address.zip} ${session?.address.city}`}</span>
                    </p>

                    <div className="d-flex align-items-center mt-4">
                        <h4 className="mb-0 font-size-20 font-weight-bold">{t('groupDetailsModal.seats')}</h4>
                        <span className="ml-2 font-weight-bold font-size-16 text-muted" data-testid="data-test-total-seats">
                            {session && `${session.places.total - session.places.available}/${session.places.total}`}
                        </span>
                    </div>

                    {session && bookings?.map((
                        {
                            id,
                            client,
                            payment: {
                                paid,
                                isInProgress,
                                usedBundle,
                                finalCost,
                                type,
                            },
                            feedback,
                            seats,
                            recurrence,
                            notPresent,
                            time: {
                                from,
                            },
                        },
                    ) => (
                        <div
                            key={`booking-${id}`}
                            className={classNames({
                                'border-danger': !usedBundle && !isInProgress && !paid && finalCost !== 0,
                                'border-success': usedBundle || (!isInProgress && paid) || finalCost === 0,
                                'border-info': !usedBundle && isInProgress,
                                'border-dark': notPresent,
                            }, 'd-flex flex-sm-row flex-column justify-content-between mt-3 pl-3 border-left border-5')}
                            data-testid={`data-test-row-${getFullName({ client })}`}
                        >
                            <div
                                className="d-flex align-items-center justify-content-between justify-content-sm-start"
                            >
                                <button
                                    type="button"
                                    className="p-0 mr-3"
                                    onClick={handleRedirectClient({ clientId: client.id })}
                                    data-testid="data-test-client"
                                >
                                    {getFullName({ client })}
                                </button>
                                <div className="d-flex">
                                    {type && (
                                        <BookingPaymentType
                                            className="text-muted mr-3"
                                            width={24}
                                            type={type}
                                        />
                                    )}
                                    {feedback && (
                                        <Feedback
                                            feedback={feedback}
                                            size={24}
                                            classNames="mr-0 mr-sm-3"
                                        />
                                    )}
                                </div>
                                <span
                                    className="d-none d-sm-inline text-muted text-uppercase font-size-12 cursor-pointer"
                                    onClick={() => setClickedBookingID({ id, timestamp: from })}
                                    data-testid="data-test-show-info"
                                >
                                    <strong>{t('groupSchedule.showInfo')}</strong>
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                {recurrence?.enabled && <Time className="mr-3" width={14} height={14} />}
                                <span className="text-muted mr-3" data-testid="data-test-seats">
                                    {t('groupDetailsModal.seat', { count: seats })}
                                </span>
                                <span data-testid="data-test-contact-number">
                                    {client?.contactNumber}
                                </span>
                            </div>
                            <div className="d-block d-sm-none">
                                <span
                                    className="text-muted text-uppercase font-size-12 cursor-pointer"
                                    onClick={() => setClickedBookingID({ id, timestamp: from })}
                                >
                                    <strong>{t('groupSchedule.showInfo')}</strong>
                                </span>
                            </div>
                        </div>
                    ))}

                    {(!session || !bookings) && _.times(session && session.places.total - session.places.available, (i) => (
                        <div
                            key={`booking-${i}`}
                            className="d-flex justify-content-between mt-3 px-3 border-left border-5"
                        >
                            <div>
                                <span className="mr-3">
                                    <Skeleton width={125 + i * 3 * (i % 2 === 0 ? 1 : -1)} />
                                </span>
                            </div>

                            <div>
                                <span className="mr-3">
                                    <Skeleton width={75} />
                                    <span><Skeleton width={100} /></span>
                                </span>
                            </div>
                        </div>
                    ))}
                    <div className={`d-flex justify-content-${session?.isCancelled ? 'end' : 'between'} mt-3`}>
                        {!session?.isCancelled && !banBooking && (
                            <Button
                                color="outline"
                                size="small"
                                onClick={() => {
                                    if (banBooking || !session?.time || session?.isFull) return;

                                    dispatch(actions.setAddBookingDetails(moment(session.time.from), {
                                        id: session.group.id,
                                        name: session.group.name,
                                        type: 'group',
                                        groupSessionId: session.id,
                                    }, {
                                        id: session.service.id,
                                        name: session.service.name,
                                    }));
                                }}
                                disabled={session?.isFull || banBooking}
                            >
                                {t('agendaRoute.addBooking')}
                            </Button>
                        )}
                        {!banSendNotification && (
                            <Button
                                disabled={banSendNotification || !bookings?.length}
                                id="sendMessage"
                                size="small"
                                onClick={() => {
                                    const selectedClientsIds = uniq(bookings?.map(({ client }) => client.id));
                                    history.push(MESSAGES_ADD_PAGE, { selectedClientsIds });
                                }}
                            >
                                {t('groupDetailsModal.sendMessage')}
                            </Button>
                        )}
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                        {!session?.isCancelled && !banOverride && (
                            <Button
                                color="outline"
                                size="small"
                                onClick={() => {
                                    if (banOverride) return;
                                    setOverrideGroup(true);
                                }}
                                className="mr-3"
                                disabled={banOverride}
                            >

                                {t('groupDetailsModal.override')}
                                {' '}
                                {t('groupDetailsModal.groupSession')}
                            </Button>
                        )}

                        {!banCancel && (
                            <Button
                                color={session?.isCancelled ? 'green' : 'red'}
                                size="small"
                                onClick={() => {
                                    if (banCancel) return;
                                    setCancelModalVisible(true);
                                }}
                                disabled={banCancel}
                            >
                                {t(`groupDetailsModal.${session?.isCancelled ? 'reactivate' : 'cancel'}`)}
                                {' '}
                                {t('groupDetailsModal.groupSession')}
                            </Button>
                        )}
                    </div>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        name="close"
                        color="outline"
                        onClick={() => setTimeout(onHide, 100)}
                    >
                        {t('groupDetailsModal.close')}
                    </Button>

                    {!banEdit && (
                        <div className="mx-n2">
                            <Button
                                className="mx-2"
                                href={`/agenda/groups/edit/${session && session.group.id}`}
                            >
                                {t('groupDetailsModal.edit')}
                            </Button>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>

            <BookingModal
                bookingIDWithTimestamp={clickedBookingID}
                show={clickedBookingID && !editBooking}
                onHide={() => setClickedBookingID(null)}
                onEditChange={() => setEditBooking(true)}
                productType="group"
            />

            <EditBookingModal
                bookingIDWithTimestamp={clickedBookingID}
                onBookingClick={setClickedBookingID}
                show={editBooking}
                onHide={() => setEditBooking(false)}
                productType="group"
            />

            <OverrideGroupModal
                show={overrideGroup}
                onHide={() => setOverrideGroup(false)}
                id={session?.id}
                timestamp={(session?.time.from || 0) / 1000}
                registrations={session?.places.total}
            />

            <ConfirmationModal
                isShow={cancelModalVisible}
                hide={() => setCancelModalVisible(false)}
                loading={isCancelingSession}
                confirmAction={cancelHandler}
                deleteColor={session?.isCancelled ? 'green' : 'red'}
                titleText={`${t(`groupDetailsModal.${session?.isCancelled ? 'reactivate' : 'cancel'}`)} ${t('groupDetailsModal.groupSession')}`}
                bodyText={t(`groupDetailsModal.areYouSureYouWantTo${session?.isCancelled ? 'Reactivate' : 'Cancel'}Session`)}
                deleteText={t(`groupDetailsModal.${session?.isCancelled ? 'reactivate' : 'cancel'}`)}
                dismissText={t('groupDetailsModal.back')}
            />
        </>
    );
};

export default GroupDetailsModal;
