import {
    select,
} from 'redux-saga/effects';

import * as AUTH_SELECTORS from '../../store/selectors/auth';
import * as COMPANY_SELECTORS from '../../store/selectors/company';
import * as LOCALES_SELECTORS from '../../store/selectors/locales';

export function* getDefaultHeaders() {
    const { accessToken, tokenType } = yield select(AUTH_SELECTORS.authSelector);
    const { apiKey } = yield select(COMPANY_SELECTORS.companySelector);
    const locale = yield select(LOCALES_SELECTORS.shortLocaleSelector);

    return {
        'X-Auth-Token': `${tokenType} ${accessToken}`,
        'company-api-key': apiKey,
        locale,
    };
}
