import { clientsListSaga } from './clientsList';
import { clientItemSaga } from './clientItem';
import { clientBookingsSaga } from './clientTables/clientBookings';
import { clientBundlesSaga } from './clientTables/clientBundles';
import { clientSubscriptionSaga } from './clientTables/clientSubscriptions';
import { clientNotesSaga } from './clientTables/clientNotes';

export const clientsSaga = [
    ...clientsListSaga,
    ...clientItemSaga,
    ...clientBookingsSaga,
    ...clientBundlesSaga,
    ...clientSubscriptionSaga,
    ...clientNotesSaga,
];
