import React from 'react';
import PropTypes from 'prop-types';

const PenIcon = (props) => {
    const { className } = props;

    return (
        <svg className={className} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path stroke="currentColor" d="M-1421.5-16.5h1519v49h-1519z" />
                <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                    <path d="M1 15.5h14M10.5.5l3 3L5 12l-4 1 1-4z" />
                </g>
            </g>
        </svg>
    );
};

PenIcon.propTypes = {
    className: PropTypes.string,
};

PenIcon.defaultProps = {
    className: '',
};

export default PenIcon;
