import { createSelector } from 'reselect';

export const userSelector = (state) => state.user;

export const userEmailSelector = createSelector(
    userSelector,
    (userState) => userState.email,
);

export const userDefaultTabSelector = createSelector(
    userSelector,
    (userState) => userState.defaultTab,
);

export const userSoundNotificationsEnabledSelector = createSelector(
    userSelector,
    (userState) => userState.soundNotificationsEnabled,
);
