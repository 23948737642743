import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/en-gb';

import { LOCALE_NAMESPACE } from '../const/translations/LOCALE_NAMESPACE';

// Translations
import enCommon from '../translations/en/common.json';
import deCommon from '../translations/de/common.json';
import nlCommon from '../translations/nl/common.json';

import enUser from '../translations/en/user.json';
import deUser from '../translations/de/user.json';
import nlUser from '../translations/nl/user.json';

import enBundles from '../translations/en/bundles.json';
import deBundles from '../translations/de/bundles.json';
import nlBundles from '../translations/nl/bundles.json';

import enSubscriptions from '../translations/en/subscriptions.json';
import deSubscriptions from '../translations/de/subscriptions.json';
import nlSubscriptions from '../translations/nl/subscriptions.json';

import enToasts from '../translations/en/toasts.json';
import deToasts from '../translations/de/toasts.json';
import nlToasts from '../translations/nl/toasts.json';

import enTable from '../translations/en/table.json';
import deTable from '../translations/de/table.json';
import nlTable from '../translations/nl/table.json';

import enClients from '../translations/en/clients.json';
import deClients from '../translations/de/clients.json';
import nlClients from '../translations/nl/clients.json';

import enInvoices from '../translations/en/invoices.json';
import deInvoices from '../translations/de/invoices.json';
import nlInvoices from '../translations/nl/invoices.json';

import enPayouts from '../translations/en/payouts.json';
import dePayouts from '../translations/de/payouts.json';
import nlPayouts from '../translations/nl/payouts.json';

import enClearings from '../translations/en/clearings.json';
import deClearings from '../translations/de/clearings.json';
import nlClearings from '../translations/nl/clearings.json';

import enMessages from '../translations/en/messages.json';
import deMessages from '../translations/de/messages.json';
import nlMessages from '../translations/nl/messages.json';

import enAccess from '../translations/en/access.json';
import deAccess from '../translations/de/access.json';
import nlAccess from '../translations/nl/access.json';

import enSettings from '../translations/en/settings.json';
import deSettings from '../translations/de/settings.json';
import nlSettings from '../translations/nl/settings.json';

const resources = {
    'en-GB': {
        [LOCALE_NAMESPACE.COMMON]: enCommon,
        [LOCALE_NAMESPACE.USER]: enUser,
        [LOCALE_NAMESPACE.BUNDLES]: enBundles,
        [LOCALE_NAMESPACE.SUBSCRIPTIONS]: enSubscriptions,
        [LOCALE_NAMESPACE.TOASTS]: enToasts,
        [LOCALE_NAMESPACE.TABLE]: enTable,
        [LOCALE_NAMESPACE.CLIENTS]: enClients,
        [LOCALE_NAMESPACE.INVOICES]: enInvoices,
        [LOCALE_NAMESPACE.PAYOUTS]: enPayouts,
        [LOCALE_NAMESPACE.MESSAGES]: enMessages,
        [LOCALE_NAMESPACE.CLEARINGS]: enClearings,
        [LOCALE_NAMESPACE.SETTINGS]: enSettings,
        [LOCALE_NAMESPACE.ACCESS]: enAccess,
    },
    de: {
        [LOCALE_NAMESPACE.COMMON]: deCommon,
        [LOCALE_NAMESPACE.USER]: deUser,
        [LOCALE_NAMESPACE.BUNDLES]: deBundles,
        [LOCALE_NAMESPACE.SUBSCRIPTIONS]: deSubscriptions,
        [LOCALE_NAMESPACE.TOASTS]: deToasts,
        [LOCALE_NAMESPACE.TABLE]: deTable,
        [LOCALE_NAMESPACE.CLIENTS]: deClients,
        [LOCALE_NAMESPACE.INVOICES]: deInvoices,
        [LOCALE_NAMESPACE.PAYOUTS]: dePayouts,
        [LOCALE_NAMESPACE.MESSAGES]: deMessages,
        [LOCALE_NAMESPACE.CLEARINGS]: deClearings,
        [LOCALE_NAMESPACE.SETTINGS]: deSettings,
        [LOCALE_NAMESPACE.ACCESS]: deAccess,
    },
    nl: {
        [LOCALE_NAMESPACE.COMMON]: nlCommon,
        [LOCALE_NAMESPACE.USER]: nlUser,
        [LOCALE_NAMESPACE.BUNDLES]: nlBundles,
        [LOCALE_NAMESPACE.SUBSCRIPTIONS]: nlSubscriptions,
        [LOCALE_NAMESPACE.TOASTS]: nlToasts,
        [LOCALE_NAMESPACE.TABLE]: nlTable,
        [LOCALE_NAMESPACE.CLIENTS]: nlClients,
        [LOCALE_NAMESPACE.INVOICES]: nlInvoices,
        [LOCALE_NAMESPACE.PAYOUTS]: nlPayouts,
        [LOCALE_NAMESPACE.MESSAGES]: nlMessages,
        [LOCALE_NAMESPACE.CLEARINGS]: nlClearings,
        [LOCALE_NAMESPACE.SETTINGS]: nlSettings,
        [LOCALE_NAMESPACE.ACCESS]: nlAccess,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en-GB',
        defaultNS: LOCALE_NAMESPACE.COMMON,
    });

export default i18n;
