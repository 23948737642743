import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ClientNotesFilter.module.scss';
import ClientCategory from './ClientCategory';

const ClientNotesFilter = (props) => (
    <div className={styles.chips}>
        {props.categories.map((category) => (
            <ClientCategory
                key={category.id}
                category={category}
                canDelete={!category.isDefault}
                selected={props.selectedCategoriesIds.includes(category.id)}
                onSelect={props.onSelect}
            />
        ))}
    </div>
);

ClientNotesFilter.propTypes = {
    categories: PropTypes.array,
    selectedCategoriesIds: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
};

ClientNotesFilter.defaultProps = {
    categories: [],
    selectedCategoriesIds: [],
};

export default ClientNotesFilter;
