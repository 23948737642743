import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
    Container, Row, Col, Form,
} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import LocalesDropdown from 'components/LocalesDropdown/LocalesDropdown';
import { tokensSelector } from 'store/selectors/auth';
import * as actions from 'store/actions';
import { Button } from 'components/Common/Button';
import { LogoIcon } from 'components/Icon/Icon';
import login from '../../static/images/login.jpg';

const ResetPassword = () => {
    const isAppLoaded = useSelector((state) => state.app.loaded);
    const { accessToken } = useSelector(tokensSelector);
    const isRequestingPasswordReset = useSelector((state) => state.auth.isRequestingPasswordReset);
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState('');

    const { t } = useTranslation();

    const onResetClick = useCallback((e) => {
        e.preventDefault();
        dispatch(actions.requestPasswordReset(email, history.push));
    }, [email, history]);

    if (isAppLoaded && accessToken) {
        return <Redirect to="/" />;
    }

    return (
        <Container className="d-flex">
            <div className="w-100 bg-white align-self-center rounded shadow-container">
                <Row>
                    <Col md={6} className="d-none d-md-block pr-md-0">
                        <img src={login} alt="" className="w-100 h-100 object-fit-cover rounded-left" />
                    </Col>

                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center pl-md-0">
                        <div
                            className="d-flex flex-column justify-content-center h-100 p-3 pt-5 px-md-4 px-lg-5 pt-md-5 pb-md-0"
                        >
                            <LogoIcon className="mb-4" />

                            <h1>
                                {t('resetPasswordRoute.resetPassword')}
                            </h1>

                            <h2 className="font-weight-normal text-muted">
                                {t('resetPasswordRoute.description')}
                            </h2>

                            <Form
                                className="mt-3"
                                onSubmit={onResetClick}
                            >
                                <Form.Group>
                                    <Form.Label>
                                        {t('resetPasswordRoute.email')}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Button
                                    color="yellow"
                                    type="submit"
                                    disabled={isRequestingPasswordReset}
                                >
                                    {
                                        isRequestingPasswordReset
                                            ? <TailSpin color="white" width={18} height={18} />
                                            : t('resetPasswordRoute.resetPassword')
                                    }
                                </Button>
                            </Form>
                        </div>

                        <div className="d-flex flex-direction-row align-items-end px-3 px-md-4 px-lg-5 py-4 pt-md-0">
                            <div className="flex-grow-1 h-100 d-flex flex-column justify-content-center">
                                <Link className="text-dark font-size-14 text-decoration-none" to="/sign-in">
                                    <Trans
                                        i18nKey="resetPasswordRoute.alreadyHaveAccount"
                                        components={[<span className="font-weight-bold" />]}
                                    >
                                        Already have an account?
                                        {' '}
                                        <span className="font-weight-bold">Sign in</span>
                                    </Trans>
                                </Link>
                            </div>

                            <LocalesDropdown />
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default ResetPassword;
