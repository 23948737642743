import * as actionTypes from '../actions/actionTypes';
import * as EVENTS_TYPES from '../actions/events/actionTypes';

const initialState = {
    loaded: false,
    loadingEvents: false,
    events: null,
    bookings: null,
    itemsCount: 0,
    pagesCount: 0,
    page: 1,
    showAll: false,
};

export const events = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.EVENTS_SET_LOADING: {
        const { loadingEvents } = payload;
        return {
            ...state,
            loadingEvents,
        };
    }
    case actionTypes.GET_EVENTS_SUCCEEDED: {
        const { events, itemsCount, pagesCount } = payload;
        return {
            ...state,
            loaded: true,
            events,
            itemsCount,
            pagesCount,
        };
    }
    case EVENTS_TYPES.SET_EVENTS_PAGE: {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case EVENTS_TYPES.SET_EVENTS_SHOW_ALL: {
        const { showAll } = payload;
        return {
            ...state,
            showAll,
        };
    }
    case actionTypes.ADD_EVENT_SUCCEEDED: {
        const { event } = payload;
        return {
            ...state,
            events: [
                ...state.events,
                event,
            ],
        };
    }
    case actionTypes.EDIT_EVENT_SUCCEEDED: {
        const event = payload.payload;

        return {
            ...state,
            events: state.events.map((item) => (item.resourceId === event.resourceId ? event : item)),
        };
    }
    case actionTypes.CANCEL_EVENT_SUCCEEDED: {
        const id = payload.payload;

        return {
            ...state,
            events: state.events.map((item) => (item.id === id ? {
                ...item,
                isCancelled: !item.isCancelled,
                participants: [],
            } : item)),
        };
    }
    case actionTypes.DELETE_EVENT_SUCCEEDED: {
        const id = payload.payload;

        return {
            ...state,
            events: state.events.filter((item) => Number(item.resourceId) !== Number(id)),
        };
    }
    case actionTypes.ADD_BOOKING_SUCCEEDED: {
        const { booking } = payload;

        if (booking.product.type !== 'event') {
            return state;
        }

        return {
            ...state,
            events: state.events.map((item) => ({
                ...item,
                participants: [...item.participants, booking],
            })),
        };
    }
    case actionTypes.EDIT_BOOKING_SUCCEEDED: {
        if (!state.events?.length) {
            return state;
        }

        const { booking } = payload;

        return {
            ...state,
            events: state.events.map((item) => ({
                ...item,
                participants: item.participants.map((existing) => (existing.id === booking.id ? booking : existing)),
            })),
        };
    }
    case actionTypes.DELETE_BOOKING_SUCCEEDED: {
        if (!state.events?.length) {
            return state;
        }

        const { booking } = payload;

        return {
            ...state,
            events: state.events.map((item) => ({
                ...item,
                participants: item.participants.filter(({ id }) => id !== booking.id),
            })),
        };
    }
    case actionTypes.OMIT_BOOKING_SUCCEEDED: {
        if (!state.events?.length) {
            return state;
        }

        const { bookingID } = payload;

        return {
            ...state,
            events: state.events.map((item) => ({
                ...item,
                participants: item.participants.map((existing) => (existing.id === bookingID
                    ? { ...existing, notPresent: true }
                    : existing)),
            })),
        };
    }
    case actionTypes.CHANGE_PAY_STATUS_BOOKING_SUCCEEDED: {
        if (!state.events?.length) {
            return state;
        }

        const { bookingID, paymentStatus } = payload;

        return {
            ...state,
            events: state.events.map((item) => ({
                ...item,
                participants: item.participants.map((existing) => (existing.id === bookingID
                    ? {
                        ...existing,
                        payment: {
                            ...existing.payment,
                            paid: paymentStatus === 'paid',
                            paidUpfront: paymentStatus === 'paid' ? existing.payment.finalCost : 0,
                            remainingCost: paymentStatus !== 'paid' ? existing.payment.finalCost : 0,
                        },
                    }
                    : existing)),
            })),
        };
    }

    case actionTypes.GET_EVENT_BOOKINGS: {
        return {
            ...state,
            bookings: null,
        };
    }

    case actionTypes.GET_EVENT_BOOKINGS_SUCCEEDED: {
        const { bookings } = payload.payload;
        return {
            ...state,
            bookings,
        };
    }

    case actionTypes.PRODUCTS_GET_BY_ID: {
        return {
            ...state,
            loaded: false,
        };
    }

    case actionTypes.PRODUCTS_GET_BY_ID_SUCCESS: {
        const { item: { type, product } } = payload;
        if (type !== 'event') return state;

        return {
            ...state,
            loaded: true,
            events: state.events.map((item) => (
                item.resourceId === product.id
                    ? ({
                        ...item,
                        ...product,
                        id: item.id,
                    })
                    : item
            )),
        };
    }

    case actionTypes.SELECT_SHOP_TRIGGER: {
        return {
            ...state,
            loaded: false,
            events: null,
        };
    }

    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    default:
        return state;
    }
};
