import React from 'react';

const FromMobile = ({
    fill = 'currentColor',
    width = '100%',
    height = '100%',
    className,
    style,
}) => (
    <svg
        className={className}
        style={style}
        height={height}
        width={width}
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 4H8C7.44771 4 7 4.44772 7 5V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V5C17 4.44772 16.5523 4 16 4ZM8 2C6.34315 2 5 3.34315 5 5V19C5 20.6569 6.34315 22 8 22H16C17.6569 22 19 20.6569 19 19V5C19 3.34315 17.6569 2 16 2H8Z"
            fill={fill}
        />
        <circle cx="12.0005" cy="16.5" r="1.5" fill={fill} />
    </svg>
);

export default FromMobile;
