import * as TYPES from '../actions/categories/actionTypes';

const initialState = {
    loading: false,
    categories: [],
};

export const categories = (state = initialState, { type, payload }) => {
    switch (type) {
    case (TYPES.SET_LOADING): {
        const { loading } = payload;
        return {
            ...state,
            loading,
        };
    }

    case (TYPES.SET_CATEGORIES): {
        const { categories: categoriesList = [] } = payload;
        return {
            ...state,
            categories: categoriesList,
        };
    }

    default:
        return state;
    }
};
