import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './InvoicesTableHeader.module.scss';

const T_PREFIX = 'list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const InvoicesTableHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    return (
        <tr>
            <TableHeaderCell
                className={styles.wideCol}
            >
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.number.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell
                className={styles.wideCol}
            >
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.client.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.date.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.amount.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.vat.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="center"
                    name={t(`${T_FIELDS}.type.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.status.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="center"
                    name={t(`${T_FIELDS}.clearingStatus.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell colSpan={2}>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.actions.label`)}
                />
            </TableHeaderCell>
        </tr>
    );
};

InvoicesTableHeader.propTypes = {};

InvoicesTableHeader.defaultProps = {};

export default InvoicesTableHeader;
