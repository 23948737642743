import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import AsyncSelect from 'react-select/async';
import { WindowedMenuList } from 'react-windowed-select';

import { AlertCircle } from 'react-feather';

import { Button } from 'components/Common/Button';
import { Dialog } from '../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../Common/Dialogs/Dialog/DialogFooter';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import { newSelectStyles, theme } from '../../../styles/select';

import * as SUBSCRIPTIONS_ACTIONS from '../../../store/actions/subscriptions';

import * as styles from './ChangeSoldSubscriptionDialog.module.scss';

const T_PREFIX = 'sold.dialogs.edit';

const ChangeSoldSubscriptionDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const dispatch = useDispatch();

    const {
        visible,
        oldSubData,
        onClose,
    } = props;

    const schema = yup.object({
        subscription: yup.mixed({
            value: yup.number(),
            label: yup.string(),
        }).notRequired(),
        selectedClientIds: yup.array(),
    });

    const DEFAULT_OPTION = {
        value: '',
        label: t(`${T_PREFIX}.body.select.default`),
    };

    const formik = useFormik({
        initialValues: {
            subscription: DEFAULT_OPTION,
        },
        validationSchema: schema,
    });

    const { setFieldValue, values } = formik;

    const handleSubscriptionChange = useCallback((selected) => {
        if (!selected) {
            return setFieldValue('subscription', DEFAULT_OPTION);
        }
        setFieldValue('subscription', selected ?? DEFAULT_OPTION);
    }, [setFieldValue]);

    const handleLoadOptions = useCallback(async (search) => {
        const subs = await dispatch(SUBSCRIPTIONS_ACTIONS.loadSubscriptionsListOptions({ search }));
        const options = [];

        subs.forEach((item) => {
            if (item.id !== oldSubData?.editableSubId) {
                options.push({
                    value: item.id,
                    label: item.subscriptionInfo.title,
                });
            }
        });

        return options;
    }, [oldSubData?.editableSubId, dispatch]);

    const handleApplyClientProfile = useCallback(async () => {
        await dispatch(
            SUBSCRIPTIONS_ACTIONS.changeSoldSubscription({
                soldSubscriptionId: oldSubData?.soldSubId,
                newSubscriptionId: values.subscription.value,
                onSuccess: onClose,
            }),
        );
        setFieldValue('subscription', DEFAULT_OPTION);
    }, [
        oldSubData?.soldSubId,
        oldSubData?.curSubId,
        values.subscription.value,
        onClose,
    ]);

    const handleCloseDialog = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.resetChangeSoldSubscription());
        onClose();
    }, [dispatch, onClose]);

    return (
        <Dialog
            visible={visible}
            onClose={handleCloseDialog}
        >
            <DialogHeader>
                <DialogTitle>
                    {t(`${T_PREFIX}.header.title`)}
                </DialogTitle>
            </DialogHeader>
            <DialogBody>
                <Form
                    id="change-subscription"
                >
                    <Container>
                        <Row>
                            <AsyncSelect
                                isSearchable
                                theme={theme}
                                styles={newSelectStyles}
                                className={styles.filter}
                                placeholder={t(`${T_PREFIX}.body.select.default`)}
                                loadOptions={handleLoadOptions}
                                onChange={handleSubscriptionChange}
                                cacheOptions
                                defaultOptions
                                components={{ MenuList: WindowedMenuList }}
                            />
                        </Row>
                    </Container>
                </Form>
                <Row className={styles.warningText}>
                    <div
                        className={classNames(
                            'mr-2',
                            styles.warningIcon,
                        )}
                    >
                        <AlertCircle />
                    </div>
                    <span>{t(`${T_PREFIX}.body.warningText`)}</span>
                </Row>
            </DialogBody>
            <DialogFooter>
                <Button
                    type="submit"
                    onClick={handleApplyClientProfile}
                    disabled={!values.subscription.value}
                >
                    {t(`${T_PREFIX}.footer.actions.change`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

ChangeSoldSubscriptionDialog.propTypes = {
    visible: PropTypes.bool,
    oldSubData: PropTypes.object,
    onClose: PropTypes.func,
};

ChangeSoldSubscriptionDialog.defaultProps = {
    visible: false,
    onClose: emptyFunc,
};

export default ChangeSoldSubscriptionDialog;
