import { put, takeEvery } from 'redux-saga/effects';

// Actions
import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions';

export function* loadApp() {
    yield put(actions.loadAppSucceeded());
}

export const appSaga = [
    takeEvery(actionTypes.LOAD_APP, loadApp),
];
