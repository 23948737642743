import * as TYPES from './actionTypes';

export const setLoading = ({ loading }) => ({
    type: TYPES.SET_LOADING,
    payload: { loading },
});

export const getCategories = () => ({
    type: TYPES.GET_CATEGORIES,
});

export const setCategories = ({ categories }) => ({
    type: TYPES.SET_CATEGORIES,
    payload: { categories },
});
