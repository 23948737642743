import React from 'react';

const HelpQuestion = ({
    fillBg = '#818E9C',
    color = '#202024',
    className,
}) => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        className={className}
    >
        <circle
            cx="16.0001"
            cy="16.0001"
            r="13.3333"
            fill={fillBg}
            fillOpacity="0.25"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.3342 13.116C14.3342 12.196 15.08 11.4502 16.0001 11.4502C16.9201 11.4502 17.6659 12.196 17.6659 13.116C17.6659 13.7422 17.3207 14.2892 16.8056 14.5747C16.4144 14.7915 15.9845 15.0943 15.6416 15.4948C15.2944 15.9003 15.0001 16.452 15.0001 17.1284C15.0001 17.6807 15.4478 18.1284 16.0001 18.1284C16.5523 18.1284 17.0001 17.6807 17.0001 17.1284C17.0001 17.0611 17.0256 16.9535 17.1608 16.7956C17.3004 16.6326 17.5156 16.4678 17.7749 16.3241C18.9004 15.7005 19.6659 14.4983 19.6659 13.116C19.6659 11.0914 18.0246 9.4502 16.0001 9.4502C13.9755 9.4502 12.3342 11.0914 12.3342 13.116C12.3342 13.6683 12.7819 14.116 13.3342 14.116C13.8865 14.116 14.3342 13.6683 14.3342 13.116ZM16.0649 22.5499C16.8014 22.5499 17.3984 21.9528 17.3984 21.2163C17.3984 20.4798 16.8014 19.8828 16.0649 19.8828C15.3284 19.8828 14.7314 20.4798 14.7314 21.2163C14.7314 21.9528 15.3284 22.5499 16.0649 22.5499Z"
            fill={color}
        />
    </svg>
);

export default HelpQuestion;
