import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const setEventsLoading = ({ loadingEvents }) => ({
    type: actionTypes.EVENTS_SET_LOADING,
    loadingEvents,
});
export const getEvents = () => ({
    type: actionTypes.GET_EVENTS,
});
export const getEventsSucceeded = ({ events, itemsCount, pagesCount }) => ({
    type: actionTypes.GET_EVENTS_SUCCEEDED,
    events,
    itemsCount,
    pagesCount,
});

export const setEventsPage = ({ page }) => ({
    type: actionTypes.SET_EVENTS_PAGE,
    page,
});

export const setEventsShowAll = ({ showAll }) => ({
    type: actionTypes.SET_EVENTS_SHOW_ALL,
    showAll,
});

export const getEventsFailed = () => ({
    type: actionTypes.GET_EVENTS_FAILED,
});

export const addEvent = createPromiseAction(actionTypes.ADD_EVENT);
export const addEventSucceeded = (event) => ({
    type: actionTypes.ADD_EVENT_SUCCEEDED,
    event,
});
export const addEventFailed = () => ({
    type: actionTypes.ADD_EVENT_FAILED,
});

export const editEvent = createPromiseAction(actionTypes.EDIT_EVENT);
export const editEventSucceeded = (event) => ({
    type: actionTypes.EDIT_EVENT_SUCCEEDED,
    payload: event,
});
export const editEventFailed = () => ({
    type: actionTypes.EDIT_EVENT_FAILED,
});

export const cancelEvent = createPromiseAction(actionTypes.CANCEL_EVENT);
export const cancelEventSucceeded = (id) => ({
    type: actionTypes.CANCEL_EVENT_SUCCEEDED,
    payload: id,
});
export const cancelEventFailed = () => ({ type: actionTypes.CANCEL_EVENT_FAILED });

export const deleteEvent = createPromiseAction(actionTypes.DELETE_EVENT);
export const deleteEventSucceeded = (id) => ({
    type: actionTypes.DELETE_EVENT_SUCCEEDED,
    payload: id,
});
export const deleteEventFailed = () => ({ type: actionTypes.DELETE_EVENT_FAILED });

export const getEventBookings = (id) => ({ type: actionTypes.GET_EVENT_BOOKINGS, payload: id });
export const getEventBookingsSucceeded = (id, bookings) => ({
    type: actionTypes.GET_EVENT_BOOKINGS_SUCCEEDED,
    payload: { id, bookings },
});
export const getEventBookingsFailed = () => ({ type: actionTypes.GET_EVENT_BOOKINGS_FAILED });
