// extracted by mini-css-extract-plugin
var _1 = "IconButton_black__P-4Fo";
var _2 = "IconButton_button__G+ldB";
var _3 = "IconButton_disabled__yufBV";
var _4 = "IconButton_gray__LLzs6";
var _5 = "IconButton_group__M28Yy";
var _6 = "IconButton_link__7Bpgb";
var _7 = "IconButton_outline__XLh7H";
var _8 = "IconButton_red__y8cKc";
var _9 = "IconButton_size19__chXvA";
var _a = "IconButton_size24__MhcPG";
var _b = "IconButton_size32__89SCp";
var _c = "IconButton_size36__bDrZS";
var _d = "IconButton_size40__twe+x";
var _e = "IconButton_size42__gdP0e";
var _f = "IconButton_size60__Jf9FE";
var _10 = "IconButton_stretched__APWCr";
var _11 = "IconButton_transparent__O18dG";
var _12 = "IconButton_white__5yVUO";
var _13 = "IconButton_yellow__RyIPQ";
export { _1 as "black", _2 as "button", _3 as "disabled", _4 as "gray", _5 as "group", _6 as "link", _7 as "outline", _8 as "red", _9 as "size19", _a as "size24", _b as "size32", _c as "size36", _d as "size40", _e as "size42", _f as "size60", _10 as "stretched", _11 as "transparent", _12 as "white", _13 as "yellow" }
