import React, { useCallback, useRef } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { generateRandomString } from 'helpers/string/generateRandomString';
import { useMobile } from 'hooks/useMobile';
import { Button } from 'components/Common/Button';
import IconButton from 'components/Common/IconButton/IconButton';
import { emptyFunc } from '../../helpers/function/emptyFunc';

import * as styles from './DateCardList.module.scss';

import { LOCALE_NAMESPACE } from '../../const/translations/LOCALE_NAMESPACE';
import DateCard from './DateCard/DateCard';

const DEFAULT_START_DATE = moment();
const DEFAULT_START_TIME = moment();
const DEFAULT_END_TIME = moment();

const T_PREFIX = 'dateCardList';

const DateCardList = (props) => {
    const {
        title,
        addButtonLabel,
        values,
        onChange,
        className,
        dateCardProps,
        withoutTimeFields,
        errors,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const isMobile = useMobile();

    const listRef = useRef(null);

    const onScrollToLastElem = useCallback(() => {
        if (!listRef.current) {
            return;
        }

        const lastElemIndex = listRef.current.children.length - 1;
        listRef.current.children[lastElemIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }, []);

    const onChangeDateCardData = useCallback(({ index }) => ({ data }) => {
        const newValues = [...values];

        newValues[index] = { ...data, id: `new-${generateRandomString()}` };

        onChange(newValues);
    }, [values, onChange]);

    const onRemoveDateCard = useCallback(({ index }) => () => {
        const newValues = [...values];

        newValues.splice(index, 1);

        onChange(newValues);
    }, [values, onChange]);

    const onAddDateCard = useCallback(() => {
        const newValues = [...values];

        const newDateCard = {
            name: '',
            startDate: DEFAULT_START_DATE,
            id: `new-${generateRandomString()}`,
        };

        if (!withoutTimeFields) {
            Object.assign(newDateCard, {
                startTime: DEFAULT_START_TIME,
                endTime: DEFAULT_END_TIME,
            });
        }

        newValues.push(newDateCard);

        onChange(newValues);
        setTimeout(onScrollToLastElem, 0);
    }, [values, withoutTimeFields, onChange]);

    return (
        <div
            ref={listRef}
            className={classNames(styles.dateCardListContainer, className)}
        >
            <div className={styles.header}>
                {title ?? t(`${T_PREFIX}.header.label`)}

                {isMobile ? (
                    <IconButton
                        size={40}
                        onClick={onAddDateCard}
                    >
                        <Plus />
                    </IconButton>
                )
                    : (
                        <Button
                            type="button"
                            onClick={onAddDateCard}
                            before={<Plus />}
                        >
                            {(addButtonLabel ?? t(`${T_PREFIX}.header.actions.add.label`))}
                        </Button>
                    )}
            </div>

            {values.map((card, index) => (
                <DateCard
                    key={`card-${index}`}
                    card={card}
                    errors={errors[index]}
                    onChange={onChangeDateCardData({ index })}
                    onRemove={onRemoveDateCard({ index })}
                    {...dateCardProps}
                    className={classNames(styles.dateCardListItem, dateCardProps.className)}
                />
            ))}
        </div>
    );
};

DateCardList.propTypes = {
    title: PropTypes.string,
    addButtonLabel: PropTypes.string,
    values: PropTypes.array,
    className: PropTypes.string,
    dateCardProps: PropTypes.object,
    withoutTimeFields: PropTypes.bool,
    errors: PropTypes.array,
    onChange: PropTypes.func,
};

DateCardList.defaultProps = {
    values: [],
    dateCardProps: {},
    withoutTimeFields: false,
    errors: [],
    onChange: emptyFunc,
};

export default DateCardList;
