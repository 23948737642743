import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const signUp = (name, email, birthDate, contactNumber, password, repeatedPassword) => createPromiseAction(actionTypes.SIGN_UP)({
    name,
    email,
    birthDate,
    contactNumber,
    password,
    repeatedPassword,
});

export const signUpSucceeded = () => ({
    type: actionTypes.SIGN_UP_SUCCEEDED,
});

export const signUpFailed = () => ({
    type: actionTypes.SIGN_UP_FAILED,
});

export const signIn = (email, password, remember) => createPromiseAction(actionTypes.SIGN_IN)({
    email,
    password,
    remember,
});

export const signInSucceeded = (accessToken, tokenType, refreshToken, expires, remember, companyAPIKey, userRole, employeeType, resourceId) => ({
    type: actionTypes.SIGN_IN_SUCCEEDED,
    accessToken,
    tokenType,
    refreshToken,
    expires,
    remember,
    companyAPIKey,
    userRole,
    employeeType,
    resourceId,
});

export const signInFailed = () => ({
    type: actionTypes.SIGN_IN_FAILED,
});

export const requestPasswordReset = (email, historyPush) => ({
    type: actionTypes.REQUEST_PASSWORD_RESET,
    email,
    historyPush,
});

export const requestPasswordResetSucceeded = () => ({
    type: actionTypes.REQUEST_PASSWORD_RESET_SUCCEEDED,
});

export const requestPasswordResetFailed = () => ({
    type: actionTypes.REQUEST_PASSWORD_RESET_FAILED,
});

export const authenticate = () => ({
    type: actionTypes.AUTHENTICATE,
});

export const refreshToken = createPromiseAction(actionTypes.REFRESH_TOKEN);

export const signOut = createPromiseAction(actionTypes.SIGN_OUT);

export const signOutSucceeded = () => ({
    type: actionTypes.SIGN_OUT_SUCCEEDED,
});

export const signOutFailed = () => ({
    type: actionTypes.SIGN_OUT_FAILED,
});

export const queueFailedAction = ({ action }) => ({
    type: actionTypes.QUEUE_FAILED_ACTION,
    payload: { action },
});

export const executeFailedActions = () => ({
    type: actionTypes.EXECUTE_FAILED_ACTIONS,
});

export const executeFailedActionsSucceeded = () => ({
    type: actionTypes.EXECUTE_FAILED_ACTIONS_SUCCEEDED,
});

export const setNewPassword = ({
    newPassword, token, onFinish, onSuccess,
}) => ({
    type: actionTypes.NEW_PASSWORD_SET,
    payload: {
        newPassword,
        token,
        onFinish,
        onSuccess,
    },
});
