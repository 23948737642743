import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../Pagination';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import * as styles from './ListTableFooter.module.scss';

const ListTableFooter = (props) => {
    const {
        page,
        showAll,
        pagesCount,
        itemsCount,
        columnsCount,
        itemsPerPage,
        onShowAll,
        onPrevPage,
        onNextPage,
        onLastPage,
        onFirstPage,
        onShowPages,
        onChangePage,
    } = props;

    return (
        <tfoot className={styles.footerRow}>
            <tr>
                <td
                    colSpan={columnsCount}
                    className={styles.footerCell}
                    data-testid="data-test-list-table-footer"
                >
                    <Pagination
                        showAll={showAll}
                        page={page}
                        pagesCount={pagesCount}
                        itemsCount={itemsCount}
                        itemsPerPage={itemsPerPage}
                        onShowAll={onShowAll}
                        onPrevPage={onPrevPage}
                        onNextPage={onNextPage}
                        onLastPage={onLastPage}
                        onFirstPage={onFirstPage}
                        onShowPages={onShowPages}
                        onChangePage={onChangePage}
                    />
                </td>
            </tr>
        </tfoot>
    );
};

ListTableFooter.propTypes = {
    page: PropTypes.number,
    showAll: PropTypes.bool,
    pagesCount: PropTypes.number,
    itemsCount: PropTypes.number,
    columnsCount: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onShowAll: PropTypes.func,
    onPrevPage: PropTypes.func,
    onNextPage: PropTypes.func,
    onLastPage: PropTypes.func,
    onFirstPage: PropTypes.func,
    onShowPages: PropTypes.func,
    onChangePage: PropTypes.func,
};

ListTableFooter.defaultProps = {
    page: 1,
    showAll: false,
    pagesCount: 0,
    itemsCount: 0,
    columnsCount: 1,
    itemsPerPage: 0,
    onShowAll: emptyFunc,
    onPrevPage: emptyFunc,
    onNextPage: emptyFunc,
    onLastPage: emptyFunc,
    onFirstPage: emptyFunc,
    onShowPages: emptyFunc,
    onChangePage: emptyFunc,
};

export default ListTableFooter;
