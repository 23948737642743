import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    ChevronDown,
    ChevronUp,
    Lock,
    Unlock,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import TextButton from 'components/Common/TextButton/TextButton';
import { IconButton } from 'components/Common/IconButton';
import { Row } from 'components/Common/Row';
import {
    ChangeIcon, Close, PauseIcon, PlayIcon, SentIcon, Trash3Icon,
} from 'components/Icon/Icon';
import Info2 from 'components/Icon/icons/info2';
import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { Tooltip } from '../../Common/Tooltip';

import { SOLD_SUBSCRIPTION_STATUS } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_STATUS';
import { SOLD_SUBSCRIPTION_PAYMENT_STATUS } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_STATUS';
import { SOLD_SUBSCRIPTION_PAYMENT_TYPE } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_TYPE';
import { SOLD_SUBSCRIPTION_PROP } from '../../../const/subscriptions/SOLD_SUBSCRIPTION_PROP';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './SoldSubscriptionInfoTableCells.module.scss';

const STATUS_T_KEY_MAP = {
    paid: 'paid',
    open: 'unpaid',
    closed: 'closed',
    paused: 'paused',
    pending: 'pending',
};

const T_PREFIX = 'sold.table.body.row.info';

const SoldSubscriptionInfoTableCells = (props) => {
    const {
        showPayments,
        soldSubscription,
        onShow,
        onEdit,
        onPause,
        onResume,
        onCancel,
        onResend,
        onDelete,
        onToggleUserLock,
        onToggleShowPayments,
        onOpenClient,
    } = props;

    const {
        id: soldSubscriptionId,
        clientId,
        clientEmail,
        clientName,
        clientLastName,
        clientIsBlocked,
        subscriptionId,
        subscriptionTitle,
        contractStartDate,
        contractEndDate,
        nextDebitDate,
        price,
        paymentType,
        payments,
        pause,
        showResend,
        unpaidCounter,
        paymentsStatusInfo,
        subscriptionStatus,
        updatingAt,
        updatingSubscription,
    } = soldSubscription || {};
    const { locale } = useSelector((state) => state.locales);
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { t: tc } = useTranslation();

    const curMonth = new Date().getMonth();
    const monthLastDay = moment().endOf('month').startOf('day');

    const isStarted = moment().isAfter(moment.unix(contractStartDate));
    const isOpenStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.OPEN;
    const isPaidStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.PAID;
    const isCloseStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.CLOSED;
    const isPendingStatus = subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.OPEN
        && payments[payments.length - 1].paymentStatusTitle === SOLD_SUBSCRIPTION_PAYMENT_STATUS.PENDING;

    const isPaused = useMemo(() => !isCloseStatus && (
        pause?.status || (isOpenStatus && [...soldSubscription.payments].pop().paymentPaused)
    ), []);
    const isPausedFromNextMonth = pause?.from && (moment.unix(pause.from).month() === curMonth + 1);
    const isAutomaticPayment = paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.AUTOMATIC;
    const isSubscriptionManuallySet = soldSubscription.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL;
    const isAutoDebitOrderReceived = monthLastDay.diff(moment().startOf('day'), 'days') <= SOLD_SUBSCRIPTION_PROP.AUTO_DEBIT.DAYS_PERIOD;

    const updatingAtData = updatingAt ? moment.unix(updatingAt).locale(locale).format('DD.MM.YYYY') : '';

    const showPauseResume = useMemo(() => {
        if (!pause?.from && nextDebitDate) {
            return true;
        }
        if (pause?.from) {
            return pause?.unlimited;
        }
        return false;
    }, [pause?.from, nextDebitDate, pause?.unlimited]);

    const pauseStatus = useMemo(() => {
        if (isCloseStatus || !pause || !pause.from) {
            return '';
        }

        const startDate = moment.unix(pause.from).locale(locale).format('DD.MM.YYYY');
        const endDate = pause.unlimited
            ? t(`${T_PREFIX}.status.infinite`)
            : moment.unix(pause.to).locale(locale).format('DD.MM.YYYY');
        const status = pause.status ? 'pausedFrom' : 'willBePaused';
        return t(`${T_PREFIX}.status.statuses.${status}`, {
            startDate,
            endDate,
        });
    }, [
        isCloseStatus,
        pause?.status,
        pause?.from,
        pause?.to,
        pause?.unlimited,
        t,
    ]);

    const paymentStatus = useMemo(() => {
        if (!subscriptionStatus) {
            return '';
        }
        let statusKey = subscriptionStatus;
        if (isPaused) {
            statusKey = STATUS_T_KEY_MAP.paid;
        } else if (isPendingStatus) {
            statusKey = STATUS_T_KEY_MAP.pending;
        }

        const status = t(`${T_PREFIX}.status.statuses.${STATUS_T_KEY_MAP[statusKey]}`);
        return (unpaidCounter && isOpenStatus && !isPaused)
            ? `${status} (${unpaidCounter})`
            : status;
    }, [
        subscriptionStatus,
        t,
        unpaidCounter,
        isOpenStatus,
        isPaused,
    ]);

    const handleShow = useCallback(() => {
        if (!soldSubscriptionId || !contractStartDate) {
            return;
        }
        onShow({ soldSubscriptionId, contractStartDate });
    }, [
        soldSubscriptionId,
        contractStartDate,
        onShow,
    ]);

    const handleResend = useCallback(() => {
        if (!soldSubscriptionId) {
            return;
        }
        onResend({ soldSubscriptionId });
    }, [
        soldSubscriptionId,
        onResend,
    ]);

    const handleEdit = useCallback(() => {
        const editableSubId = updatingSubscription?.id || subscriptionId;
        if (!soldSubscriptionId || !editableSubId) {
            return;
        }

        onEdit({
            soldSubId: soldSubscriptionId,
            editableSubId,
            curSubId: subscriptionId,
        });
    }, [
        soldSubscriptionId,
        updatingSubscription?.id,
        subscriptionId,
        onEdit,
    ]);

    const handleCancel = useCallback(() => {
        if (!soldSubscriptionId || !contractStartDate || !subscriptionStatus || !paymentType) {
            return;
        }
        onCancel({
            soldSubscriptionId, contractStartDate, subscriptionStatus, nextDebitDate, paymentType, payments, price, updatingAt, updatingSubscription,
        });
    }, [
        soldSubscriptionId,
        contractStartDate,
        updatingAt,
        updatingSubscription,
        nextDebitDate,
        subscriptionStatus,
        paymentType,
        onCancel,
        payments,
    ]);
    const isFirstPaymentPaid = payments?.[0]?.paymentStatus;

    const handleToggleUserLock = useCallback(() => {
        if (!clientId) {
            return;
        }
        onToggleUserLock({ clientId });
    }, [clientId, onToggleUserLock]);

    const handlePauseResume = useCallback(() => {
        if (!showPauseResume || !soldSubscriptionId) {
            return null;
        }
        if (pause?.from) {
            return onResume({ soldSubscription });
        }
        return onPause({ soldSubscription });
    }, [
        showPauseResume,
        soldSubscriptionId,
        pause?.from,
        onResume,
        onPause,
        soldSubscription,
    ]);

    const handleOpenClient = useCallback(() => {
        if (!clientId) {
            return;
        }
        onOpenClient({ clientId });
    }, [clientId, onOpenClient]);

    const handleDelete = useCallback(() => {
        if (!soldSubscriptionId) {
            return;
        }
        onDelete({ soldSubscriptionId });
    }, [
        soldSubscriptionId,
        onDelete,
    ]);

    const paymentInfoLabel = paymentsStatusInfo
        ? t(`${T_PREFIX}.status.paymentInfo.${paymentsStatusInfo}`)
        : null;

    const paymentTypeLabel = t(`${T_PREFIX}.status.paymentType.${paymentType}`);

    const tooltips = {
        btnDelete: '',
        btnEdit: '',
        btnCancel: '',
    };

    const isDisabledPause = payments[0].paymentStatus === false;

    const isAllUnpaid = payments.every((payment) => payment.paymentStatus === false);

    if (!isSubscriptionManuallySet && ((isOpenStatus && isStarted) || isPaidStatus) && !(isAllUnpaid && isOpenStatus)) {
        tooltips.btnDelete = t(`${T_PREFIX}.tooltips.noDelete`);
    }

    if (isPaused) {
        tooltips.btnEdit = t(`${T_PREFIX}.tooltips.noEdit.paused`);
    } else if (isPausedFromNextMonth) {
        tooltips.btnEdit = t(`${T_PREFIX}.tooltips.noEdit.pausedNextMonth`);
    }

    if (isAutomaticPayment) {
        if (isAutoDebitOrderReceived && isOpenStatus) {
            tooltips.btnEdit = t(`${T_PREFIX}.tooltips.noEdit.autoDebitReceived`);
        }
    }

    if (!isFirstPaymentPaid && isAutomaticPayment) {
        tooltips.btnCancel = tc('subscriptions.cancelSoldSubscriptionModals.showFirstPaymentNotPaid');
    }

    const statusCellColorClass = classNames(styles.statusCell, (() => {
        if (isCloseStatus) {
            return null;
        }

        if (isSubscriptionManuallySet) {
            return styles.manual;
        }

        if (isPaidStatus || isPaused) {
            return styles.paid;
        }

        if (isPendingStatus) {
            return styles.pending;
        }

        return styles.unpaid;
    })());

    return (
        <>
            <ListTableBodyCell className={styles.clientCell}>
                <Row gap={0}>
                    <TextButton
                        noPadding
                        color="black"
                        onClick={handleOpenClient}
                    >
                        {`${clientName} ${clientLastName} (${clientEmail})`}
                    </TextButton>
                    <IconButton
                        type="button"
                        color="transparent"
                        onClick={onToggleShowPayments}
                        size={24}
                    >
                        {showPayments ? (
                            <ChevronUp size={18} />
                        ) : (
                            <ChevronDown size={18} />
                        )}
                    </IconButton>
                    <IconButton
                        type="button"
                        color="transparent"
                        onClick={handleToggleUserLock}
                        size={24}
                    >
                        {clientIsBlocked ? (
                            <Lock size={18} className={styles.lockedIcon} />
                        ) : (
                            <Unlock size={18} />
                        )}
                    </IconButton>
                </Row>
            </ListTableBodyCell>
            <ListTableBodyCell className={styles.titleCell}>
                <span className={styles.titleStatus}>
                    {subscriptionTitle}
                </span>
                {Boolean(updatingAt && updatingSubscription) && (
                    <span className={styles.pauseStatus}>
                        {`${t(`${T_PREFIX}.changeSubscription.from`)} ${updatingAtData} ${updatingSubscription?.name}`}
                    </span>
                )}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <span>
                    {Boolean(contractStartDate) && moment.unix(contractStartDate).locale(locale).format('DD MMM YYYY')}
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell>
                <span>
                    {Boolean(contractEndDate) && moment.unix(contractEndDate).locale(locale).format('DD MMM YYYY')}
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell>
                <span>
                    {Boolean(nextDebitDate) && moment.unix(nextDebitDate).locale(locale).format('DD MMM YYYY')}
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell align="right">
                <span>
                    {price}
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell className={statusCellColorClass}>
                <span className={styles.titleStatus}>
                    {Boolean(paymentStatus) && `${paymentStatus} (${paymentInfoLabel || paymentTypeLabel})`}
                </span>
                <span className={styles.pauseStatus}>
                    {pauseStatus}
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell align="right">
                <Row gap={8}>
                    {showResend && (
                        <Tooltip
                            tooltip={t(`${T_PREFIX}.actions.resend`)}
                            placement="top"
                            forButton
                        >
                            <IconButton
                                type="button"
                                color="gray"
                                onClick={handleResend}
                            >
                                <SentIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!contractEndDate && (
                        <Tooltip
                            tooltip={tooltips.btnCancel || t(`${T_PREFIX}.actions.cancel`)}
                            placement="top"
                            forButton
                        >
                            <IconButton
                                type="button"
                                onClick={handleCancel}
                                disabled={tooltips.btnCancel}
                                color="gray"
                            >
                                <Close />
                            </IconButton>
                        </Tooltip>
                    )}

                    {(subscriptionStatus !== STATUS_T_KEY_MAP.closed) && nextDebitDate
                        && (
                            <Tooltip
                                tooltip={tooltips.btnEdit || t(`${T_PREFIX}.actions.edit`)}
                                placement="top"
                                forButton
                            >
                                <IconButton
                                    type="button"
                                    onClick={handleEdit}
                                    disabled={tooltips.btnEdit}
                                    color="gray"
                                >
                                    <ChangeIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    <Tooltip
                        tooltip={t(`${T_PREFIX}.actions.show`)}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            type="button"
                            onClick={handleShow}
                            color="gray"
                        >
                            <Info2 />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        tooltip={tooltips.btnDelete || t(`${T_PREFIX}.actions.delete`)}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            type="button"
                            color="gray"
                            onClick={handleDelete}
                            disabled={tooltips.btnDelete}
                        >
                            <Trash3Icon />
                        </IconButton>
                    </Tooltip>
                    {showPauseResume && (
                        <Tooltip
                            tooltip={isDisabledPause ? t(`${T_PREFIX}.tooltips.noPause`) : t(`${T_PREFIX}.actions.${pause?.from ? 'resume' : 'pause'}`)}
                            placement="top"
                            forButton
                        >
                            <IconButton
                                type="button"
                                color="gray"
                                disabled={isDisabledPause}
                                onClick={handlePauseResume}
                            >
                                {pause?.from ? <PlayIcon /> : <PauseIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                </Row>
            </ListTableBodyCell>
        </>
    );
};

SoldSubscriptionInfoTableCells.propTypes = {
    showPayments: PropTypes.bool,
    soldSubscription: PropTypes.object,
    onShow: PropTypes.func,
    onPause: PropTypes.func,
    onResume: PropTypes.func,
    onCancel: PropTypes.func,
    onResend: PropTypes.func,
    onDelete: PropTypes.func,
    onToggleUserLock: PropTypes.func,
    onToggleShowPayments: PropTypes.func,
    onOpenClient: PropTypes.func,

};

SoldSubscriptionInfoTableCells.defaultProps = {
    showPayments: false,
    soldSubscription: null,
    onShow: emptyFunc,
    onPause: emptyFunc,
    onResume: emptyFunc,
    onCancel: emptyFunc,
    onResend: emptyFunc,
    onDelete: emptyFunc,
    onToggleUserLock: emptyFunc,
    onToggleShowPayments: emptyFunc,
    onOpenClient: emptyFunc,
};

export default SoldSubscriptionInfoTableCells;
