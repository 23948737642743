import React from 'react';
import PropTypes from 'prop-types';

const Download = (props) => {
    const { className } = props;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M8.00001 12C8.30001 12 8.50001 11.9 8.70001 11.7L14.4 6L13 4.6L9.00001 8.6V0H7.00001V8.6L3.00001 4.6L1.60001 6L7.30001 11.7C7.50001 11.9 7.70001 12 8.00001 12Z"
                fill="currentColor"
            />
            <path
                d="M14 14H2V11H0V15C0 15.6 0.4 16 1 16H15C15.6 16 16 15.6 16 15V11H14V14Z"
                fill="currentColor"
            />
        </svg>

    );
};

Download.propTypes = {
    className: PropTypes.string,
};

Download.defaultProps = {
    className: '',
};

export default Download;
