import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import RBTooltip from 'react-bootstrap/Tooltip';

const Tooltip = (props) => {
    const {
        rootClassName,
        className,
        tooltip,
        children,
        placement,
        forButton,
        isDisabled,
    } = props;

    if (isDisabled) {
        return children;
    }

    return (
        <OverlayTrigger
            overlay={(
                <RBTooltip className={rootClassName}>
                    <div className={className}>
                        {tooltip}
                    </div>
                </RBTooltip>
            )}
            placement={placement}
        >
            {forButton ? <div tabIndex="0" style={{ width: 'inherit' }}>{children}</div> : children}
        </OverlayTrigger>
    );
};

Tooltip.propTypes = {
    rootClassName: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.any,
    children: PropTypes.any,
    forButton: PropTypes.bool,
    isDisabled: PropTypes.bool,
    placement: PropTypes.oneOf([
        'auto-start',
        'auto',
        'auto-end',
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom-end',
        'bottom',
        'bottom-start',
        'left-end',
        'left',
        'left-start',
    ]),
};

Tooltip.defaultProps = {
    rootClassName: '',
    className: '',
    tooltip: '',
    forButton: false,
    isDisabled: false,
    children: null,
    placement: 'top',
};

export default Tooltip;
